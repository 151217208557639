export const googleReviewConfig = {
  logo: '',
  totalReviews: 735,
  totalRatingStar: '4.2',
  RatingStar: '5',
  reviews: [
    {
      reviewerName: 'Hugo Dominguez',
      reviewerRating: '5',
      reviewerComments: "American Water Resources is prompt in providing assistance to one's sewage related issues.",
    },
    {
      reviewerName: 'Cathy Bozzolo',
      reviewerRating: '5',
      reviewerComments: 'I called about a sewer back up. They responded quickly. The company they sent were wonderful.',
    },
    {
      reviewerName: 'antoinette cottman',
      reviewerRating: '5',
      reviewerComments: "This company is great. I'm so happy I signed up they are professional, courteous and prompt.",
    },
    {
      reviewerName: 'Mark De Simon',
      reviewerRating: '5',
      reviewerComments: 'Great company. Every aspect from the phone call to the follow up to the service was outstanding.',
    },
    {
      reviewerName: 'RON CARUSO',
      reviewerRating: '5',
      reviewerComments: 'FAST RESPONSE TO MY SEWER BACKUP. GOOD CUSTOMER SERVICE AND PROFESSIONAL SERVICE.',
    },
    {
      reviewerName: 'Judy Collins',
      reviewerRating: '5',
      reviewerComments: 'I have had to use mine 3 times, once I was actually out of town and it was great all 3 times.',
    },
    {
      reviewerName: 'Judy Stott',
      reviewerRating: '5',
      reviewerComments: 'Have had AWR for years all experiences have been great, also very prompt.',
    },
    {
      reviewerName: 'Grace Cannistra',
      reviewerRating: '5',
      reviewerComments: 'Homeowners should add these service plans to their residential accounts as it is a wise choice.',
    },
    {
      reviewerName: 'Susan Jones',
      reviewerRating: '5',
      reviewerComments: 'The1st rep had prob with computer, but called back and 2nd rep took care of billing prob.',
    },
    {
      reviewerName: 'WILLIAM MILLER',
      reviewerRating: '5',
      reviewerComments: 'Excellent service provided, contacted plumber who called us immediately and service was performed same day!',
    },
    {
      reviewerName: 'John Davis',
      reviewerRating: '5',
      reviewerComments: 'I already submitted 2 reviews of your excellent service which you may post online.',
    },
    {
      reviewerName: 'Gaby Beck',
      reviewerRating: '5',
      reviewerComments: 'Getting service was easy and the plumber they sent was great. I highly recommend this service.',
    },
  ],
};
