<span *ngIf="!isPageNotFoundComp" class="product-detail-body" [ngClass]="{ 'enable-all-reviews': globalVariables.isAllReviewsClicked }">
  <div *ngIf="!this.loadZipCode">
    <hos-breadcrumb [config]="breadcrumsConfig" class="detail-header-breadcrum"></hos-breadcrumb>
    <hos-product-detail-header
      (locationCodeHandler)="locationCodeHandler($event)"
      (reviewSchemaData)="reviewSchemaData($event)"
      (showPartner)="displayPartner($event)"
      (zipcodeChanged)="zipcodeChanged($event)"
      [productType]="'product'"
      [productDetail]="productDetail"
      [locationCodeId]="locationCodeId"
      [serviceState]="service_State"
      (divHeightWithScrollEmitter)="setEmitterDivHeight($event)"
      [allProducts]="allProducts"
    ></hos-product-detail-header>
    <div
      *ngIf="productDetail"
      class="col-12 tab general-desc-section"
      id="tabs"
      [ngClass]="{ 'set-top-tabfix': globalVariables.fixedHeaderProdDetails }"
    >
      <div
        class="col-4 tab-div tab-top-tablet-section"
        [ngClass]="{ active: selectedTab === 'review-section' }"
        (click)="scrollToSection($event, 'review-section')"
      >
        <button class="tablinks">Reviews</button>
      </div>
      <div
        class="col-4 tab-div tab-top-tablet-section"
        [ngClass]="{ active: selectedTab === 'faq-section' }"
        (click)="scrollToSection($event, 'faq-section')"
      >
        <button class="tablinks">{{ "FAQ's" }}</button>
      </div>
      <div
        class="col-4 tab-div tab-top-tablet-section"
        [ngClass]="{ active: selectedTab === 'neighbourhood-service-id' }"
        (click)="scrollToSection($event, 'neighbourhood-service-id')"
      >
        <button class="tablinks">Related Products</button>
      </div>
    </div>
    <div scrollSpy [spiedTags]="['DIV']" id="scroll-spy-id" (sectionChange)="onSectionChange($event)">
      <!-- HWR-3670  -->
      <div
        id="tab-review-section"
        class="testimonial-container"
        [style.padding-top.px]="globalVariables.fixedHeaderProdDetails ? divHeightScrollFromEmitter : 0"
        [ngClass]="{ 'pad-top': globalVariables.fixedHeaderProdDetails }"
      >
        <div id="review-section" [ngClass]="{ 'padding-testimonial': !globalVariables.fixedHeaderProdDetails }">
          <div class="col-12 mobile-title-section" style="margin-bottom: 50px">
            <h2 class="section-head-title font-unbounce-title full-width-mob">
              <span>Reviews</span>
            </h2>
          </div>
          <img class="testimonial-blockquote" src="../../../../img/arrow/testimonial-quote.svg" loading="lazy" alt="testimonial-quote" />
          <div class="testimonial-div-container page-wrap">
            <carousel [showIndicators]="false" (activeSlideChange)="getSlideChangeIndex($event)" data-interval="false">
              <slide class="animated height-100 slideIn" *ngFor="let testimonial of testimonials; let i = index">
                <div class="text-center carousel-inner-item text-white">
                  <div class="mt-20">
                    <img
                      class="testimonial-author-prev"
                      title="Testimonial Author Image"
                      [src]="testimonials[i === 0 ? testimonials.length - 1 : i - 1].image"
                      alt="testimonial-author-img"
                    />
                    <img
                      class="testimonial-author-img"
                      [src]="testimonials[testimonialSlideIndex].image"
                      loading="lazy"
                      alt="testimonial-author-img"
                    />
                    <img
                      class="testimonial-author-next"
                      [src]="testimonials[i === testimonials.length - 1 ? 0 : i + 1].image"
                      alt="testimonial-author-img"
                    />
                  </div>
                  <div class="lead">
                    <p class="testimonial-description-text">{{ testimonial.description }}</p>
                    <p class="testimonial-description-author">{{ testimonial.writtenBy }}</p>
                    <p class="testimonial-description-place">{{ testimonial.place }}</p>
                  </div>
                </div>
              </slide>
            </carousel>
          </div>
        </div>
      </div>
      <div id="tab-faq-section">
        <hos-product-faq [productDetail]="productDetail"></hos-product-faq>
      </div>
      <section id="tab-neighbourhood-service-id">
        <hos-neighborhood-services
          [zipcode]="zipCode"
          [location_code]="locationCodeId"
          [productId]="marketingCodeId"
          [allProducts]="allProducts"
        ></hos-neighborhood-services>
      </section>
    </div>
  </div>
</span>
<span *ngIf="isPageNotFoundComp" class="product-detail-body">
  <hos-page-not-found404></hos-page-not-found404>
</span>
