<div class="modal-dialog custom-error-alert modal-content modal-background">
  <div class="modal-header modal-custom-header error-modal-custom-header info-type">
    <div class="info-background mx-auto"></div>
  </div>
  <div class="modal-body">
    <div class="surge-checkbox" *ngFor="let item of surgeData; let i = index">
      <div class="title mb-3">{{ item.title }}</div>
      <div *ngFor="let check of item.questions; let j = index" class="custom-checkbox">
        <div *ngIf="check.type === 'checkbox'">
          <input
            type="checkbox"
            id="surgeId_{{ i }}{{ j }}"
            name="surgeName-{{ i }}{{ j }}"
            [(ngModel)]="check.checked"
            (change)="enrollClicked && isEligibleForSurge()"
          />
          <label for="surgeId_{{ i }}{{ j }}" [innerHTML]="check.question"></label>
        </div>
        <div *ngIf="check.type === 'custom-input' && check.category === 'surgeId_contractor'" class="installation-check">
          <input
            type="checkbox"
            id="surgeId_contractor_yes"
            name="surgeName-contractor"
            [(ngModel)]="contractorCheckbox_yes"
            [checked]="contractorCheckbox_yes"
            (change)="contractorCheckbox('yes')"
          />
          <label for="surgeId_contractor_yes">Yes</label>
          <input
            type="checkbox"
            id="surgeId_contractor_no"
            name="surgeName-contractor"
            [(ngModel)]="contractorCheckbox_no"
            [checked]="contractorCheckbox_no"
            (change)="contractorCheckbox('no')"
          />
          <label for="surgeId_contractor_no" class="ml-4">No</label>
          <div class="mt-2 mb-3">
            <textarea
              maxlength="900"
              class="form-control round-form comment-section-height mb-1"
              name="Surge Comment"
              [(ngModel)]="surgeComment"
              placeholder="Additional Notes (Optional)"
              (keyup)="countChar()"
            ></textarea>
            <div style="color: #c2c2c2; font-size: 12px">
              <!-- <span style="color:red;">*</span> -->Characters Remaining: <span id="char_counter">{{ charRemaining }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="surge-enroll-msg">
      <div class="alert alert-success" *ngIf="enrollClicked && enrollSurge">
        <strong>Congratulations!</strong> You are eligible for Power Surge Protection.
        <p *ngIf="contractorCheckbox_yes">Contractor will be contacting you to schedule the installation.</p>
      </div>
      <div class="alert alert-danger" *ngIf="enrollClicked && !enrollSurge">
        Sorry, you are not eligible to enroll in Power Surge Protection Program.
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <div class="cursor-pointer">
      <button class="btn print-btn text-uppercase" (click)="close()">{{ cancelText || 'Cancel' }}</button>
    </div>
    <div>
      <button (click)="confirmHandler()" class="btn submit-btn app-btn mt-0 text-uppercase">{{ confirmText || 'Ok' }}</button>
    </div>
  </div>
</div>

<div [ngClass]="{ loading: showLoader }"></div>
