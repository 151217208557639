import { Component, OnInit } from '@angular/core';
import { NguCarouselConfig } from '@ngu/carousel';
import { SiteContentService } from '../../../core/services/siteContents';
import { SessionStorageService } from '../../../core/services/session-storage.service';

import { environment } from '../../../../environments/environment';
import { UtilsService } from '../../../core/services/utils.service';

@Component({
  selector: 'hos-testimonial',
  templateUrl: './testimonial.component.html',
  styleUrls: ['./testimonial.component.scss'],
})
export class TestimonialComponent implements OnInit {
  public testimonials: Array<any> = [];

  public testimonialCarousel: NguCarouselConfig;

  testimonialsBgImage: String = '';

  siteContent: any;

  bgImgTestimonials: any;

  constructor(
    private siteContentService: SiteContentService,
    private utilsService: UtilsService,
    private sessionStorageService: SessionStorageService,
  ) {}

  ngOnInit() {
    this.testimonialCarousel = {
      grid: {
        xs: 1,
        sm: 1,
        md: 1,
        lg: 1,
        all: 0,
      },
      slide: 1,
      speed: 500,
      interval: {
        timing: 5000,
        initialDelay: 1000,
      },
      point: {
        visible: true,
      },
      load: 2,
      custom: 'banner',
      touch: true,
      loop: true,
    };
    this.utilsService.event.subscribe(data => {
      if (Object.keys(data).length !== 0) {
        this.loadContent();
      }
    });
    this.siteContentService.event.subscribe(() => {
      this.loadContent();
    });
  }

  loadContent() {
    this.utilsService.getPartnerDetails$().subscribe(
      data => {
        const contentData = data.body.site.homepage;
        this.siteContent = contentData.testimonials.testimonialsItems;
        this.testimonialsBgImage = this.utilsService.buildImageUrl(
          `${environment.Image_API_Endpoint}${environment.Content_service_API_Endpoint}${contentData.testimonials.testimonialBgImage.url}`,
        );
        this.siteContent.forEach(testimonial => {
          const imageUrl = this.utilsService.buildImageUrl(
            `${environment.Image_API_Endpoint}${environment.Content_service_API_Endpoint}${testimonial.bgImage.url}`,
          );
          this.testimonials.push({
            image: imageUrl,
            name: `${testimonial.author.name}`,
            place: `${testimonial.author.place}`,
            description: `${testimonial.desc}`,
          });
        });
        if (![undefined, 'undefined', null, 'null', ''].includes(contentData.testimonials.testimonialBgImage.url)) {
          this.bgImgTestimonials = this.utilsService.buildImageUrl(
            `${environment.Image_API_Endpoint}${environment.Content_service_API_Endpoint}/${contentData.testimonials.testimonialBgImage.url}`,
          );
        }
      },
      () => {},
    );
  }
}
