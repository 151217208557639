import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService } from './http';
import { environment } from '../../../environments/environment';

@Injectable()
export class TemplateService {
  public constructor(private http: HttpService) {}

  /**
   * get template id
   * @returns {Observable<any>}
   * @memberof TemplateService
   */
  getTemplateId(state: any): Observable<any> {
    const apiUrl = `${environment.Template_service_API_Endpoint}leak_contract/template_id?state_code=${state}`;
    return this.http.get(apiUrl).pipe(map(res => res));
  }

  /**
   * Get contract template data
   *
   * @returns {Observable<any>}
   *
   * @memberof TemplateService
   */
  getOrderTemplate(template_data: any, templateid: any): Observable<any> {
    const payload = {
      template_data,
      download: true,
      leakContract: true,
      templateid,
    };

    const apiUrl = `${environment.Template_service_API_Endpoint}render`;
    return this.http.post(apiUrl, payload).pipe(map(res => res));
  }

  /**
   * Update DownloadId , OrderID, Signed
   * @returns {Observable<any>}
   *
   * @memberof TemplateService
   */
  updateContractDetails(downloadId: string, key: string, value: any): Observable<any> {
    const leakData = {
      downloadId,
      key,
      value,
    };

    return this.http.put(`${environment.Template_service_API_Endpoint}leak_contract`, leakData).pipe(map(res => res));
  }
}
