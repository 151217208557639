import { Component, OnInit, Inject, PLATFORM_ID, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { isPlatformBrowser, Location } from '@angular/common';
import { ProductService } from '../../../product/services/product.service';
import { Breadcrumb } from '../../../core/component/breadcrumb/breadcrumb.component';
import { SessionStorageService } from '../../../core/services/session-storage.service';
import { LocationService } from '../../../home/services/location.service';
import { SeoConfig } from '../../../core/model/seo-config.model';
import { SeoService } from '../../../core/services/seo.service';
import { environment } from '../../../../environments/environment';
import { programs } from '../../../core/component/colorConfig/serviceProgramClass';
import { GlobalVariables } from '../../../core/services/global-variables.service';
import { SiteContentService } from '../../../core/services/siteContents';
import { UtilsService } from '../../../core/services/utils.service';

@Component({
  selector: 'hos-services-generic-detail-body',
  templateUrl: './body.component.html',
  styleUrls: ['./body.component.scss'],
})
export class ServicesGenericDetailsBodyComponent implements OnInit, OnDestroy {
  products: Array<any> = [];

  productDetail: any;

  showNeighborhoodServices: Boolean = false;

  location_code: String;

  breadcrumsConfig: Array<Breadcrumb> = [
    {
      title: 'Home',
      link: '/',
      active: false,
    },
  ];

  seoConfig: SeoConfig;

  logoURL: any;

  categories: any[];

  isShowNotFoundComp: Boolean = false;

  isbrowser = true;

  selectedTab = '';

  benefitsHeading: any;

  coveredRepairsHeading: any;

  faqsHeading: any;

  divHeightScrollFromEmitter: any = 0;

  testimonialSlideIndex: any = 0;

  testimonialSlidePrevEvent: Boolean = false;

  public testimonials: Array<any> = [];

  constructor(
    private router: Router,
    public activatedRoute: ActivatedRoute,
    private productService: ProductService,
    private locationService: LocationService,
    private sessionStorageService: SessionStorageService,
    private seoService: SeoService,
    private location: Location,
    public globalVariables: GlobalVariables,
    private siteContentService: SiteContentService,
    private utilsService: UtilsService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    this.isbrowser = isPlatformBrowser(platformId);
    this.logoURL = this.sessionStorageService.getItem('logoURL');
    this.sessionStorageService.deleteItem('location_code');
    this.sessionStorageService.deleteItem('selectedAddress');
    /* HWR-2761 */
    this.location.subscribe((event: PopStateEvent): void => {
      if (event.type === 'hashchange') {
        if (window?.location?.hash === '#reviews') {
          this.reviewsFragment('reviews');
        }
      }
    });
  }

  async ngOnInit() {
    if (this.isbrowser) {
      window.scrollTo(0, 0);
    }
    const getCategories = await this.getApiCategories();
    this.categories = getCategories.body;
    // this.productService.categories$().subscribe(categories => this.categories = categories);
    /* Old service url to new service url */
    this.activatedRoute.params.subscribe(param => {
      if (param && param.serviceId && param.serviceName) {
        const catData = this.categories.filter(ele => ele.categoryName.split(' ').join('-').toLowerCase() === param.serviceName.toLowerCase());
        if (catData.length === 0) {
          this.isShowNotFoundComp = true;
        } else if (catData && param.serviceId.toLowerCase() === catData[0]._id.toLowerCase()) {
          this.isShowNotFoundComp = false;
          this.router.navigate([`/service/${param.serviceName.trim().toLowerCase()}`]);
        } else {
          this.isShowNotFoundComp = true;
        }
      }
    });
    this.activatedRoute.params.subscribe(param => {
      if (param && param.serviceName) {
        this.location_code = '';
        if (this.isbrowser) {
          window.scrollTo(0, 0);
        }
        const categoriesData = this.categories.filter(ele => ele.categoryName.split(' ').join('-').toLowerCase() === param.serviceName.toLowerCase());
        let serviceId;
        if (categoriesData && categoriesData.length === 1) {
          serviceId = categoriesData[0]._id;
        } else if (categoriesData.length === 0) {
          this.isShowNotFoundComp = true;
          return;
        }
        this.productService.getServiceGenericContent(serviceId).subscribe(data => {
          const productData = data.body;
          let objData = programs[productData.serviceName];
          if (typeof objData === 'undefined') {
            objData = programs.others;
          }
          // productData.colorConfigClass = objData.class;
          productData.colorConfigStyle = objData.color;
          if (productData.detailHeaderBgColor) {
            productData.colorConfigStyle = productData.detailHeaderBgColor;
          }
          if (data.status === 200) {
            if (this.sessionStorageService.getItem('selectedAddress')) {
              this.location_code = this.sessionStorageService.getItem('location_code');
            }
            this.productDetail = productData;
            this.benefitsHeading = this.productDetail.benefitsHeading;
            this.coveredRepairsHeading = this.productDetail.coveredRepairsHeading;
            this.faqsHeading = this.productDetail.faqsHeading;
            if (this.breadcrumsConfig.length < 2) {
              this.breadcrumsConfig.push({
                title: productData.serviceName,
                link: '',
                active: true,
              });
            } else {
              this.breadcrumsConfig[this.breadcrumsConfig.length - 1].title = productData.serviceName;
            }
            this.reviewsFragment();
            this.setPageMetaData(productData.serviceName);
            this.seoService.generateTitleTags(this.seoConfig);
            this.seoService.setCanonicalTags();
          }
        });
      }
    });
    this.utilsService.event.subscribe(data => {
      if (Object.keys(data).length !== 0) {
        this.loadContent();
      }
    });
  }

  locationCodeHandler() {
    this.location_code = this.sessionStorageService.getItem('location_code');
  }

  setPageMetaData(serviceName: string) {
    this.seoConfig = {
      title: `Residential ${serviceName} Program`,
      description: `American Water Resources offers affordable home protection programs
      that help protect against unexpected repair costs for service line emergencies.`,
      keywords: `service line warranties, water line protection, sewer line protection,
      in home plumbing emergencies, electric line protection, power surge protection, heating system repair, cooling system repair`,
      image: this.logoURL,
      slug: '',
      sitename: '',
      type: '',
      summary: '',
      url: '',
    };
  }

  public getApiCategories(): Promise<any> {
    return this.productService.getCategories().toPromise();
  }

  /* HWR-2761 */
  reviewsFragment(data?: any) {
    const tree = this.router.parseUrl(this.router.url);
    if (tree.fragment === 'reviews' || data === 'reviews') {
      const element = document.querySelector('#review-section');
      if (element) {
        setTimeout(() => {
          $('html, body').animate(
            {
              scrollTop: $('#review-section').offset().top - 170,
            },
            1000,
          );
          // document.body.scrollTop -= 200;
        }, 500);
      }
    }
  }

  scrollToSection(event, sectionId: string) {
    if (this.isbrowser) {
      const tablinks = document.getElementsByClassName('tab-div');
      for (let i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(' active', '');
      }
      event.currentTarget.className += ' active';
      //   document.querySelector('#' + sectionId).scrollIntoView({ behavior: 'smooth',block: "start",inline:"start" });
      if (this.globalVariables.fixedHeaderProdDetails) {
        $('html, body').animate(
          {
            scrollTop: $(`#${sectionId}`).offset().top - (sectionId === 'review-section' ? 240 : 170),
          },
          1000,
        );
        document.body.scrollTop -= 200;
      } else {
        $('html, body').animate(
          {
            scrollTop: $(`#${sectionId}`).offset().top - 100,
          },
          1000,
        );
      }
      this.selectedTab = sectionId;
    }
  }

  onSectionChange(sectionId: string) {
    this.selectedTab = sectionId ? sectionId.replace('tab-', '') : '';
  }

  ngOnDestroy() {
    this.globalVariables.fixedHeaderProdDetails = false;
    this.globalVariables.isAllReviewsClicked = false;
  }

  setEmitterDivHeight(data) {
    if (data) {
      this.divHeightScrollFromEmitter = data;
      this.sessionStorageService.setItem('divHeightScrollFromEmitter', this.divHeightScrollFromEmitter);
    }
  }

  getSlideChangeIndex(event: number) {
    if (event < this.testimonialSlideIndex) {
      this.testimonialSlidePrevEvent = true;
    } else {
      this.testimonialSlidePrevEvent = false;
    }
    this.testimonialSlideIndex = event;
  }

  loadContent() {
    this.utilsService.getPartnerDetails$().subscribe(data => {
      const testimonialData = data.body.site.homepage.testimonials.testimonialsItems;
      testimonialData.forEach(testimonial => {
        const imageUrl = this.utilsService.buildImageUrl(
          `${environment.Image_API_Endpoint}${environment.Content_service_API_Endpoint}${testimonial.bgImage.url}`,
        );
        this.testimonials.push({
          image: imageUrl,
          writtenBy: testimonial.author.name,
          place: testimonial.author.place,
          description: `${testimonial.desc}`,
        });
      });
    });
  }
}
