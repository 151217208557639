/* eslint-disable no-empty */
import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable()
export class SessionStorageService {
  isbrowser: Boolean = true;

  /**
   * Creates an instance of SessionStorageService.
   *
   * @memberof SessionStorageService
   */
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    this.isbrowser = isPlatformBrowser(platformId);
  }

  /**
   * Adds a key value pair to sessionstorage.
   *
   * @param {string} key
   * @param {string} token (value)
   *
   * @memberof SessionStorageService
   */
  setItem(key: string, token: string) {
    try {
      if (this.isbrowser) {
        localStorage.setItem(key, token);
      }
    } catch (e) {}
  }

  /**
   * Returns the stored vaule stored with given key.
   *
   * @param {string} key
   * @returns {string}
   *
   * @memberof SessionStorageService
   */
  getItem(key: string, parse: boolean = false): any {
    try {
      if (this.isbrowser) {
        if (parse) {
          return JSON.parse(localStorage.getItem(key));
        }
        return localStorage.getItem(key);
      }
    } catch (e) {}
  }

  /**
   * Removes the key value pair for given key.
   *
   * @param {string} key
   *
   * @memberof SessionStorageService
   */
  deleteItem(key: string) {
    try {
      if (this.isbrowser) {
        localStorage.removeItem(key);
      }
    } catch (e) {}
  }

  /**
   * Adds a key value pair to localstorage.
   *
   * @param {string} key
   * @param {string} token (value)
   *
   * @memberof SessionStorageService
   */
  setItemToloclaStorage(key: string, token: string) {
    try {
      if (this.isbrowser) {
        localStorage.setItem(key, token);
      }
    } catch (e) {}
  }

  /**
   * Returns the stored vaule stored with given key from localstorage.
   *
   * @param {string} key
   * @returns {string}
   *
   * @memberof SessionStorageService
   */
  getItemFromloclaStorage(key: string): string {
    try {
      return localStorage.getItem(key);
    } catch (e) {}
  }

  /**
   * Returns the stored vaule stored with given key from localstorage.
   *
   * @param {string} key
   * @returns {string}
   *
   * @memberof SessionStorageService
   */
  deleteItemFromloclaStorage(key: string) {
    localStorage.removeItem(key);
  }

  /**
   * Clears the data stored in local storage and session storage
   *
   * @param {string} key
   * @returns {string}
   *
   * @memberof SessionStorageService
   */
  clear() {
    try {
      sessionStorage.clear();
      localStorage.clear();
    } catch (e) {}
  }
}
