import { NgModule } from '@angular/core';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgxPaginationModule } from 'ngx-pagination';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ProductRoutingModule } from './product-routing';
import { HomeModule } from '../home/index';
import { CoreModule } from '../core/index';
import { PageNotFound404Module } from '../page-not-found404/index';
// Components
import { ProductsComponent } from './component/products/products.component';
import { ProviderModalComponent } from './component/provider-modal/provider-modal.component';
import { UpsellServiceDetailComponent } from './component/upsell-service-detail/upsell-service-detail.component';
// Services
@NgModule({
  declarations: [
    ProductsComponent,
    // UpsellServiceDetailComponent
  ],
  exports: [ProductsComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    ProductRoutingModule,
    HomeModule,
    CoreModule,
    NgxPaginationModule,
    BsDropdownModule.forRoot(),
    PageNotFound404Module,
  ],
  providers: [ProviderModalComponent, UpsellServiceDetailComponent],
})
export class ProductModule {}
