import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { SiteContentService } from '../../../core/services/siteContents';
import { environment } from '../../../../environments/environment';
import { UtilsService } from '../../../core/services/utils.service';

@Component({
  selector: 'hos-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.scss'],
})
export class PartnerComponent implements OnInit {
  partnersData: any = {
    sectionTitle: '',
    description: '',
    backgroundImage: '',
    partners: [],
  };

  contactLink: any;

  partnerServiceBaseUrl: any = `${environment.Image_API_Endpoint}${environment.Partner_service_API_Endpoint}`;

  isbrowser: boolean = true;

  constructor(private siteContentService: SiteContentService, private utilsService: UtilsService, @Inject(PLATFORM_ID) private platformId: any) {
    this.isbrowser = isPlatformBrowser(this.platformId);
  }

  ngOnInit() {
    this.siteContentService.getPartnersData().subscribe(
      data => {
        const partnersData = data.body;
        this.partnersData.title = partnersData.title;
        this.partnersData.description = partnersData.description;
        this.partnersData.partnerText = partnersData.partnerText;
        this.partnersData.partnerPhone = partnersData.partnerPhone;
        this.partnersData.backgroundImage = this.utilsService.buildImageUrl(`${this.partnerServiceBaseUrl}/${partnersData.backgroundImage}`);
        this.partnersData.partners = partnersData.partners;
        this.utilsService.getContactLink$().subscribe(linkData => {
          this.contactLink = `/page/contact-us/${linkData.entryId}`;
        });
      },
      () => {},
    );
  }

  getImage(imageName) {
    return this.utilsService.buildImageUrl(imageName);
  }
}
