import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { SimpleModalService } from 'ngx-simple-modal';
import { DomSanitizer } from '@angular/platform-browser';
import { find } from 'lodash-es';
import { ProductService } from '../../../product/services/product.service';
import { SiteContentService } from '../../../core/services/siteContents';
import { SessionStorageService } from '../../../core/services/session-storage.service';
import { environment } from '../../../../environments/environment';
import { LocationService } from '../../../home/services/location.service';
import { UtilsService } from '../../../core/services/utils.service';

@Component({
  selector: 'hos-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  categories: any[];

  recentNews: any[];

  zipcode: string;

  selectedAddress: any;

  locationId: string;

  siteContent: any;

  logoURL: String = 'img/logo-inverse.svg';

  description: String = '';

  baseContentUrl = `${environment.Image_API_Endpoint}${environment.Content_service_API_Endpoint}`;

  locationCodeData: any;

  tempZip: any;

  privacylink: any;

  i: any;

  customer_service_number: String;

  providerState: any;

  ccpaDetails: any;

  site: any;

  constructor(
    private productService: ProductService,
    private router: Router,
    private location: Location,
    private locationService: LocationService,
    private sessionStorageService: SessionStorageService,
    private siteContentService: SiteContentService,
    private simpleModalService: SimpleModalService,
    private utilsService: UtilsService,
    private sanitizer: DomSanitizer,
  ) {
    const state = sessionStorageService.getItem('providerState');
    this.providerState = state;
  }

  ngOnInit() {
    this.customer_service_number = environment.customer_service_number;
    // this.dotCMSService
    //   .getContentByCategory(DotCMSCategory.HOS_RECENT_NEWS)
    //   .subscribe(response => {
    //     this.recentNews = response.data;
    //   });
    this.utilsService.event.subscribe(data => {
      if (Object.keys(data).length !== 0) {
        this.loadContent();
      }
    });
    this.siteContentService.event.subscribe(() => {
      this.loadContent();
    });
  }

  loadContent() {
    this.utilsService.getPartnerDetails$().subscribe(
      data => {
        const contentData = data.body;
        this.siteContent = contentData.site.footer;
        this.logoURL = `${environment.Image_API_Endpoint}${environment.Content_service_API_Endpoint}${this.siteContent.logo.url}`;
        this.logoURL = this.utilsService.buildImageUrl(this.logoURL);
        this.description = this.siteContent.samllDesc;
        this.ccpaDetails = this.sanitizer.bypassSecurityTrustHtml(this.siteContent.ccpaDetails);
        this.siteContent.socials = contentData.site.socialIcons;
        this.site = contentData;
        this.location.subscribe(
          () => {},
          () => {},
        );
        if (contentData) {
          this.getPrivacyPolicyLink(contentData);
        }
        this.productService.categories$().subscribe(
          categories => {
            this.categories = categories.body;
          },
          () => {},
        );
      },
      () => {},
    );
    this.location.subscribe(
      () => {},
      () => {},
    );
  }

  goToCategory(category) {
    const savedAddress = this.sessionStorageService.getItem('selectedAddress');
    this.selectedAddress = savedAddress ? JSON.parse(savedAddress) : '';
    this.tempZip = this.sessionStorageService.getItem('tempZip');
    /* HWR-2796 */
    if (this.selectedAddress && this.selectedAddress.zipcode !== '' && !!this.selectedAddress.zipcode) {
      this.tempZip = this.selectedAddress.zipcode;
      this.catRedirect(category);
    } else if (this.tempZip !== '' && this.tempZip) {
      this.tempZip = this.sessionStorageService.getItem('tempZip');
      this.catRedirect(category);
    } else {
      this.router.navigate([`/service/${category.categoryName.trim().split(' ').join('-').toLowerCase()}`]);
    }
  }

  catRedirect(category: any) {
    let payload: any;
    this.locationService.getLocationCode(this.tempZip).subscribe(
      result => {
        const locationData = result.body;
        if (result.status === 200) {
          this.sessionStorageService.setItem('location_code', locationData.locationCodeId);
        } else {
          this.sessionStorageService.setItem('location_code', 'isOOF');
        }
        payload = {
          zipcode: this.tempZip,
          location_code: locationData.locationCodeId,
        };
        this.locationService.ZipcodeAndLocation = payload;
        const allProducts = true;
        this.productService.getMarketingCodes('', '', allProducts).subscribe(
          data => {
            if (data.status === 200) {
              const resData = data.body;
              let WebProgName: any;
              const resultData = resData.result.filter(ele => ele.program.programId === category.reviewProgramId);
              if (resultData.length > 0) {
                WebProgName = result[0].webProgramName ? result[0].webProgramName : result[0].programName;
              }
              let categoryParams: any;
              const typeParams = 'residential';
              if (category) {
                categoryParams = WebProgName.toString().split(' ').join('-').split(',').join('');
              }
              this.locationService.getLocationsContent(this.sessionStorageService.getItem('location_code')).subscribe(
                dataReceived => {
                  this.locationCodeData = dataReceived.body;
                  this.locationService.getMicroSiteForLocation(this.locationCodeData.locationCode).subscribe(
                    dataMicroSite => {
                      if (dataMicroSite.body.level === 'default') {
                        if (this.sessionStorageService.getItem('providerState')) {
                          this.sessionStorageService.setItem('isMicroSite', '0');
                          this.sessionStorageService.deleteItem('baseUrlState');
                          this.sessionStorageService.deleteItem('providerState');
                          let redirctUrl = `/product/${this.tempZip}/${this.locationCodeData.locationCode.toLowerCase()}`;
                          redirctUrl += typeParams ? `/${typeParams}` : '';
                          redirctUrl += categoryParams ? `/${categoryParams.toLowerCase()}` : '';
                          window.location.href = redirctUrl;
                        } else {
                          //   this.router.navigate(['/products/', this.tempZip, (this.locationCodeData.locationCode).toLowerCase()]);
                          let redirctUrl = `/product/${this.tempZip}/${this.locationCodeData.locationCode.toLowerCase()}`;
                          redirctUrl += typeParams ? `/${typeParams}` : '';
                          redirctUrl += categoryParams ? `/${categoryParams.toLowerCase()}` : '';
                          this.router.navigate([redirctUrl]);
                        }
                      } else {
                        this.sessionStorageService.setItem('isMicroSite', '1');
                        if (dataMicroSite.body.site.siteName) {
                          dataMicroSite.body.site.siteName = dataMicroSite.body.site.siteName.toLowerCase();
                        } else {
                          dataMicroSite.body.site.siteName = '';
                        }
                        let providerState = '';
                        providerState = this.sessionStorageService.getItem('providerState')
                          ? this.sessionStorageService.getItem('providerState').toLowerCase()
                          : '';
                        if (providerState === dataMicroSite.body.site.siteName) {
                          //   this.router.navigate(['/products/', this.tempZip, (this.locationCodeData.locationCode).toLowerCase()]);
                          let redirctUrl = `/product/${this.tempZip}/${this.locationCodeData.locationCode.toLowerCase()}`;
                          redirctUrl += typeParams ? `/${typeParams}` : '';
                          redirctUrl += categoryParams ? `/${categoryParams.toLowerCase()}` : '';
                          this.router.navigate([redirctUrl]);
                        } else {
                          if (dataMicroSite.body.site.siteName) {
                            dataMicroSite.body.site.siteName = dataMicroSite.body.site.siteName.toLowerCase();
                          } else {
                            dataMicroSite.body.site.siteName = '';
                          }
                          this.sessionStorageService.setItem('providerState', dataMicroSite.body.site.siteName);
                          this.sessionStorageService.setItem('baseUrlState', dataMicroSite.body.site.siteName);
                          let redirctUrl = `/${dataMicroSite.body.site.siteName}/product/${
                            this.tempZip
                          }/${this.locationCodeData.locationCode.toLowerCase()}`;
                          redirctUrl += typeParams ? `/${typeParams}` : '';
                          redirctUrl += categoryParams ? `/${categoryParams.toLowerCase()}` : '';
                          window.location.href = redirctUrl;
                        }
                      }
                    },
                    () => {},
                  );
                },
                () => {},
              );
            } else if (data.status !== 200) {
              this.showErrorModal();
            }
          },
          () => {},
        );
      },
      () => {
        this.showErrorModal();
        this.sessionStorageService.setItem('location_code', 'isOOF');
      },
    );
  }

  showErrorModal() {}

  getPrivacyPolicyLink(res) {
    const menu = res.site.sideMenu;
    this.privacylink = find(menu, link => {
      if (link.label === 'Privacy Policy') {
        return link;
      }
      return false;
    });
  }

  getImage(imageName) {
    return this.utilsService.buildImageUrl(imageName);
  }
}
