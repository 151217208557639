<ng-container *ngIf="isbrowser">
  <div class="card text-center cardsAlign" [ngClass]="{ 'card-height': showOldPrice }" *ngIf="product" ngsReveal>
    <div class="d-flex align-items-center justify-content-center program-name ga-track-learn-more" (click)="learnMoreHandler(product)">
      {{ product?.webProgramName }}
    </div>
    <img
      [src]="getProductCardImage(product)"
      [alt]="product?.webProgramName"
      class="card-img ga-track-learn-more"
      (click)="learnMoreHandler(product)"
    />
    <div class="card-body">
      <!-- first row start -->
      <div class="row m-0">
        <div class="col-9 p-0">
          <h3 *ngIf="specialProduct.includes(product?.programId); then leak; else notLeak"></h3>
          <ng-template #leak>
            <button (click)="goToPaymentOptions()" class="btn btn-link float-left non-href-links btn-sm primary-link change-link leak-program">
              Click here for payment options
            </button>
          </ng-template>
          <ng-template #notLeak>
            <div class="price-cls float-left">
              <span class="notranslate">${{ product?.monthlyPrice | slice : 0 : -2 }}</span>
              <span class="small-txt">Per Month</span>
            </div>
          </ng-template>
        </div>
        <div class="col-3 p-0">
          <img class="product-icon" [src]="getImage(baseUrl + product?.programIconUrl)" [alt]="product?.webProgramName" />
        </div>
        <div class="col-12 p-0">
          <div class="old-price-container" *ngIf="showOldPrice">
            <div class="d-flex flex-column">
              <span class="old-price" *ngIf="product && product.oldPrice && product.oldPrice !== ''"
                >${{ product.oldPrice | slice : 0 : -2 }} Per Month</span
              >
              <div class="rounded old-price-text p-1" *ngIf="product && product.oldPriceText && product.oldPriceText !== ''">
                <span class="text-danger">{{ product.oldPriceText }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- first row end -->
      <div class="row m-0 mt-15">
        <button
          *ngIf="!specialProduct.includes(product?.programId) && !refCheck"
          (click)="addProductToCart(product)"
          class="btn w-100 enroll-now skiptranslate ga-track-enroll-now"
          [ngClass]="{ 'commercial-enroll-now': product?.contactType === 3 }"
          title="ENROLL NOW"
        >
          ENROLL NOW
        </button>
        <button
          *ngIf="specialProduct.includes(product?.programId)"
          (click)="addProductToCart(product)"
          class="btn w-100 enroll-now skiptranslate ga-track-enroll-now"
          [ngClass]="{ 'commercial-enroll-now': product?.contactType === 3 }"
          title="BUY NOW"
        >
          BUY NOW
        </button>
        <button
          *ngIf="refCheck"
          (click)="addProductToCart(product)"
          class="btn w-100 enroll-now skiptranslate ga-track-enroll-now"
          [ngClass]="{ 'commercial-enroll-now': product?.contactType === 3 }"
          title="ADD UPGRADE TO CART"
        >
          ADD UPGRADE TO CART
        </button>
      </div>
      <!-- last row start -->
      <div class="row m-0 d-none">
        <div class="col-4 bottom-btn ga-track-learn-more" (click)="learnMoreHandler(product)" title="Learn More">
          {{ butttonText }}
        </div>

        <ul class="social social-list social-show" *ngIf="shareIcon && shareIconIndexArray[index]['value']">
          <li class="social-icons social-twitter" (click)="learnMoreNativeLink(product, 'twitter')">
            <a>
              <i class="fa fa-twitter" aria-hidden="true"></i>
            </a>
          </li>
          <li class="social-icons social-facebook" (click)="learnMoreNativeLink(product, 'fb')">
            <a>
              <i class="fa fa-facebook" aria-hidden="true"></i>
            </a>
          </li>
          <li class="social-icons social-linkedin" (click)="learnMoreNativeLink(product, 'linkedin')">
            <a>
              <i class="fa fa-linkedin" aria-hidden="true"></i>
            </a>
          </li>
          <li class="social-icons social-pinterest" (click)="learnMoreNativeLink(product, 'pinterest')">
            <a>
              <i class="fa fa-pinterest" aria-hidden="true"></i>
            </a>
          </li>
          <li class="social-icons social-mail" (click)="learnMoreNativeLink(product, 'mail')">
            <a>
              <i class="fa fa-envelope" aria-hidden="true"></i>
            </a>
          </li>
        </ul>
        <div class="col-8 button-row">
          <div class="col-6 bottom-btn btn-share" (click)="showSocialIcons()" title="Share">
            <span>Share<img src="img/my-account/share.svg" class="img-responsive img-share" alt="Share" /></span>
          </div>
          <div class="col-6 bottom-btn" (click)="openCreateForm(product)" title="Review">
            <span>Review<img src="img/my-account/review.svg" class="img-responsive img-review" alt="Review" /></span>
          </div>
        </div>
      </div>
      <div class="commercial-row" *ngIf="product.contactType === 3">
        <div class="commercial-product">COMMERCIAL</div>
      </div>
      <!-- last row end -->
    </div>
  </div>
</ng-container>
