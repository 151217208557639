import { Component, OnInit, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'hos-things-you-can-do',
  templateUrl: './things-you-can-do.component.html',
  styleUrls: ['./things-you-can-do.component.scss'],
})
export class ThingsYouCanDoComponent implements OnInit, OnChanges {
  @Input() page: string;

  cards: any[];

  selectedPageText: String;

  ngOnInit() {
    this.cards = [
      {
        image: '/img/register/ThingsYouCanDo/Feature-Ico-01.svg',
        text: 'View products and offers in your area',
      },
      {
        image: '/img/register/ThingsYouCanDo/Feature-Ico-02.svg',
        text: 'Purchase new protection services',
      },
      {
        image: '/img/register/ThingsYouCanDo/Feature-Ico-03.svg',
        text: 'View your current protection status',
      },
      {
        image: '/img/register/ThingsYouCanDo/Feature-Ico-04.svg',
        text: 'Update your account information',
      },
      {
        image: '/img/register/ThingsYouCanDo/Feature-Ico-05.svg',
        text: 'Manage your account',
      },
      {
        image: '/img/register/ThingsYouCanDo/Feature-Ico-06.svg',
        text: 'And more!',
      },
    ];
  }

  ngOnChanges(): void {
    if (this.page) {
      if (this.page === 'activate') {
        this.selectedPageText = 'log in';
      } else if (this.page === 'register') {
        this.selectedPageText = 'register';
      }
    }
  }
}
