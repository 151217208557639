/* eslint-disable no-underscore-dangle */
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { NguCarouselConfig } from '@ngu/carousel';
import { find, map } from 'lodash-es';
import { Router } from '@angular/router';
import { SimpleModalService } from 'ngx-simple-modal';
import { SessionStorageService } from '../../../core/services/session-storage.service';
import { CartService } from '../../services/cart.service';
import { Breadcrumb } from '../../../core/component/breadcrumb/breadcrumb.component';
import { ShoppingCart } from '../../model/cart.model';
import { UserService } from '../../../user/services/user.service';
import { UtilsService } from '../../../core/services/utils.service';
import { ProductService } from '../../../product/services/product.service';
import { ErrorModalComponent } from '../../../core/component/error-modal/error-modal.component';
import { SeoConfig } from '../../../core/model/seo-config.model';
import { SeoService } from '../../../core/services/seo.service';
import { LocationService } from '../../../home/services/location.service';
import { environment } from '../../../../environments/environment';
import { CCPAUpdatesService } from '../../../core/services/ccpa-updates.service';

@Component({
  selector: 'hos-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
})
export class CartComponent implements OnInit, OnDestroy {
  customerServiceNumber: String;

  grossTotal: String;

  cartSubscription: any;

  userDetails: any;

  showLoader = false;

  privacylink: any;

  public carouselTile: NguCarouselConfig;

  public cart: Observable<ShoppingCart>;

  public itemCount: number;

  public cartProducts: Array<any> = [];

  deletedCartData: any;

  otherProducts: Array<any>;

  shareIconIndexArray: any = [];

  selectedAddress: any = {
    formatted_address: '',
  };

  condotionToShowAddMoreServiceLink: Boolean = false;

  productServiceBaseUrl: String = environment.Image_API_Endpoint + environment.Product_service_API_Endpoint;

  breadcrumsConfig: Array<Breadcrumb> = [
    {
      title: 'Home',
      link: '/',
      active: false,
    },
    {
      title: 'Cart',
      link: '',
      active: true,
    },
  ];

  seoConfig: SeoConfig = {
    title: 'Cart Service Line Warranties | American Water Resources',
    description: `American Water Resources offers affordable home protection programs
    that help protect against unexpected repair costs for service line emergencies.`,
    keywords: `service line warranties, water line protection, sewer line protection,
    in home plumbing emergencies, electric line protection, power surge protection, heating system repair, cooling system repair`,
    image: '',
    slug: '',
    sitename: '',
    type: '',
    summary: '',
    url: '',
  };

  zipcode: any;

  locData: any;

  locationId: any;

  partnerName: any;

  userId: any;

  isRemoved: boolean = false;

  constructor(
    private sessionStorageService: SessionStorageService,
    private userService: UserService,
    private cartService: CartService,
    private locationService: LocationService,
    private productService: ProductService,
    private simpleModalService: SimpleModalService,
    private utilsService: UtilsService,
    private router: Router,
    seoService: SeoService,
    private ccpaUpdatesService: CCPAUpdatesService,
  ) {
    seoService.generateTitleTags(this.seoConfig);
    seoService.setCanonicalTags();
    this.customerServiceNumber = environment.customer_service_number;
  }

  ngOnDestroy() {
    if (this.cartSubscription) {
      this.cartSubscription.unsubscribe();
    }
  }

  ngOnInit() {
    this.carouselTile = {
      grid: {
        xs: 1,
        sm: 2,
        md: 4,
        lg: 4,
        all: 0,
      },
      slide: 3,
      speed: 400,
      point: {
        visible: false,
      },
      load: 2,
      touch: true,
      easing: 'ease',
    };
    this.showLoader = true;
    this.cart = this.cartService.get();
    const userData = this.userService.getSignedInUser();
    this.userId = userData ? userData.user._id : '';
    this.locationId = this.sessionStorageService.getItem('location_code');
    const selectedAddress = JSON.parse(this.sessionStorageService.getItem('selectedAddress'));
    this.getPrivacyPolicyLink();
    this.zipcode = selectedAddress.zipcode;
    this.cartSubscription = this.cart.subscribe(cart => {
      this.cartProducts = cart.items;
      this.itemCount = this.cartProducts.length;
      const productIds = map(this.cartProducts, 'productId');
      this.grossTotal = cart.grossTotal.toFixed(2);
      if (this.itemCount > 0 || this.locationId) {
        this.getProductForLocation(this.locationId, productIds, this.userId);
      } else {
        this.otherProducts = [];
      }

      const savedAddress = this.sessionStorageService.getItem('selectedAddress');
      if (savedAddress) {
        this.selectedAddress = JSON.parse(savedAddress);
      } else {
        this.selectedAddress.formatted_address = cart.serviceAddress;
      }
    });
    if (this.sessionStorageService.getItem('zipcode') || this.cartProducts.length >= 0) {
      this.condotionToShowAddMoreServiceLink = true;
    } else {
      this.condotionToShowAddMoreServiceLink = false;
    }
    const deletedData = JSON.parse(this.sessionStorageService.getItemFromloclaStorage('deletedCartData'));
    if (deletedData) {
      this.deletedCartData = deletedData.join(', ');
      this.sessionStorageService.deleteItemFromloclaStorage('deletedCartData');
    }
    if (userData) {
      this.userService.getCustomerDetails(userData.user._id).subscribe(data => {
        this.userDetails = data;
      });
    }
    this.partnerName = this.sessionStorageService.getItem('websitePartnerName');
    if (this.partnerName) {
      this.productService.getProviderContent(this.sessionStorageService.getItem('location_code')).subscribe(
        locationData => {
          this.locData = locationData;
        },
        () => {
          // error handling
          this.locData = [];
        },
      );
    } else {
      this.locData = [];
    }
    const providerState = this.sessionStorageService.getItem('providerState');
    if (environment.ccpa_updates_provider.includes(providerState) && !this.isRemoved) {
      this.ccpaUpdatesService.getCCPAUpdates();
    }
    this.showLoader = false;
  }

  getProductForLocation(locationId, productIds, userId) {
    this.showLoader = true;
    // this.zipcode = this.cartProducts[0].zipcode;
    if (locationId && productIds) {
      this.productService.getRecommendedProducts(locationId, productIds, userId).subscribe(
        data => {
          this.showLoader = false;
          if (data.status === 200) {
            this.otherProducts = [];
            const resData = data;
            resData.forEach(product => {
              if (this.otherProducts.length < 4) {
                this.otherProducts.push(product);
              }
            });
            if (this.otherProducts.length > 0) {
              for (let i = 0; i < this.otherProducts.length; i++) {
                this.shareIconIndexArray.push({
                  value: false,
                });
              }
            }
          } else if (data.status !== 200) {
            // error handling
            this.otherProducts = [];
          }
        },
        () => {
          this.showLoader = false;
          this.otherProducts = [];
        },
      );
    }
  }

  goToProducts() {
    const data = this.locationService.getZipcodeAndLocation();
    if (data) {
      this.zipcode = data.zipcode;
      this.locationId = data.location_code;
    } else {
      if (this.cartProducts.length === 0) {
        this.router.navigate(['/service']);
      }
      this.zipcode = this.cartProducts[0].zipcode;
      this.locationId = this.cartProducts[0].locationId;
    }
    this.router.navigate(['/products/', this.zipcode, this.cartProducts[0].locationCode.toLowerCase(), 'residential']);
  }

  public emptyCart(): void {
    this.cartService.empty();
  }

  removeProduct(product: any) {
    this.showLoader = true;
    this.cartService.removeItem(product, () => {
      this.isRemoved = true;
      this.showLoader = false;
    });
    if (this.sessionStorageService.getItem('zipcode') || this.cartProducts.length > 0) {
      this.condotionToShowAddMoreServiceLink = true;
    } else {
      this.condotionToShowAddMoreServiceLink = false;
    }
  }

  placeOrder() {
    this.userDetails = this.userService.getSignedInUser();
    if (this.userDetails) {
      this.router.navigateByUrl('/payment?section=cart');
    }
  }
  /* openLoginWindow() {
    this.simpleModalService.addModal(LoginModalComponent, {
      title: 'Already have an account?',
      message: ''
    }, {
        wrapperClass: 'modal-main-class in',
        closeOnClickOutside: true
      })
      .subscribe((isConfirmed) => {
        // We get dialog result
        if (isConfirmed.confirm) {
          if (isConfirmed.status === 409) {
            this.showErrorModal('Error', 'You have already enrolled or added this product to your cart', 'Ok');
          } else if (isConfirmed.status === 400) {
            this.showErrorModal('Important Information', `There is a bundle upgrade available for your account.
            Please contact Customer Service at <span class="app-txt">
            ${this.customerServiceNumber}</span> to upgrade your protection.`, 'Ok');
          } else if (isConfirmed.response.responseCode === 201) {
            this.openBundle(isConfirmed.response);
            this.router.navigateByUrl('/payment?section=cart');
          } else {
            this.router.navigateByUrl('/payment?section=cart');
          }
        } else if (isConfirmed.confirm === false) {
          this.loadForgotPassword();
        } else if (isConfirmed.confirm === undefined) {

        }
      });
    // We can close dialog calling disposable.unsubscribe();
    // If dialog was not closed manually close it by timeout
    // setTimeout(() => {
    //     disposable.unsubscribe();
    // }, 10000);
  } */

  showErrorModal(title: string, message: string, confirmationMsg?: string) {
    this.simpleModalService
      .addModal(
        ErrorModalComponent,
        {
          title,
          message,
          confirmationMsg,
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: true,
        },
      )
      .subscribe(() => {});
  }

  /* openBundle(data: any) {
    this.simpleModalService.addModal(BundleModalComponent, {
      data: data
    }, {
        wrapperClass: 'modal-main-class in',
        closeOnClickOutside: true
      })
      .subscribe((isConfirmed) => {
        // We get dialog result
        if (isConfirmed) {

        } else if (isConfirmed === false) {

        } else if (isConfirmed === undefined) {

        }
      });
  }

  loadForgotPassword() {
    this.simpleModalService.addModal(ForgotPasswordComponent, {
      title: 'Forgot your Password?',
      message: ''
    }, {
        wrapperClass: 'modal-main-class in',
        closeOnClickOutside: true
      })
      .subscribe((isConfirmed) => {
        // We get dialog result
        if (isConfirmed) {

        } else if (isConfirmed === false) {
          setTimeout(() => { this.openLoginWindow(); }, 200);
        } else if (isConfirmed === undefined) {

        }
      });
    // We can close dialog calling disposable.unsubscribe();
    // If dialog was not closed manually close it by timeout
    // setTimeout(() => {
    //     disposable.unsubscribe();
    // }, 10000);
  } */

  getPrivacyPolicyLink() {
    this.utilsService.getPartnerDetails$().subscribe(response => {
      const menu = response.body.site.sideMenu;
      this.privacylink = find(menu, link => {
        if (link.label === 'Privacy Policy') {
          return link;
        }
        return false;
      });
    });
  }

  getImage(imageName) {
    return this.utilsService.buildImageUrl(imageName);
  }
}
