import { Component, OnInit, Input, Output, EventEmitter, AfterViewChecked } from '@angular/core';
import { ClaimsService } from '../../../core/services/claims.service';

@Component({
  selector: 'hos-my-account-new-claims',
  templateUrl: './my-account-new-claims.component.html',
  styleUrls: ['./my-account-new-claims.component.scss'],
})
export class MyAccountNewClaimsComponent implements OnInit, AfterViewChecked {
  claims: any;

  @Input() isDesktopView: any;

  @Input() enrollmentId: any;

  @Output() claimsLineEmitter = new EventEmitter();

  dataRetrieved: any;

  @Input() selectedAddIndex: any;

  constructor(private claimsService: ClaimsService) {}

  ngOnInit() {
    this.getClaimsData();
  }

  ngAfterViewChecked() {
    const isDivRendered: any = document.getElementById(`${this.selectedAddIndex}-claims-list`);
    if (this.dataRetrieved && ![undefined, 'undefined', null, 'null', ''].includes(isDivRendered)) {
      this.dataRetrieved = false;
      this.claimsLineEmitter.emit(this.claims);
    }
  }

  getClaimsData() {
    if (this.enrollmentId !== undefined) {
      this.claimsService.getClaimsByEnrollmentId(this.enrollmentId).subscribe(
        claimsList => {
          const claimsResponse = claimsList.body.data;
          this.claims = claimsResponse.filter(claim => claim.HOSstatus !== 'CRM Failure' && claim.HOSstatus !== 'Initialize');
          this.dataRetrieved = true;
        },
        error => {
          console.log(error);
        },
      );
    }
  }
}
