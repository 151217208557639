import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { SimpleModalComponent, SimpleModalService } from 'ngx-simple-modal';
import { environment } from '../../../../environments/environment';
import { CartService } from '../../services/cart.service';
import { UtilsService } from '../../../core/services/utils.service';

export interface AddBundleModal {
  data: any;
}

@Component({
  selector: 'hos-bundle-modal',
  templateUrl: './bundle-modal.component.html',
  styleUrls: ['./bundle-modal.component.scss'],
})
export class BundleModalComponent extends SimpleModalComponent<AddBundleModal, any> implements AddBundleModal, OnInit {
  data: any;

  removeddata: any;

  upgradedWebProgramName: string;

  upgradedWebProgramIconUrl: string;

  baseUrl: string;

  bundleData: any;

  productsInfo: any = [];

  constructor(
    simpleModalService: SimpleModalService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private utilsService: UtilsService,
    private cartService: CartService,
  ) {
    super();
  }

  ngOnInit() {
    this.baseUrl = `${environment.Image_API_Endpoint}${environment.Product_service_API_Endpoint}`;
    this.bundleData = this.data.data;
    const productIds = [];
    if (this.bundleData.removedProducts) {
      this.bundleData.removedProducts.forEach(productId => {
        productIds.push(this.cartService.getItemDetails(productId.marketingCode));
      });
    }
    this.getProductData(productIds, productsData => {
      this.productsInfo = productsData;
    });
    this.getUpgradedBundleProduct();
  }

  getUpgradedBundleProduct() {
    for (let i = 0; i < this.data.data.cartData.length; i++) {
      if (this.data.data.cartData[i].productId === this.data.data.upgradedProductId) {
        this.upgradedWebProgramName = this.data.data.cartData[i].details.webProgramName;
        this.upgradedWebProgramIconUrl = this.data.data.cartData[i].details.programIconUrl;
        break;
      }
    }
  }

  getProductData(productIds, done: any) {
    forkJoin(productIds).subscribe(productsDataRes => {
      const productsData: any = [];
      (productsDataRes as any).forEach(ele => {
        productsData.push(ele.body);
      });
      done(productsData);
    });
  }

  confirmHandler() {
    this.result = 'confirmed';
    this.close();
  }

  getImage(imageName) {
    return this.utilsService.buildImageUrl(imageName);
  }
}
