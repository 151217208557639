<!-- <hos-breadcrumb [config]="breadcrumsConfig"></hos-breadcrumb> -->
<div class="cart-banner">
  <span class="page-wrap row">
    <div class="col-12 col-md-9">
      <h3 class="cart-title">My Cart</h3>
    </div>
    <div class="col-12 col-md-3 need-help-block desktop-only">
      <div class="help-title">NEED HELP?</div>
    </div>
  </span>
</div>
<section class="cart-list-box content-section shaded-white">
  <article class="page-wrap row">
    <!-- <div class="col-12 col-md-12 mb-4">
                <h3>
                    <b>My Cart</b>
                </h3>
            </div> -->
    <div class="cart-products col-12 col-lg-9">
      <div class="remove-error mb-2" *ngIf="deletedCartData">
        <span class="fa fa-info-circle"></span>
        <span class="error-message"> {{ deletedCartData }} was removed from your cart as it is not available for this address.</span>
      </div>
      <div class="card custom-cart-card">
        <div class="card-header light-text">
          Product(s)
          <span class="pull-right set-margin cost-static">Cost</span>
        </div>
        <div class="no-data-card-body" *ngIf="cartProducts.length === 0">Please add product/s to proceed.</div>
        <div class="card-body-box product-list">
          <div class="card-body row no-gutters" *ngFor="let product of cartProducts">
            <div class="text-left media-icon col-md-1 col-lg-1 col-2">
              <img
                [alt]="product?.details?.webProgramName"
                class="cart-image"
                [src]="product?.details?.programIconUrl ? getImage(productServiceBaseUrl + product.details.programIconUrl) : ''"
              />
            </div>
            <div class="col-7 col-md-8 col-xl-7">
              <h5 class="product-name">
                <span>{{ product?.details?.webProgramName }}</span>
              </h5>
              <h6 *ngIf="selectedAddress">
                <small> <b>Zipcode :</b> {{ product.zipcode }}</small>
              </h6>
              <div class="text-left mobile-only">
                <button type="button" class="btn btn-link remove-link navigate-link" (click)="removeProduct(product)">Remove</button>
              </div>
            </div>
            <div class="col-4 col-md-3 col-xl-4 row detail-txt-cost">
              <div class="col-5 text-right desktop-only">
                <button type="button" class="btn btn-link non-href-links btn-sm navigate-link col-9" (click)="removeProduct(product)">Remove</button>
              </div>
              <div class="col-12 col-md-12 col-xl-7 text-right">
                <h4 class="cost-txt">
                  <span>${{ product.details.monthlyPrice | slice : 0 : -2 }}</span>
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body row no-gutters sub-total-box">
          <div class="col-9 col-md-9 col-xl-8 total">
            <div class="static-text">
              <span>Represents recurring monthly charges. <br class="displayMobile" />All amounts are in US dollars.</span>
            </div>
          </div>
          <div class="col-3 col-md-3 col-xl-4 paddTM7 padd0 row total-txt-box">
            <div class="col-12 col-md-12 col-xl-5 padd0 text-right font-16 align-subtotal">Subtotal</div>
            <div class="col-12 col-md-12 col-xl-7 paddTM5 padd0 text-right align-value">
              <h4 class="amtText">${{ grossTotal }}</h4>
            </div>
          </div>
        </div>

        <div class="mt-2 set-middle">
          <button
            (click)="goToProducts()"
            *ngIf="condotionToShowAddMoreServiceLink"
            class="p-none btn btn-link navigate-link col-md-12 col-lg-2 text-left add-more-link"
          >
            ADD MORE SERVICES>
          </button>
          <button class="btn nav-btn pull-right checkout-btn mb-3" *ngIf="cartProducts.length > 0" (click)="placeOrder()">CHECKOUT</button>
        </div>

        <!--    <p class="light-text">
                        <br/>For more information review our
                        <a class="non-href-links" routerLink="/terms">Refund Policy</a> |
                        <a class="non-href-links" [routerLink]="'/page/privacy-policy/' + privacylink?.entryId">Privacy Policy</a> | or call customer service
                        <a class="text-info">123-456-7890</a>
                        <br/>
                    </p>
                    <a (click)="goToProducts()" *ngIf="condotionToShowAddMoreServiceLink" class="mt-4 non-href-links"> Add More Services ></a>
                -->
      </div>
    </div>
    <div class="col-12 col-md-3 need-help-content desktop-only">
      <div class="divider font-18">
        <span>Call customer service</span>
        <br />
        <b>
          <a href="tel:1.844.427.4687">1.844.427.4687</a>
        </b>
      </div>
      <div class="divider">
        <span>Or review our</span>
        <br />
        <div class="font-14 navigate-link mt-2">
          <a routerLink="/terms" class="navigate-link">REFUND POLICY</a>
        </div>
        <div class="font-14 navigate-link mt-2">
          <a [routerLink]="'/page/privacy-policy/' + privacylink?.entryId" class="navigate-link">PRIVACY POLICY</a>
        </div>
      </div>
      <div class="mt-3 text-center secure-payment">
        <span>Secure Payments </span>
        <img src="../../../../img/SSL-logo.svg" alt="SSL Transaction" />
      </div>
    </div>
  </article>

  <!-- Other services on successful order -->
  <section class="page-wrap" *ngIf="otherProducts && otherProducts.length !== 0">
    <article class="mt-5 page-wrap">
      <div class="col-12">
        <h2 class="section-head-title">
          <span>Other Services Recommended for your Property </span>
        </h2>
      </div>
    </article>
    <article class="page-wrap row set-border">
      <ngu-carousel [inputs]="carouselTile" [dataSource]="otherProducts" class="category-tile col-12">
        <ngu-tile *nguCarouselDef="let product of otherProducts; let i = index" class="item">
          <!-- <hos-product-card [index]="i" [locData]="locData" [product]="product" [zipcode]="zipcode" [locationId]="locationId" [shareIconIndexArray]="shareIconIndexArray"></hos-product-card> -->
        </ngu-tile>
        <span
          NguCarouselPrev
          class="cat-slide-icon left-cat-icon fa fa-chevron-left"
          [ngClass]="{ 'hide-slider': otherProducts?.length <= 4 }"
        ></span>
        <span
          NguCarouselNext
          class="cat-slide-icon right-cat-icon fa fa-chevron-right"
          [ngClass]="{ 'hide-slider': otherProducts?.length <= 4 }"
        ></span>
      </ngu-carousel>
      <div class="col-12 col-sm-12 col-md-12 mb-5 text-center" *ngIf="otherProducts?.length === 0">No other services available.</div>
    </article>
  </section>
  <div class="mobile-only page-wrap mb-5">
    <div class="col-12 col-md-12 col-xl-3 need-help-block">
      <div class="help-title">NEED HELP?</div>
    </div>
    <div class="col-12 col-md-12 col-xl-3 need-help-content">
      <div class="divider font-18">
        <span>Call customer service</span>
        <br />
        <b>
          <a href="tel:{{ customerServiceNumber }}">{{ customerServiceNumber }}</a>
        </b>
      </div>
      <div class="divider">
        <span>or review our</span>
        <br />
        <div class="font-14 navigate-link mt-2">
          <a routerLink="/terms" class="navigate-link">REFUND POLICY</a>
        </div>
        <div class="font-14 navigate-link mt-2">
          <a [routerLink]="'/page/privacy-policy/' + privacylink?.entryId" class="navigate-link">PRIVACY POLICY</a>
        </div>
      </div>
      <div class="mt-3 text-center secure-payment">
        <span>Secure Payments </span>
        <img src="../../../../img/SSL-logo.svg" alt="SSL Transaction" />
      </div>
    </div>
  </div>
</section>

<div [ngClass]="{ loading: showLoader }"></div>
