import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SimpleModalComponent, SimpleModalService } from 'ngx-simple-modal';

export interface SurgeModalModel {
  cancelText?: string;
  confirmText?: string;
}

@Component({
  selector: 'hos-surge-modal',
  templateUrl: './surge-modal.component.html',
  styleUrls: ['./surge-modal.component.scss'],
})
export class SurgeModalComponent extends SimpleModalComponent<SurgeModalModel, any> implements SurgeModalModel, OnInit {
  confirmText: string;

  cancelText: string;

  surgeData: any;

  contractorCheckbox_yes: boolean;

  contractorCheckbox_no: boolean;

  contractorCheckboxReq = false;

  surgeComment = '';

  enrollSurge = false;

  enrollClicked = false;

  showLoader = false;

  charRemaining = 900;

  constructor(simpleModalService: SimpleModalService, private httpClient: HttpClient) {
    super();
  }

  ngOnInit() {
    this.httpClient.get('assets/productQuestionnaire.json').subscribe(
      (data: any) => {
        this.surgeData = data.surgeConfig;
      },
      () => {},
    );
  }

  isEligibleForSurge() {
    this.enrollSurge = true;
    this.enrollClicked = true;
    if (
      typeof this.contractorCheckbox_yes === 'undefined' ||
      typeof this.contractorCheckbox_no === 'undefined' ||
      (!this.contractorCheckbox_yes && !this.contractorCheckbox_no)
    ) {
      this.enrollSurge = false;
      return;
    }
    for (let i = 0; i < this.surgeData.length; i++) {
      for (let j = 0; j < this.surgeData[i].questions.length; j++) {
        if (this.surgeData[i].questions[j].answer.indexOf(this.surgeData[i].questions[j].checked) === -1) {
          this.enrollSurge = false;
          break;
        }
      }
      if (!this.enrollSurge) {
        break;
      }
    }
  }

  confirmHandler() {
    this.isEligibleForSurge();
    if (this.enrollSurge) {
      this.showLoader = true;
      setTimeout(() => {
        if (this.contractorCheckbox_yes) {
          if (this.surgeComment.trim().length) {
            this.result = `Please call customer to notify contractor visit. ${this.surgeComment.trim()}`;
          } else {
            this.result = 'Please call customer to notify contractor visit.';
          }
        } else if (this.surgeComment.trim().length) {
          this.result = this.surgeComment.trim();
        } else {
          this.result = true;
        }
        this.close();
        this.showLoader = false;
      }, 3000);
    }
  }

  countChar() {
    this.charRemaining = 900 - this.surgeComment.length;
  }

  contractorCheckbox(value?) {
    if (value === 'yes') {
      this.contractorCheckbox_no = false;
      this.contractorCheckboxReq = false;
    } else {
      this.contractorCheckbox_yes = false;
      this.contractorCheckboxReq = false;
    }
    if (this.enrollClicked) {
      this.isEligibleForSurge();
    }
  }
}
