// /* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-empty */
import { Inject, Optional, PLATFORM_ID, Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SimpleModalService } from 'ngx-simple-modal';
import { Location } from '@angular/common';
import { Subject, Observable } from 'rxjs';
import { LocationService } from '../../../../home/services/location.service';
import { SessionStorageService } from '../../../../core/services/session-storage.service';
import { environment } from '../../../../../environments/environment';
import { ProductService } from '../../../services/product.service';
import { UtilsService } from '../../../../core/services/utils.service';
import { UserService } from '../../../../user/services/user.service';
import { AlertConfirmModalComponent } from '../../../../core/component/alert-confirm-modal/alert-confirm-modal.component';
import { CustomerPropertySelectionModalComponent } from '../../../../user/component/customer-property-selection-modal/customer-property-selection-modal.component';
import { ErrorModalComponent } from '../../../../core/component/error-modal/error-modal.component';
import { CartService } from '../../../../cart/services/cart.service';
import { BundleModalComponent } from '../../../../cart/component/bundle-modal/bundle-modal.component';
import { SurgeModalComponent } from '../../../../core/component/surge-modal/surge-modal.component';
import { UpsellServiceDetailComponent } from '../../upsell-service-detail/upsell-service-detail.component';
import { WindowScrolling } from '../../../../core/services/window-scrolling.service';
import { GlobalVariables } from '../../../../core/services/global-variables.service';

import { SiteContentService } from '../../../../core/services/siteContents';
import { CartQuickCheckoutService } from '../../../../cart/services/cart-quick-checkout.service';
import { UpgradeServiceModalComponent } from '../../../../user/component/checkout/upgrade-service-modal/upgrade-service-modal.component';

@Injectable()
export class ProductCardSwitcherComponent {
  micrositeNames: any;

  customer_service_number: String;

  constructor(
    private router: Router,
    private sessionStorageService: SessionStorageService,
    private productService: ProductService,
    private locationService: LocationService,
    private utilsService: UtilsService,
    private userService: UserService,
    private simpleModalService: SimpleModalService,
    private route: ActivatedRoute,
    private cartService: CartService,
    private globalVariables: GlobalVariables,
    private windowScrolling: WindowScrolling,
    private location: Location,
    private siteContentService: SiteContentService,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Optional() @Inject('serverUrl') protected origin: string,
    @Optional() @Inject('pathname') protected pathname: string,
    private cartQuickCheckoutService: CartQuickCheckoutService,
  ) {
    this.micrositeNames = this.sessionStorageService.getItem('micrositeName')
      ? JSON.parse(this.sessionStorageService.getItem('micrositeName'))
      : environment.micrositeNames;
    this.customer_service_number = environment.customer_service_number;
  }

  getImage(imageName) {
    return this.utilsService.buildImageUrl(imageName);
  }

  getProductCardImage(product, compData) {
    const imageUrl = product.defaultProgramCardImgUrl
      ? product.defaultProgramCardImgUrl.startsWith('programDetailsBgImage/Default')
        ? `../../../../img/${product.defaultProgramCardImgUrl}`
        : compData.baseUrl + product.defaultProgramCardImgUrl
      : `../../../../img/${product.defaultProgramCardImgUrl}`;
    return this.utilsService.buildImageUrl(imageUrl);
  }

  public addProductToCart(product: any, componentData?): void {
    componentData.userDetails = this.userService.getSignedInUser();
    if (!componentData.userDetails && product.contactType !== 3) {
      this.addCheckoutItemToCart(product, componentData);
      return;
    }
    componentData.productData = product;
    if (componentData.productData.isUpgrade === true) {
      this.simpleModalService.removeAll();
    }
    componentData.userDetails = this.userService.getSignedInUser();
    if (componentData.userDetails) {
      this.getCustomerAddress(componentData);
    } else {
      this.sessionStorageService.setItem('directPayment', '1');
      this.openLoginWindow(product);
    }
  }

  getCustomerAddress(componentData?) {
    componentData.loader.emit(true);
    componentData.showLoader = true;
    componentData.userDetails = this.userService.getSignedInUser();
    this.userService.getAllCustomerAddresses(componentData.userDetails.user._id).subscribe(data => {
      componentData.showLoader = false;
      componentData.loader.emit(false);
      const serviceAddresses = data.body;
      const selectedAddress = JSON.parse(this.sessionStorageService.getItem('selectedAddress'));
      const selectedZipcode = selectedAddress.zipcode ? selectedAddress.zipcode : componentData.zipcode;
      const serviceAddressesUnderZipcode = serviceAddresses.filter(
        y => y.serviceAddress.zipcode === selectedZipcode && y.customerNo !== '' && y.customerNo !== undefined,
      );
      serviceAddressesUnderZipcode.forEach(element => {
        element.showAddress = `${element.serviceAddress.streetNumber} ${element.serviceAddress.streetName}, ${element.serviceAddress.city}
, ${element.serviceAddress.state}, ${element.serviceAddress.zipcode}`;
      });
      componentData.selectedServiceAddressesUnderZipcode = serviceAddressesUnderZipcode;
      if (serviceAddressesUnderZipcode.length > 0) {
        if (componentData.productData.program.programId === environment.surge_program_id) {
          this.simpleModalService
            .addModal(
              SurgeModalComponent,
              {
                confirmText: 'Enroll Now',
              },
              {
                wrapperClass: 'modal-main-class in',
                closeOnClickOutside: false,
              },
            )
            .subscribe(isConfirmed => {
              if (isConfirmed) {
                if (typeof isConfirmed === 'boolean') {
                  this.addProductToCartProcessing(serviceAddressesUnderZipcode, componentData);
                } else {
                  this.addProductToCartProcessing(serviceAddressesUnderZipcode, componentData, isConfirmed);
                }
              }
            });
        } else if (componentData.productData.program.programCode === 'LDDP') {
          componentData.leakFrequencyVal = 'Upfront';
        } else {
          this.addProductToCartProcessing(serviceAddressesUnderZipcode, componentData);
        }
      } else {
        this.simpleModalService
          .addModal(
            AlertConfirmModalComponent,
            {
              title: `The service you selected for zip code
          ${selectedZipcode} does not match with your existing property zip code(s).`,
              message: 'Would you like to add a new property?',
              alertIcon: 'fa fa-exclamation-circle red',
              cancelText: 'No',
              confirmText: 'Yes',
            },
            {
              wrapperClass: 'modal-main-class in',
              closeOnClickOutside: false,
            },
          )
          .subscribe(isConfirmed => {
            if (isConfirmed) {
              this.globalVariables.isPropNotExist = true;
              this.sessionStorageService.setItem('isRetainEnrollClicked', 'false');
              this.router.navigate(['/user/add-property'], {
                queryParams: {
                  zipcode: selectedZipcode,
                },
              });
            }
          });
      }
    });
  }

  openLoginWindow(product?: any) {
    const hashValue: any = ![undefined, 'undefined', null, 'null', ''].includes(window.location.hash) ? window.location.hash : '';
    let urlOperation: any = window.location.pathname.split('/');
    urlOperation = urlOperation.filter(item => !this.micrositeNames.includes(item) && item !== '');
    const prevUrl = urlOperation.join('/');
    /* Storing product details */
    if (product && Object.keys(product).length > 0) {
      this.globalVariables.nonLoggedInProductListingDetails = product;
    }
    this.router.navigate(['/user/login'], {
      queryParams: {
        referer: encodeURIComponent(prevUrl + hashValue),
      },
    });
  }

  public addProductToCartProcessing(serviceAddresses: any, componentData?, description?: string) {
    const { productData } = componentData;
    productData.isBundle = componentData.productData.program.products.length > 1;
    productData.status = 'PENDING_PAYMENT';
    productData.provider = 'AWR';
    productData.locationId = componentData.locationId || productData.defaultLocationCodeId;
    productData.zipcode = componentData.zipcode;
    /** WaterBillOnOff undefined Issue  */
    this.globalVariables.waterBillOnOff = serviceAddresses[0].waterBillOnOff;
    if (this.globalVariables.selected_Customer_number) {
      this.globalVariables.waterBillOnOff = serviceAddresses.filter(
        item => item.customerNo === this.globalVariables.selected_Customer_number,
      )[0].waterBillOnOff;
    }
    if (componentData.productData.isUpgrade === true) {
      productData.isUpgrade = true;
      const enrolledProductData: any = this.sessionStorageService.getItem('enrolledProductData');
      const checkIfEnrollCancel: any = JSON.parse(enrolledProductData);
      const originalEnrollmentGuid: any = [];
      checkIfEnrollCancel.forEach(data => {
        if (data.crmStatus === '4') {
        } else if (componentData.productData.enrollmentGuid.includes(data.crmEnrollmentGuid)) {
          originalEnrollmentGuid.push(data.crmEnrollmentGuid);
        }
      });
      productData.enrollmentGuid = originalEnrollmentGuid;
    } else {
      productData.isUpgrade = false;
    }
    if (typeof description !== 'undefined') {
      productData.description = description;
    }
    if (productData.program.programId.includes(componentData.specialProduct)) {
      productData.leakFrequencyVal = componentData.leakFrequencyVal;
    }
    // option to select property
    // dont show option in case of upgrade
    if (serviceAddresses.length > 1) {
      if (componentData.productData.isUpgrade === true) {
        productData.isUpgrade = true;
        productData.customerNo = componentData.productData.customerNo;
        productData.enrollmentGuid = componentData.productData.enrollmentGuid;
        this.addItemToCartApiCall(productData, serviceAddresses, componentData);
      } else {
        this.simpleModalService
          .addModal(
            CustomerPropertySelectionModalComponent,
            {
              title: 'Protection Services Enrollment',
              message: 'Please select a property you would like to apply coverage',
              alertIcon: 'fa fa-info-circle red',
              confirmText: 'Ok',
              data: serviceAddresses,
            },
            {
              wrapperClass: 'modal-main-class in',
              closeOnClickOutside: true,
            },
          )
          .subscribe(isConfirmed => {
            this.windowScrolling.enable();
            if (isConfirmed) {
              this.globalVariables.waterBillOnOff = serviceAddresses.filter(item => item.customerNo === isConfirmed)[0].waterBillOnOff;
              productData.customerNo = isConfirmed;
              productData.isUpgrade = false;
              this.addItemToCartApiCall(productData, serviceAddresses, componentData);
            }
          });
      }
    } else if (serviceAddresses.length === 1) {
      productData.customerNo = serviceAddresses[0].customerNo;
      this.addItemToCartApiCall(productData, serviceAddresses, componentData);
    }
  }

  addItemToCartApiCall(productData, serviceAddresses?, componentData?) {
    const locData = productData.defaultLocationCodeId || componentData.locationId;
    const selAddCustDetails = serviceAddresses.filter(item => item.customerNo === productData.customerNo);
    if (!productData.isUpgrade && locData && selAddCustDetails && selAddCustDetails[0].serviceAddress.locationCodeId !== locData) {
      const gaTrackId = 'do_not_offer';
      this.simpleModalService.addModal(
        ErrorModalComponent,
        {
          title: 'We are sorry. We do not currently offer this service in your location.',
          message: '',
          showButton: 'close',
          alertType: 'info-type',
          confirmBtnId: gaTrackId || 'close',
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: true,
        },
      );
      componentData.showLoader = false;
      return;
    }
    componentData.showLoader = true;
    componentData.loader.emit(true);
    /** HWR-2386: disallow Residential customer to enroll in Commercial programs and vice versa *** */
    const productContactType = environment.types.filter(item => item.id === productData.contactType);
    const serviceAddressContactType = componentData.selectedServiceAddressesUnderZipcode
      .filter(data => data.customerNo === productData.customerNo)
      .map(item => item.serviceAddress.contactType);
    if (serviceAddressContactType[0] !== productContactType[0].type.toUpperCase() && serviceAddressContactType[0] === 'RESIDENTIAL') {
      this.showErrorModal(
        '',
        `We're sorry. We have your account identified as a Residential property and cannot apply Commercial protection plans to this account. For more information on the difference between residential and commercial protection plans please call <b>${this.globalVariables.territoryCustomerServiceNumber}</b>.`,
      );
      componentData.showLoader = false;
      componentData.loader.emit(false);
      return;
    }
    if (serviceAddressContactType[0] !== productContactType[0].type.toUpperCase() && serviceAddressContactType[0] === 'COMMERCIAL') {
      this.showErrorModal(
        '',
        `We're sorry. We have your account identified as a Commercial property and cannot apply Residential protection plans to this account. For more information on the difference between residential and commercial protection plans please call <b>${this.globalVariables.territoryCustomerServiceNumber}</b>.`,
      );
      componentData.showLoader = false;
      componentData.loader.emit(false);
      return;
    }
    /** HWR-2386 *** */

    const isonbillreturn = this.checkIfOnBill(productData, componentData);
    if (isonbillreturn) {
      /** HWR-2500: stop user to add product in card */
      return false;
    }
    this.cartService.addItem(productData, '', (err, data, status) => {
      this.globalVariables.nonLoggedInProductListingDetails = {};
      this.sessionStorageService.setItem('isRetainEnrollClicked', 'false');
      this.cartService.getCustomerCartToLocal(componentData.userDetails.user._id, '', () => {
        const cartData = this.cartService.get();
        cartData.subscribe(cart => {
          this.globalVariables.cartAmount.emit(cart);
        });
      });
      if (err) {
        if (data.status === 409) {
          if (data.error.data && data.error.data.bundleData && data.error.responseDesc === 'Bundle is possible') {
            if (data.error.data.bundleData.length > 0) {
              const { newCartItemPayload } = data;
              const payload = [];
              const enrollments = data.error.data.existingEnrollments;
              const newCartItem = data.error.data.newProgramInCart;
              const { bundleData } = data.error.data;
              if (newCartItemPayload.productId === componentData.productData._id) {
                /** To get the product details in the payload */
                newCartItemPayload.details = componentData.productData;
              }
              payload.push(newCartItemPayload); /** To get the cart payload */
              this.showUpgradePopupModal(UpgradeServiceModalComponent, enrollments, newCartItem, payload, bundleData, componentData, result => {
                if (result) {
                  const updatedPayload = result.payload[0];
                  this.addUpdatedUpgradeItemToCart(updatedPayload, componentData);
                }
              });
            } else {
              this.showErrorModal(
                "We're Sorry",
                `You are currently protected with a similar product.
                If you wish to upgrade or change your protection coverage, please contact Customer Service at ${this.customer_service_number}.`,
                'Ok',
              );
            }
          } else if (data.error.errPopupMessage) {
            this.showErrorModal(data.error.errPopupTitle, data.error.errPopupMessage, 'Ok', data.error.gaEventLabel);
          } else {
            this.showErrorModal(
              'Upgrade to Bundle',
              `If you are looking to bundle your existing services,
              please visit your My Services page and view the list of opportunities from your current services.`,
              'Ok',
            );
          }
        } else if (data.status === 400) {
          this.showErrorModal(
            'Upgrade to Bundle',
            `If you are looking to bundle your existing services,
        please visit your My Services page and view the list of opportunities from your current services.`,
            'Ok',
          );
        } else {
          this.showErrorModal('Error', 'Error while adding item to cart');
        }
      } else if (status === 201) {
        this.openBundle(data);
      } else if (!!data && data.length > 0) {
        this.sessionStorageService.setItemToloclaStorage('deletedCartData', JSON.stringify(data));
      } else if (componentData.productData.upsell !== undefined && componentData.productData.upsell === false) {
        this.router.navigate(['/payment'], {
          queryParams: {
            section: 'cart',
          },
        });
      } else {
        this.simpleModalService
          .addModal(
            UpsellServiceDetailComponent,
            {
              service: productData,
              locationCodeData: componentData.locationCodeData,
            },
            {
              wrapperClass: 'modal-main-class in',
              closeOnClickOutside: true,
            },
          )
          .subscribe((isConfirmed: any) => {
            this.windowScrolling.enable();
            if (isConfirmed) {
              componentData.showLoader = true;
              componentData.loader.emit(true);
              this.cartService.removeItem(isConfirmed.removeProduct, () => {
                if (err) {
                  componentData.showLoader = false;
                  componentData.loader.emit(false);
                  this.showErrorModal('Error', 'Error removing item from cart');
                } else {
                  delete isConfirmed.removeProduct;

                  const upsellproductData = isConfirmed;
                  upsellproductData.isBundle = upsellproductData.program.products.length > 1;
                  upsellproductData.status = 'PENDING_PAYMENT';
                  upsellproductData.provider = 'AWR';
                  upsellproductData.locationId = componentData.locationId;
                  upsellproductData.zipcode = componentData.zipcode;
                  this.cartService.addItem(upsellproductData, '', () => {
                    componentData.showLoader = false;
                    componentData.loader.emit(false);
                    if (err) {
                      if (data.status === 409) {
                        this.showErrorModal(
                          'Upgrade to Bundle',
                          `If you are looking to bundle your existing services,
                      please visit your My Services page and view the list of opportunities from your current services.`,
                          'Ok',
                        );
                      } else if (data.status === 400) {
                        this.showErrorModal(
                          'Upgrade to Bundle',
                          `If you are looking to bundle your existing services,
                      please visit your My Services page and view the list of opportunities from your current services.`,
                          'Ok',
                        );
                      } else {
                        this.showErrorModal('Error', 'Error while adding item to cart');
                      }
                    } else {
                      this.router.navigate(['/payment'], {
                        queryParams: {
                          section: 'cart',
                        },
                      });
                    }
                  });
                }
              });
            }
          });
      }
      componentData.showLoader = false;
      componentData.loader.emit(false);
    });
  }

  showErrorModal(title: string, message: string, confirmationMsg?: string, gaTrackBtnId?: string) {
    this.simpleModalService
      .addModal(
        ErrorModalComponent,
        {
          title,
          message,
          confirmationMsg,
          confirmBtnId: gaTrackBtnId || 'close',
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: true,
        },
      )
      .subscribe(isConfirmed => {
        if (isConfirmed) {
        } else if (isConfirmed === false) {
        } else if (isConfirmed === undefined) {
        }
      });
  }

  openBundle(data: any) {
    this.simpleModalService
      .addModal(
        BundleModalComponent,
        {
          data,
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: true,
        },
      )
      .subscribe(isConfirmed => {
        if (isConfirmed) {
          this.router.navigate(['/payment'], {
            queryParams: {
              section: 'cart',
            },
          });
        }
      });
  }

  checkIfOnBill(productData, componentData?) {
    /** HWR-2500: New method | This method return boolean value after checking water bill is on/off */
    componentData.showLoader = false;
    let contactNumber = environment.onbillCallNumbers.default;
    if (this.globalVariables.waterBillOnOff === false) {
      const stateVal: any = componentData.allStates.filter(stateData => stateData.key === componentData.locationCodeData.serviceState);
      if (stateVal && environment.onbillCallNumbers[stateVal[0].value]) {
        contactNumber = environment.onbillCallNumbers[stateVal[0].value];
      }
      this.simpleModalService
        .addModal(
          AlertConfirmModalComponent,
          {
            title: '',
            message: `There has been an issue with adding your program to your water bill. Please call <b>${contactNumber}</b> to verify your water bill status.`,
            alertIcon: 'fa fa-info-circle red',
            confirmText: 'OK',
          },
          {
            wrapperClass: 'modal-main-class in',
            closeOnClickOutside: true,
          },
        )
        .subscribe(() => {
          componentData.showLoader = false;
          componentData.loader.emit(false);
        });
      return true;
    }
    return false;
  }

  public getMicrositeContentApi(data): Promise<any> {
    return this.locationService.getMicroSiteForLocation(data).toPromise();
  }

  public getAllCustomerAddressesApi(userDetails): Promise<any> {
    return this.userService.getAllCustomerAddresses(userDetails.user._id).toPromise();
  }

  showCheckoutPage() {
    if (this.router.url.indexOf('checkout') > -1) {
      window.location.reload();
    } else {
      this.router.navigate(['/user/checkout']);
    }
  }

  addCheckoutItemToCart(productData: any, componentData?) {
    let { programId } = productData.program;
    if (productData.coverageType === 'UNLIMITED') {
      programId = `unlimited-${productData.program.programId}`;
    }
    const locationId = componentData.locationId || productData.defaultLocationCodeId;
    const checkoutCartData: any = this.sessionStorageService.getItem('cartCheckout')
      ? JSON.parse(this.sessionStorageService.getItem('cartCheckout'))
      : [];
    let cartValue = [];
    if (checkoutCartData.items) {
      cartValue = checkoutCartData.items.map(item => {
        let { programIdData } = item.details.program;
        if (item.details.coverageType === 'UNLIMITED') {
          programIdData = `unlimited-${item.details.program.programId}`;
        }
        return programIdData;
      });
    }

    this.productService.getUpsellPopupData(programId, locationId, cartValue).subscribe(
      data => {
        const popup = data.body.result;
        if (data.status === 200) {
          this.addToCart(productData, popup, componentData);
        }
      },
      error => {
        console.log(error);
      },
    );
  }

  addToCart(productData: any, popup, componentData?) {
    productData.isBundle = productData.program.products.length > 1;
    productData.status = 'PENDING_PAYMENT';
    productData.provider = 'AWR';
    productData.locationId = componentData.locationId || productData.defaultLocationCodeId;
    productData.zipcode = componentData.zipcode;
    const checkoutCartData: any = this.sessionStorageService.getItem('cartCheckout')
      ? JSON.parse(this.sessionStorageService.getItem('cartCheckout'))
      : [];
    const locationCodeData = this.sessionStorageService.getItem('location_code')
      ? this.sessionStorageService.getItem('location_code')
      : componentData.locationId;
    if (
      (componentData.zipcode &&
        checkoutCartData &&
        checkoutCartData.items &&
        checkoutCartData.items.length > 0 &&
        componentData.zipcode !== checkoutCartData.items[0].zipcode) ||
      (locationCodeData &&
        checkoutCartData &&
        checkoutCartData.items &&
        checkoutCartData.items.length > 0 &&
        locationCodeData !== checkoutCartData.items[0].locationId)
    ) {
      checkoutCartData.items = [];
      this.sessionStorageService.setItem('cartCheckout', JSON.stringify(checkoutCartData));
      this.globalVariables.cartCheckout = JSON.parse(this.sessionStorageService.getItem('cartCheckout'));
    }
    this.cartQuickCheckoutService.addItem(productData, (err, data, status) => {
      this.sessionStorageService.setItem('isRetainEnrollClicked', 'false');
      if (err) {
        if (data.status === 409 && !data.isAlreadyBundle) {
          const gaTrackBtnId = 'existing_program_in_cart';
          this.showErrorModal("We're Sorry", 'This program has already been added to your cart.', 'Ok', gaTrackBtnId);
        } else if (data.status === 409 && data.isAlreadyBundle) {
          const gaTrackBtnId = 'existing_bundle_in_cart';
          this.showErrorModal("We're Sorry", 'This program is included in a bundle that has already been added to your cart.', 'Ok', gaTrackBtnId);
        } else if (data.status === 400) {
          this.showErrorModal(
            'Upgrade to Bundle',
            `If you are looking to bundle your existing services,
          please visit your My Services page and view the list of opportunities from your current services.`,
            'Ok',
          );
        } else {
          this.showErrorModal('Error', 'Error while adding item to cart');
        }
      } else if (status === 201) {
        this.openBundle(data);
      } else if (!!data && data.length > 0) {
        this.sessionStorageService.setItemToloclaStorage('deletedCartDataCheckout', JSON.stringify(data));
      } else {
        if (popup.length <= 0) {
          this.showCheckoutPage();
        }
        if (popup.length > 0) {
          this.simpleModalService
            .addModal(
              UpsellServiceDetailComponent,
              {
                service: popup,
                locationCodeData: componentData.locationCodeData,
                isFromCheckout: true,
              },
              {
                wrapperClass: 'modal-main-class in',
                closeOnClickOutside: true,
              },
            )
            .subscribe((isConfirmed: any) => {
              this.windowScrolling.enable();
              if (isConfirmed) {
                productData.productId = productData._id;
                delete isConfirmed.removeProduct;
                this.cartQuickCheckoutService.removeItem(productData, () => {
                  popup = [];
                  this.addToCart(isConfirmed, popup, componentData);
                });
              }
            });
          popup = [];
        }
      }
      componentData.showLoader = false;
      componentData.loader.emit(false);
    });
  }

  getLocationsContentAPI(locationId): Observable<any> {
    const subject = new Subject<any>();
    this.locationService.getLocationsContent(locationId).subscribe(
      data => {
        subject.next(data);
      },
      () => {
        subject.next([]);
      },
    );
    return subject.asObservable();
  }

  showUpgradePopupModal(
    modalComponent: any,
    enrolledProducts: any,
    cartItems: any,
    cartPayload: any,
    bundledData: any,
    componentData?: any,
    done?: any,
  ) {
    this.simpleModalService
      .addModal(
        modalComponent,
        {
          enrolledProducts,
          cartItems,
          cartPayload,
          bundledData,
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: false,
        },
      )
      .subscribe(isConfirmed => {
        if (isConfirmed) {
          componentData.showLoader = false;
          componentData.loader?.emit(false);
          if (done) {
            done(isConfirmed);
          }
        } else if (isConfirmed === false) {
          componentData.showLoader = false;
          componentData.loader?.emit(false);
          if (done) {
            done(false);
          }
        } else if (isConfirmed === undefined) {
          componentData.showLoader = false;
          componentData.loader?.emit(false);
          if (done) {
            done(false);
          } else {
            done(undefined);
          }
        }
      });
  }

  addUpdatedUpgradeItemToCart(updatedPayload: any, componentData: any) {
    this.cartService.addItem(updatedPayload, '', (err, data) => {
      componentData.showLoader = false;
      componentData.loader.emit(false);
      if (err) {
        if (data.status === 409) {
          this.showErrorModal(
            'Upgrade to Bundle',
            `If you are looking to bundle your existing services,
        please visit your My Services page and view the list of opportunities from your current services.`,
            'Ok',
          );
        } else if (data.status === 400) {
          this.showErrorModal(
            'Upgrade to Bundle',
            `If you are looking to bundle your existing services,
        please visit your My Services page and view the list of opportunities from your current services.`,
            'Ok',
          );
        } else {
          this.showErrorModal('Error', 'Error while adding item to cart');
        }
      } else {
        this.router.navigate(['/payment'], {
          queryParams: {
            section: 'cart',
          },
        });
      }
    });
  }
}
