<section class="language-change-section">
  <div class="page-wrap text-center fs-restrict-dead-clicks">
    <div class="row justify-content-md-start lang-padding no-gutters">
      <div class="ml-auto lang-text"><i class="fa fa-globe globe-icon mr-1"></i> See this page in</div>
      <div class="mr-auto">
        <span id="google_translate_element"></span>
      </div>
    </div>
  </div>
</section>
