export interface CancellationFormModel {
  cancellationReason: Array<any>;
  cancellationEffectiveDt: any;
}

export class CancellationForm implements CancellationFormModel {
  cancellationReason: Array<any>;

  cancellationEffectiveDt: any;
}
