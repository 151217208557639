import { Injectable } from '@angular/core';
import { SessionStorageService } from './session-storage.service';
// import decode from 'jwt-decode';

@Injectable()
export class AuthService {
  constructor(private sessionStorageService: SessionStorageService) {}

  public getToken(): string {
    try {
      return this.sessionStorageService.getItem('currentUser');
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }

  public checkTokenValidity(token: string): boolean {
    return !!token;
  }

  public isAuthenticated(): boolean {
    // get the token
    const token = this.getToken();
    // return a boolean reflecting
    // whether or not the token is expired
    return this.checkTokenValidity(token);
  }
}
