import { Component, OnInit, Input, EventEmitter, Output, OnChanges } from '@angular/core';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'hos-claims-child-component',
  templateUrl: './claims-child.component.html',
  styleUrls: ['./claims-child.component.scss'],
})
export class ClaimsChildComponent implements OnInit, OnChanges {
  @Input() selectedClaim: any;

  @Input() productDetails: any;

  @Input() childQuestNumber: any;

  isChildQuestionOne: boolean = false;

  isChildQuestionTwo: boolean = false;

  question: any;

  childClaimData: any;

  @Output() childClaimAnswer = new EventEmitter();

  today: string;

  minDate: string;

  constructor(private datePipe: DatePipe) {}

  ngOnInit() {
    this.today = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.minDate = this.datePipe.transform(new Date(this.selectedClaim.dateOfClaim.split('T')[0]), 'yyyy-MM-dd');
    if (this.childQuestNumber === 1) {
      this.isChildQuestionOne = true;
      this.isChildQuestionTwo = false;
    }
  }

  ngOnChanges() {
    if (this.childQuestNumber === 1) {
      this.isChildQuestionOne = true;
      this.isChildQuestionTwo = false;
    } else if (this.childQuestNumber === 2) {
      this.childClaimData = '';
      this.isChildQuestionTwo = true;
      this.isChildQuestionOne = false;
    }
  }

  optionValueChange() {
    this.childClaimAnswer.emit(this.childClaimData);
  }
}
