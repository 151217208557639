import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { AlertModule } from 'ngx-bootstrap/alert';
import { FormsModule } from '@angular/forms';
import { NguCarouselModule } from '@ngu/carousel';

// Components
import { RouterModule } from '@angular/router';
import { BodyComponent } from './component/body/body.component';
import { HeroBannerComponent } from './component/hero-banner/hero-banner.component';
import { CategoryComponent } from './component/category/category.component';
import { HowItWorksComponent } from './component/how-it-works/how-it-works.component';
import { StoriesComponent } from './component/stories/stories.component';
import { PartnerComponent } from './component/partner/partner.component';
import { TestimonialComponent } from './component/testimonial/testimonial.component';
import { GooglePlacesComponent } from './component/google-places/google-places.component';
import { StoryComponent } from './component/stories/story/story.component';
import { PartnerDetailComponent } from './component/partner-detail/partner-detail.component';
import { ProductCardHomePageComponent } from './component/product-card-home-page/product-card-home-page.component';
import { GoogleReviewComponent } from './component/google-review/google-review.component';
import { GetStartedModalComponent } from './component/get-started-modal/get-started-modal.component';

// Modules
import { UserModule } from '../user/user.module';
import { LocationService } from './services/location.service';
import { CoreModule } from '../core';
import { PipesModule } from '../pipes';

@NgModule({
  declarations: [
    BodyComponent,
    HeroBannerComponent,
    CategoryComponent,
    HowItWorksComponent,
    StoriesComponent,
    PartnerComponent,
    TestimonialComponent,
    StoryComponent,
    GooglePlacesComponent,
    PartnerDetailComponent,
    GoogleReviewComponent,
    ProductCardHomePageComponent,
    GetStartedModalComponent,
  ],
  exports: [BodyComponent, GooglePlacesComponent, CategoryComponent, ProductCardHomePageComponent, GetStartedModalComponent],
  imports: [
    PipesModule,
    RouterModule,
    CommonModule,
    TypeaheadModule.forRoot(),
    FormsModule,
    UserModule,
    CoreModule,
    AlertModule.forRoot(),
    NguCarouselModule,
  ],
  entryComponents: [GetStartedModalComponent],
  providers: [LocationService],
})
export class HomeModule {}
