export const DotCMSCategory = {
  HOSFACT: 'hosfacts1',
  HOS_BANNER: 'hosbanner',
  HOS_RECENT_NEWS: 'hosrecentnews',
  SERVICE: 'Service',
  HOS_MY_ACCOUNT_CAROUSEL: 'hosmyaccountcarousel',
  HOS_MY_ACCOUNT_SAVE_MORE: 'hosmyaccountsavemore',
  HOS_MYACCOUNT_CONTACT_US: 'hosmyaccountcontactus',
  HOS_FACTS_DEFAULT_MS: 'hosfactsdefaultms',
};
