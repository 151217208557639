import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { SeoConfig } from '../core/model/seo-config.model';
import { HttpService } from '../core/services/http';
import { SeoService } from '../core/services/seo.service';

@Component({
  selector: 'hos-url-search',
  templateUrl: './url-search.component.html',
  styleUrls: ['./url-search.component.css'],
})
export class UrlSearchComponent implements OnInit {
  seoConfig: SeoConfig = {
    title: 'Home Service Line Warranties | American Water Resources',
    description: `Home American Water Resources offers affordable home protection programs
    that help protect against unexpected repair costs for service line emergencies.`,
    keywords: `service line warranties, water line protection, sewer line protection,
    in home plumbing emergencies, electric line protection, power surge protection,heating system repair, cooling system repair`,
    image: '',
    slug: '',
    sitename: '',
    type: '',
    summary: '',
    url: '',
  };

  isBrowser: any;

  constructor(
    private seoService: SeoService,
    private http: HttpService,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
    if (this.isBrowser) {
      this.seoConfig.url = window.location.origin + window.location.pathname;
    }
    seoService.generateTitleTags(this.seoConfig);
    seoService.setCanonicalTags();
  }

  ngOnInit() {
    const route = `/${this.route.snapshot.url.join('/')}`;
    this.http.get(`${environment.Content_service_API_Endpoint}redirects/search?route=${route}`).subscribe(
      response => {
        if (response && response.body) {
          const { body } = response;
          if (body && body.data && body.data.redirect_To_Domain && body.data.redirect_To_Path) {
            if (this.isBrowser) {
              window.location.href = body.data.redirect_To_Path;
            } else {
              this.router.navigateByUrl(body.data.redirect_To_Path);
            }
          } else {
            this.router.navigateByUrl('/404');
          }
        }
      },
      () => {
        this.router.navigateByUrl('/404');
      },
    );
  }
}
