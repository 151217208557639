<section class="hero-slider" *ngIf="dotCMSBannerData">
  <div class="slider w-100">
    <ngu-carousel [inputs]="carouselBanner" [dataSource]="dotCMSBannerData">
      <ngu-tile *nguCarouselDef="let tile of dotCMSBannerData; let i = index" class="item">
        <div class="bannerStyle">
          <img
            nolazyload="do-not-lazy-load"
            style="height: 100%; width: 100%; position: absolute"
            [src]="
              screenWidth < 768
                ? tile.bannerMobileImageURL
                : screenWidth >= 768 && screenWidth <= 1024
                ? tile.bannerTabletImageURL
                : tile.bannerImageURL
            "
            [alt]="tile.title"
          />
          <div class="col-12 col-md-12 hero-content">
            <div *ngIf="tile" class="head-content">
              <h3 [innerHTML]="tile.title"></h3>
              <h4 [innerHTML]="tile.subTitle"></h4>
            </div>
          </div>
        </div>
      </ngu-tile>
      <button NguCarouselPrev class="leftRs">&lt;</button>
      <button NguCarouselNext class="rightRs">&gt;</button>
    </ngu-carousel>
  </div>
  <div class="row search-box-content">
    <div class="col-12 d-flex justify-content-center">
      <div class="zip-code-search-box">
        <div class="zip-code-text d-flex align-items-center MB-15">
          {{ zipcodeLookupText && zipcodeLookupText?.homepageText !== '' ? zipcodeLookupText?.homepageText : 'Find your home protection plan' }}
        </div>
        <hos-google-places
          (addressHandler)="addressHandler($event)"
          [btnText]="zipcodeLookupText && zipcodeLookupText?.homepageButtonText !== '' ? zipcodeLookupText?.homepageButtonText : 'GET STARTED'"
          [placeholder]="'Enter Your Zip Code'"
          class="hero-banner"
          angulartics2On="click"
          angularticsLabel="get_started"
          angularticsValue="0"
          angularticsAction="click"
          angularticsCategory="button"
        ></hos-google-places>
      </div>
    </div>
  </div>
</section>
