<ng-container class="PD-15">
  <div class="modal-dialog modal-content modal-background">
    <div class="modal-body">
      <div class="container-fluid modal-header-container">
        <div class="row padding-10">
          <div class="col-10">
            <span class="modal-header-container--title">RECOMMENDED UPGRADES</span>
          </div>
          <div class="col-2 d-flex justify-content-end">
            <button type="button" class="close set-font" (click)="close()">
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="product-list row" *ngIf="upgradeProducts?.length > 0">
          <div class="col-12 product-promotion">
            <h6>This Program is compatible with the following Bundles. Upgrade and get more for less!</h6>
          </div>
          <div class="container-fluid upsell-product-card--container" *ngFor="let product of upgradeProducts; let i = index">
            <div class="row upsell-product-card--row">
              <div class="col col-md-3 col-lg-3 upsell-product-card--imgDiv">
                <img
                  class="card-img ga-track-learn-more upsell-product-card--imgDiv__img"
                  [src]="getProductCardImage(product)"
                  [alt]="product?.webProgramName"
                  (click)="learnMoreHandler(product)"
                />
              </div>
              <div class="col-9 col-sm-9 col-md-6 col-lg-6 upsell-product-card--productNameDiv">
                <span class="upsell-product-card--productNameDiv__productTitle">
                  {{ product?.webProgramName }}
                </span>
              </div>
              <div class="col-3 upsell-product-card--iconDiv">
                <img
                  class="float-right upsell-product-card--iconDiv__iconStyle"
                  [src]="getImage(baseUrl + product?.programIconUrl)"
                  [alt]="product?.webProgramName"
                />
              </div>
              <div class="col-12 col-md-3 col-lg-3 mt-15 upsell-product-card--productNamePriceContainer">
                <div class="col-8 col-lg-12 col-md-12 upsell-product-card--productNamePriceDiv">
                  <span class="upsell-product-card--productNamePriceDiv__priceMainStyle">${{ product?.monthlyPrice | slice : 0 : -2 }}</span>
                  <span class="upsell-product-card--productNamePriceDiv__priceSubStyle">{{
                    specialProduct.includes(product?.programId) ? '' : '/ month'
                  }}</span>
                </div>
                <div class="col-4 col-lg-12 col-md-12 upsell-product-card--productNamePriceContainer__btnDiv">
                  <button (click)="addProductToCart(product)" class="btn w-100 enroll-now skiptranslate ga-track-upgrade-cart" title="Upgrade">
                    UPGRADE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row no-upgrade-option" *ngIf="upgradeProducts?.length === 0">
        <div class="col-12 product-item">{{ service.programName || 'Protection Program' }} has been added to your cart.</div>
      </div>
      <div [ngClass]="{ loading: showLoader }"></div>
    </div>
    <div class="modal-footer">
      <div class="cursor-pointer footer-btn-1">
        <button class="btn block full-width app-btn print-btn" id="ga-track-back-to-products" (click)="closeModal()">Back to products</button>
      </div>
      <div>
        <button (click)="confirmHandler()" class="btn submit-btn block full-width app-btn mt-0" id="ga-track-view-cart">VIEW CART</button>
      </div>
    </div>
  </div>
</ng-container>
