import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SimpleModalComponent, SimpleModalService } from 'ngx-simple-modal';
import { UtilsService } from '../../../core/services/utils.service';
import { environment } from '../../../../environments/environment';
import { OrdersService } from '../../../payment-ets/services/orders.service';
import { ErrorModalComponent } from '../../../core/component/error-modal/error-modal.component';

export interface AutoRenewModalModel {
  title: string;
  message: string;
  enrollServices: any;
  service: any;
  siteContent: any;
}

@Component({
  selector: 'hos-auto-renew-modal',
  templateUrl: './auto-renew-modal.component.html',
  styleUrls: ['./auto-renew-modal.component.scss'],
})
export class AutoRenewModalComponent extends SimpleModalComponent<AutoRenewModalModel, any> implements AutoRenewModalModel, OnInit {
  title: string;

  message: string;

  referer: String;

  enrollServices: any;

  service: any;

  enrollemenntguid: any;

  productServiceBaseUrl: String = environment.Image_API_Endpoint + environment.Product_service_API_Endpoint;

  successCall: boolean = false;

  siteContent: any;

  constructor(
    private simpleModalService: SimpleModalService,
    private router: Router,
    private utilsService: UtilsService,
    private orderService: OrdersService,
    private activatedRoute: ActivatedRoute,
  ) {
    super();
    this.referer = this.activatedRoute.snapshot.queryParams.referer;
  }

  ngOnInit() {
    this.enrollemenntguid = this.service.crmEnrollmentGuid;
  }

  cancelHandler() {
    this.router.navigate(['']);
    this.close();
  }

  getImage(imageName) {
    return this.utilsService.buildImageUrl(imageName);
  }

  agreeToAutoRenew() {
    this.orderService.updateAutoRenew(this.enrollemenntguid).subscribe(
      data => {
        if (data.status === 200) {
          this.close();
          this.result = true;
        }
      },
      () => {
        this.close();
        this.simpleModalService.addModal(
          ErrorModalComponent,
          {
            title: 'Error',
            message: 'Data is not available at this moment, Please try again later.',
            showButton: 'close',
            alertType: 'info-type',
          },
          {
            wrapperClass: 'modal-main-class in',
            closeOnClickOutside: true,
          },
        );
      },
    );
  }

  goToTermsOfAuth() {
    window.open(environment.TERMS_OF_AUTH_URL, '_blank');
  }
}
