<div class="page-wrap row nav-wrap justify-content-between fs-restrict-dead-clicks">
  <div class="branding row ml-sm-2 ml-0">
    <a *ngIf="!!logoURL" routerLink="/" (click)="redirectClickLogo()" class="logo base-logo">
      <img [src]="logoURL" [alt]="site?.site.siteEmailTitle" />
    </a>
    <div *ngIf="!!partnerLogo" class="partner-logo px-2 ml-0 ml-sm-2">
      <a routerLink="/" class="logo pl-sm-3 pl-2">
        <img [src]="partnerLogo" alt="Partner Logo" />
      </a>
    </div>
  </div>
  <nav class="secondary-nav nav justify-content-end">
    <ul class="menu" [ngClass]="{ 'margin-bottom-0': alertClass }">
      <li class="contact-area header-contact-number">
        <a
          href="tel:{{ showPriceInfoContactNo ? priceInfoContactNumber : siteContent?.contactNumber }}"
          angulartics2On="click"
          angularticsLabel="phone_header"
          angularticsValue="0"
          angularticsAction="click"
          angularticsCategory="call"
        >
          <p class="repair-txt mb-0">Contact Us 24/7</p>
          <p *ngIf="siteContent" class="number-txt mb-0">
            <a href="tel:{{ showPriceInfoContactNo ? priceInfoContactNumber : siteContent?.contactNumber }}">{{
              showPriceInfoContactNo ? priceInfoContactNumber : siteContent?.contactNumber
            }}</a>
          </p>
        </a>
      </li>
      <!-- <li class="search-box">
                <div class="search-bar search-panel">
                    <form action="result.html" class="search">
                        <div class="search-field">
                            <input type="text" class="search-input" id="search" name="search" placeholder="Search" />
                            <button class="search-btn">
                                <i class="icon-search"></i>
                            </button>
                        </div>
                    </form>
                </div>
            </li> -->
      <li class="cart-area MT-10 MR-0" *ngIf="!enrollmentUpdatePage">
        <a
          (click)="checkPopUp()"
          class="header-icon"
          style="width: 100%; text-align: center"
          angulartics2On="click"
          angularticsLabel="shopping_cart"
          angularticsValue="0"
          angularticsAction="click"
          angularticsCategory="button"
        >
          <img src="../../../../img/Cart-ico.svg" loading="lazy" alt="Cart" />
          <span class="cart-count skiptranslate">{{ itemCount }} </span>
        </a>
      </li>
      <li class="notification MT-10 ML-5">
        <div
          [ngClass]="{
            'notification-area': isActive,
            'notification-area-false': !isActive
          }"
        >
          <a
            class="notification-display"
            class="header-icon"
            style="width: 100%; text-align: center"
            (click)="checkNotification()"
            angulartics2On="click"
            angularticsLabel="shopping_cart"
            angularticsValue="0"
            angularticsAction="click"
            angularticsCategory="button"
          >
            <i class="fa fa-bell-o" aria-hidden="true"></i>
            <span class="cart-count skiptranslate">{{ notificationCount + alertCount }}</span>
          </a>
        </div>
        <div class="arrow-up" *ngIf="isActive"></div>
      </li>
      <li class="login-area" *ngIf="getUser()">
        <span dropdown class="loggedin-text header-cust-name">
          <span dropdownToggle (click)="(false)" class="dropdown-toggle-cls">
            <a
              href
              class="text-capitalize font-16 href-cust-name"
              data-trigger="hover"
              data-placement="top"
              title="{{ loggedInUser?.user?.firstName ? loggedInUser?.user?.firstName : loggedInUser?.user?.lastName }}"
            >
              {{ loggedInUser?.user?.firstName ? loggedInUser?.user?.firstName : loggedInUser?.user?.lastName }}
            </a>
            <i *ngIf="loggedInUser?.user?.firstName || loggedInUser?.user?.lastName" class="fa fa-chevron-down"></i>
          </span>
          <ul *dropdownMenu class="dropdown-menu">
            <li>
              <a routerLink="/user/myaccount" class="dropdown-item" (click)="gotoLink()" href="#">My Services</a>
            </li>
            <li>
              <a routerLink="/user/profile" class="dropdown-item" href="#">Profile</a>
            </li>
            <!-- <li>
                            <a routerLink="/user/purchase-history" class="dropdown-item" href="#">Purchase History</a>
                        </li> -->
            <li>
              <a routerLink="/user/add-property" class="dropdown-item ga-track-add-property" href="#">Add Property</a>
            </li>
            <li>
              <a class="dropdown-item" (click)="logout()">Logout</a>
            </li>
          </ul>
        </span>
      </li>
      <li class="login-area" *ngIf="!getUser() && !isLoginRoute" [ngStyle]="{ visibility: !showPage ? 'hidden' : 'visible' }">
        <button
          type="button"
          (click)="openLoginWindow()"
          class="btn btn-sm nav-btn skiptranslate"
          angulartics2On="click"
          angularticsAction="click"
          angularticsLabel="login_register"
          angularticsCategory="Header-Login"
          title="Login / Register"
        >
          Login / Register
        </button>
      </li>
      <li class="nav-icon" id="navTrigger">
        <a (click)="openSidemenu()" angulartics2On="click" angularticsAction="click" angularticsCategory="Header-SidebarMenu">
          <div class="bars">
            <div class="bar__line">&nbsp;</div>
            <div class="bar__line">&nbsp;</div>
            <div class="bar__line">&nbsp;</div>
          </div>
        </a>
      </li>
    </ul>
  </nav>
</div>
<div class="notification-bar" [ngClass]="{ 'alert-class': alertClass }" *ngIf="isActive" [style.max-height.px]="notificationHeight">
  <div class="page-wrap gs-wrap row">
    <div class="col-12">
      <div class="row">
        <div class="col-6">
          <div class="info d-block d-sm-block d-md-none">
            <i class="fa fa-info-circle"></i>
          </div>
        </div>
        <div class="col-6">
          <p class="notification-close my-2" (click)="isActive = false">
            <b><span class="close-x"> X </span> <span class="close-text">CLOSE</span></b>
          </p>
        </div>
      </div>
    </div>
    <!-- <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i> -->
    <div class="col-md-1 col-xs-3 info d-none d-md-block" *ngIf="notificationCount > 0">
      <i class="fa fa-info-circle"></i>
    </div>
    <div class="col-md-10 col-xs-7" *ngIf="notificationCount > 0">
      <div class="notification-text">
        <span *ngFor="let data of notificationData">
          <div class="card card-space-mb" *ngIf="data?.isActive">
            <div class="card-body">
              <div class="display-message" [innerHTML]="data?.displayMessage"></div>
            </div>
          </div>
        </span>
      </div>
      <!-- <p>On Thursday, Jan. 3, from 11 p.m. through 2 a.m. on Friday, Jan. 4, American Water will be performing scheduled maintenance. Customers will be unable to access accounts while maintenance is being performed. Thank you for your patience. </p>-->
    </div>
    <!-- <div class="col-md-1 col-xs-2 p-0">
            <p class="notification-close" (click)="isActive=false"><b><span class="close-x"> X </span> <span class="close-text">CLOSE</span></b></p>
        </div> -->
    <!-- <span class="fa fa-info-circle-o"  aria-hidden="true"></span> -->
  </div>

  <hos-customer-notification [data]="notifyResult" (addressClick)="pageSwitch($event)"></hos-customer-notification>
</div>
