import { DefaultUrlSerializer, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { SessionStorageService } from './session-storage.service';

@Injectable()
export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
  constructor(private sessionStorageService: SessionStorageService) {
    super();
  }

  parse(url: string): UrlTree {
    // const micrositeConfig = environment.micrositeNames;
    const micrositeConfig = this.sessionStorageService.getItem('micrositeName')
      ? JSON.parse(this.sessionStorageService.getItem('micrositeName'))
      : environment.micrositeNames;
    const urlOperation: any = url.split('?');
    /* urlOperation[0] = urlOperation[0];
        urlOperation = urlOperation.join('?'); */
    const breaking = urlOperation[0].split('/').splice(1, 1);
    let buildUrl = urlOperation[0];
    let slug = '';
    slug = breaking[0] || '';
    slug = slug.toLowerCase();
    if (micrositeConfig.indexOf(slug) >= 0) {
      const remainingPath = urlOperation[0].replace(`/${breaking[0]}`, '');
      buildUrl = `/${remainingPath}`;
    }
    /* ampersand in slug url issue fix */
    if (buildUrl.includes('&') && breaking && breaking[0] === 'service') {
      buildUrl = buildUrl.replace('&', encodeURIComponent('&'));
    }
    buildUrl = buildUrl.toLowerCase();
    /**
     * HWR-3036: UTM param handeled
     */
    if (urlOperation.length > 1) {
      buildUrl += `?${urlOperation[1]}`;
    }
    // buildUrl = buildUrl.replace(new RegExp( '(/' + micrositeConfig.join('|/') + ')', 'i'), '');
    return super.parse(buildUrl);
  }
}
