<div class="modal-dialog modal-content modal-background PD-0">
  <div class="modal-body">
    <div class="row">
      <div class="col-sm-12 service-header-box">
        <div class="service-header pull-left">
          <div class="pull-left col-9 title-header">
            <p class="title mb-0">Update Payment Details</p>
          </div>
        </div>
        <button type="button" class="close pull-right set-font" (click)="close()">
          <i class="fa fa-times" aria-hidden="true"></i>
          <span class="close-text">CLOSE</span>
        </button>
      </div>
    </div>
    <div class="payment-body">
      <div class="row select-payment-method">Select a Payment Method</div>
      <!-- <div *ngIf="products && products[0].paymentMethod === 'CreditCard' && !globalVariables.migratedToOracle">
        <ng-container *ngFor="let card of paymentCards; let first = first">
          <div class="card-header-custom row card-list" *ngIf="card.CreditCardToken === financialInstrumentToken">
            <div class="col-7 col-md-7 radio-st">
              <div class="custom-radio">
                <input type="radio" disabled value="{{ card?.CreditCardToken }}" name="cards" />
                <label class="custom-control-label payment-label" for="card-selection-{{ card?.CreditCardToken }}">
                  <img src="/img/payment/credit-card.svg" />
                  <span class="card-exp">
                    <strong>{{ card?.CardType }}</strong> XXXX <strong>{{ card?.AccountNumberMasked }} </strong> (Already Being Used)
                  </span>
                </label>
              </div>
            </div>
            <div class="col-5 col-md-5">
              <div class="row">
                <div class="col-md-4">
                  <span
                    *ngIf="
                      currentDate.getFullYear() > card.ExpirationDate.split('/')[1] ||
                      (currentDate.getFullYear() >= card.ExpirationDate.split('/')[1] &&
                        currentDate.getMonth() + 1 > card.ExpirationDate.split('/')[0])
                    "
                    class="expired"
                  >
                    Expired
                  </span>
                </div>
                <div class="col-md-8">
                  <div class="row expiry-date">
                    <div class="col-md-6">
                      <span class="expiry-date-text">Month</span>
                      <div class="expiry-date-item">
                        <span>{{ card?.ExpirationDate.split('/')[0] }}</span>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <span class="expiry-date-text">Year</span>
                      <div class="expiry-date-item">
                        <span>{{ card?.ExpirationDate.split('/')[1] }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <div *ngIf="products && products[0].paymentMethod !== 'CreditCard' && !globalVariables.migratedToOracle">
        <ng-container *ngFor="let card of achCards; let first = first">
          <div class="card-header-custom row" *ngIf="card.AchAccountToken === financialInstrumentToken">
            <div class="col-12 col-md-8">
              <div class="custom-radio">
                <input
                  type="radio"
                  disabled
                  class="custom-control-input"
                  id="card-selection-{{ card.AchAccountToken }}"
                  value="{{ card.AchAccountToken }}"
                  name="cards"
                  (click)="selectCard(card, 'ACH')"
                />
                <label class="custom-control-label payment-label" for="card-selection-{{ card.AchAccountToken }}">
                  <b>{{ card.AccountHolderName }}</b> (Already Being Used)
                </label>
              </div>
            </div>
            <div class="col-12 col-md-4 text-right">
              {{ card.RoutingNumberMasked }}
            </div>
          </div>
        </ng-container>
      </div>
      <div class="add-payment-method add-method-card row" *ngIf="setCardRequired && !globalVariables.migratedToOracle">
        <div class="col-12 col-md-8">
          <div class="custom-radio">
            <input type="radio" class="custom-control-input" id="card-selection-new" value="newCard" name="cards" (click)="selectCard('newCard')" />
            <label class="payment-label new-card-label" for="card-selection-new">
              <img src="/img/various/New-Payment-Ico.svg" /> Change Payment Method</label
            >
          </div>
        </div>
      </div> -->

      <!-- <div
        class="payment-gateway px-5"
        *ngIf="!globalVariables.migratedToOracle"
        [hidden]="setCard !== 'newCard'"
        [style.margin-top]="paymentCards.length > 0 ? '0px' : '29px'"
      >
        <div id="hp" style="display: block" data-show-Discover="false" data-allow-avs-skip="false" data-disable-autocomplete="true"></div>
        <div class="payment-body">
          <div id="stripe-payment-update-payment"></div>
        </div>
      </div> -->
      <div class="payment-gateway" [style.margin-top]="paymentCards.length > 0 ? '0px' : '29px'" style="padding: 15px 0">
        <div class="payment-body">
          <div id="stripe-payment-update-payment"></div>
        </div>
      </div>
    </div>
    <!-- <div class="modal-footer login-footer">
                </div> -->
    <div [ngClass]="{ loading: showLoader || !sessionTokenGenerated }"></div>
  </div>
  <div class="modal-footer">
    <button class="cancel-button cursor-pointer" (click)="close()">CANCEL</button>
    <button *ngIf="setCard && setCard !== 'newCard'" (click)="useExistCard()" class="btn submit-btn pull-right update-button">UPDATE</button>
    <!-- <div class="cursor-pointer">
                <span class="cancel-button" (click)="close()"> CANCEL </span>
            </div>
            <div *ngIf="setCard && setCard !== 'newCard'">
                <button (click)="useExistCard()" class="btn submit-btn">UPDATE</button>
            </div> -->
  </div>
</div>
