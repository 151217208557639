<div class="modal-dialog custom-error-alert modal-content modal-background">
  <div class="modal-header modal-custom-header error-modal-custom-header" [ngClass]="alertType ? alertType : 'info-type'">
    <div class="info-background mx-auto"></div>
    <!-- <h4 class="modal-title">
                    <div *ngIf="alertIcon" class="{{alertIcon}} alert-icon"></div>
                <div class="pl-3">{{title}}</div>
            </h4> -->
  </div>
  <div class="modal-body">
    <h4 class="mb-3">{{ title }}</h4>
    <p [innerHtml]="message | safeHtml" class="info-txt pt-4 mb-0"></p>
  </div>

  <div class="modal-footer justify-content-center">
    <button
      (click)="close()"
      [attr.id]="confirmBtnId ? confirmBtnId : 'close'"
      *ngIf="!confirmationMsg"
      class="btn submit-btn block full-width app-btn mt-0"
    >
      OK
    </button>
    <button
      (click)="close()"
      [attr.id]="confirmBtnId ? confirmBtnId : 'close'"
      *ngIf="confirmationMsg"
      class="btn submit-btn block full-width app-btn mt-0"
    >
      {{ confirmationMsg }}
    </button>
  </div>
</div>
