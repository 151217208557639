export const environment = {
  production: true,
  AppName: 'hos',
  envName: 'master',
  Captcha: {
    siteKey: '6LfqsOIpAAAAAEexT0k1lfXAiCYkRQZO2AICR6aW',
  },
  Image_API_Endpoint: '/api-services/',
  API_Endpoint: '/api-services/',
  dotcms_base_url: 'https://authoring-hos-dotcms.ohsinfra.io',
  cloudfront_base_url: 'https://delivery-hos-dotcms.ohsinfra.io',
  dotcms_content_search_by_category_path:
    // eslint-disable-next-line no-template-curly-in-string
    '/api/content/render/false/query/-contentType:forms%20-baseType:6%20-basetype:3%20+contentType:Blog%20+(conhost:ed496d3c-aa73-4c10-adfa-68db6ee5ce59%20conhost:SYSTEM_HOST)%20+(categories:${categoryName})/orderby/modDate%20desc/limit/0',
  dotcms_content_search_by_entry_id_path:
    '/api/content/render/false/query/+contentType:Blog%20+(conhost:ed496d3c-aa73-4c10-adfa-68db6ee5ce59%20conhost:SYSTEM_HOST)%20+Blog.entryId:*{id}*/orderby/modDate%20desc',
  dotcms_content_search_by_dotcms_id_path: '/api/content/id/{id}',
  dotcms_image_search_path: '/dA/',
  Product_service_API_Endpoint: 'product/',
  Customer_service_API_Endpoint: 'customer/',
  User_service_API_Endpoint: 'user/',
  Cart_service_API_Endpoint: 'cart/',
  Orders_service_API_Endpoint: 'order/',
  Content_service_API_Endpoint: 'content/',
  Location_service_API_Endpoint: 'location/',
  Partner_service_API_Endpoint: 'partner/',
  Crm_customer_service_API_Endpoint: 'crm-customer/',
  Template_service_API_Endpoint: 'template/api/v1/',
  Crm_Proxy_Service_API_Endpoint: 'crm-proxy/',
  AUDIT_SERVICE_API_ENDPOINT: 'audit',
  TERMS_SERVICE_ENDPOINT: 'terms',
  Eft_Proxy_Service_API_Endpoint: 'eft/',
  Notification_service_API_Endpoint: 'notification/api/v1/',
  google_base_url: 'https://maps.googleapis.com/maps/api/',
  google_key: 'AIzaSyDNjZQGh-2HVAIMfZKH-WThtFmtubcb9WQ',
  hosted_payments_base_url: 'https://crt-htv.emoney.com/v3/adapters',
  password_regex: '^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()~`\\-_\\+])[a-zA-Z0-9!@#$%^&*()~`\\-_\\+]{8,128}$',
  email_regex: '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,255}$',
  customer_number_regex: '^[Cc,Uu,Ss]+-[a-zA-Z0-9]{0,50}$',
  customer_service_number: '1.844.427.4687',
  support_customer_service_number: '1-855-800-5195',
  valid_account_services: ['2', '3', '6', '9', '10', '11', '12', '999'],
  enroll_phone_number: '1-866-641-2174',
  phone_number_regex: '^[0-9, ()-]{0,50}$',
  is_mulesoft_enable: true,
  // tslint:disable-next-line:max-line-length
  micrositeNames: [],
  ADMIN_URL: 'https://hos-admin-ui-development.ohsdigital.io',
  valid_security_question_answer: ['answer'],
  valid_security_question_answer_length: 6,
  disposable_email: [
    'mailinator.com',
    'cndps.com',
    'guerrillamail.com',
    'mt2015.com',
    'spamgourmet.com',
    'cd.mintemail',
    'mailcatch.com',
    'bestglockner.com',
    'spammotel.com',
    'yopmail.com',
    'saynotospams.com',
    'mailcatch.com',
    'emeraldwebhost.com',
    'bog@urx7',
    'sneakemail.com',
    '4warding.com',
    '20minutemail.it',
    'dontreg.com',
    'spamex.com',
    'mailnull.com',
  ],
  special_product: ['72d71f22-65b0-e811-943a-00155d006b95'],
  website_idle_timeout: 900,
  surge_program_id: '0c18b1d4-05d7-e411-9425-00155ddf6652',
  statusReasonId: 'B618B1D4-05D7-E411-9425-00155DDF6652',
  upgrade_enable: 86400,
  renewExpirationTimePos: 8640000000,
  renewExpirationTimeNeg: -2592000000,
  one_year: 366,
  one_leap_year: 365,
  auto_renew_enable: 100,
  hide_autorenew_states: ['CA', 'VA'],
  TERMS_OF_AUTH_URL: '/page/about-us/317229',
  my_account_fb_img: 'fb_loggedin_bg.png',
  ccpa_updates_provider: ['ca', 'sanfrancisco'],
  update_paymentplan_hours: 72,
  leakDetectionProgramId: '72d71f22-65b0-e811-943a-00155d006b95',
  Claims_service_API_Endpoint: 'claims/',
  claimStateId: 'E5C0907A-C118-E811-9432-00155D006C58',
  hide_fileClaimButton: ['72d71f22-65b0-e811-943a-00155d006b95', '2ee4d3f0-587d-ea11-9474-00155d00697f', '1cb44dd2-5ecd-ea11-9471-00155d104f66'],
  onbillCallNumbers: {
    NY: '888-300-3570',
    FL: '877-320-4624',
    TN: '888-758-8935',
    default: '844-427-4687',
  },
  review_limit_per_page: 4,
  leakDetectionContractStates: ['NJ', 'PA', 'AL', 'AZ', 'CT', 'GA', 'ID', 'IL', 'IN', 'IA', 'MO', 'OH'],
  types: [
    {
      id: 1,
      type: 'residential',
    },
    {
      id: 3,
      type: 'commercial',
    },
  ],
  urltoLowerRoute: ['products', 'product', 'service', 'pages'],
  covered_repairs: ['72d71f22-65b0-e811-943a-00155d006b95', '0403dcad-b9c1-ea11-9478-00155ddf6721', '2ee4d3f0-587d-ea11-9474-00155d00697f'],
  userRouteIncludes: [
    'user/register',
    'user/email-verify',
    'user/change-password',
    'user/forgot-password',
    'user/reset-password',
    'user/set-password',
    'user/checkout',
  ],
  nycDEP: ['Unlimited WLPP', 'Unlimited SLPP'],
  unbounceDomainUrl: 'https://getstarted.awrusa.com/',
  ignoreQueryParamsArry: ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content'],
  seoBaseURL: 'https://hos-ui-development.ohsdigital.io',
  DotCMSCategoryArr: [
    {
      key: 'Home Warranties',
      value: 'homeWarranties',
      url: 'home-warranties',
    },
    {
      key: 'Heating and Cooling',
      value: 'heatingAndCooling',
      url: 'heating-and-cooling',
    },
    {
      key: 'Homeowner Tips',
      value: 'homeownerTips',
      url: 'homeowner-tips',
    },
    {
      key: 'News',
      value: 'news',
      url: 'news',
    },
    {
      key: 'Safety Tips',
      value: 'safetyTips',
      url: 'safety-tips',
    },
    {
      key: 'Plumbing',
      value: 'plumbing',
      url: 'plumbing',
    },
    {
      key: 'Appliances',
      value: 'appliances',
      url: 'appliances',
    },
    {
      key: 'Customer Stories',
      value: 'customerStories',
      url: 'customer-stories',
    },
  ],
  SAPLocations: ['NJ101', 'IA100', 'VA100', 'NJ100', 'PA100'],
  name_regex: '.*[^ ].*',
  termsLanguages: [
    {
      key: 'english',
      value: 'English',
    },
    {
      key: 'spanish',
      value: 'Spanish',
    },
    {
      key: 'arabic',
      value: 'Arabic',
    },
    {
      key: 'bengali',
      value: 'Bengali',
    },
    {
      key: 'chinese_simplified',
      value: 'Chinese Simplified',
    },
    {
      key: 'chinese_traditional',
      value: 'Chinese Traditional',
    },
    {
      key: 'tagalog',
      value: 'Tagalog',
    },
    {
      key: 'russian',
      value: 'Russian',
    },
    {
      key: 'vietnamese',
      value: 'Vietnamese',
    },
  ],
  blockUnicodeRegex: "^[A-Za-z0-9',. ]+$",
  plumbingProgramIds: [
    '0418b1d4-05d7-e411-9425-00155ddf6652',
    '0618b1d4-05d7-e411-9425-00155ddf6652',
    '1118b1d4-05d7-e411-9425-00155ddf6652',
    '0318b1d4-05d7-e411-9425-00155ddf6652',
    '0518b1d4-05d7-e411-9425-00155ddf6652',
    '0403dcad-b9c1-ea11-9478-00155ddf6721',
    '6d2fa4d1-01f7-ea11-9474-00155d104f66',
  ],
  redirectDigitalPoc: {
    redirectUrl: 'https://products.awrusa.com/?zipcode=',
    locationCodeIds: [],
  },
  gaTargetId: 'G-T78G395DQF',
  stripeEnv: 'qa',
  renewCustomerCareNumber: '1-855-390-4571',
};
