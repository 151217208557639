export function getBaseLocation(): any {
  try {
    const paths = window ? window.location.pathname.split('/').splice(1, 1) : [''];
    const myPages = JSON.parse(localStorage.getItem('micrositeName'));
    if (myPages.indexOf(paths[0].toLowerCase()) > -1) {
      const basePath: string = (paths && paths[0].toLowerCase()) || ''; // Default: my-account
      if (basePath !== '') {
        localStorage.setItem('providerState', basePath);
      } else {
        localStorage.setItem('providerState', '');
      }
      return `/${basePath}`;
    }
    localStorage.setItem('providerState', '');
    return '/';
  } catch (e) {
    return '/';
  }
}
