import { Component, OnInit, OnChanges, OnDestroy, Input, Output, EventEmitter, PLATFORM_ID, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { TitleCasePipe, isPlatformBrowser } from '@angular/common';
import { find } from 'lodash-es';
import { environment } from '../../../../environments/environment';

/* Service Import */
import { UserService } from '../../services/user.service';
import { UtilsService } from '../../../core/services/utils.service';
import { AlertService } from '../../../core/services/alert.service';
import { OrdersService } from '../../../payment-ets/services/orders.service';
import { SiteContentService } from '../../../core/services/siteContents';
import { LocationService } from '../../../home/services/location.service';
import { SessionStorageService } from '../../../core/services/session-storage.service';
import { GlobalVariables } from '../../../core/services/global-variables.service';
/* Service Import */

@Component({
  selector: 'hos-my-account-new-header',
  templateUrl: './my-account-new-header.component.html',
  styleUrls: ['./my-account-new-header.component.scss'],
})
export class MyAccountNewHeaderComponent implements OnInit, OnChanges, OnDestroy {
  @Input() pageName: boolean;

  @Input() content?: any;

  @Input() cusNo?: any;

  @Input() selectedAddress?: any;

  @Output() myAccClick = new EventEmitter();

  @Output() addressHandler = new EventEmitter();

  bannerImage: String;

  userDetails: any = {};

  customerSinceYear: Number;

  history: any;

  timeoutAddressLookup: any;

  public secrets: any;

  customerProfile: any;

  isAddressFound: Boolean = true;

  page: string;

  searchZipCode: string;

  myAccountHome: boolean;

  isInvalid = false;

  totalProperties: number;

  loggedInUser: any;

  @Input() firstName: String;

  @Input() lastName: String;

  myProfileText: string;

  addPropertyText: string;

  @Input() myProfilePage: boolean;

  @Input() addPropertyPage: boolean;

  @Output() myProfileHomeClick = new EventEmitter();

  @Output() addPropertyHomeClick = new EventEmitter();

  totalAddresses: any;

  isBrowser: Boolean = true;

  constructor(
    private router: Router,
    private userService: UserService,
    private alertService: AlertService,
    private ordersService: OrdersService,
    private siteContentService: SiteContentService,
    private utilsService: UtilsService,
    private titleCasePipe: TitleCasePipe,
    private sessionStorageService: SessionStorageService,
    private locationService: LocationService,
    private globalVariables: GlobalVariables,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    this.userDetails = this.userService.getSignedInUser();
    const date = new Date(this.userDetails.user.createdAt);
    this.customerSinceYear = date.getFullYear();
    this.userService.getProfileTipsAndTitle().subscribe(data => {
      this.bannerImage = this.utilsService.buildImageUrl(
        `${environment.Image_API_Endpoint}${environment.Content_service_API_Endpoint}${data.body[0].bannerImage.url}`,
      );
    });
    this.getOrderHistory();
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit() {
    this.myAccountHome = this.pageName;
    this.page = this.myAccountHome || typeof this.myAccountHome === 'undefined' ? 'My Services' : '< Home';
    this.myProfileText = this.myProfilePage ? '< Home' : 'My Profile';
    this.addPropertyText = this.addPropertyPage ? '< Home' : 'Add Property';
    this.getUserProfile();
    this.getCustomerAddress();
    this.loggedInUser = this.getUser();
    const serviceaddress = JSON.parse(this.sessionStorageService.getItemFromloclaStorage('serviceaddress'));
    this.totalAddresses = serviceaddress ? serviceaddress.length : 0;
  }

  getUser(): Object {
    const token = this.sessionStorageService.getItem('authHeader');
    let user;
    if (token && this.isBrowser) {
      user = JSON.parse(atob(token.split(' ')[1].split('.')[1]));
    }
    return user;
  }

  getOrderHistory() {
    this.ordersService.getOrderHistorySummary(this.userDetails.user._id).subscribe(response => {
      this.history = response.body;
    });
  }

  getCustomerAddress() {
    if (!this.cusNo) {
      this.userService.getAllCustomerAddresses(this.userDetails.user._id).subscribe(data => {
        const serviceAddresses = data.body;
        this.totalProperties = serviceAddresses.length ? serviceAddresses.length : 0;
        if (serviceAddresses.length > 0) {
          const getSelectedAddress = find(serviceAddresses, {
            _id: this.selectedAddress._id,
          });
          if (!!getSelectedAddress && !!getSelectedAddress.customerNo) {
            this.cusNo = getSelectedAddress.customerNo;
            this.globalVariables.selected_Customer_number = this.cusNo;
            this.globalVariables.selected_customerGuid = getSelectedAddress.customerGuid;
            this.globalVariables.waterBillOnOff = getSelectedAddress.waterBillOnOff;
          } else {
            this.cusNo = '';
            this.globalVariables.selected_Customer_number = this.cusNo;
            this.timeoutAddressLookup = setTimeout(() => {
              this.getCustomerAddress();
            }, 15000);
          }
        }
      });
    }
  }

  getUserProfile() {
    this.userService.getUserProfile(this.userDetails.user._id).subscribe(data => {
      this.customerProfile = data.body;
      this.customerProfile.lastName = this.titleCasePipe.transform(this.customerProfile.lastName);
      this.customerProfile.firstName = this.titleCasePipe.transform(this.customerProfile.firstName);
    });
  }

  ngOnDestroy(): void {
    clearTimeout(this.timeoutAddressLookup);
  }

  ngOnChanges() {
    this.myAccountHome = this.pageName;
    this.page = this.myAccountHome ? 'My Services' : '< Home';
    const serviceaddress = JSON.parse(this.sessionStorageService.getItemFromloclaStorage('serviceaddress'));
    this.totalProperties = serviceaddress ? serviceaddress.length : 0;
    clearTimeout(this.timeoutAddressLookup);
    if (this.pageName === true && !!this.selectedAddress) {
      if (this.selectedAddress.customerNo) {
        this.cusNo = this.selectedAddress.customerNo;
        this.globalVariables.selected_Customer_number = this.cusNo;
        this.globalVariables.selected_customerGuid = this.selectedAddress.customerGuid;
      } else {
        this.cusNo = '';
        this.globalVariables.selected_Customer_number = this.cusNo;
        this.getCustomerAddress();
      }
    }
    this.isAddressFound = true;
    if (this.selectedAddress && !Object.keys(this.selectedAddress).length) {
      this.isAddressFound = false;
      // this.router.navigate(['/user/add-property']);
    }
  }

  gotoLink() {
    if (this.router.url.indexOf('/user/profile') > -1 || this.router.url.indexOf('/user/add-property') > -1) {
      this.globalVariables.myAccClicked = true;
      this.router.navigate(['/user/myaccount']);
      this.globalVariables.myAccClickFromHeader.emit(true);
    } else {
      this.myAccountHome = !this.myAccountHome;
      this.page = this.myAccountHome ? 'My Services' : '< Home';
      this.myAccClick.emit();
    }
  }

  gotoMyAccount() {
    if (this.myAccountHome) {
      this.gotoLink();
    }
  }

  gotoMyProfile() {
    if (this.myProfilePage) {
      this.myProfileHomeClick.emit();
    } else {
      this.router.navigate(['/user/profile']);
    }
  }

  gotoAddProperty() {
    if (this.addPropertyPage) {
      this.addPropertyHomeClick.emit();
    } else {
      this.router.navigate(['/user/add-property']);
    }
  }

  getLocationCode(zipcode: String) {
    let payload: any;
    this.locationService.getLocationCode(zipcode).subscribe(
      result => {
        const locationData = result.body;
        if (result.status === 200) {
          this.sessionStorageService.setItem('location_code', locationData.locationCodeId);
        } else {
          this.sessionStorageService.setItem('location_code', 'isOOF');
        }

        const addresses: any[] = JSON.parse(this.sessionStorageService.getItem('serviceaddress'));
        const addressResult = addresses.filter(p => p.serviceAddress.zipcode === zipcode);
        if (addressResult.length === 1) {
          locationData.locationCodeId = addressResult[0].serviceAddress.locationCodeId;
        }

        payload = {
          zipcode,
          location_code: locationData.locationCodeId,
        };
        this.addressHandler.emit(payload);
        this.locationService.ZipcodeAndLocation = payload;
        this.isInvalid = false;
      },
      () => {
        this.isInvalid = true;
        this.sessionStorageService.setItem('location_code', 'isOOF');
        this.addressHandler.emit(false);
      },
    );
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
