import { Component, OnInit, Input, OnChanges, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NguCarouselConfig } from '@ngu/carousel';
import { SessionStorageService } from '../../../core/services/session-storage.service';
import { ProductService } from '../../../product/services/product.service';
import { LocationService } from '../../../home/services/location.service';
import { UserService } from '../../../user/services/user.service';

@Component({
  selector: 'hos-neighborhood-services',
  templateUrl: './neighborhood-services.component.html',
  styleUrls: ['./neighborhood-services.component.scss'],
})
export class NeighborhoodServicesComponent implements OnInit, OnChanges {
  @Input() location_code: String;

  @Input() productId: String;

  @Input() zipcode: any;

  @Input() allProducts: Array<any> = [];

  showNeighborhoodServices: Boolean = false;

  showPage: Boolean = false;

  products: Array<any> = [];

  allProductDetails: Array<any> = [];

  isVisible: Boolean = false;

  locationId: any;

  //   zipcode: string;
  partnerName: String;

  locData: any;

  userDetails: any = [];

  shareIconIndexArray: any = [];

  public carouselTile: NguCarouselConfig;

  AddressId: string;

  setOldPriceFlag: boolean = false;

  contactType: string;

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.scrollNeighbourhoodHandler();
  }

  constructor(
    private router: Router,
    private sessionStorageService: SessionStorageService,
    private activatedRoute: ActivatedRoute,
    private locationService: LocationService,
    private productService: ProductService,
    private userService: UserService,
  ) {
    /** HWR- 2696 - Start */
    this.zipcode = this.activatedRoute.snapshot.params.zipcode;
    this.locationId = this.location_code;
    this.contactType = this.activatedRoute.snapshot.params.type;
    /** HWR- 2696 - End */
    this.userDetails = this.userService.getSignedInUser();
  }

  ngOnInit() {
    this.carouselTile = {
      grid: {
        xs: 1,
        sm: 2,
        md: 2,
        lg: 4,
        all: 0,
      },
      slide: 3,
      speed: 400,
      point: {
        visible: false,
      },
      load: 2,
      touch: true,
      easing: 'ease',
    };
  }

  ngOnChanges() {
    if (this.location_code) {
      this.AddressId = this.sessionStorageService.getItem('addressId');
      this.getProducts();
    } else {
      this.showNeighborhoodServices = false;
    }
  }

  showPages() {
    this.scrollToSection();
  }

  seeMoreHandler() {
    if (this.location_code) {
      const data = this.locationService.getZipcodeAndLocation();
      if (data) {
        this.zipcode = data.zipcode;
        this.locationId = data.location_code;
      }
      this.router.navigate([`/products/${this.zipcode}/${this.locData.locationCode.toLowerCase()}/residential`]);
    }
  }

  scrollNeighbourhoodHandler() {
    if ($('.neighbourhood-service').offset()) {
      const elementTop = $('.neighbourhood-service').offset().top;
      const elementBottom = elementTop + $('.neighbourhood-service').outerHeight();
      const viewportTop = $(window).scrollTop();
      const viewportBottom = viewportTop + $(window).height();
      if (elementBottom > viewportTop && elementTop < viewportBottom) {
        this.isVisible = true;
      } else {
        this.isVisible = false;
      }
    }
  }

  scrollToSection() {
    $('html, body').animate(
      {
        scrollTop: $('.neighbourhood-service').offset().top - 170,
      },
      1000,
    );
    document.body.scrollTop -= 200;
  }

  getProducts() {
    this.partnerName = this.sessionStorageService.getItem('websitePartnerName');
    if (this.partnerName) {
      this.productService.getProviderContent(this.sessionStorageService.getItem('location_code')).subscribe(
        locationData => {
          this.locData = locationData.body;
        },
        () => {
          this.locData = [];
        },
      );
    } else {
      this.locData = [];
    }
    if (this.userDetails && this.AddressId) {
      const serviceAddress = this.sessionStorageService.getItem('serviceaddress');
      const addresss = JSON.parse(serviceAddress);
      const userAddress = addresss.filter(item => item._id === this.AddressId);
      let contentTypeValue = 1;
      const locationCodeId = this.sessionStorageService.getItem('location_code');
      if (userAddress[0].serviceAddress.contactType === 'RESIDENTIAL') {
        contentTypeValue = 1;
      } else if (userAddress[0].serviceAddress.contactType === 'COMMERCIAL') {
        contentTypeValue = 3;
      }

      this.productService
        .getRecommendedProducts(locationCodeId, '', this.userDetails.user._id, contentTypeValue, userAddress[0].customerNo)
        .subscribe(
          data => {
            if (data.status === 200) {
              this.products = [];
              this.allProductDetails = [];
              const resData = data.body;
              this.allProductDetails = data.body.result;
              resData.filter(product => {
                if (product._id !== this.productId && this.products.length < 4) {
                  this.products.push(product);
                }
              });

              if (this.products.length > 0) {
                this.showNeighborhoodServices = true;
              }
              if (this.products.length > 0) {
                this.showOldPriceContainer(this.products);
                for (let i = 0; i < this.products.length; i++) {
                  this.shareIconIndexArray.push({
                    value: false,
                  });
                }
              }
            } else if (data.status !== 200) {
              this.products = [];
              this.allProductDetails = [];
            }
          },
          () => {
            this.products = [];
            this.allProductDetails = [];
          },
        );
    } else {
      this.allProductDetails = this.allProducts;
      this.allProducts?.forEach(product => {
        if (product._id !== this.productId && this.products.length < 4) {
          this.products.push(product);
        }
      });
      if (this.products.length > 0) {
        this.showNeighborhoodServices = true;
        this.showOldPriceContainer(this.products);
      }
    }
  }

  showOldPriceContainer(products) {
    if (products) {
      products.forEach(item => {
        if (item.oldPrice || (item.oldPriceText && !['', undefined, 'undefined', null, 'null'].includes(item.oldPriceText))) {
          this.setOldPriceFlag = true;
        }
      });
    }
  }
}
