import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
  name: 'blogIdPipe',
})
export class BlogIdPipe implements PipeTransform {
  transform(story) {
    return `/blog/${story.entryId || story.id}`;
  }
}
