import { Directive, ElementRef, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'img',
})
export class LazyImgDirective {
  isBrowser: any;

  constructor({ nativeElement }: ElementRef, @Inject(PLATFORM_ID) private platformId: any) {
    this.isBrowser = isPlatformBrowser(this.platformId);
    if (this.isBrowser) {
      const supports = 'loading' in HTMLImageElement.prototype;
      if (supports) {
        const nolazyload = nativeElement.getAttribute('nolazyload');
        if (nolazyload && nolazyload === 'do-not-lazy-load') {
          return;
        }
        if (nativeElement.ger) nativeElement.setAttribute('loading', 'lazy');
      } else {
        // fallback to IntersectionObserver
      }
    }
  }
}
