import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CanActivateViaLoggedinGuard } from './core/guards/logged.guards';
import { BodyComponent } from './home/component/body/body.component';
import { PageNotFound404Component } from './page-not-found404/component/page-not-found404.component';
import { UrlSearchComponent } from './url-search/url-search.component';

export const routes: Routes = [
  {
    path: '',
    component: BodyComponent,
    pathMatch: 'full',
    canActivate: [CanActivateViaLoggedinGuard],
  },
  {
    path: 'user',
    loadChildren: () => import('./user/user.module').then(m => m.UserModule),
  },
  {
    path: 'products',
    loadChildren: () => import('./product/product.module').then(m => m.ProductModule),
  },
  {
    path: 'product',
    loadChildren: () => import('./product-details/product-details.module').then(m => m.ProductDetailModule),
  },
  {
    path: 'service',
    loadChildren: () => import('./services-generic-details/services-generic-detail.module').then(m => m.ServicesGenericDetailModule),
  },
  {
    path: 'cart',
    loadChildren: () => import('./cart/cart.module').then(m => m.CartModule),
  },
  {
    path: 'blog',
    loadChildren: () => import('./blog/blog.module').then(m => m.BlogModule),
  },
  {
    path: 'blog/:entryId',
    loadChildren: () => import('./blog/blog.module').then(m => m.BlogModule),
  },
  {
    path: 'page',
    loadChildren: () => import('./liferay-static-pages/static-pages.module').then(m => m.PageModule),
  },
  {
    path: 'payment',
    loadChildren: () => import('./payment-ets/payment-ets.module').then(m => m.PaymentEtsModule),
  },
  {
    path: 'support',
    loadChildren: () => import('./forms/form.module').then(m => m.FormModule),
  },
  {
    path: 'terms',
    loadChildren: () => import('./terms/terms.module').then(m => m.TermsModule),
  },
  {
    path: 'city-ordinance',
    loadChildren: () => import('./city-ordinance/city-ordinance.module').then(m => m.CityOrdinanceModule),
  },
  {
    path: 'sitemap',
    loadChildren: () => import('./url-listing/url-listing.module').then(m => m.UrlListingModule),
  },
  {
    path: 'pages',
    loadChildren: () => import('./landing-pages/landing-page.module').then(m => m.LandingPageModule),
  },
  {
    path: 'landing-page',
    loadChildren: () => import('./unbounce/unbounce.module').then(m => m.UnbouncePageModule),
  },
  {
    path: 'nw-terms',
    loadChildren: () => import('./nw-terms/nwterms.module').then(m => m.NwTermsModule),
  },
  {
    path: '404',
    component: PageNotFound404Component,
  },
  {
    path: '**',
    component: UrlSearchComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
