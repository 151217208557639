import { NgModule } from '@angular/core';
import { SafeHtmlPipe } from './safe-html';
import { BlogIdPipe } from './blog-id-pipe';
import { EnrollmentStatusTextPipe } from './enrollment-status-text.pipe';

@NgModule({
  imports: [],
  declarations: [SafeHtmlPipe, BlogIdPipe, EnrollmentStatusTextPipe],
  exports: [SafeHtmlPipe, BlogIdPipe, EnrollmentStatusTextPipe],
})
export class PipesModule {}
