<div class="partner-section">
  <section class="row page-wrap col-12 partner-flex-cls">
    <div class="partner-card col-12 col-md-6 partner-flex-cls" *ngIf="siteContent?.saveYourselfLinks?.length">
      <div class="card">
        <div class="header">
          <div class="headerIcon">
            <img src="/img/microsites/eva_phone-call-outline.svg" alt="Phone call outline icon" />
          </div>
          <div class="headerText">Save Yourself a Call</div>
        </div>
        <div class="card-body pt-1">
          <div class="row card-list">
            <div class="col-12 col-md-6 MB-10" *ngFor="let anchor of siteContent.saveYourselfLinks">
              <a [routerLink]="anchor.link">{{ anchor.label }} ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="partner-card col-12 col-md-6 partner-flex-cls MB-0" *ngIf="siteContent?.homeOwnerLinks?.length">
      <div class="card">
        <div class="header">
          <div class="headerIcon">
            <img src="/img/microsites/homeowner_hub.svg" alt="Homeowner hub icon" />
          </div>
          <div class="headerText">Homeowner Hub</div>
        </div>
        <div class="card-body">
          <div class="row card-list">
            <div class="col-12 col-md-6 MB-10" *ngFor="let anchor of siteContent.homeOwnerLinks">
              <a [routerLink]="anchor.link">{{ anchor.label }} ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
