import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SimpleModalService } from 'ngx-simple-modal';
import { UserService } from '../../services/user.service';
import { ErrorModalComponent } from '../../../core/component/error-modal/error-modal.component';
import { AlertService } from '../../../core/services/alert.service';
import { environment } from '../../../../environments/environment';
import { UtilsService } from '../../../core/services/utils.service';

@Component({
  selector: 'hos-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent {
  showError: Boolean = false;

  isNotConfirmed: Boolean = false;

  errorMessage: String = '';

  strOTP: String = '';

  isClicked: Boolean;

  isPassChanged: Boolean = false;

  today: Date = new Date();

  password_regex: String;

  passwordModel: String;

  confirmPasswordModel: String;

  contactLink: any;

  /**
   *
   * Creates an instance of ResetPasswordComponent.
   *
   * @param {Router} router
   * @param {UserService} userService
   *
   * @memberof ResetPasswordComponent
   */
  constructor(
    private router: Router,
    private userService: UserService,
    private simpleModalService: SimpleModalService,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private utilsService: UtilsService,
  ) {
    this.strOTP = this.activatedRoute.snapshot.queryParams.token;

    this.password_regex = environment.password_regex;
    utilsService.getContactLink$().subscribe(linkData => {
      this.contactLink = `/page/contact-us/${linkData.entryId}`;
    });
  }

  /**
   * Form submit handler
   *
   * @param {any} form
   *
   * @memberof ResetPasswordComponent
   */

  onSubmit(form: any) {
    this.errorMessage = '';
    this.isClicked = true;
    if (form.valid && form.value.password === form.value.confirmPassword) {
      this.isNotConfirmed = false;
      this.showError = false;
      this.userService.resetPassword(form.value.password, this.strOTP).subscribe(
        data => {
          this.isClicked = false;
          this.errorMessage = '';
          if (data.status === 200) {
            this.isPassChanged = true;
            this.alertService.success('Your password has been changed');
          }
        },
        error => {
          if (error.status === 400) {
            const errorTitle = 'Sorry, we have a problem with your reset password request';
            // tslint:disable-next-line:max-line-length
            const errorMessage =
              'The reset password link has expired. Please try the reset password option again to receive instructions on how to reset your password.';
            this.showErrorModal(errorTitle, errorMessage, 'Close');
          } else {
            this.showError = true;
            this.errorMessage = error.error.message;
          }
          this.isClicked = false;
        },
      );
    } else {
      this.showError = true;
      if (form.value.password !== form.value.confirmPassword) {
        this.isNotConfirmed = true;
      }
    }
  }

  keyPressHandler(input: any) {
    const field = input;
    field.isBlur = false;
  }

  blurHandler(input: any) {
    const field = input;
    field.isBlur = true;
  }

  showErrorModal(title: string, message: string, btnText: string) {
    this.simpleModalService
      .addModal(
        ErrorModalComponent,
        {
          title,
          message,
          confirmationMsg: btnText,
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: true,
        },
      )
      .subscribe(() => {});
  }
}
