import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable()
export class GooglePlacesService {
  googlePlacesBaseUrl: string;

  /**
   * Creates an instance of googlePlacesBaseUrl.
   * @param {HttpClient} http
   *
   * @memberof googlePlacesBaseUrl
   */
  constructor(private http: HttpClient) {
    this.googlePlacesBaseUrl = environment.google_base_url;
  }

  /**
   *
   * @param {string} category
   * @returns {Observable<any>}
   *
   * @memberof LiferayService
   */
  getCityAndStateData(zipcode: String): any {
    return this.http
      .get(`${this.googlePlacesBaseUrl}geocode/json`, {
        observe: 'response',
        params: {
          address: `${zipcode} USA`,
          key: environment.google_key,
        },
      })
      .pipe(map(res => res));
  }
}
