import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SimpleModalComponent, SimpleModalService } from 'ngx-simple-modal';
import { WindowScrolling } from '../../../core/services/window-scrolling.service';

export interface CustomerPropertySelectionModalModel {
  title: string;
  message: string;
  alertIcon: string;
  confirmText: string;
  data: any;
}

@Component({
  selector: 'hos-customer-property-selection-modal',
  templateUrl: './customer-property-selection-modal.component.html',
  styleUrls: ['./customer-property-selection-modal.component.scss'],
})
export class CustomerPropertySelectionModalComponent
  extends SimpleModalComponent<CustomerPropertySelectionModalModel, Boolean>
  implements CustomerPropertySelectionModalModel
{
  title: string;

  message: string;

  alertIcon: string;

  confirmText: string;

  data: any;

  error: Boolean = false;

  selectedCusNo: any;

  constructor(
    simpleModalService: SimpleModalService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private windowScrolling: WindowScrolling,
  ) {
    super();
    // this.windowScrolling.disable();
  }

  selectCode(selectedCusNo: String) {
    this.error = false;
    this.selectedCusNo = selectedCusNo;
  }

  confirmHandler() {
    if (!this.selectedCusNo) {
      this.error = true;
    } else {
      this.result = this.selectedCusNo;
      this.close();
      this.windowScrolling.enable();
    }
  }
}
