<ng-container *ngIf="showBannerImageComp">
  <hos-hero-banner></hos-hero-banner>
</ng-container>

<h1 class="title-heading-seo">American Water Home Page</h1>
<!-- Please don't remove we require a h1 tag on each page for SEO purposes-->

<main class="content">
  <!-- category component here-->
  <hos-category></hos-category>

  <!-- google review component here-->
  <hos-google-review class="padding-bottom-section"></hos-google-review>

  <!-- Product card component -->
  <section hosLazyLoad class="content-section padding-bottom-section PT-0" *ngIf="showBannerImageComp && products && products?.length > 0">
    <article class="page-wrap set-border paddTM20 paddB50 home-page-product-card">
      <div class="protection-class">Home Protection Plans</div>
      <div *ngIf="products">
        <ngu-carousel
          [inputs]="carouselTile"
          [dataSource]="products"
          class="category-tile"
          [ngClass]="{ 'carousel-center-flex': products.length < 3, 'multi-product-card': products.length < 4 }"
        >
          <ngu-tile *nguCarouselDef="let product of products; let i = index">
            <hos-product-card-home-page [product]="product" [isCategory]="isCategory" [mkcCategories]="mkcCategories"> </hos-product-card-home-page>
          </ngu-tile>
          <button NguCarouselPrev class="slide_arrow leftRs" [ngClass]="{ 'hide-slider': products.length < 3 }">&lt;</button>
          <button NguCarouselNext class="slide_arrow rightRs" [ngClass]="{ 'hide-slider': products.length < 3 }">&gt;</button>
        </ngu-carousel>
      </div>
    </article>
  </section>

  <!-- Partner detail component here-->
  <section class="padding-bottom-section" *ngIf="!!sessionStorageService.getItem('providerState')">
    <hos-partner-detail></hos-partner-detail>
  </section>
  <div hosLazyLoad>
    <ng-container *ngIf="globalVariables.showLazyLoadContent">
      <!-- how it works component here-->
      <section class="padding-bottom-section">
        <hos-how-it-works></hos-how-it-works>
      </section>

      <!-- Stories component here-->
      <!-- <hos-stories></hos-stories> -->

      <!-- testimonial component here-->
      <section class="padding-bottom-section">
        <hos-testimonial></hos-testimonial>
      </section>

      <!-- partner component here-->
      <section class="padding-bottom-section" *ngIf="!sessionStorageService.getItem('providerState')">
        <hos-partner></hos-partner>
      </section>
    </ng-container>
  </div>
</main>
