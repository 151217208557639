<div class="search-bar search-panel">
  <form class="search form-inline" (submit)="getStartedClick()">
    <div class="search-field form-group" [ngClass]="{ 'is-invalid': isInvalid }">
      <input
        type="text"
        maxlength="5"
        (keyup)="changeHandler($event)"
        class="search-zipcode text-center"
        [(ngModel)]="strAddress"
        name="zipcode"
        placeholder="{{ placeholder }}"
        (keypress)="numberOnly($event)"
      />
    </div>
    <div class="row product-dtls-zip-err-text error-zipcode">
      <small class="form-text text-danger error-text" id="searchHelp" *ngIf="isInvalid">Please enter valid zip code.</small>
    </div>
    <span class="product-details-zip-text">{{ subtitleText }}</span>
    <button
      class="btn primary-btn get-started-btn product-page-search text-transform-none"
      angulartics2On="click"
      angularticsLabel="zip_code_search"
      angularticsValue="0"
      angularticsAction="click"
      angularticsCategory="button"
      type="submit"
    >
      <span class="desktop-only text-visible">{{ btnText }}</span>
      <span class="mobile-only serach-wrap"><i class="fa fa-search mobile-serach" aria-hidden="true"></i></span>
    </button>
  </form>
  <div class="row error-zipcode" [class.invalid-error]="isInvalid">
    <small class="form-text text-danger error-text" id="searchHelp" *ngIf="isInvalid">Please enter valid zip code.</small>
  </div>
</div>
