<div class="modal-dialog custom-error-alert modal-content modal-background">
  <div class="modal-header modal-custom-header">
    <div class="info-background mx-auto">
      <i class="fa fa-info-circle my-auto" aria-hidden="true"></i>
    </div>
  </div>
  <div class="modal-body" id="achTerms">
    <div id="scroll-bar">
      <!-- <h4 class="my-3">TERMS OF AUTHORIZATION FOR DIRECT ACH DEBIT OPTION</h4> -->
      <!-- <p class="terms-body custom-scroll mb-0"> Your access to, and use of American Water Resources (AWR) preauthorized transfer payment program (“Direct Debit”)
                is subject to the following terms and conditions of authorization (“Terms of Authorization”) and all applicable
                laws. These Terms of Authorization apply exclusively to your access to, and use of Direct Debit payment and
                do not alter in any way the terms or conditions of any other agreement you may have with AWR for products,
                services or otherwise. YOU MUST AGREE TO THESE TERMS OF AUTHORIZATION TO ENROLL IN THE PREAUTHORIZED TRANSFER
                PAYMENT PROGRAM. If you chose to use the Direct Debit method of payment for the AWR Protection Program(s)
                (the “Program(s)”) you are enrolling in, your electronic signature as part of the online enrollment will
                serve as acknowledgement that you have reviewed and accepted these Terms of Authorization. Please retain
                these Terms of Authorization for your records.<br>
                1. AUTHORIZATION: By selecting Direct Debit as your payment method and enrolling online, you hereby authorize your bank or
                financial institution to make preauthorized electronic bill payments to AWR by debiting the account for which
                you provided information in the online enrollment. You also authorize AWR to credit or debit the account
                to correct any payment errors. This authorization will remain in effect until you cancel it, as provided
                in Section 2, below, or your obligation to pay AWR has ended. If the Program(s) you are enrolled in auto-renews
                on an annual basis then the Terms of Authorization will apply to each renewal of the Program(s)<br>
                2. CANCELLING AUTHORIZATION: You may cancel authorization for the Direct Debit by notifying AWR in writing to American Water
                Resources, LLC, Attention: Service Line Protection Administrator, 1420 Discovery Parkway, Alton, Illinois
                62002 or by calling 1-888-207-5228. We will process your cancellation within a reasonable time following
                receipt of your notice. You may also contact your financial institution to stop payments under this authorization,
                at least 3 business days before a scheduled transfer.<br>
                3. REJECTION OF AUTOMATIC PAYMENTS: If your bank or financial institution rejects any specific transfer for any reason other
                than cancellation of this authorization, AWR may resubmit any such returned payment as permitted by applicable
                law and payment network rules.<br>
                4. VARIATION OF PAYMENT: The transfers made through the Programs may vary. If there is a variation in the monthly or annual
                amount to be transferred, AWR will provide you a notice of the amount to be transferred at least ten (10)
                days prior to the date of such transfer. You will have the ability to stop payment on such transfer up to
                three (3) days prior to the date of such transfer by contacting AWR at 1-888-207-5228.<br>
                5. DISCLAIMER: THE PREAUTHORIZED TRANSFER PAYMENT PROGRAM IS PROVIDED ON AN “AS IS” BASIS WITHOUT WARRANTIES OF ANY KIND,
                EITHER EXPRESS OR IMPLIED, AND TO THE EXTENT PERMITTED BY APPLICABLE LAW AWR DISCLAIMS ALL WARRANTIES, EXPRESS
                OR IMPLIED.<br>
                6. LIMITATION OF LIABILITY: IN NO EVENT SHALL AWR OR ITS OFFICERS, DIRECTORS, SHAREHOLDERS OR EMPLOYEES BE LIABLE FOR ANY
                INDIRECT, SPECIAL OR CONSEQUENTIAL DAMAGES OF ANY KIND WHETHER IN AN ACTION IN CONTRACT, TORT (INCLUDING
                BUT NOT LIMITED TO NEGLIGENCE) OR OTHERWISE, ARISING OUT OF OR IN ANY WAY CONNECTED TO THE PREAUTHORIZED
                TRANSFER PAYMENT PROGRAM (DIRECT DEBIT PAYMENT).
            </p> -->
      <h4 class="heading"><strong>TERMS OF AUTHORIZATION FOR DIRECT DEBIT OPTION</strong></h4>
      <p>
        Your access to, and use of American Water Resources (AWR) preauthorized transfer payment program (&ldquo;Direct Debit&rdquo;) is subject to
        the following terms and conditions of authorization (&ldquo;Terms of Authorization&rdquo;) and all applicable laws. These Terms of
        Authorization apply exclusively to your access to, and use of Direct Debit payment and do not alter in any way the terms or conditions of any
        other agreement you may have with AWR for products, services or otherwise. YOU MUST AGREE TO THESE TERMS OF AUTHORIZATION TO ENROLL IN THE
        PREAUTHORIZED TRANSFER PAYMENT PROGRAM. If you chose to use the Direct Debit method of payment for the AWR Protection Program(s) (the
        &ldquo;Program(s)&rdquo;) you are enrolling in, your signature on the enrollment form will serve as acknowledgement that you have reviewed and
        accepted these Terms of Authorization. Please retain these Terms of Authorization for your records.
      </p>
      <p>
        <strong>1. AUTHORIZATION</strong>: By selecting Direct Debit as your payment method and completing the enrollment form, you hereby authorize
        your bank or financial institution to make preauthorized electronic bill payments to AWR by debiting the account for which you provided
        information on the enrollment form. You also authorize AWR to credit or debit the account to correct any payment errors. This authorization
        will remain in effect until you cancel it, as provided in Section 2, below, or your obligation to pay AWR has ended. If the Program(s) you are
        enrolled in auto-renews on an annual basis then the Terms of Authorization will apply to each renewal of the Program(s).
      </p>
      <p>
        <strong>2. CANCELLING AUTHORIZATION</strong>: You may cancel authorization for the Direct Debit by notifying AWR in writing to American Water
        Resources, LLC, Attention: Service Line Protection Administrator, 1420 Discovery Parkway, Alton, Illinois 62002 or by calling 1-866-641-2177.
        We will process your cancellation within a reasonable time following receipt of your notice. You may also contact your financial institution
        to stop payments under this authorization, at least 3 business days before a scheduled transfer.
      </p>
      <p>
        <strong>3. REJECTION OF AUTOMATIC PAYMENTS</strong>: If your bank or financial institution rejects any specific transfer for any reason other
        than cancellation of this authorization, AWR may resubmit any such returned payment as permitted by applicable law and payment network rules.
      </p>
      <p>
        <strong>4. VARIATION OF PAYMENT</strong>: The transfers made through the Programs may vary. If there is a variation in the monthly or annual
        amount to be transferred, AWR will provide you a notice of the amount to be transferred at least ten (10) days prior to the date of such
        transfer. You will have the ability to stop payment on such transfer up to three (3) days prior to the date of such transfer by contacting AWR
        at 1-866-641-2177.
      </p>
      <p>
        <strong>5. DISCLAIMER</strong>: THE PREAUTHORIZED TRANSFER PAYMENT PROGRAM IS PROVIDED ON AN &ldquo;AS IS&rdquo; BASIS WITHOUT WARRANTIES OF
        ANY KIND, EITHER EXPRESS OR IMPLIED, AND TO THE EXTENT PERMITTED BY APPLICABLE LAW AWR DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED.
      </p>
      <p>
        <strong>6. LIMITATION OF LIABILITY</strong>: IN NO EVENT SHALL AWR OR ITS OFFICERS, DIRECTORS, SHAREHOLDERS OR EMPLOYEES BE LIABLE FOR ANY
        INDIRECT, SPECIAL OR CONSEQUENTIAL DAMAGES OF ANY KIND WHETHER IN AN ACTION IN CONTRACT, TORT (INCLUDING BUT NOT LIMITED TO NEGLIGENCE) OR
        OTHERWISE, ARISING OUT OF OR IN ANY WAY CONNECTED TO THE PREAUTHORIZED TRANSFER PAYMENT PROGRAM (DIRECT DEBIT PAYMENT).
      </p>
    </div>
  </div>

  <div class="modal-footer justify-content-start">
    <button (click)="print()" class="btn block full-width print-btn mr-auto"><i class="fa fa-print" aria-hidden="true"></i> &nbsp; PRINT</button>
    <button (click)="close()" class="btn submit-btn block full-width app-btn my-auto">CLOSE</button>
  </div>
</div>
