import { Component, HostListener, OnInit, PLATFORM_ID, Inject, AfterContentInit } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../environments/environment';
import { UserService } from './user/services/user.service';
import { CartService } from './cart/services/cart.service';
import { SessionStorageService } from './core/services/session-storage.service';
import { GlobalVariables } from './core/services/global-variables.service';

@Component({
  selector: 'hos-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterContentInit {
  title = 'HOS';

  showPage = false;

  fixedHeader = false;

  isConnected = false;

  isBrowser = true;

  adminURL: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private router: Router,
    private cartService: CartService,
    @Inject(PLATFORM_ID) private platformId: any,
    private seotitle: Title,
    private meta: Meta,
    private sessionStorageService: SessionStorageService,
    private globalVariables: GlobalVariables,
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
    try {
      if (this.isBrowser) {
        if (!(location.hostname.indexOf(environment.API_Endpoint) !== -1) || !!sessionStorage.getItem('siteHeader')) {
          this.showPage = true;
        } else {
          this.showPage = false;
        }
      }
    } catch (ex) {
      console.error(ex);
    }
    this.adminURL = environment.ADMIN_URL;
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.activatedRoute.queryParams.subscribe(params => {
        if (!['', null, undefined].includes(params.lang)) {
          this.setCookie('googtrans', `/en/${params.lang}`);
        }
        const utmParams = params.utm_source;
        if (!this.sessionStorageService.getItem('utmParams')) {
          this.sessionStorageService.setItem('utmParams', '');
        }
        if (utmParams) {
          this.sessionStorageService.setItem(
            'utmParams',
            `utm_source=${params.utm_source}&utm_medium=${params.utm_medium}&utm_campaign=${params.utm_campaign}&utm_content=${params.utm_content}`,
          );
        }
        window.addEventListener('message', this.receiveMessage.bind(this), false);
      });
    }
  }

  ngAfterContentInit() {
    if (this.isBrowser) {
      setTimeout(() => {
        this.loadThirdPartyScripts();
      }, 1500);
    }
  }

  loadThirdPartyScripts() {
    this.globalVariables.loadScriptsFunction.emit(true);
    // add scripts here
    const scripts = [
      'https://www.google.com/recaptcha/api.js',
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyB6LqUjwivm2ThffV5fWF8O68j5jYbA4uc&libraries=places',
    ];

    scripts.forEach(src => {
      const scriptElement = document.createElement('script');
      scriptElement.src = src;
      scriptElement.defer = true;
      document.head.appendChild(scriptElement);
    });
    this.globalVariables.scriptsLoaded = true;
  }

  showPageHandler() {
    this.showPage = true;
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.setFixedHeader();
  }

  setFixedHeader() {
    if (navigator.userAgent.match(/Trident\/7\./)) {
      if (window.pageYOffset > 100) {
        this.fixedHeader = true;
        $('body').addClass('fixed-nav');
      } else {
        this.fixedHeader = false;
        $('body').removeClass('fixed-nav');
      }
    } else if (window.scrollY > 100) {
      this.fixedHeader = true;
      $('body').addClass('fixed-nav');
    } else {
      this.fixedHeader = false;
      $('body').removeClass('fixed-nav');
    }
  }

  async receiveMessage(event) {
    if (event.origin !== this.adminURL) {
      return;
    }
    this.userService.signOut();
    this.router.navigate(['/']);
    const token = event.data.token.split(' ');
    try {
      const adminSignInAsUserData = await this.userService.adminSignInAsUser(event.data.email, token[1], true).toPromise();
      const userData = adminSignInAsUserData.body;

      if (adminSignInAsUserData.status === 200) {
        this.sessionStorageService.setItem('authHeader', userData.authHeader);
        this.sessionStorageService.setItem('refreshToken', userData.refreshToken);
        const userDetails = this.userService.getSignedInUser();

        await this.cartService.getCustomerCartToLocal(userDetails.user._id, '');
        this.userService.setLoggin(userData.authHeader);

        const customerData = await this.userService.getUserProfile(userDetails.user._id).toPromise();
        const customer = customerData.body;
        this.sessionStorageService.setItem('userProfileDetails', JSON.stringify(customer));

        window.open(window.location.href);
        // this.router.navigate(['/user/myaccount']);
      }
    } catch (error) {
      console.log(error);
    }
}

  onActivate() {
    if (this.isBrowser) {
      window.scrollTo(0, 0);
      $('body').css('padding-top', '');
    }
  }

  closeSideBar() {
    document.body.classList.remove('sidebar-open');
    document.getElementById('box').classList.remove('active');
    document.getElementById('backdrop-sidemenu').style.display = 'none';
    const list = document.getElementsByClassName('accordmenucls');
    for (let index = 0; index < list.length; ++index) {
      list[index].setAttribute('aria-expanded', 'false');
    }
  }

  setCookie(name, value) {
    document.cookie = `${name}=${value};path= /`;
    document.cookie = `${name}=${value};domain=.awapps.com;path= /`;
    document.cookie = `${name}=${value};domain=.awrusa.com;path= /`;
  }
}
