import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { HttpService } from './http';
import { SessionStorageService } from './session-storage.service';
import { environment } from '../../../environments/environment';
import { GlobalVariables } from './global-variables.service';

@Injectable()
export class ZipcodeService {
  constructor(
    private router: Router,
    private http: HttpService,
    private sessionStorageService: SessionStorageService,
    private globalVariables: GlobalVariables,
  ) {}

  getZipcodeId(zipcode) {
    return this.http.get(`${environment.Location_service_API_Endpoint}zip_codes/${zipcode}`).pipe(
      map(result => {
        return result;
      }),
    );
  }
}
