export const copyCodeConfig = {
  V3: {
    WL_New_Monthly_Price: 'Water Line Protection Program',
  },
  V4: {
    SL_New_Monthly_Price: 'Sewer Line Protection Program',
  },
  V5: {
    Combo_New_Monthly_Price: 'Water Line and Sewer Line Protection Program',
  },
  V6: {
    Triple_New_Monthly_Price: 'Water Line, Sewer Line, and In-Home Plumbing Emergency Program',
  },
  V7: {
    WL_New_Monthly_Price: 'Unlimited Water Line Protection Program',
  },
  V8: {
    SL_New_Monthly_Price: 'Unlimited Sewer Line Protection Program',
  },
  V9: {
    Combo_New_Monthly_Price: 'Unlimited Water Line and Sewer Line Protection Program',
  },
  V10: {
    WL_New_Monthly_Price: 'Water Line Protection Program',
    SL_New_Monthly_Price: 'Unlimited Sewer Line Protection Program',
  },
  V11: {
    WL_New_Monthly_Price: 'Unlimited Water Line Protection Program',
    SL_New_Monthly_Price: 'Sewer Line Protection Program',
  },
  V12: {
    WL_New_Monthly_Price: 'Commercial Water Line Protection Program',
  },
  V13: {
    SL_New_Monthly_Price: 'Commercial Sewer Line Protection Program',
  },
  V14: {
    Combo_New_Monthly_Price: 'Commercial Water Line and Sewer Line Protection Program',
  },
  V15: {
    IHP_New_Monthly_Price: 'In-Home Plumbing Emergency Program',
  },
  V22: {
    WL_New_Monthly_Price: 'Unlimited Water Line Protection Program',
    IHP_New_Monthly_Price: 'In-Home Plumbing Emergency Program',
  },
  V23: {
    SL_New_Monthly_Price: 'Unlimited Sewer Line Protection Program',
    IHP_New_Monthly_Price: 'In-Home Plumbing Emergency Program',
  },
  V24: {
    Combo_New_Monthly_Price: 'Unlimited Water Line and Sewer Line Protection Program',
    IHP_New_Monthly_Price: 'In-Home Plumbing Emergency Program',
  },
  V28: {
    WL_New_Monthly_Price: 'Water Line Protection Program',
    SL_New_Monthly_Price: 'Unlimited Sewer Line Protection Program',
    IHP_New_Monthly_Price: 'In-Home Plumbing Emergency Program',
  },
  V31: {
    WL_New_Monthly_Price: 'Unlimited Water Line Protection Program',
    SL_New_Monthly_Price: 'Sewer Line Protection Program',
    IHP_New_Monthly_Price: 'In-Home Plumbing Emergency Program',
  },
  V35: {
    WL_New_Monthly_Price: 'Water Line Protection Program',
    EIHP_New_Monthly_Price: 'In-Home Plumbing Plus Program',
  },
  V36: {
    SL_New_Monthly_Price: 'Sewer Line Protection Program',
    EIHP_New_Monthly_Price: 'In-Home Plumbing Plus Program',
  },
  V37: {
    Combo_New_Monthly_Price: 'Water Line and Sewer Line Protection Program',
    EIHP_New_Monthly_Price: 'In-Home Plumbing Plus Program',
  },
  V38: {
    WL_New_Monthly_Price: 'Unlimited Water Line Protection Program',
    EIHP_New_Monthly_Price: 'In-Home Plumbing Plus Program',
  },
  V39: {
    SL_New_Monthly_Price: 'Unlimited Sewer Line Protection Program',
    EIHP_New_Monthly_Price: 'In-Home Plumbing Plus Program',
  },
  V40: {
    Combo_New_Monthly_Price: 'Unlimited Water Line and Sewer Line Protection Program',
    EIHP_New_Monthly_Price: 'In-Home Plumbing Plus Program',
  },
  V41: {
    WL_New_Monthly_Price: 'Unlimited Water Line Protection Program',
    SL_New_Monthly_Price: 'Sewer Line Protection Program',
    EIHP_New_Monthly_Price: 'In-Home Plumbing Plus Program',
  },
  V42: {
    WL_New_Monthly_Price: 'Water Line Protection Program',
    SL_New_Monthly_Price: 'Unlimited Sewer Line Protection Program',
    EIHP_New_Monthly_Price: 'In-Home Plumbing Plus Program',
  },
  V43: {
    EIHP_New_Monthly_Price: 'In-Home Plumbing Plus Program',
  },
  V44: {
    WL_New_Monthly_Price: 'Water Line Protection Program',
    IHP_New_Monthly_Price: 'In-Home Plumbing Emergency Program',
  },
  V45: {
    SL_New_Monthly_Price: 'Sewer Line Protection Program',
    IHP_New_Monthly_Price: 'In-Home Plumbing Emergency Program',
  },
  V46: {
    HWHP_New_Monthly_Price: 'Water Heater Repair and Replacement Program',
  },
  V47: {
    WL_New_Monthly_Price: 'Water Line Protection Program',
    HWHP_New_Monthly_Price: 'Water Heater Repair and Replacement Program',
  },
  V48: {
    SL_New_Monthly_Price: 'Sewer Line Protection Program',
    HWHP_New_Monthly_Price: 'Water Heater Repair and Replacement Program',
  },
  V49: {
    Combo_New_Monthly_Price: 'Water Line and Sewer Line Protection Program',
    HWHP_New_Monthly_Price: 'Water Heater Repair and Replacement Program',
  },
  V50: {
    Triple_New_Monthly_Price: 'Water Line, Sewer Line, and In-Home Plumbing Emergency Program',
    HWHP_New_Monthly_Price: 'Water Heater Repair and Replacement Program',
  },
  V51: {
    IHP_New_Monthly_Price: 'In-Home Plumbing Emergency Program',
    HWHP_New_Monthly_Price: 'Water Heater Repair and Replacement Program',
  },
  V52: {
    WL_New_Monthly_Price: 'Unlimited Water Line Protection Program',
    HWHP_New_Monthly_Price: 'Water Heater Repair and Replacement Program',
  },
  V53: {
    SL_New_Monthly_Price: 'Unlimited Sewer Line Protection Program',
    HWHP_New_Monthly_Price: 'Water Heater Repair and Replacement Program',
  },
  V54: {
    Combo_New_Monthly_Price: 'Unlimited Water Line and Sewer Line Protection Program',
    HWHP_New_Monthly_Price: 'Water Heater Repair and Replacement Program',
  },
  V55: {
    WL_New_Monthly_Price: 'Water Line Protection Program',
    SL_New_Monthly_Price: 'Unlimited Sewer Line Protection Program',
    HWHP_New_Monthly_Price: 'Water Heater Repair and Replacement Program',
  },
  V56: {
    WL_New_Monthly_Price: 'Water Line Protection Program',
    SL_New_Monthly_Price: 'Unlimited Sewer Line Protection Program',
    IHP_New_Monthly_Price: 'In-Home Plumbing Emergency Program',
    HWHP_New_Monthly_Price: 'Water Heater Repair and Replacement Program',
  },
  V57: {
    WL_New_Monthly_Price: 'Unlimited Water Line Protection Program',
    SL_New_Monthly_Price: 'Sewer Line Protection Program',
    HWHP_New_Monthly_Price: 'Water Heater Repair and Replacement Program',
  },
  V58: {
    WL_New_Monthly_Price: 'Water Line Protection Program',
    SL_New_Monthly_Price: 'Sewer Line Protection Program',
    IHP_New_Monthly_Price: 'In-Home Plumbing Emergency Program',
    HWHP_New_Monthly_Price: 'Water Heater Repair and Replacement Program',
  },
  V59: {
    Combo_New_Monthly_Price: 'Unlimited Water Line and Sewer Line Protection Program',
    IHP_New_Monthly_Price: 'In-Home Plumbing Emergency Program',
    HWHP_New_Monthly_Price: 'Water Heater Repair and Replacement Program',
  },
  V60: {
    WL_New_Monthly_Price: 'Water Line Protection Program',
    IHP_New_Monthly_Price: 'In-Home Plumbing Emergency Program',
    HWHP_New_Monthly_Price: 'Water Heater Repair and Replacement Program',
  },
  V61: {
    SL_New_Monthly_Price: 'Sewer Line Protection Program',
    IHP_New_Monthly_Price: 'In-Home Plumbing Emergency Program',
    HWHP_New_Monthly_Price: 'Water Heater Repair and Replacement Program',
  },
  V62: {
    EIHP_New_Monthly_Price: 'In-Home Plumbing Plus Program',
    HWHP_New_Monthly_Price: 'Water Heater Repair and Replacement Program',
  },
  V63: {
    WL_New_Monthly_Price: 'Water Line Protection Program',
    SL_New_Monthly_Price: 'Unlimited Sewer Line Protection Program',
    EIHP_New_Monthly_Price: 'In-Home Plumbing Plus Program',
    HWHP_New_Monthly_Price: 'Water Heater Repair and Replacement Program',
  },
  V64: {
    WL_New_Monthly_Price: 'Water Line Protection Program',
    SL_New_Monthly_Price: 'Sewer Line Protection Program',
    EIHP_New_Monthly_Price: 'In-Home Plumbing Plus Program',
    HWHP_New_Monthly_Price: 'Water Heater Repair and Replacement Program',
  },
  V65: {
    Combo_New_Monthly_Price: 'Unlimited Water Line and Sewer Line Protection Program',
    EIHP_New_Monthly_Price: 'In-Home Plumbing Plus Program',
    HWHP_New_Monthly_Price: 'Water Heater Repair and Replacement Program',
  },
  V66: {
    Combo_New_Monthly_Price: 'Water Line and Sewer Line Protection Program',
    EIHP_New_Monthly_Price: 'In-Home Plumbing Plus Program',
    HWHP_New_Monthly_Price: 'Water Heater Repair and Replacement Program',
  },
  V67: {
    WL_New_Monthly_Price: 'Water Line Protection Program',
    SL_New_Monthly_Price: 'Sewer Line Protection Program',
    HWHP_New_Monthly_Price: 'Water Heater Repair and Replacement Program',
  },
  V68: {
    Combo_New_Monthly_Price: 'Water Line and Sewer Line Protection Program',
    IHP_New_Monthly_Price: 'In-Home Plumbing Emergency Program',
    HWHP_New_Monthly_Price: 'Water Heater Repair and Replacement Program',
  },
  V69: {
    WL_New_Monthly_Price: 'Unlimited Water Line Protection Program',
    SL_New_Monthly_Price: 'Unlimited Sewer Line Protection Program',
    IHP_New_Monthly_Price: 'In-Home Plumbing Emergency Program',
  },
  V70: {
    WL_New_Monthly_Price: 'Water Line Protection Program',
    SL_New_Monthly_Price: 'Sewer Line Protection Program',
  },
  V71: {
    WL_New_Monthly_Price: 'Water Line Protection Program',
    SL_New_Monthly_Price: 'Unlimited Sewer Line Protection Program',
    EIHP_New_Monthly_Price: 'In-Home Plumbing Plus Program',
  },
  V72: {
    Combo_New_Monthly_Price: 'Water Line and Sewer Line Protection Program',
    IHP_New_Monthly_Price: 'In-Home Plumbing Emergency Program',
  },
  V73: {
    WL_New_Monthly_Price: 'Water Line Protection Program',
    EIHP_New_Monthly_Price: 'In-Home Plumbing Plus Program',
    HWHP_New_Monthly_Price: 'Water Heater Repair and Replacement Program',
  },
  V74: {
    WL_New_Monthly_Price: 'Unlimited Water Line Protection Program',
    SL_New_Monthly_Price: 'Unlimited Sewer Line Protection Program',
  },
  V75: {
    WL_New_Monthly_Price: 'Water Line Protection Program',
    SL_New_Monthly_Price: 'Sewer Line Protection Program',
    IHP_New_Monthly_Price: 'In-Home Plumbing Emergency Program',
  },
  V76: {
    WL_New_Monthly_Price: 'Unlimited Water Line Protection Program',
    IHP_New_Monthly_Price: 'In Home Plumbing Emergency Program',
    HWHP_New_Monthly_Price: 'Water Heater Repair and Replacement Program',
  },
  V77: {
    WL_New_Monthly_Price: 'Water Line Protection Program',
    SL_New_Monthly_Price: 'Sewer Line Protection Program',
    EIHP_New_Monthly_Price: 'In Home Plumbing Plus Protection Program',
  },
  V78: {
    SL_New_Monthly_Price: 'Sewer Line Protection Program',
    EIHP_New_Monthly_Price: 'In Home Plumbing Plus Protection Program',
    HWHP_New_Monthly_Price: 'Water Heater Repair and Replacement Program',
  },
  V79: {
    WL_New_Monthly_Price: 'Unlimited Water Line Protection Program',
    SL_New_Monthly_Price: 'Unlimited Sewer Line Protection Program',
    EIHP_New_Monthly_Price: 'In-Home Plumbing Plus Program',
  },
  V80: {
    Combo_New_Monthly_Price: 'Water Line and Sewer Line Protection Program',
    IHP_New_Monthly_Price: 'In Home Plumbing Emergency Protection Program',
    HWHP_New_Monthly_Price: 'Water Heater Repair and Replacement Program',
  },
  V81: {
    WL_New_Monthly_Price: 'Unlimited Water Line Protection Program',
    IHP_New_Monthly_Price: 'In-Home Plumbing Emergency Program',
    HWHP_New_Monthly_Price: 'Water Heater Repair and Replacement Program',
  },
  V82: {
    WL_New_Monthly_Price: 'Water Line Protection Program',
    SL_New_Monthly_Price: 'Sewer Line Protection Program',
    IHP_New_Monthly_Price: 'In-Home Plumbing Emergency Program',
  },
  V83: {
    WL_New_Monthly_Price: 'Water Line Protection Program',
    SL_New_Monthly_Price: 'Sewer Line Protection Program',
    IHP_New_Monthly_Price: 'In-Home Plumbing Emergency Program',
  },
  V84: {
    Combo_New_Monthly_Price: 'Water Line and Sewer Line Protection Program',
    IHP_New_Monthly_Price: 'In-Home Plumbing Emergency Program',
  },
  V85: {
    WL_New_Monthly_Price: 'Water Line Protection Program',
    SL_New_Monthly_Price: 'Sewer Line Protection Program',
  },
  V86: {
    WL_New_Monthly_Price: 'Water Line Protection Program',
    SL_New_Monthly_Price: 'Sewer Line Protection Program',
  },
  V87: {
    WL_New_Monthly_Price: 'Unlimited Water Line Protection Program',
    SL_New_Monthly_Price: 'Sewer Line Protection Program',
    EIHP_New_Monthly_Price: 'In-Home Plumbing Plus Program',
    HWHP_New_Monthly_Price: 'Water Heater Repair and Replacement Program',
  },
  V88: {
    WL_New_Monthly_Price: 'Water Line Protection Program',
    SL_New_Monthly_Price: 'Sewer Line Protection Program',
    EIHP_New_Monthly_Price: 'In-Home Plumbing Plus Program',
  },
  V89: {
    WL_New_Monthly_Price: 'Water Line Protection Program',
    SL_New_Monthly_Price: 'Sewer Line Protection Program',
    IHP_New_Monthly_Price: 'In-Home Plumbing Emergency Program',
    HWHP_New_Monthly_Price: 'Water Heater Repair and Replacement Program',
  },
  V90: {
    WL_New_Monthly_Price: 'Water Line Protection Program',
    SL_New_Monthly_Price: 'Sewer Line Protection Program',
    EIHP_New_Monthly_Price: 'In-Home Plumbing Plus Program',
    HWHP_New_Monthly_Price: 'Water Heater Repair and Replacement Program',
  },
  V91: {
    WL_New_Monthly_Price: 'Unlimited Water Line Protection Program',
    SL_New_Monthly_Price: 'Sewer Line Protection Program',
    IHP_New_Monthly_Price: 'In-Home Plumbing Emergency Program',
    HWHP_New_Monthly_Price: 'Water Heater Repair and Replacement Program',
  },
  V92: {
    WL_New_Monthly_Price: 'Water Line Protection Program',
    SL_New_Monthly_Price: 'Sewer Line Protection Program',
    EIHP_New_Monthly_Price: 'In-Home Plumbing Plus Program',
  },
};
