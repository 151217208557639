import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NguCarouselConfig } from '@ngu/carousel';
import { take } from 'lodash-es';
import { SimpleModalService } from 'ngx-simple-modal';
import { forkJoin } from 'rxjs';
import { Breadcrumb } from '../../../core/component/breadcrumb/breadcrumb.component';
import { CartService } from '../../../cart/services/cart.service';
import { UserService } from '../../services/user.service';
import { OrdersService } from '../../../payment-ets/services/orders.service';
import { ProductService } from '../../../product/services/product.service';
import { SessionStorageService } from '../../../core/services/session-storage.service';
import { environment } from '../../../../environments/environment';
import { UtilsService } from '../../../core/services/utils.service';
import { GlobalVariables } from '../../../core/services/global-variables.service';
import { ErrorModalComponent } from '../../../core/component/error-modal/error-modal.component';
import { GtagService } from '../../../gtag/gtag.service';
import { GtagPayloadGeneratorService } from '../../../core/services/gtag-payload-generator';

declare let ga: Function;
@Component({
  selector: 'hos-ets-payment-response',
  templateUrl: './quick-checkout-payment-response.component.html',
  styleUrls: ['./quick-checkout-payment-response.component.scss'],
})
export class CheckoutEtsPaymentResponseComponent implements OnInit {
  @Input() orderId: any;

  @Input() customerDetails: any;

  @Input() customerName: any;

  @Input() existingCustomerData: any;

  @Input() passNewRegisterCustomerResponse: any;

  @Input() EmailExistedData: any;

  @Input() addressPresentIn: any;

  @Input() enrollmentGuid: any;

  customerId: any;

  isSuccess = false;

  isAnySuccess = false;

  isAddress = false;

  public carouselTile: NguCarouselConfig;

  products: any;

  otherProducts: Array<any> = [];

  productsByBillingType: any = {
    onbill: {
      total: 0,
      products: [],
    },
    paidbill: {
      total: 0,
      products: [],
    },
  };

  errorProducts: any = [];

  zipcode: any;

  locationId: any;

  paramsData: any;

  userName: String;

  transactionId: String;

  partnerName: any;

  locData: any;

  customer_service_number: any;

  productServiceBaseUrl: String = environment.Image_API_Endpoint + environment.Product_service_API_Endpoint;

  generalInformation: any = {
    orderPlacedOn: '',
    address: {},
    billingFrequency: '',
    effectiveDate: '',
    renewalDate: '',
    paymentInfo: {},
  };

  breadcrumsConfig: Array<Breadcrumb> = [
    {
      title: 'Home',
      link: '/',
      active: false,
    },
    {
      title: 'Payment Response',
      link: '',
      active: true,
    },
  ];

  setOldPriceFlag: boolean = false;

  guestToken: any;

  password_regex: String;

  email_regex: String;

  isClicked: Boolean;

  emailId: any;

  hideRegisterBlock: boolean = false;

  showLoader: Boolean = false;

  isConfirmView = false;

  fromNonLoggedInRenewal: Boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private cartService: CartService,
    private userService: UserService,
    private productService: ProductService,
    private utilsService: UtilsService,
    private sessionStorageService: SessionStorageService,
    private ordersService: OrdersService,
    private simpleModalService: SimpleModalService,
    public globalVariables: GlobalVariables,
    private gtagService: GtagService,
    private gtagPayloadGeneratorService: GtagPayloadGeneratorService,
    private router: Router,
  ) {
    this.customer_service_number = environment.customer_service_number;
    this.password_regex = environment.password_regex;
    this.email_regex = environment.email_regex;
    this.isClicked = false;
  }

  ngOnInit() {
    if (
      this.passNewRegisterCustomerResponse &&
      this.existingCustomerData &&
      this.addressPresentIn &&
      this.addressPresentIn === 'customersDB' &&
      this.existingCustomerData.email !== this.passNewRegisterCustomerResponse.email
    ) {
      this.hideRegisterBlock = true;
    } else if (this.EmailExistedData && !this.EmailExistedData.isFromCheckout) {
      this.hideRegisterBlock = true;
    }
    if (this.passNewRegisterCustomerResponse) {
      this.emailId = this.passNewRegisterCustomerResponse.email;
    }
    this.carouselTile = {
      grid: {
        xs: 1,
        sm: 2,
        md: 4,
        lg: 4,
        all: 0,
      },
      slide: 3,
      speed: 400,
      point: {
        visible: false,
      },
      load: 2,
      touch: true,
      easing: 'ease',
    };
    $('html, body').animate(
      {
        scrollTop: 0,
      },
      1000,
    );
    if (this.customerDetails) {
      this.customerId = this.customerDetails.data.customerId ? this.customerDetails.data.customerId : '';
    }
    if (this.orderId) {
      this.getOrderDetails(this.orderId);
    }
    if (this.enrollmentGuid) {
      this.getOrderDetailsUsingEnrGuid(this.enrollmentGuid);
    }
    this.partnerName = this.sessionStorageService.getItem('websitePartnerName');
    if (this.partnerName) {
      this.productService.getProviderContent(this.sessionStorageService.getItem('location_code')).subscribe(
        locationData => {
          const reslocationData = locationData.body;
          this.locData = reslocationData;
        },
        () => {
          // error handling
          this.locData = [];
        },
      );
    } else {
      this.locData = [];
    }
    this.userName = this.customerName;
    this.fromNonLoggedInRenewal = this.router.url.includes('enrollment-update-renewal');
  }

  getOrderDetails(orderId: String) {
    this.showLoader = true;
    this.guestToken = this.sessionStorageService.getItem('guestToken');
    let isCountAnnuallyGATracking = false;
    const orderData = [];
    if (this.globalVariables.nonLoggedInRenewedOrderIds && this.globalVariables.nonLoggedInRenewedOrderIds.length > 1) {
      // eslint-disable-next-line no-restricted-syntax
      for (const id of this.globalVariables.nonLoggedInRenewedOrderIds) {
        orderData.push(this.ordersService.getOrderByOrderId(id, this.customerId, this.guestToken));
      }
    } else {
      orderData.push(this.ordersService.getOrderByOrderId(orderId, this.customerId, this.guestToken));
    }

    this.getOrdersData(orderData, ordersDetails => {
      this.showLoader = false;
      if (ordersDetails) {
        this.products = ordersDetails;
        const element: HTMLElement = document.getElementById('checkout_order_success') as HTMLElement;
        element.click();
        // Condition to delete locally stored cart data once logged in
        this.sessionStorageService.deleteItem('cartCheckout');
        this.sessionStorageService.deleteItem('guestToken');
        /** Resetting the Local User Cart Data */
        this.sessionStorageService.deleteItem('user_data');
        this.zipcode = this.products[0].address.serviceAddress.zipcode;
        this.locationId = this.products[0].address.serviceAddress.locationCodeId;
        this.generalInformation.orderPlacedOn = this.products[0].createdAt;
        this.generalInformation.address = this.products[0].address;
        this.generalInformation.paymentInfo = this.products[0].paymentDetails;
        this.generalInformation.billingFrequency = this.products[0].billingFrequency;
        if (this.generalInformation.billingFrequency === 'Annual') {
          this.generalInformation.effectiveDate = this.products[0].programEffectiveDate;
        } else {
          this.generalInformation.effectiveDate = this.products[0].programEffectiveDate;
        }
        this.generalInformation.renewalDate = this.products[0].renewalDate;
        let triggerNotAutoRenew = false; // HWR-4891: This is used to trigger GA event if any order from order list includes isAutoRenew = false
        this.products.forEach(product => {
          /* HWR-5564 Renewal Enrollment GA Tracking */
          if (product.enrollmentType === 'renewal') {
            const element2: HTMLElement = document.getElementById('successful_enrollment_renewal') as HTMLElement;
            element2.click();
          }
          // HWR-3380 Complete-SuccessPage/Failure-ErrorPage
          if (product.status === 'COMPLETE') {
            this.isSuccess = true;
          }
          if (product.status === 'CRM_PENDING') {
            this.isSuccess = true;
          }
          if (product.status === 'CRM_FAILURE') {
            this.errorProducts.push(product);
          }
          if (product.billingType === 'offbill') {
            this.isAnySuccess = true;
            product.enrollmentPrice.TaxDetails.Total = Number(product.enrollmentPrice.TaxDetails.Total);
            this.productsByBillingType.paidbill.products.push(product);
            // GA tracking only for offbill enrollment
            if (product.billingFrequency === 'Annual') {
              isCountAnnuallyGATracking = true;
            }
          } else {
            this.isAnySuccess = true;
            product.enrollmentPrice.TaxDetails.Total = Number(product.enrollmentPrice.TaxDetails.Total);
            this.productsByBillingType.onbill.products.push(product);
          }
          if (!product.isAutoRenew) {
            triggerNotAutoRenew = true;
          }
        });
        if (triggerNotAutoRenew) {
          const ele: HTMLElement = document.getElementById('order_includes_do_not_autoRenew') as HTMLElement;
          ele.click();
        }
        if (this.productsByBillingType.onbill.products.length > 0 || this.productsByBillingType.paidbill.products.length) {
          this.isAddress = true;
        }
        this.calculateBillTotal();
        this.ecommerceEventsForGA(this.productsByBillingType);
        this.getOtherProducts();

        // GA traking of monthly/annually billing frequency
        if (isCountAnnuallyGATracking) {
          const ele1: HTMLElement = document.getElementById('annual_billing_frequency') as HTMLElement;
          ele1.click();
        } else {
          const ele2: HTMLElement = document.getElementById('monthly_billing_frequency') as HTMLElement;
          ele2.click();
        }
        // GA push
        if ((window as any).ga) {
          ga('ecommerce:addTransaction', {
            id: this.products[0].orderId, // Transaction ID. Required.
            affiliation: 'AW Checkout', // Affiliation or store name.
            revenue: this.productsByBillingType.onbill.total + this.productsByBillingType.paidbill.total, // Grand Total.
            shipping: '0', // Shipping.
            tax: this.productsByBillingType.onbill.tax + this.productsByBillingType.paidbill.tax, // Tax.
          });
        }

        this.products.forEach(product => {
          if ((window as any).ga) {
            ga('ecommerce:addItem', {
              id: product.orderId, // Transaction ID. Required same as in transaction data.
              name: product.productDetails.customerProgramName, // product name required.
              sku: product.productDetails.defaultCodeId, // product sku.
              category: product.productDetails.programName, // product category or variation.
              price: product.productDetails.monthlyPrice, // product price // monthlyPrice
              quantity: '1', // product quantity.
            });
          }
        });
        if ((window as any).ga) {
          ga('ecommerce:send');
          ga('ecommerce:clear');
        }
      }
    });
  }

  getOrdersData(orderData, done: any) {
    forkJoin(orderData).subscribe(
      data => {
        let ordersData: any[] = [];
        (data as any).forEach(ele => {
          ordersData = [...ordersData, ...ele.body];
        });
        done(ordersData);
      },
      () => {
        const errorTitle = "We're sorry, there is technical problem.";
        const strError = `Please contact Customer Service at <span class="app-txt">
        ${this.customer_service_number}</span>`;
        this.showLoader = false;
        this.showErrorModal(errorTitle, strError, ErrorModalComponent);
      },
    );
  }

  getOtherProducts() {
    let contactType = 1;
    if (
      this.products[0] &&
      this.products[0].address &&
      this.products[0].address.serviceAddress &&
      this.products[0].address.serviceAddress.contactType === 'COMMERCIAL'
    ) {
      contactType = 3;
    }
    this.productService.getRecommendedProducts(this.locationId, '', this.customerId, contactType, null, this.guestToken).subscribe(
      data => {
        if (data.status === 200) {
          this.otherProducts = [];
          const resData = data.body;
          this.otherProducts = take(resData, 4);
        } else if (data.status !== 200) {
          // error handling
          this.otherProducts = [];
        }
        if (this.otherProducts.length > 0) {
          this.showOldPriceContainer(this.otherProducts);
        }
      },
      () => {
        this.otherProducts = [];
        // error handling
      },
    );
  }

  calculateBillTotal() {
    const totalOnbill = this.productsByBillingType.onbill.products.reduce(
      (previous, current) => Number(previous) + Number(current.enrollmentPrice.TaxDetails.Total),
      0,
    );
    const totalOnbillTax = this.productsByBillingType.onbill.products.reduce(
      (previous, current) => Number(previous) + Number(current.enrollmentPrice.TaxDetails.Tax),
      0,
    );
    const totalPaidbill = this.productsByBillingType.paidbill.products.reduce(
      (previous, current) => Number(previous) + Number(current.enrollmentPrice.TaxDetails.Total),
      0,
    );
    const totalPaidbillTax = this.productsByBillingType.paidbill.products.reduce(
      (previous, current) => Number(previous) + Number(current.enrollmentPrice.TaxDetails.Tax),
      0,
    );

    this.productsByBillingType.onbill.total = totalOnbill;
    this.productsByBillingType.onbill.tax = totalOnbillTax;
    this.productsByBillingType.paidbill.total = totalPaidbill;
    this.productsByBillingType.paidbill.tax = totalPaidbillTax;
  }

  getImage(imageName) {
    return this.utilsService.buildImageUrl(imageName);
  }

  downloadContract(service) {
    // If origin is CRM don't display confirmation email
    if (service.origin === 'HOS') {
      let event;
      const link = document.createElement('a');
      if (document.createEvent) {
        event = document.createEvent('MouseEvent');
        event.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
      } else {
        event = new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: true,
        });
      }
      const isSafari = !!navigator.userAgent.match(/Version\/[\d\\.]+.*Safari/);
      const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !(<any>window).MSStream;
      let newTab: any;
      if (iOS && isSafari) {
        newTab = window.open();
      }

      if (iOS && isSafari) {
        // tslint:disable-next-line:max-line-length
        newTab.location.href = this.getImage(
          `${environment.Image_API_Endpoint}${environment.Template_service_API_Endpoint}downloadcontract/${this.products[0].orderId}`,
        );
      } else {
        // tslint:disable-next-line:max-line-length
        link.href = this.getImage(
          `${environment.Image_API_Endpoint}${environment.Template_service_API_Endpoint}downloadcontract/${this.products[0].orderId}`,
        );
        link.target = '_blank';
        link.download = `${this.products[0].orderId}.pdf`;
        link.dispatchEvent(event);
      }
    }
  }

  showOldPriceContainer(products) {
    if (products) {
      products.forEach(item => {
        if (item.oldPrice || (item.oldPriceText && !['', undefined, 'undefined', null, 'null'].includes(item.oldPriceText))) {
          this.setOldPriceFlag = true;
        }
      });
    }
  }

  blurHandler(input: any) {
    input.isBlur = true;
    const pattern = /^\d{10}$/;
    if (input.value && !pattern.test(input.value)) {
      return false;
    }
    return true;
  }

  formKeyPressHandler(event: any) {
    const keyCode = event.keyCode || event.which;
    if (keyCode === 13) {
      event.preventDefault();
      return false;
    }
  }

  emailKeyPressHandler(input: any) {
    input.isBlur = false;
  }

  emailBlurHandler(input: any) {
    input.isBlur = true;
    if (input.valid) {
      if (environment.disposable_email.includes(input.value.split('@')[1].toLowerCase())) {
        input.isBlur = false;
      }
    }
  }

  registerCustomer(form: any) {
    this.isClicked = true;
    if (form.valid && form.controls.password && form.controls.password.value === form.controls.confirmPassword.value) {
      this.callRegisterCustomerAPI(this.passNewRegisterCustomerResponse, form);
    }
  }

  callRegisterCustomerAPI(payload, form) {
    this.showLoader = true;
    const apiPayload: any =
      this.passNewRegisterCustomerResponse.resSource === 'register'
        ? this.setRegisterCustomerPayload(payload, form)
        : this.passNewRegisterCustomerResponse.resSource === 'address' || this.passNewRegisterCustomerResponse.resSource === 'customersDB'
        ? this.setAddressCustomerPayload(payload, form)
        : this.setRegisterCustomerPayload(payload, form);
    this.userService.registerCustomer(apiPayload).subscribe(
      data => {
        this.showLoader = false;
        $('html, body').animate(
          {
            scrollTop: 0,
          },
          'slow',
        );
        if (data.status === 200) {
          const element: HTMLElement = document.getElementById('checkout_successpage_register_success') as HTMLElement;
          element.click();
          this.globalVariables.isSuccessCheckoutWebRegistration = true;
        }
      },
      error => {
        this.isClicked = false;
        this.showLoader = false;
        let errorTitle = "We're sorry, there was a problem attempting to create your account.";
        let strError: String = 'Please call the number at the top of page to speak to a Customer Service Representative.';
        if (error.status === 409) {
          strError = 'User already exists';
          errorTitle = 'Error';
        }
        const trackingId: any = 'checkout_successpage_register_error';
        this.showErrorModal(errorTitle, strError, ErrorModalComponent, trackingId);
      },
    );
  }

  scrollToSection(sectionId: string) {
    $('html, body').animate(
      {
        scrollTop: $(`#${sectionId}`).offset().top - 150,
      },
      1000,
    );
  }

  showErrorModal(title: String, message: String, modalComponent: any, trackGA?: any) {
    this.simpleModalService
      .addModal(
        modalComponent,
        {
          title,
          message,
          confirmBtnId: trackGA || 'close',
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: false,
        },
      )
      .subscribe(() => {});
  }

  setRegisterCustomerPayload(payload, form) {
    const createPayload: any = {
      lastName: payload.lastName,
      email: payload.email,
      password: form.controls.password.value,
      isFromCheckout: payload.isFromCheckout,
      isPasswordUpdate: true,
      address: [
        {
          customerNo: this.customerDetails.data.customerNo,
          customerGuid: this.customerDetails.data.customerGuid,
          waterBillOnOff: payload.address[0].waterBillOnOff || true,

          ownOrRentedValidated: payload.address[0].ownOrRentedValidated,
          noWaterBillConversion: payload.address[0].noWaterBillConversion,
          firstName: payload.address[0].firstName || '',
          lastName: payload.address[0].lastName,

          serviceAddress: {
            streetName: payload.address[0].serviceAddress.streetName || '',
            streetNumber: payload.address[0].serviceAddress.streetNumber,
            city: payload.address[0].serviceAddress.city,
            state: payload.address[0].serviceAddress.state,
            zipcode: payload.address[0].serviceAddress.zipcode,
            contactType: payload.address[0].serviceAddress.contactType,
            zipcodeId: payload.address[0].serviceAddress.zipcodeId,
            locationCodeId: payload.address[0].serviceAddress.locationCodeId,
          },

          billingAddress: {
            streetName: payload.address[0].billingAddress.streetName || '',
            streetNumber: payload.address[0].billingAddress.streetNumber || '',
            city: payload.address[0].billingAddress.city || '',
            state: payload.address[0].billingAddress.state || '',
            zipcode: payload.address[0].billingAddress.zipcode || '',
            contactType: payload.address[0].billingAddress.contactType || '',
          },
        },
      ],
    };
    if (payload.retainUrl) {
      createPayload.retainUrl = payload.retainUrl;
    }
    createPayload.utmParams = payload.retainUrl || '';
    createPayload.address[0].providerAvailable = payload.address[0].providerAvailable;
    createPayload.address[0].ownOrRentedValidated = payload.address[0].ownOrRentedValidated;
    createPayload.address[0].noWaterBillConversion = payload.address[0].noWaterBillConversion;

    return createPayload;
  }

  setAddressCustomerPayload(payload, form) {
    const createPayload: any = {
      lastName: payload.lastName,
      email: payload.email,
      password: form.controls.password.value,
      isFromCheckout: payload.isFromCheckout,
      isPasswordUpdate: true,
      address: [
        {
          customerNo: this.customerDetails.data.customerNo,
          customerGuid: this.customerDetails.data.customerGuid,
          waterBillOnOff: payload.waterBillOnOff || true,

          ownOrRentedValidated: payload.ownOrRentedValidated,
          noWaterBillConversion: payload.noWaterBillConversion,
          firstName: payload.firstName || '',
          lastName: payload.lastName,

          serviceAddress: {
            streetName: payload.serviceAddress.streetName || '',
            streetNumber: payload.serviceAddress.streetNumber,
            city: payload.serviceAddress.city,
            state: payload.serviceAddress.state,
            zipcode: payload.serviceAddress.zipcode,
            contactType: payload.serviceAddress.contactType,
            zipcodeId: payload.serviceAddress.zipcodeId,
            locationCodeId: payload.serviceAddress.locationCodeId,
          },

          billingAddress: {
            streetName: payload.billingAddress.streetName || '',
            streetNumber: payload.billingAddress.streetNumber || '',
            city: payload.billingAddress.city || '',
            state: payload.billingAddress.state || '',
            zipcode: payload.billingAddress.zipcode || '',
            contactType: payload.billingAddress.contactType || '',
          },
        },
      ],
    };
    if (payload.retainUrl) {
      createPayload.retainUrl = payload.retainUrl;
    }
    createPayload.utmParams = payload.retainUrl || '';
    createPayload.address[0].providerAvailable = payload.providerAvailable;
    createPayload.address[0].ownOrRentedValidated = payload.ownOrRentedValidated;
    createPayload.address[0].noWaterBillConversion = payload.noWaterBillConversion;

    return createPayload;
  }

  ecommerceEventsForGA(products: any) {
    let items = [];

    if (products.paidbill.products.length > 0) {
      items = [...this.gtagPayloadGeneratorService.generatingPayloadsForGA(products.paidbill.products)];
    }

    if (products.onbill.products.length > 0) {
      items = [...items, ...this.gtagPayloadGeneratorService.generatingPayloadsForGA(products.onbill.products, null, true)];
    }

    const billingFrequency =
      products.paidbill.products.length > 0 ? products.paidbill.products[0].billingFrequency : products.onbill.products[0].billingFrequency;

    const transactionId = products.paidbill.products.length > 0 ? products.paidbill.products[0].orderId : products.onbill.products[0].orderId;

    const grossTotal = Number(parseFloat(products.paidbill.total)) + Number(parseFloat(products.onbill.total));
    const custid =
      products.paidbill.products.length > 0 ? products.paidbill.products[0].crmCustomerNumber : products.onbill.products[0].crmCustomerNumber;

    this.userService.getIsFistTimeCustomer(this.emailId).subscribe(
      data => {
        data = data.body;
        const firstTime = !!(data.success && data.isfirsttimeuser);
        const actionItems = {
          transaction_id: transactionId,
          affiliation: 'AW Checkout',
          currency: 'USD',
          value: grossTotal.toString(),
          billingfrequency: billingFrequency.toLowerCase(),
          email: this.emailId,
          zipcode: this.zipcode,
          items,
          firstTime,
          custid,
        };
        this.sendPurchaseGA(actionItems);
      },
      () => {
        const actionItems = {
          transaction_id: transactionId,
          affiliation: 'AW Checkout',
          currency: 'USD',
          value: grossTotal.toString(),
          billingfrequency: billingFrequency.toLowerCase(),
          email: this.emailId,
          zipcode: this.zipcode,
          items,
          firstTime: null,
          custid,
        };
        this.sendPurchaseGA(actionItems);
      },
    );
  }

  sendPurchaseGA(actionItems) {
    this.gtagService.purchase(actionItems);
  }

  getOrderDetailsUsingEnrGuid(enrollmentGuid) {
    this.showLoader = true;
    this.guestToken = this.sessionStorageService.getItem('guestToken');
    this.ordersService.getOrderBycrmEnrollmentGuid(enrollmentGuid, this.guestToken ? this.guestToken : '').subscribe(
      async data => {
        this.showLoader = false;
        if (data.status === 200) {
          this.products = data.body;
          // Condition to delete locally stored cart data once logged in
          this.sessionStorageService.deleteItem('cartCheckout');
          this.sessionStorageService.deleteItem('guestToken');
          /** Resetting the Local User Cart Data */
          this.sessionStorageService.deleteItem('user_data');
          this.zipcode = this.products[0].address.serviceAddress.zipcode;
          this.locationId = this.products[0].address.serviceAddress.locationCodeId;
          this.generalInformation.orderPlacedOn = this.products[0].createdAt;
          this.generalInformation.address = this.products[0].address;
          this.generalInformation.paymentInfo = this.products[0].paymentDetails;
          this.generalInformation.billingFrequency = this.products[0].billingFrequency;
          if (this.generalInformation.billingFrequency === 'Annual') {
            this.generalInformation.effectiveDate = this.products[0].programEffectiveDate;
          } else {
            this.generalInformation.effectiveDate = this.products[0].programEffectiveDate;
          }
          this.generalInformation.renewalDate = this.products[0].renewalDate;
          this.products.forEach(product => {
            // HWR-3380 Complete-SuccessPage/Failure-ErrorPage
            if (product.status === 'COMPLETE') {
              this.isSuccess = true;
            }
            if (product.status === 'CRM_PENDING') {
              this.isSuccess = true;
            }
            if (!!this.enrollmentGuid && !this.orderId) {
              this.isSuccess = true;
              product.status = 'CRM_PENDING';
            }
            if (product.status === 'CRM_FAILURE') {
              this.errorProducts.push(product);
            }
            if (product.billingType === 'offbill') {
              this.isAnySuccess = true;
              product.enrollmentPrice.TaxDetails.Total = Number(product.enrollmentPrice.TaxDetails.Total);
              this.productsByBillingType.paidbill.products.push(product);
            } else {
              this.isAnySuccess = true;
              product.enrollmentPrice.TaxDetails.Total = Number(product.enrollmentPrice.TaxDetails.Total);
              this.productsByBillingType.onbill.products.push(product);
            }
          });
          if (this.productsByBillingType.onbill.products.length > 0 || this.productsByBillingType.paidbill.products.length) {
            this.isAddress = true;
          }
          this.calculateBillTotal();
          this.getOtherProducts();
        }
      },
      () => {},
    );
  }
}
