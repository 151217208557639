import { PLATFORM_ID, Inject, Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { SessionStorageService } from '../services/session-storage.service';

@Injectable()
export class CanActivateViaAuthGuard implements CanActivate {
  constructor(private router: Router, private sessionStorageService: SessionStorageService, @Inject(PLATFORM_ID) private platformId: any) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (isPlatformBrowser(this.platformId)) {
      if (this.sessionStorageService.getItem('authHeader')) {
        // logged in so return true
        return true;
      }
      // not logged in so redirect to login page with the return url
      const queryParams: any = {};
      if (state.url) {
        queryParams.referer = encodeURIComponent(state.url);
      }
      this.router.navigate(['/user/login'], {
        queryParams,
      });
      return false;
    }
    this.router.navigate(['/']);
    return false;
  }
}
