import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SimpleModalComponent, SimpleModalService } from 'ngx-simple-modal';
import { UtilsService } from '../../services/utils.service';
import { environment } from '../../../../environments/environment';

export interface ConfirmModalModel {
  title: string;
  message: string;
  description: string;
  data: any;
  billingFrequency?: any;
}

@Component({
  selector: 'hos-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent extends SimpleModalComponent<ConfirmModalModel, Boolean> implements ConfirmModalModel, OnInit {
  title: string;

  message: string;

  referer: String;

  description: string;

  data: any;

  productServiceBaseUrl: String = environment.Image_API_Endpoint + environment.Product_service_API_Endpoint;

  isChecked: Boolean = false;

  billingFrequency: any;

  constructor(
    simpleModalService: SimpleModalService,
    private router: Router,
    private utilsService: UtilsService,
    private activatedRoute: ActivatedRoute,
  ) {
    super();
    this.referer = this.activatedRoute.snapshot.queryParams.referer;
  }

  ngOnInit() {
    if (!Object.prototype.hasOwnProperty.call(this.data, 'isAutoRenew')) {
      this.data.isAutoRenew = true; // key doesnt exit then true
    } else {
      this.isChecked = !this.data.isAutoRenew;
    }
  }

  cancelHandler() {
    this.router.navigate(['']);
    this.close();
  }

  getImage(imageName) {
    return this.utilsService.buildImageUrl(imageName);
  }

  checkValue(isChecked) {
    this.assignValueIsAutoRenew(isChecked);
    this.result = true;
  }

  removeProduct() {}

  assignValueIsAutoRenew(isChecked) {
    this.data.isAutoRenew = !isChecked; // key doesnt exit then true
  }
}
