export class CartItem {
  type: string;

  productId: string;

  status: string;

  quantity: number;

  locationId: string;

  zipcode: string;

  customerNo: string;

  isUpgrade: boolean = false;

  details: any;

  enrollmentGuid: string;

  _id?: string;

  description?: string;

  isRenew: boolean;

  parentEnrollmentId: string;

  parentEnrollmentDate: string;

  leakFrequencyVal?: string;

  contactType: number;

  isReEnroll?: boolean = false;
}
