export const programs = {
  CSRP: {
    class: 'cooling',
    color: '#06B3E4',
  },
  EELP: {
    class: 'Exterior_electric',
    color: '#F3C317',
  },
  'HR/CR': {
    class: 'heating_cooling',
    color: '#F3C317',
  },
  HSRP: {
    class: 'heating',
    color: '#EB7924',
  },
  EIHP: {
    class: 'plumbing_plus',
    color: '#AD073D',
  },
  IHPP: {
    class: 'plumbing_emergency',
    color: '#AD073D',
  },
  'EI/EL': {
    class: 'plumbing_plus_electric',
    color: '#F3C317',
  },
  'IH/EL': {
    class: 'plumbing_plus_electric1',
    color: '#F3C317',
  },
  ELPP: {
    class: 'Interior_Electric',
    color: '#225E92',
  },
  SEPT: {
    class: 'septic',
    color: '#77C652',
  },
  SLPP: {
    class: 'sewer',
    color: '#77C652',
  },
  SURGE: {
    class: 'surge',
    color: '#B41044',
  },
  SRG: {
    class: 'surge',
    color: '#B41044',
  }, // For Claims (Power Surge)
  'b818b88a-4ea7-e611-9417-00155d8ff122': {
    class: 'triple_plus',
    color: '#F3C317',
  },
  WESE: {
    class: 'WSSE',
    color: '#F3C317',
  },
  'WL/SL': {
    class: 'WLSL',
    color: '#F3C317',
  },
  'WL/SL/EE': {
    class: 'WLSLEE',
    color: '#F3C317',
  },
  'WL/SL/IH': {
    class: 'WLSLIH',
    color: '#F3C317',
  },
  HWHP: {
    class: 'HWHP',
    color: '#EB7924',
  },
  WLPP: {
    class: 'WLPP',
    color: '#0099CC',
  },
  WELL: {
    class: 'WELL',
    color: '#F3C317',
  },
  GLPP: {
    class: 'cooling',
    color: '#EB7924',
  },
  others: {
    class: 'other',
    color: '#0099CC',
  },
  LDDP: {
    class: 'cooling',
    color: '#044E87',
  },
  SPP: {
    class: 'SPP',
    color: '#F3C317',
  }, // HWR-2500:color code added
  IHCP: {
    class: 'IHCP',
    color: '#06B3E4',
  } /** In-Home-Clog-Removal Program */,
  EEPP: {
    class: 'EEPP',
    color: '#06B3E4',
  } /** Energy Essentials Protection Program */,
  CRSS: {
    class: 'cooling',
    color: '#06B3E4',
  } /** Cooling Repair - Secondary System */,
  CRPS: {
    class: 'cooling',
    color: '#06B3E4',
  } /** Cooling Repair - Primary System */,
  CPRS: {
    class: 'cooling',
    color: '#06B3E4',
  } /** Preferred Cooling Protection - Primary System */,
  CPSS: {
    class: 'cooling',
    color: '#06B3E4',
  } /** Preferred Cooling Protection - Secondary System */,
  'ELPP/EELP': {
    class: 'electric_combo',
    color: '#06B3E4',
  } /**  Electric Combo Program */,
};
