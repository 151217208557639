export const claimsSummary = {
  SLPP: ({
    customerName,
    backupLocation,
    drainsLocation,
    issueLocation,
    firstNoticed,
    programName,
    effectiveDate,
    additionalNotes,
    contactName,
    contactNo,
    sewerCleanout,
    rainorFlooding,
  }): any => {
    if (rainorFlooding === 'Neither of these') {
      return `${customerName} reports a Sewer Back up from the ${backupLocation} and drains are slow at ${drainsLocation}. Neither Raining nor Flooding has occurred. Sewer Cleanout: ${sewerCleanout}. Issue located at ${issueLocation}. First noticed ${firstNoticed}. Customer enrolled in ${programName}. Program Effective From ${effectiveDate}. Additional Notes : ${
        additionalNotes || '-'
      }. For Contractor's Information :- Preferred Contact Name - ${contactName}. Preferred Contact No - ${contactNo}.`;
    }
    if (rainorFlooding === 'Rain') {
      return `${customerName} reports a Sewer Back up from the ${backupLocation} and drains are slow at ${drainsLocation}. Raining has occurred. Sewer Cleanout: ${sewerCleanout}. Issue located at ${issueLocation}. First noticed ${firstNoticed}. Customer enrolled in ${programName}. Program Effective From ${effectiveDate}. Additional Notes : ${
        additionalNotes || '-'
      }. For Contractor's Information :- Preferred Contact Name - ${contactName}. Preferred Contact No - ${contactNo}.`;
    }
    if (rainorFlooding === 'Flooding') {
      return `${customerName} reports a Sewer Back up from the ${backupLocation} and drains are slow at ${drainsLocation}. Flooding has occurred. Sewer Cleanout: ${sewerCleanout}. Issue located at ${issueLocation}. First noticed ${firstNoticed}. Customer enrolled in ${programName}. Program Effective From ${effectiveDate}. Additional Notes : ${
        additionalNotes || '-'
      }. For Contractor's Information :- Preferred Contact Name - ${contactName}. Preferred Contact No - ${contactNo}.`;
    }
  },
  SEPT: ({
    customerName,
    backupLocation,
    drainsLocation,
    firstNoticed,
    programName,
    effectiveDate,
    issueLocation,
    lastEmptied,
    additionalNotes,
    contactName,
    contactNo,
    rainorFlooding,
  }): any => {
    if (rainorFlooding === 'Neither of these') {
      return `${customerName} reports a Sewer Back up from the ${backupLocation} and drains are slow at ${drainsLocation}. Neither Raining nor Flooding has occurred. Issue located at ${issueLocation}. Septic System last emptied ${lastEmptied}. First noticed ${firstNoticed}. Customer enrolled in ${programName}. Program Effective From ${effectiveDate}. Additional Notes : ${
        additionalNotes || '-'
      }. For Contractor's Information :- Preferred Contact Name - ${contactName}. Preferred Contact No - ${contactNo}.`;
    }
    if (rainorFlooding === 'Rain') {
      return `${customerName} reports a Sewer Back up from the ${backupLocation} and drains are slow at ${drainsLocation}. Raining has occurred. Issue located at ${issueLocation}. Septic System last emptied ${lastEmptied}. First noticed ${firstNoticed}. Customer enrolled in ${programName}. Program Effective From ${effectiveDate}. Additional Notes : ${
        additionalNotes || '-'
      }. For Contractor's Information :- Preferred Contact Name - ${contactName}. Preferred Contact No - ${contactNo}.`;
    }
    if (rainorFlooding === 'Flooding') {
      return `${customerName} reports a Sewer Back up from the ${backupLocation} and drains are slow at ${drainsLocation}. Flooding has occurred. Issue located at ${issueLocation}. Septic System last emptied ${lastEmptied}. First noticed ${firstNoticed}. Customer enrolled in ${programName}. Program Effective From ${effectiveDate}. Additional Notes : ${
        additionalNotes || '-'
      }. For Contractor's Information :- Preferred Contact Name - ${contactName}. Preferred Contact No - ${contactNo}.`;
    }
  },
  WLPP: ({ customerName, leakLocation, waterOff, programName, effectiveDate, contactName, contactNo }): any =>
    `${customerName} has a visible water line break that has been verified by the water company. Water is ${waterOff}. ${
      leakLocation ? `Leak is present: ${leakLocation}.` : ''
    } Customer enrolled in ${programName}. Program Effective From ${effectiveDate}. For Contractor's Information :- Preferred Contact Name - ${contactName}. Preferred Contact No - ${contactNo}.`,
  IHPP: ({
    issueType,
    firstNoticed,
    customerName,
    leakLocation,
    fixture,
    programName,
    effectiveDate,
    backupLocation,
    drainsLocation,
    additionalNotes,
    contactName,
    contactNo,
  }): any => {
    if (issueType === 'Leak') {
      return `${customerName} reports a constant leak on a supply line. Located at ${leakLocation}. First noticed ${firstNoticed}. Customer enrolled in ${programName}. Program Effective From ${effectiveDate}. Additional notes: ${
        additionalNotes || '-'
      }. For Contractor's Information :- Preferred Contact Name - ${contactName}. Preferred Contact No - ${contactNo}.`;
    }
    if (issueType === 'Blockage') {
      return `${customerName} reports a Clogged/Slow draining on ${fixture}. Located at ${backupLocation} and drains are slow at ${drainsLocation}. First noticed ${firstNoticed}. Customer enrolled in ${programName}. Program Effective From ${effectiveDate}. Additional notes: ${
        additionalNotes || '-'
      }. For Contractor's Information :- Preferred Contact Name - ${contactName}. Preferred Contact No - ${contactNo}.`;
    }
  },
  EIPP: ({ issueType, firstNoticed, customerName, leakLocation, fixture, programName, effectiveDate, backupLocation, faucetType, issue }): any => {
    if (issueType === 'Leak') {
      return `${customerName} reports a leak on a (Supply/drain line) located at ${leakLocation}. First noticed ${firstNoticed}. Customer enrolled in ${programName}. Program Effective From ${effectiveDate}`;
    }
    if (issueType === 'Toilet') {
      return `${customerName} reports a ${issue} with the toilet located. Located at ${backupLocation}. First noticed ${firstNoticed}. Customer enrolled in ${programName}. Program Effective From ${effectiveDate}`;
    }
    if (issueType === 'Faucet') {
      return `${customerName} reports a leak on the ${faucetType} faucet, ${fixture}. Located at ${backupLocation}. First noticed ${firstNoticed}. Customer enrolled in ${programName}. Program Effective From ${effectiveDate}`;
    }
  },
  ELPP: ({
    issueType,
    firstNoticed,
    customerName,
    outletLocation,
    switchLocation,
    issueLocation,
    programName,
    effectiveDate,
    additionalNotes,
    contactName,
    contactNo,
  }): any => {
    if (issueType === 'Outlets') {
      return `${customerName} reports a issue with outlets located at ${outletLocation}. First noticed ${firstNoticed}. Customer enrolled in ${programName}. Program Effective From ${effectiveDate}. Additional notes: ${
        additionalNotes || '-'
      }. For Contractor's Information :- Preferred Contact Name - ${contactName}. Preferred Contact No - ${contactNo}.`;
    }
    if (issueType === 'Switches') {
      return `${customerName} reports a issue with the switches. Located at ${switchLocation}. First noticed ${firstNoticed}. Customer enrolled in ${programName}. Program Effective From ${effectiveDate}. Additional notes: ${
        additionalNotes || '-'
      }. For Contractor's Information :- Preferred Contact Name - ${contactName}. Preferred Contact No - ${contactNo}.`;
    }
    if (issueType === 'Power') {
      return `${customerName} reports a issue with the power. Located at ${issueLocation}. First noticed ${firstNoticed}. Customer enrolled in ${programName}. Program Effective From ${effectiveDate}. For Contractor's Information :- Preferred Contact Name - ${contactName}. Preferred Contact No - ${contactNo}.`;
    }
  },

  EELP: ({
    issueType,
    firstNoticed,
    customerName,
    outletLocation,
    switchLocation,
    issueLocation,
    programName,
    effectiveDate,
    additionalNotes,
    contactName,
    contactNo,
  }): any => {
    if (issueType === 'Outlets') {
      return `${customerName} reports a issue with the ${issueType}. located at ${outletLocation}. First noticed ${firstNoticed}. Customer enrolled in ${programName}. Program Effective From ${effectiveDate}. Additional notes: ${
        additionalNotes || '-'
      }. For Contractor's Information :- Preferred Contact Name - ${contactName}. Preferred Contact No - ${contactNo}.`;
    }
    if (issueType === 'Switches') {
      return `${customerName} reports a issue with the ${issueType}. Located at ${switchLocation}. First noticed ${firstNoticed}. Customer enrolled in ${programName}. Program Effective From ${effectiveDate}. Additional notes: ${
        additionalNotes || '-'
      }. For Contractor's Information :- Preferred Contact Name - ${contactName}. Preferred Contact No - ${contactNo}.`;
    }
    if (issueType === 'Power') {
      return `${customerName} reports a issue with the ${issueType}. Located at ${issueLocation}. First noticed ${firstNoticed}. Customer enrolled in ${programName}. Program Effective From ${effectiveDate}. Additional notes: ${
        additionalNotes || '-'
      }. For Contractor's Information :- Preferred Contact Name - ${contactName}. Preferred Contact No - ${contactNo}.`;
    }
    if (issueType === 'Outside lighting') {
      return `${customerName} reports a issue with ${issueType}. Located at ${issueLocation}. First noticed ${firstNoticed}. Customer enrolled in ${programName}. Program Effective From ${effectiveDate}. Additional notes: ${
        additionalNotes || '-'
      }. For Contractor's Information :- Preferred Contact Name - ${contactName}. Preferred Contact No - ${contactNo}.`;
    }
  },
  CSRP: ({
    firstNoticed,
    customerName,
    noOfSystems,
    issueLocation,
    programName,
    thermostat,
    effectiveDate,
    additionalNotes,
    contactName,
    contactNo,
  }): any =>
    `${customerName} reports a issue with Cooling Systems. Located at ${issueLocation}. Issue is with ${noOfSystems} system. Thermostat is on ${thermostat}. First noticed ${firstNoticed}. Customer enrolled in ${programName}. Program Effective From ${effectiveDate}. Additional notes: ${
      additionalNotes || '-'
    }. For Contractor's Information :- Preferred Contact Name - ${contactName}. Preferred Contact No - ${contactNo}.`,
  SRG: ({ firstNoticed, customerName, programName, lightsOff, effectiveDate, additionalNotes, contactName, contactNo, damagedAppliances }): any =>
    `${customerName} reports a surge event occurred ${firstNoticed}. Customer reports lights of Surge device are ${lightsOff}. Equipments/Appliances damaged: ${damagedAppliances.join(
      ', ',
    )}. Customer enrolled in ${programName}. Program Effective From ${effectiveDate}. Additional notes: ${
      additionalNotes || '-'
    }. For Contractor's Information :- Preferred Contact Name - ${contactName}. Preferred Contact No - ${contactNo}.`,
  HSRP: ({
    firstNoticed,
    customerName,
    noOfSystems,
    issueLocation,
    programName,
    unit,
    effectiveDate,
    additionalNotes,
    contactName,
    contactNo,
  }): any =>
    `${customerName} reports a issue with Heating Systems. Located at ${issueLocation}. Issue is with ${noOfSystems} system. Unit is ${unit}. First noticed ${firstNoticed}. Customer enrolled in ${programName}. Program Effective From ${effectiveDate}. Additional notes: ${
      additionalNotes || '-'
    }. For Contractor's Information :- Preferred Contact Name - ${contactName}. Preferred Contact No - ${contactNo}.`,
  HWHP: ({
    firstNoticed,
    customerName,
    issueLocation,
    programName,
    heaterType,
    issueType,
    effectiveDate,
    additionalNotes,
    contactName,
    contactNo,
  }): any => {
    if (issueType === 'No Hot Water') {
      return `${customerName} reports a ${issueType} issue. Located at ${issueLocation}. Water heater is ${heaterType}. First noticed ${firstNoticed}. Customer enrolled in ${programName}. Program Effective From ${effectiveDate}. Additional Notes : ${
        additionalNotes || '-'
      }. For Contractor's Information :- Preferred Contact Name - ${contactName}. Preferred Contact No - ${contactNo}.`;
    }
    if (issueType === 'Leak') {
      return `${customerName} reports a ${issueType} issue. Located at ${issueLocation}. Water heater is ${heaterType}. First noticed ${firstNoticed}. Customer enrolled in ${programName}. Program Effective From ${effectiveDate}. Additional Notes : ${
        additionalNotes || '-'
      }. For Contractor's Information :- Preferred Contact Name - ${contactName}. Preferred Contact No - ${contactNo}.`;
    }
  },
  WELL: ({ firstNoticed, customerName, programName, effectiveDate, additionalNotes, contactName, contactNo }): any =>
    `${customerName} reports a issue with well. First noticed ${firstNoticed}. Customer enrolled in ${programName}. Program Effective From ${effectiveDate}. Additional Notes : ${
      additionalNotes || '-'
    }. For Contractor's Information :- Preferred Contact Name - ${contactName}. Preferred Contact No - ${contactNo}.`,
  GLPP: ({ firstNoticed, customerName, programName, effectiveDate, contactName, gasOff, contactNo, leakLocation }): any =>
    `${customerName} reports a issue with Gas. First noticed ${firstNoticed}. Leak located at ${leakLocation}. Gas company has shut off the gas : ${gasOff}. Customer enrolled in ${programName}. Program Effective From ${effectiveDate}. Note: If gas company hasn't turned gas off, Immediately contact Gas Line company for immediate assistance and Please safely exit the premises or call 911. For Contractor's Information :- Preferred Contact Name - ${contactName}. Preferred Contact No - ${contactNo}.`,
  EIHP: ({
    issueType,
    firstNoticed,
    customerName,
    leakLocation,
    toiletLocation,
    faucetType,
    programName,
    issue,
    effectiveDate,
    additionalNotes,
    contactName,
    contactNo,
    fixture,
    backupLocation,
    drainsLocation,
  }): any => {
    if (issueType === 'Leak') {
      return `${customerName} reports a ${issueType}. Located at ${leakLocation}. First noticed ${firstNoticed}. Customer enrolled in ${programName}. Program Effective From ${effectiveDate}. Additional Notes : ${
        additionalNotes || '-'
      }. For Contractor's Information :- Preferred Contact Name - ${contactName}. Preferred Contact No - ${contactNo}.`;
    }
    if (issueType === 'Toilet') {
      return `${customerName} reports a ${issue} with the ${issueType}. Located at ${toiletLocation}. First noticed ${firstNoticed}. Customer enrolled in ${programName}. Program Effective From ${effectiveDate}. Additional Notes : ${
        additionalNotes || '-'
      }. For Contractor's Information :- Preferred Contact Name - ${contactName}. Preferred Contact No - ${contactNo}.`;
    }
    if (issueType === 'Faucet') {
      return `${customerName} reports a leak on ${faucetType} faucet. Located at ${leakLocation}. First noticed ${firstNoticed}. Customer enrolled in ${programName}. Program Effective From ${effectiveDate}. Additional Notes : ${
        additionalNotes || '-'
      }. For Contractor's Information :- Preferred Contact Name - ${contactName}. Preferred Contact No - ${contactNo}.`;
    }
    if (issueType === 'Blockage') {
      return `${customerName} reports a Clogged/Slow draining on ${fixture}. Located at ${backupLocation} and drains are slow at ${drainsLocation}. First noticed ${firstNoticed}. Customer enrolled in ${programName}. Program Effective From ${effectiveDate}. Additional notes: ${
        additionalNotes || '-'
      }. For Contractor's Information :- Preferred Contact Name - ${contactName}. Preferred Contact No - ${contactNo}.`;
    }
  },
  IHCP: ({
    issueType,
    firstNoticed,
    customerName,
    leakLocation,
    fixture,
    programName,
    effectiveDate,
    backupLocation,
    drainsLocation,
    additionalNotes,
    contactName,
    contactNo,
  }): any => {
    if (issueType === 'Leak') {
      return `${customerName} reports a constant leak on a supply line. Located at ${leakLocation}. First noticed ${firstNoticed}. Customer enrolled in ${programName}. Program Effective From ${effectiveDate}. Additional notes: ${
        additionalNotes || '-'
      }. For Contractor's Information :- Preferred Contact Name - ${contactName}. Preferred Contact No - ${contactNo}.`;
    }
    if (issueType === 'Blockage') {
      return `${customerName} reports a Clogged/Slow draining on ${fixture}. Located at ${backupLocation} and drains are slow at ${drainsLocation}. First noticed ${firstNoticed}. Customer enrolled in ${programName}. Program Effective From ${effectiveDate}. Additional notes: ${
        additionalNotes || '-'
      }. For Contractor's Information :- Preferred Contact Name - ${contactName}. Preferred Contact No - ${contactNo}.`;
    }
  },
  CRSS: ({
    firstNoticed,
    customerName,
    noOfSystems,
    issueLocation,
    programName,
    thermostat,
    effectiveDate,
    additionalNotes,
    contactName,
    contactNo,
  }): any =>
    `${customerName} reports a issue with Cooling Systems. Located at ${issueLocation}. Issue is with ${noOfSystems} system. Thermostat is on ${thermostat}. First noticed ${firstNoticed}. Customer enrolled in ${programName}. Program Effective From ${effectiveDate}. Additional notes: ${
      additionalNotes || '-'
    }. For Contractor's Information :- Preferred Contact Name - ${contactName}. Preferred Contact No - ${contactNo}.`,
  CRPS: ({
    firstNoticed,
    customerName,
    noOfSystems,
    issueLocation,
    programName,
    thermostat,
    effectiveDate,
    additionalNotes,
    contactName,
    contactNo,
  }): any =>
    `${customerName} reports a issue with Cooling Systems. Located at ${issueLocation}. Issue is with ${noOfSystems} system. Thermostat is on ${thermostat}. First noticed ${firstNoticed}. Customer enrolled in ${programName}. Program Effective From ${effectiveDate}. Additional notes: ${
      additionalNotes || '-'
    }. For Contractor's Information :- Preferred Contact Name - ${contactName}. Preferred Contact No - ${contactNo}.`,
  CPRS: ({
    firstNoticed,
    customerName,
    noOfSystems,
    issueLocation,
    programName,
    thermostat,
    effectiveDate,
    additionalNotes,
    contactName,
    contactNo,
  }): any =>
    `${customerName} reports a issue with Cooling Systems. Located at ${issueLocation}. Issue is with ${noOfSystems} system. Thermostat is on ${thermostat}. First noticed ${firstNoticed}. Customer enrolled in ${programName}. Program Effective From ${effectiveDate}. Additional notes: ${
      additionalNotes || '-'
    }. For Contractor's Information :- Preferred Contact Name - ${contactName}. Preferred Contact No - ${contactNo}.`,
  CPSS: ({
    firstNoticed,
    customerName,
    noOfSystems,
    issueLocation,
    programName,
    thermostat,
    effectiveDate,
    additionalNotes,
    contactName,
    contactNo,
  }): any =>
    `${customerName} reports a issue with Cooling Systems. Located at ${issueLocation}. Issue is with ${noOfSystems} system. Thermostat is on ${thermostat}. First noticed ${firstNoticed}. Customer enrolled in ${programName}. Program Effective From ${effectiveDate}. Additional notes: ${
      additionalNotes || '-'
    }. For Contractor's Information :- Preferred Contact Name - ${contactName}. Preferred Contact No - ${contactNo}.`,
};
