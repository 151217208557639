import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { takeUntil } from 'rxjs/operators';
import { timer } from 'rxjs/internal/observable/timer';
import { GlobalVariables } from '../../../core/services/global-variables.service';
import { ClaimsService } from '../../../core/services/claims.service';
import { SessionStorageService } from '../../../core/services/session-storage.service';

@Component({
  selector: 'hos-claims-success',
  templateUrl: './claims-success.component.html',
  styleUrls: ['./claims-success.component.scss'],
})
export class ClaimsSuccessComponent implements OnInit, OnDestroy {
  claimsResult: any;

  claimResult_error: any;

  claimNameResult: any;

  claimName: any;

  alive: boolean;

  callUpto = timer(40000);

  timerSubscription: any;

  enrolledProducts: any;

  product: any;

  locationCodeData: any;

  constructor(
    private route: ActivatedRoute,
    private globalVariables: GlobalVariables,
    private httpClient: HttpClient,
    private claimsService: ClaimsService,
    private sessionStorageService: SessionStorageService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.claimResult_error = this.route.snapshot.queryParams.error;
    this.claimsResult = this.globalVariables.claimResults;
    if (this.claimsResult) {
      // If claim name not defined we are making constant API calls after every 10sec till we get Claim Name
      this.alive = true;
      this.timerSubscription = timer(0, 10000)
        .pipe(takeUntil(this.callUpto))
        .subscribe(() => {
          this.getClaimName();
        });
    }
    this.enrolledProducts = JSON.parse(this.sessionStorageService.getItem('enrolledProductData'));
    this.globalVariables.locationData$().subscribe(data => {
      if (data) {
        this.locationCodeData = data;
      }
    });
    this.globalVariables.productData$().subscribe(data => {
      if (data) {
        this.product = data;
      }
    });
  }

  getClaimName() {
    this.claimsService.getClaimName(this.claimsResult.data.webclaimid).subscribe(
      res => {
        this.claimNameResult = res.body.data[0];
        this.claimName = this.claimNameResult.ClaimName;
        if (this.claimName) {
          this.alive = false;
          this.timerSubscription.unsubscribe();
        }
      },
      error => {
        console.log(error);
      },
    );
  }

  ngOnDestroy() {
    this.alive = false;
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }

  goToProducts() {
    if (this.product && this.locationCodeData) {
      const type = this.product.address.serviceAddress.contactType ? this.product.address.serviceAddress.contactType.toLowerCase() : 'residential';
      this.router.navigate([`/products/${this.product.address.serviceAddress.zipcode}/${this.locationCodeData.locationCode.toLowerCase()}/${type}`]);
    }
  }
}
