<hos-breadcrumb [config]="breadcrumsConfig"></hos-breadcrumb>
<!-- <hos-my-account-header pageName="Purchase History"></hos-my-account-header> -->
<section class="content-section content-block purchase-history-section">
  <div class="page-wrap">
    <div *ngIf="!purchaseDetails?.length" class="text-center p-5 col-12">
      <h5>You have not made any purchases!</h5>
    </div>
    <table *ngIf="purchaseDetails?.length" class="col-12 payment-history-table table-striped">
      <thead>
        <tr class="table-header-border light-text">
          <th width="15" class="light-text text-center">ID</th>
          <th class="light-text">Service(s) / Price</th>
          <th class="light-text">Service Address</th>
          <th class="light-text">Purchase Date</th>
          <th class="light-text">Enrollment Date</th>
          <th class="light-text">Renewal Date</th>
          <th class="light-text">Confirmation</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of purchaseDetails | paginate : pagingConfig">
          <td class="text-center">{{ item.crmEnrollmentNumber ? item.crmEnrollmentNumber : 'NA' }}</td>
          <td>
            <span>{{ item.productDetails.webProgramName }}</span>
            <br />
            <span>${{ item.cost }}</span>
            <span *ngIf="item.billingType === 'onbill'" class="light-text">(On {{ item.billingFrequency }} utility bill)</span>
            <span *ngIf="item.billingType === 'offbill'" class="light-text">(Paid during enrollment)</span>
          </td>
          <td class="skiptranslate">
            {{ item.address.serviceAddress.streetNumber }} {{ item.address.serviceAddress.streetName }}, <br />
            {{ item.address.serviceAddress.city }}, {{ item.address.serviceAddress.state }}, {{ item.address.serviceAddress.zipcode }}
          </td>
          <td>{{ item.createdAt | date : 'MM/dd/yyyy' : 'UTC' }}</td>
          <td>{{ item.effectiveFrom | date : 'MM/dd/yyyy' : 'UTC' }}</td>
          <td>{{ item.renewalDate | date : 'MM/dd/yyyy' : 'UTC' }}</td>
          <td class="text-center pdf-download"><span class="fa fa-file-pdf-o" (click)="downloadEnrolledPdf(item.orderId)"></span></td>
        </tr>
      </tbody>
    </table>
    <hr class="hr-history" />
    <div class="col-12 col-sm-12 col-md-12 row justify-content-center mt-3" *ngIf="purchaseDetails.length !== 0">
      <pagination-template #p="paginationApi" [id]="pagingConfig.id" (pageChange)="goToPage($event)">
        <nav class="col-12">
          <ul class="pagination">
            <li class="page-item" [class.disabled]="p.isFirstPage()">
              <span class="page-link" (click)="p.previous()">Previous</span>
            </li>
            <li class="page-item" *ngFor="let page of p.pages" [class.active]="p.getCurrent() === page.value">
              <a class="page-link" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">{{ page.label }}</a>
              <span class="page-link" *ngIf="p.getCurrent() === page.value">{{ page.label }}</span>
            </li>
            <li class="page-item" [class.disabled]="p.isLastPage()">
              <a class="page-link" (click)="p.next()">Next</a>
            </li>
          </ul>
        </nav>
      </pagination-template>
    </div>
  </div>
</section>

<div [ngClass]="{ loading: showLoader }"></div>
