import { Injectable } from '@angular/core';
import { Observable, Subject, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService } from './http';
import { SessionStorageService } from './session-storage.service';
import { environment } from '../../../environments/environment';
import file from '../../../assets/states.json';

@Injectable()
export class SiteContentService {
  public subject = new Subject<object>();

  public event = this.subject.asObservable();

  statesStream: ReplaySubject<any> = new ReplaySubject();

  constructor(private http: HttpService, private sessionStorageService: SessionStorageService) {
    this.getStatesData();
  }

  public getStatesData() {
    const statesList = file;
    this.sessionStorageService.setItem('statesList', JSON.stringify(statesList));
    this.statesStream.next(statesList);
  }

  getStateListData(stateName?: String) {
    let url = `${environment.Location_service_API_Endpoint}statelist`;
    const name = stateName;
    if (name) {
      url += `?name=${name}`;
    }
    return this.http.get(url).pipe(map(res => res));
  }

  getHomePartnerData(key: String) {
    return this.http.get(`${environment.Content_service_API_Endpoint}home-content/section/?key=${key}`).pipe(map(res => res));
  }

  getPartnersData() {
    return this.http.get(`${environment.Partner_service_API_Endpoint}hos-partner/`).pipe(map(res => res));
  }

  getPartnerDetails(code: String) {
    return this.http.get(`${environment.Content_service_API_Endpoint}microsite/${code}`).pipe(map(res => res));
  }

  getUnbouncePageData(url?: any) {
    const query = url ? `?url=${url}` : '';
    return this.http
      .get(`${environment.Content_service_API_Endpoint}unbounce/${query}`, {
        observe: 'response',
      })
      .pipe(map(res => res));
  }

  getMicrositeNames() {
    return this.http.get(`${environment.Content_service_API_Endpoint}micrositenames`).pipe(
      map(res => {
        environment.micrositeNames = res.body;
        return res;
      }),
    );
  }

  public publish(data: any) {
    this.subject.next(data);
  }

  statesdata$(): Observable<any> {
    return this.statesStream.asObservable();
  }
}
