import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { find } from 'lodash-es';
import { SimpleModalComponent, SimpleModalService } from 'ngx-simple-modal';
import { UserService } from '../../services/user.service';
import { WindowScrolling } from '../../../core/services/window-scrolling.service';
import { AuditService } from '../../services/audit.service';
import { AlertConfirmModalComponent } from '../../../core/component/alert-confirm-modal/alert-confirm-modal.component';
import { GlobalVariables } from '../../../core/services/global-variables.service';
import { environment } from '../../../../environments/environment';

export interface CustomerServiceValidationModalModel {
  title: string;
  message: string;
  alertIcon: string;
  cancelText: string;
  confirmText: string;
  data: any;
  existing: any;
  modalFor: number;
  deleteAddressPayload?: any;
  address?: any;
}

@Component({
  selector: 'hos-customer-service-validation-modal',
  templateUrl: './customer-service-validation-modal.component.html',
  styleUrls: ['./customer-service-validation-modal.component.scss'],
})
export class CustomerServiceValidationModalComponent
  extends SimpleModalComponent<CustomerServiceValidationModalModel, Boolean>
  implements CustomerServiceValidationModalModel
{
  title: string;

  message: string;

  alertIcon: string;

  cancelText: string;

  confirmText: string;

  data: any;

  existing: any;

  selectedMarketingCode: string;

  error: Boolean = false;

  modalFor: number;

  msg: String = 'Please select one to proceed.';

  deleteAddressPayload: any;

  msgs: any[] = [];

  address: any;

  email: string;

  isExistingUser: boolean;

  isDisposableEmail: boolean;

  email_regex = environment.email_regex;

  @ViewChild('emailInput') emailInput;

  constructor(
    private simpleModalService: SimpleModalService,
    private router: Router,
    private userService: UserService,
    private windowScrolling: WindowScrolling,
    private auditService: AuditService,
    private globalVariable: GlobalVariables,
  ) {
    super();
    // this.windowScrolling.disable();
  }

  selectCode(marketingCode: string) {
    this.error = false;
    this.selectedMarketingCode = marketingCode;
  }

  confirmHandler() {
    if (this.modalFor === 3) {
      if (this.emailInput.valid) {
        this.emailBlurHandler(this.emailInput);
      } else {
        this.emailInput.isBlur = true;
      }
      return;
    }
    if (!this.selectedMarketingCode) {
      if (this.modalFor === 2) {
        this.msg = 'Please input your password to proceed.';
      }
      this.error = true;
    } else {
      let isExist = this.existing;
      if (!this.modalFor) {
        isExist = find(this.existing, code => code === this.selectedMarketingCode);
      }
      if (!this.modalFor && !!isExist) {
        this.result = true;
      } else if (this.modalFor === 1 && this.existing === this.selectedMarketingCode) {
        this.result = true;
      } else if (this.modalFor === 2) {
        this.checkAuthentication();
      } else {
        this.result = false;
      }
      if (this.modalFor !== 2) {
        this.close();
        this.windowScrolling.enable();
      }
    }
  }

  checkAuthentication() {
    this.userService.signIn(this.existing, this.selectedMarketingCode).subscribe(
      data => {
        if (data && this.deleteAddressPayload.actionType) {
          this.deleteServiceAddress();
        }
        this.result = true;
        this.close();
        this.windowScrolling.enable();
      },
      error => {
        this.windowScrolling.enable();
        this.msg = error.error.message;
        if (this.msg === 'Invalid Email or Password.') {
          this.msg = 'Wrong password, please try again.';
        } else {
          this.close();
          this.userService.signOut();
          this.userService.setLoggin('');
          this.router.navigate(['']);
        }
        this.error = true;
        this.result = false;
      },
    );
  }

  closeModal() {
    this.windowScrolling.enable();
    this.close();
  }

  refresh(response) {
    this.result = response;
    this.close();
  }

  deleteServiceAddress() {
    this.userService.deleteProperty(this.deleteAddressPayload).subscribe(
      response => {
        if (response.status === 200) {
          const trackingId = 'remove_property';
          const auditData = {
            customerId: this.deleteAddressPayload.customerId,
            userName: this.deleteAddressPayload.email,
            isDeletedBy: 'web',
            action: {
              customerId: this.deleteAddressPayload.customerId,
              customerNo: [this.deleteAddressPayload.customerNo],
              actionType: this.deleteAddressPayload.actionType,
            },
          };
          this.auditService.saveToAudit(auditData).subscribe(() => {
            let message = 'Web Account Deleted Sucessfully';
            if (this.deleteAddressPayload.actionType === 'delete_property') {
              message = 'Property Deleted Sucessfully';
            }
            message = `<div class="success-text">${message}</div>`;
            this.simpleModalService
              .addModal(
                AlertConfirmModalComponent,
                {
                  title: '',
                  message,
                  alertIcon: 'fa fa-exclamation-circle red',
                  alertType: 'info-type',
                  confirmText: 'ok',
                  confirmBtnId: trackingId || 'close',
                },
                {
                  wrapperClass: 'modal-main-class in',
                  closeOnClickOutside: false,
                },
              )
              .subscribe(isConfirmed => {
                if (isConfirmed && auditData.action.actionType === 'delete_web_account') {
                  this.userService.signOut();
                  this.router.navigate(['/']);
                }
                if (isConfirmed && auditData.action.actionType === 'delete_property') {
                  this.globalVariable.removeProperty.emit({
                    customerNo: this.deleteAddressPayload.customerNo,
                  });
                }
              });
          });
        }
      },
      error => {
        this.refresh(error);
      },
    );
  }

  emailKeyPressHandler(input: any) {
    input.isBlur = false;
    this.isExistingUser = false;
  }

  emailBlurHandler(input: any) {
    this.isDisposableEmail = false;
    input.isBlur = true;
    this.isExistingUser = false;
    if (input.valid) {
      if (environment.disposable_email.includes(this.email.split('@')[1].toLowerCase())) {
        input.isBlur = false;
        this.isDisposableEmail = true;
      } else {
        this.userService.verifyUser(input.value).subscribe(
          data => {
            if (data.status === 200) {
              this.isExistingUser = true;
              input.isBlur = false;
            }
          },
          () => {
            this.isExistingUser = false;
            this.result = this.email as any;
            this.close();
            this.windowScrolling.enable();
          },
        );
      }
    }
  }
}
