<div class="modal-dialog custom-error-alert modal-content modal-background">
  <div class="modal-header modal-custom-header error-modal-custom-header" [ngClass]="alertType ? alertType : 'info-type'">
    <div class="info-background mx-auto"></div>
    <!-- <h4 class="modal-title">
                <div class="{{alertIcon}} alert-icon"></div>
                <div class="pl-3">{{title}}</div>
            </h4> -->
  </div>
  <div class="modal-body">
    <h4 class="mb-3" *ngIf="title">{{ title }}</h4>
    <p [innerHtml]="message | safeHtml" class="info-txt pt-4 mb-0"></p>
  </div>

  <div class="modal-footer">
    <div class="cursor-pointer m-0" *ngIf="cancelText && cancelText !== ' '">
      <button
        [attr.id]="cancelBtnId ? cancelBtnId : 'cancel'"
        class="btn block full-width print-btn skiptranslate"
        (click)="close()"
        [attr.title]="cancelText ? cancelText : 'Cancel'"
      >
        {{ cancelText || 'Cancel' }}
      </button>
    </div>
    <div class="marginTop mx-0" [ngClass]="{ 'mx-auto': !cancelText || cancelText === ' ' }">
      <button
        [attr.id]="confirmBtnId ? confirmBtnId : 'submit'"
        (click)="confirmHandler()"
        class="btn submit-btn block full-width app-btn mt-0 skiptranslate p-0"
        [attr.title]="confirmText ? confirmText : 'Ok'"
      >
        {{ confirmText || 'Ok' }}
      </button>
    </div>
  </div>
</div>
