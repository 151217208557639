<div class="modal-dialog modal-content modal-background">
  <div class="modal-body">
    <div class="row">
      <div class="col-sm-12 service-header-box">
        <div class="service-header pull-left">
          <div class="pull-left m-r-l header-image">
            <img [src]="getImage(imageBaseUrl + service.productDetails?.programIconUrl)" class="service-logo" />
          </div>
          <div class="pull-left col-9">
            <p class="title mb-0">{{ service?.productDetails?.webProgramName || service.productDetails?.customerProgramName }}</p>
            <span class="details-and-terms-condition">
              <a class="non-href-links" (click)="goToTerms(service)">
                <i class="fa fa-file-pdf-o m-r-sm" aria-hidden="true"></i> Terms &#38; Conditions</a
              >
            </span>
          </div>
        </div>
        <button type="button" class="close pull-right set-font" (click)="close()">
          <i class="fa fa-times" aria-hidden="true"></i>
        </button>
      </div>
      <div class="col-sm-12 mobile-term-condition">
        <a class="non-href-links" (click)="goToTerms(service)"> <i class="fa fa-file-pdf-o m-r-sm" aria-hidden="true"></i> Terms &#38; Conditions</a>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-7">
        <div class="col-sm-12 panel m-0 m-b-sm row">
          <div class="col-12 col-md-5">
            <i class="fa fa-clock-o m-r-sm"></i>
            Billing period:
          </div>
          <div class="billing-panel col-12 col-md-7">
            {{ service?.effectiveFrom | date : 'MM-dd-yy' : 'UTC' }} - {{ service?.renewalDate | date : 'MM-dd-yy' : 'UTC' }}
          </div>
        </div>
        <div class="col-12 panel service-content">
          <div class="row">
            <div class="col-6 m-b-sm detail-block">
              <img
                [src]="
                  (service?.renewalDate | date : 'yyyy-MM-dd-yy' : 'UTC') >= today
                    ? 'img/my-account/Status_Normal.svg'
                    : 'img/my-account/Status_Alert.svg'
                "
                class="pull-left"
              />
              <div>
                <span class="grey"
                  >Status
                  <br />
                </span>
                <span class="text">
                  {{ (service?.renewalDate | date : 'yyyy-MM-dd HH:mm:s' : 'UTC') >= today ? 'Current' : 'Suspended' }}
                </span>
              </div>
            </div>
            <div class="col-6 m-b-sm detail-block">
              <img
                [src]="
                  (service?.renewalDate | date : 'yyyy-MM-dd HH:mm:s' : 'UTC') >= today
                    ? 'img/my-account/RenewalDate_Normal.svg'
                    : 'img/my-account/RenewalDate_Alert.svg'
                "
                class="pull-left m-r-xl"
              />
              <div>
                <span class="grey">Renewal Date</span>
                <span class="text red">{{ service?.renewalDate | date : 'MM-dd-yy' : 'UTC' }}</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6 m-b-sm cost-block">
              <img src="img/my-account/Cost_label.svg" class="pull-left" />
              <div class="text">
                <span class="cost">${{ service?.enrollmentPrice.TaxDetails.EnrollmentPrice }}</span>
                <br />
                per {{ service && service.billingFrequency === 'Monthly' ? 'Month' : 'Year' }}
              </div>
            </div>
            <div class="col-6 m-b-sm detail-block">
              <img
                [src]="service.billingType === 'onbill' ? 'img/my-account/Payment_01.svg' : 'img/my-account/Payment_02.svg'"
                class="pull-left billing-type-info-img"
              />
              <div>
                <span class="grey">Payment Type</span>
                <span class="text" *ngIf="service.billingType === 'onbill'">On Utility Bill</span>
                <span class="text" *ngIf="service.billingType === 'offbill'">{{ service.paymentMethod === 'ACH' ? 'ACH' : 'Credit Card' }}</span>
              </div>
            </div>
          </div>
        </div>
        <!--div class="col-12 text-center claim-btn">
                        <button class="btn btn-primary app-btn m-b-sm" (click)="close()" routerLink="/support/repair-a-request">Request a repair</button>
                    </div-->
      </div>
      <div class="col-sm-5 black-text font-14">
        <div class="col-sm-12 panel right-panel">
          <span class="text-left">Last Payment ({{ service?.createdAt | date : 'MM-dd-yy' : 'UTC' }}): </span>
          <span class="pull-right">
            ${{ service?.enrollmentPrice?.TaxDetails?.Total ? service?.enrollmentPrice?.TaxDetails?.Total?.toFixed(2) : 'NA' }}</span
          >
          <span class="details-and-terms-condition payment-history-link"> Payment History</span>
        </div>
        <div class="col-sm-12 panel right-panel">
          <div>
            <span class="text-left">Service Program Cost </span>
            <span class="pull-right">
              ${{
                service?.enrollmentPrice?.TaxDetails?.EnrollmentPrice ? service?.enrollmentPrice?.TaxDetails?.EnrollmentPrice?.toFixed(2) : 'NA'
              }}</span
            >
          </div>
          <div *ngIf="service?.enrollmentPrice?.TaxDetails?.Tax">
            <span class="text-left">Taxes </span>
            <span class="pull-right"> ${{ service?.enrollmentPrice?.TaxDetails?.Tax?.toFixed(2) }}</span>
          </div>
        </div>
        <div class="total">
          <span class="text-left">Total </span>
          <span class="pull-right text">
            ${{ service?.enrollmentPrice?.TaxDetails?.Total ? service?.enrollmentPrice?.TaxDetails?.Total?.toFixed(2) : 'NA' }}</span
          >
        </div>
        <div class="col-sm-12 panel mt-2">
          <span> <i class="fa fa-clock-o m-r-xs"></i> Next Billing Date: </span>
          <span class="pull-right"> {{ nextBillingDate ? (nextBillingDate | date : 'MM-dd-yy' : 'UTC') : 'NA' }}</span>
        </div>
      </div>
    </div>
    <div class="modal-footer login-footer"></div>
  </div>
</div>
