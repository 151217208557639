import { Component, OnInit } from '@angular/core';
import { SiteContentService } from '../../../core/services/siteContents';
import { SessionStorageService } from '../../../core/services/session-storage.service';
import { UtilsService } from '../../../core/services/utils.service';

@Component({
  selector: 'hos-partner-detail',
  templateUrl: './partner-detail.component.html',
  styleUrls: ['./partner-detail.component.scss'],
})
export class PartnerDetailComponent implements OnInit {
  siteContent: any = {};

  constructor(
    private siteContentService: SiteContentService,
    private sessionStorageService: SessionStorageService,
    private utilsService: UtilsService,
  ) {}

  ngOnInit() {
    const state = this.sessionStorageService.getItem('providerState');
    if (state) {
      this.utilsService.getPartnerDetails$().subscribe(
        data => {
          this.siteContent = data.body;
        },
        () => {},
      );
    }
  }
}
