<section class="page-wrap things-after-register">
  <div class="row page-wrap">
    <hr class="d-none d-md-flex col-md-2 px-0 my-auto" />
    <p class="col-12 col-md-8 header-text">Here are a few things you can do after you {{ selectedPageText }}</p>
    <hr class="d-none d-md-flex col-2 px-0 my-auto" />
  </div>
  <div class="row page-wrap">
    <div class="col-md-4 col-sm-6 col-12 card-block" *ngFor="let card of cards">
      <div class="info-card row justify-content-center">
        <img class="col-3" [src]="card.image" />
        <p class="col-9 m-auto card-text">{{ card.text }}</p>
      </div>
    </div>
  </div>
</section>
