import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { SimpleModalService } from 'ngx-simple-modal';
import { UserService } from '../../services/user.service';
import { environment } from '../../../../environments/environment';
import { SessionStorageService } from '../../../core/services/session-storage.service';

@Component({
  selector: 'hos-activate-account',
  templateUrl: './activate-account.component.html',
  styleUrls: ['./activate-account.component.scss'],
})
export class ActivateAccountComponent implements OnInit {
  isActivated: Boolean = false;

  authenticationSuccess: String = '';

  authenticationError: String = '';

  errorMessage: String = '';

  email_regex: String;

  successMessage: String = '';

  errorResendMessage: String = '';

  /**
   *
   * Creates an instance of ActivateAccountComponent.
   *
   * @param {Router} router
   * @param {UserService} userService
   * @param {ActivatedRoute} activatedRoute
   *
   * @memberof ActivateAccountComponent
   */
  constructor(
    private router: Router,
    private userService: UserService,
    public activatedRoute: ActivatedRoute,
    private sessionStorageService: SessionStorageService,
    private simpleModalService: SimpleModalService,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
    this.sessionStorageService.deleteItem('authHeader');
    this.sessionStorageService.deleteItem('refreshToken');
    this.userService.setLoggin('');
    this.email_regex = environment.email_regex;
  }

  ngOnInit() {
    const { token } = this.activatedRoute.snapshot.queryParams;
    const referer = this.activatedRoute.snapshot.queryParams.retainUrl;
    if (isPlatformBrowser(this.platformId)) {
      if (token) {
        this.userService.activateAccount(token).subscribe(
          data => {
            if (data.status === 200) {
              this.authenticationSuccess = "Account is activated please click on 'Log in' to access your account.";
            }
            if (referer) {
              setTimeout(() => {
                this.router.navigate(['/user/login'], {
                  queryParams: {
                    referer: encodeURIComponent(referer),
                  },
                });
              }, 2500);
            }
          },
          error => {
            if (error.status === 400) {
              this.errorMessage = 'Token is expired';
              this.authenticationSuccess = '';
            } else if (error.status === 409) {
              this.isActivated = true;
              this.errorMessage = "Email is already verified, please click on 'Log in' to access your account.";
              this.authenticationSuccess = '';
            } else {
              this.errorMessage = 'Unable to verify';
              this.authenticationSuccess = '';
            }
            this.authenticationError = 'failure';
          },
        );
      } else {
        this.authenticationError = 'failure';
      }
    }
  }

  onSubmit(activationForm: NgForm) {
    this.userService.resendActivationEmail(activationForm.value.email).subscribe(
      data => {
        if (data.status === 200) {
          this.errorResendMessage = '';
          this.successMessage = `An activation email has been sent
        to the following email address "${activationForm.value.email}". Please check your email.`;
        }
      },
      error => {
        this.successMessage = '';
        if (error.status === 400) {
          this.errorResendMessage = 'Please provide valid email address.';
        } else if (error.status === 404) {
          this.errorResendMessage = 'User not found.';
        } else {
          this.errorResendMessage = 'Unable to send activation email';
        }
      },
    );
  }
}
