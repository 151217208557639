/* eslint-disable prefer-destructuring */
import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class JsonLDService {
  scriptType = 'application/ld+json';

  constructor(@Inject(DOCUMENT) private document: Document) {}

  removeStructuredData(): void {
    let script;
    let needToRemove = false;
    if (this.document.head.getElementsByClassName('structured-data').length) {
      needToRemove = true;
      script = this.document.head.getElementsByClassName('structured-data')[0];
    }
    if (needToRemove) {
      this.document.head.removeChild(script);
    }
  }

  insertSchema(schema: Record<string, any>, className = 'structured-data'): void {
    let script;
    let needToAppend = false;
    if (this.document.head.getElementsByClassName(className).length) {
      script = this.document.head.getElementsByClassName(className)[0];
    } else {
      script = this.document.createElement('script');
      needToAppend = true;
    }
    script.setAttribute('class', className);
    script.type = this.scriptType;
    script.text = JSON.stringify(schema);
    if (needToAppend) {
      this.document.head.appendChild(script);
    }
  }
}
