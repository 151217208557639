<div class="modal-dialog custom-error-alert modal-content modal-background">
  <div class="modal-header modal-custom-header error-modal-custom-header">
    <div class="info-background mx-auto">
      <i class="fa fa-info-circle my-auto" aria-hidden="true"></i>
    </div>
  </div>
  <div class="modal-body">
    <p>
      There is a bundle service available for the products you have selected. These products will automatically be converted to the bundle option.
    </p>
    <div class="card">
      <div *ngFor="let product of productsInfo">
        <img class="icon-style" [src]="getImage(baseUrl + product.programIconUrl)" /> {{ product.webProgramName }}
        <br />
      </div>
    </div>
    <div class="text-center">
      <i class="fa fa-arrow-down arrow-font" aria-hidden="true"></i>
    </div>
    <div class="bundle-detail">
      <span> <img class="icon-style" [src]="getImage(baseUrl + upgradedWebProgramIconUrl)" /> {{ upgradedWebProgramName }} </span>
    </div>
  </div>

  <div class="modal-footer text-center">
    <div class="mx-auto">
      <button (click)="confirmHandler()" class="btn btn-primary block full-width app-btn">Ok</button>
    </div>
  </div>
</div>
