import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NguCarouselModule } from '@ngu/carousel';

import { NgxPaginationModule } from 'ngx-pagination';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { ProductModule } from '../product/product.module';
import { CartModule } from '../cart/cart.module';
import { CoreModule } from '../core/index';
import { TermsServiceService } from './service/terms-service.service';
import { PageNotFound404Module } from '../page-not-found404/index';
import { HomeModule } from '../home/index';

// Components
import { ProductDetailsBodyComponent } from './component/body/body.component';
import { ProductDetailHeaderComponent } from './component/detail-header/detail-header.component';
import { ProductGeneralComponent } from './component/general/general.component';
import { ProductBenifitComponent } from './component/benifits/benifits.component';
import { ProductsCoveredRepairsComponent } from './component/covered-repairs/covered-repairs.component';
import { ProductVideoComponent } from './component/video/video.component';
import { ProductTestimonialsComponent } from './component/testimonials/testimonials.component';
import { ProductFaqComponent } from './component/faq/faq.component';
import { ProductTermsConditionsComponent } from './component/terms-conditions/terms-conditions.component';
import { NeighborhoodServicesComponent } from './component/neighborhood-services/neighborhood-services.component';
import { ProductDetailRoutingModule } from './product-detail-routing';
import { ProductReviewComponent } from './component/product-review/product-review.component';
import { ProductReviewFormComponent } from './component/product-review-form/product-review-form.component';
import { AllProductReviewsComponent } from './component/product-review/all-product-reviews/all-product-reviews.component';
// Animation

@NgModule({
  entryComponents: [ProductReviewFormComponent],
  declarations: [
    ProductDetailsBodyComponent,
    ProductDetailHeaderComponent,
    ProductGeneralComponent,
    ProductBenifitComponent,
    ProductsCoveredRepairsComponent,
    ProductVideoComponent,
    ProductTestimonialsComponent,
    ProductFaqComponent,
    ProductTermsConditionsComponent,
    NeighborhoodServicesComponent,
    ProductReviewComponent,
    ProductReviewFormComponent,
    AllProductReviewsComponent,
  ],
  exports: [
    ProductDetailsBodyComponent,
    ProductDetailHeaderComponent,
    ProductGeneralComponent,
    ProductReviewComponent,
    ProductReviewFormComponent,
    ProductBenifitComponent,
    ProductsCoveredRepairsComponent,
    ProductVideoComponent,
    ProductTestimonialsComponent,
    ProductFaqComponent,
    ProductTermsConditionsComponent,
    NeighborhoodServicesComponent,
    AllProductReviewsComponent,
  ],
  imports: [
    CommonModule,
    ProductDetailRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    NguCarouselModule,
    ProductModule,
    CartModule,
    CoreModule,
    NgxPaginationModule,
    PageNotFound404Module,
    // BrowserAnimationsModule,
    HomeModule,
    CarouselModule,
  ],
  providers: [TermsServiceService],
})
export class ProductDetailModule {}
