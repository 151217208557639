import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { SessionStorageService } from '../../../core/services/session-storage.service';

@Component({
  selector: 'hos-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent {
  showError: Boolean = false;

  isNotConfirmed: Boolean = false;

  errorMessage: String = '';

  /**
   *
   * Creates an instance of ChangePasswordComponent.
   *
   * @param {Router} router
   * @param {UserService} userService
   * @param {SessionStorageService} sessionStorageService
   *
   * @memberof ChangePasswordComponent
   */
  constructor(private router: Router, private userService: UserService, private sessionStorageService: SessionStorageService) {}

  /**
   * Form submit handler
   *
   * @param {any} form
   *
   * @memberof ChangePasswordComponent
   */
  onSubmit(form: any) {
    this.errorMessage = '';
    if (form.valid && form.value.password === form.value.confirmPassword) {
      this.isNotConfirmed = false;
      this.showError = false;
      this.userService.changePassword(form.value.password, form.value.confirmPassword).subscribe(
        data => {
          this.errorMessage = '';
          if (data.status === 200) {
            this.sessionStorageService.deleteItem('authHeader');
            this.sessionStorageService.deleteItem('refreshToken');
            this.router.navigate(['/']);
          }
        },
        error => {
          // handle error here for incorect login
          this.errorMessage = `We are experiencing technical difficulties. Please try again.
If you require further assistance please call customer service.`;
          if (error && error.error) {
            this.errorMessage = error.error.message;
          }
          this.showError = true;
        },
      );
    } else {
      this.showError = true;
      if (form.value.password !== form.value.confirmPassword) {
        this.isNotConfirmed = true;
      }
    }
  }
}
