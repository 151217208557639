import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { Breadcrumb } from '../../core/component/breadcrumb/breadcrumb.component';
import { SeoService } from '../../core/services/seo.service';
import { SeoConfig } from '../../core/model/seo-config.model';
import { HttpService } from '../../core/services/http';

@Component({
  selector: 'hos-page-not-found404',
  templateUrl: './page-not-found404.component.html',
  styleUrls: ['./page-not-found404.component.scss'],
})
export class PageNotFound404Component implements OnInit {
  loading: boolean = true;

  breadcrumsConfig: Array<Breadcrumb> = [
    {
      title: 'Home',
      link: '/',
      active: false,
    },
    {
      title: 'Page Not found',
      link: '',
      active: true,
    },
  ];

  seoConfig: SeoConfig = {
    title: '404 Page not found',
    description: `American Water Resources offers affordable home protection programs
  that help protect against unexpected repair costs for service line emergencies.`,
    keywords: `service line warranties, water line protection, sewer line protection,
  in home plumbing emergencies, electric line protection, power surge protection, heating system repair, cooling system repair`,
    image: '',
    slug: '',
    sitename: '',
    type: '',
    summary: '',
    url: '',
  };

  isBrowser: any;

  constructor(private seoService: SeoService, private http: HttpService, private router: Router, @Inject(PLATFORM_ID) private platformId: any) {
    this.isBrowser = isPlatformBrowser(this.platformId);
    if (this.isBrowser) {
      this.seoConfig.url = window.location.origin + window.location.pathname;
    }
    seoService.generateTitleTags(this.seoConfig);
    seoService.setCanonicalTags();
  }

  ngOnInit() {
    this.loading = false;
  }
}
