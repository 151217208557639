import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../services/user.service';
import { copyCodeConfig } from './copy-code-map';

@Component({
  selector: 'hos-increased-price-cus-info',
  templateUrl: './increased-price-cus-info.component.html',
  styleUrls: ['./increased-price-cus-info.component.scss'],
})
export class IncreasedPriceCusInfoComponent implements OnInit {
  purchaseDetails: any = [];

  customerId: string;

  showLoader: boolean;

  custDetailsNotFoundComp: boolean = false;

  labelKeys: any = [];

  labelValues: any = [];

  copyCodeProductNames: Object = copyCodeConfig;

  constructor(private userService: UserService, private activatedRoute: ActivatedRoute) {
    this.activatedRoute.params.subscribe(params => {
      this.customerId = params.id || this.activatedRoute.snapshot.queryParams.id;
    });
  }

  ngOnInit() {
    this.getPriceDetailInfo(this.customerId);
  }

  getPriceDetailInfo(customerId: string) {
    this.showLoader = true;
    this.userService.getIncreasedPriceCustomerInfo(customerId).subscribe(
      data => {
        this.showLoader = false;
        const custInfo = data.body;
        this.purchaseDetails = custInfo.data;
        if (this.purchaseDetails[0]) {
          const copyCode = this.purchaseDetails[0]['Copy Code'];

          if (this.purchaseDetails[0]?.syncSource.toLowerCase() === 'awr' || this.purchaseDetails[0]?.syncSource.toLowerCase() === 'ouc') {
            const purchaseData = Object.keys(custInfo.data[0])
              .filter(key => key.startsWith('product') && key.slice(7).length < 4 && custInfo.data[0][key])
              .map(key => custInfo.data[0][key]);
            const custCareNumber = Object.keys(custInfo.data[0])
              .filter(key => key.startsWith('1800_support') && custInfo.data[0][key])
              .map(key => custInfo.data[0][key].replace(/\r$/, ''));
            const details = {
              end_date: this.purchaseDetails[0].effectivedate,
              customerCareNumber: custCareNumber[0] || '1.855.716.5487',
              billing_profile: 'monthly',
              FullName: `${this.purchaseDetails[0].firstname}  ${this.purchaseDetails[0].lastname}`,
            };
            this.purchaseDetails[0].products = purchaseData;
            this.purchaseDetails[0] = { ...this.purchaseDetails[0], ...details };
          } else if (this.purchaseDetails[0]?.syncSource.toLowerCase() === 'rpi') {
            const details = {
              FullName: `${this.purchaseDetails[0].first_name}  ${this.purchaseDetails[0].last_name}`,
              customerCareNumber: '1.855.390.4573',
              end_date: this.purchaseDetails[0].effectivedate || this.purchaseDetails[0].end_date || '02/10/2023',
            };
            this.labelKeys.push(this.purchaseDetails[0].pkg1_desc);
            this.labelValues.push(this.purchaseDetails[0].total_amount);
            // eslint-disable-next-line
            this.purchaseDetails[0].pkg1_desc = this.purchaseDetails[0].pkg1_desc.replace(/^\"|\"$/g, '');
            this.purchaseDetails[0] = { ...this.purchaseDetails[0], ...details };
          } else if (copyCode) {
            this.labelKeys = Object.keys(this.copyCodeProductNames[copyCode]);
            this.labelValues = Object.values(this.copyCodeProductNames[copyCode]);
            const details = {
              end_date: '02/10/2023',
              customerCareNumber: '1.855.716.5487',
              billing_profile: 'monthly',
            };
            this.purchaseDetails[0] = { ...this.purchaseDetails[0], ...details };
          } else {
            this.custDetailsNotFoundComp = true;
          }
        }
      },
      () => {
        this.showLoader = false;
        this.custDetailsNotFoundComp = true;
      },
    );
  }
}
