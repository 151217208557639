<section class="payment-box content-section shaded-white">
  <article>
    <div class="ets-box col-12">
      <div class="card border-light mt-0 mb-3" [hidden]="!selectedAddress || productsByBillingType.offbill.products.length === 0">
        <div>
          <div class="payment-body">
            <div class="add-payment-method add-method-card">
              <div class="col-12 style-payment-text">
                <label class="payment-label new-card-label" for="card-selection-new-checkout" style="margin-bottom: 0 !important">
                  <img src="/img/various/New-Payment-Ico.svg" alt="New Payment Icon" /> Select a Payment Method</label
                >
              </div>
            </div>

            <div id="checkout_order_error" class="btn gaTrackBtn"></div>

            <!-- <div *ngIf="!globalVariables.migratedToOracle" class="payment-gateway">
              <div class="block_div" id="hp" data-show-Discover="false" data-allow-avs-skip="false" data-disable-autocomplete="true"></div>
            </div> -->
            <div class="payment-body">
              <div id="stripe-payment-quick-checkout" style="padding: 15px"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </article>
</section>

<div [ngClass]="{ loading: showLoader || !sessionTokenGenerated }"></div>
