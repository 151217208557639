import { environment } from '../../../environments/environment';

export enum PaymentMode {
  GENERAL_PAYMENT = 'GENERAL_PAYMENT', // for new enrollment
  ONE_TIME_PAYMENT = 'CLAIM_CREATION', // for suspended one time charge / service fee
  UPDATE_PAYMENT = 'UPDATE_PAYMENT_METHOD', // needs to be added for update payment without amountInCents
}

export enum PaymentMethod {
  CARD = 'card',
  ACH = 'us_bank_account',
}

export interface PaymentPayload {
  customer: {
    oracleCustomerId: string;
    postalCode: string;
    fullName: string;
    email: string;
    oracleCustomerGuid?: string;
  };
  amount?: string;
  paymentMethods: PaymentMethod[];
  mountNode: HTMLElement;
  disabledPaymentMethodIds?: string[];
  enrollments: {
    referenceNumber: string;
    amount?: number;
  }[];
}

export interface PaymentResponse {
  intent: Intent;
  method: CCMethod | ACHMethod;
  referenceNumber: string;
}

interface IError {
  error: any;
  referenceNumber: string;
}

type IntentPaymentResponse = PaymentResponse | IError;

export interface Intent {
  payment_method_id: string;
  stripe_customer_id: string;
  status: string;
  payment_intent_id: string;
  response_code: string;
  response_message: string;
  response_description: string;
}

export interface CCMethod {
  payment_method_id: string;
  payment_method_type: 'Credit Card';
  brand: string;
  exp_month: number;
  exp_year: number;
  funding: string;
  last4: string;
  fingerprint: string;
}

export interface ACHMethod {
  account_holder_type: string;
  account_type: string;
  bank_name: string;
  fingerprint: string;
  last4: string;
  payment_method_id: string;
  payment_method_type: 'ACH';
  routing_number: string;
}

const merchant = 'AWR';

function isError(payload: IntentPaymentResponse): payload is IError {
  return !!(payload as IError).error;
}

function fixedPriceInCents(amount: string | number): number {
  return Number((Number(amount) * 100).toFixed(0));
}

export class Stripe {
  payment = (window as any).PaymentHubWidget;

  paymentInstance;

  makeGeneralPayment(paymentPayload: PaymentPayload) {
    return new Promise<any>((res, rej) => {
      const payload = {
        customer: paymentPayload.customer,
        mode: PaymentMode.GENERAL_PAYMENT,
        amountInCents: fixedPriceInCents(paymentPayload.amount),
        mountNode: paymentPayload.mountNode,
        paymentMethods: paymentPayload.paymentMethods,
        enrollments: paymentPayload.enrollments.map(p => ({
          referenceNumber: p.referenceNumber,
          price: fixedPriceInCents(p.amount),
        })),
        merchant,
        environment: environment.stripeEnv,
        disabledFeatures: {
          addNewPaymentMethodFromPaymentList: false,
          availablePaymentMethods: false,
          payButton: false,
          autoNavigateToCreateMethodInCaseOfNoMethods: false,
        },
        hideRemoveMethodBtn: true,
        onIntentsCreationCompleted: (result: IntentPaymentResponse[]) => res(this.stripePayloadChange(result)),
        onIntentCreationFailure: rej,
      };
      console.log({ stripe: payload });
      this.paymentInstance = this.payment.initialize(payload);
    });
  }

  makeOneTimePayment(paymentPayload: PaymentPayload) {
    return new Promise<any>((res, rej) => {
      const payload = {
        customer: paymentPayload.customer,
        mode: PaymentMode.ONE_TIME_PAYMENT,
        amountInCents: fixedPriceInCents(paymentPayload.amount),
        mountNode: paymentPayload.mountNode,
        paymentMethods: paymentPayload.paymentMethods,
        enrollments: paymentPayload.enrollments.map(p => ({
          referenceNumber: p.referenceNumber,
          price: fixedPriceInCents(p.amount),
        })),
        merchant,
        environment: environment.stripeEnv,
        disabledFeatures: {
          addNewPaymentMethodFromPaymentList: false,
          availablePaymentMethods: false,
          payButton: false,
          autoNavigateToCreateMethodInCaseOfNoMethods: false,
        },
        hideRemoveMethodBtn: true,
        onIntentsCreationCompleted: (result: IntentPaymentResponse[]) => res(this.stripePayloadChange(result)),
        onIntentCreationFailure: rej,
      };
      console.log({ stripe: payload });
      this.paymentInstance = this.payment.initialize(payload);
    });
  }

  updatePaymentMethod(paymentPayload: PaymentPayload) {
    return new Promise<any>((res, rej) => {
      const payload = {
        customer: paymentPayload.customer,
        mode: PaymentMode.UPDATE_PAYMENT,
        mountNode: paymentPayload.mountNode,
        paymentMethods: paymentPayload.paymentMethods,
        disabledPaymentMethodIds: paymentPayload.disabledPaymentMethodIds,
        disabledFeatures: {
          addNewPaymentMethodFromPaymentList: false,
          availablePaymentMethods: false,
          payButton: false,
          autoNavigateToCreateMethodInCaseOfNoMethods: false,
          addNewPaymentMethodFollowToTheForm: true,
        },
        enrollments: paymentPayload.enrollments.map(p => ({
          referenceNumber: p.referenceNumber,
        })),
        merchant,
        environment: environment.stripeEnv,
        hideRemoveMethodBtn: true,
        onIntentsCreationCompleted: (result: IntentPaymentResponse[]) => res(this.stripePayloadChange(result)),
        onIntentCreationFailure: rej,
      };
      console.log({ stripe: payload });
      this.paymentInstance = this.payment.initialize(payload);
    });
  }

  stripePayloadChange(results: IntentPaymentResponse[]) {
    console.log({ stripeResult: results });
    return results.map(result => (isError(result) ? result : this.stripeToEts(result)));
  }

  stripeToEts(result: PaymentResponse) {
    const mapped = {
      customer_token: result.intent.payment_method_id,
      instrument_id: result.intent.stripe_customer_id,
      etsAccountName: result.intent.payment_intent_id,
      upfrontPaymentToken: result.intent.payment_intent_id,
      fingerprint: result.method.fingerprint,
      referenceNumber: result.referenceNumber,
    };
    if (result.method.payment_method_type === 'Credit Card') {
      return {
        ...mapped,
        instrument_type: 'CC',
        instrument_last_four: result.method.last4,
        instrument_expiration_date: `${result.method.exp_month.toString().padStart(2, '0')}${result.method.exp_year % 100}`,
        CardType: result.method.brand,
      };
    }
    return {
      ...mapped,
      instrument_type: 'ACH',
      instrument_last_four: result.method.last4,
      instrument_routing_last_four: result.method.routing_number,
      bankAccountType: result.method.bank_name,
    };
  }

  destroy() {
    if (this.paymentInstance) {
      this.paymentInstance.destroy();
    }
  }
}
