import { Injectable } from '@angular/core';
import file from '../../secret.json';

@Injectable()
export class AppSecrets {
  private secrets: Object = null;

  /**
   * Use to get the data found in the second file (secrets file)
   */
  public getSecrets() {
    this.load();
    return this.secrets;
  }

  /**
   * This method:
   *   b) Loads "config.js" to get all secrets variables best on environment
   */
  public load() {
    this.secrets = file;
  }
}
