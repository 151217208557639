<div class="modal-dialog custom-error-alert modal-content modal-background">
  <div class="modal-header modal-custom-header error-modal-custom-header" [ngClass]="alertType ? alertType : 'info-type'">
    <div class="info-background mx-auto"></div>
    <button type="button" class="close pull-right set-font" (click)="close()">
      <i class="fa fa-times close-icon" aria-hidden="true"></i>
    </button>
    <!-- <h4 class="modal-title">
                <div *ngIf="alertIcon" class="{{alertIcon}} alert-icon"></div>
            <div class="pl-3">{{title}}</div>
        </h4> -->
  </div>
  <div class="modal-body">
    <!-- <h4 class="mb-3">CCPA Updates</h4>
        <p class="info-txt pt-4 mb-0">
            California Residents: In connection with the California Consumer Privacy Act, American Water Resources Insurance Services will collect personal information about you in order to </p>
        <ul class="info-txt pt-4 mb-0">
            <li>Process and manage your purchases, transactions, and payments with us.</li>
            <li>Provide customer service.</li>
            <li>Market AWRIS products to you.</li>
        </ul>
        <p class="info-txt pt-4 mb-0">Please click on the privacy policy link at the bottom of the page for more information about our privacy practices.<br> Thank you.</p> -->
    <p class="info-txt pt-4 mb-0">
      We care about your privacy. For details on what personal information we collect and for what purposes, and your privacy rights and how to
      exercise them, click on the privacy policy link at the bottom of the page or contact us at 1-844-599-4625.
    </p>
  </div>

  <div class="modal-footer justify-content-center">
    <button (click)="close()" class="btn submit-btn block full-width app-btn mt-0">OK</button>
  </div>
</div>
