import { Component, OnInit } from '@angular/core';
import { NguCarouselConfig } from '@ngu/carousel';
import { CrmProxyService } from '../../../core/services/crm-proxy.service';
import { GlobalVariables } from '../../../core/services/global-variables.service';
import { googleReviewConfig } from './google-review-config';

@Component({
  selector: 'hos-google-review',
  templateUrl: './google-review.component.html',
  styleUrls: ['./google-review.component.scss'],
})
export class GoogleReviewComponent implements OnInit {
  googleReviewCarousel: NguCarouselConfig;

  reviewsData = googleReviewConfig;

  googleReviewData: any;

  public stars: any[] = new Array(5);

  constructor(private crmProxyService: CrmProxyService, private globalVariables: GlobalVariables) {}

  ngOnInit() {
    this.googleReviewCarousel = {
      grid: {
        xs: 1,
        sm: 2,
        md: 3,
        lg: 4,
        all: 0,
      },
      slide: 4,
      speed: 1000,
      interval: {
        timing: 4000,
      },
      load: 2,
      custom: 'banner',
      touch: true,
      loop: false,
      point: {
        visible: false,
      },
      easing: 'ease-in',
    };
  }
}
