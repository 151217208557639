<section class="container mt-5 mb-5 userContainer">
  <div class="row justify-content-center">
    <div class="middle-box loginscreen animated fadeInDown gray-bg col-4">
      <form class="m-t" role="form" #changePasswordForm="ngForm" (ngSubmit)="onSubmit(changePasswordForm)" novalidate>
        <h4 class="mb-5 text-center">Change Password</h4>
        <div class="form-group">
          <label>Password</label>
          <input type="password" class="form-control" name="password" required type="password" ngModel #password="ngModel" />
          <small id="emailHelp" class="form-text text-danger" *ngIf="showError && password.pristene">Password is required.</small>
        </div>

        <div class="form-group">
          <label>Confirm Password</label>
          <input type="password" class="form-control" name="confirmPassword" required type="password" ngModel #confirmPassword="ngModel" />
          <small id="emailHelp" class="form-text text-danger" *ngIf="showError && confirmPassword.pristene">Password is required.</small>
        </div>
        <label *ngIf="showError && !errorMessage"> Password and confirm password should match. </label>

        <label *ngIf="showError && errorMessage"> {{ errorMessage }}. </label>

        <button type="submit" class="btn btn-primary block full-width m-b">Submit</button>
      </form>
    </div>
  </div>
</section>
