import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { SiteContentService } from '../../../core/services/siteContents';
import { environment } from '../../../../environments/environment';
import { SessionStorageService } from '../../../core/services/session-storage.service';
import { UtilsService } from '../../../core/services/utils.service';

@Component({
  selector: 'hos-how-it-works',
  templateUrl: './how-it-works.component.html',
  styleUrls: ['./how-it-works.component.scss'],
})
export class HowItWorksComponent implements OnInit {
  safetyStepsContent: any;

  baseURL: String;

  isbrowser: boolean = true;

  siteData: any;

  constructor(
    private siteContentService: SiteContentService,
    private sessionStorageService: SessionStorageService,
    private utilsService: UtilsService,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
    this.isbrowser = isPlatformBrowser(this.platformId);
  }

  ngOnInit() {
    this.utilsService.event.subscribe(data => {
      if (Object.keys(data).length !== 0) {
        this.loadContent();
      }
    });
    this.siteContentService.event.subscribe(() => {
      this.loadContent();
    });
  }

  loadContent() {
    this.utilsService.getPartnerDetails$().subscribe(
      data => {
        const contentData = data.body;
        this.siteData = contentData.site.homepage;
        this.safetyStepsContent = contentData.site.homepage.safetySteps;
        this.baseURL = `${environment.Image_API_Endpoint}${environment.Content_service_API_Endpoint}`;
      },
      () => {},
    );
  }

  imagePathBuilder(imgUrl) {
    return this.utilsService.buildImageUrl(`${this.baseURL}${imgUrl}`);
  }
}
