import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'hos-customer-notification',
  templateUrl: './customer-notification.component.html',
  styleUrls: ['./customer-notification.component.scss'],
})
export class CustomerNotificationComponent {
  @Input()
  data: any[];

  @Output()
  addressClick = new EventEmitter<string>();

  onAddressClick(customerNumber) {
    this.addressClick.emit(customerNumber);
  }
}
