import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { forEach, findIndex } from 'lodash-es';
import { SimpleModalService } from 'ngx-simple-modal';
import { SessionStorageService } from '../../../core/services/session-storage.service';
import { VeiwServiceAddressComponent } from '../../../core/component/veiw-service-address/veiw-service-address.component';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'hos-my-account-address',
  templateUrl: './my-account-address.component.html',
  styleUrls: ['./my-account-address.component.scss'],
})
export class MyAccountAddressComponent implements OnInit {
  @Input() pageType: string;

  @Output() selectAddress?: EventEmitter<any> = new EventEmitter<any>();

  @Output() allAddresses?: EventEmitter<any> = new EventEmitter<any>();

  userDetails: any;

  fixedHeader: Boolean;

  serviceAddresses: any;

  serviceAddressesOld: any;

  selectedAddress: any;

  urlAddressId: any;

  addressId: any;

  constructor(
    private userService: UserService,
    private simpleModalService: SimpleModalService,
    private location: Location,
    private route: ActivatedRoute,
    private sessionStorageService: SessionStorageService,
  ) {}

  ngOnInit() {
    this.userDetails = this.userService.getSignedInUser();
    this.urlAddressId = this.route.snapshot.queryParams.addressId;
    if (this.userDetails) {
      this.sessionStorageService.setItem('initialEmit', '1');
      this.getCustomerAddress();
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (window.scrollY > 375) {
      this.fixedHeader = true;
    } else {
      this.fixedHeader = false;
    }
  }

  getCustomerAddress() {
    this.userService.getAllCustomerAddresses(this.userDetails.user._id).subscribe(data => {
      this.serviceAddresses = data.body;
      forEach(this.serviceAddresses, value => {
        value.serviceAddress.city = value.serviceAddress.city ? this.allTitleCase(value.serviceAddress.city) : '';
        value.serviceAddress.streetName = value.serviceAddress.streetName ? this.allTitleCase(value.serviceAddress.streetName) : '';
        value.serviceAddress.streetNumber = value.serviceAddress.streetNumber ? this.allTitleCase(value.serviceAddress.streetNumber) : '';
      });
      this.serviceAddressesOld = data.body;
      if (this.serviceAddresses.length > 0) {
        let getSelectedAddressIndex = findIndex(this.serviceAddresses, {
          _id: this.urlAddressId,
        });
        if (getSelectedAddressIndex > -1) {
          this.changeAddress(getSelectedAddressIndex, this.serviceAddresses[getSelectedAddressIndex]);
          this.sendAllAddress(this.serviceAddresses);
        } else {
          this.addressId = this.sessionStorageService.getItem('addressId');
          if (this.addressId) {
            getSelectedAddressIndex = findIndex(this.serviceAddresses, {
              _id: this.addressId,
            });
            this.changeAddress(getSelectedAddressIndex, this.serviceAddresses[getSelectedAddressIndex]);
            this.sendAllAddress(this.serviceAddresses);
          } else {
            this.changeAddress(0, this.serviceAddresses[0]);
            this.sendAllAddress(this.serviceAddresses);
          }
        }
      }
    });
  }

  allTitleCase(inStr) {
    return inStr.replace(/\w\S*/g, tStr => tStr.charAt(0).toUpperCase() + tStr.substr(1).toLowerCase());
  }

  changeAddress(index, address) {
    this.sessionStorageService.setItem('addressId', address._id);
    this.selectedAddress = index;
    this.selectAddress.emit(address);
  }

  sendAllAddress(allAddress) {
    this.allAddresses.emit(allAddress);
  }

  seeAddressDetail(serviceAddress) {
    this.simpleModalService
      .addModal(
        VeiwServiceAddressComponent,
        {
          address: serviceAddress,
          userId: this.userDetails.user._id,
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: true,
        },
      )
      .subscribe((isConfirmed: any) => {
        if (!isConfirmed) {
          this.serviceAddresses = this.serviceAddressesOld;
        }
      });
  }
}
