/* eslint-disable no-underscore-dangle */
import { Component, OnInit, Input, PLATFORM_ID, Inject, OnDestroy } from '@angular/core';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { SimpleModalService } from 'ngx-simple-modal';
import { UserService } from '../../../user/services/user.service';
import { SessionStorageService } from '../../../core/services/session-storage.service';
import { ShoppingCart } from '../../../cart/model/cart.model';
import { CartService } from '../../../cart/services/cart.service';
import { SiteContentService } from '../../../core/services/siteContents';
import { UtilsService } from '../../../core/services/utils.service';
import { environment } from '../../../../environments/environment';
import { HttpService } from '../../../core/services/http';
import { GlobalVariables } from '../../../core/services/global-variables.service';
import { OrdersService } from '../../../payment-ets/services/orders.service';
import { CartQuickCheckoutService } from '../../../cart/services/cart-quick-checkout.service';

@Component({
  selector: 'hos-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss'],
})
export class NavigationBarComponent implements OnInit, OnDestroy {
  @Input() alertClass: boolean;

  showPage: Boolean = false;

  notificationUrl = `${environment.Notification_service_API_Endpoint}web/banner-notification/active`;

  loggedInUser: any;

  cartSubscription: any;

  public cart: Observable<ShoppingCart>;

  public itemCount: number;

  userObservabele: Observable<any>;

  userSubscription: any;

  siteContent: any;

  baseUrl: String;

  logoURL: String = 'img/logo/AW_Logo_02.svg';

  partnerLogo: any;

  isLoginOpen: Boolean = false;

  isActive: Boolean = false;

  notificationCount = 0; // notification-area

  notificationData: any;

  isBrowser: any;

  isLoginRoute: boolean = false;

  micrositeNames: any;

  notifyResult = [];

  notificationHeight = 300;

  alertCount = 0;

  serviceAddresses: any[];

  showPriceInfoContactNo: boolean = false;

  priceInfoContactNumber = '1.855.716.5487';

  enrollmentUpdatePage: Boolean = false;

  site: any;

  constructor(
    private router: Router,
    public simpleModalService: SimpleModalService,
    private utilsService: UtilsService,
    private sessionStorageService: SessionStorageService,
    private siteContentService: SiteContentService,
    public userService: UserService,
    private orderService: OrdersService,
    private http: HttpService,
    private cartService: CartService,
    private cartQuickCheckoutService: CartQuickCheckoutService,
    private globalVariables: GlobalVariables,
    private activatedRoute: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
    this.micrositeNames = this.sessionStorageService.getItem('micrositeName')
      ? this.sessionStorageService.getItem('micrositeName', true)
      : environment.micrositeNames;
    try {
      if (this.isBrowser) {
        if (!(location.hostname.indexOf(environment.API_Endpoint) !== -1) || !!sessionStorage.getItem('siteHeader')) {
          this.showPage = true;
        } else {
          this.showPage = false;
        }
      }
      // eslint-disable-next-line no-empty
    } catch (ex) {}
    this.baseUrl = `${environment.Image_API_Endpoint}${environment.Content_service_API_Endpoint}`;
    this.globalVariables.cartAmount.subscribe(data => {
      if (data) {
        this.itemCount = data.items.length;
      } else {
        this.itemCount = 0;
      }
    });
    this.globalVariables.isEnrollmentUpdatePage.subscribe(data => {
      if (data) {
        this.enrollmentUpdatePage = false;
      }
    });
    this.globalVariables.loggedInCustNotification.subscribe(data => {
      if (data) {
        this.http.get(`${this.notificationUrl}`).subscribe(
          response => {
            if (!this.loggedInUser) {
              response.body = response.body.filter(notification => !notification.loggedInUsers);
            }
            this.notificationData = response.body;
            this.notificationCount = response.body.length;
            if (this.notificationCount) {
              this.isActive = response.body[0].isActive;
              this.setHeight();
            }
          },
          () => {},
        );
      }
    });
    this.router.events.subscribe(val => {
      if (this.isBrowser) {
        if (val instanceof NavigationEnd) {
          if (val.url.startsWith('/user/login')) {
            this.isLoginRoute = true;
          } else {
            this.isLoginRoute = false;
          }
          if (window.location.pathname.includes('/user/price-info')) {
            this.showPriceInfoContactNo = true;
          } else {
            this.showPriceInfoContactNo = false;
          }
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.cartSubscription) {
      this.cartSubscription.unsubscribe();
    }
  }

  async ngOnInit() {
    this.utilsService.event.subscribe(data => {
      if (Object.keys(data).length !== 0) {
        this.loadContent();
      }
    });
    if (this.micrositeNames.length === 0) {
      const apiData = await this.getMicroSitesApi();
      this.micrositeNames = apiData.body;
      environment.micrositeNames = this.micrositeNames;
      this.sessionStorageService.setItem('micrositeName', JSON.stringify(this.micrositeNames));
    }
    if (this.isBrowser) {
      this.activatedRoute.queryParams.subscribe(queryparams => {
        this.loggedInUser = this.getUser();
        let urlOperation: any = window.location.pathname.split('/');
        // urlOperation = urlOperation.filter(item => !environment.micrositeNames.includes(item) && item !== "");
        urlOperation = urlOperation.filter(item => !this.micrositeNames.includes(item) && item !== '');
        if (urlOperation && urlOperation[0] === 'terms') {
          if (queryparams.otherProducts) {
            this.loadContent();
          } else {
            this.loadContent();
          }
        }
      });
      if (window.location.pathname.includes('enrollment-update-renewal') || window.location.pathname.includes('enrollment-update-reactivate')) {
        this.enrollmentUpdatePage = true;
      }
    }
    // check for token in localstorage if found set the value to sessionstorage and call getUser()
    const authHeader = this.sessionStorageService.getItemFromloclaStorage('authHeader');
    const refreshToken = this.sessionStorageService.getItemFromloclaStorage('refreshToken');
    if (authHeader && refreshToken && this.isBrowser) {
      this.sessionStorageService.setItem('authHeader', authHeader);
      this.sessionStorageService.setItem('refreshToken', refreshToken);
    }
    this.loggedInUser = this.getUser();
    if (this.loggedInUser) {
      this.cart = this.cartService.get();
    } else {
      this.cart = this.cartQuickCheckoutService.get();
    }
    this.userObservabele = this.userService.get();
    this.userSubscription = this.userObservabele.subscribe(userToken => {
      if (userToken) {
        const token = userToken;
        if (token) {
          this.loggedInUser = JSON.parse(atob(token.split(' ')[1].split('.')[1]));
        }
      } else {
        this.loggedInUser = null;
      }
    });
    this.cartSubscription = this.cart.subscribe(cart => {
      this.itemCount = cart.items.length;
    });

    this.siteContentService.event.subscribe(() => {
      this.loadContent();
    });

    if (this.isBrowser) {
      this.globalVariables.loadScriptsFunction.subscribe(() => {
        this.http.get(`${this.notificationUrl}`).subscribe(
          response => {
            // this.isActive = true; //
            // this.notificationCount = 1; //
            if (!this.loggedInUser) {
              response.body = response.body.filter(notification => !notification.loggedInUsers);
            }
            this.notificationData = response.body;
            this.notificationCount = response.body.length;
            if (this.notificationCount) {
              this.isActive = response.body[0].isActive; // if notificationCount is 0 then isActive is false
              this.setHeight();
            }
          },
          () => {},
        );
      });
      this.globalVariables.userLoggedIn.subscribe(isLogin => {
        if (isLogin) {
          this.loadCustomerAlert();
        } else {
          this.clearAlert();
        }
      });
      this.globalVariables.userLoggedIn.emit(true);
      this.setHeight();
    }

    this.globalVariables.removeProperty.subscribe(() => {
      this.itemCount = 0;
      this.sessionStorageService.deleteItem('cart');
    });
  }

  loadContent() {
    this.utilsService.getPartnerDetails$().subscribe(
      data => {
        this.partnerLogo = data.body.locationLogo && data.body.locationLogo.url ? `${this.baseUrl}${data.body.locationLogo.url}` : '';
        this.partnerLogo = this.utilsService.buildImageUrl(this.partnerLogo);
        this.sessionStorageService.setItem('partnerLogo', this.partnerLogo);
        if (!this.partnerLogo) {
          this.sessionStorageService.setItem('websitePartnerName', '');
        }
        this.siteContent = data.body.site.header;
        this.site = data.body;
        this.logoURL = !!this.siteContent.logo && !!this.siteContent.logo.url ? `${this.baseUrl}${this.siteContent.logo.url}` : '';
        this.logoURL = this.utilsService.buildImageUrl(this.logoURL);
        this.sessionStorageService.setItem('logoURL', this.utilsService.buildImageUrl(this.logoURL));
        this.globalVariables.territoryCustomerServiceNumber = this.siteContent.contactNumber;
      },
      () => {},
    );
  }

  openLoginWindow() {
    /* if (this.isLoginOpen) {
      this.simpleModalService.removeAll();
      this.isLoginOpen = false;
    }
    this.simpleModalService.addModal(LoginModalComponent, {
      title: 'Already have an account?',
      message: ''
    }, {
        wrapperClass: 'modal-main-class in',
        closeOnClickOutside: true
      })
      .subscribe((isConfirmed) => {
        // We get dialog result
        if (isConfirmed) {
          this.loggedInUser = this.getUser();
        } else if (isConfirmed === false) {
          this.loadForgotPassword();
        } else if (isConfirmed === undefined) {

        }
      });
    this.isLoginOpen = true; */
    let prevUrl;
    const referer = this.activatedRoute.snapshot.queryParams.referer || '';
    const hashValue: any = ![undefined, 'undefined', null, 'null', ''].includes(window.location.hash) ? window.location.hash : '';
    let urlOperation: any = window.location.pathname.split('/');
    // urlOperation = urlOperation.filter(item => !environment.micrositeNames.includes(item) && item !== "");
    urlOperation = urlOperation.filter(item => !this.micrositeNames.includes(item) && item !== '');
    prevUrl = `${urlOperation.join('/')}${window.location.search}`;

    const urlResult = environment.userRouteIncludes.filter(val => prevUrl.startsWith(val));
    if (urlResult.length > 0) {
      prevUrl = '';
    }

    // when user clicks on login after registeration
    let urlReferer = encodeURIComponent(prevUrl + hashValue);
    urlReferer = urlReferer === '' ? referer : urlReferer;
    this.router.navigate(['/user/login'], {
      queryParams: {
        referer: urlReferer,
      },
    });
  }

  getUser(): Object {
    if (this.isBrowser) {
      const token = this.sessionStorageService.getItem('authHeader');
      let user;
      if (token) {
        user = JSON.parse(atob(token.split(' ')[1].split('.')[1]));
      }
      return user;
    }
    return '';
  }

  logout() {
    const cartCheckoutData: any = this.sessionStorageService.getItem('cartCheckout', true);
    if (cartCheckoutData && cartCheckoutData.items.length > 0) {
      this.itemCount = cartCheckoutData.items.length;
    } else {
      this.itemCount = 0;
    }
    this.globalVariables.nonLoggedInProductListingDetails = {};
    this.globalVariables.nonLoggedInProductDetails = {};
    this.globalVariables.nonLoggedInRenewedOrderIds = [];
    this.globalVariables.myAccClicked = false;
    this.clearAlert();
    this.userService.signOut();
    this.loggedInUser = null;
    this.router.navigate(['']);
  }

  openSidemenu() {
    if (this.isLoginOpen) {
      this.simpleModalService.removeAll();
      this.isLoginOpen = false;
    }
    document.body.classList.add('sidebar-open');
    document.getElementById('box').classList.add('active');
    $('.accordionMenu').removeClass('show');
    document.getElementById('backdrop-sidemenu').style.display = 'block';
  }

  checkPopUp() {
    if (this.isLoginOpen) {
      this.simpleModalService.removeAll();
      this.isLoginOpen = false;
    }
    const userDetails = this.userService.getSignedInUser();
    if (userDetails) {
      this.router.navigateByUrl('/payment?section=cart');
    } else if (!userDetails) {
      if (this.globalVariables.showCheckoutPaymentReponsePage) {
        this.globalVariables.showCheckoutPaymentReponsePage = false;
        this.router.navigateByUrl('/user/checkout?section=cart');
        window.location.reload();
      } else {
        this.globalVariables.showCheckoutPaymentReponsePage = false;
        this.router.navigateByUrl('/user/checkout?section=cart');
      }
    }
  }

  checkNotification() {
    this.setHeight();
    if (this.notificationCount > 0 || this.alertCount > 0) {
      this.isActive = !this.isActive;
    }
  }

  redirectClickLogo() {
    const userDetails = this.userService.getSignedInUser();
    let urlOperation: any = window.location.pathname.split('?');
    // urlOperation = urlOperation.filter(item => !environment.micrositeNames.includes(item) && item !== "");
    urlOperation = urlOperation.filter(item => !this.micrositeNames.includes(item) && item !== '');
    if (!!userDetails && urlOperation.length > 0 && urlOperation[0].includes('user/myaccount')) {
      this.globalVariables.isRouteToMyAccount = true;
    }
  }

  public async getMicroSitesApi(): Promise<any> {
    return this.siteContentService.getMicrositeNames().toPromise();
  }

  clearAlert() {
    this.alertCount = 0;
    this.notifyResult = [];
    this.serviceAddresses = undefined;
  }

  async loadCustomerAlert() {
    const user = this.userService.getSignedInUser();

    if (!user) {
      return;
    }

    this.serviceAddresses = this.sessionStorageService.getItem('serviceaddress', true);

    if (!this.serviceAddresses) {
      const result = await this.userService.getAllCustomerAddresses(user.user._id).toPromise();
      this.serviceAddresses = result.body;
    }
    const addressMap = new Map<string, any>();

    this.serviceAddresses.forEach(address => {
      addressMap.set(address.customerNo, address.serviceAddress);
    });

    if (user.user._id) {
      this.orderService.customerNotification(user.user._id).subscribe(res => {
        const result: any[] = res.body.customerNumbers;
        if (result.length > 0) {
          this.isActive = true;
          this.setHeight();
          this.alertCount = result.length;
          this.notifyResult = result.map(p => ({
            customerNumber: p,
            address: addressMap.get(p),
          }));
        }
      });
    }
  }

  pageSwitch(customerNumber: string) {
    const selectedAddress = this.serviceAddresses.find(p => p.customerNo === customerNumber);
    this.sessionStorageService.setItem('location_code', selectedAddress.serviceAddress.locationCodeId);
    this.sessionStorageService.setItem('addressId', selectedAddress._id);
    this.sessionStorageService.setItem(
      'selectedAddress',
      JSON.stringify({
        rawData: '',
        zipcode: selectedAddress.serviceAddress.zipcode,
      }),
    );
    this.globalVariables.myAccClicked = true;
    this.router.navigate(['/user/myaccount']);
    this.globalVariables.myAccClickFromHeader.emit(true);
    this.isActive = false;
  }

  setHeight() {
    this.notificationHeight = window.innerHeight - 110;
  }

  gotoLink() {
    this.globalVariables.myAccClicked = true;
    this.router.navigate(['/user/myaccount']);
    this.globalVariables.myAccClickFromHeader.emit(true);
  }
}
