import { Component, OnInit, Input, OnChanges, Output, EventEmitter, Inject, Optional, PLATFORM_ID } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SimpleModalService } from 'ngx-simple-modal';
import { Location, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { LocationService } from '../../../../../home/services/location.service';
import { SessionStorageService } from '../../../../../core/services/session-storage.service';
import { environment } from '../../../../../../environments/environment';
import { GlobalVariables } from '../../../../../core/services/global-variables.service';
import { ProductCardSwitcherComponent } from '../product-card-switcher.component';

@Component({
  selector: 'hos-product-card-claim-page',
  templateUrl: './product-card-claim-page.component.html',
  styleUrls: ['./product-card-claim-page.component.scss'],
})
export class ProductCardClaimPageComponent implements OnInit, OnChanges {
  @Input() product: any;

  @Input() zipcode: any;

  @Input() selectedAddress: any;

  @Input() locationId: any;

  @Input() isUpgrade: Boolean;

  @Input() locationCode: any;

  @Input() refCheck?: any;

  @Output() loader = new EventEmitter();

  @Input() showOldPrice: boolean = false;

  @Input() products: any = {};

  butttonText: String = 'Learn More';

  baseUrl: String;

  baseUrlLoc: String;

  iSLocation: Boolean = false;

  userDetails: any;

  showLoader: Boolean = false;

  specialProduct: Array<String> = [];

  partnerLogo: String;

  logoURL: String;

  isbrowser = true;

  isserver = false;

  micrositeNames: any;

  constructor(
    private router: Router,
    private sessionStorageService: SessionStorageService,
    private locationService: LocationService,
    private simpleModalService: SimpleModalService,
    private route: ActivatedRoute,
    private globalVariables: GlobalVariables,
    private location: Location,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Optional() @Inject('serverUrl') protected origin: string,
    @Optional() @Inject('pathname') protected pathname: string,
    private productCardSwitcherComponent: ProductCardSwitcherComponent,
  ) {
    this.micrositeNames = this.sessionStorageService.getItem('micrositeName')
      ? JSON.parse(this.sessionStorageService.getItem('micrositeName'))
      : environment.micrositeNames;
    this.specialProduct = environment.special_product;
    this.partnerLogo = this.sessionStorageService.getItem('partnerLogo');
    this.logoURL = this.sessionStorageService.getItem('logoURL');
    this.isbrowser = isPlatformBrowser(platformId);
    this.isserver = isPlatformServer(platformId);
    // this.products = this.products; To Check
  }

  ngOnInit() {
    this.baseUrl = `${environment.Image_API_Endpoint}${environment.Product_service_API_Endpoint}`;
    this.baseUrlLoc = `${environment.Image_API_Endpoint}${environment.Location_service_API_Endpoint}`;
    if (this.isUpgrade) {
      this.butttonText = 'Upgrade';
    }
    if (this.locationId) {
      this.iSLocation = true;
    }
  }

  async ngOnChanges() {
    this.partnerLogo = this.sessionStorageService.getItem('partnerLogo');
    this.logoURL = this.sessionStorageService.getItem('logoURL');
    /* Add to cart logic on click of enroll now before login to add cart after login */
    const isRetainEnrollClicked = this.sessionStorageService.getItem('isRetainEnrollClicked');
    if (
      isRetainEnrollClicked !== 'true' &&
      this.product &&
      this.globalVariables.nonLoggedInProductListingDetails &&
      Object.keys(this.globalVariables.nonLoggedInProductListingDetails).length > 0
    ) {
      this.sessionStorageService.setItem('isRetainEnrollClicked', 'true');
      const urlOperation: any = window.location.pathname.split('/');
      if (
        urlOperation.length > 0 &&
        urlOperation[0] !== '' &&
        !this.micrositeNames.includes(urlOperation[0]) &&
        this.globalVariables.isPropNotExist
      ) {
        if (['products'].includes(urlOperation[1])) {
          this.globalVariables.isPropNotExist = false;
          const getMicrositeDetails = await this.getMicrositeContentApi(urlOperation[3]);
          if (getMicrositeDetails && getMicrositeDetails.body && getMicrositeDetails.body.site.siteName) {
            urlOperation.unshift(getMicrositeDetails.body.site.siteName);
            const redirectUrlOp: any = urlOperation.join('/');
            this.location.go(redirectUrlOp);
          }
        }
      }
      /* Start - To Check if cust# is null return to myaccount route */
      let isEmptyCustNo: boolean = false;
      const userDetails = JSON.parse(this.sessionStorageService.getItem('loginUserDetails'));
      this.showLoader = true;
      if (userDetails) {
        await this.getAllCustomerAddressesApi(userDetails);
      }
      if (userDetails && this.globalVariables.getAllCustomerAddresses) {
        const lastPropData = JSON.parse(
          JSON.stringify(this.globalVariables.getAllCustomerAddresses[this.globalVariables.getAllCustomerAddresses.length - 1]),
        );
        if (
          lastPropData &&
          lastPropData.serviceAddress.zipcode === this.route.snapshot.params.zipcode &&
          ['null', 'undefined', undefined, null, ''].includes(lastPropData.customerNo)
        ) {
          for (let i = 0; i <= 2; i++) {
            const lastIndexData = JSON.parse(
              JSON.stringify(this.globalVariables.getAllCustomerAddresses[this.globalVariables.getAllCustomerAddresses.length - 1]),
            );
            if (lastIndexData && ['null', 'undefined', undefined, null, ''].includes(lastIndexData.customerNo)) {
              if (i === 2) {
                isEmptyCustNo = true;
              } else {
                await new Promise(resolve => {
                  setTimeout(resolve, 2000);
                });
                await this.getAllCustomerAddressesApi(userDetails);
              }
            }
          }
        }
      }
      this.showLoader = false;
      if (isEmptyCustNo) {
        this.router.navigate(['/user/myaccount']);
        return;
      }
      /* End - To Check if cust# is null return to myaccount route */
      if (this.product.defaultLocationCodeId === this.globalVariables.nonLoggedInProductListingDetails.defaultLocationCodeId) {
        this.addProductToCart(this.globalVariables.nonLoggedInProductListingDetails);
      } else {
        this.globalVariables.nonLoggedInProductDetails = this.product;
        this.addProductToCart(this.globalVariables.nonLoggedInProductDetails);
      }
    }
  }

  learnMoreHandler(product: any) {
    if (product.isUpgrade) {
      this.simpleModalService.removeAll();
    }
    window.scrollTo(0, 0);
    const data = this.locationService.getZipcodeAndLocation();
    const existingData = JSON.parse(this.sessionStorageService.getItem('selectedAddress'));
    if (!existingData && this.zipcode !== undefined) {
      const selectedAddress = {
        formatted_address: this.zipcode,
        rawData: '',
        zipcode: this.zipcode,
      };
      this.sessionStorageService.setItem('selectedAddress', JSON.stringify(selectedAddress));
    }
    if (data) {
      this.zipcode = this.zipcode ? this.zipcode : data.zipcode;
    }
    this.locationId = this.locationId ? this.locationId : data.location_code;
    const productContactType = environment.types.filter(item => item.id === this.product.contactType)[0].type.toLowerCase();
    if (this.zipcode && productContactType && this.locationId) {
      /** HWR - 2696 Start - get locationCode based on location id  */
      this.productCardSwitcherComponent.getLocationsContentAPI(this.locationId).subscribe(res => {
        const locationCodeData = res.body.locationCode;
        this.router.navigate([
          `/product/${this.zipcode}/${locationCodeData.toLowerCase()}/${productContactType}/${product.webProgramName
            .toString()
            .split(' ')
            .join('-')
            .split(',')
            .join('')
            .toLowerCase()}`,
        ]);
      });
    } else {
      this.router.navigate(['/404']);
    }
    /** HWR - 2696 Start - get locationCode based on location id  */
  }

  getImage(imageName) {
    return this.productCardSwitcherComponent.getImage(imageName);
  }

  public addProductToCart(product: any): void {
    this.productCardSwitcherComponent.addProductToCart(product, this);
  }

  getProductCardImage(product) {
    return this.productCardSwitcherComponent.getProductCardImage(product, this);
  }

  goToPaymentOptions() {}

  public getMicrositeContentApi(data): Promise<any> {
    return this.productCardSwitcherComponent.getMicrositeContentApi(data);
  }

  public getAllCustomerAddressesApi(userDetails): Promise<any> {
    return this.productCardSwitcherComponent.getAllCustomerAddressesApi(userDetails.user._id);
  }
}
