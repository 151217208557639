<div class="modal-dialog modal-content modal-background">
  <div class="modal-body">
    <div class="col-sm-12 header-content">
      <button type="button" class="close pull-right set-font" (click)="close()">
        <i class="fa fa-times close-icon" aria-hidden="true"></i>&nbsp;&nbsp;CLOSE
      </button>
      <div class="header-text">Residential</div>
    </div>
    <div class="service-area sub-section col-12 textBlack">
      <div class="address-header-text">Service Address</div>
      <p class="address-body mb-2 text-ellipsis skiptranslate">
        {{ address.serviceAddress.streetNumber }} {{ address.serviceAddress.streetName }}, <br />
        {{ address.serviceAddress.city }}, {{ address.serviceAddress.state }}, {{ address.serviceAddress.zipcode }}
      </p>
    </div>
    <div class="billing-area">
      <div class="sub-section mb-0">
        <div class="address-header-text textBlack">Billing Address</div>
        <div [hidden]="!!editAddress">
          <p class="address-body mb-2 text-ellipsis textBlack">
            <span class="skiptranslate"
              >{{ address.billingAddress.streetNumber }} {{ address.billingAddress.streetName }}, {{ address.billingAddress.city }},
              {{ address.billingAddress.state }}, {{ address.billingAddress.zipcode }}</span
            >
            <button
              class="float-right address-body btn pull-right btn-link non-href-links btn-sm primary-link paddT0 desk-edit-btn"
              (click)="editAddress = true"
            >
              Edit Address
            </button>
          </p>
          <button class="address-body btn btn-link non-href-links primary-link paddT0 mobile-edit-btn" (click)="editAddress = true">
            Edit Address
          </button>
        </div>
        <form #addressForm="ngForm" novalidate [hidden]="!editAddress">
          <div class="row p-0 pt-2">
            <div class="form-group col-12 col-md-6">
              <label class="input-label" for="selectType">Address</label>
              <input
                type="text"
                autocomplete="none"
                class="form-control round-form"
                [ngClass]="{ 'is-invalid': isClicked && !streetNumber.valid }"
                name="streetNumber"
                maxlength="100"
                pattern="{{ streetNumberValidation }}"
                required
                [(ngModel)]="address.billingAddress.streetNumber"
                #streetNumber="ngModel"
              />
              <small id="emailHelp" class="form-text text-danger" *ngIf="isClicked && address.billingAddress.streetNumber === ''"
                >Address is required.</small
              >
              <small
                id="emailHelp"
                class="form-text text-danger"
                *ngIf="isClicked && !streetNumber.valid && address.billingAddress.streetNumber !== ''"
                ><span *ngIf="streetNumber.errors?.required">Address is required.</span
                ><span *ngIf="streetNumber.errors?.pattern">Address is invalid.</span></small
              >
            </div>
            <div class="form-group col-12 col-md-6">
              <label class="input-label" for="selectType">Apt., Unit, PO Box, etc.</label>
              <input
                type="text"
                autocomplete="none"
                class="form-control round-form"
                [ngClass]="{ 'is-invalid': isClicked && !streetName.valid }"
                name="streetName"
                maxlength="100"
                [(ngModel)]="address.billingAddress.streetName"
                #streetName="ngModel"
              />
              <small id="emailHelp" class="form-text text-danger" *ngIf="isClicked && !streetName.valid">Apt., Unit, PO Box, etc. is required.</small>
            </div>

            <div class="form-group col-12 col-md-6">
              <label class="input-label" for="selectType">City</label>
              <input
                type="text"
                class="form-control round-form"
                [ngClass]="{ 'is-invalid': isClicked && !city.valid }"
                name="city"
                required
                [(ngModel)]="address.billingAddress.city"
                #city="ngModel"
              />
              <small id="emailHelp" class="form-text text-danger" *ngIf="isClicked && !city.valid">City address is required.</small>
            </div>
            <div class="form-group col-12 col-md-6">
              <label class="input-label" for="selectType">Zipcode</label>
              <hos-zipcode [isClicked]="isClicked" [address]="address.billingAddress" (notify)="onZipcodeUpdated($event)"></hos-zipcode>
            </div>
            <div class="form-group col-12 col-md-6">
              <label class="input-label" for="selectType">State</label>
              <ng-select
                [items]="states"
                bindLabel="value"
                bindValue="value"
                (change)="stateChangeHandler($event)"
                [(ngModel)]="address.billingAddress.state"
                [disabled]="true"
                name="state"
                #state="ngModel"
                class="skiptranslate state-arrow"
                required
              >
              </ng-select>
            </div>
          </div>
          <hos-success-alert life="2000"></hos-success-alert>
        </form>
      </div>

      <hr class="horizontal-divider" [hidden]="!editAddress" />
      <div class="row alignBtmContent col-12 no-gutters" [hidden]="!editAddress">
        <div class="col-6">
          <a class="primary-v-link" (click)="closeModal()"> CANCEL</a>
        </div>
        <div class="col-6 alignRight">
          <button type="submit" (click)="saveAddress(addressForm)" class="btn primary-btn app-btn block full-width saveBtn">Save</button>
        </div>
      </div>
    </div>
  </div>
</div>
<div [ngClass]="{ loading: !!showLoader }"></div>
