<section class="activate-account-section">
  <div class="row animated fadeInDown no-gutters">
    <div class="head-title-text col-12 col-md-12" *ngIf="authenticationSuccess">
      <div class="alert alert-success final-success-alert text-center" role="alert">
        <div class="page-wrap row">
          <img src="/img/payment/Message-Ico-Success.svg" class="col-4 col-md-3" />
          <span class="text-msg col-8 col-md-9 text-left">Your email address has been verified!</span>
        </div>
      </div>
    </div>
    <div class="head-title-text col-12 col-md-12" *ngIf="errorMessage">
      <div class="alert alert-danger final-error-alert text-center mb-0" role="alert">
        <div class="page-wrap row">
          <img src="/img/payment/Message-Ico-Error.svg" class="col-4 col-md-3" />
          <span class="text-msg col-8 col-md-9 text-left">{{ errorMessage }}</span>
        </div>
      </div>
    </div>

    <div class="head-title-text row page-wrap col-10 mt-3 justify-content-center" *ngIf="authenticationSuccess">
      <hos-things-you-can-do [page]="'activate'"></hos-things-you-can-do>
    </div>
  </div>
</section>
