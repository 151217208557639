import { Component, OnInit, Input, EventEmitter, Output, OnChanges, ElementRef, ViewChild, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'hos-claims-question-helper-component',
  templateUrl: './claims-question.component.html',
  styleUrls: ['./claims-question.component.scss'],
})
export class ClaimsQuestionComponent implements OnInit, OnChanges, AfterViewChecked {
  @Input() productInfo: any;

  @Input() effectiveFromDt: any;

  @Input() question: any;

  @Output() claimAnswer = new EventEmitter();

  @Input() previousQuestion: any;

  minDate: any; // Min date for date fields

  today: any; // Date Validation for Claims

  radioMultiClrConfig: any = [];

  radioVal: any;

  checkboxConfig: any = [];

  @ViewChild('YesContent') YesContent: ElementRef;

  @ViewChild('NoContent') NoContent: ElementRef;

  switchWidth: any;

  constructor(private route: ActivatedRoute, private httpClient: HttpClient, private datePipe: DatePipe, private cdRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.today = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.minDate = this.datePipe.transform(new Date(this.effectiveFromDt.split('T')[0]), 'yyyy-MM-dd');
  }

  ngOnChanges() {
    if (this.question.type === 'radio-multi' && this.question.answer === null) {
      this.radioMultiClrConfig = this.question;
      this.radioMultiClrConfig.colorConfigStyle = [];
      this.question.inputArray.forEach(() => {
        this.radioMultiClrConfig.colorConfigStyle.push('#FFFFFF');
      });
    }
    if (this.question.type === 'checkbox' && this.question.answer === null) {
      this.checkboxConfig = [];
    }
    if (this.question.type === 'dropdown' && this.question.answer === null) {
      this.question.answer = 'Choose an option';
    }
    if (this.question.type === 'radio-slider' && this.question.answer === null) {
      // eslint-disable-next-line prefer-destructuring
      this.radioVal = this.question.inputArray[1]; /* Setting by default first value */
      // eslint-disable-next-line prefer-destructuring
      this.question.answer = this.question.inputArray[0];
      this.optionValueChange(this.question.answer);
    }
  }

  ngAfterViewChecked() {
    if (this.question.type === 'radio-slider') {
      const yesWidth = this.YesContent.nativeElement.offsetWidth;
      const noWidth = this.NoContent.nativeElement.offsetWidth;
      if (yesWidth > noWidth) {
        this.switchWidth = yesWidth * 2;
      } else {
        this.switchWidth = noWidth * 2;
      }
      this.cdRef.detectChanges();
    }
  }

  optionValueChange(Event: any) {
    let inputValue = Event;
    if (this.question.type === 'checkbox') {
      if (inputValue.length === 0) {
        inputValue = '';
      }
      this.claimAnswer.emit(inputValue);
    } else {
      this.question.answer = this.question.answer.replace(/^\s+/g, '');
      this.claimAnswer.emit(inputValue.replace(/^\s+/g, ''));
    }
  }

  changeBackgroundClr(indexVal) {
    this.radioMultiClrConfig.colorConfigStyle.forEach((data, index) => {
      if (index === indexVal) {
        this.radioMultiClrConfig.colorConfigStyle[index] = '#C1E6FF';
      } else {
        this.radioMultiClrConfig.colorConfigStyle[index] = '#FFFFFF';
      }
    });
  }

  radioButtonChange(Event: any) {
    this.radioVal = Event;
    if (Event === true) {
      // eslint-disable-next-line prefer-destructuring
      this.question.answer = this.question.inputArray[0];
    } else if (Event === false) {
      // eslint-disable-next-line prefer-destructuring
      this.question.answer = this.question.inputArray[1];
    }
    this.optionValueChange(this.question.answer);
  }

  checkboxValueChange(Event, value) {
    if (value.checked) {
      this.checkboxConfig.push(value.title);
    } else {
      const index = this.checkboxConfig.indexOf(value.title);
      if (index > -1) {
        this.checkboxConfig.splice(index, 1);
      }
    }
    this.optionValueChange(this.checkboxConfig);
  }

  /* Allow only number for contact number  */
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
