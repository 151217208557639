<section *ngIf="safetyStepsContent" class="inverse how-it-works parallax content-section" id="how-it-works">
  <article class="intro-txt row col-12 page-wrap mt-3">
    <div class="col-xs-12 col-sm-12 col-md-12 headingStyle">
      <!-- <span class="blackText">{{safetyStepsContent?.safetyStepsTitle}}</span> -->
      <h2 class="how-it-works-title">How it works</h2>
      <div class="how-it-works-desc">
        <span>{{ safetyStepsContent?.safetyStepsSubHeading }}</span>
      </div>
    </div>
  </article>
  <div class="page-wrap row main-head-how-it-works" *ngIf="isbrowser">
    <ng-container *ngFor="let stepsItems of safetyStepsContent?.safetyStepsItems; let ind = index; let even = even; let odd = odd; let last = last">
      <div class="feature-box mob-feature-box col-12 col-sm-12 col-md-3" appReveal>
        <article class="feature-box-inner">
          <div class="show-number-block">
            <span>{{ ind + 1 }}</span>
          </div>
          <figure class="feature-box-thumb">
            <img [src]="imagePathBuilder(stepsItems.icon.url)" alt="{{ stepsItems.title }}" />
          </figure>
          <div class="show-number-block-mob">
            <span>{{ ind + 1 }}</span>
          </div>
          <div class="feature-box-text">
            <!--  <h3>{{stepsItems?.title}}</h3> -->
            <p [innerHTML]="stepsItems?.desc"></p>
          </div>
        </article>
      </div>
      <div *ngIf="safetyStepsContent" class="desk-arrow-cls desktop-only-view" [style.top.px]="even ? '30' : odd ? '50' : '30'">
        <img *ngIf="!last && even" src="../../../../img//how-it-works/desktop-step1-2.png" alt="How It Works" />
        <img *ngIf="!last && odd" src="../../../../img//how-it-works/desktop-step2-3.png" alt="How It Works" />
      </div>
      <div class="tab-arrow-cls tab-only-view" [style.top.px]="even ? '20' : odd ? '50' : '20'">
        <img *ngIf="!last && even" src="../../../../img//how-it-works/tab-step1-2.png" alt="How It Works" />
        <img *ngIf="!last && odd" src="../../../../img//how-it-works/tab-step2-3.png" alt="How It Works" />
      </div>
    </ng-container>
  </div>
  <div class="row m-0 mt-15 display-flex-center-cls">
    <a
      [routerLink]="safetyStepsContent.getStartedButtonLink"
      class="btn enroll-now skiptranslate"
      [title]="safetyStepsContent.getStartedButtonLabel"
      >{{ safetyStepsContent?.getStartedButtonLabel }}</a
    >
  </div>
</section>
