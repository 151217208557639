<!-- Covered repair section -->
<section class="content-section covered-repair-section" id="covered-repair-section">
  <div class="bg-covered-repair-cls">
    <article class="row full-width-mob">
      <div class="col-12">
        <h2 class="section-head-title font-unbounce-title">{{ coveredRepairsHeading ? coveredRepairsHeading : 'Covered Repairs' }}</h2>
      </div>
    </article>
    <article class="row mobile-full-width justify-content-center covered-repairs">
      <div class="col-12 max-width-tab-100" [ngClass]="{ 'scroll-li list-max-height': coveredRepairsData?.length > 10 }">
        <!--  <ul class="covered-repairs-list">
                <li *ngFor="let coveredRepair of coveredRepairsData">{{coveredRepair.value}}</li>
            </ul> -->
        <ol
          *ngIf="bulletTypeCoveredRepairs"
          class="covered-repairs-list MB-0"
          [ngStyle]="{ 'list-style-type': bulletListStyleType === 'initial' || bulletListStyleType === 'checkmark' ? 'none' : bulletListStyleType }"
          [ngClass]="{
            initial: bulletListStyleType === 'initial',
            checkmark: bulletListStyleType === 'checkmark',
            columncls: coveredRepairsData?.length > 5
          }"
          [style.padding-left.px]="bulletListStyleType === 'initial' || bulletListStyleType === 'none' ? '0' : '20'"
        >
          <li *ngFor="let coveredRepair of coveredRepairsData">{{ coveredRepair.value }}</li>
        </ol>
      </div>
    </article>
  </div>
</section>
