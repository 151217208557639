export const programs = {
  'Cooling System Repair': {
    class: 'cooling',
    color: '#06B3E4',
  },
  'Interior Electric Line Protection': {
    class: 'Interior_Electric',
    color: '#225E92',
  },
  'Exterior Electric Line Protection': {
    class: 'Exterior_electric',
    color: '#F3C317',
  },
  'Water Heater Repair & Replacement': {
    class: 'heater',
    color: '#EB7924',
  },
  'Heating System Repair': {
    class: 'heating',
    color: '#EB7924',
  },
  'In Home Plumbing Emergency Program': {
    class: 'plumbing',
    color: '#AD073D',
  },
  'Sewer Line Protection': {
    class: 'sewer',
    color: '#77C652',
  },
  'Power Surge Protection': {
    class: 'surge',
    color: '#B41044',
  },
  'Water Line Protection': {
    class: 'WLPP',
    color: '#0099CC',
  },
  'Leak Detection': {
    class: 'LDDP',
    color: '#0c4f85',
  },
  others: {
    class: 'other',
    color: '#0099CC',
  },
  'Gas Line Protection Program': {
    class: 'cooling',
    color: '#EB7924',
  },
  'Surge Protection Program': {
    class: 'SPP',
    color: '#F3C317',
  }, // HWR-2500:color code added
  'In-Home Plumbing Clog Protection Program': {
    class: 'IHCP',
    color: '#06B3E4',
  } /** In-Home-Clog-Removal Program */,
  'Energy Essentials Protection Program': {
    class: 'EEPP',
    color: '#06B3E4',
  } /** Energy Essentials Protection Program */,
  'Water Line and Sewer Line Protection Program': {
    class: 'WLSL',
    color: '#F3C317',
  } /** Water Line and Sewer Line Protection Program */,
  'Water Line Sewer Line and In Home Plumbing Emergency Program': {
    class: 'WLSLIH',
    color: '#F3C317',
  } /** Water Line Sewer Line and In Home Plumbing Emergency Program */,
  'In Home Plumbing Plus Program': {
    class: 'plumbing',
    color: '#AD073D',
  },
  'Cooling Repair - Secondary System': {
    class: 'cooling',
    color: '#06B3E4',
  } /** Cooling Repair - Secondary System */,
  'Cooling Repair - Primary System': {
    class: 'cooling',
    color: '#06B3E4',
  } /** Cooling Repair - Primary System */,
  'Preferred Cooling Protection - Primary System': {
    class: 'cooling',
    color: '#06B3E4',
  } /** Preferred Cooling Protection - Primary System */,
  'Preferred Cooling Protection - Secondary System': {
    class: 'cooling',
    color: '#06B3E4',
  } /** Preferred Cooling Protection - Secondary System */,
};
