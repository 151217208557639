<div class="modal-dialog custom-error-alert modal-content modal-background">
  <div class="modal-header modal-custom-header error-modal-custom-header info-type">
    <div class="info-background mx-auto"></div>
  </div>
  <div class="modal-body">
    <h4 class="mb-3">Verify Service Address</h4>
    <div class="info-txt address-block pt-1 mb-0">
      <p>
        <b>Suggested address</b><br />
        {{ suggestedAddress?.delivery_line_1 }}, {{ suggestedAddress.delivery_line_2 ? suggestedAddress.delivery_line_2 + ', ' : ''
        }}{{ suggestedAddress?.components?.city_name }}, {{ suggestedAddress?.components?.state_abbreviation }}<br />
        {{ suggestedAddress?.components?.zipcode }}
      </p>
      <p>
        <b>Original address</b><br />
        {{ inputAddress.streetNumber }}, {{ inputAddress.streetName ? inputAddress.streetName + ', ' : '' }}{{ inputAddress.city }},
        {{ inputAddress.state }} <br />
        {{ inputAddress.zipcode }}
      </p>
    </div>
  </div>
  <div class="modal-footer">
    <div class="row m-0 footer-block">
      <h4 class="mb-3 col-12 px-0 py-2 flex-none-ie">Use suggested address?</h4>
      <div class="action-block col-12 row mx-0 p-0 flex-none-ie">
        <button (click)="confirmHandler()" class="btn submit-btn block full-width app-btn mt-0">YES</button>
        <button (click)="close()" class="btn print-btn block full-width app-btn mt-0">NO</button>
      </div>
    </div>
  </div>
</div>
