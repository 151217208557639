<form *ngIf="strOTP" class="m-t form-horizontal" #changePasswordForm="ngForm" novalidate>
  <section class="content-section content-block reset-password-section">
    <div class="row page-wrap">
      <div class="head-title-text col-12 col-md-12">
        <h3 class="mb-5 bold">Reset Password <img src="img/reset_password/Reset_icon.svg" alt="Reset Icon" /></h3>
      </div>
      <hos-success-alert></hos-success-alert>

      <div class="row col-12 justify-content-center" *ngIf="isPassChanged">
        <div class="col-8 no-gutters">
          <p>Please Log in using your new password.</p>
          <hr />
          <p>Update Information</p>
          <p>Date/ Hour: {{ today | date }}</p>
          <hr />
          <p>
            If you need further assistance
            <a class="cta-btn partner-button" [routerLink]="contactLink">Contact Us</a>
          </p>
        </div>
      </div>

      <div class="col-12 col-md-4" *ngIf="!isPassChanged">Please enter and confirm a new password to complete the request of resetting it.</div>
      <div class="col-12 col-md-4" [hidden]="isPassChanged">
        <div class="form-group">
          <label for="selectType">* New Password</label>
          <input
            [type]="isView ? 'text' : 'password'"
            class="form-control round-form"
            (blur)="blurHandler(password)"
            [ngClass]="{ 'is-invalid': (isClicked || password.isBlur) && !password.valid }"
            name="password"
            [pattern]="password_regex"
            required
            [(ngModel)]="passwordModel"
            #password="ngModel"
          />
          <span (click)="isView = !isView" class="fa {{ isView ? 'fa-eye' : 'fa-eye-slash' }} pwd-toggle"></span>
        </div>
        <div class="form-group">
          <label for="selectType">* Confirm New Password</label>
          <input
            [type]="isConfirmView ? 'text' : 'password'"
            class="form-control round-form"
            (blur)="blurHandler(confirmPassword)"
            [ngClass]="{ 'is-invalid': (isClicked || confirmPassword.isBlur) && !confirmPassword.valid }"
            [pattern]="password_regex"
            name="confirmPassword"
            required
            [(ngModel)]="confirmPasswordModel"
            #confirmPassword="ngModel"
          />
          <span (click)="isConfirmView = !isConfirmView" class="fa {{ isConfirmView ? 'fa-eye' : 'fa-eye-slash' }} pwd-toggle"></span>
        </div>
      </div>
      <div class="col-12 col-md-4" [hidden]="isPassChanged">
        <hos-password-strength-meter [password]="password.value" [confirmPassword]="confirmPassword.value"></hos-password-strength-meter>
      </div>

      <label *ngIf="showError && errorMessage"> {{ errorMessage }}. </label>
    </div>
    <section class="content-section reset-password-button-section" *ngIf="!isPassChanged">
      <div class="row page-wrap justify-content-end">
        <button type="submit" class="btn btn-primary block full-width app-btn m-b" (click)="onSubmit(changePasswordForm)">Reset Password ></button>
      </div>
    </section>
  </section>
</form>
