import { NgModule } from '@angular/core';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NguCarouselModule } from '@ngu/carousel';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CartRoutingModule } from './cart-routing';
import { CoreModule } from '../core/index';
import { ProductModule } from '../product/product.module';
// Components
import { CartComponent } from './component/cart/cart.component';
import { BundleModalComponent } from './component/bundle-modal/bundle-modal.component';

// Services
import { CartService } from './services/cart.service';

@NgModule({
  declarations: [CartComponent, BundleModalComponent],
  exports: [CartComponent, BundleModalComponent],
  imports: [
    CommonModule,
    NguCarouselModule,
    ReactiveFormsModule,
    FormsModule,
    CartRoutingModule,
    CoreModule,
    ProductModule,
    BsDropdownModule.forRoot(),
  ],
  providers: [CartService],
})
export class CartModule {}
