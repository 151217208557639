import { Component, OnInit, Input, OnChanges, AfterViewInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { PaginationInstance } from 'ngx-pagination';
import { find } from 'lodash-es';
import { SimpleModalService } from 'ngx-simple-modal';
import { ProductService } from '../../../../product/services/product.service';
import { ProductReviewFormComponent } from '../../product-review-form/product-review-form.component';
import { environment } from '../../../../../environments/environment';
import { AlertService } from '../../../../core/services/alert.service';
import { UserService } from '../../../../user/services/user.service';
import { GlobalVariables } from '../../../../core/services/global-variables.service';
import { SessionStorageService } from '../../../../core/services/session-storage.service';

declare let grecaptcha;

@Component({
  selector: 'hos-all-product-reviews',
  templateUrl: './all-product-reviews.component.html',
  styleUrls: ['all-product-reviews.component.scss'],
})
export class AllProductReviewsComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() productDetail: any;

  userDetails: any;

  numbers: number[];

  reviews: any = [];

  reviewLimitPerPage: any;

  page: Number;

  totalReviews: any;

  startPage: Number = 1;

  showLoader = false;

  math = Math;

  /* pagination realated */
  public pagingConfig: PaginationInstance = {
    id: 'all-reviews-list-paging',
    itemsPerPage: 4,
    currentPage: 1,
    totalItems: 0,
  };

  allReviewsDtlForm: UntypedFormGroup;

  selectedAddressId: any;

  selectedAddress: any;

  userAddress: any;

  customerName: any;

  copyOfRating: any;

  captchaResponse: String;

  sortReviewsBy: any[] = [
    {
      id: 1,
      name: 'Newest to Oldest',
    },
    {
      id: 2,
      name: 'Oldest to Newest',
      query: 'oldest=1',
    },
    {
      id: 3,
      name: 'High to Low ratings',
      query: 'rating=-1',
    },
    {
      id: 4,
      name: 'Low to High ratings',
      query: 'rating=1',
    },
  ];

  selectedSortReview: number = 0;

  sortQuery: any;

  constructor(
    private allReviewsform: UntypedFormBuilder,
    private productService: ProductService,
    private simpleModalService: SimpleModalService,
    private alertService: AlertService,
    private userService: UserService,
    public globalVariables: GlobalVariables,
    private sessionStorageService: SessionStorageService,
  ) {
    this.userDetails = this.userService.getSignedInUser();
    this.reviewLimitPerPage = environment.review_limit_per_page;
    this.numbers = Array(5)
      .fill(1)
      .map((x, i) => i + 1);
    this.selectedAddressId = this.sessionStorageService.getItem('addressId');
    this.userDetails = this.userService.getSignedInUser();
    let firstName: any = '';
    let lastName: any = '';
    if (this.userDetails && this.userDetails.user) {
      this.userService.getAllCustomerAddresses(this.userDetails.user._id).subscribe(data => {
        const serviceAddresses = data.body;
        if (this.selectedAddressId) {
          this.selectedAddress = find(serviceAddresses, {
            _id: this.selectedAddressId,
          });
          this.userAddress = this.selectedAddress.serviceAddress;
        } else {
          this.selectedAddressId = serviceAddresses[0]._id;
          this.selectedAddress = find(serviceAddresses, {
            _id: this.selectedAddressId,
          });
          this.userAddress = this.selectedAddress.serviceAddress;
        }
      });
      if (this.userDetails.user.firstName) {
        firstName = this.toTitleCase(this.userDetails.user.firstName);
      }
      if (this.userDetails.user.lastName) {
        lastName = this.toTitleCase(this.userDetails.user.lastName);
      }
      this.customerName = `${firstName} ${lastName}`;
    }
    this.createForm();
  }

  ngOnChanges() {
    if (this.productDetail) {
      if (this.productDetail.coverageType && this.productDetail.coverageType === 'UNLIMITED') {
        this.productDetail.programId = this.productDetail.reviewProgramId;
        this.productDetail.programName = this.productDetail.reviewProgramName;
      }
      this.getProductsReviews(this.startPage);
    }
  }

  /* Get product review based om programid API Call - AP - 1-june */
  getProductsReviews(pageNumber, sectionId?: any, sortQuery?: any) {
    this.showLoader = true;
    this.productService.getProductReviews(this.productDetail.programId, pageNumber, sortQuery).subscribe(
      data => {
        if (data.status === 200) {
          this.page = pageNumber;
          const resp = data.body;
          this.reviews = resp.result;
          this.reviews.foreach(review => {
            if (review && review.customerName) {
              const fullName = review.customerName.split(' ').map(name => {
                if (name) {
                  return name.charAt(0).toUpperCase() + name.slice(1);
                }
                return undefined;
              });
              if (fullName.length === 2) {
                review.customerName = `${fullName[0]} ${fullName[1].charAt(0)}`;
              }
            }
          });
          this.totalReviews = resp.total;
          this.pagingConfig.itemsPerPage = resp.limit;
          this.pagingConfig.totalItems = resp.total;
          if (sectionId) {
            this.scrollToSection(sectionId);
          }
        }
        this.showLoader = false;
      },
      () => {
        this.totalReviews = 0;
        this.reviews = [];
        this.showLoader = false;
      },
    );
    this.pagingConfig.currentPage = pageNumber;
  }

  /* Dialog Modal Open Review Form AP- 2-June */
  openCreateForm() {
    const programName = this.productDetail.programName
      ? this.productDetail.programName
      : this.productDetail.serviceName
      ? this.productDetail.serviceName
      : ' ';
    this.simpleModalService
      .addModal(
        ProductReviewFormComponent,
        {
          programName,
          programId: this.productDetail.programId,
          reviews: this.reviews,
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: true,
        },
      )
      .subscribe(result => {
        if (result === true && result !== undefined) {
          this.alertService.success('Review Has Been Submitted Successfully!');
        } else if (result !== undefined) {
          this.alertService.error(`${result}`);
        }
      });
  }

  ngOnInit() {
    $('html, body').animate(
      {
        scrollTop: $('#all-reviews-section').offset().top - 170,
      },
      1000,
    );
    document.body.scrollTop -= 200;
  }

  backToProduct() {
    this.globalVariables.isAllReviewsClicked = false;
  }

  toTitleCase(str) {
    return str.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
  }

  createForm() {
    if (this.userDetails && this.userDetails.user) {
      this.allReviewsDtlForm = this.allReviewsform.group({
        title: [''],
        description: [''],
        rating: ['5', Validators.required],
        captcha: [null, Validators.required],
      });
    } else {
      this.allReviewsDtlForm = this.allReviewsform.group({
        firstName: ['', [Validators.required, Validators.pattern('^[a-zA-Z]+')]],
        lastName: ['', [Validators.required, Validators.pattern('^[a-zA-Z]+')]],
        email: ['', [Validators.required, Validators.pattern('^[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}')]],
        title: [''],
        description: [''],
        rating: ['5', Validators.required],
        captcha: [null, Validators.required],
      });
    }
  }

  ratingSelect(val) {
    this.allReviewsDtlForm.value.rating = val;
    this.copyOfRating = this.allReviewsDtlForm.value.rating;
  }

  onMouseover(val) {
    this.copyOfRating = JSON.parse(JSON.stringify(this.allReviewsDtlForm.value.rating));
    this.allReviewsDtlForm.value.rating = val;
  }

  onMouseout() {
    this.allReviewsDtlForm.value.rating = this.copyOfRating;
  }

  trimSpaceFromBothEnd() {
    if (this.allReviewsDtlForm.value.title) {
      this.allReviewsDtlForm.controls.title.setValue(this.allReviewsDtlForm.value.title.trim());
    }
    if (this.allReviewsDtlForm.value.description) {
      this.allReviewsDtlForm.controls.description.setValue(this.allReviewsDtlForm.value.description.trim());
    }
  }

  modalClose() {
    this.createForm();
    grecaptcha.reset();
  }

  ngAfterViewInit() {
    /* ReCaptcha render For Desktop & Mobile AP */
    this.recaptchaFunction();
  }

  /* ReCaptcha verifyCallback function AP */
  verifyCallback(data: String) {
    this.allReviewsDtlForm.patchValue({
      captcha: data,
    });
    this.captchaResponse = data;
  }

  onRecaptchaExpired(data: String) {
    this.allReviewsDtlForm.patchValue({
      captcha: data,
    });
    this.captchaResponse = data;
  }

  /* Create Review API Call */
  createReview() {
    this.showLoader = true;
    const programName = this.productDetail.programName
      ? this.productDetail.programName
      : this.productDetail.serviceName
      ? this.productDetail.serviceName
      : ' ';
    let payload;
    if (this.userDetails && this.userDetails.user) {
      let customerNumber: any;
      if ([undefined, '', 'false', null, false].includes(this.globalVariables.selected_Customer_number)) {
        customerNumber = this.selectedAddress.customerNo;
      } else {
        customerNumber = this.globalVariables.selected_Customer_number;
      }
      if (customerNumber) {
        payload = {
          customerId: this.userDetails.user._id,
          customer_number: this.globalVariables.selected_Customer_number || this.selectedAddress.customerNo,
          programId: this.productDetail.programId,
          programName,
          productComment: this.allReviewsDtlForm.value.description,
          productRating: this.allReviewsDtlForm.value.rating,
          customerName: this.customerName,
          customerEmail: this.userDetails.user.email || '',
          reviewStatus: '0',
          reviewTitle: this.allReviewsDtlForm.value.title,
          custAddress: `${this.userAddress.city}, ${this.userAddress.state}`,
          customerGuid: this.globalVariables.selected_customerGuid || this.selectedAddress.customerGuid,
        };
      } else {
        payload = {};
      }
    } else {
      this.customerName = `${this.allReviewsDtlForm.value.firstName} ${this.allReviewsDtlForm.value.lastName}`;
      payload = {
        programId: this.productDetail.programId,
        programName,
        productComment: this.allReviewsDtlForm.value.description,
        productRating: this.allReviewsDtlForm.value.rating,
        customerName: this.customerName,
        customerEmail: this.allReviewsDtlForm.value.email,
        reviewStatus: '0',
        reviewTitle: this.allReviewsDtlForm.value.title,
      };
    }
    if (payload) {
      this.productService.createReview(payload).subscribe(
        () => {
          this.showLoader = false;
          this.alertService.success('Review Has Been Submitted Successfully!');
          this.createForm();
          grecaptcha.reset();
        },
        error => {
          const errorData = error.error;
          this.showLoader = false;
          const result = errorData.responseDesc;
          this.alertService.error(`${result}`);
          this.createForm();
          grecaptcha.reset();
        },
      );
    } else {
      this.alertService.error('Something went wrong. Please try again later');
    }
  }
  /* Create Review API Call */

  recaptchaFunction() {
    grecaptcha.render(document.getElementById('captcha'), {
      sitekey: environment.Captcha.siteKey,
      theme: 'light',
      callback: this.verifyCallback.bind(this),
      'expired-callback': this.onRecaptchaExpired.bind(this),
    });
    grecaptcha.render(document.getElementById('captchaMob'), {
      sitekey: environment.Captcha.siteKey,
      size: 'compact',
      theme: 'light',
      callback: this.verifyCallback.bind(this),
      'expired-callback': this.onRecaptchaExpired.bind(this),
    });
  }

  scrollToSection(sectionId: string) {
    $('html, body').animate(
      {
        scrollTop: $(`#${sectionId}`).offset().top - 200,
      },
      1000,
    );
    document.body.scrollTop -= 200;
  }

  sortReviews(reviewId) {
    if (reviewId === 0) {
      this.getProductsReviews(this.startPage);
    } else {
      this.sortQuery = this.sortReviewsBy[reviewId].query;
      this.getProductsReviews(this.startPage, '', this.sortQuery);
    }
  }
}
