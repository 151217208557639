import { PLATFORM_ID, Inject, Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { SessionStorageService } from '../services/session-storage.service';
import { UserService } from '../../user/services/user.service';

@Injectable()
export class CanDeactivateViaAuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private sessionStorageService: SessionStorageService,
    private userService: UserService,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {}

  canActivate() {
    if (isPlatformBrowser(this.platformId)) {
      if (this.sessionStorageService.getItem('authHeader')) {
        // logged in so return true
        /* HWR-2524 Changes AP 12 March 2020 adding signout and register route */
        this.userService.signOut();
        this.router.navigate(['/user/register']);
        return false;
      }
    }
    // not logged in so redirect to login page with the return url
    return true;
  }
}
