<section class="payment-response-box content-section shaded-white">
  <div class="head-title-text" *ngIf="claimResult_error === 'false'">
    <div class="alert alert-success final-success-alert text-center head-padding" role="alert">
      <span class="text-msg">
        <img src="../../../../img/payment/Message-Ico-Success.svg" />
        <p *ngIf="!claimName" class="paragraphStyle">We are processing your Claim Request</p>
        <p *ngIf="claimName" class="claimNameParaStyle">
          <span class="spanTextStyle"
            >Your claim has been submitted successfully and is currently under review. If there is any additional information needed you will receive
            a phone call from our office within 4 business hours.</span
          >
          <span class="spanTextStyle">Your Claim Name is {{ claimName }}.</span>
        </p>
      </span>
    </div>
    <article class="page-wrap mt-4 mb-3">
      <div class="col-12 heading-text mt-2">
        Protecting your home from unexpected repairs may be easier than you think! We offer a number of great ways for homeowners to reduce the
        hardships and stresses associated with maintaining a home. Please see other available protection programs to you below.
      </div>
    </article>
    <section class="page-wrap mb-5">
      <div class="container-fluid row p-0 m-0">
        <div class="col-12 col-md-12 p-0">
          <div class="recommended-services">
            <div class="col-12 col-md-12 title-search-block">SERVICES RECOMMENDATIONS</div>
            <div class="col-12 col-md-12 recommended-property-block">
              <hos-claim-recommended-services
                [customerNum]="product?.address?.customerNo || product?.crmCustomerNumber"
                [locationId]="product?.address?.serviceAddress?.locationCodeId"
                [selectedAddress]="product?.address"
                [zipcode]="product?.address?.serviceAddress?.zipcode"
                [services]="enrolledProducts"
              >
              </hos-claim-recommended-services>
            </div>
            <div class="col-12 col-md-12 see-more-options">
              <a (click)="goToProducts()">See more options</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <div class="head-title-text" *ngIf="claimResult_error === 'true'">
    <div class="alert alert-danger final-error-alert text-center head-padding" role="alert">
      <span class="text-msg">
        <img src="../../../../img/payment/Message-Ico-Error.svg" />
        <p class="paragraphStyle">Issue ineligible under program. Please contact AWR if you have any questions on your coverage.</p>
      </span>
    </div>
  </div>
</section>
