<ng-container *ngIf="isbrowser">
  <article class="card text-center cardsAlign" [ngClass]="{ 'card-height': showOldPrice }" *ngIf="product" ngsReveal>
    <div class="d-flex align-items-center justify-content-center program-name ga-track-claim-success-learn-more" (click)="learnMoreHandler(product)">
      {{ product?.webProgramName }}
    </div>
    <img
      [src]="getProductCardImage(product)"
      [alt]="product?.webProgramName"
      class="card-img ga-track-claim-success-learn-more"
      (click)="learnMoreHandler(product)"
    />
    <div class="card-body">
      <!-- first row start -->
      <div class="row m-0">
        <div class="col-9 p-0">
          <h3 *ngIf="specialProduct.includes(product?.programId); then leak; else notLeak"></h3>
          <ng-template #leak>
            <button (click)="goToPaymentOptions()" class="btn btn-link float-left non-href-links btn-sm primary-link change-link leak-program">
              Click here for payment options
            </button>
          </ng-template>
          <ng-template #notLeak>
            <div class="price-cls float-left">
              <span class="notranslate">${{ product?.monthlyPrice | slice : 0 : -2 }}</span>
              <span class="small-txt">Per Month</span>
            </div>
          </ng-template>
        </div>
        <div class="col-3 p-0">
          <img class="product-icon" [src]="getImage(baseUrl + product?.programIconUrl)" [alt]="product?.webProgramName" />
        </div>
        <div class="col-12 p-0">
          <div class="old-price-container" *ngIf="showOldPrice">
            <div class="d-flex flex-column">
              <span class="old-price" *ngIf="product && product.oldPrice && product.oldPrice !== ''"
                >${{ product.oldPrice | slice : 0 : -2 }} Per Month</span
              >
              <div class="rounded old-price-text p-1" *ngIf="product && product.oldPriceText && product.oldPriceText !== ''">
                <span class="text-danger">{{ product.oldPriceText }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- first row end -->
      <div class="row m-0 mt-15">
        <button
          *ngIf="!specialProduct.includes(product?.programId) && !refCheck"
          (click)="addProductToCart(product)"
          class="btn w-100 enroll-now skiptranslate ga-track-claim-success-enroll-now"
          [ngClass]="{ 'commercial-enroll-now': product?.contactType === 3 }"
          title="ENROLL NOW"
        >
          ENROLL NOW
        </button>
        <button
          *ngIf="specialProduct.includes(product?.programId)"
          (click)="addProductToCart(product)"
          class="btn w-100 enroll-now skiptranslate ga-track-claim-success-enroll-now"
          [ngClass]="{ 'commercial-enroll-now': product?.contactType === 3 }"
          title="BUY NOW"
        >
          BUY NOW
        </button>
        <button
          *ngIf="refCheck"
          (click)="addProductToCart(product)"
          class="btn w-100 enroll-now skiptranslate ga-track-claim-success-enroll-now"
          [ngClass]="{ 'commercial-enroll-now': product?.contactType === 3 }"
          title="ADD UPGRADE TO CART"
        >
          ADD UPGRADE TO CART
        </button>
      </div>
      <!-- last row start -->
      <div class="commercial-row" *ngIf="product.contactType === 3">
        <div class="commercial-product">COMMERCIAL</div>
      </div>
      <!-- last row end -->
    </div>
  </article>
</ng-container>
