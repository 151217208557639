<ng-container *ngIf="isbrowser">
  <article class="card text-center cardsAlign" [ngClass]="{ 'card-height': showOldPrice }" *ngIf="product">
    <div>
      <div class="description-heading-text-content" [innerHTML]="description"></div>
      <div class="product-card-details-footer col-12">
        <button (click)="showEnterZipPopup(product)" class="btn w-100 enroll-now skiptranslate" title="Get Started">Get Started</button>
      </div>
    </div>
  </article>
</ng-container>
