import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from '../../core/services/http';
import { environment } from '../../../environments/environment';

@Injectable()
export class ResyncEnrollmentService {
  constructor(private router: Router, private http: HttpService) {}

  resyncEnrollment(custNo, custId) {
    const resyncURL = `${environment.Customer_service_API_Endpoint}customer/customerno/${custNo}/${custId}`;
    return this.http
      .put(resyncURL, '')
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }
}
