import { Component, OnInit, HostListener } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SiteContentService } from '../../../core/services/siteContents';
import { SessionStorageService } from '../../../core/services/session-storage.service';
import { UtilsService } from '../../../core/services/utils.service';

@Component({
  selector: 'hos-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
})
export class TopBarComponent implements OnInit {
  isScrolled = false;

  currPos = 0;

  siteContent: any;

  showPriceInfoContactNo: boolean = false;

  priceInfoContactNumber = '1.855.716.5487';

  constructor(
    private router: Router,
    private sessionStorageService: SessionStorageService,
    private siteContentService: SiteContentService,
    private utilsService: UtilsService,
  ) {
    this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd && window) {
        if (window.location.pathname.includes('/user/price-info')) {
          this.showPriceInfoContactNo = true;
        } else {
          this.showPriceInfoContactNo = false;
        }
      }
    });
  }

  ngOnInit() {
    this.siteContentService.event.subscribe(() => {
      this.loadContent();
    });
    this.utilsService.event.subscribe(data => {
      if (Object.keys(data).length !== 0) {
        this.loadContent();
      }
    });
  }

  @HostListener('window:scroll', ['$event']) updateHeader(evt) {
    this.currPos = (window.pageYOffset || evt.target.scrollTop) - (evt.target.clientTop || 0);
    if (this.currPos > 60) {
      this.isScrolled = true;
    } else {
      this.isScrolled = false;
    }
  }

  loadContent() {
    this.utilsService.getPartnerDetails$().subscribe(
      data => {
        this.siteContent = data.body.site.header;
      },
      () => {},
    );
  }
}
