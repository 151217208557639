<div id="box" class="sidebar-menu">
  <div class="head-box">
    <img [src]="logoURL || 'img/logo/AW_logo_White.svg'" [alt]="site?.site.siteEmailTitle" />
    <div class="close-menu">
      <span class="closeBtn" (click)="closeSideBar()"></span>
    </div>
  </div>
  <div class="login-box" *ngIf="!this.userService.getSignedInUser() && !isLoginRoute">
    <button
      type="button"
      (click)="openLoginWindow(); closeSideBar()"
      class="btn btn-sm nav-btn notranslate"
      angulartics2On="click"
      angularticsAction="click"
      angularticsLabel="login_register"
      angularticsCategory="Header-Login"
    >
      Login / register
    </button>
  </div>
  <!-- <div class="enroll-box">
        <div class="search-bar search-panel">
            <form action="result.html" class="search">
                <div class="search-field">
                    <input type="text" class="search-input" id="search" name="search" placeholder="Search" />
                    <button class="search-btn">
                        <i class="icon-search"></i>
                    </button>
                </div>
            </form>
        </div>
    </div> -->
  <div *ngIf="this.userService.getSignedInUser()" id="items" class="link-box mobile-logged">
    <div class="profile-details">
      <div>
        <span class="welcome-text text-capitalize">
          <b>{{ this.userService.getSignedInUser().user.firstName }} </b>
        </span>
        <div class="pull-right font-medium primary-link" (click)="closeSideBar(); logout()" href="#">LOGOUT</div>
      </div>
      <div class="row col-sm-12 no-gutters p-0">
        <a routerLink="/user/profile" class="col-6 col-md-8 primary-link" (click)="closeSideBar()">My Profile</a>
        <a routerLink="/user/myaccount" class="col-6 col-md-4 text-right primary-link" (click)="closeSideBar(); gotoLink()">My Services</a>
      </div>
      <div class="row col-sm-12 no-gutters p-0">
        <a routerLink="/user/add-property" class="col-6 col-md-4 primary-link" (click)="closeSideBar()">Add Property</a>
      </div>
    </div>
  </div>

  <div class="enroll-box hidden">
    <button
      class="btn btn-primary btn-sm enroll-btn hidden"
      type="button"
      routerLink="/product/5a2faaf8f3255e001c1b516e/details"
      (click)="closeSideBar()"
    >
      Enroll Now
    </button>
  </div>
  <div id="items" class="link-box">
    <div class="mobile-logged panel-group" *ngIf="this.userService.getSignedInUser()">
      <div class="panel panel-default">
        <div class="panel-heading">
          <h4 class="panel-title row">
            <div class="col-8 primary-link cart-link" (click)="redirectToCart(); closeSideBar()">
              <span class="fa fa-shopping-cart mr-1"></span>My Cart
              <span *ngIf="itemCount; skiptranslate">({{ itemCount }})</span>
            </div>
          </h4>
        </div>
      </div>
    </div>
    <div class="panel-group" id="accordionMenu" role="tablist" aria-multiselectable="true">
      <div
        class="panel panel-default"
        *ngFor="let category of categories; let i = index"
        [hidden]="category.label === 'Privacy Policy'"
        [ngClass]="{ 'no-submenu': !!category.sub && category.sub?.length < 1 }"
      >
        <div class="panel-heading" role="tab" id="heading{{ i }}">
          <h4 class="panel-title row">
            <a *ngIf="category.label === 'Services'" class="col-8" role="button" (click)="closeSideBar(); redirectToDetails()">
              {{ category.label }}
            </a>
            <a *ngIf="category.label === 'Terms and Conditions'" class="col-8" role="button" (click)="closeSideBar()" [routerLink]="['/terms']">
              Terms & Conditions
            </a>
            <a
              *ngIf="category.label !== 'Services' && category.label !== 'Terms and Conditions' && category.label !== 'Blogs'"
              class="col-8"
              role="button"
              [routerLink]="'page/' + category.label?.toLowerCase().replace(' ', '-') + '/' + category.entryId"
              (click)="closeSideBar()"
            >
              {{ category.label }}
            </a>
            <a *ngIf="category.label === 'Blogs'" class="col-8" role="button" (click)="closeSideBar()" [routerLink]="['/blog']">
              {{ category.label }}
            </a>
            <a
              *ngIf="category.label !== 'Terms and Conditions' && !!category.sub?.length"
              data-toggle="collapse"
              data-parent="#accordionMenu"
              href="#collapse{{ i }}"
              class="col-3 ml-auto pr-1 accordmenucls"
              aria-expanded="false"
              attr.aria-controls="collapse{{ i }}"
            ></a>
          </h4>
        </div>
        <div id="collapse{{ i }}" class="panel-collapse collapse in accordionMenu" role="tabpanel" attr.aria-labelledby="heading{{ i }}">
          <div class="panel-body" *ngFor="let link of category.sub">
            <ul class="nav">
              <li *ngIf="category.label !== 'Services'" (click)="closeSideBar()">
                <a *ngIf="link.entryId !== 'support'" [routerLink]="'page/' + category.label?.toLowerCase().replace(' ', '-') + '/' + link.entryId">{{
                  link.label
                }}</a>
                <a *ngIf="link.entryId === 'support'" [routerLink]="['/support/partner-request']">{{ link.label }}</a>
              </li>
              <li *ngIf="category.label === 'Services'" (click)="goToCategory(link); closeSideBar()">
                <div class="cursor underline">{{ link.categoryName }}</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
