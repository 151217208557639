import { Component, OnInit, Input, Output, EventEmitter, Inject, Optional, PLATFORM_ID } from '@angular/core';
import { SimpleModalService } from 'ngx-simple-modal';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../../environments/environment';
import { UtilsService } from '../../../core/services/utils.service';
import { GetStartedModalComponent } from '../get-started-modal/get-started-modal.component';

@Component({
  selector: 'hos-product-card-home-page',
  templateUrl: './product-card-home-page.component.html',
  styleUrls: ['./product-card-home-page.component.scss'],
})
export class ProductCardHomePageComponent implements OnInit {
  @Input() product: any;

  @Output() loader = new EventEmitter();

  @Input() isCategory: any;

  @Input() mkcCategories: any;

  baseUrl: String;

  productData: any;

  showLoader: Boolean = false;

  isbrowser = true;

  isserver = false;

  description: any = '';

  constructor(
    private utilsService: UtilsService,
    private simpleModalService: SimpleModalService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private sanitizer: DomSanitizer,
    @Optional() @Inject('serverUrl') protected origin: string,
    @Optional() @Inject('pathname') protected pathname: string,
  ) {
    this.isbrowser = isPlatformBrowser(platformId);
    this.isserver = isPlatformServer(platformId);
  }

  ngOnInit() {
    this.baseUrl = `${environment.Image_API_Endpoint}${environment.Product_service_API_Endpoint}`;
    this.product.homeProgramCardContent = this.product.homeProgramCardContent
      .replace('{{price_monthly}}', `$${Number(this.product.monthlyPrice).toFixed(2)}`)
      .replace('{price_monthly}', `$${Number(this.product.monthlyPrice).toFixed(2)}`)
      .replace('price_monthly', `$${Number(this.product.monthlyPrice).toFixed(2)}`);
    if (this.isbrowser) {
      // Create a temporary element to parse the HTML content
      const tempElement = document.createElement('div');
      tempElement.innerHTML = this.product.homeProgramCardContent;

      // Retrieve all image elements inside the temporary element
      const imageElements = tempElement.querySelectorAll('img');

      // Iterate through the image elements and set the loading attribute to "lazy"
      imageElements.forEach(element => {
        element.setAttribute('loading', 'lazy');
      });

      // Get the modified HTML content with the updated loading attributes
      const modifiedHTML = tempElement.innerHTML;
      this.description = this.sanitizer.bypassSecurityTrustHtml(modifiedHTML || '');
    }
  }

  getImage(imageName) {
    return this.utilsService.buildImageUrl(imageName);
  }

  getProductCardImage(product) {
    let imageUrl;
    if (this.isCategory) {
      imageUrl =
        product.bannerImages.descriptionMobileImages && product.bannerImages.descriptionMobileImages.length > 0
          ? product.bannerImages.descriptionMobileImages[0].startsWith('programDetailsBgImage/Mobile')
            ? `../../../../img/${product.bannerImages.descriptionMobileImages[0]}`
            : this.baseUrl + product.bannerImages.descriptionMobileImages[0]
          : `../../../../img/${product.bannerImages.descriptionMobileImages[0]}`;
    } else {
      imageUrl = product.programBannerIconUrl
        ? this.baseUrl + product.programBannerIconUrl
        : product.descriptionMobileImages && product.descriptionMobileImages.length > 0
        ? product.descriptionMobileImages[0].startsWith('programDetailsBgImage/Mobile')
          ? `../../../../img/${product.descriptionMobileImages[0]}`
          : this.baseUrl + product.descriptionMobileImages[0]
        : `../../../../img/${product.descriptionMobileImages[0]}`;
    }
    return this.utilsService.buildImageUrl(imageUrl);
  }

  showEnterZipPopup(service) {
    this.simpleModalService
      .addModal(
        GetStartedModalComponent,
        {
          productData: service,
          mkcCategories: this.mkcCategories,
          isCategory: this.isCategory,
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: false,
        },
      )
      .subscribe(() => {});
  }
}
