<div class="modal-dialog modal-custom-header modal-content">
  <div class="modal-header">
    <button type="button" class="close" (click)="close()">&times;</button>
    <span class="close-text" (click)="close()">CLOSE</span>
    <!-- <h4 class="modal-title">{{title || 'Confirm'}}</h4> -->
  </div>
  <div class="modal-body">
    <div class="card-body-box">
      <div class="card-body row no-gutters">
        <div class="col-2">
          <img
            class="cart-image"
            [src]="data?.details?.programIconUrl ? getImage(productServiceBaseUrl + data?.details?.programIconUrl) : ''"
            [alt]="data?.details?.webProgramName"
          />
        </div>
        <div class="col-10">
          <div class="row desktop-only">
            <div class="col-md-8 product-details">
              <div class="product-currently-en">Program Currently Enrolling</div>
              <h6 class="product-name">
                <strong>{{ data?.details?.webProgramName }}</strong>
              </h6>
              <div class="product-desc">
                {{ billingFrequency }} Billing Frequency Selected
                <br />
                Auto Renews Annually
              </div>
            </div>
            <div class="col-md-4">
              <div class="row">
                <!-- <button type="button" class="btn btn-link non-href-links btn-sm navigate-link col-9" (click)="removeProduct()">
                                REMOVE
                            </button> -->
                <br />
                <div class="auto-renew">
                  <label> <input type="checkbox" [(ngModel)]="isChecked" (change)="checkValue(isChecked ? true : false)" /> Do Not Auto Renew </label>
                </div>
              </div>
            </div>
          </div>
          <div class="mobile-only">
            <div class="col-md-8 product-details">
              <div class="product-currently-en">Program Currently Enrolling</div>
              <p class="product-name">
                <strong>{{ data?.details?.webProgramName }}</strong>
              </p>
              <div class="product-desc">
                {{ billingFrequency }} Billing Frequency Selected
                <br />
                Auto Renews Annually
              </div>
            </div>
            <div class="col-md-8">
              <div class="">
                <!-- <button type="button" class="btn btn-link non-href-links btn-sm navigate-link col-9" (click)="removeProduct()">
                                    REMOVE
                                </button> -->
                <br />
                <div class="auto-renew">
                  <label> <input type="checkbox" [(ngModel)]="isChecked" (change)="checkValue(isChecked ? true : false)" /> Do Not Auto Renew </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <p>{{ description || '' }}</p>
  </div>
</div>
