import { Component } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';

export interface ErrorModalModel {
  title: string;
  message: string;
  confirmationMsg?: string;
  showButton?: string;
  alertIcon?: string;
  alertType?: string;
  confirmBtnId?: string;
}

@Component({
  selector: 'hos-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss'],
})
export class ErrorModalComponent extends SimpleModalComponent<ErrorModalModel, Boolean> implements ErrorModalModel {
  title: string;

  message: string;

  showButton: string;

  confirmationMsg?: string;

  alertIcon?: string;

  alertType?: string;

  confirmBtnId?: string;

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() {
    super();
  }

  confirmHandler() {
    this.close();
  }
}
