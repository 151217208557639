import { Component } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';

export interface AlertConfirmModalModel {
  title: string;
  message: string;
  alertIcon?: string;
  cancelText?: string;
  confirmText: string;
  alertType?: string;
  navigate?: string;
  confirmBtnId?: string;
  cancelBtnId?: string;
  reloadPage?: boolean;
}

@Component({
  selector: 'hos-alert-confirm-modal',
  templateUrl: './alert-confirm-modal.component.html',
  styleUrls: ['./alert-confirm-modal.component.scss'],
})
export class AlertConfirmModalComponent extends SimpleModalComponent<AlertConfirmModalModel, any> implements AlertConfirmModalModel {
  title: string;

  message: string;

  alertIcon: string;

  cancelText: string;

  confirmText: string;

  alertType: string;

  navigate: string;

  confirmBtnId: string;

  cancelBtnId: string;

  reloadPage: boolean;

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() {
    super();
  }

  confirmHandler() {
    this.result = 'confirmed';
    this.close();
  }
}
