import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SimpleModalComponent, SimpleModalService } from 'ngx-simple-modal';
import { DatePipe } from '@angular/common';
import { SessionStorageService } from '../../../core/services/session-storage.service';
import { CartService } from '../../../cart/services/cart.service';
import { SeoService } from '../../../core/services/seo.service';
import { ProductService } from '../../../product/services/product.service';
import { ErrorModalComponent } from '../../../core/component/error-modal/error-modal.component';
import { UtilsService } from '../../../core/services/utils.service';
import { SiteContentService } from '../../../core/services/siteContents';
import { WindowScrolling } from '../../../core/services/window-scrolling.service';

export interface ServiceModal {
  service: any;
  enrollServices: any;
  upsell?: any;
}
@Component({
  selector: 'hos-app-update-service-detail',
  templateUrl: './update-service-detail.component.html',
  styleUrls: ['./update-service-detail.component.scss'],
})
export class UpdateServiceDetailComponent extends SimpleModalComponent<ServiceModal, Boolean> implements ServiceModal, OnInit, OnDestroy {
  service: any;

  enrollServices: any;

  upgradeProducts: any;

  myServicePage: Boolean = true;

  zipcode: any;

  locData: any;

  locationId: any;

  showLoader: Boolean = false;

  upsell: any;

  allStates: any;

  locationCodeData: any;

  shareIconIndexArray: any = [];

  setOldPriceFlag: boolean = false;

  constructor(
    private router: Router,
    public activatedRoute: ActivatedRoute,
    private cartService: CartService,
    private simpleModalService: SimpleModalService,
    private seoService: SeoService,
    private utilsService: UtilsService,
    private sessionStorageService: SessionStorageService,
    private datePipe: DatePipe,
    private productService: ProductService,
    private siteContentService: SiteContentService,
    private windowScrolling: WindowScrolling,
  ) {
    super();
  }

  ngOnInit() {
    this.siteContentService
      .statesdata$()
      .toPromise()
      .then(response => {
        this.allStates = response;
      });

    this.zipcode = this.service.address.serviceAddress.zipcode;
    this.locData = this.service.address.serviceAddress.zipcode;
    this.locationId = this.service.locationId;
    this.showLoader = true;
    // this.windowScrolling.disable();
    this.productService.getProviderContent(this.locationId).subscribe(data => {
      this.locationCodeData = data.body;
    });
    this.cartService
      .getUpgrades(
        this.service.address.customerNo,
        this.service.productId,
        this.service.productDetails.contactType,
        this.service.productDetails.coverageType,
        this.service.locationId,
      )
      .subscribe(
        data => {
          this.upgradeProducts = data.body.data;
          this.upgradeProducts.forEach(product => {
            const p = product;
            p.isUpgrade = true;
            p.customerNo = this.service.address.customerNo;
            // product['crmCustomerGuid'] = this.service.crmCustomerGuid;
            if (this.upsell === false) {
              p.upsell = false;
            }
            p.enrollmentGuid = [];
            const currentServiceGuid = this.service.crmEnrollmentGuid;
            product.enrollmentGuid.push(this.service.crmEnrollmentGuid);
            // product['crmEnrollmentNumber'] = this.service.crmEnrollmentNumber;
            if (this.upgradeProducts.length > 0) {
              this.showOldPriceContainer(this.upgradeProducts);
              for (let i = 0; i < this.upgradeProducts.length; i++) {
                this.shareIconIndexArray.push({
                  value: false,
                });
              }
            }
            this.enrollServices.forEach(service => {
              if (service.crmEnrollmentGuid && currentServiceGuid !== service.crmEnrollmentGuid) {
                if (this.hasSubArray(product.program.products, service.productDetails.program.products)) {
                  product.enrollmentGuid.push(service.crmEnrollmentGuid);
                }
              }
            });
          });

          this.showLoader = false;
        },
        () => {
          this.showLoader = false;
          this.upgradeProducts = [];
          // error handling
        },
      );
  }

  hasSubArray(master, sub) {
    if (master.length === sub.length) {
      return false;
    }
    let i = 0;
    return sub.every(v => {
      i = master.indexOf(v, i) + 1;
      return i !== 0;
    });
  }

  showErrorModal(title: string, message: string, confirmationMsg?: string) {
    this.simpleModalService
      .addModal(
        ErrorModalComponent,
        {
          title,
          message,
          confirmationMsg,
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: true,
        },
      )
      .subscribe(() => {});
  }

  ngOnDestroy() {
    this.windowScrolling.enable();
  }

  showOldPriceContainer(products) {
    if (products) {
      products.forEach(item => {
        if (item.oldPrice || (item.oldPriceText && !['', undefined, 'undefined', null, 'null'].includes(item.oldPriceText))) {
          this.setOldPriceFlag = true;
        }
      });
    }
  }
}
