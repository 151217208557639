<div class="about-us">
  <section class="banner-box">
    <div class="banner" [style.background]="'url(' + baseImage + ')'">
      <div class="content-box page-wrap">
        <div class="col-12 col-sm-12 col-md-12 col-lg-8 page-title">{{ selectedPage.title }}</div>
      </div>
    </div>
  </section>
  <hos-breadcrumb class="modified-breadcrumb" [config]="breadcrumsConfig"></hos-breadcrumb>
  <section class="static-page-content page-wrap">
    <div class="col-12 page-content">
      <section class="about-menu-card-mobile">
        <div class="row">
          <div class="col-11 services-title-mobile">
            <h6>
              <span>
                {{ menuTitle | titlecase }}
              </span>
            </h6>
          </div>
          <div class="col-1 menu-title">
            <i class="fa fa-angle-up menu-arrows" *ngIf="showSubLink === true" (click)="showSubLink = false"></i>
            <i class="fa fa-angle-down menu-arrows" *ngIf="showSubLink === false" (click)="showSubLink = true"></i>
          </div>
        </div>

        <div class="content-box" *ngIf="showSubLink">
          <div class="card-item" *ngFor="let category of categories" (click)="goToCategory(category.hrefLink)">
            {{ category.categoryName ? category.categoryName : category.customerProgramName }}
          </div>
        </div>
      </section>

      <section class="about-menu-card">
        <h6>{{ selectedPage.subTitle }}</h6>
        <div class="content-box">
          <div class="card-item" *ngFor="let category of categories" (click)="goToCategory(category.hrefLink)">
            {{ category.categoryName ? category.categoryName : category.customerProgramName }}
          </div>
        </div>
      </section>
      <div class="col-12 col-sm-12 col-lg-12 col-xl-8 col-md-12 head-text">
        <div class="content-section">
          <div *ngIf="selectedPage.content" class="desc" [innerHTML]="selectedPage.content | safeHtml"></div>
        </div>
      </div>
    </div>
  </section>
</div>

<div [ngClass]="{ loading: showLoader }"></div>
