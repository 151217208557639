import { PLATFORM_ID, Inject, Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { SessionStorageService } from '../services/session-storage.service';
import { GlobalVariables } from '../services/global-variables.service';

@Injectable()
export class CanActivateViaLoggedinGuard implements CanActivate {
  constructor(
    private router: Router,
    private sessionStorageService: SessionStorageService,
    @Inject(PLATFORM_ID) private platformId: any,
    private globalVariables: GlobalVariables,
  ) {}

  canActivate() {
    if (isPlatformBrowser(this.platformId)) {
      if (this.sessionStorageService.getItem('authHeader')) {
        // logged in so redirect to myaccount if clicking on home link
        if (!this.globalVariables.isRouteToMyAccount) {
          this.router.navigate(['/user/myaccount']);
        } else {
          this.router.navigate(['/user/myaccount']);
          const selectedAddress = this.sessionStorageService.getItem('selectedAddress')
            ? JSON.parse(this.sessionStorageService.getItem('selectedAddress'))
            : '';
          // eslint-disable-next-line @typescript-eslint/naming-convention
          const location_code = this.sessionStorageService.getItem('location_code');
          const addressId = this.sessionStorageService.getItem('addressId');
          const providerState = this.sessionStorageService.getItem('providerState');
          if (selectedAddress && selectedAddress.zipcode && location_code && addressId) {
            const queryString = `${providerState || ''}/user/myaccount?zipcode=${
              selectedAddress.zipcode
            }&location_code=${location_code}&addressId=${addressId}`;
            window.location.href = queryString;
          }
        }
      }
    }
    return true;
  }
}
