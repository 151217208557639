/* eslint-disable no-template-curly-in-string */
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AppSecrets } from '../../app.secrets';

export interface BaseDotCMSResponse {
  responseDesc: string;
  data: Blog[];
  totalCount: string;
  responseCode: number;
}

export interface DotCMSContentItem {
  hostName: string;
  modDate: string;
  commentscount: number;
  title: string;
  content: string;
  factDescription: string;
  baseType: string;
  inode: string;
  entryId: number;
  bannerImage: string;
  archived: boolean;
  host: string;
  working: boolean;
  categories: string[];
  locked: boolean;
  stInode: string;
  sourceFeed: string;
  contentType: string;
  live: boolean;
  owner: string;
  identifier: string;
  image: string;
  languageId: number;
  URL_MAP_FOR_CONTENT: string;
  url: string;
  titleImage: string;
  modUserName: string;
  urlMap: string;
  folder: string;
  hasTitleImage: boolean;
  sortOrder: number;
  modUser: string;
  subTitle?: string;
  summary?: string;
  bannerTabletImage?: string;
  bannerMobileImage?: string;
}

export interface Blog {
  publishedBy: string;
  authorDesignation: string;
  publishedOn: Date;
  likeCount: number;
  updatedDate: Date;
  title: string;
  expiredOn: Date;
  content: string;
  factDescription: string;
  entryId: number | string;
  subTitle: string;
  imageURL: string;
  bannerImageURL: string;
  isVideoContent: boolean;
  viewCount: number;
  id: string;
  featuredContent: boolean;
  categories: string[];
  disLikeCount: number;
  profilePictureURL: string;
  summary: string;
  sourceFeed: string;
  priority: string;
  factTitle: string;
  displayOverlay: boolean;
  commentCount: number;
  relatedBlogs: Blog[];
  createdDate: Date;
  articleSource: string;
  relatedAssets: any[];
  createdBy: string;
  authorName: string;
  bannerTabletImageURL: string;
  bannerMobileImageURL: string;
}

@Injectable()
export class DotCMSService {
  dotCMSContentSearchByCategoryPath: string;

  dotCMSContentSearchByEntryIdPath: string;

  dotCMSContentSearchByDotCMSIdPath: string;

  dotCMSBaseUrl: string;

  dotCMSImageByIdPath: string;

  cloudfrontBaseUrl: string;

  public secrets: any;

  /**
   * Creates an instance of DotCMSService.
   * @param {HttpService} http
   *
   * @memberof DotCMSService
   */
  constructor(private http: HttpClient, public appSecrets?: AppSecrets) {
    this.dotCMSContentSearchByCategoryPath = environment.dotcms_content_search_by_category_path;
    this.dotCMSContentSearchByEntryIdPath = environment.dotcms_content_search_by_entry_id_path;
    this.dotCMSContentSearchByDotCMSIdPath = environment.dotcms_content_search_by_dotcms_id_path;
    this.dotCMSBaseUrl = environment.dotcms_base_url;
    this.dotCMSImageByIdPath = environment.dotcms_image_search_path;
    this.cloudfrontBaseUrl = environment.cloudfront_base_url;
    this.secrets = this.appSecrets.getSecrets();
  }

  /**
   *
   * @param {string} category
   * @returns {Observable<any>}
   *
   * @memberof DotCMSService
   */
  getContentByCategory(category: string, categoriesArray?: any): Observable<BaseDotCMSResponse> {
    let url: string;
    if (categoriesArray) {
      url = this.buildSearchByCategoryUrl('', categoriesArray);
    } else {
      url = this.buildSearchByCategoryUrl(category);
    }
    return this.http.get(url).pipe(
      catchError(() =>
        of({
          responseDesc: 'failure',
          data: [],
          totalCount: '0',
          responseCode: 400,
        }),
      ),
      map(({ contentlets }: { contentlets: DotCMSContentItem[] }) => {
        const content = contentlets.map(this.convertDotCMSContentItemToBlog);
        return {
          responseDesc: 'success',
          data: content,
          totalCount: '0',
          responseCode: 0,
        };
      }),
    );
  }

  // This method is not used because blog.component.ts is not used.
  getAllBlogs(): Observable<Blog[]> {
    // const url = this.buildUrl(`blog-service/blogs/subcategories/Safety`);
    // return this.http.get(url).pipe(
    //   map(({ contentlets }: { contentlets: DotCMSContentItem[] }) =>
    //     contentlets.map(this.convertDotCMSContentItemToBlog)
    //   )
    // );
    return null;
  }

  /**
   *
   * @returns This method is used to get all blogs list
   */
  getAllBlogsList(category: string = 'blogPosts'): Observable<Blog[]> {
    let url;
    url = this.buildSearchByCategoryUrl(category);
    // adding /blogs to not show 0 in network tab.
    url = `${url}/blogs`;
    return this.http
      .get(url)
      .pipe(map(({ contentlets }: { contentlets: DotCMSContentItem[] }) => contentlets.map(this.convertDotCMSContentItemToBlog)));
  }

  getBlogByEntryId(blogId: string): Observable<BaseDotCMSResponse> {
    const url = this.buildUrlWithEntryId(blogId);
    return this.fetchBlog(url);
  }

  getBlogByDotCMSId(blogId: string): Observable<BaseDotCMSResponse> {
    const url = this.buildUrlWithDotCMSId(blogId);
    return this.fetchBlog(url);
  }

  fetchBlog(url: string) {
    return this.http.get(url).pipe(
      catchError(() =>
        of({
          responseDesc: 'failure',
          data: [],
          totalCount: '0',
          responseCode: 400,
        }),
      ),
      map(({ contentlets }: { contentlets: DotCMSContentItem[] }) => {
        if (contentlets === undefined || contentlets === null || contentlets.length === 0) {
          // Remove last condition of length === 0 if any wierd behaviour is observed.
          return {
            responseDesc: 'failure',
            data: [],
            totalCount: '0',
            responseCode: 404,
          };
        }
        const [contentItem] = contentlets;
        return {
          responseDesc: 'success',
          data: [this.convertDotCMSContentItemToBlog(contentItem)],
          totalCount: '0',
          responseCode: 0,
        };
      }),
    );
  }

  buildUrlWithEntryId(id: string) {
    return this.dotCMSBaseUrl.concat(this.dotCMSContentSearchByEntryIdPath.replace(/{id}/g, id));
  }

  buildUrlWithDotCMSId(id: string) {
    return this.dotCMSBaseUrl.concat(this.dotCMSContentSearchByDotCMSIdPath.replace(/{id}/g, id));
  }

  buildSearchByCategoryUrl(category: string, categoriesArray?: any) {
    if (categoriesArray) {
      // Concating microsite blog category with default blog for all microsite.
      return `${this.dotCMSBaseUrl.concat(
        this.dotCMSContentSearchByCategoryPath.replace('${categoryName}', `${categoriesArray[0]}%20categories:${categoriesArray[1]}`),
      )}/blogs`;
    }
    // adding /blogs to not show 0 in network tab
    return `${this.dotCMSBaseUrl.concat(this.dotCMSContentSearchByCategoryPath.replace('${categoryName}', category))}/blogs`;
  }

  dotCMSImageById(imageId: any): any {
    return this.dotCMSBaseUrl.concat(this.dotCMSImageByIdPath.concat(imageId));
  }

  dotCMSRelativeImage(relativePath: string) {
    return this.cloudfrontBaseUrl.concat(relativePath);
  }

  convertDotCMSContentItemToBlog(contentItem: DotCMSContentItem): Blog {
    const input = contentItem.modDate;
    const date = new Date(input.replace(/\s+/g, 'T'));
    const modDate = date.toISOString();
    return {
      publishedBy: contentItem.modUserName,
      authorDesignation: '',
      publishedOn: new Date(modDate),
      likeCount: 0, // dotCMS doesn't support "like"
      updatedDate: new Date(modDate),
      title: contentItem.title,
      expiredOn: null,
      content: contentItem.content,
      factDescription: contentItem.factDescription,
      entryId: contentItem.entryId,
      subTitle: contentItem.subTitle || '',
      imageURL: contentItem.image,
      bannerImageURL: contentItem.bannerImage,
      isVideoContent: false,
      viewCount: 0,
      id: contentItem.identifier,
      featuredContent: false,
      categories: contentItem.categories || [],
      disLikeCount: 0,
      profilePictureURL: '',
      summary: contentItem.summary || '',
      sourceFeed: contentItem.sourceFeed,
      priority: 'Low',
      factTitle: '',
      displayOverlay: true,
      commentCount: 0,
      relatedBlogs: [],
      createdDate: new Date(modDate),
      articleSource: 'Intranet',
      relatedAssets: [],
      createdBy: contentItem.modUserName,
      authorName: contentItem.modUserName,
      bannerTabletImageURL: contentItem.bannerTabletImage,
      bannerMobileImageURL: contentItem.bannerMobileImage,
    };
  }
}
