import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[appReveal]',
})
export class RevealDirective {
  isActive = false;

  constructor(private el: ElementRef<HTMLElement>) {
    this.el.nativeElement.classList.add('reveal');
  }

  reveal() {
    if (this.isActive) return;
    const windowHeight = window.innerHeight;
    const elementTop = this.el.nativeElement.getBoundingClientRect().top;
    const elementVisible = 0;
    if (elementTop < windowHeight - elementVisible) {
      this.el.nativeElement.classList.add('active');
      this.isActive = true;
    }
  }

  @HostListener('window:scroll', []) onMouseEnter() {
    this.reveal();
  }
}
