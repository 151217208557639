import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { DatePipe, isPlatformBrowser } from '@angular/common';
import { SimpleModalComponent, SimpleModalService } from 'ngx-simple-modal';
import { WindowScrolling } from '../../services/window-scrolling.service';
import { CancellationForm } from './model/forms.model';
import { UserService } from '../../../user/services/user.service';
import { OrdersService } from '../../../payment-ets/services/orders.service';
import { ErrorModalComponent } from '../error-modal/error-modal.component';
import { AlertConfirmModalComponent } from '../alert-confirm-modal/alert-confirm-modal.component';

export interface CancelEnrollmentModel {
  cancelText?: string;
  confirmText?: string;
  serviceDetails: any;
}

@Component({
  selector: 'hos-cancel-enrollment-modal',
  templateUrl: './cancel-enrollment-modal.component.html',
  styleUrls: ['./cancel-enrollment-modal.component.scss'],
})
export class CancelEnrollmentComponent extends SimpleModalComponent<CancelEnrollmentModel, Boolean> implements CancelEnrollmentModel, OnInit {
  cancelText?: string;

  confirmText?: string;

  serviceDetails: any;

  showContinue: boolean = false;

  cancellationenrollmentForm: CancellationForm = new CancellationForm();

  isClicked: Boolean;

  cancellationReasonList: any = [
    {
      reasonId: '0',
      reasonValue: 'Competitive Offer from another company',
    },
    {
      reasonId: '1',
      reasonValue: 'I know a Service Provider',
    },
    {
      reasonId: '2',
      reasonValue: 'I No Longer Want',
    },
    {
      reasonId: '3',
      reasonValue: 'Product Cost',
    },
    {
      reasonId: '4',
      reasonValue: 'Price Increase',
    },
    {
      reasonId: '5',
      reasonValue: 'Unsatisfied with Experience Overall',
    },
    {
      reasonId: '6',
      reasonValue: 'Deceased family member',
    },
    {
      reasonId: '7',
      reasonValue: 'Moved to a new home',
    },
  ];

  userDetails: any;

  customerName: any;

  cancellationRequestedDt: string;

  isbrowser = true;

  lpTagscriptNotLoaded: boolean = false;

  constructor(
    private simpleModalService: SimpleModalService,
    private router: Router,
    private windowScrolling: WindowScrolling,
    private userService: UserService,
    private datePipe: DatePipe,
    private orderService: OrdersService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    super();
    // this.windowScrolling.disable();
    this.isClicked = false;
    this.isbrowser = isPlatformBrowser(platformId);
  }

  ngOnInit() {
    if (this.isbrowser) {
      if (window && !(window as any).lpTag) {
        this.lpTagscriptNotLoaded = true;
      }
    }
    this.cancellationRequestedDt = this.datePipe.transform(new Date(), 'MM/dd/yyyy');
    this.userDetails = this.userService.getSignedInUser();
    let firstName: any = '';
    let lastName: any = '';
    if (this.userDetails && this.userDetails.user) {
      if (this.userDetails.user.firstName) {
        firstName = this.toTitleCase(this.userDetails.user.firstName);
      }
      if (this.userDetails.user.lastName) {
        lastName = this.toTitleCase(this.userDetails.user.lastName);
      }
      this.customerName = `${firstName} ${lastName}`;
    }
  }

  confirmHandler(respVal?: any) {
    this.result = respVal ? respVal.data : '';
    this.windowScrolling.enable();
    this.close();
  }

  modalClose() {
    this.windowScrolling.enable();
    this.close();
  }

  onSubmit(form: NgForm) {
    this.isClicked = true;
    if (form.valid) {
      this.sanitize(form.value);
      const formdetails: any = form.value;
      const payload = {
        enrollmentNumber: this.serviceDetails.crmEnrollmentNumber,
        cancelEnrollmentDetails: {
          isEmailSent: 'false',
          isFormSubmitted: 'true',
          cancelEnrollmentReason: formdetails.cancellationReason,
          cancellationRequestedDt: this.cancellationRequestedDt,
        },
        customerNo: this.serviceDetails.address.customerNo,
        userEmail: this.userDetails.user.email,
        customerName: this.customerName,
        webProgramName: this.serviceDetails.productDetails.webProgramName || this.serviceDetails.productDetails.customerProgramName,
        locationCodeId: this.serviceDetails.address.serviceAddress.locationCodeId || this.serviceDetails.locationId,
      };
      this.orderService.cancelEnrollmentRequest(this.serviceDetails, payload).subscribe(
        async response => {
          const result = response.body;
          this.confirmHandler(result);
          this.resetForm(form);
          await new Promise<void>(f => {
            setTimeout(() => {
              f();
            }, 1000);
          });
          // await new Promise(f => setTimeout(f, 1000));
          this.showAlertModal({
            title: 'Your request has been submitted. Our customer support team will get back to you.',
            message: `Program Cancellation Request has been sent for enrollment No. <span class="app-txt">
                ${this.serviceDetails.crmEnrollmentNumber}</span> for Customer Number <span class="app-txt"> ${this.serviceDetails.address.customerNo} </span>. `,
            alertIcon: 'fa fa-info-circle red',
            cancelText: ' ',
            confirmText: 'OK',
            confirmBtnId: 'cancel-enrollment-btn',
          });
        },
        () => {
          this.confirmHandler();
          this.resetForm(form);
          this.showErrorModal('Error', 'Something went wrong. Please try again later.');
        },
      );
    } else {
      // let target: any;
      // for (const i in form.controls) {
      //   if (!form.controls[i].valid) {
      //     target = form.controls[i];
      //     break;
      //   }
      // } To Check
    }
  }

  onLiveChatOpen() {
    const payload = {
      enrollmentNumber: this.serviceDetails.crmEnrollmentNumber,
      cancelEnrollmentDetails: {
        isEmailSent: 'false',
        isFormSubmitted: 'false',
        cancelEnrollmentReason: 'Live Chat',
        cancellationRequestedDt: this.cancellationRequestedDt,
      },
      customerNo: this.serviceDetails.address.customerNo,
      userEmail: this.userDetails.user.email,
      customerName: this.customerName,
      webProgramName: this.serviceDetails.productDetails.webProgramName || this.serviceDetails.productDetails.customerProgramName,
      locationCodeId: this.serviceDetails.address.serviceAddress.locationCodeId || this.serviceDetails.locationId,
    };
    this.orderService.cancelEnrollmentRequest(this.serviceDetails, payload).subscribe(() => {});
  }

  sanitize(sanitizedObj: Object) {
    Object.keys(sanitizedObj).forEach(prop => {
      if (Object.prototype.hasOwnProperty.call(sanitizedObj, prop)) {
        if (typeof sanitizedObj[prop] === 'object') {
          this.sanitize(sanitizedObj[prop]);
        } else if (!sanitizedObj[prop] && sanitizedObj[prop] !== 0) {
          delete sanitizedObj[prop];
        }
      }
    });
    return sanitizedObj;
  }

  resetForm(form: NgForm) {
    form.resetForm();
    this.isClicked = false;
  }

  toTitleCase(str) {
    return str.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
  }

  showErrorModal(title: string, message: string, confirmationMsg?: string, alertIcon?: string) {
    this.simpleModalService
      .addModal(
        ErrorModalComponent,
        {
          title,
          message,
          confirmationMsg,
          alertIcon,
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: true,
        },
      )
      .subscribe(() => {});
  }

  showAlertModal(data?: any, done?) {
    this.simpleModalService
      .addModal(
        AlertConfirmModalComponent,
        {
          title: data.title,
          message: data.message,
          alertIcon: data.alertIcon,
          cancelText: data.cancelText,
          confirmText: data.confirmText,
          confirmBtnId: data.confirmBtnId,
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: false,
        },
      )
      .subscribe(isConfirmed => {
        if (isConfirmed) {
          if (done) {
            done(true);
          }
        } else if (isConfirmed === false) {
          if (done) {
            done(false);
          }
        } else if (isConfirmed === undefined) {
          if (done) {
            done(false);
          }
        }
      });
  }
}
