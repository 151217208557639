import { AfterViewInit, Component, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'hos-select-language',
  templateUrl: './select-language.component.html',
  styleUrls: ['./select-language.component.scss'],
})
export class SelectLanguageComponent implements AfterViewInit {
  constructor(@Inject(PLATFORM_ID) private platformId: any) {}

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      const element = document.createElement('script');
      element.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
      document.head.appendChild(element);

      ($(document) as any).ready(() => {
        ($('#google_translate_element') as any).bind('DOMNodeInserted', () => {
          setTimeout(() => {
            if ($('.goog-te-menu-value span:first').text() === 'Filipino') {
              $('.goog-te-menu-value span:first').text('Tagalog');
            }
            $('.goog-te-menu-value span:first').contents().find('span:contains("Filipino")').html('Tagalog');
            $('.skiptranslate').contents().find('body').find('.goog-te-menu2-item').find('span:contains("Filipino")').html('Tagalog');
          }, 300);
          $('.skiptranslate').contents().find('body').find('.goog-te-menu2-item').find('span:contains("Filipino")').html('Tagalog');
        });
      });
      $(window).on('load', () => {
        $('.skiptranslate').contents().find('body').find('.goog-te-menu2-item').find('span:contains("Filipino")').html('Tagalog');
        ($('.skiptranslate').contents().find('body').find('.goog-te-menu2-item') as any).click(() => {
          this.removeParams();
          $('.skiptranslate').contents().find('body').find('.goog-te-menu2-item').find('span:contains("Filipino")').html('Tagalog');
        });
      });
    }
  }

  removeParams() {
    if (typeof URLSearchParams !== 'undefined') {
      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);
      if (params.toString().startsWith('lang')) {
        params.delete('lang');
        const href = window.location.pathname;
        location.assign(href);
      }
    }
  }
}
