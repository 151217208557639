import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { NguCarouselConfig } from '@ngu/carousel';
import { uniq, flatten } from 'lodash-es';
import { SessionStorageService } from '../../../core/services/session-storage.service';
import { UserService } from '../../services/user.service';
import { ProductService } from '../../../product/services/product.service';
import { SiteContentService } from '../../../core/services/siteContents';

@Component({
  selector: 'hos-other-recommended-new-services',
  templateUrl: './other-recommended-new-services.component.html',
  styleUrls: ['./other-recommended-new-services.component.scss'],
})
export class OtherRecommendedNewServicesComponent implements OnInit, OnChanges {
  @Input() locationId: String;

  @Input() selectedAddress: any;

  @Input() zipcode: String;

  @Input() customerNum?: any;

  @Input() services: any;

  @Input() locationCodeData: any;

  shareIconIndexArray: any = [];

  public carouselTile: NguCarouselConfig;

  products: Array<any> = [];

  userDetails: any = [];

  partnerName: String;

  locData: any;

  showLoader: Boolean;

  allStates: any;

  setOldPriceFlag: boolean = false;

  constructor(
    private router: Router,
    private userService: UserService,
    private sessionStorageService: SessionStorageService,
    private productService: ProductService,
    private siteContentService: SiteContentService,
  ) {
    this.userDetails = this.userService.getSignedInUser();
    this.siteContentService
      .statesdata$()
      .toPromise()
      .then(response => {
        this.allStates = response;
      });
  }

  ngOnInit() {
    this.carouselTile = {
      grid: {
        xs: 1,
        sm: 2,
        md: 4,
        lg: 4,
        all: 0,
      },
      slide: 4,
      speed: 400,
      point: {
        visible: false,
      },
      load: 2,
      touch: true,
      loop: true,
      easing: 'ease',
    };
    this.partnerName = this.sessionStorageService.getItem('websitePartnerName');
    if (this.partnerName) {
      this.productService.getProviderContent(this.sessionStorageService.getItem('location_code')).subscribe(locationData => {
        this.locData = locationData.body;
      });
    } else {
      this.productService.getProviderContent(this.sessionStorageService.getItem('location_code')).subscribe(data => {
        this.sessionStorageService.setItem('websitePartnerName', data.body.websitePartnerName);
        this.partnerName = this.sessionStorageService.getItem('websitePartnerName');
        if (this.partnerName) {
          this.locData = data.body;
        }
      });
    }
  }

  ngOnChanges() {
    if (this.locationId) {
      this.getProducts(this.locationId);
    }
  }

  getProducts(locationId?: String) {
    let contentTypeValue = 1;
    if (this.selectedAddress.serviceAddress.contactType === 'RESIDENTIAL') {
      contentTypeValue = 1;
    } else if (this.selectedAddress.serviceAddress.contactType === 'COMMERCIAL') {
      contentTypeValue = 3;
    }
    this.productService.getRecommendedProducts(locationId, '', this.userDetails.user._id, contentTypeValue, this.customerNum).subscribe(
      data => {
        if (data.status === 200) {
          let resData = data.body;
          this.products = [];
          /** HWR-2517 remove suspended enrolled products - Start* */
          const enrolledProductData: any = this.services;
          const enrolledProductList = enrolledProductData.filter(item => item.crmStatus === '6').map(value => value.productDetails.program.products);
          const uniqEnrolledProductList = uniq(flatten(enrolledProductList));
          uniqEnrolledProductList.forEach(ele => {
            resData = resData.filter(item => !item.program.products.includes(ele));
          });
          /** HWR-2517 -End * */
          /*  for (let i = 0; i < resData.length; i ++) {
                        if (i === 6) {
                            break;
                        } else {
                            this.products.push(resData[i]);
                        }
                    } */
          this.products = resData;
          this.showOldPriceContainer(resData);
          if (this.products.length > 0) {
            for (let i = 0; i < this.products.length; i++) {
              this.shareIconIndexArray.push({
                value: false,
              });
            }
          }
        } else if (data.status !== 200) {
          this.products = [];
        }
      },
      () => {
        this.products = [];
      },
    );

    this.partnerName = this.sessionStorageService.getItem('websitePartnerName');
    if (this.partnerName) {
      this.productService.getProviderContent(this.sessionStorageService.getItem('location_code')).subscribe(locationData => {
        this.locData = locationData.body;
      });
    }
  }

  loaderValue(value) {
    this.showLoader = value;
  }

  showOldPriceContainer(products) {
    if (products) {
      products.forEach(item => {
        if (item.oldPrice || (item.oldPriceText && !['', undefined, 'undefined', null, 'null'].includes(item.oldPriceText))) {
          this.setOldPriceFlag = true;
        }
      });
    }
  }
}
