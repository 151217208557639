<!-- Fixed Header section -->
<section
  *ngIf="fixedHeader"
  [@inOutAnimation]
  class="content-section detail-header-section fs-restrict-dead-clicks"
  style.backgroundColor="{{ colorbanner }}"
  [ngClass]="{ 'fixed-header': fixedHeader, 'no-background': selectedAddress?.zipcode }"
>
  <div class="page-wrap row fixed-header-wrapper except-mobile-only" *ngIf="productDetail">
    <div class="col-12 col-lg-7 left-box max-width-header" [ngClass]="{ 'col-12 col-lg-8': fixedHeader }">
      <div class="program-head row" style="height: 70px; padding-top: 0">
        <img
          class="icon"
          [src]="getImage(productDetail?.programIconUrl ? baseUrl + productDetail.programIconUrl : '')"
          (click)="scrollToTop()"
          [alt]="productDetail?.webProgramName"
          *ngIf="productType === 'product'"
        />
        <img
          class="icon"
          [src]="getImage(baseUrl + productDetail?.icon)"
          loading="lazy"
          alt="product-detail"
          (click)="scrollToTop()"
          [alt]="productDetail?.serviceName"
          *ngIf="productType === 'service'"
        />
        <span class="program-name" (click)="scrollToTop()">
          <h3 *ngIf="productDetail?.serviceName">{{ productDetail.serviceName }}</h3>
          <h3 *ngIf="productDetail?.webProgramName">{{ productDetail.webProgramName }}</h3>
        </span>
      </div>
      <div class="detail-link">
        <div class="detail-link-social font-weight-500">
          <a class="social-btn d-none" #toggleButton (click)="showSocialIcons()">Share<i class="fa fa-share-alt iconClass" aria-hidden="true"></i></a>
          <div #menu class="social fixed-social" *ngIf="shareIcon">
            <ul class="social-list">
              <li class="social-icons social-twitter" (click)="learnMoreNativeLink(productDetail, 'twitter')">
                <a>
                  <i class="fa fa-twitter" aria-hidden="true"></i>
                </a>
              </li>
              <li class="social-icons social-facebook" (click)="learnMoreNativeLink(productDetail, 'fb')">
                <a>
                  <i class="fa fa-facebook" aria-hidden="true"></i>
                </a>
              </li>
              <li class="social-icons social-linkedin" (click)="learnMoreNativeLink(productDetail, 'linkedin')">
                <a>
                  <i class="fa fa-linkedin" aria-hidden="true"></i>
                </a>
              </li>
              <li class="social-icons social-pinterest" (click)="learnMoreNativeLink(productDetail, 'pinterest')">
                <a>
                  <i class="fa fa-pinterest" aria-hidden="true"></i>
                </a>
              </li>
              <li class="social-icons social-mail" (click)="learnMoreNativeLink(productDetail, 'mail')">
                <a>
                  <i class="fa fa-envelope" aria-hidden="true"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="program-name font-weight-500" *ngIf="avgrating">
          <a class="rating-btn" (click)="scrollToSection('review-section')">Rating</a>
          <div class="rating-average" (click)="scrollToSection('review-section')">
            <span *ngFor="let i of numbers" class="rating-star fa" [ngClass]="{ 'fa-star': i <= avgrating, 'fa-star-o': i > avgrating }"></span>
            <span *ngIf="totalReviews" class="rating-label"> ({{ totalReviews }}) </span>
          </div>
        </div>
        <div *ngIf="productType === 'product'" class="program-name font-weight-500">
          <a class="terms-btn" (click)="goToTerms()">Terms & Conditions</a>
        </div>
      </div>
    </div>
    <!-- <div class="col-4 col-md-2 center-align partner-section mt-3" *ngIf="!!partnerName && isProviser">
            <div *ngIf="!!partnerName && isProviser" class="pb-2">
                <p *ngIf="locData?.providerIcon" class="pt-2 mt-1 mb-1 partner-text">In partnership with</p>
                <img *ngIf="locData?.providerIcon" class="provider-logo" [src]="baseUrlLoc + locData?.providerIcon">
            </div>
        </div> -->
    <div *ngIf="productType === 'service'" class="right-box google-popup no-background col-12 col-lg-4">
      <div
        class="selected-location-area"
        [ngClass]="{ 'service-lddp': specialProduct.includes(productDetail?.programId) && productType === 'service' }"
      >
        <span
          *ngIf="specialProduct.includes(productDetail?.programId) && productType === 'service' && !selectedAddress?.zipcode"
          class="pricing-text"
          [ngClass]="{ 'line-height-1 ML-0': fixedHeader }"
          [hidden]="productType === 'product'"
        >
          <button
            style.color="{{ productDetail?.colorConfigStyle }}"
            [ngClass]="{ 'leak-program-fix': fixedHeader }"
            class="btn btn-link non-href-links btn-sm change-link leak-program leak-margin"
            (click)="goToPaymentOptions()"
          >
            View Payment Options
          </button>
        </span>
        <span *ngIf="specialProduct.includes(productDetail?.programId); then leakSpan; else notLeakSpan"></span>
        <ng-template #leakSpan>
          <span class="search-txt" [hidden]="selectedAddress?.zipcode">Enter your ZIP to see payment options.</span>
        </ng-template>
        <ng-template #notLeakSpan>
          <span class="search-txt" [hidden]="selectedAddress?.zipcode">Enter your ZIP to see your monthly payment.</span>
        </ng-template>
        <div class="pricing-box" *ngIf="isLocationCodeMatching">
          <h3 *ngIf="specialProduct.includes(productDetail?.programId); then leak; else notLeak"></h3>
          <ng-template #leak>
            <span class="pricing-text" [ngClass]="{ 'line-height-1': fixedHeader }" [hidden]="!selectedAddress?.zipcode">
              <button
                style.color="{{ productDetail?.colorConfigStyle }}"
                [ngClass]="{ 'leak-program-fix': fixedHeader }"
                class="btn btn-link non-href-links btn-sm change-link leak-program"
                (click)="goToPaymentOptions()"
              >
                Click here for payment options
              </button>
            </span>
          </ng-template>
          <ng-template #notLeak>
            <span class="pricing-text" [hidden]="!selectedAddress?.zipcode"
              >${{ productDetail?.monthlyPrice | slice : 0 : -2 }}
              <small>per month</small>
            </span>
          </ng-template>
        </div>
        <div class="selected-location-box" *ngIf="isLocationCodeMatching" [hidden]="!selectedAddress?.zipcode">
          <label class="og-label">Price based on your location</label>
          <label class="fixed-label">Price based on: </label>
          <div class="address-text">
            <span class="selected-area-text skiptranslate">
              <!-- <i aria-hidden="true" class="fa fa-map-marker"></i> -->
              {{ selectedAddress?.zipcode }}.</span
            >
            <div class="langConfig">
              <span class="change-area-link btn-link btn-sm zip-change-link primary-link change-link pull-right paddM0" (click)="changeAddress()">
                CHANGE
              </span>
            </div>
          </div>
        </div>

        <div class="unavailable-block" *ngIf="!isLocationCodeMatching" [hidden]="!selectedAddress?.zipcode">
          <label *ngIf="showProductChecking" class="my-2" class="product-check"
            ><i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw mr-2 spinner"></i>Checking Product in the zipcode</label
          >
          <label *ngIf="!showProductChecking" class="error-text mb-2">
            <span class="fa fa-exclamation-circle"></span> This service is not available for the selected address</label
          >
          <div class="address-text">
            <span class="selected-area-text skiptranslate">
              <!-- <i aria-hidden="true" class="fa fa-map-marker"></i> -->{{ selectedAddress?.zipcode }}</span
            >
            <span class="change-area-link btn-link btn-sm zip-change-link primary-link change-link pull-right paddM0" (click)="changeAddress()">
              CHANGE
            </span>
          </div>
        </div>
        <div
          class="enroll-box text-center mobile-only"
          style.backgroundColor="{{ productDetail?.colorConfigStyle }}"
          *ngIf="productDetail?.isAvailableOnline || productDetail?.program.programCode.toUpperCase() === 'SURGE'"
        >
          <button
            class="btn ga-track-enroll-now enroll-btn text-right env-btn skiptranslate"
            *ngIf="selectedAddress?.zipcode && productType === 'product' && !specialProduct.includes(productDetail?.programId)"
            [disabled]="fixedHeader && !selectedAddress?.zipcode"
            (click)="addProductToCart(productDetail)"
            title="Add to Cart"
          >
            Add to Cart
          </button>
          <button
            class="btn ga-track-enroll-now enroll-btn text-right env-btn skiptranslate"
            [disabled]="fixedHeader && !selectedAddress?.zipcode"
            (click)="addProductToCart(productDetail)"
            *ngIf="selectedAddress?.zipcode && productType === 'product' && specialProduct.includes(productDetail?.programId)"
            title="Buy Now"
          >
            Buy Now
          </button>
        </div>
      </div>

      <div
        [ngClass]="{ 'service-lddp-zip': specialProduct.includes(productDetail?.programId) && productType === 'service' }"
        [hidden]="selectedAddress?.zipcode"
        [ngClass]="{ 'empty-zipcode': !selectedAddress?.zipcode && productType === 'service' }"
      >
        <div class="search-location-area detail-header">
          <hos-google-places
            (addressHandler)="addressHandler($event)"
            [placeholder]="'Your Zip Code'"
            [btnText]="'Get Started'"
            [isLeakProg]="specialProduct.includes(productDetail?.programId) ? 'true' : 'false'"
            class="pull-left"
          ></hos-google-places>
        </div>
      </div>
    </div>
    <div class="col-lg-4 max-width-price-area display-flex-cls" *ngIf="productType === 'product'">
      <div class="fixed-header-pricing-box display-flex-cls" [style.backgroundColor]="adjustPricingBoxColor(colorbanner, -30)">
        <span *ngIf="specialProduct.includes(productDetail?.programId); then leak; else notLeak"></span>
        <ng-template #leak>
          <div class="fixed-leak-options-link">
            <a class="Pricing-terms-link" (click)="goToPaymentOptions()">View Payment Options</a>
          </div>
        </ng-template>
        <ng-template #notLeak>
          <div class="pricing-text pricing-text-style">
            ${{ productDetail?.monthlyPrice | slice : 0 : -2 }}
            <span class="font-size-16">per month</span>
          </div>
        </ng-template>
      </div>
      <div class="MT-0" *ngIf="productDetail?.isAvailableOnline || productDetail?.program.programCode.toUpperCase() === 'SURGE'">
        <!-- HWR - 2800 04 Jul -->
        <button
          class="btn enroll-btn ga-track-enroll-now text-right skiptranslate"
          [disabled]="disableButton || (fixedHeader && !selectedAddress?.zipcode)"
          (click)="addProductToCart(productDetail)"
          *ngIf="productType === 'product' && !specialProduct.includes(productDetail?.programId)"
          title="Add to Cart"
        >
          Add to Cart
        </button>
        <button
          class="btn enroll-btn ga-track-enroll-now text-right skiptranslate"
          [disabled]="disableButton || (fixedHeader && !selectedAddress?.zipcode)"
          (click)="addProductToCart(productDetail)"
          *ngIf="productType === 'product' && specialProduct.includes(productDetail?.programId)"
          title="Buy Now"
        >
          Buy Now
        </button>
        <!-- HWR - 2800 04 Jul -->
      </div>
    </div>
    <span class="col-lg-2 col-12 text-center align-info desktop-only" *ngIf="!productDetail?.isAvailableOnline">
      <strong>This product is currently not available online.</strong>
      <br />
      <span>To enroll in this service please call customer service</span>
    </span>
  </div>
  <div class="page-wrap row fixed-header-wrapper mobile-screen-only" *ngIf="productDetail">
    <div class="col-12 left-box max-width-header">
      <div class="program-head row" style="height: 60px; padding-top: 0">
        <img
          class="icon"
          [src]="getImage(productDetail?.programIconUrl ? baseUrl + productDetail.programIconUrl : '')"
          (click)="scrollToTop()"
          *ngIf="productType === 'product'"
          [alt]="productDetail?.webProgramName"
        />
        <img
          class="icon"
          [src]="getImage(baseUrl + productDetail?.icon)"
          (click)="scrollToTop()"
          *ngIf="productType === 'service'"
          [alt]="productDetail?.serviceName"
        />
        <span class="program-name" (click)="scrollToTop()">
          <h3 *ngIf="productDetail?.serviceName">{{ productDetail.serviceName }}</h3>
          <h3 *ngIf="productDetail?.webProgramName">{{ productDetail.webProgramName }}</h3>
        </span>
      </div>
    </div>
    <span class="col-lg-2 col-12 text-center align-info desktop-only" *ngIf="!productDetail?.isAvailableOnline">
      <strong>This product is currently not available online.</strong>
      <br />
      <span>To enroll in this service please call customer service</span>
    </span>
  </div>
</section>
<section *ngIf="fixedHeader" class="selected-zipcode-area mobile-screen-only fs-restrict-dead-clicks" [ngClass]="{ 'fixed-header': fixedHeader }">
  <div class="page-wrap program-content" *ngIf="productDetail">
    <div *ngIf="!selectedAddress?.zipcode" class="no-zipcode-search-mobile">
      <!-- <div class="pricing-txt">Pricing and Availability</div> -->
      <div
        class="col-12 search-zipcode-area"
        [ngClass]="{ 'service-lddp-zip': specialProduct.includes(productDetail?.programId) && productType === 'service' }"
        [ngClass]="{ 'empty-zipcode': !selectedAddress?.zipcode && productType === 'service' }"
      >
        <div class="col-12 text-align-center PD-0">
          <span *ngIf="specialProduct.includes(productDetail?.programId); then leakSpan; else notLeakSpan"></span>
          <ng-template #leakSpan>
            <span>Enter your ZIP to see payment options.</span>
          </ng-template>
          <ng-template #notLeakSpan>
            <span>Enter your ZIP to see your monthly payment.</span>
          </ng-template>
        </div>
        <div class="col-12 display-flex-cls div-top">
          <hos-google-places
            (addressHandler)="addressHandler($event)"
            [placeholder]="'Enter ZIP Code'"
            [btnText]="'Get Started'"
            [isLeakProg]="specialProduct.includes(productDetail?.programId) ? 'true' : 'false'"
            class="pull-left"
          ></hos-google-places>
          <i class="fa fa-times pull-right" (click)="close()" aria-hidden="true" *ngIf="!selectedAddress?.zipcode && productType !== 'service'"></i>
        </div>
      </div>
      <div class="text-align-center margin-ld-div" *ngIf="specialProduct.includes(productDetail?.programId)">
        <button (click)="goToInstallRequirements()" class="payment-options-btn">Installation Requirements</button>
      </div>
    </div>
    <div
      *ngIf="selectedAddress?.zipcode"
      class="zipcode-searched-mobile"
      [ngClass]="{ 'lddp-zipcode-search': specialProduct.includes(productDetail?.programId) }"
    >
      <div class="unavailable-block MT-15" *ngIf="!isLocationCodeMatching" [hidden]="!selectedAddress?.zipcode">
        <label *ngIf="showProductChecking" class="my-2" class="product-check"
          ><i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw mr-2 spinner"></i>Checking Product in the zipcode</label
        >
        <label *ngIf="!showProductChecking" class="error-text mb-2">
          <span class="fa fa-exclamation-circle"></span> This service is not available for the selected address</label
        >
        <div class="address-text">
          <span class="selected-area-text skiptranslate font-weight-bold">
            <!-- <i aria-hidden="true" class="fa fa-map-marker"></i>-->
            {{ selectedAddress?.zipcode }}</span
          >
          <span class="change-area-link btn-link btn-sm change-link paddM0 change-link-style" (click)="changeAddress()"> Change </span>
        </div>
      </div>
      <div class="pricing-box display-flex-cls" *ngIf="isLocationCodeMatching">
        <span *ngIf="specialProduct.includes(productDetail?.programId); then leak; else notLeak"></span>
        <ng-template #leak>
          <div class="pricing-leak-mob">
            <div class="Pricing-terms-leak ML-0">
              <a class="Pricing-terms-link" (click)="goToPaymentOptions()">View Payment Options</a>
            </div>
            <div>
              <span class="selected-area-text skiptranslate font-weight-bold font-size-12">
                <!-- <i aria-hidden="true" class="fa fa-map-marker MR-3"></i>-->{{ selectedAddress?.zipcode }}</span
              >
              <span class="change-zip change-area-link btn-link btn-sm change-link paddM0 font-size-12 text-underline" (click)="changeAddress()">
                Change
              </span>
            </div>
          </div>
          <div class="MR-15" *ngIf="productDetail?.isAvailableOnline || productDetail?.program.programCode.toUpperCase() === 'SURGE'">
            <!-- HWR - 2800 04 Jul -->
            <button
              class="btn ga-track-enroll-now enroll-now-btn-mob skiptranslate"
              [disabled]="disableButton || !selectedAddress?.zipcode"
              [ngClass]="{ 'disabled-btn': disableButton || !selectedAddress?.zipcode }"
              (click)="addProductToCart(productDetail)"
              *ngIf="productType === 'product' && specialProduct.includes(productDetail?.programId)"
              title="Buy Now"
            >
              Buy Now
            </button>
            <!-- HWR - 2800 04 Jul -->
          </div>
        </ng-template>
        <ng-template #notLeak>
          <div class="pricing-not-leak">
            <div class="pricing-text">
              ${{ productDetail?.monthlyPrice | slice : 0 : -2 }}
              <small>per month</small>
            </div>
            <div>
              <span class="selected-area-text skiptranslate font-weight-bold font-size-12">
                <!-- <i aria-hidden="true" class="fa fa-map-marker MR-3"></i>-->{{ selectedAddress?.zipcode }}</span
              >
              <span class="change-zip change-area-link btn-link btn-sm change-link paddM0 font-size-12 text-underline" (click)="changeAddress()">
                Change
              </span>
            </div>
          </div>
          <div class="MR-15" *ngIf="productDetail?.isAvailableOnline || productDetail?.program.programCode.toUpperCase() === 'SURGE'">
            <!-- HWR - 2800 04 Jul -->
            <button
              class="btn ga-track-enroll-now enroll-now-btn-mob skiptranslate"
              [disabled]="disableButton || !selectedAddress?.zipcode"
              [ngClass]="{ 'disabled-btn': disableButton || !selectedAddress?.zipcode }"
              (click)="addProductToCart(productDetail)"
              *ngIf="productType === 'product' && !specialProduct.includes(productDetail?.programId)"
              title="Add to Cart"
            >
              Add to Cart
            </button>
            <!-- HWR - 2800 04 Jul -->
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</section>
<!-- 2925 Default Header Section  -->
<section
  #generalSection
  *ngIf="!fixedHeader"
  class="content-block content-section shaded-white general-section general-desc-section fs-restrict-dead-clicks"
  id="general-section"
>
  <!-- <div class="round-up"></div> -->
  <article class="bg-banner-img mob-gen-section" [ngClass]="{ 'min-height-500': productBannerMedia && productBannerMedia.length > 1 }">
    <div class="float-left width-gen-section">
      <div class="banner-image-div">
        <img
          *ngIf="bannerMedia?.type === 'image' || tabletBannerMedia?.type === 'image' || mobileBannerMedia?.type === 'image'"
          class="banner-img"
          [src]="innerWidth > 1024 ? bannerMedia?.value : innerWidth < 768 ? mobileBannerMedia?.value : tabletBannerMedia?.value"
          loading="lazy"
          [alt]="productDetail?.webProgramName ?? productDetail?.serviceName"
        />
        <div *ngIf="bannerMedia?.type === 'video'" class="preview-banner-video" [innerHTML]="bannerMedia?.value"></div>
      </div>
      <!-- Carousel -->
      <div class="PD-desktop-10">
        <h3 *ngIf="innerWidth > 1024; then Desktop; else TabMob"></h3>
        <ng-template #Desktop>
          <ngu-carousel
            *ngIf="productBannerMedia && productBannerMedia.length > 1"
            [inputs]="carouselBanner"
            [dataSource]="productBannerMedia"
            class="carousel-banner"
          >
            <ngu-tile
              *nguCarouselDef="let media of productBannerMedia ? productBannerMedia : []; let i = index"
              class="white-space-ie border-banners item"
            >
              <div *ngIf="media.type === 'video'" (click)="setBannerMedia(media, 'Desktop')" class="paddLRM10 banner-video">
                <!-- <i class="fa fa-play-circle"></i> -->
                <img class="play-circle-btn" src="../../../../img/product-details/Play_ico.svg" alt="Play" />
                <img class="banner-img" [src]="media?.thumbnail" [alt]="productDetail?.webProgramName ?? productDetail?.serviceName" />
              </div>
              <img
                *ngIf="media.type === 'image'"
                (click)="setBannerMedia(media, 'Desktop')"
                class="banner-img carousel-img"
                [src]="media?.value"
                loading="lazy"
                [alt]="productDetail?.webProgramName ?? productDetail?.serviceName"
              />
            </ngu-tile>
            <button NguCarouselPrev class="leftRs"></button>
            <button NguCarouselNext class="rightRs"></button>
          </ngu-carousel>
        </ng-template>
        <ng-template #TabMob>
          <h3 *ngIf="innerWidth < 768; then Mobile; else Tablet"></h3>
          <ng-template #Tablet>
            <ngu-carousel
              *ngIf="productTabletBannerMedia && productTabletBannerMedia.length > 1"
              [inputs]="carouselBanner"
              [dataSource]="productTabletBannerMedia"
              class="carousel-banner"
            >
              <ngu-tile
                *nguCarouselDef="let media of productTabletBannerMedia ? productTabletBannerMedia : []; let i = index"
                class="white-space-ie border-banners item"
              >
                <div *ngIf="media.type === 'video'" (click)="setBannerMedia(media, 'Tablet')" class="paddLRM10 banner-video">
                  <!-- <i class="fa fa-play-circle"></i> -->
                  <img class="play-circle-btn" src="../../../../img/product-details/Play_ico.svg" alt="Play" />
                  <img class="banner-img" [src]="media?.thumbnail" [alt]="productDetail?.webProgramName ?? productDetail?.serviceName" />
                </div>
                <img
                  *ngIf="media.type === 'image'"
                  (click)="setBannerMedia(media, 'Tablet')"
                  class="banner-img carousel-img"
                  [src]="media?.value"
                  loading="lazy"
                  [alt]="productDetail?.webProgramName ?? productDetail?.serviceName"
                />
              </ngu-tile>
              <button NguCarouselPrev class="leftRs"></button>
              <button NguCarouselNext class="rightRs"></button>
            </ngu-carousel>
          </ng-template>
          <ng-template #Mobile>
            <ngu-carousel
              *ngIf="productMobileBannerMedia && productMobileBannerMedia.length > 1"
              [inputs]="carouselBanner"
              [dataSource]="productMobileBannerMedia"
              class="carousel-banner"
            >
              <ngu-tile
                *nguCarouselDef="let media of productMobileBannerMedia ? productMobileBannerMedia : []; let i = index"
                class="white-space-ie border-banners item"
              >
                <div *ngIf="media.type === 'video'" (click)="setBannerMedia(media, 'Mobile')" class="paddLRM10 banner-video">
                  <!-- <i class="fa fa-play-circle"></i> -->
                  <img class="play-circle-btn" src="../../../../img/product-details/Play_ico.svg" loading="lazy" alt="Play" />
                  <img class="banner-img" [src]="media?.thumbnail" [alt]="productDetail?.webProgramName ?? productDetail?.serviceName" />
                </div>
                <img
                  *ngIf="media.type === 'image'"
                  (click)="setBannerMedia(media, 'Mobile')"
                  class="banner-img carousel-img PD-0"
                  [src]="media?.value"
                  [alt]="productDetail?.webProgramName ?? productDetail?.serviceName"
                />
              </ngu-tile>
              <button NguCarouselPrev class="leftRs"></button>
              <button NguCarouselNext class="rightRs"></button>
            </ngu-carousel>
          </ng-template>
        </ng-template>
      </div>
      <!-- Carousel -->
    </div>
    <div class="page-wrap row justify-content-gen-sec image-mobile-overlay PD-0 max-width-100">
      <div class="col-12 col-md-12 content-box">
        <section class="content-section detail-header-section content-style">
          <div class="page-wrap row fixed-header-wrapper" *ngIf="productDetail">
            <div class="col-12 left-box tab-width-100 PD-0">
              <div class="desktop-only-view program-name font-weight-400 PR-15" *ngIf="avgrating">
                <a class="rating-btn" (click)="scrollToSection('review-section')">Rating</a>
                <div class="rating-average" (click)="scrollToSection('review-section')">
                  <span *ngFor="let i of numbers" class="rating-star fa" [ngClass]="{ 'fa-star': i <= avgrating, 'fa-star-o': i > avgrating }"></span>
                  <span *ngIf="totalReviews" class="rating-label"> ({{ totalReviews }}) </span>
                </div>
              </div>
              <div *ngIf="productType === 'product'" class="desktop-only-view program-name font-weight-400">
                <a class="terms-btn" (click)="goToTerms()">Terms & Conditions</a>
              </div>
              <div class="detail-link links-pad-marg mobile-display-style">
                <div class="program-name font-weight-400">
                  <div>
                    <a class="social-btn d-none" #toggleButton (click)="showSocialIcons()"
                      >Share<i class="fa fa-share-alt iconClass" aria-hidden="true"></i
                    ></a>
                    <div #menu class="social" *ngIf="shareIcon" style="position: absolute">
                      <ul class="social-list">
                        <li class="social-icons social-twitter" (click)="learnMoreNativeLink(productDetail, 'twitter')">
                          <a>
                            <i class="fa fa-twitter" aria-hidden="true"></i>
                          </a>
                        </li>
                        <li class="social-icons social-facebook" (click)="learnMoreNativeLink(productDetail, 'fb')">
                          <a>
                            <i class="fa fa-facebook" aria-hidden="true"></i>
                          </a>
                        </li>
                        <li class="social-icons social-linkedin" (click)="learnMoreNativeLink(productDetail, 'linkedin')">
                          <a>
                            <i class="fa fa-linkedin" aria-hidden="true"></i>
                          </a>
                        </li>
                        <li class="social-icons social-pinterest" (click)="learnMoreNativeLink(productDetail, 'pinterest')">
                          <a>
                            <i class="fa fa-pinterest" aria-hidden="true"></i>
                          </a>
                        </li>
                        <li class="social-icons social-mail" (click)="learnMoreNativeLink(productDetail, 'mail')">
                          <a>
                            <i class="fa fa-envelope" aria-hidden="true"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="mobile-only-view program-name font-weight-400 rating-link" *ngIf="avgrating">
                    <a class="rating-btn" (click)="scrollToSection('review-section')">Rating</a>
                    <div class="rating-average" (click)="scrollToSection('review-section')">
                      <span
                        *ngFor="let i of numbers"
                        class="rating-star fa"
                        [ngClass]="{ 'fa-star': i <= avgrating, 'fa-star-o': i > avgrating }"
                      ></span>
                      <span *ngIf="totalReviews" class="rating-label"> ({{ totalReviews }}) </span>
                    </div>
                  </div>
                  <div *ngIf="productType === 'product'" class="mobile-only-view program-name font-weight-400">
                    <a class="terms-btn" (click)="goToTerms()">Terms & Conditions</a>
                  </div>
                </div>
              </div>
              <div class="program-head">
                <span class="program-name default-cursor">
                  <h3 *ngIf="productDetail?.serviceName">{{ productDetail.serviceName }}</h3>
                  <h3 *ngIf="productDetail?.webProgramName">{{ productDetail.webProgramName }}</h3>
                </span>
              </div>
            </div>
          </div>
        </section>
        <section class="selected-zipcode-area">
          <div class="page-wrap program-content" *ngIf="productDetail">
            <div *ngIf="!selectedAddress?.zipcode" class="no-zipcode-search">
              <span class="details-zip-text-mobile">{{ zipcodeLookupTexts && zipcodeLookupTexts?.productServicePageSubtitleText }}</span>
              <div class="pricing-txt">
                {{
                  zipcodeLookupTexts && zipcodeLookupTexts?.productServicePageText !== ''
                    ? zipcodeLookupTexts?.productServicePageText
                    : 'YOUR ZIP CODE:'
                }}
              </div>
              <i
                class="fa fa-times pull-right mobile-only"
                (click)="close()"
                aria-hidden="true"
                *ngIf="!selectedAddress?.zipcode && productType !== 'service'"
              ></i>
              <div
                class="search-zipcode-area mobile-margin"
                [ngClass]="{ 'service-lddp-zip': specialProduct.includes(productDetail?.programId) && productType === 'service' }"
                [ngClass]="{ 'empty-zipcode': !selectedAddress?.zipcode && productType === 'service' }"
              >
                <!-- <span>Search your Zip Code</span> -->
                <hos-google-places
                  (addressHandler)="addressHandler($event)"
                  [placeholder]="'Enter ZIP Code'"
                  [btnText]="
                    zipcodeLookupTexts && zipcodeLookupTexts?.productServicePageButtonText !== ''
                      ? zipcodeLookupTexts?.productServicePageButtonText
                      : 'Get Started'
                  "
                  [subtitleText]="zipcodeLookupTexts && zipcodeLookupTexts?.productServicePageSubtitleText"
                  [isLeakProg]="specialProduct.includes(productDetail?.programId) ? 'true' : 'false'"
                  class="pull-left w-100"
                ></hos-google-places>
                <i
                  class="fa fa-times pull-right desktop-only"
                  (click)="close()"
                  aria-hidden="true"
                  *ngIf="!selectedAddress?.zipcode && productType !== 'service'"
                ></i>
              </div>
              <div class="text-align-center margin-15-zero" *ngIf="specialProduct.includes(productDetail?.programId)">
                <button (click)="goToInstallRequirements()" class="payment-options-btn">Installation Requirements</button>
              </div>
            </div>
            <div
              *ngIf="selectedAddress?.zipcode"
              class="zipcode-searched"
              [ngClass]="{ 'lddp-zipcode-search': specialProduct.includes(productDetail?.programId) }"
            >
              <div class="unavailable-block MT-15" *ngIf="!isLocationCodeMatching" [hidden]="!selectedAddress?.zipcode">
                <label *ngIf="showProductChecking" class="my-2" class="product-check"
                  ><i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw mr-2 spinner"></i>Checking Product in the zipcode</label
                >
                <label *ngIf="!showProductChecking" class="error-text mb-2">
                  <span class="fa fa-exclamation-circle"></span> This service is not available for the selected address</label
                >
                <div class="address-text">
                  <label class="og-label">Based on your location</label>
                  <span class="selected-area-text pl-1 skiptranslate font-weight-bold">
                    <!-- <i aria-hidden="true" class="fa fa-map-marker"></i> -->{{ selectedAddress?.zipcode }}</span
                  >
                  <span class="change-area-link btn-link btn-sm change-link paddM0 change-link-style" (click)="changeAddress()"> Change </span>
                </div>
              </div>
              <div class="pricing-box" *ngIf="isLocationCodeMatching">
                <span *ngIf="specialProduct.includes(productDetail?.programId); then leak; else notLeak"></span>
                <ng-template #leak>
                  <div class="col-12 PT-15 PB-15 disp-flex">
                    <div class="payment-options-box ML-0">
                      <div class="pricing-text pricing-text-leak">
                        <span class="notranslate">$10.00</span>
                        <div class="leak-txt">per month*</div>
                        <div class="pricing-text">60 - Month Term</div>
                      </div>
                    </div>
                    <div class="payment-options-box">
                      <div class="pricing-text pricing-text-leak">
                        <span class="notranslate">$25.00</span>
                        <div class="leak-txt">per month*</div>
                        <div class="pricing-text">24 - Month Term</div>
                      </div>
                    </div>
                    <div class="payment-options-box">
                      <div class="pricing-text pricing-text-leak">
                        <span class="notranslate">$600.00</span>
                        <div class="pricing-text">One-time payment</div>
                      </div>
                    </div>
                  </div>
                </ng-template>
                <ng-template #notLeak>
                  <div class="pricing-not-leak MT-10">
                    <label class="pricing-text">
                      <span class="notranslate">${{ productDetail?.monthlyPrice | slice : 0 : -2 }}</span>
                      <small>per month</small>
                    </label>
                  </div>
                  <div class="text-position">
                    <div class="old-price-container">
                      <span class="old-price" *ngIf="productDetail && productDetail.oldPrice && productDetail.oldPrice !== ''"
                        >${{ productDetail.oldPrice | slice : 0 : -2 }} per month</span
                      >
                      <div class="rounded old-price-text" *ngIf="productDetail && productDetail.oldPriceText && productDetail.oldPriceText !== ''">
                        <span class="text-red">{{ productDetail.oldPriceText }}</span>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </div>
              <div class="selected-location-box" *ngIf="isLocationCodeMatching">
                <span *ngIf="specialProduct.includes(productDetail?.programId); then leak; else notLeak"></span>
                <ng-template #leak>
                  <div class="pull-left Pricing-terms-leak">
                    <a class="Pricing-terms-link" (click)="goToPaymentOptions()">*Pricing Terms</a>
                  </div>
                  <div class="pull-right MR-10 LH-15">
                    <label class="og-label">Based on your location</label>
                    <span class="selected-area-text pl-1 skiptranslate font-weight-bold font-size-16">
                      <!-- <i aria-hidden="true" class="fa fa-map-marker MR-3"></i>-->{{ selectedAddress?.zipcode }}.</span
                    >
                    <span class="change-zip change-area-link btn-link btn-sm change-link paddM0" (click)="changeAddress()"> Change </span>
                  </div>
                </ng-template>
                <ng-template #notLeak>
                  <label class="og-label">Based on your location</label>
                  <span class="selected-area-text pl-1 skiptranslate font-size-16">
                    <!-- <i aria-hidden="true" class="fa fa-map-marker MR-3"></i>-->{{ selectedAddress?.zipcode }}.</span
                  >
                  <span class="change-zip change-area-link btn-link btn-sm change-link paddM0" (click)="changeAddress()"> Change </span>
                </ng-template>
              </div>
            </div>
            <div
              class="enroll-box enroll-now-box"
              *ngIf="productDetail?.isAvailableOnline || productDetail?.program.programCode.toUpperCase() === 'SURGE'"
            >
              <!-- HWR - 2800 04 Jul -->
              <button
                class="btn ga-track-enroll-now enroll-now-btn skiptranslate"
                [disabled]="disableButton || !selectedAddress?.zipcode"
                [ngClass]="{ 'disabled-btn': disableButton || !selectedAddress?.zipcode }"
                (click)="addProductToCart(productDetail)"
                *ngIf="productType === 'product' && !specialProduct.includes(productDetail?.programId)"
                title="Add to Cart"
              >
                Add to Cart
              </button>
              <button
                class="btn ga-track-enroll-now enroll-now-btn skiptranslate"
                [disabled]="disableButton || !selectedAddress?.zipcode"
                [ngClass]="{ 'disabled-btn': disableButton || !selectedAddress?.zipcode }"
                (click)="addProductToCart(productDetail)"
                *ngIf="productType === 'product' && specialProduct.includes(productDetail?.programId)"
                title="Buy Now"
              >
                Buy Now
              </button>
              <!-- HWR - 2800 04 Jul -->
            </div>
            <div class="description-heading-text-content" [innerHTML]="description"></div>
            <div *ngIf="productDetail?.protectionLimits?.length" class="middle-section">
              <div class="col-12 PD-0">
                <ul>
                  <li *ngFor="let data of productDetail?.protectionLimits">{{ data.value }}</li>
                </ul>
              </div>
            </div>
            <!-- HWR-3670: Product details page - Start --->
            <hos-product-covered-repairs [productDetail]="productDetail"></hos-product-covered-repairs>
            <!-- HWR-3670: Product details page - End --->
            <div class="blog-link">
              <a
                class="blog-link-anchor"
                *ngIf="productDetail?.customerReviewLinkText?.length && productDetail?.customerReviewLink?.length > 0"
                target="_blank"
                href="{{ productDetail?.customerReviewLink }}"
              >
                {{ productDetail?.customerReviewLinkText }}
              </a>
              <span
                class="blog-link-text"
                *ngIf="productDetail?.customerReviewLinkText?.length && productDetail?.customerReviewLink?.length <= 0"
                [innerHTML]="productDetail?.customerReviewLinkText"
              >
              </span>
            </div>
          </div>
        </section>
      </div>
    </div>
  </article>
</section>
<!-- <div class="loading overlay desktop-only" *ngIf="!selectedAddress?.zipcode && productType==='product'"></div> -->
<section *ngIf="globalVariables.isAllReviewsClicked">
  <hos-all-product-reviews [productDetail]="productDetail" class="prod-review"></hos-all-product-reviews>
</section>
<div [ngClass]="{ loading: showLoader }"></div>
<div class="back-to-top" *ngIf="fixedHeader" (click)="scrollToTop()"></div>
