import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SimpleModalComponent, SimpleModalService } from 'ngx-simple-modal';
import { nanoid } from 'nanoid';
import { environment } from '../../../../environments/environment';
import { UserService } from '../../services/user.service';
import { OrdersService } from '../../../payment-ets/services/orders.service';
import { AlertConfirmModalComponent } from '../../../core/component/alert-confirm-modal/alert-confirm-modal.component';
import { ErrorModalComponent } from '../../../core/component/error-modal/error-modal.component';
import { CrmProxyService } from '../../../core/services/crm-proxy.service';
import { LocationService } from '../../../home/services/location.service';
import { GlobalVariables } from '../../../core/services/global-variables.service';
import { SessionStorageService } from '../../../core/services/session-storage.service';
import { PaymentMethod, PaymentPayload, Stripe } from '../../../models/payment/stripe';

declare let hp: any;

export interface EnrollmentModel {
  crmEnrollmentGuid: String;
  OtherGuid?: String;
  userAllDetails?: any;
}

@Component({
  selector: 'hos-manage-payment',
  templateUrl: './manage-payment.component.html',
  styleUrls: ['./manage-payment.component.scss'],
})
export class ManagePaymentComponent extends SimpleModalComponent<EnrollmentModel, Boolean> implements EnrollmentModel, OnInit {
  cuid: String;

  crmPaymentPlanId: String;

  crmCustomerGuid: string;

  financialInstrumentToken: String;

  crmEnrollmentGuid: String;

  isLoggedIn: String = 'true';

  paymentCards: any = [];

  achCards: any = [];

  setCard: any;

  setCardRequired: Boolean = true;

  paymentType: String;

  products: any;

  user: any;

  showLoader: Boolean = false;

  customerServiceNumber: String;

  currentDate: Date = new Date();

  selectPaymentOption: Boolean = false;

  userDetails: any;

  OtherGuid: String;

  sessionTokenGenerated: boolean = true;

  payment = (window as any).PaymentHubWidget;

  userAllDetails: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private ordersService: OrdersService,
    private router: Router,
    private simpleModalService: SimpleModalService,
    private crmProxyService: CrmProxyService,
    private locationService: LocationService,
    public globalVariables: GlobalVariables,
    private sessionStorageService: SessionStorageService,
  ) {
    super();
    this.customerServiceNumber = environment.customer_service_number;
  }

  ngOnInit() {
    this.getOrderDetails(this.crmEnrollmentGuid);
    this.cuid = nanoid();
    this.showLoader = true;
    this.userDetails = this.userService.getSignedInUser();
    this.user = this.userService.getSignedInUser().user;
  }

  /**
   * Get customer existing cards and ACH accouts.
   * @param done
   */
  getCustomerPayments(done?: any) {
    this.paymentCards = [];
    this.achCards = [];
    this.getEFTCustomerAccount(this.crmCustomerGuid)
      .then((results: any) => {
        let customerGuid;
        if (
          results &&
          results.response &&
          results.response.GetCustomerAccountResponse &&
          results.response.GetCustomerAccountResponse.GetCustomerAccountResult &&
          results.response.GetCustomerAccountResponse.GetCustomerAccountResult.Id
        ) {
          customerGuid = results.response.GetCustomerAccountResponse.GetCustomerAccountResult.Id;
        } else {
          customerGuid = this.crmCustomerGuid;
        }
        if (customerGuid && !this.globalVariables.paymentWidgetStripe) {
          this.userService.getCustomerCards(customerGuid).subscribe(
            data1 => {
              const results1 = data1.body;
              // excluding existing card
              // this.paymentCards = results1.data.creditCardAccounts.creditCardModel;
              // for (let index = 0; index < results1.data.creditCardAccounts.creditCardModel.length; index++) {
              //   if (results1.data.creditCardAccounts.creditCardModel[index].CreditCardToken !== this.financialInstrumentToken) {
              //     this.paymentCards.push(results1.data.creditCardAccounts.creditCardModel[index]);
              //   }
              // }
              this.paymentCards = results1.data.creditCardAccounts.creditCardModel;
              this.achCards = results1.data.achAccounts.AchAccountModel;
              if (!!this.paymentCards && this.paymentCards.length > 0) {
                for (let i = 0; i < this.paymentCards.length; i++) {
                  this.paymentCards[i].ExpirationDate = this.changeExpDate(this.paymentCards[i].ExpirationDate.toString(), 2, '/');
                  this.paymentCards[i].AccountNumberMasked = this.getMaskedAccountNumber(this.paymentCards[i].AccountNumberMasked);
                }
              }
              if ((!!this.paymentCards && this.paymentCards.length > 0) || (!!this.achCards && this.achCards.length > 0)) {
                //
              } else {
                this.setCard = 'newCard';
                this.setCardRequired = false;
              }
              if (done) {
                done(false, true);
              }
            },
            () => {
              this.setCard = 'newCard';
              this.setCardRequired = false;
              if (done) {
                done(true, null);
              }
            },
          );
        } else {
          this.setCard = 'newCard';
          this.setCardRequired = false;
        }
        this.showLoader = false;
      })
      .catch(() => {
        this.setCard = 'newCard';
        this.setCardRequired = false;
        if (done) {
          done(true, null);
        }
      });
  }

  /**
   * Format expiry date.
   * @param str
   * @param index
   * @param value
   */
  changeExpDate(str, index, value) {
    const getCurrentYearPrefix = new Date().getFullYear().toString().slice(0, 2);
    return str.substr(0, index) + value + getCurrentYearPrefix + str.substr(index);
  }

  /**
   * Set existing selected card and payment type.
   * @param cardData
   * @param paymentType
   */
  selectCard(cardData: any, paymentType: string) {
    this.setCard = cardData;
    this.paymentType = paymentType;
  }

  getMaskedAccountNumber(string) {
    return string.substr(string.length - 4, 4);
  }

  getOrderDetails(enrollmentId: String) {
    this.ordersService.getOrderBycrmEnrollmentGuid(enrollmentId).subscribe(data => {
      if (data.status === 200) {
        this.products = data.body;
        if (this.products.length === 0) {
          this.showErrorAlert();
        } else {
          this.crmPaymentPlanId = this.products[0].crmPaymentPlanId ? this.products[0].crmPaymentPlanId : '';
          this.crmCustomerGuid = this.products[0].crmCustomerGuid ? this.products[0].crmCustomerGuid : '';
          this.financialInstrumentToken =
            this.products[0].paymentData && this.products[0].paymentData.financialInstrumentToken
              ? this.products[0].paymentData.financialInstrumentToken
              : '';
          if (this.user._id !== this.products[0].customerId) {
            this.showErrorAlert();
          }
        }
      }

      if (this.products.length !== 0) {
        this.getCustomerPayments(error => {
          if (error) {
            this.showLoader = false;
            // this.showErrorAlert();
          }
        });
        // const serviceAddress = `${this.products[0].address.serviceAddress.streetNumber} ${this.products[0].address.serviceAddress.streetName}, ${this.products[0].address.serviceAddress.city} ${this.products[0].address.serviceAddress.state} ${this.products[0].address.serviceAddress.zipcode}`;
        this.stripeInit();
        // this.paymentBox(this.crmCustomerGuid, serviceAddress, this.products[0].address.serviceAddress.zipcode, this.products[0].address.customerNo);
      }
    });
  }

  paymentBox(customerGuid: String, serviceAddress: any, zipcode: any, cusno: any) {
    if (this.globalVariables.paymentWidgetStripe === 'false') {
      this.sessionTokenGenerated = false;
      this.userService.etsSignInToken().subscribe(
        data => {
          this.sessionTokenGenerated = true;
          const response = data.body;
          if (response.data && response.data.signInResponse && response.data.signInResponse.token) {
            const pluginOptions = {
              baseUrl: environment.hosted_payments_base_url,
              apiKey: response.data.signInResponse.token,
              paymentService: hp.PaymentService.TOKEN,
              paymentTypeOrder: this.products[0].paymentMethod === 'CreditCard' ? [0] : [1],
              saveCustomer: true,
              instrumentId: customerGuid,
              correlationId: cusno,
              billingAddress: {
                addressLine1: serviceAddress,
                postalCode: zipcode,
              },
              transactionId: this.guid(),
              successCallback: resp => {
                if (this.products[0].syncSource !== 'oracle') {
                  if (
                    resp.instrument_type === this.products[0].paymentMethod ||
                    (resp.instrument_type !== 'ACH' && this.products[0].paymentMethod === 'CreditCard')
                  ) {
                    this.updateOrderDetails(resp, 'new');
                  } else {
                    this.offbillPayment(resp, 'new');
                  }
                } else {
                  this.oracleChangePayment(resp, 'new');
                }
              },
              errorCallback: () => {
                this.showFailureAlert();
              },
            };
            (<any>$('#hp')).hp(pluginOptions);
            hp.Utils.reset(pluginOptions);
          }
        },
        () => {
          this.sessionTokenGenerated = true;
          this.showErrorModal('Error', `We Are facing problem with the payment gateway, you won't be able to add new payment method.`);
        },
      );
    }
  }

  updateOrderDetails(planRes: any, cardType: String, paymentType?: String) {
    this.showLoader = true;
    let paymentData;
    const payload: any = {};
    const order: any = {};
    this.userService.etsSignInToken().subscribe(result => {
      const response = result.body;
      if (response.data && response.data.signInResponse && response.data.signInResponse.token) {
        const signInToken = response.data.signInResponse.token;
        if (cardType === 'new') {
          // Check for New Credit Card
          if (planRes.instrument_type !== 'ACH') {
            paymentData = {
              expiryDate: planRes.instrument_expiration_date,
              etsCustomerId: planRes.instrument_id,
              financialInstrumentToken: planRes.customer_token,
              cardType: planRes.instrument_type,
              last4Digits_CC: planRes.instrument_last_four,
              paymentType: 'CC',
              approvalCode: planRes.transaction_approval_code,
              paymentMethod: 'CreditCard',
              token: signInToken,
            };
          } else {
            // Using New ACH Card
            paymentData = {
              etsCustomerId: planRes.instrument_id,
              financialInstrumentToken: planRes.customer_token,
              paymentType: planRes.instrument_type,
              approvalCode: planRes.transaction_approval_code,
              paymentMethod: 'ACH',
              token: signInToken,
            };
          }
        } else if (paymentType === 'CC') {
          // Check for Exist Credit Card
          paymentData = {
            expiryDate: planRes.ExpirationDate,
            etsCustomerId: planRes.CustomerToken,
            financialInstrumentToken: planRes.CreditCardToken,
            cardType: planRes.CardType,
            last4Digits_CC: planRes.AccountNumberMasked,
            paymentType: 'CC',
            approvalCode: '',
            paymentMethod: 'CreditCard',
            token: signInToken,
          };
        } else {
          // Using Exist ACH Card
          paymentData = {
            etsCustomerId: planRes.CustomerToken,
            financialInstrumentToken: planRes.AchAccountToken,
            paymentType: 'ACH',
            paymentMethod: 'ACH',
            approvalCode: '',
            token: signInToken,
          };
        }
      }
      // order object
      order.address = this.products[0].address;
      order.productId = this.products[0].productId;
      order.customerId = this.products[0].customerId;
      order.locationId = this.products[0].locationId;
      order.crmCustomerGuid = this.products[0].crmCustomerGuid;
      order.crmPaymentPlanId = this.products[0].crmPaymentPlanId;
      order.crmEnrollmentGuid = this.products[0].crmEnrollmentGuid;
      order.paymentData = this.products[0].paymentData ? this.products[0].paymentData : {};
      payload.paymentData = paymentData;
      payload.order = order;
      payload.paymentData.cardType = planRes.CardType;
      this.ordersService.changePaymentPlan(payload, this.user._id).subscribe(
        resp => {
          const responseBody = resp.body;
          this.showLoader = false;
          if (responseBody.result === 'SUCCESS') {
            this.showSuccessModel();
          } else if (responseBody.result === 'FAILURE') {
            const trackingId: any = 'ga_track_payment_update_failure';
            this.showFailureAlert(trackingId);
          }
        },
        () => {
          const trackingId: any = 'ga_track_payment_update_failure';
          this.showFailureAlert(trackingId);
        },
      );
    });
  }

  useExistCard() {
    if (this.products[0].syncSource !== 'oracle') {
      if (this.paymentType === this.products[0].paymentMethod || (this.paymentType === 'CC' && this.products[0].paymentMethod === 'CreditCard')) {
        this.updateOrderDetails(this.setCard, 'exist', this.paymentType);
      } else {
        this.offbillPayment(this.setCard);
      }
    } else {
      this.oracleChangePayment(this.setCard, 'exist', this.paymentType);
    }
  }

  guid() {
    return `${this.s4() + this.s4()}-${this.s4()}-${this.s4()}-${this.s4()}-${this.s4()}${this.s4()}${this.s4()}`;
  }

  /**
   * Random 4 character string.
   * @param product
   */
  s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  showFailureAlert(GATrackingId?: any) {
    this.simpleModalService
      .addModal(
        AlertConfirmModalComponent,
        {
          title: 'Payment Information Update Failed',
          message: `<p style="margin-top:-20px;margin-bottom:0;">There was a problem updating your payment information. Please contact Customer Service for further assistance. <b><a href="tel:${this.customerServiceNumber}">${this.customerServiceNumber}</a></b></p>`,
          alertIcon: 'fa fa-exclamation-circle red',
          cancelText: ' ',
          confirmText: 'Close Message',
          confirmBtnId: GATrackingId || 'close',
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: false,
        },
      )
      .subscribe(isConfirmed => {
        if (isConfirmed) {
          this.close();
        }
      });
  }

  showSuccessModel() {
    this.simpleModalService
      .addModal(
        AlertConfirmModalComponent,
        {
          title: 'Payment Information Update Success',
          message: 'Payment information has been updated successfully. There will be no interruption in your protection services.',
          confirmText: 'close',
          alertType: 'info-type',
          confirmBtnId: 'proactive_payment_confirm',
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: false,
        },
      )
      .subscribe(isConfirmed => {
        if (isConfirmed) {
          this.close();
          window.location.reload();
        }
      });
  }

  showErrorAlert(trackAPIGATracking?: any) {
    this.simpleModalService
      .addModal(
        AlertConfirmModalComponent,
        {
          title: 'We have encountered a problem with your enrollment',
          message: `<p style="margin-top:-20px;margin-bottom:0;">Please call customer service to ensure your enrollment has completed and you are protected. <b><a href="tel:${this.customerServiceNumber}">${this.customerServiceNumber}</a></b></p>`,
          alertIcon: 'fa fa-exclamation-circle red',
          cancelText: ' ',
          confirmText: 'Close Message',
          confirmBtnId: trackAPIGATracking || 'close',
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: false,
        },
      )
      .subscribe(isConfirmed => {
        if (isConfirmed) {
          this.close();
        }
      });
  }

  getPaymentOption() {
    this.selectPaymentOption = true;
    this.getOrderDetails(this.crmEnrollmentGuid);
    this.cuid = nanoid();
    this.showLoader = true;
  }

  offbillPayment(newpaymentdetails: any, paymentType?: String) {
    this.showLoader = true;
    const payload: any = {};
    // - from etspayment component
    payload.customerId = this.userDetails.user._id;
    payload.orderId = this.cuid;
    if (paymentType !== 'new') {
      payload.PaymentType = newpaymentdetails.CreditCardToken !== undefined ? 'CC' : 'ACH';
      if (payload.PaymentType === 'CC') {
        payload.FinancialInstrumentToken = newpaymentdetails.CreditCardToken;
        payload.Last4Digits_CC = newpaymentdetails.AccountNumberMasked.substr(newpaymentdetails.AccountNumberMasked.length - 4);
        payload.ExpirationDate = newpaymentdetails.ExpirationDate;
        payload.CardType = newpaymentdetails.CardType;
      } else {
        payload.FinancialInstrumentToken = newpaymentdetails.AchAccountToken;
      }
      payload.etsAccountName = newpaymentdetails.AccountHolderName;
      payload.ETSCustomerId = newpaymentdetails.CustomerToken;
    } else {
      if (newpaymentdetails.instrument_type === 'ACH') {
        payload.PaymentType = newpaymentdetails.instrument_type;
      } else {
        payload.PaymentType = 'CC';
        payload.Last4Digits_CC = newpaymentdetails.instrument_last_four;
        payload.ExpirationDate = newpaymentdetails.instrument_expiration_date;
        payload.CardType = newpaymentdetails.instrument_type;
      }
      payload.FinancialInstrumentToken = newpaymentdetails.instrument_id;
      payload.etsAccountName = newpaymentdetails.customer_name;
      payload.ETSCustomerId = newpaymentdetails.customer_token;
    }
    payload.billingType = 'offbill';
    payload.CardType = newpaymentdetails.CardType;
    payload.orders = [];
    const { enrollmentPrice } = this.products[0];

    delete enrollmentPrice.TaxDetails._id;
    delete enrollmentPrice._id;
    const orderItem: any = {
      changedPaymentMethod: true,
      cartId: this.products[0].cartId,
      cartItemId: this.products[0].cartItemId,
      address: this.products[0].address,
      productId: this.products[0].productId,
      billingFrequency: payload.PaymentType === 'CC' ? this.products[0].billingFrequency : 'Monthly',
      crmCustomerGuid: this.products[0].crmCustomerGuid,
      crmCustomerNumber: this.products[0].crmCustomerNumber,
      enrollmentPrice,
      locationId: this.products[0].locationId,
      partner: this.products[0].partner,
      enrollmentGuid: this.OtherGuid !== undefined ? [this.products[0].crmEnrollmentGuid, this.OtherGuid] : [this.products[0].crmEnrollmentGuid],
      isAutoRenew: false,
    };
    if (this.products[0].billingFrequency === 'Annual' && payload.PaymentType === 'ACH') {
      Object.keys(orderItem).forEach(key => {
        if (orderItem[key] === undefined) {
          delete orderItem[key];
        }
      });
      payload.orders.push(orderItem);
      this.locationService.getLocationsContent(orderItem.locationId).subscribe(data => {
        const locationcode = data.body.locationCode;
        this.crmProxyService
          .getProductsTax(
            this.products[0].crmCustomerGuid,
            this.products[0].productDetails.marketingCodeId,
            this.products[0].productDetails.programId,
            locationcode,
            payload.PaymentType,
            orderItem.billingFrequency,
          )
          .subscribe(
            productsData => {
              const billingData = productsData.body.data;
              payload.orders[0].enrollmentPrice = billingData.pop();
              this.ordersService.dataFromEts(payload, this.userDetails.user._id).subscribe(
                results => {
                  const resultData = results.body;
                  const responseBody = resultData[0];
                  this.showLoader = false;
                  if (responseBody.data !== null) {
                    this.showSuccessModel();
                  } else if (responseBody.error !== null) {
                    this.showFailureAlert();
                  }
                },
                () => {
                  this.showFailureAlert();
                },
              );
            },
            () => {
              const trackAPIGATracking = 'trackProductTaxBillingAPIFail';
              this.showErrorAlert(trackAPIGATracking);
            },
          );
      });
    } else {
      Object.keys(orderItem).forEach(key => {
        if (orderItem[key] === undefined) {
          delete orderItem[key];
        }
      });
      payload.orders.push(orderItem);
      this.ordersService.dataFromEts(payload, this.userDetails.user._id).subscribe(
        results => {
          const resultData = results.body;
          const responseBody = resultData[0];
          this.showLoader = false;
          if (responseBody.data !== null) {
            this.showSuccessModel();
          } else if (responseBody.error !== null) {
            this.showFailureAlert();
          }
        },
        () => {
          this.showFailureAlert();
        },
      );
    }
  }

  oracleChangePayment(planRes: any, cardType: String, paymentType?: String) {
    this.showLoader = true;
    let paymentData;
    const payload: any = {};
    if (cardType === 'new') {
      // Check for New Credit Card
      if (planRes.instrument_type !== 'ACH') {
        paymentData = {
          expiryDate: planRes.instrument_expiration_date,
          etsCustomerId: planRes.instrument_id,
          financialInstrumentToken: planRes.customer_token,
          cardType: planRes.instrument_type,
          last4Digits_CC: planRes.instrument_last_four,
          paymentType: 'CC',
          approvalCode: planRes.transaction_approval_code,
          paymentMethod: 'CreditCard',
          etsAccountName: planRes.customer_name,
        };
      } else {
        // Using New ACH Card
        paymentData = {
          etsCustomerId: planRes.instrument_id,
          financialInstrumentToken: planRes.customer_token,
          paymentType: planRes.instrument_type,
          approvalCode: planRes.transaction_approval_code,
          paymentMethod: 'ACH',
          achRoutingNo: planRes.instrument_routing_last_four,
          achAccountNo: planRes.instrument_last_four,
          etsAccountName: planRes.customer_name,
        };
      }
    } else if (paymentType === 'CC') {
      // Check for Exist Credit Card
      paymentData = {
        expiryDate: planRes.ExpirationDate,
        etsCustomerId: planRes.CustomerToken,
        financialInstrumentToken: planRes.CreditCardToken,
        cardType: planRes.CardType,
        last4Digits_CC: planRes.AccountNumberMasked,
        paymentType: 'CC',
        approvalCode: '',
        paymentMethod: 'CreditCard',
        etsAccountName: planRes.AccountHolderName,
      };
    } else {
      // Using Exist ACH Card
      paymentData = {
        etsCustomerId: planRes.CustomerToken,
        financialInstrumentToken: planRes.AchAccountToken,
        paymentType: 'ACH',
        paymentMethod: 'ACH',
        approvalCode: '',
        achAccountNo: planRes.AccountNumberMasked.substr(planRes.AccountNumberMasked.length - 4),
        achRoutingNo: planRes.RoutingNumberMasked.substr(planRes.RoutingNumberMasked.length - 4),
        etsAccountName: planRes.AccountHolderName,
      };
    }

    payload.paymentData = paymentData;
    payload.paymentData.cardType = planRes.CardType;
    payload.paymentData.fingerprint = planRes.fingerprint;
    payload.paymentData.bankAccountType = planRes.bankAccountType;
    // payload.order = this.products[0];
    payload.order = {
      address: this.products[0].address,
      customerId: this.products[0].customerId,
      locationId: this.products[0].locationId,
      crmEnrollmentGuid: this.products[0].crmEnrollmentGuid,
    };
    payload.paymentData.chargeDate = this.products[0].crmChargeDate;
    payload.paymentData.enrollmentGuid = this.products[0].crmEnrollmentGuid;
    this.ordersService.oracleChangePaymentPlan(payload, this.user._id).subscribe(
      response => {
        const responseBody = response.body;
        this.showLoader = false;
        if (responseBody.result === 'SUCCESS') {
          this.showSuccessModel();
        } else if (responseBody.result === 'FAILURE') {
          const trackingId: any = 'ga_track_payment_update_failure';
          this.showFailureAlert(trackingId);
        }
      },
      () => {
        const trackingId: any = 'ga_track_payment_update_failure';
        this.showFailureAlert(trackingId);
      },
    );
  }

  /**
   * Show error message.
   * @param title
   * @param message
   */
  showErrorModal(title: string, message: string) {
    this.simpleModalService
      .addModal(
        ErrorModalComponent,
        {
          title,
          message,
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: true,
        },
      )
      .subscribe(() => {});
  }

  getEFTCustomerAccount(customerGuid) {
    return new Promise(resolve => {
      let results = {};
      this.userService.getCustomerAccounts(customerGuid).subscribe(
        data => {
          results = data.body.data;
          resolve({
            error: false,
            response: results,
          });
        },
        () => {
          resolve({
            error: true,
            response: {},
          });
        },
      );
    });
  }

  private stripeInit() {
    const stripe = new Stripe();
    const paymentMethods = [PaymentMethod.CARD, PaymentMethod.ACH];
    const element = document.getElementById('stripe-payment-update-payment');
    const payload: PaymentPayload = {
      customer: {
        oracleCustomerId: this.userAllDetails.oracleAccountNumber,
        postalCode: this.products[0].address.serviceAddress.zipcode,
        fullName: `${this.userDetails.user.firstName ?? ''} ${this.userDetails.user.lastName ?? ''}`,
        email: this.userDetails.user.email, // US Bank extra field
        oracleCustomerGuid: this.products[0].address.customerGuid,
      },
      enrollments: [
        {
          referenceNumber: `UPFRONT-${this.products[0].productDetails.marketingCode}-${
            this.userAllDetails.address[0].oracleCustId || this.products[0].address.oracleCustId
          }-01`,
        },
      ],
      mountNode: element,
      paymentMethods,
      disabledPaymentMethodIds: this.products[0].paymentData ? [this.products[0].paymentData.financialInstrumentToken] : [],
    };
    console.log(payload);
    stripe
      .updatePaymentMethod(payload)
      .then(p => {
        this.submitUpdatePaymentInfo(p);
      })
      .catch(error => {
        console.log(error);
        this.showFailureAlert();
      });
  }

  submitUpdatePaymentInfo(response: any) {
    this.oracleChangePayment(response[0], 'new');
  }
}
