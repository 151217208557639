<div id="overlay" *ngIf="showPage">
  <div class="modal-dialog custom-error-alert modal-content modal-background">
    <div class="modal-header modal-custom-header error-modal-custom-header" [ngClass]="'info-type'">
      <div class="info-background mx-auto"></div>
    </div>
    <div class="modal-body">
      <h4 class="mb-3">Oops!! No Internet.</h4>
      <p class="info-txt pt-4 mb-0">Internet connection lost, please try to connect again later.</p>
    </div>
  </div>
</div>
<ng-container>
  <header id="header-sect" [ngClass]="{ 'fixed-nav': fixedHeader }" class="top-bar">
    <hos-select-language *ngIf="isBrowser" [ngClass]="{ 'gt-hide': fixedHeader }"></hos-select-language>
    <hos-top-bar *ngIf="isBrowser"></hos-top-bar>
    <hos-navigation-bar [alertClass]="fixedHeader"></hos-navigation-bar>
  </header>
  <router-outlet *ngIf="isBrowser" (activate)="onActivate()"></router-outlet>
  <hos-right-navigation-menu *ngIf="isBrowser"></hos-right-navigation-menu>
  <hos-footer></hos-footer>
  <div *ngIf="isBrowser" id="backdrop-sidemenu" (click)="closeSideBar()" class="backdrop-sidemenu"></div>
</ng-container>
