<form>
  <div class="modal-dialog custom-error-alert modal-content modal-background">
    <div class="modal-header modal-custom-header error-modal-custom-header info-type">
      <div class="info-background mx-auto"></div>
      <!-- <h4 class="modal-title">
                <div class="{{alertIcon}} pt-1"></div>
                <div class="pl-2">{{title}}</div>
            </h4> -->
    </div>
    <div class="modal-body">
      <h4 class="mb-3">{{ title }}</h4>
      <div class="row pt-3">
        <div class="col-12 mb-3" *ngFor="let service of data">
          <div class="custom-radio" *ngIf="!modalFor">
            <input
              type="radio"
              class="custom-control-input"
              id="{{ service.marketingCodeId }}"
              value="{{ service.marketingCodeId }}"
              name="serviceValidation"
              (click)="selectCode(service.marketingCodeId)"
            />
            <label class="custom-control-label" for="{{ service.marketingCodeId }}">
              <strong>{{
                service.webProgramName ? service.webProgramName : service.customerProgramName ? service.customerProgramName : service.programName
              }}</strong>
            </label>
          </div>
          <div class="custom-radio" *ngIf="modalFor === 1">
            <input
              type="radio"
              class="custom-control-input"
              id="{{ service.name }}"
              value="{{ service.name }}"
              name="serviceValidation"
              (click)="selectCode(service.name)"
            />
            <label class="custom-control-label" for="{{ service.name }}">
              <strong>{{ service.name }}</strong>
            </label>
          </div>
        </div>
        <div class="col-12 mb-3" *ngIf="modalFor === 2">
          <input
            [type]="isView ? 'text' : 'password'"
            class="form-control round-form"
            (blur)="selectCode(password.value)"
            (keypress)="selectCode(password.value)"
            [ngClass]="{ 'is-invalid': (isClicked || password.isBlur) && !password.valid }"
            [pattern]="password_regex"
            name="password"
            required
            ngModel
            #password="ngModel"
          />
          <span (click)="isView = !isView" class="fa {{ isView ? 'fa-eye' : 'fa-eye-slash' }} pwd-toggle"></span>
        </div>
        <div class="col-12 mb-3" *ngIf="modalFor === 3">
          <input
            type="text"
            autocomplete="off"
            class="form-control round-form"
            (keypress)="emailKeyPressHandler(emailInput)"
            [ngClass]="{ 'is-invalid': emailInput.isBlur && !emailInput.valid }"
            [pattern]="email_regex"
            name="email"
            required
            [(ngModel)]="email"
            #emailInput="ngModel"
          />
          <small id="emailHelp" class="form-text text-danger" *ngIf="isExistingUser && emailInput.valid"
            >The email address you have entered is already registered</small
          >
          <small id="emailHelp" class="form-text text-danger" *ngIf="emailInput.isBlur && !emailInput.valid">Please enter valid email address.</small>
        </div>
        <div class="col-12" *ngIf="error">
          <label class="text-danger">{{ msg }}</label>
        </div>
      </div>
    </div>

    <div class="modal-footer">
      <div class="primary-link cursor-pointer">
        <a (click)="closeModal()"> {{ cancelText || 'Cancel' }} </a>
      </div>
      <div>
        <button type="submit" (click)="confirmHandler()" class="submit-btn block full-width app-btn mt-0">{{ confirmText || 'Ok' }}</button>
      </div>
    </div>
  </div>
</form>
