import { Component } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';

export interface AddressValidationModel {
  inputAddress: any;
  suggestedAddress: any;
}

@Component({
  selector: 'hos-address-validation-modal',
  templateUrl: './address-validation-modal.component.html',
  styleUrls: ['./address-validation-modal.component.scss'],
})
export class AddressValidationModalComponent extends SimpleModalComponent<AddressValidationModel, any> implements AddressValidationModel {
  inputAddress: any;

  suggestedAddress: any;

  confirmHandler() {
    // var Streetname = this.suggestedAddress.components.street_predirection + ' ' + this.suggestedAddress.components.street_name + ' ' + this.suggestedAddress.components.street_suffix;
    this.result = {
      streetNumber: this.suggestedAddress.delivery_line_1,
      streetName: this.suggestedAddress.delivery_line_2 ? this.suggestedAddress.delivery_line_2 : '',
      city: this.suggestedAddress.components.city_name,
      state: this.suggestedAddress.components.state_abbreviation,
      zipcode: this.suggestedAddress.components.zipcode,
    };
    this.close();
  }
}
