import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class WindowScrolling {
  private styleElemTag: HTMLStyleElement;

  isbrowser: boolean = true;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    this.isbrowser = isPlatformBrowser(platformId);
    this.styleElemTag = this.buildStyleElement();
  }

  // Disable the scrolling feature on the main viewport.
  public disable(): void {
    document.body.appendChild(this.styleElemTag);
  }

  // Re-enable the scrolling feature on the main viewport.
  public enable(): void {
    if (document.body.contains(this.styleElemTag)) {
      document.body.removeChild(this.styleElemTag);
    }
  }

  private buildStyleElement(): HTMLStyleElement {
    if (!this.isbrowser) {
      return;
    }
    const style = document.createElement('style');
    style.type = 'text/css';
    style.setAttribute('data-debug', 'Injected by WindowScrolling service.');
    style.textContent = `
			body {
				overflow: hidden !important ;
			}
		`;
    return style;
  }
}
