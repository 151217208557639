import { Component, Inject, OnChanges, OnInit, Optional, PLATFORM_ID } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { SimpleModalComponent, SimpleModalService } from 'ngx-simple-modal';
import { SessionStorageService } from '../../../core/services/session-storage.service';
import { CartService } from '../../../cart/services/cart.service';
import { environment } from '../../../../environments/environment';
import { SeoService } from '../../../core/services/seo.service';
import { ProductService } from '../../services/product.service';
import { ErrorModalComponent } from '../../../core/component/error-modal/error-modal.component';
import { UtilsService } from '../../../core/services/utils.service';
import { LocationService } from '../../../home/services/location.service';
import { WindowScrolling } from '../../../core/services/window-scrolling.service';
import { ProductReviewFormComponent } from '../../../product-details/component/product-review-form/product-review-form.component';
import { AlertConfirmModalComponent } from '../../../core/component/alert-confirm-modal/alert-confirm-modal.component';

export interface ServiceModal {
  service: any;
  locationCodeData?: any;
  isFromCheckout?: Boolean;
}
@Component({
  selector: 'hos-app-upsell-service-detail',
  templateUrl: './upsell-service-detail.component.html',
  styleUrls: ['./upsell-service-detail.component.scss'],
})
export class UpsellServiceDetailComponent extends SimpleModalComponent<ServiceModal, boolean> implements ServiceModal, OnInit, OnChanges {
  service: any;

  upgradeProducts: any;

  myServicePage: Boolean = true;

  zipcode: any;

  locData: any;

  locationId: any;

  showLoader: Boolean = false;

  baseUrl: String;

  baseUrlLoc: String;

  iSLocation: Boolean = false;

  customer_service_number: String;

  specialProduct: Array<String> = [];

  partnerLogo: String;

  logoURL: String;

  programId: any;

  isMicroSite: Number;

  websitePartnerName: String;

  isbrowser: Boolean = true;

  isserver: Boolean = false;

  siteNameParam: any;

  shareIcon: Boolean = false;

  location_code: any;

  locationCodeData: any;

  shareIndex: any = 0;

  shareIconIndexArray: any = [];

  setOldPriceFlag: boolean = false;

  isFromCheckout: Boolean = false;

  constructor(
    private router: Router,
    public activatedRoute: ActivatedRoute,
    private cartService: CartService,
    private simpleModalService: SimpleModalService,
    private seoService: SeoService,
    private utilsService: UtilsService,
    private sessionStorageService: SessionStorageService,
    private datePipe: DatePipe,
    private productService: ProductService,
    private locationService: LocationService,
    private windowScrolling: WindowScrolling,
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Optional() @Inject('serverUrl') protected origin: string,
  ) {
    super();
    this.specialProduct = environment.special_product;
    this.partnerLogo = this.sessionStorageService.getItem('partnerLogo');
    this.logoURL = this.sessionStorageService.getItem('logoURL');
    this.isMicroSite = +this.sessionStorageService.getItem('isMicroSite');
    this.websitePartnerName = this.sessionStorageService.getItem('websitePartnerName');
    // this.windowScrolling.disable();
    this.isbrowser = isPlatformBrowser(platformId);
    this.isserver = isPlatformServer(platformId);
  }

  ngOnInit() {
    this.zipcode = this.service.zipcode;
    this.locData = this.service.zipcode;
    this.locationId = this.service.locationId;

    this.service.productId = this.service._id;
    this.customer_service_number = environment.customer_service_number;
    this.baseUrl = `${environment.Image_API_Endpoint}${environment.Product_service_API_Endpoint}`;
    this.baseUrlLoc = `${environment.Image_API_Endpoint}${environment.Location_service_API_Endpoint}`;
    if (this.locationId) {
      this.iSLocation = true;
    }
    this.showLoader = true;
    // upgrade and upsell is same concept
    if (this.service.customerNo) {
      const cartCheckoutData: any = this.sessionStorageService.getItem('cart', true);
      const { customerId } = cartCheckoutData;
      let cartProgramid = [];
      if (cartCheckoutData) {
        cartProgramid = cartCheckoutData.items.map(item => {
          let { programId } = item.details.program;
          if (item.details.coverageType === 'UNLIMITED') {
            programId = `unlimited-${item.details.program.programId}`;
          }

          return programId;
        });
      }

      this.programId = this.service.program.programId;
      if (this.service.coverageType === 'UNLIMITED') {
        this.programId = `unlimited-${this.service.program.programId}`;
      }

      this.productService.getUpsellPopupData(this.programId, this.service.locationId, cartProgramid, this.service.customerNo, customerId).subscribe(
        resData => {
          this.upgradeProducts = resData.body.result;
          this.upgradeProducts.forEach(product => {
            product.customerNo = this.service.customerNo;
          });
          if (this.upgradeProducts.length > 0) {
            this.showOldPriceContainer(this.upgradeProducts);
            for (let i = 0; i < this.upgradeProducts.length; i++) {
              this.shareIconIndexArray.push({
                value: false,
              });
            }
          }

          this.showLoader = false;
        },
        () => {
          this.showLoader = false;
          this.upgradeProducts = [];
          // error handling
        },
      );
    } else {
      this.upgradeProducts = this.service;
      this.showLoader = false;
    }
  }

  ngOnChanges() {
    this.partnerLogo = this.sessionStorageService.getItem('partnerLogo');
    this.logoURL = this.sessionStorageService.getItem('logoURL');
  }

  showErrorModal(title: string, message: string, confirmationMsg?: string) {
    this.simpleModalService
      .addModal(
        ErrorModalComponent,
        {
          title,
          message,
          confirmationMsg,
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: true,
        },
      )
      .subscribe(() => {});
  }

  confirmHandler() {
    this.close();
    this.windowScrolling.enable();
    if (this.isFromCheckout) {
      if (this.router.url.indexOf('checkout') > -1) {
        window.location.reload();
      } else {
        this.router.navigate(['/user/checkout']);
      }
    } else {
      this.router.navigate(['/payment'], {
        queryParams: {
          section: 'cart',
        },
      });
    }
  }

  addProductToCart(product) {
    this.service.productId = this.service._id;
    product.removeProduct = this.service;
    this.result = product;
    this.cartService.ecommerceEventsForGA(this.result, 'addUpgradeToCart');
    this.close();
  }

  learnMoreHandler(product: any) {
    this.close();
    window.scrollTo(0, 0);
    const data = this.locationService.getZipcodeAndLocation();
    const existingData = JSON.parse(this.sessionStorageService.getItem('selectedAddress'));
    if (!existingData && this.zipcode !== undefined) {
      const selectedAddress = {
        formatted_address: this.zipcode,
        rawData: '',
        zipcode: this.zipcode,
      };
      this.sessionStorageService.setItem('selectedAddress', JSON.stringify(selectedAddress));
    }
    if (data) {
      this.zipcode = this.zipcode ? this.zipcode : data.zipcode;
    }
    this.locationId = this.locationId ? this.locationId : data.location_code;
    const productContactType = environment.types.filter(item => item.id === product.contactType)[0].type.toLowerCase();
    /** HWR - 2696 Start - get locationCode based on location id  */
    if (this.zipcode && productContactType && this.locationId) {
      this.locationService.getLocationsContent(this.locationId).subscribe(res => {
        const locationCodeDat = res.body.locationCode;
        this.router.navigate([
          `/product/${this.zipcode}/${locationCodeDat.toLowerCase()}/${productContactType}/${product.webProgramName
            .toString()
            .split(' ')
            .join('-')
            .split(',')
            .join('')
            .toLowerCase()}`,
        ]);
      });
    } else {
      this.router.navigate(['/404']);
    }
    /** HWR - 2696 Start - get locationCode based on location id  */
  }

  getImage(imageName) {
    return this.utilsService.buildImageUrl(imageName);
  }

  closeModal() {
    this.close();
    this.windowScrolling.enable();
  }

  showSocialIcons(index) {
    this.shareIndex = this.shareIconIndexArray.map(e => e.value).indexOf(true);
    this.shareIconIndexArray.forEach(element => {
      element.value = false;
    });
    if (index || index === 0) {
      this.shareIconIndexArray[index].value = true;
      if (index !== this.shareIndex) {
        this.shareIcon = false;
      }
    }
    this.shareIcon = !this.shareIcon;
  }

  learnMoreNativeLink(product: any, link: any) {
    let url;
    let originUrl;
    const zipcodeParams = this.route.snapshot.params.zipcode || this.zipcode;
    const typeParams = product.contactType === 1 ? 'residential' : 'commercial';
    const locationcodeParams = this.locationCodeData.locationCode.toLowerCase();
    this.siteNameParam = this.sessionStorageService.getItem('providerState');
    if (this.isbrowser) {
      originUrl = window.location.origin;
    }
    if (this.isserver) {
      originUrl = this.origin;
    }
    if (this.siteNameParam) {
      url = `${originUrl}/${this.siteNameParam}/product/${zipcodeParams}/${locationcodeParams}/${typeParams}/${product.webProgramName
        .toString()
        .split(' ')
        .join('-')
        .split(',')
        .join('')
        .toLowerCase()}`;
    } else {
      url = `${originUrl}/product/${zipcodeParams}/${locationcodeParams}/${typeParams}/${product.webProgramName
        .toString()
        .split(' ')
        .join('-')
        .split(',')
        .join('')
        .toLowerCase()}`;
    }
    const args = {
      url,
      title: 'Protect your family’s home with this affordable protection plan!',
      description: '',
      image: '',
      via: '',
      tags: '',
    };
    const width = 820;
    const height = 500;
    const left = Math.floor((screen.width - width) / 2);
    const top = Math.floor((screen.height - height) / 4);
    let params = `width=${width}, height=${height}`;
    params += `, top=${top}, left=${left}`;
    params += ', directories=no';
    params += ', location=no';
    params += ', menubar=no';
    params += ', resizable=no';
    params += ', scrollbars=no';
    params += ', status=no';
    params += ', toolbar=no';
    switch (link) {
      case 'fb':
        this.popupfacebook(args, params);
        break;
      case 'twitter':
        this.popuptweet(args, params);
        break;
      case 'linkedin':
        this.popuplinkedin(args, params);
        break;
      case 'pinterest':
        this.popupPinterest(args, params);
        break;
      case 'mail':
        this.popupMail(args);
        break;
      default:
    }
    // }
  }

  /* Facebook Share Popup */
  popupfacebook(args, screenParams) {
    /* Passing encodeURIComponent as fix of truncate url */
    let shareUrl = 'https://www.facebook.com/sharer/sharer.php';
    shareUrl += `?u=${args.url}`;
    if (args.title) {
      shareUrl += `&title=${args.title}`;
    }
    if (args.description) {
      shareUrl += `&description=${args.description}`;
    }
    if (args.image) {
      shareUrl += `&picture=${args.image}`;
    }
    const newwindow = window.open(shareUrl, 'name', screenParams);
    if (window.focus) {
      newwindow.focus();
    }
  }

  /* Twitter Share Popup */
  popuptweet(args, screenParams) {
    /* Passing encodeURIComponent as fix of truncate url */
    let shareUrl = 'https://twitter.com/intent/tweet';
    shareUrl += `?url=${encodeURIComponent(args.url)}`;
    if (args.title) {
      shareUrl += `&text=${args.title}`;
    }
    if (args.via) {
      shareUrl += `&via=${args.via}`;
    }
    if (args.tags) {
      shareUrl += `&hashtags=${args.tags}`;
    }
    const newwindow = window.open(shareUrl, 'name', screenParams);
    if (window.focus) {
      newwindow.focus();
    }
  }

  /* Linked In Share Popup */
  popuplinkedin(args, screenParams) {
    /* Passing encodeURIComponent as fix of truncate url */

    let shareUrl = 'http://www.linkedin.com/shareArticle';
    shareUrl += `?url=${args.url}`;
    if (args.title) {
      shareUrl += `&title=${args.title}`;
    }
    if (args.description) {
      shareUrl += `&summary=${args.description}`;
    }
    const newwindow = window.open(shareUrl, 'name', screenParams);
    if (window.focus) {
      newwindow.focus();
    }
  }

  /* Pinterest Save Popup */
  popupPinterest(args, screenParams) {
    /* Need to add a visible image for it to show in pnterest (Append the url in media query parameter) */
    /* Passing encodeURIComponent as fix of truncate url */
    let pinterestUrl = `https://pinterest.com/pin/create/button/?url=${args.url}`;
    // if text is not provided, pin button won't work.
    if (args.description) {
      pinterestUrl += `&description=${args.description}`;
    } else if (document) {
      const descElm = document.querySelector('meta[property="og:description"]');
      if (descElm) {
        pinterestUrl += `&description=${descElm.getAttribute('content')}`;
      }
    }
    if (args.image) {
      pinterestUrl += `&media=${args.image}`;
    } else if (document) {
      const imageElm = document.querySelector('meta[property="og:image"]');
      if (imageElm) {
        pinterestUrl += `&media=${imageElm.getAttribute('content')}`;
      }
    }
    const newwindow = window.open(pinterestUrl, 'name', screenParams);
    if (window.focus) {
      newwindow.focus();
    }
  }

  /* Mail to Popup */
  popupMail(args) {
    /* Passing encodeURIComponent as fix of truncate url */
    const mailUrl = `mailto:?subject=Check out this product&body=Protect your family%27s home with this affordable protection plan%21  %3A  ${encodeURIComponent(
      args.url,
    )}`;
    const newwindow = window.open(mailUrl);
    if (window.focus) {
      newwindow.focus();
    }
  }

  /* Dialog Modal Open Review Form */
  openCreateForm(productReview) {
    const programName =
      productReview.coverageType && productReview.coverageType === 'UNLIMITED'
        ? `Unlimited ${productReview.programName}`
        : productReview.programName
        ? productReview.programName
        : ' ';
    const programId =
      productReview.coverageType && productReview.coverageType === 'UNLIMITED' ? `unlimited-${productReview.programId}` : productReview.programId;
    this.simpleModalService
      .addModal(
        ProductReviewFormComponent,
        {
          programName,
          programId,
          reviews: [],
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: true,
        },
      )
      .subscribe(result => {
        if (result === true && result !== undefined) {
          const successModal = this.simpleModalService
            .addModal(
              AlertConfirmModalComponent,
              {
                title: '',
                message: '<p style="text-align:center;font-size: 24px;font-weight: 500;">Review has been submitted successfully!</p>',
                alertIcon: 'fa fa-info-circle',
                confirmText: 'CLOSE',
              },
              {
                wrapperClass: 'modal-main-class in',
                closeOnClickOutside: true,
              },
            )
            .subscribe(() => {});
          setTimeout(() => {
            successModal.unsubscribe();
          }, 5000);
        } else if (result !== undefined) {
          const errorModal = this.simpleModalService
            .addModal(
              ErrorModalComponent,
              {
                title: '',
                message: `<p style="text-align:center;font-size: 24px;font-weight: 500;">${result}.</p>`,
                confirmationMsg: 'CLOSE',
                alertType: 'info-type',
              },
              {
                wrapperClass: 'modal-main-class in',
                closeOnClickOutside: true,
              },
            )
            .subscribe(() => {});
          setTimeout(() => {
            errorModal.unsubscribe();
          }, 5000);
        }
        this.windowScrolling.enable();
      });
  }

  showOldPriceContainer(products) {
    if (products) {
      products.forEach(item => {
        if (item.oldPrice || (item.oldPriceText && !['', undefined, 'undefined', null, 'null'].includes(item.oldPriceText))) {
          this.setOldPriceFlag = true;
        }
      });
    }
  }

  getProductCardImage(product) {
    const imageUrl = product.upsellProgramCardImgUrl
      ? this.baseUrl + product.upsellProgramCardImgUrl
      : product.defaultProgramCardImgUrl
      ? product.defaultProgramCardImgUrl.startsWith('programDetailsBgImage/Default')
        ? `../../../../img/${product.defaultProgramCardImgUrl}`
        : this.baseUrl + product.defaultProgramCardImgUrl
      : `../../../../img/${product.defaultProgramCardImgUrl}`;
    return this.utilsService.buildImageUrl(imageUrl);
  }
}
