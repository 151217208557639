<ngu-carousel [inputs]="carouselTile" [dataSource]="products" class="category-tile">
  <ngu-tile *nguCarouselDef="let product of products; let i = index" class="item">
    <hos-product-card-claim-page
      (loader)="loaderValue($event)"
      [product]="product"
      [zipcode]="zipcode"
      [locationId]="locationId"
      [selectedAddress]="selectedAddress"
      [showOldPrice]="setOldPriceFlag"
    ></hos-product-card-claim-page>
  </ngu-tile>
  <span NguCarouselPrev class="cat-slide-icon left-cat-icon" [ngClass]="{ 'hide-slider': products?.length <= 4 }"></span>
  <span NguCarouselNext class="cat-slide-icon right-cat-icon" [ngClass]="{ 'hide-slider': products?.length <= 4 }"></span>
</ngu-carousel>
<div *ngIf="products.length === 0 && services.length > 0; then allenrolled; else noservice"></div>
<ng-template #allenrolled>
  <div class="mt-3 mb-3">
    <small>You're currently enrolled in all available protection programs for your area. Please check back in the future for new programs.</small>
  </div>
</ng-template>
<ng-template #noservice>
  <div class="mt-3 mb-3" *ngIf="products.length === 0">
    <small>There are currently no protection programs available in your area. Please continue to check back for new programs.</small>
  </div>
</ng-template>
<div [ngClass]="{ loading: showLoader }"></div>
