<div *ngIf="!isShowNotFoundComp" class="service-generic-body" [ngClass]="{ 'enable-all-reviews': globalVariables.isAllReviewsClicked }">
  <hos-breadcrumb [config]="breadcrumsConfig"></hos-breadcrumb>
  <hos-product-detail-header
    (locationCodeHandler)="locationCodeHandler()"
    [productType]="'service'"
    [productDetail]="productDetail"
    (divHeightWithScrollEmitter)="setEmitterDivHeight($event)"
  ></hos-product-detail-header>
  <!-- <hos-product-general [productDetail]="productDetail" [productType]="'service'"></hos-product-general> -->
  <div
    *ngIf="productDetail"
    class="col-12 tab general-desc-section"
    id="tabs"
    [ngClass]="{ 'set-top-tabfix': globalVariables.fixedHeaderProdDetails }"
  >
    <div
      class="col-4 tab-div tab-top-tablet-section"
      [ngClass]="{ active: selectedTab === 'review-section' }"
      (click)="scrollToSection($event, 'review-section')"
    >
      <button class="tablinks">Reviews</button>
    </div>
    <div
      class="col-4 tab-div tab-top-tablet-section"
      [ngClass]="{ active: selectedTab === 'faq-section' }"
      (click)="scrollToSection($event, 'faq-section')"
    >
      <button class="tablinks">{{ "FAQ's" }}</button>
    </div>
    <div
      class="col-4 tab-div tab-top-tablet-section"
      [ngClass]="{ active: selectedTab === 'category-section' }"
      (click)="scrollToSection($event, 'category-section')"
    >
      <button class="tablinks">Other Services</button>
    </div>
  </div>
  <div scrollSpy [spiedTags]="['DIV']" id="scroll-spy-id" (sectionChange)="onSectionChange($event)">
    <div
      id="tab-review-section"
      class="testimonial-container"
      [style.padding-top.px]="globalVariables.fixedHeaderProdDetails ? divHeightScrollFromEmitter : 0"
      [ngClass]="{ 'pad-top': globalVariables.fixedHeaderProdDetails }"
    >
      <div id="review-section" [ngClass]="{ 'padding-testimonial': !globalVariables.fixedHeaderProdDetails }">
        <div class="col-12 mobile-title-section" style="margin-bottom: 50px">
          <h2 class="section-head-title font-unbounce-title full-width-mob">
            <span>Reviews</span>
          </h2>
        </div>
        <img class="testimonial-blockquote" src="../../../../img/arrow/testimonial-quote.svg" />
        <div class="testimonial-div-container page-wrap">
          <carousel [showIndicators]="false" (activeSlideChange)="getSlideChangeIndex($event)" data-interval="false">
            <slide class="animated height-100 slideIn" *ngFor="let testimonial of testimonials; let i = index">
              <div class="text-center carousel-inner-item text-white">
                <div class="mt-20">
                  <img
                    class="testimonial-author-prev"
                    title="Testimonial Author Image"
                    [src]="testimonials[i === 0 ? testimonials.length - 1 : i - 1].image"
                    alt="testimonial-author-img"
                  />
                  <img class="testimonial-author-img" [src]="testimonials[testimonialSlideIndex].image" alt="testimonial-author-img" />
                  <img
                    class="testimonial-author-next"
                    [src]="testimonials[i === testimonials.length - 1 ? 0 : i + 1].image"
                    alt="testimonial-author-img"
                  />
                </div>
                <div class="lead">
                  <p class="testimonial-description-text">{{ testimonial.description }}</p>
                  <p class="testimonial-description-author">{{ testimonial.writtenBy }}</p>
                  <p class="testimonial-description-place">{{ testimonial.place }}</p>
                </div>
              </div>
            </slide>
          </carousel>
        </div>
      </div>
    </div>
    <div id="tab-faq-section">
      <hos-product-faq [productDetail]="productDetail"></hos-product-faq>
    </div>
    <div class="padding-bottom-rem"></div>
    <div id="tab-category-section">
      <hos-category [pageName]="'productDetail'"></hos-category>
    </div>
  </div>
</div>
<div *ngIf="isShowNotFoundComp" class="service-generic-body">
  <hos-page-not-found404></hos-page-not-found404>
</div>
