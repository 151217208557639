<div class="modal-dialog custom-error-alert modal-content modal-background">
  <div class="modal-header modal-custom-header error-modal-custom-header">
    <div class="info-background mx-auto">
      <i class="fa fa-info-circle my-auto" aria-hidden="true"></i>
    </div>
    <div class="d-flex justify-content-end">
      <button type="button" class="close set-font" (click)="close()">
        <i class="fa fa-times" aria-hidden="true"></i>
      </button>
    </div>
  </div>
  <div class="modal-body">
    <p class="info-txt">
      <span
        >You're already enrolled in the <strong>{{ enrolledProductName }}</strong> for ${{ enrolledProductPrice }} per month.</span
      >
      <br />
      <!-- <ng-container *ngIf="isNotDuplicateName">
                    <span>and you are opting for <strong>{{ defaultNewCartItemProduct?.webProgramName ? defaultNewCartItemProduct?.webProgramName : defaultNewCartItemProduct?.customerProgramName }}</strong>.</span>
                    <br />
                </ng-container> -->
    </p>
    <p class="info-txt">
      <span
        >We can upgrade your coverage to the <strong>{{ bundleProductName }}</strong> for ${{ bundleProductPrice }} per month.</span
      >
    </p>

    <div class="card">
      <div class="product-list-div">
        <span (click)="confirmHandler()" class="product-list-name d-flex-al-items-center">YES, UPGRADE MY COVERAGE!</span>
        <br />
      </div>
    </div>

    <div class="d-flex-al-items-center">
      <div class="text-center">
        <!-- <img class="icon-style" [src]="getImage(baseUrl + cartItems?.programIconUrl)">  -->
        <span (click)="close()" class="margin-left-5 bundle-detail">No, thank you. I prefer to keep my current coverage.</span>
      </div>
    </div>
  </div>
</div>
