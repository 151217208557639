import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ServicesGenericDetailsBodyComponent } from './component/body/body.component';
import { ServiceLandingComponent } from './component/services-landing/service-landing.component';

export const servicesGenericDetailRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: ServiceLandingComponent,
      },
      {
        path: 'zipcode/:zipcode',
        component: ServiceLandingComponent,
      },
      {
        path: ':serviceName/:serviceId',
        component: ServicesGenericDetailsBodyComponent,
      },
      {
        path: ':serviceName',
        component: ServicesGenericDetailsBodyComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(servicesGenericDetailRoutes)],
  exports: [RouterModule],
})
export class ServicesGenericDetailRoutingModule {}
