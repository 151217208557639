import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable()
export class UtilityProviderService {
  constructor(private router: Router, private http: HttpClient) {}

  getProvider(zipcode: any) {
    return this.http
      .get(`${environment.API_Endpoint}sync-service/utility_providers/zip_code/${zipcode}`)
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  private handleError(error: any): Promise<any> {
    return Promise.reject(error.message || error);
  }
}
