import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent, SimpleModalService } from 'ngx-simple-modal';
import { environment } from '../../../../../environments/environment';
import { UtilsService } from '../../../../core/services/utils.service';

export interface UpgradeServiceModalModel {
  enrolledProducts?: any;
  cartItems?: any;
  cartPayload?: any;
  bundledData?: any;
}

@Component({
  selector: 'hos-checkout-upgrade-service-modal',
  templateUrl: './upgrade-service-modal.component.html',
  styleUrls: ['./upgrade-service-modal.component.scss'],
})
export class UpgradeServiceModalComponent extends SimpleModalComponent<UpgradeServiceModalModel, any> implements UpgradeServiceModalModel, OnInit {
  enrolledProducts?: any;

  cartItems?: any;

  cartPayload?: any;

  bundledData?: any;

  enrolledProductName: string = '';

  bundleProductName: string = '';

  baseUrl: string = '';

  enrollmentGuids: any = [];

  enrolledProductsList: any = [];

  defaultNewCartItemProduct: any;

  newUpgradeCartProducts?: any = [];

  isNotDuplicateName: boolean = false;

  bundleProductPrice: string = null;

  enrolledProductPrice: string = null;

  upgradeBundle;

  constructor(simpleModalService: SimpleModalService, private utilsService: UtilsService) {
    super();
  }

  ngOnInit() {
    this.baseUrl = `${environment.Image_API_Endpoint}${environment.Product_service_API_Endpoint}`;
    this.upgradeProduct();
  }

  upgradeProduct() {
    const enrolledProducts: any = [];
    const cartProducts: any = [...this.cartItems.program.products];
    const enrolledProductsNameArr = [];
    const enrolledProductsPriceArr = [];
    this.defaultNewCartItemProduct = this.cartItems;

    /** This is to store the enrollmentGuids and the name of the products for display purpose */
    this.enrolledProducts.forEach(enrollProduct => {
      enrolledProducts.push(...enrollProduct.productDetails.program.products);
      this.enrollmentGuids.push(enrollProduct.crmEnrollmentGuid);
      enrolledProductsNameArr.push(
        enrollProduct.productDetails.webProgramName ? enrollProduct.productDetails.webProgramName : enrollProduct.productDetails.customerProgramName,
      );
      enrolledProductsPriceArr.push(enrollProduct.productDetails.monthlyPrice ? Number(enrollProduct.productDetails.monthlyPrice).toFixed(2) : null);
      this.enrolledProductsList.push(enrollProduct);
    });

    const bundleProducts = new Set(this.bundledData[0]?.program.products);

    this.enrollmentGuids = this.enrolledProducts
      .filter(enrollProduct => enrollProduct.productDetails.program.products.every(p => bundleProducts.has(p)))
      .map(p => p.crmEnrollmentGuid);

    const enrolledPlusCartProducts = Array.from(new Set(enrolledProducts.concat(cartProducts)));

    this.bundledData.forEach(bundleProduct => {
      if (
        enrolledPlusCartProducts.every(product => bundleProduct.program.products.includes(product)) &&
        enrolledPlusCartProducts.length <= bundleProduct.program.products.length
      ) {
        this.newUpgradeCartProducts.push(bundleProduct);
      }
    });

    if (this.newUpgradeCartProducts.length) {
      if (this.newUpgradeCartProducts.length > 1) {
        /** This condition is to upgrade the product based on coverageType */
        this.newUpgradeCartProducts.forEach(product => {
          if (product.coverageType === this.defaultNewCartItemProduct.coverageType) {
            this.cartItems = product;
          }
        });
      } else {
        this.cartItems = this.newUpgradeCartProducts[0];
      }
    }

    this.upgradeBundle = this.bundledData[0];
    this.upgradeBundle ??= this.cartItems[0];
    this.renderingProductsName(this.upgradeBundle);
  }

  renderingProductsName(filterUpgrade) {
    const defaultCartItemName = this.defaultNewCartItemProduct.webProgramName
      ? this.defaultNewCartItemProduct.webProgramName
      : this.defaultNewCartItemProduct.customerProgramName;
    const filteredDataArray = [];
    this.enrolledProducts.forEach(item => {
      const filteredProducts = item.productDetails.program.products.filter(filItem => {
        return filterUpgrade.program.products.includes(filItem);
      });
      if (filteredProducts.length > 0) {
        filteredDataArray.push(item);
      }
    });

    this.enrolledProductName = filteredDataArray
      .map(item => (item.productDetails.webProgramName ? item.productDetails.webProgramName : item.productDetails.customerProgramName))
      .join();

    this.enrolledProductPrice = filteredDataArray
      .map(item => (item.productDetails.monthlyPrice ? Number(item.productDetails.monthlyPrice).toFixed(2) : null))
      .join(',$ ');
    this.bundleProductName = filterUpgrade.webProgramName ? filterUpgrade.webProgramName : filterUpgrade.customerProgramName;
    this.bundleProductPrice = filterUpgrade.monthlyPrice ? Number(filterUpgrade.monthlyPrice).toFixed(2) : null;

    /** This is check the name for combo to triple and single to combo/triple upgrade name */
    if (defaultCartItemName !== this.bundleProductName) {
      this.isNotDuplicateName = true;
    }
  }

  getImage(imageName) {
    return this.utilsService.buildImageUrl(imageName);
  }

  confirmHandler() {
    this.cartItems = this.defaultNewCartItemProduct;

    const payload = [
      {
        ...this.cartPayload[0],
        isUpgrade: true,
        enrollmentGuid: this.enrollmentGuids,
        productId: this.upgradeBundle._id,
        _id: this.upgradeBundle._id,
        details: {
          ...this.upgradeBundle,
          count: undefined,
          isPossible: undefined,
          customerNo: this.cartPayload[0].customerNo,
          isBundle: this.upgradeBundle.program.products.length > 1,
          isUpgrade: true,
          zipcode: this.cartPayload[0].zipcode,
          locationId: this.cartPayload[0].locationId,
        },
      },
    ];

    this.result = {
      payload,
    };
    this.close();
  }
}
