import { Component } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';

export interface CCPAUpdatesModalModel {
  showButton?: string;
  alertIcon?: string;
  alertType?: string;
  title?: string;
  message?: string;
}

@Component({
  selector: 'hos-ccpa-updates',
  templateUrl: './ccpa-updates.component.html',
  styleUrls: ['./ccpa-updates.component.scss'],
})
export class CCPAUpdatesModalComponent extends SimpleModalComponent<CCPAUpdatesModalModel, Boolean> implements CCPAUpdatesModalModel {
  showButton: string;

  alertIcon?: string;

  alertType?: string;

  title?: string;

  message?: string;

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() {
    super();
  }

  confirmHandler() {
    this.close();
  }
}
