import { Injectable } from '@angular/core';

export interface PayloadItems {
  item_id: any;
  location_id: any;
  currency: string;
  quantity: number;
  item_name?: string;
  billingType?: string;
  price?: string;
  transaction_id?: string;
  webEnrollmentId?: string;
}
@Injectable()
export class GtagPayloadGeneratorService {
  generatingPayloadsForGA(products: any, singleProductPrice?: any, isOnBill?: any) {
    const items = [];

    if (products.length > 0) {
      /** This is for Multiple Product when Cart is Viewed or Purchase is made via QC or Normal Checkout */
      // eslint-disable-next-line array-callback-return
      products.map(product => {
        const marketingCode = product.details?.marketingCode ?? product.productDetails?.marketingCode ?? '';

        const payloadItems: PayloadItems = {
          item_id: marketingCode,
          location_id: product.locationId,
          currency: 'USD',
          quantity: 1,
        };

        if (product.details) {
          payloadItems.item_name = product.details.customerProgramName ? product.details.customerProgramName : product.details.webProgramName;
          payloadItems.billingType = isOnBill ? 'onbill' : product.details.billingType;
          payloadItems.price = parseFloat(product.details.monthlyPrice).toFixed(2).toString();
        } else {
          payloadItems.item_name = product.productDetails.customerProgramName
            ? product.productDetails.customerProgramName
            : product.productDetails.webProgramName;
          payloadItems.billingType = isOnBill ? 'onbill' : product.productDetails.billingType;
          payloadItems.price = parseFloat(product.productDetails.monthlyPrice).toFixed(2).toString();
        }

        if (product.orderId) {
          payloadItems.transaction_id = product.orderId;
        }

        if (product.webEnrollmentId) {
          payloadItems.webEnrollmentId = product.webEnrollmentId;
        }

        items.push(payloadItems);
      });
    } else {
      /** This is for Single Product when it is added in cart via QC or Normal Adding of Products in cart */
      const marketingCode = products.details?.marketingCode ?? products.marketingCode ?? '';
      const webEnrollmentId = products.details?.webEnrollmentId || products.webEnrollmentId || '';
      const payloadItems: PayloadItems = {
        item_id: marketingCode,
        location_id: products.locationId,
        price: singleProductPrice.toFixed(2).toString(),
        currency: 'USD',
        quantity: 1,
      };

      if (products.details) {
        payloadItems.item_name = products.details.customerProgramName ? products.details.customerProgramName : products.details.webProgramName;
        payloadItems.billingType = isOnBill ? 'onbill' : products.details.billingType;
      } else {
        payloadItems.item_name = products.customerProgramName ? products.customerProgramName : products.webProgramName;
        payloadItems.billingType = isOnBill ? 'onbill' : products.billingType;
      }

      if (webEnrollmentId) {
        payloadItems.webEnrollmentId = webEnrollmentId;
      }

      items.push(payloadItems);
    }

    return items;
  }
}
