<div *ngIf="!globalVariables.showCheckoutPaymentReponsePage && !globalVariables.isSuccessCheckoutWebRegistration" class="checkout-plans page-wrap">
  <form #registerForm="ngForm" novalidate (keypress)="formKeyPressHandler($event)">
    <div class="row ML-0 MR-0 MB-20">
      <div class="col-12 payment-gateway mobile-only margin-20-0">
        <div class="order-summary">
          <button
            class="order-summary__button beige-bg"
            [ngClass]="{ collapsed: !toggleMobileCartVal }"
            data-toggle="collapse"
            data-parent="#cart-accordion"
            href="#cartcollapse"
            aria-expanded="false"
            aria-controls="collapseOne"
            (click)="toggleMobileCart()"
          >
            <div class="cart-text show-summary">
              <img class="order-summary__icon order-summary__icon--cart cart-img-icon" src="../../../../img/Cart-ico.svg" loading="lazy" alt="cart" />
              <span>{{ !toggleMobileCartVal ? 'Show' : 'Hide' }} Order Summary</span>
            </div>
            <span class="order-summary__total-price">${{ productsByBillingType?.grossTotal | number : '1.2-2' }}</span>
          </button>
          <div
            id="cart--dropdown"
            id="cartcollapse"
            class="collapse"
            role="tabpanel"
            data-parent="#cart-accordion"
            class="cart cart--dropdown"
            [ngClass]="{ collapse: !toggleMobileCartVal }"
          >
            <section class="col-12 padding-0-0-0">
              <hos-cart-details
                *ngIf="locationDataFetched"
                tempId="mobile"
                [productsByBillingType]="productsByBillingType"
                [customerGuid]="customerGuid"
                (frequencyChange)="onBillFrequencyChange($event)"
                [showPaymentPage]="showPaymentPage"
                [showLoader]="showLoader"
              ></hos-cart-details>
            </section>
          </div>
        </div>
      </div>
      <div class="col-lg-7 pad-desktop larger-tab-width-cls" *ngIf="!showPaymentPage">
        <section class="content-section content-block register-section">
          <div class="row page-wrap no-gutters MB-0 PD-0">
            <div class="right-form-box row col-12 no-gutters PL-0">
              <div class="row col-12 no-gutters">
                <!-- General information left side area -->
                <div class="general-information left-side step-1 col-12 PR-0">
                  <div class="form-border">
                    <div class="form-header row m-0">
                      <img src="/img/MyProfile/Profile-Ico-01.svg" class="mt-auto px-4" />
                      <span class="my-auto">General Information</span>
                    </div>
                    <div class="py-4 px-4">
                      <div
                        [ngClass]="{
                          'pointer-events-none': globalVariables?.cartCheckout?.items?.length === 0 || (cartProducts && cartProducts.length === 0)
                        }"
                      >
                        <div
                          [ngClass]="{
                            'disable-cls': globalVariables?.cartCheckout?.items?.length === 0 || (cartProducts && cartProducts.length === 0)
                          }"
                        >
                          <div class="row" id="name">
                            <div class="form-group col-12 col-md-6">
                              <label for="selectType">First Name<sup class="text-danger">*</sup></label>
                              <input
                                type="text"
                                class="form-control round-form"
                                [ngClass]="{ 'is-invalid': isClicked && !firstName.valid }"
                                name="firstName"
                                required
                                [(ngModel)]="user.firstName"
                                #firstName="ngModel"
                                maxlength="80"
                                [pattern]="blockUnicodeRegex"
                                (change)="user.firstName = user.firstName.trim()"
                              />
                              <small id="emailHelp" class="form-text text-danger" *ngIf="firstName.touched && firstName.errors?.required"
                                >First name is required.</small
                              >
                              <small id="emailHelp" class="form-text text-danger" *ngIf="firstName.errors && firstName.errors.pattern"
                                >Please remove special characters.</small
                              >
                            </div>

                            <div class="form-group col-12 col-md-6">
                              <label for="selectType">Last Name<sup class="text-danger">*</sup></label>
                              <input
                                type="text"
                                class="form-control round-form"
                                [ngClass]="{ 'is-invalid': isClicked && !lastName.valid }"
                                name="lastName"
                                required
                                [(ngModel)]="user.lastName"
                                #lastName="ngModel"
                                maxlength="80"
                                [pattern]="blockUnicodeRegex"
                                (change)="user.lastName = user.lastName.trim()"
                              />
                              <small id="emailHelp" class="form-text text-danger" *ngIf="lastName.touched && lastName.errors?.required"
                                >Last name is required.</small
                              >
                              <small id="emailHelp" class="form-text text-danger" *ngIf="lastName.errors && lastName.errors.pattern"
                                >Please remove special characters.</small
                              >
                            </div>
                          </div>
                          <hr class="margin-em-hr" />

                          <div class="row" id="email">
                            <div class="form-group col-12 col-md-6">
                              <label for="selectType">Email<sup class="text-danger">*</sup></label>
                              <input
                                type="email"
                                class="form-control round-form"
                                (keypress)="emailKeyPressHandler(email)"
                                (blur)="emailBlurHandler(email)"
                                [ngClass]="{ 'is-invalid': (email.isBlur || isClicked) && !email.valid }"
                                [pattern]="email_regex"
                                name="email"
                                required
                                [(ngModel)]="user.email"
                                #email="ngModel"
                                maxlength="100"
                              />

                              <!-- <small id="emailHelp" class="form-text text-danger"
                                                                *ngIf="isExistingUser && email.valid">The email address
                                                                you
                                                                have
                                                                entered is already registered</small> -->
                              <small id="emailHelp" class="form-text text-danger" *ngIf="email.isBlur && !email.valid"
                                >Please enter valid email address.</small
                              >
                              <small
                                id="emailHelp"
                                class="form-text text-danger"
                                *ngIf="(isClicked && !email.valid && !email.touched) || isDisposableEmail"
                                >Please enter valid email address.</small
                              >
                            </div>
                            <div class="form-group col-12 col-md-6">
                              <label for="selectType">Confirm Email</label>
                              <input
                                type="email"
                                class="form-control round-form"
                                (keypress)="keyPressHandler(confirmEmail)"
                                (blur)="blurHandler(confirmEmail)"
                                name="confirmEmail"
                                [(ngModel)]="confirmEmailId"
                                #confirmEmail="ngModel"
                                maxlength="100"
                              />
                            </div>
                            <div class="form-group col-12 col-md-6">
                              <label for="selectType">Phone Number<sup class="text-danger">*</sup></label>
                              <input
                                type="text"
                                class="form-control round-form"
                                (blur)="phoneBlurHandler(phoneNumber)"
                                (keypress)="keyPressHandler(phoneNumber, 'phoneNumber'); numberOnly($event)"
                                maxlength="10"
                                minlength="10"
                                [ngClass]="{ 'is-invalid': isClicked && !phoneNumber.valid }"
                                [pattern]="phone_number_regex"
                                name="phoneNumber"
                                required
                                [(ngModel)]="user.phoneNumber"
                                #phoneNumber="ngModel"
                              />
                              <small id="emailHelp" class="form-text text-danger" *ngIf="isClicked && !homePhoneErrorMsg && !phoneNumber.valid"
                                >Please enter valid phone number.</small
                              >
                              <small id="emailHelp" class="form-text text-danger" *ngIf="phoneNumber.isBlur || isClicked">{{
                                homePhoneErrorMsg
                              }}</small>
                              <!-- <small id="emailHelp" *ngIf="showPhoneError" class="form-text text-danger">This phone number already exists.</small> -->
                              <!-- HWR-2485 -->
                            </div>
                          </div>
                          <hr class="margin-em-hr" />
                          <div class="row">
                            <div class="form-group col-12 col-md-6">
                              <label for="selectType">Address<sup class="text-danger">*</sup></label>
                              <input
                                type="text"
                                autocomplete="off"
                                class="form-control round-form"
                                [ngClass]="{ 'is-invalid': (streetNumber.dirty || isClicked) && !streetNumber.valid }"
                                pattern="{{ streetNumberValidation }}"
                                name="streetNumber"
                                required
                                [(ngModel)]="user.address[0].serviceAddress.streetNumber"
                                (keyup)="getSuggestion($event)"
                                #streetNumber="ngModel"
                                maxlength="100"
                              />
                              <small id="emailHelp" class="form-text text-danger" *ngIf="(streetNumber.dirty || isClicked) && !streetNumber.valid"
                                ><span *ngIf="streetNumber.errors?.required">Address is required.</span
                                ><span *ngIf="streetNumber.errors?.pattern">Address is invalid.</span></small
                              >
                              <div class="showDropDownWrapper">
                                <div *ngIf="showDropDown" class="search-drop-down">
                                  <div
                                    (click)="selectValue(addr.street_line, addr.city, 1)"
                                    class="search-results"
                                    *ngFor="let addr of suggestedAddress.suggestions"
                                  >
                                    <a [innerHTML]="addr.street_line + ', ' + addr.city"> {{ addr.street_line }}, {{ addr.city }} </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="form-group col-12 col-md-6">
                              <label for="selectType">Apt., Unit, PO Box, etc.</label>
                              <input
                                type="text"
                                autocomplete="off"
                                class="form-control round-form"
                                [ngClass]="{ 'is-invalid': isClicked && !streetName.valid }"
                                name="streetName"
                                [(ngModel)]="user.address[0].serviceAddress.streetName"
                                #streetName="ngModel"
                                maxlength="100"
                              />
                              <small id="emailHelp" class="form-text text-danger" *ngIf="isClicked && !streetName.valid"
                                >Apt., Unit, PO Box, etc. is required.</small
                              >
                            </div>
                            <div class="form-group col-12 col-md-6">
                              <label for="selectType">City<sup class="text-danger">*</sup></label>
                              <input
                                type="text"
                                class="form-control round-form"
                                [ngClass]="{ 'is-invalid': isClicked && !city.valid }"
                                name="city"
                                required
                                [(ngModel)]="user.address[0].serviceAddress.city"
                                #city="ngModel"
                                maxlength="80"
                              />
                              <small id="emailHelp" class="form-text text-danger" *ngIf="isClicked && !city.valid">City address is required.</small>
                            </div>
                            <div class="form-group col-12 col-md-6" [ngClass]="{ '.not-allowed': !isZipcodeEditable }">
                              <label for="selectType">ZIP Code<sup class="text-danger" *ngIf="!isDefaultMkc">*</sup></label>
                              <hos-zipcode
                                *ngIf="zipcodeFromCartCheckout"
                                [isClicked]="isClicked"
                                [address]="zipcodeFromCartCheckout"
                                (notify)="onZipcodeUpdated($event)"
                                [isFromCheckout]="!isZipcodeEditable"
                                [ngClass]="{ 'zipcode-readonly': !isZipcodeEditable }"
                              ></hos-zipcode>
                            </div>
                            <div class="form-group col-12 col-md-6">
                              <label for="selectType">State</label>
                              <ng-select
                                [disabled]="true"
                                [items]="states"
                                bindLabel="value"
                                bindValue="value"
                                (change)="stateChangeHandler($event)"
                                [(ngModel)]="user.address[0].serviceAddress.state"
                                name="state"
                                #state="ngModel"
                                class="skiptranslate state-arrow"
                                required
                              >
                              </ng-select>
                            </div>
                          </div>
                          <label class="form-message">All fields are required</label>
                          <!-- </form> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div class="col-lg-7 payment-gateway margin-30-0" *ngIf="showPaymentPage && productsByBillingType.offbill.products.length > 0">
        <div class="col-12">
          <a
            [routerLink]="['/user/checkout']"
            [queryParams]="{ section: 'cart' }"
            (click)="showPaymentPage = false; isAuthorizedToViewForm = false"
            class="btn btn-link navigate-link m-b-lg text-left desktop-only"
            style="color: #189aca !important; text-decoration: underline"
          >
            <span>&lt; </span> Back To General Information</a
          >
        </div>
        <div class="col-12 address-box-container">
          <div class="address-box">
            <p class="user-name">{{ user.firstName }}&nbsp;{{ user.lastName }}</p>
            <div><label>Service Address</label></div>
            <div class="address skiptranslate">
              <span>
                {{ user.address[0].serviceAddress.streetNumber }} {{ user.address[0].serviceAddress.streetName }},
                {{ user.address[0].serviceAddress.city }}, {{ user.address[0].serviceAddress.state }}, {{ user.address[0].serviceAddress.zipcode }}
              </span>
            </div>
          </div>
        </div>
        <div *ngIf="(customerDetails || existingUser) && productsByBillingType?.offbill?.products?.length > 0" class="col-12 PD-0">
          <hos-quick-checkout-payment-box
            [userInfo]="existingUser && addressPresentIn && !lastNameMismatchFound.length ? existingUser : user"
            [customerDetails]="customerDetails"
            [guestToken]="guestToken"
            [productsByBillingTypeCopy]="productsByBillingType"
          ></hos-quick-checkout-payment-box>
        </div>
      </div>
      <section class="col-lg-5 desktop-only padding-30px-0-0">
        <hos-cart-details
          *ngIf="locationDataFetched"
          tempId="desktop"
          [productsByBillingType]="productsByBillingType"
          [customerGuid]="customerGuid"
          (frequencyChange)="onBillFrequencyChange($event)"
          [showPaymentPage]="showPaymentPage"
        ></hos-cart-details>
      </section>
    </div>
    <!-- <div *ngIf="!showPaymentPage" id="service-address" class="col-12 col-md-12 home-owner">
            <div class="custom-checkbox highlight-checkbox">
                <input type="checkbox" id="authorizeinfo-check" [disabled]="((globalVariables?.cartCheckout?.items?.length === 0) || (cartProducts && cartProducts.length === 0))"
                    name="authorizeinfo-check" [checked]="(globalVariables?.cartCheckout?.items?.length > 0 || productsByBillingType?.offbill?.products?.length > 0 || productsByBillingType?.onbill?.products?.length > 0) && isAuthorizedToViewForm" (click)="checkboxFunction()" autocomplete="off">
                <label for="authorizeinfo-check" class="authorizeinfo-check">By clicking the checkbox you are confirming
                     that you are the homeowner, and your name is on the water bill.</label>
            </div>
        </div>
        <div *ngIf="productsByBillingType?.onbill?.products?.length > 0 && productsByBillingType?.offbill?.products?.length === 0" id="service-address" class="col-12 col-md-12 onbill-auth-msg">
            <div class="custom-checkbox">
                <label for="authorizeinfo-checkout" class="MB-0">By clicking Complete Purchase you are authorizing American water to
                    add
                    these charges to your bill.
                </label>
            </div>
        </div> -->
    <div
      [ngClass]="{ 'disable-cls': globalVariables.cartCheckout['items'].length === 0 }"
      *ngIf="!showPaymentPage && productsByBillingType?.offbill?.products?.length > 0"
      class="col-12 flex-center-cls pad-btn-checkout"
    >
      <button
        [ngClass]="{ 'pointer-events-none': globalVariables.cartCheckout['items'].length === 0 }"
        class="btn btn-sm nav-btn enroll-now skiptranslate"
        (click)="goToStep(registerForm)"
        title="Proceed To Checkout"
        style="
          text-transform: none;
          width: auto;
          border-radius: 5px;
          background: #b83436;
          font-size: 1.125rem;
          text-align: center;
          justify-content: center;
          display: flex;
          margin: 0;
          line-height: 1.3;
        "
      >
        Continue to Payment Method
      </button>
    </div>
    <div
      [ngClass]="{ 'disable-cls': globalVariables.cartCheckout['items'].length === 0 }"
      *ngIf="!showPaymentPage && productsByBillingType?.onbill?.products?.length > 0 && productsByBillingType?.offbill?.products?.length === 0"
      class="col-12 flex-center-cls pad-btn-checkout"
    >
      <button
        [ngClass]="{ 'pointer-events-none': globalVariables.cartCheckout['items'].length === 0 }"
        class="btn btn-sm nav-btn enroll-now skiptranslate"
        (click)="goToStep(registerForm)"
        title="Proceed To Checkout"
        style="
          text-transform: none;
          width: auto;
          border-radius: 5px;
          background: #b83436;
          font-size: 1.125rem;
          text-align: center;
          justify-content: center;
          display: flex;
          margin: 0;
          line-height: 1.3;
        "
      >
        Complete Purchase
      </button>
    </div>
    <div class="noteTextEnroll pad-5-20">
      <!-- HWR-2190 Jan 9 2020 changes start -AP
            <p class="text-justify"><u>Protection Program</u>. If I have one or more Protection Programs in my cart above then by
                clicking &ldquo;Enroll Now&rdquo; above I acknowledge and agree (i) to the Terms and Conditions
                for the Program(s), which can be found at <u><a target="_blank"
                        href="https://www.awrusa.com/terms">awrusa.com/terms</a></u> (ii) that the Program(s) I
                selected will auto-renew on an annual basis until cancelled by me in accordance with the Terms
                and Conditions of the Program(s); (iii) that I meet the eligibility requirements in the Terms
                and Conditions; and (iv) that there is a 30 day waiting period before I can make a claim.
                 There is NO 30-Day waiting period if you are renewing your existing Protection Program(s) before the
                expiration date. To opt
                out of auto-renewal, click the &ldquo;Edit&rdquo; link above and select &ldquo;Do Not Auto
                Renew&rdquo;. </p>
            <p>All amounts are in US dollars. </p>
            HWR-2190 Jan 9 2020 changes end -AP -->
      <!-- HWR-5136 Checkbox Removal and Updated Disclaimer for Checkout -->
      <p class="text-justify">
        I acknowledge that I am the homeowner at the service address responsible for repairs to the covered products or systems, and if my name is on
        the water bill at this service address, that I am responsible for making payments. Additionally, I agree (i) to the Terms and Conditions for
        the Program(s), which can be found at <u><a target="_blank" href="https://www.awrusa.com/terms">awrusa.com/terms</a></u> (ii) that the
        Program(s) I selected will auto-renew on an annual basis until cancelled by me in accordance with the Terms and Conditions of the Program(s);
        (iii) that I meet the eligibility requirements in the Terms and Conditions; and (iv) that there is a 30 day waiting period before I can make a
        claim (unless renewing before the expiration date). To opt out of auto-renewal, click the “Edit” link above and select “Do Not Auto Renew.”
        All amounts are in US dollars.
      </p>
    </div>
  </form>
  <div id="checkout_successful_register" class="btn gaTrackBtn"></div>
  <div id="checkout_order_error" class="btn gaTrackBtn"></div>
  <div id="ets-payment-gateway-checkout" class="btn gaTrackBtn"></div>
</div>
<hos-ets-payment-response
  *ngIf="globalVariables.showCheckoutPaymentReponsePage && !globalVariables.isSuccessCheckoutWebRegistration"
  [customerDetails]="customerDetails"
  [orderId]="globalVariables.checkoutOrderCuid"
  [customerName]="userName"
  [existingCustomerData]="existingCustomerData"
  [passNewRegisterCustomerResponse]="passNewRegisterCustomerResponse"
  [EmailExistedData]="EmailExistedData?.user"
  [addressPresentIn]="addressPresentIn"
></hos-ets-payment-response>
<div class="alert alert-success final-success-alert text-center" role="alert" *ngIf="globalVariables.isSuccessCheckoutWebRegistration">
  <div class="page-wrap row">
    <img src="/img/payment/Message-Ico-Success.svg" class="col-4 col-md-3" />
    <span class="text-msg col-8 col-md-9 text-left">Your web account registration is complete!</span>
  </div>
</div>
<section class="content-section content-block register-section" *ngIf="globalVariables.isSuccessCheckoutWebRegistration">
  <div class="row page-wrap no-gutters">
    <div class="final-form-box step-3">
      <div class="row justify-content-center mt-2 mb-2">
        <div class="col-md-6 content-confirmation">
          <p>
            <strong>Hello {{ existingUser.firstName || user.firstName }} {{ existingUser.lastName || user.lastName }},</strong>
          </p>
          <div class="text-justify">
            <p>Thank for registering your online account. You are all set and can now login to your account!</p>
            <p>You will receive an email to verify your email address.</p>
            <p>If you do not receive the email to your inbox, please be sure to check your spam folder as well.</p>
          </div>
        </div>
        <div class="col-md-6">
          <hos-login-page [isRegisterSucess]="true"></hos-login-page>
        </div>
      </div>
    </div>
    <div class="things-to-do">
      <hos-things-you-can-do [page]="'register'"></hos-things-you-can-do>
    </div>
  </div>
</section>
<div [ngClass]="{ loading: showLoader }"></div>
