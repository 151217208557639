import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CanActivateViaAuthGuard } from '../core/guards/auth.guards';
import { CanDeactivateViaAuthGuard } from '../core/guards/deactivate.guards';

import { RegisterComponent } from './component/register/register.component';
import { ChangePasswordComponent } from './component/change-password/change-password.component';
import { SetPasswordComponent } from './component/set-password/set-password.component';
import { ResetPasswordComponent } from './component/reset-password/reset-password.component';
import { ActivateAccountComponent } from './component/activate-account/activate-account.component';
import { ProfileComponent } from './component/profile/profile.component';
import { AddPropertyComponent } from './component/add-property/add-property.component';
import { PaymentHistoryComponent } from './component/payment-history/payment-history.component';
import { ClaimsSuccessComponent } from './component/claims-success/claims-success.component';
import { LoginPageComponent } from './component/login-page-component/login-page.component';
import { MyAccountNewHomeRedesignComponent } from './component/my-account-new-home-redesign/my-account-new-home-redesign.component';
import { CheckoutComponent } from './component/checkout/checkout.component';
import { IncreasedPriceCusInfoComponent } from './component/increased-price-cus-info/increased-price-cus-info.component';
import { QuickEnrollmentUpdateComponent } from './component/quick-enrollment-update/quick-enrollment-update.component';

export const userRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'register',
        component: RegisterComponent,
        canActivate: [CanDeactivateViaAuthGuard],
      },
      {
        path: 'change-password',
        component: ChangePasswordComponent,
        canActivate: [CanActivateViaAuthGuard],
      },
      {
        path: 'set-password',
        component: SetPasswordComponent,
      },
      {
        path: 'reset-password',
        component: ResetPasswordComponent,
      },
      {
        path: 'email-verify',
        component: ActivateAccountComponent,
      },
      {
        path: 'login',
        component: LoginPageComponent,
      },
      {
        path: 'profile',
        component: ProfileComponent,
        canActivate: [CanActivateViaAuthGuard],
      },
      {
        path: 'add-property',
        component: AddPropertyComponent,
        canActivate: [CanActivateViaAuthGuard],
      },
      {
        path: 'purchase-history',
        component: PaymentHistoryComponent,
        canActivate: [CanActivateViaAuthGuard],
      },
      {
        path: 'claim-success',
        component: ClaimsSuccessComponent,
        canActivate: [CanActivateViaAuthGuard],
      },
      {
        path: 'myaccount',
        component: MyAccountNewHomeRedesignComponent,
        canActivate: [CanActivateViaAuthGuard],
      },
      {
        path: 'checkout',
        component: CheckoutComponent,
        canActivate: [CanDeactivateViaAuthGuard],
      },
      {
        path: 'price-info/:id',
        component: IncreasedPriceCusInfoComponent,
      },
      {
        path: 'price-info',
        component: IncreasedPriceCusInfoComponent,
      },
      {
        path: 'enrollment-update-renewal',
        component: QuickEnrollmentUpdateComponent,
        canActivate: [CanDeactivateViaAuthGuard],
      },
      {
        path: 'enrollment-update-reactivate',
        component: QuickEnrollmentUpdateComponent,
        canActivate: [CanDeactivateViaAuthGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(userRoutes)],
  exports: [RouterModule],
})
export class UsersRoutingModule {}
