import { Component, Input, AfterViewInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { find } from 'lodash-es';
import { SimpleModalComponent, SimpleModalService } from 'ngx-simple-modal';
import { ProductService } from '../../../product/services/product.service';
import { UserService } from '../../../user/services/user.service';
import { environment } from '../../../../environments/environment';
import { SessionStorageService } from '../../../core/services/session-storage.service';
import { GlobalVariables } from '../../../core/services/global-variables.service';
import { AlertService } from '../../../core/services/alert.service';
import { WindowScrolling } from '../../../core/services/window-scrolling.service';

declare let grecaptcha;

export interface ProductReviewFormModel {
  programName: string;
  programId: string;
  reviews: any;
}

@Component({
  selector: 'hos-product-review-form',
  templateUrl: './product-review-form.component.html',
  styleUrls: ['product-review-form.component.scss'],
})
export class ProductReviewFormComponent
  extends SimpleModalComponent<ProductReviewFormModel, Boolean>
  implements ProductReviewFormModel, AfterViewInit
{
  @Input() productDetail: any;

  numbers: number[];

  userDetails: any;

  userAddress: any;

  selectedAddressId: any;

  captchaResponse: String;

  showLoader = false;

  selectedAddress: any;

  programName: string;

  programId: string;

  reviews: any;

  reviewDtlForm: UntypedFormGroup;

  customerName: any;

  copyOfRating: any;

  constructor(
    simpleModalService: SimpleModalService,
    private reviewform: UntypedFormBuilder,
    private productService: ProductService,
    private sessionStorageService: SessionStorageService,
    private userService: UserService,
    private globalVariables: GlobalVariables,
    private alertService: AlertService,
    private windowScrolling: WindowScrolling,
    private router: Router,
  ) {
    super();
    // this.windowScrolling.disable();
    this.selectedAddressId = this.sessionStorageService.getItem('addressId');
    this.userDetails = this.userService.getSignedInUser();
    let firstName: any = '';
    let lastName: any = '';
    if (this.userDetails && this.userDetails.user) {
      this.userService.getAllCustomerAddresses(this.userDetails.user._id).subscribe(data => {
        const serviceAddresses = data.body;
        if (this.selectedAddressId) {
          this.selectedAddress = find(serviceAddresses, {
            _id: this.selectedAddressId,
          });
          this.userAddress = this.selectedAddress.serviceAddress;
        } else {
          this.selectedAddressId = serviceAddresses[0]._id;
          this.selectedAddress = find(serviceAddresses, {
            _id: this.selectedAddressId,
          });
          this.userAddress = this.selectedAddress.serviceAddress;
        }
      });
      if (this.userDetails.user.firstName) {
        firstName = this.toTitleCase(this.userDetails.user.firstName);
      }
      if (this.userDetails.user.lastName) {
        lastName = this.toTitleCase(this.userDetails.user.lastName);
      }
      this.customerName = `${firstName} ${lastName}`;
    }
    this.createForm();
    this.numbers = Array(5)
      .fill(1)
      .map((x, i) => i + 1);
    /*  Close Modal on change of router AP- 2-June */
    this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        this.modalClose();
      }
    });
  }

  /* Create review form validation AP - 2-june */
  createForm() {
    if (this.userDetails && this.userDetails.user) {
      this.reviewDtlForm = this.reviewform.group({
        title: [''],
        description: [''],
        rating: ['5', Validators.required],
        captcha: [null, Validators.required],
      });
    } else {
      this.reviewDtlForm = this.reviewform.group({
        firstName: ['', [Validators.required, Validators.pattern('^[a-zA-Z]+')]],
        lastName: ['', [Validators.required, Validators.pattern('^[a-zA-Z]+')]],
        email: ['', [Validators.required, Validators.pattern('^[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}')]],
        title: [''],
        description: [''],
        rating: ['5', Validators.required],
        captcha: [null, Validators.required],
      });
    }
  }

  ngAfterViewInit() {
    /* ReCaptcha render For Desktop & Mobile AP - 2-june */
    grecaptcha.render(document.getElementById('captcha'), {
      sitekey: environment.Captcha.siteKey,
      theme: 'light',
      callback: this.verifyCallback.bind(this),
      'expired-callback': this.onRecaptchaExpired.bind(this),
    });
    grecaptcha.render(document.getElementById('captchaMob'), {
      sitekey: environment.Captcha.siteKey,
      size: 'compact',
      theme: 'light',
      callback: this.verifyCallback.bind(this),
      'expired-callback': this.onRecaptchaExpired.bind(this),
    });
  }

  /* ReCaptcha verifyCallback function AP - 2-june */
  verifyCallback(data: String) {
    this.reviewDtlForm.patchValue({
      captcha: data,
    });
    this.captchaResponse = data;
  }

  onRecaptchaExpired(data: String) {
    this.reviewDtlForm.patchValue({
      captcha: data,
    });
    this.captchaResponse = data;
  }

  /* Create Review API Call - AP - 29-05-2020 start */
  createReview() {
    this.showLoader = true;
    let payload;
    if (this.userDetails && this.userDetails.user) {
      let customerNumber: any;
      if ([undefined, '', 'false', null, false].includes(this.globalVariables.selected_Customer_number)) {
        customerNumber = this.selectedAddress.customerNo;
      } else {
        customerNumber = this.globalVariables.selected_Customer_number;
      }
      if (customerNumber) {
        payload = {
          customerId: this.userDetails.user._id,
          customer_number: this.globalVariables.selected_Customer_number || this.selectedAddress.customerNo,
          programId: this.programId,
          programName: this.programName,
          productComment: this.reviewDtlForm.value.description,
          productRating: this.reviewDtlForm.value.rating,
          customerName: this.customerName.trim(),
          customerEmail: this.userDetails.user.email || '',
          reviewStatus: '0',
          reviewTitle: this.reviewDtlForm.value.title,
          custAddress: `${this.userAddress.city}, ${this.userAddress.state}`,
          customerGuid: this.globalVariables.selected_customerGuid || this.selectedAddress.customerGuid,
        };
      } else {
        payload = {};
      }
    } else {
      this.customerName = `${this.reviewDtlForm.value.firstName} ${this.reviewDtlForm.value.lastName}`;
      payload = {
        programId: this.programId,
        programName: this.programName,
        productComment: this.reviewDtlForm.value.description,
        productRating: this.reviewDtlForm.value.rating,
        customerName: this.customerName.trim(),
        customerEmail: this.reviewDtlForm.value.email,
        reviewStatus: '0',
        reviewTitle: this.reviewDtlForm.value.title,
      };
    }
    if (payload) {
      this.productService.createReview(payload).subscribe(
        () => {
          this.showLoader = false;
          this.result = true;
          this.windowScrolling.enable();
          this.close();
        },
        error => {
          const errorData = error.error;
          this.showLoader = false;
          this.result = errorData.responseDesc;
          this.windowScrolling.enable();
          this.close();
        },
      );
    } else {
      this.alertService.error('Something went wrong. Please try again later');
    }
  }
  /* Create Review API Call - AP - 29-05-2020 end */

  modalClose() {
    this.windowScrolling.enable();
    this.close();
  }

  toTitleCase(str) {
    return str.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
  }

  ratingSelect(val) {
    this.reviewDtlForm.value.rating = val;
    this.copyOfRating = this.reviewDtlForm.value.rating;
  }

  onMouseover(val) {
    this.copyOfRating = JSON.parse(JSON.stringify(this.reviewDtlForm.value.rating));
    this.reviewDtlForm.value.rating = val;
  }

  onMouseout() {
    this.reviewDtlForm.value.rating = this.copyOfRating;
  }

  trimSpaceFromBothEnd() {
    if (this.reviewDtlForm.value.title) {
      this.reviewDtlForm.controls.title.setValue(this.reviewDtlForm.value.title.trim());
    }
    if (this.reviewDtlForm.value.description) {
      this.reviewDtlForm.controls.description.setValue(this.reviewDtlForm.value.description.trim());
    }
  }
}
