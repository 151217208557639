import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Observable } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { ProductService } from '../../../product/services/product.service';
import { UserService } from '../../../user/services/user.service';
import { ShoppingCart } from '../../../cart/model/cart.model';
import { CartService } from '../../../cart/services/cart.service';
import { SessionStorageService } from '../../../core/services/session-storage.service';
import { LocationService } from '../../../home/services/location.service';
import { environment } from '../../../../environments/environment';
import { SiteContentService } from '../../../core/services/siteContents';
import { UtilsService } from '../../../core/services/utils.service';
import { GlobalVariables } from '../../../core/services/global-variables.service';
import { CartQuickCheckoutService } from '../../../cart/services/cart-quick-checkout.service';

@Component({
  selector: 'hos-right-navigation-menu',
  templateUrl: './right-navigation-menu.component.html',
  styleUrls: ['./right-navigation-menu.component.scss'],
})
export class RightNavigationMenuComponent implements OnInit {
  categories: any;

  subLinks: any;

  loggedInUser: any;

  orderArray = [];

  selectedAddress: any;

  services: any;

  objectKeys: any;

  public cart: Observable<ShoppingCart>;

  public itemCount: number;

  logoURL: String;

  isLoginRoute: boolean = false;

  isbrowser = true;

  site: any;

  constructor(
    private router: Router,
    public userService: UserService,
    private productService: ProductService,
    private utilsService: UtilsService,
    private locationService: LocationService,
    private route: ActivatedRoute,
    private sessionStorageService: SessionStorageService,
    private cartService: CartService,
    private cartQuickCheckoutService: CartQuickCheckoutService,
    private siteContentService: SiteContentService,
    private globalVariables: GlobalVariables,
    @Inject(PLATFORM_ID) platformId: Object,
  ) {
    this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        if (val.url.startsWith('/user/login')) {
          this.isLoginRoute = true;
        } else {
          this.isLoginRoute = false;
        }
      }
    });
    this.isbrowser = isPlatformBrowser(platformId);
  }

  ngOnInit() {
    const partner = this.sessionStorageService.getItem('providerState');
    if (partner) {
      this.utilsService.event.subscribe(eventData => {
        if (Object.keys(eventData).length !== 0) {
          this.utilsService.getPartnerDetails$().subscribe(
            data => {
              const { url } = data.body.site.footer.logo;
              this.logoURL = url
                ? `${environment.Image_API_Endpoint}${environment.Content_service_API_Endpoint}${url}`
                : 'img/logo/AW_logo_White.svg';
              this.logoURL = this.utilsService.buildImageUrl(this.logoURL);
              this.site = data.body;
            },
            () => {},
          );
        }
      });
    }
    if (this.loggedInUser) {
      this.cart = this.cartService.get();
    } else {
      this.cart = this.cartQuickCheckoutService.get();
    }
    this.objectKeys = Object.keys;
    this.utilsService.event.subscribe(data => {
      if (Object.keys(data).length !== 0) {
        this.getParterDetailsFromSubject();
      }
    });
    if (this.loggedInUser) {
      this.cart.subscribe(cart => {
        this.itemCount = cart.items.length;
      });
    }
    this.siteContentService.event.subscribe(() => {
      const provider = this.sessionStorageService.getItem('providerState');
      this.loadContent(provider);
    });
    this.productService.categories$().subscribe(categoriesRec => {
      if (this.categories) {
        for (let i = 0; i < this.categories.length; i++) {
          const category = this.categories[i];
          category.sub = this.subLinks.filter(obs => obs.categoryId === category.categoryId);
          if (category.label === 'Services') {
            category.sub = categoriesRec.body;
          }
        }
      }
    });

    this.globalVariables.removeProperty.subscribe(() => {
      this.itemCount = 0;
      this.sessionStorageService.deleteItem('cart');
    });
  }

  loadContent(provider: any) {
    if (provider) {
      this.utilsService.getPartnerDetails$().subscribe(
        data => {
          const resp = data.body;
          /** ********** HWR-3642: multiple locations for FEH microsite  ******** */
          const locationCodeId = this.sessionStorageService.getItem('location_code')
            ? this.sessionStorageService.getItem('location_code')
            : Array.isArray(resp.locationCodeId)
            ? resp.locationCodeId[0]
            : '';
          if (![undefined, 'undefined', null, 'null', ''].includes(locationCodeId)) {
            this.sessionStorageService.setItem('location_code', locationCodeId);
          }
          /** ********** HWR-3642: multiple locations for FEH microsite  ******** */
          this.productService.categories$().subscribe(
            categories => {
              this.categories = categories.body;
              this.categories = data.body.site.sideMenu;
              this.subLinks = data.body.site.sideSubMenu;
              this.categories.forEach(category => {
                category.sub = this.subLinks.filter(obs => obs.categoryId === category.categoryId);
                if (category.label === 'Services') {
                  category.sub = this.services;
                }
              });
            },
            () => {},
          );
        },
        () => {},
      );
    }
  }

  openLoginWindow() {
    /* this.simpleModalService.addModal(LoginModalComponent, {
      title: 'Already have an account?',
      message: ''
    }, {
        wrapperClass: 'modal-main-class in',
        closeOnClickOutside: true
      })
      .subscribe((isConfirmed) => {
        if (isConfirmed) {
          this.loggedInUser = this.userService.getSignedInUser();
        } else if (isConfirmed === false) {
          this.loadForgotPassword();
        } else if (isConfirmed === undefined) {

        }
      }); */
    let prevUrl;
    const referer = this.route.snapshot.queryParams.referer || '';
    const hashValue: any = ![undefined, 'undefined', null, 'null', ''].includes(window.location.hash) ? window.location.hash : '';
    let urlOperation: any = window.location.pathname.split('/');
    const micrositeNames = this.sessionStorageService.getItem('micrositeName')
      ? JSON.parse(this.sessionStorageService.getItem('micrositeName'))
      : environment.micrositeNames;
    // urlOperation = urlOperation.filter(item => !environment.micrositeNames.includes(item) && item !== "");
    urlOperation = urlOperation.filter(item => !micrositeNames.includes(item) && item !== '');
    prevUrl = `${urlOperation.join('/')}${window.location.search}`;

    const urlResult = environment.userRouteIncludes.filter(val => prevUrl.startsWith(val));
    if (urlResult.length > 0) {
      prevUrl = '';
    }

    // when user clicks on login after registeration
    let urlReferer = encodeURIComponent(prevUrl + hashValue);
    urlReferer = urlReferer === '' ? referer : urlReferer;
    this.router.navigate(['/user/login'], {
      queryParams: {
        referer: urlReferer,
      },
    });
  }

  logout() {
    this.userService.signOut();
    this.globalVariables.userLoggedIn.next(false);
    this.loggedInUser = null;
    this.router.navigate(['']);
    this.globalVariables.myAccClicked = false;
  }

  closeSideBar() {
    document.body.classList.remove('sidebar-open');
    document.getElementById('box').classList.remove('active');
    document.getElementById('backdrop-sidemenu').style.display = 'none';
    let index;
    const list = document.getElementsByClassName('accordmenucls');
    for (index = 0; index < list.length; index++) {
      list[index].setAttribute('aria-expanded', 'false');
    }
  }

  goToCategory(category) {
    const zipcodeByURL = this.route.snapshot.queryParams.zipcode;
    const savedAddress = this.sessionStorageService.getItem('selectedAddress');
    const dataLocation = this.locationService.ZipcodeAndLocation;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const location_code = dataLocation ? dataLocation.location_code : this.sessionStorageService.getItem('location_code');
    this.selectedAddress = savedAddress ? JSON.parse(savedAddress) : '';
    const zipcodeOtherwise = savedAddress ? this.selectedAddress.zipcode : zipcodeByURL;
    const zipcode = dataLocation ? dataLocation.zipcode : zipcodeOtherwise;
    /* HWR-2796 */
    if (!!this.selectedAddress && !!this.selectedAddress.zipcode && this.selectedAddress.zipcode !== '') {
      this.productService.getProviderContent(location_code).subscribe(data => {
        const locationCodeData = data.body;
        const allProducts = true;
        this.productService.getMarketingCodes('', locationCodeData.locationCodeId, allProducts).subscribe(datamkc => {
          if (datamkc.status === 200) {
            const resData = datamkc.body;
            let WebProgName: any;
            const result = resData.result.filter(ele => ele.program.programId === category.reviewProgramId);
            if (result.length > 0) {
              WebProgName = result[0].webProgramName ? result[0].webProgramName : result[0].programName;
            }
            let categoryParams: any;
            const typeParams = 'residential';
            if (category) {
              categoryParams = WebProgName.toString().split(' ').join('-').split(',').join('');
            }
            // this.router.navigate(['/products/', zipcode, (locationCodeData['locationCode']).toLowerCase()]);
            let redirctUrl = `/product/${zipcode}/${locationCodeData.locationCode.toLowerCase()}`;
            redirctUrl += typeParams ? `/${typeParams}` : '';
            redirctUrl += categoryParams ? `/${categoryParams.toLowerCase()}` : '';
            this.router.navigate([redirctUrl]);
          }
        });
      });
    } else {
      this.router.navigate([`/service/${category.categoryName.trim().split(' ').join('-').toLowerCase()}`]);
    }
  }

  redirectToCart() {
    this.loggedInUser = this.userService.getSignedInUser();
    if (this.loggedInUser !== undefined && this.loggedInUser !== '') {
      this.router.navigate(['/payment'], {
        queryParams: {
          section: 'cart',
        },
      });
    }
  }

  gotoLink() {
    this.globalVariables.myAccClicked = true;
    this.router.navigate(['/user/myaccount']);
    this.globalVariables.myAccClickFromHeader.emit(true);
  }

  redirectToDetails() {
    if (this.isbrowser) {
      const tempZip = this.sessionStorageService.getItem('tempZip');
      const selectedAddress = this.sessionStorageService.getItem('selectedAddress');

      if (tempZip || (selectedAddress !== null && selectedAddress !== '{}' && JSON.parse(selectedAddress).zipcode !== '')) {
        window.location.href = `/service/zipcode/${
          this.sessionStorageService.getItem('tempZip')
            ? this.sessionStorageService.getItem('tempZip')
            : JSON.parse(this.sessionStorageService.getItem('selectedAddress')).zipcode
        }`;
      } else {
        window.location.href = '/service';
      }
    }
  }

  getParterDetailsFromSubject() {
    this.utilsService.getPartnerDetails$().subscribe(response => {
      this.categories = response.body.site.sideMenu;
      this.subLinks = response.body.site.sideSubMenu;
      this.productService.categories$().subscribe(categoriesLoad => {
        this.services = categoriesLoad.body;
        this.categories.forEach(
          category => {
            category.sub = this.subLinks.filter(obs => obs.categoryId === category.categoryId);
            if (category.label === 'Services') {
              category.sub = this.services;
            }
          },
          () => {},
        );
      });
    });
  }
}
