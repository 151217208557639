import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { SimpleModalComponent, SimpleModalService } from 'ngx-simple-modal';
import { environment } from '../../../../environments/environment';
import { ProductService } from '../../../product/services/product.service';
import { ErrorModalComponent } from '../../../core/component/error-modal/error-modal.component';
import { UtilsService } from '../../../core/services/utils.service';

export interface ServiceModal {
  service: any;
}
@Component({
  selector: 'hos-app-service-detail-info',
  templateUrl: './service-detail-info.component.html',
  styleUrls: ['./service-detail-info.component.scss'],
})
export class ServiceDetailInfoComponent extends SimpleModalComponent<ServiceModal, boolean> implements ServiceModal, OnInit {
  service: any;

  termsLink: string;

  today: any;

  effectiveDate: any;

  nextBillingDate: any;

  imageBaseUrl: String;

  constructor(
    public activatedRoute: ActivatedRoute,
    private simpleModalService: SimpleModalService,
    private utilsService: UtilsService,
    private datePipe: DatePipe,
    private productService: ProductService,
  ) {
    super();
  }

  ngOnInit() {
    this.today = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:s', 'UTC');
    this.imageBaseUrl = `${environment.Image_API_Endpoint}${environment.Product_service_API_Endpoint}`;
    if (this.service.billingFrequency === 'Annual') {
      this.effectiveDate = this.datePipe.transform(new Date(this.service.programEffectiveData), 'yyyy-MM-dd HH:mm:s', 'UTC');
    } else {
      this.effectiveDate = this.datePipe.transform(new Date(this.service.programEffectiveDate), 'yyyy-MM-dd HH:mm:s', 'UTC');
    }
    this.nextBillingDate = this.effectiveDate;
    const effectiveDate = new Date(this.effectiveDate);
    if (this.service.billingFrequency === 'Monthly') {
      this.nextBillingDate = effectiveDate.setMonth(effectiveDate.getMonth());
    } else if (this.service.billingFrequency === 'Annual') {
      this.nextBillingDate = effectiveDate.setMonth(effectiveDate.getMonth() + 11);
    }
  }

  goToTerms(service): void {
    if (service.productDetails) {
      const program = (service.productDetails.coverageType === 'UNLIMITED' ? 'Unlimited ' : '') + service.productDetails.program.programCode;
      this.productService
        .getTermsAndConditions('english', service.address.serviceAddress.state, service.address.serviceAddress.zipcode, program)
        .subscribe(
          response => {
            this.termsLink = `${environment.Image_API_Endpoint + environment.TERMS_SERVICE_ENDPOINT}/${
              response.body.result && response.body.result.length ? response.body.result[0].pdfUrl : ''
            }`;
            window.open(this.termsLink, '_blank');
          },
          () => {},
        );
    }
  }

  showErrorModal(title: string, message: string, confirmationMsg?: string) {
    this.simpleModalService
      .addModal(
        ErrorModalComponent,
        {
          title,
          message,
          confirmationMsg,
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: true,
        },
      )
      .subscribe(() => {});
  }

  getImage(imageName) {
    return this.utilsService.buildImageUrl(imageName);
  }
}
