import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
  name: 'enrollmentStatusText',
})
export class EnrollmentStatusTextPipe implements PipeTransform {
  transform(service: any) {
    if (service.crmStatus === '6') {
      return 'There is an issue with the payment information for this enrollment that needs your attention';
    }
    if (service.crmStatus === '4') {
      return 'Your Enrollment is cancelled';
    }
    if (service.crmStatus === '999' || (service.crmStatus === '3' && service.productDetails.program.programCode !== 'LDDP')) {
      return 'Your enrollment is being processed. The status will update once completed.';
    }
    if (service.isAlreadyEnrolled && service.renewNow === true) {
      return 'Your renewal has been received.';
    }
    if (service.expiredIn > 0 && service.crmStatus === '2' && new Date(service.renewalDate) > new Date()) {
      return 'Your Enrollment is active';
    }
    if (service.expiredIn < 0) {
      return 'Your Enrollment has expired';
    }
    return '';
  }
}
