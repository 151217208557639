<div class="breadcrumb-wrapper">
  <div class="mobile-breadcrumb custom-breadcrumb page-wrap">
    <ul class="breadcrumb-ul-list">
      <li class="breadcrumb-li-list" *ngFor="let item of config" [ngClass]="{ active: item.active }">
        <a class="breadcrumb-anchor-link" [routerLink]="[item.link]" [queryParams]="item.query" [innerHTML]="item.title" *ngIf="item.link"></a>
        <span [innerHTML]="item.title.length > 40 ? item.title.slice(0, 40) + '...' : item.title" *ngIf="!item.link"></span>
      </li>
    </ul>
  </div>
</div>
