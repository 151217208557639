/* eslint-disable prefer-rest-params */
/* eslint-disable no-inner-declarations */
/* eslint-disable no-return-assign */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { InjectionToken } from '@angular/core';
import { Gtag, ConfigParams, CustomParams } from './gtag-definitions';
import { environment } from '../../environments/environment';

export interface GtagConfig {
  targetId?: string; // gtag('config', 'targetId', configInfo )
  configInfo?: ConfigParams;
  setParams?: CustomParams; // gtag('set', setParams)
  moreIds?: string[];
}

export const GtagConfigToken = new InjectionToken<GtagConfig>('wizdm.gtag.config');

/** Reproduces the standard code snippet we would paste in index.html
 * @see: https://developers.google.com/analytics/devguides/collection/gtagjs */
export function gtagFactory(config: GtagConfig): Gtag {
  if (window && (window as any).gtag) {
    return (window as any).gtag;
  }

  if (window) {
    const script = document.createElement('script');

    script.src = `https://www.googletagmanager.com/gtag/js?id=${environment.gaTargetId}`;
    script.type = 'text/javascript';
    script.async = true;

    document.head.appendChild(script);

    (window as any).dataLayer = (window as any).dataLayer || [];
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function gtag(...args) {
      (window as any).dataLayer.push(arguments);
    }

    gtag('js', new Date());

    gtag('config', environment.gaTargetId, {
      send_page_view: false,
      ...config.configInfo,
    });

    'setParams' in config && gtag('set', config.setParams);

    'moreIds' in config && config.moreIds.forEach(id => gtag('config', id));

    return ((window as any).gtag = gtag);
  }
}
