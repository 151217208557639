<section class="category-block content-block content-section" *ngIf="pageName === 'productDetail'" id="category-section">
  <article class="page-wrap row">
    <div class="col-12">
      <h2 class="additional-head-title font-unbounce-title">Additional Products And Services Available</h2>
    </div>
  </article>
  <!-- <article class="page-wrap row mt-4" *ngIf="pageName !== 'productDetail'">
          <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 mob-hide pad-left-40">
              <hr>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 cat-slide-heading">Protect The Unexpected</div>
          <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 mob-hide pad-right-40">
              <hr>
          </div>
      </article> -->
  <div class="page-wrap row cat-row" *ngIf="categories">
    <ngu-carousel #myCarousel [inputs]="carouselTile" [dataSource]="categories" class="category-tile">
      <ngu-tile *nguCarouselDef="let category of categories; let i = index" class="item">
        <a class="cat-item col cursor ga-track-program-icon" (click)="goToCategory(category)">
          <figure class="image-thumb">
            <img [src]="getImage(productServiceUrl + category.icon)" [alt]="category.categoryName" />
          </figure>
          <div class="text">
            <h3>{{ category.categoryName }}</h3>
          </div>
        </a>
      </ngu-tile>
      <button NguCarouselPrev class="left-arrow-icon" [style.display]="myCarousel.isFirst ? 'none' : 'block'">&lt;</button>
      <button NguCarouselNext class="right-arrow-icon" [style.display]="myCarousel.isLast ? 'none' : 'block'"></button>
    </ngu-carousel>
  </div>
</section>
