export interface UserModel {
  referer?: string;
  accountnumber?: number;
  firstName?: string;
  lastName: string;
  middleName?: string;
  email: string;
  phoneNumber: string;
  phoneNumber2: string;
  isAuthorizedToSendMessage?: MessageAuthorization;
  password: string;
  address: Array<AddressTypeModel>;
}

export interface AddressModel {
  streetNumber: string;
  streetName: string;
  city: string;
  state: string;
  zipcode?: string;
  zipcodeId?: string;
  locationCodeId?: string;
  contactType?: string;
}

export interface AddressTypeModel {
  serviceAddress: AddressModel;
  billingAddress: AddressModel;
  customerNo?: string;
  customerGuid?: string;
  ownOrRentedValidated?: string;
  noWaterBillConversion?: string;
  waterBillOnOff?: boolean;
  firstName?: string;
  lastName: string;
  isFromCheckout: boolean;
}

export type MessageAuthorization = 0 | 1;

export class User implements UserModel {
  referer?: string;

  accountnumber?: number;

  firstName?: string;

  lastName: string;

  middleName?: string;

  email: string;

  phoneNumber: string;

  phoneNumber2: string;

  // [VJ HWR-2661-2654 MOBILE FIELD RELATED CODE]
  mobilePhoneNumber: string;

  isAuthorizedToSendMessage?: MessageAuthorization = 0;

  cellPhone1SmsOptInDate: string;

  password: string;

  address: Array<AddressTypeModel> = [
    {
      serviceAddress: {
        streetNumber: '',
        streetName: '',
        city: '',
        state: '',
      },
      billingAddress: {
        streetNumber: '',
        streetName: '',
        city: '',
        state: '',
      },
      firstName: '',
      lastName: '',
      isFromCheckout: false,
    },
  ];

  customerId: string;
}
