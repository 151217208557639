import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { NguCarouselConfig } from '@ngu/carousel';
import { SeoConfig } from '../../../core/model/seo-config.model';
import { SeoService } from '../../../core/services/seo.service';
import { SessionStorageService } from '../../../core/services/session-storage.service';
import { ProductService } from '../../../product/services/product.service';
import { SiteContentService } from '../../../core/services/siteContents';
import { LocationService } from '../../services/location.service';
import { UtilsService } from '../../../core/services/utils.service';
import { GlobalVariables } from '../../../core/services/global-variables.service';

@Component({
  selector: 'hos-body',
  templateUrl: './body.component.html',
  styleUrls: ['./body.component.scss'],
})
export class BodyComponent implements OnInit {
  seoConfig: SeoConfig = {
    title: 'Home Service Line Warranties | American Water Resources',
    description: `Home American Water Resources offers affordable home protection programs
    that help protect against unexpected repair costs for service line emergencies.`,
    keywords: `service line warranties, water line protection, sewer line protection,
    in home plumbing emergencies, electric line protection, power surge protection,heating system repair, cooling system repair`,
    image: '',
    slug: '',
    sitename: '',
    type: '',
    summary: '',
    url: '',
  };

  products: Array<any> = [];

  isCategory: boolean = false;

  lowestPrice: any;

  public carouselTile: NguCarouselConfig;

  mkcCategories: any = [];

  showBannerImageComp: Boolean = false;

  constructor(
    private seoService: SeoService,
    public sessionStorageService: SessionStorageService,
    private productService: ProductService,
    private siteContentService: SiteContentService,
    private utilsService: UtilsService,
    private locationService: LocationService,
    public globalVariables: GlobalVariables,
  ) {
    seoService.generateTitleTags(this.seoConfig);
    seoService.setCanonicalTags();
    this.globalVariables.loadScriptsFunction.pipe(take(1)).subscribe(data => {
      if (data) {
        this.showBannerImageComp = true;
        this.getProductCardData();
      }
    });
  }

  ngOnInit() {
    this.carouselTile = {
      grid: {
        xs: 1,
        sm: 2,
        md: 2,
        lg: 3,
        all: 0,
      },
      slide: 1,
      speed: 500,
      interval: {
        timing: 5000,
      },
      load: 2,
      custom: 'banner',
      touch: true,
      loop: true,
      point: {
        visible: false,
      },
      easing: 'ease',
    };
    if (this.globalVariables.scriptsLoaded) {
      this.globalVariables.loadScriptsFunction.emit(true);
    }
    // this.getProductCardData();
  }

  getProductCardData() {
    /* if state empty show static product card else product cards from marketing codes */
    const state = this.sessionStorageService.getItem('providerState');
    if (!state) {
      this.getDefaultLocProductCard();
    } else {
      this.getMarketingCodeDetails();
    }
  }

  getDefaultLocProductCard() {
    this.productService.categories$().subscribe(
      categories => {
        const categoriesData = categories.body;
        this.mkcCategories = categories.body;
        this.isCategory = true;
        const filteredCategory = categoriesData.filter(data => data.isActiveOnHomePage);
        // eslint-disable-next-line no-underscore-dangle
        forkJoin(filteredCategory.map(ele => this.productService.getServiceGenericContent(ele._id).pipe(map(response => response.body)))).subscribe(
          (data: any) => {
            this.products = [].concat(...data).filter(ele => ele.homeProgramCardContent);
            this.lowestPrice = Number(Math.min(...this.products.map(ele => ele.monthlyPrice))).toFixed(2);
          },
        );
      },
      () => {
        this.products = [];
      },
    );
  }

  getMarketingCodeDetails() {
    this.utilsService.event.subscribe(eventData => {
      if (Object.keys(eventData).length !== 0) {
        this.utilsService.getPartnerDetails$().subscribe(
          data => {
            const partnerDetails = data.body;
            this.productService.getCategories(partnerDetails.locationCodeId[0]).subscribe(
              categories => {
                this.mkcCategories = categories.body;
                this.locationService.getLocationsContent(partnerDetails.locationCodeId[0]).subscribe(() => {
                  this.productService.getMarketingCodes('', partnerDetails.locationCodeId[0], true).subscribe(
                    res => {
                      if (res.status === 200) {
                        const resData = res.body.result;
                        this.products = resData.filter(result => result.isActiveOnHomePage && result.homeProgramCardContent);
                        this.lowestPrice = Number(Math.min(...this.products.map(ele => ele.monthlyPrice))).toFixed(2);
                      }
                    },
                    () => {
                      this.products = [];
                    },
                  );
                });
              },
              () => {
                this.products = [];
              },
            );
          },
          () => {
            this.products = [];
          },
        );
      }
    });
  }
}
