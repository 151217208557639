import { Component, OnInit, OnDestroy, Inject, Optional, PLATFORM_ID } from '@angular/core';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { isPlatformBrowser, isPlatformServer, Location } from '@angular/common';
import { ZipcodeService } from '../../../core/services/zipcode.service';
import { ProductService } from '../../../product/services/product.service';
import { Breadcrumb } from '../../../core/component/breadcrumb/breadcrumb.component';
import { SessionStorageService } from '../../../core/services/session-storage.service';
import { LocationService } from '../../../home/services/location.service';
import { SeoService } from '../../../core/services/seo.service';
import { programs } from '../../../core/component/colorConfig/programClass';
import { environment } from '../../../../environments/environment';
import { JsonLDService } from '../../../core/services/json-ld.service';
import { UtilsService } from '../../../core/services/utils.service';
import { GlobalVariables } from '../../../core/services/global-variables.service';

@Component({
  selector: 'hos-product-detail-body',
  templateUrl: './body.component.html',
  styleUrls: ['./body.component.scss'],
})
export class ProductDetailsBodyComponent implements OnInit, OnDestroy {
  // tslint:disable-next-line:max-line-length
  seoConfig: {
    title: string;
    description: string;
    keywords: string;
    image: string;
    slug: string;
    sitename: string;
    type: string;
    summary: string;
    url: string;
  };

  products: Array<any> = [];

  productDetail: any;

  location_code: String;

  partnerName: any;

  locData: any;

  locationData: any;

  breadcrumsConfig: Array<Breadcrumb> = [
    {
      title: 'Home',
      link: '/',
      active: false,
    },
    {
      title: '',
      link: `/products/${this.activatedRoute.snapshot.params.zipcode}/${this.activatedRoute.snapshot.params.locationcode}`,
      active: false,
      query: {},
    },
    {
      title: '',
      link: `/products/${this.activatedRoute.snapshot.params.zipcode}/${this.activatedRoute.snapshot.params.locationcode}/${this.activatedRoute.snapshot.params.type}`,
      active: false,
      query: {},
    },
  ];

  allowedTypes = ['residential', 'commercial'];

  locationCodeId: any;

  marketingCodeId: any;

  loadZipCode: Boolean = false;

  zipCode: any;

  locationCode: any;

  programName: any;

  contact_type: any;

  logoURL: any;

  aggregateRatingSchema: any;

  reviewsDetailsSchema: any = [];

  imageBaseUrl: any;

  specialProduct: Array<String> = [];

  type: any;

  typeFromOldUrl: any;

  service_State: any;

  isPageNotFoundComp: Boolean = false;

  isbrowser = true;

  isserver = false;

  seoURL = '';

  selectedTab = '';

  benefitsHeading: any;

  coveredRepairsHeading: any;

  faqsHeading: any;

  divHeightScrollFromEmitter: any = 0;

  allProducts: any;

  testimonialSlideIndex: any = 0;

  testimonialSlidePrevEvent: Boolean = false;

  public testimonials: Array<any> = [];

  constructor(
    private router: Router,
    public activatedRoute: ActivatedRoute,
    private productService: ProductService,
    private locationService: LocationService,
    private sessionStorageService: SessionStorageService,
    private seoService: SeoService,
    private jsonLDService: JsonLDService,
    private utilsService: UtilsService,
    private zipcodeService: ZipcodeService,
    private location: Location,
    public globalVariables: GlobalVariables,
    @Inject(PLATFORM_ID) platformId: Object,
    @Optional() @Inject('serverUrl') protected origin: string,
    @Optional() @Inject('pathname') protected pathname: string,
  ) {
    this.loadZipCode = true;
    this.isbrowser = isPlatformBrowser(platformId);
    this.isserver = isPlatformServer(platformId);
    this.logoURL = this.sessionStorageService.getItem('logoURL');
    this.imageBaseUrl = `${environment.Image_API_Endpoint}${environment.Product_service_API_Endpoint}`;
    this.specialProduct = environment.special_product;
    this.router.events.subscribe(val => {
      if (this.isbrowser) {
        this.seoURL = window.location.origin + window.location.pathname;
      }
      if (this.isserver) {
        this.seoURL = this.origin + this.pathname;
      }
      if (val instanceof NavigationStart) {
        this.jsonLDService.removeStructuredData();
      }
    });
    /* HWR-2761 */
    this.location.subscribe((event: PopStateEvent): void => {
      if (event.type === 'hashchange') {
        if (window.location.hash === '#reviews') {
          this.reviewsFragment('reviews');
        }
      }
    });
  }

  ngOnInit() {
    this.utilsService.event.subscribe(data => {
      if (Object.keys(data).length !== 0) {
        this.loadContent();
      }
    });
    this.activatedRoute.params.subscribe(param => {
      const { zipcode } = this.activatedRoute.snapshot.queryParams;
      const locaId = this.activatedRoute.snapshot.queryParams.location_code;
      if (param && param.productName && param.productId) {
        if (zipcode && locaId) {
          this.productService.getProviderContent(locaId).subscribe(res => {
            const providerContent = res.body;
            const { locationCode } = providerContent;
            this.productService.getMarketingCodesById(param.productId).subscribe(
              data => {
                const resData = data.body;
                const productContactType = resData.contactType;
                const contactType = environment.types.filter(item => item.id === Number(productContactType));
                if (contactType && contactType.length === 1 && Object.prototype.hasOwnProperty.call(contactType[0], 'type')) {
                  this.typeFromOldUrl = contactType[0].type;
                }
                this.router.navigate([
                  '/product/',
                  zipcode,
                  locationCode.toLowerCase(),
                  this.typeFromOldUrl.toLowerCase(),
                  param.productName.toString().split(' ').join('-').split(',').join('').toLowerCase(),
                ]);
              },
              () => {},
            );
          });
        } else {
          this.isPageNotFoundComp = true;
          return;
        }
      }
      if (param) {
        this.zipCode = param.zipcode;
        this.locationCode = param.locationcode;
        this.programName = param.programName;
        this.contact_type = param.type ? param.type.toLowerCase() : param.type;
      }
      if (
        !(zipcode && locaId) &&
        this.zipCode &&
        this.zipCode.length === 5 &&
        this.locationCode &&
        this.programName &&
        this.contact_type &&
        this.allowedTypes.includes(this.contact_type)
      ) {
        this.zipcodeService.getZipcodeId(this.zipCode).subscribe(
          async resData => {
            const res = resData.body;
            if (res.locationCodeId) {
              if (this.locationCode.includes('150')) {
                try {
                  const getLocDataApiCall = await this.getLocDataApi();
                  const getLocData = getLocDataApiCall.body;
                  if (getLocData) {
                    this.locationCodeId = getLocData.LocationDetails.filter(obj => obj.Code.includes('150')).map(obj => obj.Id)[0];
                    this.sessionStorageService.setItem('location_code', this.locationCodeId);
                  }
                } catch (err) {
                  this.isPageNotFoundComp = true;
                  return;
                }
              }
              this.locationService.getLocationsDataByLocationCode(this.locationCode).subscribe(resdata => {
                const data = resdata.body[0];
                this.locationCodeId = data.locationCodeId;
                this.sessionStorageService.setItem('location_code', this.locationCodeId);
                this.productService.setCategories();
                /** match url servicestate, location code with zip code servicestate and locatin code */
                if (res.serviceState === data.serviceState && data.locationCode.toLowerCase() === this.locationCode.toLowerCase()) {
                  this.service_State = data.serviceState;
                  const contactType = environment.types.filter(item => item.type === this.contact_type);
                  if (contactType && contactType.length === 1 && Object.prototype.hasOwnProperty.call(contactType[0], 'id')) {
                    this.type = contactType[0].id;
                    const query = `contact_type=${this.type}&allProducts=true`;
                    /** To get all products according to contact type and location code. */
                    this.productService.getMarketingCodes(query, this.locationCodeId).subscribe(
                      dataRes => {
                        if (dataRes.status === 200) {
                          const allProducts = dataRes.body;
                          this.allProducts = allProducts.result;
                          /** match webProgramName and filter productdata from all products */
                          const webProgramName = this.programName.toString().split('-').join(' ').split(',').join('').toLowerCase();
                          const selectedProductByProgramName = allProducts.result.filter(
                            obj => obj.webProgramName.split('-').join(' ').split(',').join('').toLowerCase() === webProgramName,
                          );
                          if (
                            selectedProductByProgramName &&
                            selectedProductByProgramName.length === 1 &&
                            Object.prototype.hasOwnProperty.call(selectedProductByProgramName[0], '_id')
                          ) {
                            /** to set zipCode and Location code */
                            const payload = {
                              zipcode: this.zipCode,
                              location_code: this.locationCodeId,
                            };
                            this.locationService.ZipcodeAndLocation = payload;
                            this.location_code = '';
                            this.marketingCodeId = selectedProductByProgramName[0]._id;
                            /** fetch product data by MarketingCode _id */
                            this.productService.getMarketingCodesById(this.marketingCodeId).subscribe(
                              dataResponse => {
                                const productData = dataResponse.body;
                                if (this.sessionStorageService.getItem('selectedAddress')) {
                                  this.location_code = this.sessionStorageService.getItem('location_code');
                                }
                                let objData = programs[productData.program.programCode];
                                if (typeof objData === 'undefined') {
                                  objData = programs.others;
                                }
                                // productData.colorConfigClass = objData.class;
                                productData.colorConfigStyle = objData.color;
                                if (productData.detailHeaderBgColor) {
                                  productData.colorConfigStyle = productData.detailHeaderBgColor;
                                }
                                this.productDetail = productData;
                                this.productDetail.testimonials.forEach(testimonial => {
                                  testimonial.author = {
                                    name: testimonial.author,
                                  };
                                });
                                if (this.productDetail) {
                                  this.benefitsHeading = this.productDetail.benefitsHeading;
                                  this.coveredRepairsHeading = this.productDetail.coveredRepairsHeading;
                                  this.faqsHeading = this.productDetail.faqsHeading;
                                  /** *** HWR-1211 - Start */
                                  if (this.productDetail.coverageType && this.productDetail.coverageType === 'UNLIMITED') {
                                    this.productDetail.reviewProgramId = `unlimited-${this.productDetail.programId}`;
                                    this.productDetail.reviewProgramName = `Unlimited ${this.productDetail.programName}`;
                                  }
                                  /** *** HWR-1211 - End */
                                  const locId = this.sessionStorageService.getItem('location_code');
                                  if (locId) {
                                    this.productDetail.webProgramName = this.productDetail.webProgramName ? this.productDetail.webProgramName : '';
                                    this.locationData = this.productDetail.defaultLocationCodeId ? this.productDetail.defaultLocationCodeId : '';
                                    if (this.breadcrumsConfig.length < 4) {
                                      this.breadcrumsConfig.push({
                                        title: this.productDetail.webProgramName,
                                        link: '',
                                        active: true,
                                        query: '',
                                      });
                                    } else {
                                      this.breadcrumsConfig[this.breadcrumsConfig.length - 1].title = this.productDetail.webProgramName;
                                    }
                                    /* Setting Products URL */
                                    this.breadcrumsConfig[1].link = `/products/${this.zipCode}/${this.locationCode}/residential`;
                                    this.breadcrumsConfig[2].link = `/products/${this.zipCode}/${this.locationCode}/${this.contact_type}`;
                                    /* Setting breadcrum title */
                                    this.breadcrumsConfig[1].title = this.zipCode;
                                    this.breadcrumsConfig[2].title = this.contact_type;
                                  }
                                  this.setPageMetaData();
                                  this.seoService.generateTitleTags(this.seoConfig);
                                  if (this.zipCode) {
                                    this.seoService.metaSetNoIndexNoFollow();
                                  } else {
                                    this.seoService.setCanonicalTags();
                                  }
                                  /* HWR-2666 Get All Product Reviews based on Program Id */
                                  this.productService.getProductReviewsForSchema(this.productDetail.programId, true).subscribe(
                                    result => {
                                      if (result.status === 200) {
                                        const resp = result.body;
                                        this.reviewsDetailsSchema = resp;
                                        this.reviewsFragment();
                                        this.checkJsonLdScriptData();
                                      }
                                    },
                                    () => {
                                      this.reviewsDetailsSchema = [];
                                    },
                                  );
                                }
                              },
                              () => {},
                            );
                          } else {
                            this.isPageNotFoundComp = true;
                          }
                        }
                      },
                      () => {
                        this.isPageNotFoundComp = true;
                      },
                    );
                  }
                  this.partnerName = this.sessionStorageService.getItem('websitePartnerName');
                  if (this.partnerName) {
                    /** HWR - 2696  pass location code in below call */
                    this.productService.getProviderContent(this.locationCodeId || this.sessionStorageService.getItem('location_code')).subscribe(
                      locationData => {
                        this.locData = locationData.body;
                      },
                      () => {
                        this.locData = [];
                      },
                    );
                  } else {
                    this.locData = [];
                  }
                } else {
                  this.isPageNotFoundComp = true;
                }
              });
            }
            this.loadZipCode = false;
          },
          err => {
            this.loadZipCode = false;
            if (err.status === 404) {
              this.isPageNotFoundComp = true;
            }
          },
        );
      } else if (Object.keys(param).length === 4) {
        this.isPageNotFoundComp = true;
      }
    });
    this.products = [
      {
        programName: 'Sewer line protection program',
      },
      {
        programName: 'Water line protection program',
      },
      {
        programName: 'Electric line protection program',
      },
      {
        programName: 'Water Heater protection program',
      },
    ];
  }

  public getLocDataApi(): Promise<any> {
    return this.productService.getLocationDataCRM(this.activatedRoute.snapshot.params.locationcode.replace(/^\s+/g, '')).toPromise();
  }

  zipcodeChanged() {
    // this.breadcrumsConfig[1].query = { 'zipcode': event.zipcode, 'location_code': event.location_code };
  }

  setPageMetaData() {
    let url = '';
    if (this.isbrowser) {
      url = window.location.origin + window.location.pathname;
    }
    const imageUrl = this.productDetail.programBannerIconUrl
      ? this.imageBaseUrl + this.productDetail.programBannerIconUrl
      : this.productDetail.bannerImages.descriptionMobileImages && this.productDetail.bannerImages.descriptionMobileImages.length > 0
      ? this.productDetail.bannerImages.descriptionMobileImages[0].startsWith('programDetailsBgImage/Mobile')
        ? `../../../../img/${this.productDetail.bannerImages.descriptionMobileImages[0]}`
        : this.imageBaseUrl + this.productDetail.bannerImages.descriptionMobileImages[0]
      : `../../../../img/${this.productDetail.bannerImages.descriptionMobileImages[0]}`;
    const seoConfigImageUrl = this.utilsService.buildImageUrl(imageUrl);
    this.seoConfig = {
      title: 'Product Service Line Warranties | American Water Resources',
      description: `American Water Resources offers affordable home protection programs
      that help protect against unexpected repair costs for service line emergencies.`,
      keywords: `service line warranties, water line protection, sewer line protection,
      in home plumbing emergencies, electric line protection, power surge protection,heating system repair, cooling system repair`,
      image: seoConfigImageUrl,
      slug: '',
      sitename: '',
      type: '',
      summary: '',
      url,
    };
  }

  locationCodeHandler() {
    this.location_code = this.locationCodeId;
  }

  displayPartner() {
    this.productService.getProviderContent(this.locationCodeId).subscribe(
      locationData => {
        this.locData = locationData.body;
      },
      () => {
        this.locData = [];
      },
    );
  }

  addJsonLdScript() {
    const reviews: any = [];
    if (this.reviewsDetailsSchema.length > 0) {
      this.reviewsDetailsSchema.forEach(element => {
        const objectData = {
          '@type': 'Review',
          author: element.customerName,
          datePublished: element.createdAt,
          description: element.productComment,
          name: element.reviewTitle,
          reviewRating: {
            '@type': 'Rating',
            bestRating: '5',
            ratingValue: element.productRating,
            worstRating: '1',
          },
        };
        reviews.push(objectData);
      });
    }
    const imageUrl = this.productDetail.programBannerIconUrl
      ? this.imageBaseUrl + this.productDetail.programBannerIconUrl
      : this.productDetail.bannerImages.descriptionMobileImages && this.productDetail.bannerImages.descriptionMobileImages.length > 0
      ? this.productDetail.bannerImages.descriptionMobileImages[0].startsWith('programDetailsBgImage/Mobile')
        ? `../../../../img/${this.productDetail.bannerImages.descriptionMobileImages[0]}`
        : this.imageBaseUrl + this.productDetail.bannerImages.descriptionMobileImages[0]
      : `../../../../img/${this.productDetail.bannerImages.descriptionMobileImages[0]}`;
    const data = {
      '@context': 'http://schema.org',
      '@type': 'Product',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: this.aggregateRatingSchema.avgrating,
        reviewCount: this.aggregateRatingSchema.totalReviews,
      },
      description: this.productDetail.programDescription.replace(/<[^>]+>/g, ''),
      name: this.productDetail.webProgramName || this.productDetail.customerProgramName,
      image: this.utilsService.buildImageUrl(imageUrl),
      offers: {
        '@type': 'Offer',
        url: this.seoURL,
        availability: 'http://schema.org/InStock',
        price: this.specialProduct.includes(this.productDetail.programId) ? this.productDetail.annualPrice : this.productDetail.monthlyPrice,
        priceCurrency: 'USD',
      },
      review: reviews,
    };
    this.jsonLDService.insertSchema(data);
  }

  reviewSchemaData(event) {
    this.aggregateRatingSchema = event;
    this.checkJsonLdScriptData();
  }

  getImage(imageName) {
    return this.utilsService.buildImageUrl(imageName);
  }

  ngOnDestroy() {
    this.globalVariables.fixedHeaderProdDetails = false;
    this.globalVariables.isAllReviewsClicked = false;
  }

  checkJsonLdScriptData() {
    if (this.aggregateRatingSchema && this.reviewsDetailsSchema) {
      this.addJsonLdScript();
    }
  }

  /* HWR-2761 */
  reviewsFragment(data?: any) {
    const tree = this.router.parseUrl(this.router.url);
    if (tree.fragment === 'reviews' || data === 'reviews') {
      const element = document.querySelector('#review-section');
      if (element) {
        setTimeout(() => {
          $('html, body').animate(
            {
              scrollTop: $('#review-section').offset().top - 170,
            },
            1000,
          );
          // document.body.scrollTop -= 100;
        }, 500);
      }
    }
  }

  scrollToSection(event, sectionId: string) {
    if (this.isbrowser) {
      const tablinks = document.getElementsByClassName('tab-div');
      for (let i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(' active', '');
      }
      event.currentTarget.className += ' active';
      if (this.globalVariables.fixedHeaderProdDetails) {
        $('html, body').animate(
          {
            scrollTop: $(`#${sectionId}`).offset().top - (sectionId === 'review-section' ? 240 : 170),
          },
          1000,
        );
        document.body.scrollTop -= 200;
      } else {
        $('html, body').animate(
          {
            scrollTop: $(`#${sectionId}`).offset().top - 100,
          },
          1000,
        );
      }
      this.selectedTab = sectionId;
    }
  }

  onSectionChange(sectionId: string) {
    this.selectedTab = sectionId ? sectionId.replace('tab-', '') : '';
  }

  setEmitterDivHeight(data) {
    if (data) {
      this.divHeightScrollFromEmitter = data;
      this.sessionStorageService.setItem('divHeightScrollFromEmitter', this.divHeightScrollFromEmitter);
    }
  }

  getSlideChangeIndex(event: number) {
    if (event < this.testimonialSlideIndex) {
      this.testimonialSlidePrevEvent = true;
    } else {
      this.testimonialSlidePrevEvent = false;
    }
    this.testimonialSlideIndex = event;
  }

  loadContent() {
    // this.siteContentService.getPartnerDetails(state ? state.toUpperCase() : '', 'homepage')
    this.utilsService.getPartnerDetails$().subscribe(
      data => {
        const testimonialData = data.body.site.homepage.testimonials.testimonialsItems;
        testimonialData.forEach(testimonial => {
          const imageUrl = this.utilsService.buildImageUrl(
            `${environment.Image_API_Endpoint}${environment.Content_service_API_Endpoint}${testimonial.bgImage.url}`,
          );
          this.testimonials.push({
            image: imageUrl,
            writtenBy: testimonial.author.name,
            place: testimonial.author.place,
            description: `${testimonial.desc}`,
          });
        });
      },
      () => {},
    );
  }
}
