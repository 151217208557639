import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ProductsComponent } from './component/products/products.component';

export const productRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: ProductsComponent,
      },
    ],
  },
  {
    path: '',
    children: [
      {
        path: ':zipcode',
        component: ProductsComponent,
      },
    ],
  },
  {
    path: '',
    children: [
      {
        path: ':zipcode/:locationcode',
        component: ProductsComponent,
      },
    ],
  },
  {
    path: '',
    children: [
      {
        path: ':zipcode/:locationcode/:type',
        component: ProductsComponent,
      },
    ],
  },
  {
    path: '',
    children: [
      {
        path: ':zipcode/:locationcode/:type/:category',
        component: ProductsComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(productRoutes)],
  exports: [RouterModule],
})
export class ProductRoutingModule {}
