import { Component, Input, OnChanges } from '@angular/core';
import { SimpleModalService } from 'ngx-simple-modal';
import { environment } from '../../../../environments/environment';
import { UtilsService } from '../../../core/services/utils.service';

@Component({
  selector: 'hos-product-covered-repairs',
  templateUrl: './covered-repairs.component.html',
  styleUrls: ['covered-repairs.component.scss'],
})
export class ProductsCoveredRepairsComponent implements OnChanges {
  @Input() productDetail: any;

  coveredRepairsData: any = [];

  coveredRepairsHeading: String;

  productServiceBaseUrl: any = `${environment.API_Endpoint}${environment.Product_service_API_Endpoint}`;

  isLeakProgram: Boolean = false;

  bulletTypeCoveredRepairs: any;

  bulletTypeConfig: any = ['Default', 'Numeric', 'Alphabetical', 'Roman Numerals', 'Checkmark', 'No'];

  bulletListStyleType: any;

  constructor(private utilsService: UtilsService, private simpleModalService: SimpleModalService) {}

  ngOnChanges() {
    this.isLeakProgram = false;
    if (this.productDetail) {
      this.coveredRepairsHeading = this.productDetail.coveredRepairsHeading;
      this.bulletTypeCoveredRepairs = this.productDetail.bulletTypeCoveredRepairs;
      this.coveredRepairsData = JSON.parse(JSON.stringify(this.productDetail.coveredRepairs));
      this.bulletListStyleType = this.setBulletType(this.bulletTypeCoveredRepairs);
    }
  }

  scrollToSection(sectionId: string) {
    document.querySelector(`#${sectionId}`).scrollIntoView({
      behavior: 'smooth',
    });
  }

  getImage(imageName) {
    return this.utilsService.buildImageUrl(imageName);
  }

  goToPaymentOptions() {}

  setBulletType(data) {
    switch (data) {
      case this.bulletTypeConfig[0]:
        return 'initial';
      case this.bulletTypeConfig[1]:
        return 'decimal';
      case this.bulletTypeConfig[2]:
        return 'lower-alpha';
      case this.bulletTypeConfig[3]:
        return 'lower-roman';
      case this.bulletTypeConfig[4]:
        return 'checkmark';
      case this.bulletTypeConfig[5]:
        return 'none';
    }
  }
}
