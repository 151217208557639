<input
  [disabled]="disabled"
  type="text"
  maxlength="5"
  class="form-control round-form"
  pattern="^\d{5}?$"
  [ngClass]="{ 'is-invalid': (Zipcode.dirty || isClicked) && !Zipcode.valid, 'checkout-bg': isFromCheckout }"
  name="Zipcode"
  [required]="!isFromCheckout"
  [(ngModel)]="zipcode"
  (input)="onZipcodeChange($event.target.value, Zipcode)"
  (blur)="blurHandler(Zipcode)"
  (keypress)="keyPressHandler(Zipcode); numberOnly($event)"
  #Zipcode="ngModel"
  autocomplete="off"
  [readonly]="isFromCheckout"
/>

<small class="form-text text-danger" *ngIf="(Zipcode.isBlur || isClicked) && !Zipcode.valid && Zipcode.dirty">Zipcode is invalid.</small>
<small class="form-text text-danger" *ngIf="!Zipcode.dirty && isClicked && !Zipcode.valid">Zipcode is required.</small>
<small class="form-text text-danger" *ngIf="notFound || serviceUnavailable">Service is not provided with this Zipcode.</small>
