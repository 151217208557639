import { Component, OnInit, OnDestroy, PLATFORM_ID, Inject, Input } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { Router } from '@angular/router';
import { map, find } from 'lodash-es';
import { nanoid } from 'nanoid';
import { isPlatformBrowser } from '@angular/common';
import { SimpleModalService } from 'ngx-simple-modal';
import { Breadcrumb } from '../../../core/component/breadcrumb/breadcrumb.component';
import { ShoppingCart } from '../../../cart/model/cart.model';
import { CartService } from '../../../cart/services/cart.service';
import { UserService } from '../../services/user.service';
import { SessionStorageService } from '../../../core/services/session-storage.service';
import { ProductService } from '../../../product/services/product.service';
import { LocationService } from '../../../home/services/location.service';
import { TemplateService } from '../../../core/services/template.service';
import { environment } from '../../../../environments/environment';
import { SiteContentService } from '../../../core/services/siteContents';
import { ErrorModalComponent } from '../../../core/component/error-modal/error-modal.component';
import { VeiwServiceAddressComponent } from '../../../core/component/veiw-service-address/veiw-service-address.component';
import { CrmProxyService } from '../../../core/services/crm-proxy.service';
import { UtilsService } from '../../../core/services/utils.service';
import { AlertConfirmModalComponent } from '../../../core/component/alert-confirm-modal/alert-confirm-modal.component';
import { ConfirmModalComponent } from '../../../core/component/confirm-modal/confirm-modal.component';
import { CCPAUpdatesService } from '../../../core/services/ccpa-updates.service';
import { GlobalVariables } from '../../../core/services/global-variables.service';
import { PaymentEtsService } from '../../../payment-ets/services/payment-ets.service';
import { OrdersService } from '../../../payment-ets/services/orders.service';
import { AchTermsModalComponent } from '../../../payment-ets/component/ach-terms-modal/ach-terms-modal.component';
import { CartQuickCheckoutService } from '../../../cart/services/cart-quick-checkout.service';
import { PaymentMethod, PaymentPayload, Stripe } from '../../../models/payment/stripe';

declare let hp: any;

@Component({
  selector: 'hos-quick-checkout-payment-box',
  templateUrl: './quick-checkout-payment-box.component.html',
  styleUrls: ['./quick-checkout-payment-box.component.scss'],
})
export class QuickCheckoutPaymentBoxComponent implements OnInit, OnDestroy {
  customerServiceNumber: String;

  states: any;

  userDetails: any;

  isLoggedIn = false;

  grossTotal: number;

  cartSubscription: any;

  cartData: any;

  selectedAddress;

  customerAddresses: any[];

  email_regex: String;

  isClicked: Boolean;

  address: any = {};

  showAddressBox: Boolean = false;

  showLoader: Boolean = false;

  public cart: Observable<ShoppingCart>;

  public itemCount: number;

  public cartProducts: Array<any>;

  productsByBillingType: any;

  zipcodeValid: Boolean = false;

  arrProductDeleted: Array<String> = [];

  //   billingfrequency: any = 'Monthly';
  cartLocationID: String;

  cartCusNo: String;

  customerGuid: string;

  pageHead: String = 'My Cart';

  enrollBtnStatus: Boolean = false;

  setCardRequired: Boolean = true;

  privacylink: any;

  productServiceBaseUrl: String = environment.Image_API_Endpoint + environment.Product_service_API_Endpoint;

  breadcrumsConfig: Array<Breadcrumb> = [
    {
      title: 'Home',
      link: '/',
      active: false,
    },
    {
      title: 'payment',
      link: '',
      active: true,
    },
  ];

  setCard: any;

  paymentType: string;

  paymentCards: any = [];

  achCards: any = [];

  cuid: String;

  providerState: any;

  siteName: any;

  partnerObj: any = {};

  deletedCartData: any;

  otherProducts: Array<any>;

  condotionToShowAddMoreServiceLink: Boolean = false;

  zipcode: any;

  locData: any;

  locationId: any;

  partnerName: any;

  userId: any;

  specialProduct: Array<String> = [];

  templateDetails: any;

  totalPayOfLDDP: any;

  isRemoved: boolean = false;

  isbrowser: Boolean = true;

  sessionTokenGenerated: boolean = true;

  sapLocations: any;

  @Input() userInfo: any;

  @Input() customerDetails: any;

  @Input() guestToken: any = '';

  @Input() productsByBillingTypeCopy: any;

  customerId: any = '';

  payment = (window as any).PaymentHubWidget;

  productsDataFromTaxAPI: any;

  constructor(
    private cartService: CartService,
    private userService: UserService,
    private paymentEtsService: PaymentEtsService,
    private sessionStorageService: SessionStorageService,
    private productService: ProductService,
    private locationService: LocationService,
    private ordersService: OrdersService,
    private siteContentService: SiteContentService,
    private crmProxyService: CrmProxyService,
    private utilsService: UtilsService,
    private simpleModalService: SimpleModalService,
    private templateService: TemplateService,
    private router: Router,
    private ccpaUpdatesService: CCPAUpdatesService,
    public globalVariables: GlobalVariables,
    @Inject(PLATFORM_ID) private platformId: any,
    private cartQuickCheckoutService: CartQuickCheckoutService,
  ) {
    this.isbrowser = isPlatformBrowser(this.platformId);
    this.email_regex = environment.email_regex;
    this.customerServiceNumber = environment.customer_service_number;
    this.isClicked = false;
    this.specialProduct = environment.special_product;
    this.sapLocations = environment.SAPLocations;
  }

  ngOnDestroy() {
    if (this.cartSubscription) {
      this.cartSubscription.unsubscribe();
    }
  }

  ngOnInit() {
    this.showLoader = true;
    this.router.navigate(['/user/checkout'], {
      queryParams: {
        section: 'etspayment',
      },
    });
    this.cuid = nanoid();
    this.selectedAddress = this.userInfo.address[0];
    const customerNo = this.selectedAddress.customerNo ? this.selectedAddress.customerNo : this.customerDetails.data.customerNo;
    this.customerId = this.customerDetails.data.customerId;
    this.cartCusNo = customerNo;
    // this.cartService.getCustomerCartToLocal(this.customerId);
    this.siteName = this.sessionStorageService.getItem('providerState');
    this.providerState = this.sessionStorageService.getItem('websitePartnerName');
    this.partnerObj = {
      siteName: this.siteName,
      providerName: this.providerState,
    };
    this.getPrivacyPolicyLink();
    this.siteContentService
      .statesdata$()
      .toPromise()
      .then(response => {
        this.states = response;
      });
    this.initializeProductStructure();
    this.userDetails = this.customerDetails.data;
    this.guestToken = this.sessionStorageService.getItem('guestToken');
    if (this.userDetails) {
      this.cartProducts = [];
      this.isLoggedIn = true;
      this.cartQuickCheckoutService.getCustomerCartToLocal(this.customerId, this.guestToken, () => {
        this.cart = this.cartQuickCheckoutService.get();
        this.globalVariables.cartCheckout = JSON.parse(this.sessionStorageService.getItem('cartCheckout'));
        this.cartSubscription = this.cart.subscribe(cart => {
          // cart.items.forEach((obj, index) => {});
          this.cartData = cart;
          this.cartProducts = cart.items;
          this.globalVariables.cartAmount.emit(cart);
          if (this.cartProducts.length) {
            this.cartLocationID = this.cartProducts[0].locationId;
          }
          this.itemCount = this.cartProducts.length;
          this.grossTotal = cart.grossTotal;
        });
        this.globalVariables.fequencyChangeEvent.subscribe(() => {
          this.updateBillingAndTaxInfo(error => {
            if (error) {
              this.showErrorAlert();
            }
          });
        });
        if (this.cartData.serviceAddress === undefined) {
          this.getCustomerAddress(err => {
            if (err) {
              this.showLoader = false;
              this.showErrorAlert();
            }
          });
        }
      });
    } else {
      this.showLoader = false;
      this.showErrorAlert();
    }
    // this.userService.getUserProfile(this.userInfo._id).subscribe(data => {
    //   const userProfile = data.body;
    //   if (data.status === 200) {
    //     this.sessionStorageService.setItem('userPhoneNumber', userProfile.phoneNumber);
    //   }
    // });

    // const serviceAddress = `${this.selectedAddress.serviceAddress.streetNumber} ${this.selectedAddress.serviceAddress.streetName}, ${this.selectedAddress.serviceAddress.city} ${this.selectedAddress.serviceAddress.state} ${this.selectedAddress.serviceAddress.zipcode}`;
    // this.loadPaymentbox(customerGuid, serviceAddress, this.selectedAddress.serviceAddress.zipcode, customerNo);

    if (environment.ccpa_updates_provider.includes(this.providerState) && !this.isRemoved) {
      this.ccpaUpdatesService.getCCPAUpdates();
    }
  }

  /**
   * Calculate total, subtoal & tax amount.
   * @param products
   */
  calculateBybillingType(products: any) {
    products.onbill.subtotal = products.onbill.products.reduce((previous, current) => previous + current.taxData.TaxDetails.EnrollmentPrice, 0);
    products.onbill.tax = products.onbill.products.reduce((previous, current) => previous + current.taxData.TaxDetails.Tax, 0);
    products.offbill.subtotal = products.offbill.products.reduce((previous, current) => previous + current.taxData.TaxDetails.EnrollmentPrice, 0);
    products.offbill.tax = products.offbill.products.reduce((previous, current) => previous + current.taxData.TaxDetails.Tax, 0);
    products.onbill.total = (products.onbill.subtotal + products.onbill.tax).toFixed(2);
    products.offbill.total = (products.offbill.subtotal + products.offbill.tax).toFixed(2);
  }

  /**
   * Get billing type and tax information.
   * @param done
   */
  updateBillingAndTaxInfo(done?: any) {
    if (!!this.selectedAddress && this.selectedAddress.customerGuid && this.cartProducts.length > 0) {
      if (!this.globalVariables.paymentWidgetStripe) {
        $('#hp').empty();
      }
      /* Get product on and off bill type & Tax amount */
      const locationCodeId = this.cartProducts[0].locationId;
      //   let programIds = _.map(this.cartProducts, 'details.program.programId').join(','); // AP HWR-2190
      const cartProductDuplicate: any = JSON.parse(JSON.stringify(this.cartProducts));
      const programIds = map(cartProductDuplicate, 'details.program.programId').join(',');
      const marketingcodeids = map(cartProductDuplicate, 'details.marketingCodeId').join(',');
      const customerId = this.selectedAddress.customerGuid;
      const paymentMethod = 'Check';
      const frequency = this.globalVariables.billingFrequencyCheckout === 'Monthly' ? 'Monthly' : 'Annually';
      let locationCodeData = '';
      this.locationService.getLocationsContent(this.cartProducts[0].locationId).subscribe(
        data => {
          locationCodeData = data.body.locationCode;
          if (programIds) {
            const getProductBillingType = this.crmProxyService.getProductBillTypes(locationCodeId, programIds, marketingcodeids);
            const getProducttaxAmount = this.crmProxyService.getProductsTaxCheckout(
              this.guestToken,
              customerId,
              marketingcodeids,
              programIds,
              locationCodeData,
              paymentMethod,
              frequency,
            );
            const productDetails = [getProductBillingType, getProducttaxAmount];

            forkJoin(productDetails).subscribe(
              productsData => {
                this.showLoader = true;
                this.productsDataFromTaxAPI = productsData[1].body; // Only Tax data
                this.initializeProductStructure();
                const billingData = productsData[0].body.data;
                const taxData = productsData[1].body.data;
                cartProductDuplicate.forEach(product => {
                  if (product.details.programId.includes('unlimited-')) {
                    product.details.programId = product.details.programId.replace('unlimited-', '');
                  }
                  const matchedBillingData = find(billingData, {
                    programId: product.details.programId,
                  });
                  const matchedtaxData = find(taxData, {
                    marketingCodeId: product.details.marketingCodeId,
                  });
                  if (matchedBillingData.operations.OnBill || (matchedBillingData.operations.OnBill && matchedBillingData.operations.OffBill)) {
                    if (this.globalVariables.billingFrequencyCheckout === 'Annual') {
                      matchedtaxData.TaxDetails = {
                        EnrollmentPrice: matchedtaxData.TaxDetails.EnrollmentPrice / 12,
                        SubTotal: matchedtaxData.TaxDetails.SubTotal / 12,
                        Tax: matchedtaxData.TaxDetails.Tax / 12,
                        Total: matchedtaxData.TaxDetails.Total / 12,
                      };
                    }
                    product.taxData = matchedtaxData;
                    this.productsByBillingType.onbill.products.push(product);
                  } else if (matchedBillingData.operations.OffBill) {
                    product.taxData = matchedtaxData;
                    this.productsByBillingType.offbill.products.push(product);
                  }
                });

                this.calculateBybillingType(this.productsByBillingType);
                if (this.productsByBillingType.offbill.products.length > 0) {
                  // add payment box
                  this.stripeInit();
                } else {
                  $('#hp').empty();
                }
                this.showLoader = false;
                done(false, true);
                // if (leakSaveData.products.length !== 0) {
                //     this.productsByBillingType.leak.products.push(leakSaveData.products[0]);
                //     this.calculateBybillingType(this.productsByBillingType);
                //     done(false, true);
                // } else {
                //     if (leakSaveData.products.length === 0 && (leakProgramId === undefined || leakProgramId === '') ) {
                //         done(false, true);
                //     }
                // }
              },
              () => {
                const trackAPIGATracking = 'trackProductTaxBillingAPIFail';
                this.showErrorAlertAndReload(trackAPIGATracking);
                done(true, null);
              },
            );
          }
        },
        () => {
          this.showErrorAlertAndReload();
          done(true, null);
        },
      );
    } else {
      if (this.cartProducts.length > 0) {
        this.showErrorAlert();
      }
      this.initializeProductStructure();
      done(true, null);
    }
  }

  /**
   * Get customer addresses.
   * @param done
   */
  getCustomerAddress(done?: any) {
    this.userService.getAllCustomerAddresses(this.customerId, this.guestToken).subscribe(
      data => {
        this.customerAddresses = [];
        const results = data.body;
        results.forEach(result => {
          if (result.customerNo === this.cartCusNo) {
            this.customerAddresses.push(result);
          }
        });
        // this.updateEnrolledAddress(this.customerAddresses);
        if (this.customerAddresses.length) {
          this.locationService.getLocationCode(this.customerAddresses[0].serviceAddress.zipcode).subscribe(() => {
            if (this.customerAddresses && this.customerAddresses.length === 1) {
              this.enrollAddress(this.customerAddresses[0]);
            } else if (this.customerAddresses && this.customerAddresses.length > 1) {
              this.enrollAddress(this.customerAddresses[0]);
            } else {
              this.showLoader = false;
            }
            if (done) {
              done(false, true);
            }
          });
        } else {
          this.showLoader = false;
        }
      },
      () => {
        if (done) {
          done(true, null);
        }
      },
    );
  }

  /**
   * Get customer existing cards and ACH accouts.
   * @param done
   */
  getCustomerPayments(done?: any) {
    this.paymentCards = [];
    this.achCards = [];
    this.setCard = 'newCard';
    this.setCardRequired = false;
    if (done) {
      done(true, null);
    }
  }

  /**
   * initialize onbill off bill product structure.
   */
  initializeProductStructure() {
    this.productsByBillingType = {
      onbill: {
        total: 0,
        subtotal: 0,
        tax: 0,
        products: [],
      },
      offbill: {
        total: 0,
        subtotal: 0,
        tax: 0,
        products: [],
      },
    };
  }

  /**
   * Format expiry date.
   * @param str
   * @param index
   * @param value
   */
  changeExpDate(str, index, value) {
    const getCurrentYearPrefix = new Date().getFullYear().toString().slice(0, 2);
    return str.substr(0, index) + value + getCurrentYearPrefix + str.substr(index);
  }

  /**
   * Set existing selected card and payment type.
   * @param cardData
   * @param paymentType
   */
  selectCard(cardData: any, paymentType: string) {
    this.setCard = cardData;
    this.paymentType = paymentType;
  }

  /**
   * On submit existing payment selection.
   */
  // useExistCard() {
  //   this.submitEtsPaymentInfo(this.setCard, 'exist', this.paymentType);
  // }

  /**
   * Load ETS payment box.
   * @param customerGuid
   * @param serviceAddress
   * @param zipcode
   * @param cusno
   */
  loadPaymentbox(customerGuid: String, serviceAddress: any, zipcode: any, cusno: any) {
    this.sessionTokenGenerated = false;
    this.userService.etsSignInToken(this.guestToken).subscribe(
      data => {
        this.sessionTokenGenerated = true;
        this.showLoader = false;
        const response = data.body;
        if (response.data && response.data.signInResponse && response.data.signInResponse.token) {
          const pluginOptions = {
            baseUrl: environment.hosted_payments_base_url,
            apiKey: response.data.signInResponse.token,
            paymentService: hp.PaymentService.TOKEN,
            instrumentId: customerGuid,
            paymentTypeOrder: [0, 1],
            saveCustomer: true,
            correlationId: cusno,
            billingAddress: {
              addressLine1: serviceAddress,
              postalCode: zipcode,
            },
            transactionId: this.guid(),
            successCallback: resp => {
              this.submitEtsPaymentInfo(resp, 'new');
              // add payment box
            },
            errorCallback: () => {
              this.errorPaymentCallback();
            },
          };
          /* IE loader issue AP March 16 2020 */
          const isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
          if (isIEOrEdge) {
            this.showLoader = false;
          }
          (<any>$('#hp')).hp(pluginOptions);
          hp.Utils.reset(pluginOptions);
        }
      },
      () => {
        this.sessionTokenGenerated = true;
        this.showErrorModal(
          'Error',
          `We are currently facing technical difficulties, please try to add your payment method again. Sorry for any inconvenience.`,
        );
      },
    );
  }

  /**
   * Submit ETS payment info.
   * @param res
   * @param cardType
   * @param paymentType
   */
  submitEtsPaymentInfo(res: any, cardType?: string, stripeArrayRes?: any, paymentType?: string) {
    const payload: any = {};
    this.showLoader = true;
    payload.orderId = this.cuid;
    payload.customerId = this.customerId;
    const onbillPayload: Array<any> = [];

    if (cardType !== 'new') {
      payload.PaymentType = paymentType;
      if (paymentType === 'CC') {
        payload.FinancialInstrumentToken = res.CreditCardToken;
        payload.Last4Digits_CC = res.AccountNumberMasked.substr(res.AccountNumberMasked.length - 4);
        payload.ExpirationDate = res.ExpirationDate;
        payload.CardType = res.CardType;
      } else if (paymentType === 'ACH') {
        payload.FinancialInstrumentToken = res.AchAccountToken;
        payload.achAccountNo = res.AccountNumberMasked.substr(res.AccountNumberMasked.length - 4);
        payload.achRoutingNo = res.RoutingNumberMasked.substr(res.RoutingNumberMasked.length - 4);
      }
      payload.etsAccountName = res.AccountHolderName;
      payload.ETSCustomerId = res.CustomerToken;
    } else {
      if (res.instrument_type === 'ACH') {
        payload.PaymentType = res.instrument_type;
        payload.achAccountNo = res.instrument_last_four;
        payload.achRoutingNo = res.instrument_routing_last_four;
      } else {
        payload.PaymentType = 'CC';
        payload.Last4Digits_CC = res.instrument_last_four;
        payload.ExpirationDate = res.instrument_expiration_date;
        payload.CardType = res.instrument_type;
      }
      payload.FinancialInstrumentToken = res.customer_token;
      payload.ETSCustomerId = res.instrument_id;
      payload.etsAccountName = res.customer_name;
    }

    payload.fingerprint = res.fingerprint;
    payload.bankAccountType = res.bankAccountType;
    payload.oracleCustId = this.selectedAddress.oracleCustId;
    payload.oracleAccountNumber = this.userInfo.oracleAccountNumber || this.customerDetails.data.oracleAccountNumber;
    payload.CardType = res.CardType;
    payload.billingType = 'offbill';
    payload.isFromCheckout = true;
    payload.orders = [];
    // const resOfError = res.filter(data => data.error);
    // if (resOfError && resOfError.length > 0) {
    //   this.productsByBillingType.offbill.products.map(item1 => {
    //     const marketingCode = item1.details.marketingCode;
    //     resOfError.forEach(item2 => {
    //       if (item2.referenceNumber.includes(marketingCode)) {
    //         item1.stripeError = item2.error;
    //       }
    //     });
    //     return item1;
    //   });
    //   console.log(this.productsByBillingType);
    //   this.calculateBybillingType(this.productsByBillingType);
    // }
    if (this.productsByBillingType.offbill.products.length > 0 && this.productsByBillingType.onbill.products.length > 0) {
      this.productsByBillingType.onbill.products.forEach(item => {
        const orderItem: any = {
          cartId: this.cartData._id,
          cartItemId: item._id,
          customerId: this.customerId,
          address: this.cartData.serviceAddress,
          productId: item.productId,
          billingFrequency: 'Monthly', // default all onbill orders are monthly
          crmCustomerGuid: this.selectedAddress.customerGuid,
          crmCustomerNumber: this.selectedAddress.customerNo,
          enrollmentPrice: item.taxData,
          locationId: item.locationId,
          partner: this.partnerObj,
          description: item.description,
          enrollmentGuid: item.enrollmentGuid ? item.enrollmentGuid : '',
          isUpgrade: !!item.isUpgrade,
        };
        onbillPayload.push(orderItem);
      });
      const allOnBillProductPayload = [];
      onbillPayload.forEach(product => {
        const tepmPayload = {
          order: product,
          billingType: 'onbill',
          orderId: this.cuid,
          isFromCheckout: true,
        };
        allOnBillProductPayload.push(tepmPayload);
      });
      this.enrollmentRequest(allOnBillProductPayload, err => {
        if (err) {
          this.showLoader = false;
          this.showErrorAlert();
        }
      });
      // Order related data for Leak Detection AP
      this.productsByBillingType.offbill.products.forEach(item => {
        const checkRefNumber = stripeArrayRes.find(ele => ele.referenceNumber.includes(item.details.marketingCode));
        const stripeReferenceNumber = checkRefNumber ? checkRefNumber.referenceNumber : "";
        const productData = this.productsByBillingTypeCopy.offbill.products.filter(item2 => {
          if (item.details.marketingCodeId === item2.details.marketingCodeId) {
            return true;
          }
          return false;
        });
        if (productData[0] && productData[0].isAutoRenewStatus) {
          item.isAutoRenew = productData[0].isAutoRenew;
        }
        const orderItem: any = {
          cartId: this.cartData._id,
          cartItemId: item._id,
          address: this.cartData.serviceAddress,
          productId: item.productId,
          billingFrequency: this.globalVariables.billingFrequencyCheckout,
          crmCustomerGuid: this.selectedAddress.customerGuid,
          crmCustomerNumber: this.selectedAddress.customerNo,
          enrollmentPrice: item.taxData,
          locationId: item.locationId,
          partner: this.partnerObj,
          isAutoRenew: [undefined, 'undefined', null, 'null', ''].includes(item.isAutoRenew) ? true : item.isAutoRenew,
          enrollmentGuid: item.enrollmentGuid ? item.enrollmentGuid : '',
          isUpgrade: !!item.isUpgrade,
          stripeReferenceNumber
        };
        const matchingStripeObject = stripeArrayRes.find(ele => ele.referenceNumber.includes(item.details.marketingCode));
        if (matchingStripeObject) {
          orderItem.upfrontPaymentToken = matchingStripeObject.upfrontPaymentToken;
          orderItem.FinancialInstrumentToken = matchingStripeObject.customer_token;
        }
        if (typeof item.isRenew !== 'undefined' && item.isRenew === true) {
          orderItem.parentEnrollmentDate = item.parentEnrollmentDate;
          orderItem.isRenew = true;
          orderItem.parentEnrollmentId = item.parentEnrollmentId;
          orderItem.isUpgrade = false;
        }
        if (item.stripeError) {
          orderItem.stripeError = item.stripeError;
        }
        payload.orders.push(orderItem);
      });
      delete payload.FinancialInstrumentToken;
      // offbill payment async call
      this.ordersService.dataFromEts(payload, this.customerId, this.guestToken).subscribe(
        () => {
          this.globalVariables.billingFrequencyCheckout = 'Monthly';
          // this.sendEnrollmentEmail(this.cuid);
          this.updateSuccessCartStatus(errCart => {
            this.showLoader = false;
            if (!errCart) {
              const queryParam = {
                queryParams: {
                  orderId: this.cuid,
                },
              };
              this.globalVariables.checkoutOrderCuid = this.cuid;
              this.globalVariables.showCheckoutPaymentReponsePage = true;
              this.router.navigate(['user/checkout'], queryParam);
              /** Resetting the Local User Cart Data */
              this.sessionStorageService.deleteItem('user_data');
            } else {
              this.showErrorModal('Error', 'Error while updating cart status.');
            }
          });
        },
        error => {
          const element: HTMLElement = document.getElementById('checkout_order_error') as HTMLElement;
          element.click();
          this.showLoader = false;
          if (error.status === 500) {
            this.showErrorAlert();
          } else if (error.status === 400) {
            this.retryPaymentAlert(alertRes => {
              if (alertRes) {
                /* const serviceAddress = `${this.selectedAddress.serviceAddress.streetNumber} ${this.selectedAddress.serviceAddress.streetName}, ${this.selectedAddress.serviceAddress.city} ${this.selectedAddress.serviceAddress.state} ${this.selectedAddress.serviceAddress.zipcode}`;
                this.loadPaymentbox(
                  this.selectedAddress.customerGuid,
                  serviceAddress,
                  this.selectedAddress.serviceAddress.zipcode,
                  this.selectedAddress.customerNo,
                ); */
              }
            });
          }
        },
      );
    } else {
      // Offbill product only
      this.productsByBillingType.offbill.products.forEach(item => {
        const checkRefNumber = stripeArrayRes.find(ele => ele.referenceNumber.includes(item.details.marketingCode));
        const stripeReferenceNumber = checkRefNumber ? checkRefNumber.referenceNumber : "";
        const productData = this.productsByBillingTypeCopy.offbill.products.filter(item2 => {
          if (item.details.marketingCodeId === item2.details.marketingCodeId) {
            return true;
          }
          return false;
        });
        if (productData[0] && productData[0].isAutoRenewStatus) {
          item.isAutoRenew = productData[0].isAutoRenew;
        }
        const orderItem: any = {
          cartId: this.cartData._id,
          cartItemId: item._id,
          address: this.cartData.serviceAddress,
          productId: item.productId,
          billingFrequency: this.globalVariables.billingFrequencyCheckout,
          crmCustomerGuid: this.selectedAddress.customerGuid,
          crmCustomerNumber: this.selectedAddress.customerNo,
          enrollmentPrice: item.taxData,
          locationId: item.locationId,
          partner: this.partnerObj,
          isAutoRenew: [undefined, 'undefined', null, 'null', ''].includes(item.isAutoRenew) ? true : item.isAutoRenew,
          enrollmentGuid: item.enrollmentGuid ? item.enrollmentGuid : '',
          isUpgrade: !!item.isUpgrade,
          stripeReferenceNumber
        };
        const matchingStripeObject = stripeArrayRes.find(ele => ele.referenceNumber.includes(item.details.marketingCode));
        if (matchingStripeObject) {
          orderItem.upfrontPaymentToken = matchingStripeObject.upfrontPaymentToken;
          orderItem.FinancialInstrumentToken = matchingStripeObject.customer_token;
        }
        if (typeof item.isRenew !== 'undefined' && item.isRenew === true) {
          orderItem.parentEnrollmentDate = item.parentEnrollmentDate;
          orderItem.isRenew = true;
          orderItem.parentEnrollmentId = item.parentEnrollmentId;
          orderItem.isUpgrade = false;
        }
        if (item.stripeError) {
          orderItem.stripeError = item.stripeError;
        }
        payload.orders.push(orderItem);
      });
      delete payload.FinancialInstrumentToken;
      // offbill payment async call
      this.ordersService.dataFromEts(payload, this.customerId, this.guestToken).subscribe(
        () => {
          this.globalVariables.billingFrequencyCheckout = 'Monthly';
          // this.sendEnrollmentEmail(this.cuid);
          this.updateSuccessCartStatus(errCart => {
            this.showLoader = false;
            if (!errCart) {
              const queryParam = {
                queryParams: {
                  orderId: this.cuid,
                },
              };
              this.router.navigate(['user/checkout'], queryParam);
              this.globalVariables.checkoutOrderCuid = this.cuid;
              this.globalVariables.showCheckoutPaymentReponsePage = true;
              /** Resetting the Local User Cart Data */
              this.sessionStorageService.deleteItem('user_data');
            } else {
              this.showErrorModal('Error', 'Error while updating cart status.');
            }
          });
        },
        error => {
          const element: HTMLElement = document.getElementById('checkout_order_error') as HTMLElement;
          element.click();
          this.showLoader = false;
          if (error.status === 500) {
            this.showErrorAlert();
          } else if (error.status === 400) {
            this.retryPaymentAlert(alertRes => {
              if (alertRes) {
                /* const serviceAddress = `${this.selectedAddress.serviceAddress.streetNumber} ${this.selectedAddress.serviceAddress.streetName}, ${this.selectedAddress.serviceAddress.city} ${this.selectedAddress.serviceAddress.state} ${this.selectedAddress.serviceAddress.zipcode}`;
                this.loadPaymentbox(
                  this.selectedAddress.customerGuid,
                  serviceAddress,
                  this.selectedAddress.serviceAddress.zipcode,
                  this.selectedAddress.customerNo,
                ); */
              }
            });
          }
        },
      );
    }
  }

  /**
   * Enroll on and offbill products.
   */
  enrollProducts() {
    const onbillOrderRequests: Array<any> = [];
    this.showLoader = true;
    // make call to enrollment service and redirect to payment/success
    this.productsByBillingType.onbill.products.forEach(item => {
      const orderItem: any = {
        cartId: this.cartData._id,
        cartItemId: item._id,
        customerId: this.customerId,
        address: this.cartData.serviceAddress,
        productId: item.productId,
        billingFrequency: 'Monthly', // default all onbill orders are monthly
        crmCustomerGuid: this.selectedAddress.customerGuid,
        crmCustomerNumber: this.selectedAddress.customerNo,
        enrollmentPrice: item.taxData,
        locationId: item.locationId,
        partner: this.partnerObj,
        description: item.description,
        enrollmentGuid: item.enrollmentGuid ? item.enrollmentGuid : '',
        isUpgrade: !!item.isUpgrade,
      };
      /* if (typeof(item.renewNow) !== 'undefined' && item.renewNow === 'true') {
        orderItem.parentEnrollmentDate = item.parentEnrollmentDate;
        orderItem.renewNow = true;
        orderItem.parentEnrollmentId = item.parentEnrollmentId;
        orderItem.isUpgrade = false;
      } */
      onbillOrderRequests.push(orderItem);
    });

    if (!(this.productsByBillingType.offbill.products.length > 0) && this.productsByBillingType.onbill.products.length > 0) {
      // On bill enrollment
      const allOnBillProductPayload = [];
      onbillOrderRequests.forEach(product => {
        const tepmPayload = {
          order: product,
          billingType: 'onbill',
          orderId: this.cuid,
          isFromCheckout: true,
        };
        allOnBillProductPayload.push(tepmPayload);
      });
      this.enrollmentRequest(allOnBillProductPayload, err => {
        if (!err) {
          // this.sendEnrollmentEmail(this.cuid);
          this.updateSuccessCartStatus(errCart => {
            this.showLoader = false;
            if (!errCart) {
              const queryParam = {
                queryParams: {
                  orderId: this.cuid,
                },
              };
              this.globalVariables.billingFrequencyCheckout = 'Monthly';
              this.router.navigate(['user/checkout'], queryParam);
              this.globalVariables.checkoutOrderCuid = this.cuid;
              this.globalVariables.showCheckoutPaymentReponsePage = true;
              /** Resetting the Local User Cart Data */
              this.sessionStorageService.deleteItem('user_data');
            } else {
              this.showErrorModal('Error', 'Error while updating cart status.');
            }
          });
        } else {
          this.showLoader = false;
          this.showErrorAlert();
        }
      });
    } else {
      this.showLoader = false;
    }
  }

  /**
   * Update cart status to complete.
   * @param done
   */
  updateSuccessCartStatus(done: any) {
    this.cartQuickCheckoutService.updateProductCartStatus('ASYNC_IN_PROGRESS', errCart => {
      if (!errCart) {
        done(null, true);
      } else {
        done(true, false);
      }
    });
  }

  /**
   * Error payment callback.
   * @param res
   */
  errorPaymentCallback() {
    this.showLoader = true;
    this.cartQuickCheckoutService.updateProductCartStatus('PAYMENT_DECLINED', () => {
      this.showLoader = false;
      this.showErrorAlert();
    });
  }

  /**
   * Multiple enrollment request.
   * @param products
   * @param done
   */
  enrollmentRequest(products: any, done: any) {
    const guestToken: any = this.sessionStorageService.getItem('guestToken');
    const apiArray = products.map(product => this.ordersService.createOrder(product, product.billingType, this.customerId, guestToken));

    forkJoin(apiArray).subscribe({
      next: results => {
        const element: HTMLElement = document.getElementById('checkout_order_error') as HTMLElement;
        element.click();
        done(null, results);
      },
      error: err => done(true, err),
    });
  }

  /**
   * Send enrollment email on success of payment.
   * @param orderId
   */
  sendEnrollmentEmail(orderId: any) {
    this.ordersService.sendEnrollmentEmail(orderId, this.partnerObj, this.customerId).subscribe(() => {});
  }

  /**
   * Generate GUID.
   */
  guid() {
    return `${this.s4() + this.s4()}-${this.s4()}-${this.s4()}-${this.s4()}-${this.s4()}${this.s4()}${this.s4()}`;
  }

  /**
   * Random 4 character string.
   * @param product
   */
  s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  /**
   * Address selection.
   * @param address
   */
  enrollAddress(address) {
    this.selectedAddress = address;
    this.customerGuid = this.selectedAddress.customerGuid;
    this.getLocationCode(address);
    this.showLoader = true;
    this.getCustomerPayments();
    this.updateBillingAndTaxInfo(error => {
      this.showLoader = false;
      if (error) {
        this.showLoader = false;
      }
    });
  }

  /**
   * Get Location code.
   * @param address
   */
  getLocationCode(address: any) {
    this.locationService.getLocationCode(address.serviceAddress.zipcode).subscribe(
      result => {
        if (result.status === 200) {
          this.cartData.serviceAddress = address;
          this.mapCartProducts(this.cartCusNo);
        }
      },
      () => {
        this.cartQuickCheckoutService.updateCartOnCustomerNo('', (err, data) => {
          if (data) {
            this.arrProductDeleted = data;
          }
        });
      },
    );
  }

  /**
   * Map cart products.
   * @param customerNo
   */
  mapCartProducts(customerNo: String) {
    // - made changes on base of customerNo
    this.cartQuickCheckoutService.updateCartOnCustomerNo(customerNo, (error, data) => {
      if (data) {
        this.arrProductDeleted = data;
      }
    });
  }

  /**
   * Change address.
   */
  changeEnrollAddress() {
    this.selectedAddress = '';
  }

  /**
   * Set payment frequency.
   * @param frequency
   */
  setPaymentFrequency(frequency: string) {
    if (frequency === 'month') {
      this.globalVariables.billingFrequencyCheckout = 'Monthly';
      //   this.globalVariables.billingFrequency = this.billingfrequency;
      //   hp.Utils.reset();
    } else if (frequency === 'year') {
      this.globalVariables.billingFrequencyCheckout = 'Annual';
      //   this.globalVariables.billingFrequency = this.billingfrequency;
      //   hp.Utils.reset();
    }
    this.showLoader = true;
    this.updateBillingAndTaxInfo(() => {
      this.showLoader = false;
    });
  }

  /**
   * Remove product from cart.
   * @param product
   */
  removeProduct(product: any) {
    this.showLoader = true;
    this.cartQuickCheckoutService.removeItem(product, err => {
      const cartData = this.cartQuickCheckoutService.get();
      this.cartSubscription = cartData.subscribe(cart => {
        this.cartProducts = cart.items;
        if (this.cartProducts.length === 0) {
          this.globalVariables.billingFrequencyCheckout = 'Monthly';
        }
      });
      this.isRemoved = true;
      if (err) {
        this.showErrorModal('Error', 'Error removing item from cart');
      }
      this.ngOnInit();
      this.setCard = '';
    });
  }

  /**
   * Go to payment page.
   * @param product
   */
  enrollProcess() {
    this.cartProducts.forEach(() => {
      this.enrollBtnStatus = true;
    });
    this.pageHead = 'Check Out';
    this.enrollProducts();
  }

  getTemplateId(template_data: any, templateid: any, done: any) {
    this.templateService.getOrderTemplate(template_data, templateid).subscribe(
      data => {
        this.templateDetails = data.body;

        if (this.templateDetails.msg) {
          done(this.templateDetails.msg);
        } else {
          done(null);
        }
      },
      () => {
        done(null);
      },
    );
  }

  enrollProcessCancel() {
    this.enrollBtnStatus = false;
    this.pageHead = 'My Cart';
    this.enrollProducts();
    if (this.isbrowser) {
      window.scrollTo(0, 0);
    }
  }

  /**
   * Show error message.
   * @param title
   * @param message
   */
  showErrorModal(title: string, message: string) {
    this.simpleModalService
      .addModal(
        ErrorModalComponent,
        {
          title,
          message,
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: true,
        },
      )
      .subscribe(() => {});
  }

  /**
   * Edit billing address.
   * @param serviceAddress
   */
  editAddress(serviceAddress) {
    this.simpleModalService
      .addModal(
        VeiwServiceAddressComponent,
        {
          address: serviceAddress,
          userId: this.userInfo._id,
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: true,
        },
      )
      .subscribe(isConfirmed => {
        if (isConfirmed) {
          this.getCustomerAddress();
        }
      });
  }

  /**
   * Update endolled address.
   * @param address
   */
  updateEnrolledAddress(address) {
    if (!!this.selectedAddress && this.selectedAddress.serviceAddress._id) {
      address.forEach(data => {
        if (data.serviceAddress._id === this.selectedAddress.serviceAddress._id) {
          this.selectedAddress = data;
        }
      });
    }
  }

  getPrivacyPolicyLink() {
    this.utilsService.getPartnerDetails$().subscribe(response => {
      const menu = response.body.site.sideMenu;
      this.privacylink = find(menu, link => {
        if (link.label === 'Privacy Policy') {
          return link;
        }
      });
    });
  }

  showErrorAlert() {
    this.simpleModalService
      .addModal(
        AlertConfirmModalComponent,
        {
          title: 'We have encountered a problem with your enrollment',
          message: `<p style="margin-top:-20px;margin-bottom:0;">Please call customer service to ensure your enrollment has completed and you are protected. <b><a href="tel:${this.customerServiceNumber}">${this.customerServiceNumber}</a></b></p>`,
          alertIcon: 'fa fa-exclamation-circle red',
          cancelText: ' ',
          confirmText: 'Close Message',
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: false,
        },
      )
      .subscribe(isConfirmed => {
        if (isConfirmed) {
          // this.router.navigate(['cart']);
        }
      });
  }

  showErrorAlertAndReload(trackAPIGATracking?: any) {
    this.simpleModalService
      .addModal(
        AlertConfirmModalComponent,
        {
          title: 'We have encountered a problem with your enrollment',
          message:
            '<p style="margin-top:-20px;margin-bottom:0;">We are currently facing technical difficulties.Please try again after sometime. Sorry for any inconvenience. <br> <br> Please call the number at the top of page to speak to a Customer Service Representative.</p>',
          alertIcon: 'fa fa-exclamation-circle red',
          cancelText: ' ',
          confirmText: 'Close Message',
          confirmBtnId: trackAPIGATracking || 'close',
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: false,
        },
      )
      .subscribe(isConfirmed => {
        if (isConfirmed) {
          window.location.reload();
        }
      });
  }

  retryPaymentAlert(done?: any) {
    this.simpleModalService
      .addModal(
        AlertConfirmModalComponent,
        {
          title: 'We are experiencing difficulties completing your payment.',
          message: 'Please try again or select a different payment method.',
          alertIcon: 'fa fa-exclamation-circle red',
          cancelText: ' ',
          confirmText: 'Continue',
          confirmBtnId: 'ga-ets-payment-alert',
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: false,
        },
      )
      .subscribe(isConfirmed => {
        if (isConfirmed) {
          if (done) {
            done(true);
          }
        } else if (isConfirmed === false) {
          if (done) {
            done(false);
          }
        } else if (isConfirmed === undefined) {
          if (done) {
            done(false);
          }
        }
      });
  }

  getMaskedAccountNumber(string) {
    return string.substr(string.length - 4, 4);
  }

  getImage(imageName) {
    return this.utilsService.buildImageUrl(imageName);
  }

  openAchTerms() {
    this.simpleModalService.addModal(
      AchTermsModalComponent,
      {
        title: '',
        message: '',
      },
      {
        wrapperClass: 'modal-main-class in',
        closeOnClickOutside: true,
      },
    );
  }

  renewProduct(product?: any) {
    this.simpleModalService
      .addModal(
        ConfirmModalComponent,
        {
          title: '',
          message: '',
          data: product,
          description:
            "By selecting 'Do Not Auto Renew',your program will not automatically renew at the conclusion of the program term and you will be required to contact American Water Resources to re-activate your protection for another 12 months to prevent a lapse in coverage",
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: false,
        },
      )
      .subscribe(() => {
        product.isAutoRenewStatus = 'Auto Renews Annually';
        if (!product.isAutoRenew) {
          product.isAutoRenewStatus = 'Doesn`t Auto Renew';
        }
      });
  }

  getTodayDate() {
    let today: any = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const yyyy = today.getFullYear();
    today = `${mm}/${dd}/${yyyy}`;
    return today;
  }

  getEFTCustomerAccount(customerGuid) {
    return new Promise(resolve => {
      let results = {};
      this.userService.getCustomerAccounts(customerGuid).subscribe(
        data => {
          results = data.body.data;
          resolve({
            error: false,
            response: results,
          });
        },
        () => {
          resolve({
            error: true,
            response: {},
          });
        },
      );
    });
  }

  fetchToken(email: any) {
    this.userService.getGuestToken(email).subscribe(async res => {
      if (res.status === 200) {
        this.guestToken = res.body.authHeader;
      }
    });
  }

  private stripeInit() {
    if (document.getElementById('stripe-payment-quick-checkout').innerHTML) {
      document.getElementById('stripe-payment-quick-checkout').innerHTML = '';
    }
    const stripe = new Stripe();
    const paymentMethods = [PaymentMethod.CARD, PaymentMethod.ACH];
    const element = document.getElementById('stripe-payment-quick-checkout');
    const payload: PaymentPayload = {
      customer: {
        oracleCustomerId: this.userInfo.oracleAccountNumber || this.customerDetails.data.oracleAccountNumber,
        oracleCustomerGuid: this.selectedAddress.customerGuid || this.customerDetails.data.customerGuid,
        postalCode: this.selectedAddress.serviceAddress.zipcode,
        fullName: `${this.selectedAddress.firstName ?? ''} ${this.selectedAddress.lastName ?? ''}`,
        email: this.userInfo.email, // US Bank extra field
      },
      // amount: this.productsByBillingType.offbill.total,
      mountNode: element,
      paymentMethods,
      enrollments: this.cartProducts.map((result, index) => ({
        referenceNumber: `UPFRONT-${result.details.marketingCode}-${this.selectedAddress.oracleCustId}-${(index + 1).toString().padStart(2, '0')}`,
        amount: this.productsDataFromTaxAPI.data[index].TaxDetails.Total.toFixed(2),
      })),
    };
    if (environment.hide_autorenew_states.includes(this.selectedAddress.serviceAddress.state)) {
      stripe
        .updatePaymentMethod(payload)
        .then(p => {
          this.submitEtsPaymentInfo(p[0], 'new', p);
        })
        .catch(() => this.errorPaymentCallback());
    } else {
      stripe
        .makeGeneralPayment(payload)
        .then(p => {
          const resOfError = p.filter(data => data.error);
          if (resOfError && resOfError.length > 0) {
            this.productsByBillingType.offbill.products.map(item1 => {
              const marketingCode = item1.details.marketingCode;
              resOfError.forEach(item2 => {
                if (item2.referenceNumber.includes(marketingCode)) {
                  item1.stripeError = item2.error;
                }
              });
              return item1;
            });
            console.log(this.productsByBillingType);
            this.calculateBybillingType(this.productsByBillingType);
          }
          this.submitEtsPaymentInfo(p[0], 'new', p);
        })
        .catch(err => {
          console.log(err);
          this.errorPaymentCallback();
        });
    }
  }
}
