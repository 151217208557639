<section class="banner-box account-header" [ngStyle]="{ 'background-image': 'url(' + bannerImage + ')' }">
  <div class="content-box">
    <div class="row no-gutters account-details fs-restrict-dead-clicks">
      <div class="col-12 col-md-3">
        <div class="row no-gutters customer-name-no align-items-center">
          <div class="col-4 col-md-4">
            <div class="awr-icon d-flex align-items-center justify-content-center"><img alt="HOS Logo Icon" src="img/logo/AW_logo_icon.svg" /></div>
          </div>
          <div class="col-8 col-md-8">
            <!-- showing logged in user name instead of customer profile name  -->
            <div class="text-capitalize cus-name" data-trigger="hover" data-placement="top" title="{{ firstName ? firstName : lastName }}">
              {{ firstName ? firstName : lastName }}
            </div>
            <div class="cus-number">
              <span *ngIf="!cusNo || cusNo === 'no_custNo'">Processing customer no..</span>
              <span class="notranslate" *ngIf="cusNo && cusNo !== 'no_custNo'">{{ cusNo }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-5">
        <div class="row no-gutters navigation-block align-items-center">
          <div class="col-5">
            <div class="my-acc-nav d-flex align-items-center justify-content-center mr-0" (click)="gotoLink()">
              <img alt="My Property Icon" src="img/my-account/icons/MyAccount.svg" class="my-property-icon" />
              <span class="title">{{ page }}</span>
            </div>
          </div>
          <div class="col-7 d-inline-flex">
            <div class="profile-property text-center mr-0" (click)="gotoMyProfile()">
              <img alt="My Profile Icon" src="img/my-account/icons/MyProfile.svg" class="my-profile-icon mb-1 mt-2" />
              <div class="title my-profile-text ml-2 mr-2">{{ myProfileText }}</div>
            </div>
            <div
              class="profile-property text-center"
              [ngClass]="{ 'ga-track-add-property': addPropertyText === 'Add Property' }"
              (click)="gotoAddProperty()"
            >
              <img
                alt="Add Property Icon"
                [ngClass]="{ 'ga-track-add-property': addPropertyText === 'Add Property' }"
                src="img/my-account/icons/AddProperty.svg"
                class="add-property-icon mt-2 mb-1"
              />
              <div class="title add-property-text ml-2 mr-2" [ngClass]="{ 'ga-track-add-property': addPropertyText === 'Add Property' }">
                {{ addPropertyText }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-3">
        <div class="row no-gutters acc-service-charges align-items-center">
          <div class="my-property" (click)="gotoMyAccount()">
            <div class="d-flex align-items-center justify-content-center">
              <div
                class="property-count p-2 d-flex align-items-center justify-content-center"
                [style.font-size]="(totalProperties || totalAddresses).length > 2 ? '25px' : '36px'"
              >
                {{ totalProperties || totalAddresses }}
              </div>
            </div>
            <div class="myacc-title">Properties</div>
          </div>
          <div class="enrolled-services" (click)="gotoMyAccount()">
            <div class="d-flex align-items-center justify-content-center">
              <div
                class="service-count p-2 d-flex align-items-center justify-content-center"
                [style.font-size]="(history?.totalServices).length > 2 ? '25px' : '36px'"
              >
                {{ history?.totalServices }}
              </div>
            </div>
            <div class="myacc-title">Enrolled Services</div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-1">
        <div class="search-block d-flex align-items-center">
          <div class="row no-gutters align-items-center search-box">
            <div class="col-4 col-sm-12 col-md-12 col-lg-12">
              <div class="check-service-avail mt-1">Check Service Availability</div>
            </div>
            <div class="col-8 col-sm-12 col-md-12 col-lg-12">
              <div class="input-group zipcode-search">
                <input
                  type="text"
                  name="zipcode"
                  maxlength="5"
                  class="form-control"
                  placeholder="Enter Zip Code"
                  [(ngModel)]="searchZipCode"
                  (keypress)="numberOnly($event)"
                />
                <div class="input-group-append">
                  <span class="input-group-text"
                    ><i class="fa fa-search search-icon" aria-hidden="true" id="ga-track-account-search" (click)="getLocationCode(searchZipCode)"></i
                  ></span>
                </div>
              </div>
              <div><small class="form-text text-danger error-text" *ngIf="isInvalid">Please enter valid zipcode.</small></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
