import { Component, OnInit, Input, OnChanges, Output, EventEmitter, Inject, Optional, PLATFORM_ID } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SimpleModalService } from 'ngx-simple-modal';
import { Location, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { LocationService } from '../../../../../home/services/location.service';
import { SessionStorageService } from '../../../../../core/services/session-storage.service';
import { environment } from '../../../../../../environments/environment';
import { AlertConfirmModalComponent } from '../../../../../core/component/alert-confirm-modal/alert-confirm-modal.component';
import { ErrorModalComponent } from '../../../../../core/component/error-modal/error-modal.component';
import { WindowScrolling } from '../../../../../core/services/window-scrolling.service';
import { GlobalVariables } from '../../../../../core/services/global-variables.service';
import { ProductReviewFormComponent } from '../../../../../product-details/component/product-review-form/product-review-form.component';
import { ProductCardSwitcherComponent } from '../product-card-switcher.component';

@Component({
  selector: 'hos-product-card-listing-page',
  templateUrl: './product-card-listing-page.component.html',
  styleUrls: ['./product-card-listing-page.component.scss'],
})
export class ProductCardListingPageComponent implements OnInit, OnChanges {
  @Input() product: any;

  @Input() locData: any;

  @Input() zipcode: any;

  @Input() zipcodefromProduct: any;

  @Input() selectedAddress: any;

  @Input() locationId: any;

  @Input() isUpgrade: Boolean;

  @Input() locationCode: any;

  @Input() productcount?: any;

  @Input() productcountid?: any;

  @Input() refCheck?: any;

  @Output() loader = new EventEmitter();

  @Input() locationCodeData: any;

  @Input() index: any; // For Product Index

  @Input() shareIconIndexArray: any;

  @Input() showOldPrice: boolean = false;

  @Input() products: any = {};

  butttonText: String = 'Learn More';

  baseUrl: String;

  baseUrlLoc: String;

  iSLocation: Boolean = false;

  userDetails: any;

  productData: any;

  showLoader: Boolean = false;

  customer_service_number: String;

  specialProduct: Array<String> = [];

  partnerLogo: String;

  logoURL: String;

  isMicroSite: Number;

  websitePartnerName: String;

  leakFrequencyVal: any;

  shareIcon: Boolean = false;

  selectedServiceAddressesUnderZipcode: any;

  @Input() allStates: any;

  siteNameParam: any;

  isbrowser = true;

  isserver = false;

  shareIndex: any = 0;

  micrositeNames: any;

  isFlipped: boolean = false;

  // new regi flow check
  showProceedToCheckout: any = [];

  constructor(
    private router: Router,
    private sessionStorageService: SessionStorageService,
    private locationService: LocationService,
    private simpleModalService: SimpleModalService,
    private route: ActivatedRoute,
    private globalVariables: GlobalVariables,
    private windowScrolling: WindowScrolling,
    private location: Location,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Optional() @Inject('serverUrl') protected origin: string,
    @Optional() @Inject('pathname') protected pathname: string,
    private productCardSwitcherComponent: ProductCardSwitcherComponent,
  ) {
    this.micrositeNames = this.sessionStorageService.getItem('micrositeName')
      ? JSON.parse(this.sessionStorageService.getItem('micrositeName'))
      : environment.micrositeNames;
    this.specialProduct = environment.special_product;
    this.partnerLogo = this.sessionStorageService.getItem('partnerLogo');
    this.logoURL = this.sessionStorageService.getItem('logoURL');
    this.isMicroSite = +this.sessionStorageService.getItem('isMicroSite');
    this.websitePartnerName = this.sessionStorageService.getItem('websitePartnerName');
    this.isbrowser = isPlatformBrowser(platformId);
    this.isserver = isPlatformServer(platformId);
    // this.products = this.products; To Check
  }

  ngOnInit() {
    this.customer_service_number = environment.customer_service_number;
    this.baseUrl = `${environment.Image_API_Endpoint}${environment.Product_service_API_Endpoint}`;
    this.baseUrlLoc = `${environment.Image_API_Endpoint}${environment.Location_service_API_Endpoint}`;
    if (this.isUpgrade) {
      this.butttonText = 'Upgrade';
    }
    if (this.locationId) {
      this.iSLocation = true;
    }
  }

  async ngOnChanges() {
    this.partnerLogo = this.sessionStorageService.getItem('partnerLogo');
    this.logoURL = this.sessionStorageService.getItem('logoURL');
    /* Add to cart logic on click of enroll now before login to add cart after login */
    const isRetainEnrollClicked = this.sessionStorageService.getItem('isRetainEnrollClicked');
    if (
      isRetainEnrollClicked !== 'true' &&
      this.product &&
      this.globalVariables.nonLoggedInProductListingDetails &&
      Object.keys(this.globalVariables.nonLoggedInProductListingDetails).length > 0
    ) {
      this.sessionStorageService.setItem('isRetainEnrollClicked', 'true');
      const urlOperation: any = window.location.pathname.split('/');
      if (
        urlOperation.length > 0 &&
        urlOperation[0] !== '' &&
        !this.micrositeNames.includes(urlOperation[0]) &&
        this.globalVariables.isPropNotExist
      ) {
        if (['products'].includes(urlOperation[1])) {
          this.globalVariables.isPropNotExist = false;
          const getMicrositeDetails = await this.getMicrositeContentApi(urlOperation[3]);
          if (getMicrositeDetails && getMicrositeDetails.body && getMicrositeDetails.body.site.siteName) {
            urlOperation.unshift(getMicrositeDetails.body.site.siteName);
            const redirectUrlOp: any = urlOperation.join('/');
            this.location.go(redirectUrlOp);
          }
        }
      }
      /* Start - To Check if cust# is null return to myaccount route */
      let isEmptyCustNo: boolean = false;
      const userDetails = JSON.parse(this.sessionStorageService.getItem('loginUserDetails'));
      this.showLoader = true;
      if (userDetails) {
        await this.getAllCustomerAddressesApi(userDetails);
      }
      if (userDetails && this.globalVariables.getAllCustomerAddresses) {
        const lastPropData = JSON.parse(
          JSON.stringify(this.globalVariables.getAllCustomerAddresses[this.globalVariables.getAllCustomerAddresses.length - 1]),
        );
        if (
          lastPropData &&
          lastPropData.serviceAddress.zipcode === this.route.snapshot.params.zipcode &&
          ['null', 'undefined', undefined, null, ''].includes(lastPropData.customerNo)
        ) {
          for (let i = 0; i <= 2; i++) {
            const lastIndexData = JSON.parse(
              JSON.stringify(this.globalVariables.getAllCustomerAddresses[this.globalVariables.getAllCustomerAddresses.length - 1]),
            );
            if (lastIndexData && ['null', 'undefined', undefined, null, ''].includes(lastIndexData.customerNo)) {
              if (i === 2) {
                isEmptyCustNo = true;
              } else {
                await new Promise(resolve => {
                  setTimeout(resolve, 2000);
                });
                await this.getAllCustomerAddressesApi(userDetails);
              }
            }
          }
        }
      }
      this.showLoader = false;
      if (isEmptyCustNo) {
        this.router.navigate(['/user/myaccount']);
        return;
      }
      /* End - To Check if cust# is null return to myaccount route */
      if (this.product.defaultLocationCodeId === this.globalVariables.nonLoggedInProductListingDetails.defaultLocationCodeId) {
        this.addProductToCart(this.globalVariables.nonLoggedInProductListingDetails);
      } else {
        this.globalVariables.nonLoggedInProductDetails = this.product;
        this.addProductToCart(this.globalVariables.nonLoggedInProductDetails);
      }
    }
  }

  learnMoreHandler(product: any) {
    if (product.isUpgrade) {
      this.simpleModalService.removeAll();
    }
    window.scrollTo(0, 0);
    const data = this.locationService.getZipcodeAndLocation();
    const existingData = JSON.parse(this.sessionStorageService.getItem('selectedAddress'));
    if (!existingData && this.zipcode !== undefined) {
      const selectedAddress = {
        formatted_address: this.zipcode,
        rawData: '',
        zipcode: this.zipcode,
      };
      this.sessionStorageService.setItem('selectedAddress', JSON.stringify(selectedAddress));
    }
    if (data) {
      this.zipcode = this.zipcode ? this.zipcode : data.zipcode;
    }
    this.locationId = this.locationId ? this.locationId : data.location_code;
    const productContactType = environment.types.filter(item => item.id === this.product.contactType)[0].type.toLowerCase();
    if (this.zipcode && productContactType && this.locationId) {
      /** HWR - 2696 Start - get locationCode based on location id  */
      this.productCardSwitcherComponent.getLocationsContentAPI(this.locationId).subscribe(res => {
        const locationCodeData = res.body.locationCode;
        this.router.navigate([
          `/product/${this.zipcode}/${locationCodeData.toLowerCase()}/${productContactType}/${product.webProgramName
            .toString()
            .split(' ')
            .join('-')
            .split(',')
            .join('')
            .toLowerCase()}`,
        ]);
      });
    } else {
      this.router.navigate(['/404']);
    }
    /** HWR - 2696 Start - get locationCode based on location id  */
  }

  showSocialIcons() {
    // this.shareIndex = this.shareIconIndexArray.map(e => e.value).indexOf(true);
    // this.shareIconIndexArray.forEach(element => {
    //   element.value = false;
    // });
    // if (this.index || this.index === 0) {
    //   this.shareIconIndexArray[this.index].value = true;
    //   if (this.index !== this.shareIndex) {
    //     this.shareIcon = false;
    //   }
    // } To Check
    this.shareIcon = !this.shareIcon;
    /**
     *  Fix added for Social Icons for payment success page
     */
    if (!(this.route.snapshot.params.locationcode && this.locationCodeData && this.locationCodeData.locationCode)) {
      this.productCardSwitcherComponent.getLocationsContentAPI(this.locationId).subscribe(data => {
        this.locationCodeData = data.body;
      });
    }
  }

  learnMoreNativeLink(product: any, link: any) {
    let url;
    let originUrl;
    const zipcodeParams = this.route.snapshot.params.zipcode || this.zipcode;
    const typeParams = this.product.contactType === 1 ? 'residential' : 'commercial';
    const locationcodeParams = this.route.snapshot.params.locationcode || this.locationCodeData.locationCode.toLowerCase();
    this.siteNameParam = this.sessionStorageService.getItem('providerState');
    if (this.isbrowser) {
      originUrl = window.location.origin;
    }
    if (this.isserver) {
      originUrl = this.origin;
    }
    if (this.siteNameParam) {
      url = `${originUrl}/${this.siteNameParam}/product/${zipcodeParams}/${locationcodeParams}/${typeParams}/${product.webProgramName
        .toString()
        .split(' ')
        .join('-')
        .split(',')
        .join('')
        .toLowerCase()}`;
    } else {
      url = `${originUrl}/product/${zipcodeParams}/${locationcodeParams}/${typeParams}/${product.webProgramName
        .toString()
        .split(' ')
        .join('-')
        .split(',')
        .join('')
        .toLowerCase()}`;
    }
    const args = {
      url,
      title: 'Protect your family’s home with this affordable protection plan!',
      description: '',
      image: '',
      via: '',
      tags: '',
    };
    const width = 820;
    const height = 500;
    const left = Math.floor((screen.width - width) / 2);
    const top = Math.floor((screen.height - height) / 4);
    let params = `width=${width}, height=${height}`;
    params += `, top=${top}, left=${left}`;
    params += ', directories=no';
    params += ', location=no';
    params += ', menubar=no';
    params += ', resizable=no';
    params += ', scrollbars=no';
    params += ', status=no';
    params += ', toolbar=no';
    switch (link) {
      case 'fb':
        this.popupfacebook(args, params);
        break;
      case 'twitter':
        this.popuptweet(args, params);
        break;
      case 'linkedin':
        this.popuplinkedin(args, params);
        break;
      case 'pinterest':
        this.popupPinterest(args, params);
        break;
      case 'mail':
        this.popupMail(args);
        break;
      default:
    }
  }

  /* Facebook Share Popup */
  popupfacebook(args, screenParams) {
    /* Passing encodeURIComponent as fix of truncate url */
    let shareUrl = 'https://www.facebook.com/sharer/sharer.php';
    shareUrl += `?u=${args.url}`;
    if (args.title) {
      shareUrl += `&title=${args.title}`;
    }
    if (args.description) {
      shareUrl += `&description=${args.description}`;
    }
    if (args.image) {
      shareUrl += `&picture=${args.image}`;
    }
    const newwindow = window.open(shareUrl, 'name', screenParams);
    if (window.focus) {
      newwindow.focus();
    }
  }

  /* Twitter Share Popup */
  popuptweet(args, screenParams) {
    /* Passing encodeURIComponent as fix of truncate url */
    let shareUrl = 'https://twitter.com/intent/tweet';
    shareUrl += `?url=${encodeURIComponent(args.url)}`;
    if (args.title) {
      shareUrl += `&text=${args.title}`;
    }
    if (args.via) {
      shareUrl += `&via=${args.via}`;
    }
    if (args.tags) {
      shareUrl += `&hashtags=${args.tags}`;
    }
    const newwindow = window.open(shareUrl, 'name', screenParams);
    if (window.focus) {
      newwindow.focus();
    }
  }

  /* Linked In Share Popup */
  popuplinkedin(args, screenParams) {
    /* Passing encodeURIComponent as fix of truncate url */

    let shareUrl = 'http://www.linkedin.com/shareArticle';
    shareUrl += `?url=${args.url}`;
    if (args.title) {
      shareUrl += `&title=${args.title}`;
    }
    if (args.description) {
      shareUrl += `&summary=${args.description}`;
    }
    const newwindow = window.open(shareUrl, 'name', screenParams);
    if (window.focus) {
      newwindow.focus();
    }
  }

  /* Pinterest Save Popup */
  popupPinterest(args, screenParams) {
    /* Need to add a visible image for it to show in pnterest (Append the url in media query parameter) */
    /* Passing encodeURIComponent as fix of truncate url */
    let pinterestUrl = `https://pinterest.com/pin/create/button/?url=${args.url}`;
    // if text is not provided, pin button won't work.
    if (args.description) {
      pinterestUrl += `&description=${args.description}`;
    } else if (document) {
      const descElm = document.querySelector('meta[property="og:description"]');
      if (descElm) {
        pinterestUrl += `&description=${descElm.getAttribute('content')}`;
      }
    }
    if (args.image) {
      pinterestUrl += `&media=${args.image}`;
    } else if (document) {
      const imageElm = document.querySelector('meta[property="og:image"]');
      if (imageElm) {
        pinterestUrl += `&media=${imageElm.getAttribute('content')}`;
      }
    }
    const newwindow = window.open(pinterestUrl, 'name', screenParams);
    if (window.focus) {
      newwindow.focus();
    }
  }

  /* Mail to Popup */
  popupMail(args) {
    /* Passing encodeURIComponent as fix of truncate url */
    const mailUrl = `mailto:?subject=Check out this product&body=Protect your family%27s home with this affordable protection plan%21  %3A  ${encodeURIComponent(
      args.url,
    )}`;
    const newwindow = window.open(mailUrl);
    if (window.focus) {
      newwindow.focus();
    }
  }

  goToProducts() {
    const data = this.locationService.getZipcodeAndLocation();
    if (data) {
      this.zipcode = data.zipcode;
      this.locationId = data.location_code;
      this.router.navigate([`/products/${this.zipcode}/${this.locationCodeData.locationCode.toLowerCase()}/residential`]);
    }
  }

  getImage(imageName) {
    return this.productCardSwitcherComponent.getImage(imageName);
  }

  public addProductToCart(product: any): void {
    this.productCardSwitcherComponent.addProductToCart(product, this);
  }

  getProductCardImage(product) {
    return this.productCardSwitcherComponent.getProductCardImage(product, this);
  }

  goToPaymentOptions() {}

  public getMicrositeContentApi(data): Promise<any> {
    return this.productCardSwitcherComponent.getMicrositeContentApi(data);
  }

  public getAllCustomerAddressesApi(userDetails): Promise<any> {
    return this.productCardSwitcherComponent.getAllCustomerAddressesApi(userDetails.user._id);
  }

  /* Dialog Modal Open Review Form */
  openCreateForm(productReview) {
    const programName =
      productReview.coverageType && productReview.coverageType === 'UNLIMITED'
        ? `Unlimited ${productReview.programName}`
        : productReview.programName
        ? productReview.programName
        : ' ';
    const programId =
      productReview.coverageType && productReview.coverageType === 'UNLIMITED' ? `unlimited-${productReview.programId}` : productReview.programId;
    this.simpleModalService
      .addModal(
        ProductReviewFormComponent,
        {
          programName,
          programId,
          reviews: [],
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: true,
        },
      )
      .subscribe(result => {
        if (result === true && result !== undefined) {
          const successModal = this.simpleModalService
            .addModal(
              AlertConfirmModalComponent,
              {
                title: '',
                message: '<p style="text-align:center;font-size: 24px;font-weight: 500;">Review has been submitted successfully!</p>',
                alertIcon: 'fa fa-info-circle',
                confirmText: 'CLOSE',
              },
              {
                wrapperClass: 'modal-main-class in',
                closeOnClickOutside: true,
              },
            )
            .subscribe(() => {});
          setTimeout(() => {
            successModal.unsubscribe();
          }, 5000);
        } else if (result !== undefined) {
          const errorModal = this.simpleModalService
            .addModal(
              ErrorModalComponent,
              {
                title: '',
                message: `<p style="text-align:center;font-size: 24px;font-weight: 500;">${result}.</p>`,
                confirmationMsg: 'CLOSE',
                alertType: 'info-type',
              },
              {
                wrapperClass: 'modal-main-class in',
                closeOnClickOutside: true,
              },
            )
            .subscribe(() => {});
          setTimeout(() => {
            errorModal.unsubscribe();
          }, 5000);
        }
        this.windowScrolling.enable();
      });
  }

  flipHandler(data) {
    this.isFlipped = !!data;
  }
}
