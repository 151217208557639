<footer>
  <div class="page-footer">
    <div class="page-wrap footer-wrap row">
      <div class="footer-col col-12 col-sm-12 col-md-5 about-txt mb-3">
        <figure class="footerlogo">
          <img [src]="logoURL" [alt]="site?.site.siteEmailTitle" />
        </figure>
        <p *ngIf="siteContent" class="company-about-desc text-justify" [innerHTML]="siteContent.samllDesc"></p>
        <p class="footer-contact-number">
          <span
            *ngIf="siteContent"
            angulartics2On="click"
            angularticsLabel="phone_footer"
            angularticsValue="0"
            angularticsAction="click"
            angularticsCategory="call"
            href="tel:{{ siteContent.contactNumber }}"
            class="link-block"
          >
            <i class="fa fa-phone icon"></i> {{ siteContent.contactNumber }}
          </span>
        </p>
        <a *ngIf="siteContent" class="link-block"> <i class="fa fa-map-marker icon"></i> {{ siteContent.address }} </a>
        <p *ngIf="siteContent?.onCourseDetails" class="link-block site-block mt-1">
          {{ siteContent?.onCourseDetails?.infoText }} |
          <a href="{{ siteContent?.onCourseDetails?.link }}" class="site-link" target="_blank">{{ siteContent?.onCourseDetails?.label }}</a>
        </p>
      </div>
      <div class="footer-col col-12 col-sm-12 col-md-4 mb-3 service-link-block d-none">
        <h2 class="desktop-text">SERVICES</h2>
        <!-- <h2 class="mobile-text" data-toggle="collapse" href="#serviceCollapse" aria-expanded="false" aria-controls="serviceCollapse">SERVICES</h2> -->
        <div class="panel-group mobile-text" role="tablist" aria-multiselectable="false">
          <div class="panel panel-default">
            <div class="panel-heading" role="tab" id="headingServices">
              <h2 class="panel-title">
                <a data-toggle="collapse" href="#serviceCollapse" aria-expanded="false" aria-controls="serviceCollapse">SERVICES</a>
              </h2>
            </div>
            <ul class="footer-menu" id="serviceCollapse" role="tabpanel" aria-labelledby="headingServices">
              <li *ngFor="let category of categories" (click)="goToCategory(category)" class="cursor">
                <a routerLink="/service/{{ category.categoryName.split(' ').join('-').toLowerCase() }}">{{ category.categoryName }}</a>
              </li>
            </ul>
          </div>
        </div>
        <ul class="footer-menu" id="serviceCollapse">
          <li *ngFor="let category of categories" (click)="goToCategory(category)" class="cursor">
            <a routerLink="/service/{{ category.categoryName.split(' ').join('-').toLowerCase() }}">{{ category.categoryName }}</a>
          </li>
        </ul>
      </div>
      <!-- <div class="footer-col col-12 col-sm-6 col-md-3 mb-3 news-link-block">
                <h2 class="desktop-text">Recent News</h2>
                <h2 class="mobile-text" data-toggle="collapse" href="#newsCollapse" aria-expanded="false" aria-controls="newsCollapse">Recent News</h2>
                <ul class="news-list" id="newsCollapse">
                    <li class="news-item" *ngFor="let news of recentNews">
                        <a [routerLink]="['/blog']" [queryParams]="{entryId: news.entryId}">
                            <figure class="img-thumb">
                                <img [src]="imageBaseUrl + news.imageURL" alt="">
                            </figure>
                            <div class="txt">
                                <h3>{{news.title}}</h3>
                                <em>{{news.summary}}</em>
                            </div>

                        </a>
                    </li>
                </ul>
            </div> -->

      <div class="footer-col col-12 col-sm-12 col-md-7 mb-3 links-block display-flex">
        <div class="footer-col col-12 col-sm-12 col-md-6">
          <h2 class="desktop-text skiptranslate">LINKS</h2>
          <!-- <h2 class="mobile-text" data-toggle="collapse" href="#linksCollapse" aria-expanded="false" aria-controls="linksCollapse">LINKS</h2> -->
          <div class="panel-group mobile-text" role="tablist" aria-multiselectable="false">
            <div class="panel panel-default">
              <div class="panel-heading" role="tab" id="headingLinks">
                <h2 class="panel-title">
                  <a
                    data-toggle="collapse"
                    href="#linksCollapse"
                    aria-expanded="false"
                    aria-controls="linksCollapse"
                    class="skiptranslate desktop-only"
                    >LINKS</a
                  >
                  <a
                    data-toggle="collapse"
                    href="#linksCollapseMobile"
                    aria-expanded="false"
                    aria-controls="linksCollapseMobile"
                    class="skiptranslate mobile-only"
                    >LINKS</a
                  >
                </h2>
              </div>
              <ul id="linksCollapseMobile" class="panel-collapse footer-menu collapse" role="tabpanel" attr.aria-labelledby="headingLinks">
                <li *ngFor="let link of siteContent?.links">
                  <a href="{{ link?.link }}" target="{{ link?.isLinkExternal ? '_blank' : '_self' }}">{{ link?.label }}</a>
                </li>
              </ul>
            </div>
          </div>
          <ul class="footer-menu hide-on-mobile-tab" id="linksCollapse">
            <li *ngFor="let link of siteContent?.links">
              <a href="{{ link?.link }}" target="{{ link?.isLinkExternal ? '_blank' : '_self' }}">{{ link?.label }}</a>
            </li>
          </ul>
        </div>
        <div class="footer-col col-12 col-sm-12 col-md-6 desktop-only">
          <h2 class="followus-head">FOLLOW US</h2>
          <ul *ngIf="siteContent" class="social-icons">
            <li *ngFor="let social of siteContent.socials">
              <a *ngIf="social" [href]="social?.link" target="_blank">
                <img [src]="getImage(baseContentUrl + social?.iconUrl)" loading="lazy" alt="Social Icon" />
              </a>
            </li>
          </ul>
          <ul class="footer-bb" *ngIf="siteContent?.bbbDetails">
            <li class="bbb-box" [innerHTML]="siteContent?.bbbDetails"></li>
          </ul>
          <ul class="footer-bb MT-35">
            <li *ngIf="ccpaDetails" class="ccpa-updates-link" [innerHTML]="ccpaDetails">&nbsp;</li>
          </ul>
        </div>
      </div>

      <div class="footer-col col-12 col-sm-12 col-md-3 mb-3 mobile-social-block">
        <h2 class="mobile-followus-head mobile-text">FOLLOW US</h2>
        <ul *ngIf="siteContent" class="mobile-social-icons">
          <li *ngFor="let social of siteContent.socials">
            <a *ngIf="social" [href]="social?.link" target="_blank">
              <img [src]="getImage(baseContentUrl + social?.iconUrl)" alt="Social Icon" loading="lazy" />
            </a>
          </li>
        </ul>
      </div>

      <div class="footer-col col-12 col-sm-12 col-md-4 mobile-contact-txt mb-3">
        <a
          *ngIf="siteContent"
          href="tel:{{ siteContent.contactNumber }}"
          class="link-block"
          angulartics2On="click"
          angularticsLabel="phone_footer"
          angularticsValue="0"
          angularticsAction="click"
          angularticsCategory="call"
        >
          <i class="fa fa-phone icon"></i> {{ siteContent.contactNumber }}
        </a>
        <br />
        <div *ngIf="siteContent" class="link-block"><i class="fa fa-map-marker icon"></i> {{ siteContent.address }}</div>
        <p *ngIf="siteContent?.onCourseDetails" class="link-block site-block">
          {{ siteContent?.onCourseDetails?.infoText }} |
          <a href="{{ siteContent?.onCourseDetails?.link }}" class="site-link" target="_blank">{{ siteContent?.onCourseDetails?.label }}</a>
        </p>
      </div>

      <div class="mobile-footer-bb">
        <div *ngIf="siteContent?.bbbDetails" class="bbb-box" [innerHTML]="siteContent?.bbbDetails"></div>
        <div *ngIf="ccpaDetails" class="ccpa-updates-link mt-2" [innerHTML]="ccpaDetails">&nbsp;</div>
      </div>
    </div>
  </div>
</footer>
