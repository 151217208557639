import { Injectable } from '@angular/core';
import { ReplaySubject, Observable, BehaviorSubject } from 'rxjs';
import { find } from 'lodash-es';
import { SessionStorageService } from './session-storage.service';
import { HttpService } from './http';
import { SiteContentService } from './siteContents';
import { environment } from '../../../environments/environment';
import { AppSecrets } from '../../app.secrets';

@Injectable()
export class UtilsService {
  public secrets: any;

  linkStream: ReplaySubject<any> = new ReplaySubject();

  public partnerDetailsStream: BehaviorSubject<any> = new BehaviorSubject<any>({});

  public event = this.partnerDetailsStream.asObservable();

  constructor(
    private sessionStorageService: SessionStorageService,
    private http: HttpService,
    private siteContentService: SiteContentService,
    public appSecrets?: AppSecrets,
  ) {
    this.secrets = this.appSecrets.getSecrets();
    this.setPartnerDetailsInSubject();
  }

  getContactLink$(): Observable<any> {
    return this.linkStream.asObservable();
  }

  getWebProgramName(defaultMarketingCodes: any[], locationCodeId: string): string {
    const selectedMarketingCode = find(defaultMarketingCodes, {
      locationCodeId,
    });
    return selectedMarketingCode ? selectedMarketingCode.webProgramName : '';
  }

  buildImageUrl(url: any): any {
    if (url !== '') {
      if (this.secrets) {
        const queryParam = url.indexOf('?') !== -1 || url.indexOf('&') !== -1 ? '&' : '?';
        const query = environment.is_mulesoft_enable === true ? `${queryParam}APIKey=${this.secrets.APIKey}` : '';
        return url + query;
      }
      return url;
    }
    return '';
  }

  filterCompanyName(data: string): any {
    return data.replace(/American Water( Resources( of \w+)?)?/gi, x => `<span class="skiptranslate">${x}</span>`);
  }

  getPartnerDetails$(): Observable<any> {
    return this.partnerDetailsStream.asObservable();
  }

  public getPartnerDetailsAPI(): Promise<any> {
    const partner = this.sessionStorageService.getItem('providerState');
    return this.siteContentService.getPartnerDetails(partner || '').toPromise();
  }

  async setPartnerDetailsInSubject() {
    const getPartnerDetails = await this.getPartnerDetailsAPI();
    const data = getPartnerDetails;
    if (data.status === 200) {
      const menu = data.body.site.sideMenu;
      const contactLink = find(menu, link => {
        if (link.label === 'Contact Us') {
          return link;
        }
        return false;
      });
      this.partnerDetailsStream.next(data);
      this.linkStream.next(contactLink);
    } else if (data.status !== 200) {
      // error handling
      this.linkStream.next('');
      this.partnerDetailsStream.next([]);
    }
  }
}
