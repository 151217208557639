import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, NgForm } from '@angular/forms';
import { SimpleModalService } from 'ngx-simple-modal';
import { Location } from '@angular/common';
import { UserService } from '../../services/user.service';
import { CartService } from '../../../cart/services/cart.service';
import { Breadcrumb } from '../../../core/component/breadcrumb/breadcrumb.component';
import { environment } from '../../../../environments/environment';
import { AlertService } from '../../../core/services/alert.service';
import { SeoService } from '../../../core/services/seo.service';
import { SeoConfig } from '../../../core/model/seo-config.model';
import { SiteContentService } from '../../../core/services/siteContents';
import { VeiwServiceAddressComponent } from '../../../core/component/veiw-service-address/veiw-service-address.component';
import { ErrorModalComponent } from '../../../core/component/error-modal/error-modal.component';
import { AlertConfirmModalComponent } from '../../../core/component/alert-confirm-modal/alert-confirm-modal.component';
import { SessionStorageService } from '../../../core/services/session-storage.service';
import { LocationService } from '../../../home/services/location.service';
import { UtilsService } from '../../../core/services/utils.service';
import { CustomerServiceValidationModalComponent } from '../customer-service-validation-modal/customer-service-validation-modal.component';
import { GlobalVariables } from '../../../core/services/global-variables.service';
import { ProductService } from '../../../product/services/product.service';

@Component({
  selector: 'hos-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  errorMessage: String = '';

  userProfile: any;

  zipcodeValid: Boolean = true;

  oldUserDetails: any;

  email_regex: String;

  password_regex: String;

  emailerror: String;

  // phoneerror: String;
  isClicked: Boolean = false;

  bannerImage: String;

  phone_number_regex: String;

  userId: any;

  ProfileTips: any;

  ProfileTipsTitle: String = '';

  ProfileTipsTips: any = [];

  user: any;

  states: any;

  showGeneralError: Boolean = false;

  showAddressError: Boolean = false;

  showContactError: Boolean = false;

  showChangePasswordError: Boolean = false;

  showChangeSecurityError: Boolean = false;

  enableGeneral: Boolean = false;

  enableEditAddress: Boolean = false;

  enableContactEdit: Boolean = false;

  enableCredentialEdit: Boolean = false;

  enablePreferenceEdit: Boolean = false;

  enableSecurityEdit: Boolean = false;

  showEmailError: Boolean = false;

  // showPhoneError: Boolean = false;
  showLoader: Boolean;

  public itemCount: number;

  public cartProducts: Array<any>;

  // boolean for profile preference
  preferenceForm: UntypedFormGroup;

  message: Boolean = false;

  alert: Boolean;

  text: Boolean;

  mail: Boolean;

  mobile: Boolean;

  siteContent: any;

  msgs: any[] = [];

  deleteAddressPayload: any;

  verifyEmailBtn = {
    text: 'Verify Email',
    state: 'initial',
  };

  isEmailVerified = false;

  breadcrumsConfig: Array<Breadcrumb> = [
    {
      title: 'Home',
      link: '/',
      active: false,
    },
    {
      title: 'My Profile',
      link: '',
      active: true,
    },
  ];

  seoConfig: SeoConfig = {
    title: 'Profile Service Line Warranties | American Water Resources',
    description: `American Water Resources offers affordable home protection programs
        that help protect against unexpected repair costs for service line emergencies.`,
    keywords: `service line warranties, water line protection, sewer line protection,
        in home plumbing emergencies, electric line protection, power surge protection, heating system repair, cooling system repair`,
    image: '',
    slug: '',
    sitename: '',
    type: '',
    summary: '',
    url: '',
  };

  isViewSecurity: Array<boolean> = [false, false];

  secQueAnsErrorMsg: Array<String> = [];

  homePhoneErrorMsg: String;

  mobilePhoneErrorMsg: String;

  isDisposableEmail: Boolean = false;

  isDisposableEmailPrimary: Boolean = false;

  isDisposableEmailSecondary: Boolean = false;

  passwordStrength: String = 'initial';

  disabledSave: Boolean = false;

  isMyProfile: boolean;

  selectedCustNo: any;

  locationCodeData: any;

  isConfirmView = false;

  _deleteCustomerUrl = `${environment.Customer_service_API_Endpoint}customer/delete-customer`;

  /**
   *
   * Creates an instance of ProfileComponent.
   *
   * @param {Router} router
   * @param {UserService} userService
   *
   * @memberof ProfileComponent
   */
  constructor(
    private seoService: SeoService,
    private router: Router,
    private userService: UserService,
    private cartService: CartService,
    private alertService: AlertService,
    private simpleModalService: SimpleModalService,
    private location: Location,
    private locationService: LocationService,
    private sessionStorageService: SessionStorageService,
    private siteContentService: SiteContentService,
    public utilsService: UtilsService,
    private globalVariables: GlobalVariables,
    private productService: ProductService,
  ) {
    seoService.generateTitleTags(this.seoConfig);
    seoService.setCanonicalTags();
  }

  ngOnInit() {
    this.email_regex = environment.email_regex;
    this.password_regex = environment.password_regex;
    this.phone_number_regex = environment.phone_number_regex;
    this.showLoader = true;
    const user = JSON.parse(this.sessionStorageService.getItem('loginUserDetails'));
    if (user) {
      this.isEmailVerified = user.user.email_verified;
    }
    this.getUserProfile();
    this.getProfileTipsAndTitle();
    this.siteContentService
      .statesdata$()
      .toPromise()
      .then(response => {
        this.states = response;
      });
    this.selectedCustNo = this.globalVariables.selected_Customer_number;
    this.isMyProfile = true;
    this.globalVariables.removeProperty.subscribe(() => {
      this.router.navigate(['/user/myaccount']);
    });
  }

  /**
   * Form submit handler
   *
   * @param {any} form
   * @param {Boolean} isValidEmail
   *
   * @memberof NewForgotPasswordComponent
   */
  getProfileTipsAndTitle() {
    this.utilsService.getPartnerDetails$().subscribe(data => {
      this.siteContent = data.body.site.profilepage;
      this.ProfileTips = this.siteContent.tips;
      this.ProfileTipsTitle = this.ProfileTips.title;
      this.ProfileTipsTips = this.ProfileTips.tips;
      if (!!this.siteContent.bannerImage && !!this.siteContent.bannerImage.url) {
        // tslint:disable-next-line:max-line-length
        this.bannerImage = this.utilsService.buildImageUrl(
          `${environment.Image_API_Endpoint}${environment.Content_service_API_Endpoint}${this.siteContent.bannerImage.url}`,
        );
      }
    });
    this.location.subscribe(() => {});
  }

  getUserProfile() {
    this.user = this.userService.getSignedInUser();
    this.userId = this.user.user._id;
    this.userService.getUserProfile(this.userId).subscribe(
      data => {
        const userProfile = data.body;
        if (data.status === 200) {
          this.oldUserDetails = JSON.parse(JSON.stringify(userProfile));
          this.userProfile = userProfile;
        } else {
          this.errorMessage = userProfile.message;
        }
        this.preferenceForm = new UntypedFormGroup({
          message: new UntypedFormControl(this.userProfile.preferences ? this.userProfile.preferences.message : false, Validators.required),
          alert: new UntypedFormControl(this.userProfile.preferences ? this.userProfile.preferences.alert : false, Validators.required),
          text: new UntypedFormControl(this.userProfile.preferences ? this.userProfile.preferences.text : false, Validators.required),
          mail: new UntypedFormControl(this.userProfile.preferences ? this.userProfile.preferences.mail : false, Validators.required),
          mobile: new UntypedFormControl(this.userProfile.preferences ? this.userProfile.preferences.mobile : false, Validators.required),
        });
        this.showLoader = false;
      },
      error => {
        this.errorMessage = error.error.message;
        this.showLoader = false;
      },
    );
  }

  setoldform() {
    this.showLoader = true;
    this.getUserProfile();
  }

  setoldsecurityform() {
    this.showLoader = true;
    this.getUserProfile();
  }

  clearcredentials(form: NgForm) {
    const f = form;
    form.controls.password.reset();
    form.controls.confirmPassword.reset();
    form.controls.password.setErrors(null);
    form.controls.confirmPassword.setErrors(null);
    f.value.password = '';
    f.value.confirmPassword = '';
    this.enableCredentialEdit = false;
    this.passwordStrength = 'initial';
  }

  blurHandler(input: any) {
    const field = input;
    field.isBlur = true;
    const pattern = /^\d{10}$/;
    if (input.value && !pattern.test(input.value)) {
      this.mobilePhoneErrorMsg = 'Please enter 10 digit number';
      return false;
    }
    this.mobilePhoneErrorMsg = '';

    return true;
  }

  phoneBlurHandler(input: any) {
    const field = input;
    field.isBlur = true;
    const pattern = /^\d{10}$/;
    if (input.value && !pattern.test(input.value)) {
      if (input.name === 'phoneNumber') {
        this.homePhoneErrorMsg = 'Please enter 10 digit number';
      }
      return false;
    }
    if (input.name === 'phoneNumber') {
      this.homePhoneErrorMsg = '';
    }

    // this.showPhoneError = false;
    if (input.valid) {
      if (this.oldUserDetails.phoneNumber !== this.userProfile.phoneNumber) {
        /* HWR-2485 Start */
        /* this.userService.verifyPhoneNumber(input.value)
                    .subscribe((data) => {
                        if (data.status === 200) {
                            this.showPhoneError = true;
                            this.phoneerror = 'This phone number already exists.';
                            input.isBlur = false;
                        }
                    }, (error) => {
                        this.showPhoneError = false;
                    }); */
        /* HWR-2485 End */
      }
    }
    return true;
  }

  emailBlurHandler(input: any) {
    const field = input;
    if (input.name === 'email') {
      this.disabledSave = true;
    }
    field.isBlur = true;
    this.showEmailError = false;
    this.isDisposableEmail = false;
    const oldEmail = this.oldUserDetails.email ? this.oldUserDetails.email.toLowerCase() : '';
    const newEmail = this.userProfile.email ? this.userProfile.email.toLowerCase() : '';
    if (input.valid) {
      if (environment.disposable_email.includes(input.value.split('@')[1].toLowerCase())) {
        field.isBlur = false;
        this.isDisposableEmail = true;
      } else if (oldEmail !== newEmail) {
        this.userService.verifyUser(input.value).subscribe(
          data => {
            if (data.status === 200) {
              this.showEmailError = true;
              this.emailerror = 'This email already exists.';
              field.isBlur = false;
              if (input.name === 'email') {
                this.disabledSave = true;
              }
            }
          },
          () => {
            this.showEmailError = false;
            if (input.name === 'email') {
              this.disabledSave = false;
            }
          },
        );
      } else if (input.name === 'email') {
        this.disabledSave = false;
      }
    }
  }

  keyPressHandler(input: any) {
    const field = input;
    field.isBlur = false;
  }

  phoneChangeHandler() {}

  updateContactInfo(form: NgForm) {
    if (this.showEmailError) {
      return;
    }
    this.showContactError = false;
    this.alertService.clear();
    let isPhoneChanged = false;
    let isPrimaryEmailChanged = false;
    this.showEmailError = false;
    // this.showPhoneError = false;
    this.isDisposableEmailPrimary = false;
    this.isDisposableEmailSecondary = false;
    if (form.valid) {
      const email = this.userProfile.email ? this.userProfile.email.toLowerCase() : '';
      const email2 = this.userProfile.email2 ? this.userProfile.email2.toLowerCase() : '';
      const oldEmail = this.oldUserDetails.email ? this.oldUserDetails.email.toLowerCase() : '';
      const oldEmail2 = this.oldUserDetails.email2 ? this.oldUserDetails.email2.toLowerCase() : '';
      if (environment.disposable_email.includes(email.split('@')[1].toLowerCase())) {
        this.isDisposableEmailPrimary = true;
        return;
      }
      if (typeof email2.split('@')[1] !== 'undefined' && environment.disposable_email.includes(email2.split('@')[1].toLowerCase())) {
        this.isDisposableEmailSecondary = true;
        return;
      }
      if (email === email2) {
        this.showEmailError = true;
        this.emailerror = 'Primary & Secondary email should not be the same.';
        return;
      }
      /* Removing Phone Number Validation */
      /* if (this.userProfile.phoneNumber === this.userProfile.phoneNumber1) {
                this.showPhoneError = true;
                this.phoneerror = 'Primary & Secondary phonenumber should not be the same.';
                return;
            } */
      let payload: any = {};
      let emailpayload: any = {};
      payload = {
        // [VJ HWR-2661-2654 MOBILE FIELD RELATED CODE]
        mobilePhoneNumber: this.userProfile.mobilePhoneNumber,
        email2,
        isAuthorizedToSendMessage: this.userProfile.isAuthorizedToSendMessage === true ? 1 : 0,
      };
      // update optincellphone date if subscibe to sms service
      if (this.userProfile.isAuthorizedToSendMessage) {
        payload.cellPhone1SmsOptInDate = new Date().toJSON().slice(0, 10);
      }

      if (this.oldUserDetails.phoneNumber !== this.userProfile.phoneNumber) {
        payload.phoneNumber = this.userProfile.phoneNumber;
      }

      emailpayload = {
        email,
      };

      if (oldEmail !== email) {
        isPrimaryEmailChanged = true;
      } else {
        isPrimaryEmailChanged = false;
      }
      if (
        this.oldUserDetails.phoneNumber !== this.userProfile.phoneNumber ||
        // [VJ HWR-2661-2654 MOBILE FIELD RELATED CODE]
        this.oldUserDetails.mobilePhoneNumber !== this.userProfile.mobilePhoneNumber ||
        oldEmail2 !== email2 ||
        this.oldUserDetails.isAuthorizedToSendMessage !== this.userProfile.isAuthorizedToSendMessage
      ) {
        isPhoneChanged = true;
      } else {
        isPhoneChanged = false;
      }
      // call api
      if (isPhoneChanged && isPrimaryEmailChanged) {
        this.showAuthenticationModal(payload, this.oldUserDetails.email, 'Please enter your password:', 2, 0, emailpayload);
      } else if (isPhoneChanged) {
        this.showAuthenticationModal(payload, this.oldUserDetails.email, 'Please enter your password:', 2, 1, emailpayload);
      } else if (isPrimaryEmailChanged) {
        this.showAuthenticationModal(payload, this.oldUserDetails.email, 'Please enter your password:', 2, 2, emailpayload);
      } else {
        this.enableContactEdit = false;
        // eslint-disable-next-line no-alert
        alert('No changes detected');
      }
    }
  }

  updateemail(payload, done) {
    this.showLoader = true;
    this.userService.updatePrimaryEmail(this.userId, payload).subscribe(
      res => {
        this.showLoader = false;
        if (res.status === 200) {
          done(true);
        } else {
          done(false);
        }
      },
      error => {
        this.showLoader = false;
        const errors = error.error;
        if (errors.statusCode === 409) {
          this.emailerror = 'This email already exists.';
          this.showEmailError = true;
        }
        done(false);
      },
    );
  }

  showErrorModal(title: String, message: String, modalComponent: any) {
    this.simpleModalService
      .addModal(
        modalComponent,
        {
          title,
          message,
          showButton: 'close',
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: true,
        },
      )
      .subscribe(() => {});
  }

  updateProfile(form: NgForm) {
    this.showGeneralError = false;
    this.alertService.clear();
    const payload = form.value;
    this.update(payload, success => {
      if (success) {
        this.enableGeneral = false;
      } else {
        this.showGeneralError = true;
      }
    });
  }

  updateAddress(form: NgForm) {
    this.showAddressError = false;
    this.alertService.clear();
    this.isClicked = true;
    if (form.valid && this.zipcodeValid) {
      this.isClicked = false;
      let payload: any = {};
      payload = {
        streetNumber: this.userProfile.billingAddress[0].streetNumber,
        streetName: this.userProfile.billingAddress[0].streetName,
        city: this.userProfile.billingAddress[0].city,
        state: this.userProfile.billingAddress[0].state,
        zipcode: this.userProfile.billingAddress[0].zipcode,
        zipcodeId: this.userProfile.billingAddress[0].zipcodeId,
        locationCodeId: this.userProfile.billingAddress[0].locationCodeId,
        _id: this.userProfile.billingAddress[0]._id,
      };
      this.showLoader = true;
      this.userService.updateCustomerBillingAddress(this.userId, this.userId, payload).subscribe(
        res => {
          this.showLoader = false;
          if (res.status === 200) {
            this.getUserProfile();
            this.alertService.success('Your profile has been updated successfully!');
            this.enableEditAddress = false;
          } else {
            this.alertService.error('Error saving customer details');
            this.showAddressError = true;
          }
        },
        () => {
          this.alertService.error('Error saving customer details');
          this.showLoader = false;
        },
      );
    }
  }

  update(payload, done) {
    this.showLoader = true;
    this.userService.updateCustomerInfo(this.userId, payload).subscribe(
      res => {
        this.showLoader = false;
        if (res.status === 200) {
          this.getUserProfile();
          this.alertService.success('Your profile has been updated successfully!');
          done(true);
        } else {
          done(false);
        }
      },
      () => {
        this.showLoader = false;
        done(false);
      },
    );
  }

  updatenumbers(payload, done) {
    this.showLoader = true;
    this.userService.updateCustomerInfo(this.userId, payload).subscribe(
      res => {
        this.showLoader = false;
        if (res.status === 200) {
          this.getUserProfile();
          this.alertService.success('Your profile has been updated successfully!');
          done(true);
        } else {
          done(false);
        }
      },
      () => {
        this.showLoader = false;
        /* Removing Phone Number Validation */
        /* if (errors.responseCode === 409) {
                this.phoneerror = 'This phone number already exists.';
                this.showPhoneError = true;
            } */
        done(false);
      },
    );
  }

  updatePreferences() {
    this.showLoader = true;
    const payload = {
      preferences: {
        message: this.preferenceForm.value.message,
        alert: this.preferenceForm.value.alert,
        text: this.preferenceForm.value.text,
        mail: this.preferenceForm.value.mail,
        mobile: this.preferenceForm.value.mobile,
      },
    };
    this.userService.updatePreferences(this.userId, payload).subscribe(
      res => {
        this.showLoader = false;
        this.enablePreferenceEdit = false;
        if (res.status === 200) {
          this.getUserProfile();
          this.alertService.success('Your preferences has been updated successfully!');
        } else {
          this.alertService.error('Error saving preference details');
        }
      },
      () => {
        this.showLoader = false;
      },
    );
  }

  updatePassword(form: NgForm) {
    this.showChangePasswordError = false;
    this.alertService.clear();
    if (this.passwordStrength === 'true') {
      this.userService.changePassword(form.value.password, form.value.confirmPassword).subscribe(
        res => {
          if (res.status === 200) {
            this.passwordStrength = 'initial';
            this.alertService.success('You have sucessfully changed your password');
            this.enableCredentialEdit = false;
            this.clearcredentials(form);
            // if(form.value.password === '' || (form.value.password && form.value.confirmPassword && (form.value.password === form.value.confirmPassword))) {
            // }
          } else {
            this.showChangePasswordError = true;
          }
        },
        () => {
          this.showChangePasswordError = true;
          this.showLoader = false;
        },
      );
    }
  }

  onZipcodeUpdated(data: any) {
    if (data) {
      this.userProfile.billingAddress[0].zipcodeId = data.zipcodeId;
      this.userProfile.billingAddress[0].zipcodeId = data.locationCodeId;
      this.zipcodeValid = true;
    } else {
      this.zipcodeValid = false;
    }
  }

  editAddress(serviceAddress) {
    this.simpleModalService
      .addModal(
        VeiwServiceAddressComponent,
        {
          address: serviceAddress,
          userId: this.userId,
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: true,
        },
      )
      .subscribe(() => {
        this.getUserProfile();
      });
  }

  showAlertModal(data?: any, payload?: any) {
    this.simpleModalService
      .addModal(
        AlertConfirmModalComponent,
        {
          title: ' Are you sure you want to change your email address?',
          message: 'Your new email address will be used to log into your account.',
          alertIcon: 'fa fa-exclamation-circle red',
          cancelText: 'No Thanks',
          confirmText: 'Yes, Change it',
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: false,
        },
      )
      .subscribe(isConfirmed => {
        if (isConfirmed === 'confirmed') {
          this.showLoader = true;
          this.updateemail(payload, success => {
            if (success) {
              this.userService.signOut();
              this.userService.setLoggin('');
              this.cartService.empty();
              this.router.navigate(['']);
            } else if (!this.showEmailError) {
              this.showContactError = true;
            }
            this.showLoader = false;
          });
        }
      });
  }

  // Before update Authenticate
  showAuthenticationModal(payload: any, existingCodes: any, title = '', modalFor = 0, caseCall = 0, emailpayload = {}) {
    this.simpleModalService
      .addModal(
        CustomerServiceValidationModalComponent,
        {
          title,
          message: 'In progress',
          alertIcon: 'fa fa-info-circle red',
          cancelText: 'Cancel',
          confirmText: 'Continue',
          data: '',
          modalFor,
          existing: existingCodes,
          deleteAddressPayload: emailpayload,
          address: payload,
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: false,
        },
      )
      .subscribe(isConfirmed => {
        if (isConfirmed && modalFor === 2) {
          if (!caseCall) {
            this.showLoader = true;
            this.updatenumbers(payload, success => {
              if (success) {
                this.showAlertModal('', emailpayload);
              } else {
                /* if (!this.showPhoneError) {
                                    this.showContactError = true;
                                } */
              }
              this.showLoader = false;
            });
          } else if (caseCall === 1) {
            this.showLoader = true;
            this.updatenumbers(payload, success => {
              if (success) {
                this.showContactError = false;
                this.enableContactEdit = false;
              } else {
                /*  if (!this.showPhoneError) {
                                    this.showContactError = true;
                                } */
              }
              this.showLoader = false;
            });
          } else if (caseCall === 2) {
            this.showAlertModal('', emailpayload);
          } else if (caseCall === 3) {
            this.updatePassword(payload);
          } else if (caseCall === 4 && !('actionType' in emailpayload)) {
            this.editAddress(payload);
          }
        }
      });
  }

  updateLogInInfo(form: NgForm) {
    this.isViewSecurity = [false, false];
    if (this.passwordStrength === 'true' || this.passwordStrength === 'initial') {
      this.showAuthenticationModal(form, this.oldUserDetails.email, 'Please enter your current password:', 2, 3, {});
    } else if (this.passwordStrength === 'false' && form.controls.password.value !== '') {
      this.alertService.info('Password criteria does not match');
    }
  }

  updateServiceAddress(address) {
    this.showAuthenticationModal(address, this.oldUserDetails.email, 'Please enter your password:', 2, 4, {});
  }

  deleteServiceAddress(address) {
    let actionType = 'delete_web_account';
    let deleteWebAccountFlag = true;
    if (this.userProfile.address.length > 1) {
      actionType = 'delete_property';
      deleteWebAccountFlag = false;
    }
    const deleteAddressPayload = {
      actionType,
      customerNo: address.customerNo,
      deleteWebAccountFlag,
      email: this.user.user.email,
      customerId: this.user.user._id,
      addressId: address._id,
    };
    this.delCustPropertyOrAccount(deleteAddressPayload, address);
  }

  delCustPropertyOrAccount(deleteAddressPayload, address) {
    const message =
      'Are you sure? Please confirm that you would like to have your online profile removed for this property. If you change your mind, you can always come back and register this property again.';
    let title = 'Delete Web Account';
    if (deleteAddressPayload.actionType === 'delete_property') {
      title = 'Delete Property';
    }
    this.simpleModalService
      .addModal(
        AlertConfirmModalComponent,
        {
          title,
          message,
          alertIcon: 'fa fa-exclamation-circle red',
          confirmText: 'Yes',
          cancelText: 'Cancel',
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: false,
        },
      )
      .subscribe(isConfirmed => {
        if (isConfirmed === 'confirmed') {
          this.showAuthenticationModal(address, this.oldUserDetails.email, 'Please enter your password:', 2, 4, deleteAddressPayload);
        }
      });
  }

  getPasswordMeterResult(event) {
    this.passwordStrength = event;
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  /** ****** HWR-3180 - My Profile and Add Property Should Maintain same header design Start ******* */
  pageSwitch() {
    this.globalVariables.myAccClicked = false;
    this.router.navigate(['/user/myaccount']);
    const selectedAddress = this.sessionStorageService.getItem('selectedAddress')
      ? JSON.parse(this.sessionStorageService.getItem('selectedAddress'))
      : '';
    const locationCode = this.sessionStorageService.getItem('location_code');
    const addressId = this.sessionStorageService.getItem('addressId');
    const providerState = this.sessionStorageService.getItem('providerState');
    if (selectedAddress && selectedAddress.zipcode && locationCode && addressId) {
      const queryString = `${providerState || ''}/user/myaccount?zipcode=${
        selectedAddress.zipcode
      }&location_code=${locationCode}&addressId=${addressId}`;
      this.location.go('/user/myaccount', queryString);
    }
  }

  addressHandler(event) {
    if (event) {
      this.productService.getMarketingCodes().subscribe(
        data => {
          if (data.status === 200) {
            this.locationService
              .getLocationsContent(event.location_code || this.sessionStorageService.getItem('location_code'))
              .subscribe(dataReceived => {
                this.locationCodeData = dataReceived.body;
                this.locationService.getMicroSiteForLocation(this.locationCodeData.locationCode).subscribe(dataMicroSiteResponse => {
                  const dataMicroSite = dataMicroSiteResponse.body;
                  if (dataMicroSite.level === 'default') {
                    if (this.sessionStorageService.getItem('providerState')) {
                      this.sessionStorageService.setItem('isMicroSite', '0');
                      this.sessionStorageService.deleteItem('baseUrlState');
                      this.sessionStorageService.deleteItem('providerState');
                      window.location.href = `/products/${event.zipcode}/${this.locationCodeData.locationCode.toLowerCase()}/residential`;
                    } else {
                      this.router.navigate([`/products/${event.zipcode}/${this.locationCodeData.locationCode.toLowerCase()}/residential`]);
                    }
                  } else {
                    this.sessionStorageService.setItem('isMicroSite', '1');
                    if (dataMicroSite.site.siteName) {
                      dataMicroSite.site.siteName = dataMicroSite.site.siteName.toLowerCase();
                    } else {
                      dataMicroSite.site.siteName = '';
                    }

                    let providerState = '';
                    providerState = this.sessionStorageService.getItem('providerState')
                      ? this.sessionStorageService.getItem('providerState').toLowerCase()
                      : '';
                    if (providerState === dataMicroSite.site.siteName) {
                      this.router.navigate([`/products/${event.zipcode}/${this.locationCodeData.locationCode.toLowerCase()}/residential`]);
                    } else {
                      if (dataMicroSite.site.siteName) {
                        dataMicroSite.site.siteName = dataMicroSite.site.siteName.toLowerCase();
                      } else {
                        dataMicroSite.site.siteName = '';
                      }
                      this.sessionStorageService.setItem('providerState', dataMicroSite.site.siteName);
                      this.sessionStorageService.setItem('baseUrlState', dataMicroSite.site.siteName);
                      window.location.href = `/${dataMicroSite.site.siteName}/products/${
                        event.zipcode
                      }/${this.locationCodeData.locationCode.toLowerCase()}/residential`;
                    }
                  }
                });
              });
          } else if (data.status !== 200) {
            this.showErrorModal('Error', 'No service available in your vicinity.', ErrorModalComponent);
          }
        },
        () => {
          this.simpleModalService.addModal(
            ErrorModalComponent,
            {
              title: 'We are sorry. We do not currently offer services in your location.',
              message: 'Please check back soon for product and service availability in your area.',
              showButton: 'close',
              alertType: 'info-type',
            },
            {
              wrapperClass: 'modal-main-class in',
              closeOnClickOutside: true,
            },
          );
        },
      );
    } else {
      this.sessionStorageService.setItem('selectedAddress', JSON.stringify(''));
    }
  }

  /** ****** HWR-3180 - My Profile and Add Property Should Maintain same header design End ******* */
  onVerifyClick() {
    if (this.verifyEmailBtn.state !== 'initial') {
      return;
    }

    this.verifyEmailBtn = {
      text: '',
      state: 'loading',
    };
    this.userService.resendActivationEmail(this.userProfile.email).subscribe(
      () => {
        this.alertService.success('Verification link sent to your email id');
        this.verifyEmailBtn = {
          text: 'Link Sent!',
          state: 'loaded',
        };
      },
      () => {
        this.alertService.error('Error in sending verification link');
        this.verifyEmailBtn = {
          text: 'Verify Email',
          state: 'initial',
        };
      },
    );
  }
}
