import { Injectable } from '@angular/core';
import { SimpleModalService } from 'ngx-simple-modal';
import { CCPAUpdatesModalComponent } from '../component/ccpa-updates/ccpa-updates.component';

@Injectable()
export class CCPAUpdatesService {
  constructor(private simpleModalService: SimpleModalService) {}

  public getCCPAUpdates() {
    this.simpleModalService.addModal(
      CCPAUpdatesModalComponent,
      {
        showButton: 'close',
        alertType: 'info-type',
      },
      {
        wrapperClass: 'modal-main-class in',
        closeOnClickOutside: true,
      },
    );
  }
}
