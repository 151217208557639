<div class="modal-dialog get-started-modal-container modal-content modal-background">
  <div class="modal-header modal-custom-header error-modal-custom-header">
    <button type="button" class="close pull-right set-font" (click)="close()">
      <i class="fa fa-times close-icon" aria-hidden="true"></i>
    </button>
  </div>
  <div class="modal-body display-flex-center-cls">
    <div class="title-text">
      <div class="main-header">With Us, Your Home’s Protected</div>
      <div class="sub-header">Find Your Protection Plan</div>
    </div>
    <div>
      <hos-google-places
        (addressHandler)="addressHandler($event)"
        [btnText]="'Get Started'"
        [placeholder]="'Enter Your Zip Code'"
        class="hero-banner"
      ></hos-google-places>
    </div>
    <div class="alert text-danger" *ngIf="errorText.length > 0">
      {{ errorText }}
    </div>
  </div>
</div>
