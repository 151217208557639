import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserIdleConfig, UserIdleService } from './user-idle.service';

@NgModule({
  imports: [CommonModule],
  declarations: [],
  providers: [UserIdleService],
})
export class UserIdleModule {
  static forRoot(config: UserIdleConfig): ModuleWithProviders<UserIdleModule> {
    return {
      ngModule: UserIdleModule,
      providers: [
        UserIdleService,
        {
          provide: 'config',
          useValue: config,
        },
      ],
    };
  }
}
