import { Component, Output, OnInit, Input, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UtilityProviderService } from '../../../core/services/utility-provider';
import { SessionStorageService } from '../../../core/services/session-storage.service';
import { GooglePlacesService } from '../../../core/services/google-places.service';
import { LocationService } from '../../services/location.service';

import { SiteContentService } from '../../../core/services/siteContents';
import { GlobalVariables } from '../../../core/services/global-variables.service';
import { UtilsService } from '../../../core/services/utils.service';

@Component({
  selector: 'hos-google-places',
  templateUrl: './google-places.component.html',
  styleUrls: ['./google-places.component.scss'],
})
export class GooglePlacesComponent implements OnInit {
  @Output() addressHandler: EventEmitter<any> = new EventEmitter<any>();

  @Output() zipcodeSend: EventEmitter<any> = new EventEmitter<any>();

  @Input() btnText: string;

  @Input() placeholder: string;

  @Input() isLeakProg: any;

  @Input() subtitleText: any;

  alerts: any = [];

  getZipcodefromUrl: String;

  isInvalid: Boolean = false;

  selectedAddress: any = {
    rawData: '',
    zipcode: '',
  };

  defaultAutocompleteSettings: any = {
    showSearchButton: false,
    inputPlaceholderText: 'Enter your Zip Code or Address',
    inputString: '',
    geoCountryRestriction: ['us'],
  };

  validAddress = true;

  strAddress: String = '';

  isGetStartedClick: Boolean = false;

  constructor(
    private utilityProviderService: UtilityProviderService,
    private sessionStorageService: SessionStorageService,
    private router: Router,
    private route: ActivatedRoute,
    private locationService: LocationService,
    private googlePlacesService: GooglePlacesService,
    private siteContentService: SiteContentService,
    private globalVariables: GlobalVariables,
    private utilsService: UtilsService,
  ) {}

  ngOnInit() {
    this.btnText = this.btnText ? this.btnText : 'SEARCH';
    this.subtitleText = this.subtitleText ? this.subtitleText : 'Enter your ZIP to see your monthly payment.';
    this.placeholder = this.placeholder ? this.placeholder : '';
    this.isLeakProg = this.isLeakProg ? this.isLeakProg : 'false';
    const url = this.sessionStorageService.getItem('providerState');
    this.sessionStorageService.deleteItem('tempZip');
    this.getZipcodefromUrl = this.route.snapshot.params.zipcode;
    if (this.sessionStorageService.getItem('selectedAddress')) {
      const savedAddress = JSON.parse(this.sessionStorageService.getItem('selectedAddress'));
      if (this.getZipcodefromUrl) {
        if (savedAddress.zipcode !== this.getZipcodefromUrl) {
          this.selectedAddress.zipcode = this.getZipcodefromUrl;
          this.strAddress = this.getZipcodefromUrl;
        } else {
          this.strAddress = savedAddress.zipcode;
          this.selectedAddress.rawData = savedAddress.rawData;
          this.selectedAddress.zipcode = savedAddress.zipcode;
        }
      } else if (savedAddress.zipcode !== '') {
        this.strAddress = savedAddress.zipcode;
        this.selectedAddress.rawData = savedAddress.rawData;
        this.selectedAddress.zipcode = savedAddress.zipcode;
      } else {
        this.utilsService.getPartnerDetails$().subscribe(
          data => {
            const resp = data.body;
            this.strAddress = resp.defaultZipCode;
          },
          () => {},
        );
      }

      this.validAddress = false;
      /**
       * Zipcode lookup caching fix (Defect SL122)
         if (!savedAddress.rawData) {
          this.strAddress = savedAddress.zipcode;
         }
       */
      if (this.router.url === 'products' && savedAddress.zipcode.length === 5 && !Number.isNaN(Number(savedAddress.zipcode))) {
        this.getLocationCode(this.strAddress);
      }
    } else if (this.getZipcodefromUrl) {
      this.strAddress = this.getZipcodefromUrl;
      this.selectedAddress.zipcode = this.getZipcodefromUrl;
      this.getLocationCode(this.getZipcodefromUrl);
    } else if (url) {
      this.utilsService.getPartnerDetails$().subscribe(
        data => {
          const resp = data.body;
          this.strAddress = resp.defaultZipCode;
        },
        () => {},
      );
    }

    this.sessionStorageService.setItem('selectedAddress', JSON.stringify(this.selectedAddress));
  }

  autoCompleteSearchCallback(data: any) {
    this.sessionStorageService.deleteItem('providerSelected');
    this.sessionStorageService.deleteItem('isProvider');
    this.sessionStorageService.deleteItem('websitePartnerName');
    this.sessionStorageService.setItem('isMicroSite', '0');
    this.sessionStorageService.deleteItem('baseUrlState');
    if (data) {
      if (data) {
        for (let i = 0; i < data.address_components.length; i++) {
          for (let j = 0; j < data.address_components[i].types.length; j++) {
            if (data.address_components[i].types[j] === 'postal_code') {
              this.selectedAddress.rawData = data;
              this.selectedAddress.zipcode = this.strAddress;
              this.validAddress = false;
              this.sessionStorageService.setItem('selectedAddress', JSON.stringify(this.selectedAddress));
            }
          }
        }
      }
    } else {
      this.sessionStorageService.deleteItem('location_code');
      this.sessionStorageService.deleteItem('selectedAddress');
    }
  }

  getStartedClick() {
    if (this.strAddress) {
      this.googlePlacesService.getCityAndStateData(this.strAddress).subscribe(
        result => {
          this.autoCompleteSearchCallback(result.body.results[0]);
          this.sessionStorageService.deleteItem('providerSelected');
          this.sessionStorageService.deleteItem('isProvider');
          this.sessionStorageService.deleteItem('websitePartnerName');
          this.sessionStorageService.setItem('isMicroSite', '0');
          this.sessionStorageService.deleteItem('baseUrlState');
          this.isGetStartedClick = true;
          if (this.selectedAddress && this.selectedAddress.zipcode) {
            this.getLocationCode(this.strAddress);
          } else {
            this.selectedAddress.rawData = '';
            this.selectedAddress.zipcode = this.strAddress;
            this.sessionStorageService.setItem('selectedAddress', JSON.stringify(this.selectedAddress));

            if (this.strAddress && this.strAddress.length < 6 && !Number.isNaN(Number(this.strAddress))) {
              this.getLocationCode(this.strAddress);
            } else {
              this.isInvalid = true;
              this.sessionStorageService.setItem('selectedAddress', JSON.stringify(''));
            }
          }
        },
        () => {
          this.isInvalid = true;
        },
      );
    } else {
      this.isInvalid = true;
      this.sessionStorageService.setItem('selectedAddress', JSON.stringify(''));
      this.locationService.ZipcodeAndLocation = '';
    }
  }

  getLocationCode(zipcode: String) {
    let payload: any;
    const providerSelectionDetails = JSON.parse(this.sessionStorageService.getItem('providerSelectionDetails'));
    if (
      this.isGetStartedClick &&
      providerSelectionDetails &&
      providerSelectionDetails.providerValue === 'no' &&
      providerSelectionDetails.zipCode === zipcode
    ) {
      payload = {
        zipcode,
        location_code: providerSelectionDetails.locationCodeId,
      };
      this.addressHandler.emit(payload);
      this.locationService.ZipcodeAndLocation = payload;
      this.isInvalid = false;
    } else {
      this.locationService.getLocationCode(zipcode).subscribe(
        result => {
          const locationData = result.body;
          if (result.status === 200) {
            this.sessionStorageService.setItem('location_code', locationData.locationCodeId);
          } else {
            this.sessionStorageService.setItem('location_code', 'isOOF');
          }
          payload = {
            zipcode,
            location_code: locationData.locationCodeId,
          };
          this.addressHandler.emit(payload);
          this.locationService.ZipcodeAndLocation = payload;
          this.isInvalid = false;
        },
        () => {
          this.isInvalid = true;
          this.sessionStorageService.setItem('location_code', 'isOOF');
          this.addressHandler.emit(false);
        },
      );
    }
  }

  changeHandler(event: any) {
    this.sessionStorageService.setItem('tempZip', event.target.value);
    if (event.which !== 13) {
      this.sessionStorageService.deleteItem('providerSelected');
      this.sessionStorageService.deleteItem('isProvider');
      this.sessionStorageService.deleteItem('websitePartnerName');
      this.isInvalid = false;
      this.strAddress = event.target.value;
      this.sessionStorageService.deleteItem('location_code');
      this.sessionStorageService.deleteItem('selectedAddress');
      this.sessionStorageService.deleteItem('recentSearches');
      this.selectedAddress = {
        rawData: '',
        zipcode: event.target.value,
      };
      this.sessionStorageService.setItem('selectedAddress', JSON.stringify(this.selectedAddress));
    }
    if ((event.target.value.length === 0 || event.target.value.length === 5) && !this.router.url.includes('products')) {
      this.globalVariables.categoriesBasedOnZipcode.emit(event.target.value);
    }
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  // showErrorModal(title: String, message: String) {
  //   this.simpleModalService.addModal(ErrorModalComponent, {
  //     title: title,
  //     message: message
  //   }, {
  //       wrapperClass: 'modal-main-class in',
  //       closeOnClickOutside: true
  //     })
  //     .subscribe((isConfirmed) => {
  //       if (isConfirmed) {

  //       } else if (isConfirmed === false) {

  //       } else if (isConfirmed === undefined) {

  //       }
  //     });
  // }
}
