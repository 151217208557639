<div class="modal-dialog modal-custom-header cancellation-form modal-content">
  <div class="modal-header">
    <button type="button" class="close pull-right set-font closeBtn" (click)="modalClose()">
      <i class="fa fa-times close-icon" aria-hidden="true"></i>&nbsp;&nbsp;CLOSE
    </button>
    <div class="header-text cancel-enroll">Enrollment Cancellation</div>
  </div>
  <div class="modal-body">
    <div class="row cancel-enroll-row">
      <div class="col-md-12 product-details">
        <h6 *ngIf="!lpTagscriptNotLoaded" class="form-read-title ml-0">
          We're sorry to see you go. Please click on the button below to request the cancellation of your program(s).
        </h6>
        <h6 *ngIf="lpTagscriptNotLoaded" class="form-read-title ml-0">
          We are currently experiencing technical difficulties. Please try again later. We apologize for any inconvenience.
        </h6>
      </div>
    </div>
    <div class="card-body-box">
      <div class="card-body row no-gutters">
        <div class="col-12">
          <form #cancellationEnrForm="ngForm" novalidate>
            <section class="content-section content-block form-section">
              <div class="row page-wrap PD-0">
                <div class="col-12 col-md-12 col-sm-12 PD-0">
                  <div class="form-outer">
                    <div class="customer-information">
                      <!-- <div class="row">
                                                    <div class="form-group col-md-6 col-sm-12">
                                                        <label for="name">Name</label>
                                                        <div class="label-value">{{customerName}}</div>
                                                    </div>
                                                    <div class="form-group col-md-6 col-sm-12">
                                                        <label for="program">Program</label>
                                                        <div class="label-value">{{serviceDetails?.productDetails?.webProgramName || serviceDetails?.productDetails?.customerProgramName}}</div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="form-group col-md-6 col-sm-12">
                                                        <label for="customer_number">Customer Number</label>
                                                        <div class="label-value">{{serviceDetails?.crmCustomerNumber}}</div>
                                                    </div>
                                                    <div class="form-group col-md-6 col-sm-12">
                                                        <label for="enrollment_number">Enrollment Number</label>
                                                        <div class="label-value">{{serviceDetails?.crmEnrollmentNumber}}</div>
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="form-group set-field-width col-sm-12 col-md-6">
                                                        <label for="cancellationReqDt">Cancellation Requested Date</label>
                                                        <div class="label-value">{{cancellationRequestedDt}}</div>
                                                    </div>
                                                    <div class="form-group set-field-width col-sm-12 col-md-6">
                                                        <label for="selectType">Cancellation Reason</label>
                                                        <ng-select placeholder="- Select a Cancellation Reason -" [ngClass]="{'is-invalid': isClicked && !cancellationReason.valid}"
                                                            [(ngModel)]="cancellationenrollmentForm.cancellationReason" name="cancellationReason" required ngModel #cancellationReason="ngModel">
                                                            <ng-container *ngFor="let list of cancellationReasonList" >
                                                                <ng-option [value]="list?.reasonValue">{{list?.reasonValue}}</ng-option>
                                                            </ng-container>
                                                        </ng-select>
                                                        <small class="form-text text-danger" *ngIf="isClicked && !cancellationReason.valid">Please select cancellation reason</small>
                                                    </div>
                                                </div> -->
                      <div class="row justify-content-center" (click)="onLiveChatOpen()">
                        <div id="LP_DIV_1652199867486"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="modal-footer">
            <div class="cursor-pointer">
                <button class="btn print-btn text-uppercase" (click)="modalClose()"> CANCEL </button>
            </div>
            <div>
                <button class="btn submit-btn mt-0 text-uppercase" (click)="onSubmit(cancellationEnrForm)"> SUBMIT FORM </button>
            </div>
        </div> -->
</div>

<!-- <div [ngClass]="{'loading' : showLoader}"></div> -->
