import { Directive, Input, EventEmitter, Output, ElementRef, HostListener } from '@angular/core';
import { GlobalVariables } from '../services/global-variables.service';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[scrollSpy]',
})
export class ScrollSpyDirective {
  @Input() public spiedTags = [];

  @Output() public sectionChange = new EventEmitter<string>();

  private currentSection: string;

  constructor(private el: ElementRef, private globalVariables: GlobalVariables) {}

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    if (window.innerWidth > 767) {
      let currentSection: string;
      const { children } = this.el.nativeElement;
      const scrollSpyId = document.getElementById('scroll-spy-id');
      const scrollTop = scrollSpyId.scrollTop || window.pageYOffset;
      const parentOffset = scrollSpyId ? scrollSpyId.offsetTop : 120;
      for (let i = 0; i < children.length; i++) {
        const element = children[i];
        if (this.spiedTags.some(spiedTag => spiedTag === element.tagName)) {
          if (element.offsetTop - parentOffset - 50 <= scrollTop) {
            currentSection = element.id;
          }
        }
      }
      if (currentSection !== this.currentSection) {
        this.currentSection = currentSection;
        if (!this.globalVariables.fixedHeaderProdDetails) {
          this.currentSection = 'tab-review-section';
        }
        this.sectionChange.emit(this.currentSection);
      }
    }
  }
}
