import { Component } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';

export interface AchTremsModal {
  title: string;
  message: string;
}

@Component({
  selector: 'hos-ach-terms-modal',
  templateUrl: './ach-terms-modal.component.html',
  styleUrls: ['./ach-terms-modal.component.scss'],
})
export class AchTermsModalComponent extends SimpleModalComponent<AchTremsModal, any> implements AchTremsModal {
  title: string;

  message: string;

  confirmHandler() {
    this.close();
  }

  print() {
    const doc = document.getElementById('achTerms').innerHTML;
    const mywindow = window.open('', 'Print', 'height=600,width=800');
    mywindow.document.write('<html><head><title>Print</title>');
    mywindow.document.write('</head><body >');
    mywindow.document.write(doc);
    mywindow.document.write('</body></html>');
    mywindow.document.close();
    mywindow.print();
  }
}
