import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PlatformLocation } from '@angular/common';
import { SimpleModalComponent, SimpleModalService } from 'ngx-simple-modal';
import { SessionStorageService } from '../../../core/services/session-storage.service';
import { CartService } from '../../../cart/services/cart.service';
import { LocationService } from '../../../home/services/location.service';
import { ProductService } from '../../services/product.service';
import { environment } from '../../../../environments/environment';
import { SeoService } from '../../../core/services/seo.service';
import { UtilsService } from '../../../core/services/utils.service';

export interface ProviderModal {
  title?: string;
  message?: string;
  zipcode?: any;
}

@Component({
  selector: 'hos-app-provider-modal',
  templateUrl: './provider-modal.component.html',
  styleUrls: ['./provider-modal.component.scss'],
})
export class ProviderModalComponent extends SimpleModalComponent<ProviderModal, any> implements ProviderModal, OnInit {
  title: string;

  message: string;

  zipcode?: any;

  isData: Boolean = false;

  URLZipcode: String;

  location_code: String;

  selectedAddress: any = {};

  providerName: String;

  providerLogo: String;

  locationCode: String;

  constructor(
    simpleModalService: SimpleModalService,
    private router: Router,
    public activatedRoute: ActivatedRoute,
    private cartService: CartService,
    private seoService: SeoService,
    private route: ActivatedRoute,
    private locationService: LocationService,
    private productService: ProductService,
    private sessionStorageService: SessionStorageService,
    public platformLocation: PlatformLocation,
    public utilsService: UtilsService,
  ) {
    super();
    platformLocation.onPopState(() => {
      this.close();
      this.router.navigate(['/']);
    });
  }

  ngOnInit() {
    this.URLZipcode = this.route.snapshot.params.zipcode || this.zipcode;
    this.selectedAddress = JSON.parse(this.sessionStorageService.getItem('selectedAddress'));
    if (this.URLZipcode || this.selectedAddress.zipcode) {
      this.URLZipcode = this.URLZipcode ? this.URLZipcode : this.selectedAddress.zipcode;
      this.locationService.getLocationCode(this.URLZipcode).subscribe(
        result => {
          if (result.status === 200) {
            const locationData = result.body;
            this.location_code = locationData.locationCodeId;
            // this.sessionStorageService.setItem('location_code', locationData.locationCodeId);
          } else {
            this.sessionStorageService.setItem('location_code', 'isOOF');
          }
          this.getLocationCode();
        },
        () => {
          this.sessionStorageService.setItem('location_code', 'isOOF');
          this.getLocationCode();
        },
      );
    }
  }

  getLocationCode() {
    this.isData = true;
    // this.location_code = this.sessionStorageService.getItem('location_code');
    this.productService.getProviderContent(this.location_code).subscribe(data => {
      const { body } = data;
      this.locationCode = body.locationCode;
      this.providerName = body.websitePartnerName;
      this.providerLogo = this.utilsService.buildImageUrl(
        environment.Image_API_Endpoint + environment.Location_service_API_Endpoint + (body.providerIcon ? body.providerIcon : ''),
      );
    });
  }

  confirm() {
    this.sessionStorageService.setItem('providerSelected', '1');
    this.sessionStorageService.setItem('isProvider', '1');
    this.result = true;
    /* HWR-1445 Store provider selected value for zipcode - start */
    const providerDetails: any = {};
    providerDetails.zipCode = this.URLZipcode;
    providerDetails.providerSelected = true;
    this.sessionStorageService.setItem('providerSelectionDetails', JSON.stringify(providerDetails));
    /* HWR-1445 end */
    this.close();
  }

  cancel() {
    this.sessionStorageService.setItem('isProvider', '0');
    /* HWR-1445 Store provider selected value for zipcode - start */
    const providerDetails: any = {};
    providerDetails.zipCode = this.URLZipcode;
    providerDetails.providerSelected = true;
    providerDetails.providerValue = 'no';
    this.sessionStorageService.setItem('providerSelectionDetails', JSON.stringify(providerDetails));
    /* HWR-1445 end */
    this.close();
  }
}
