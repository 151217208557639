/**
 *  Contains probbing questions  for all programs
 *  Mapped with
 *  @name programName - eg. SLPP
 *  @property programName - String
 *  @property questions - Array of probbing questions for each program
 *
 */

export const claimsConfig = {
  SLPP: {
    productId: 'd7cb9274-15d7-e411-9425-00155ddf6652',
    daysForChildClaim: 90,
    questions: [
      {
        // 1
        question: 'When did you first notice the issue?',
        type: 'date-input',
        placeholder: 'Enter the date in DD/MM/YY format',
        answer: null,
        error: {},
        next: 1,
        updateInPayload: null,
        claimField: 'firstNoticed',
      },
      {
        // 2
        question: 'Where is the sewer backing up from?',
        type: 'text-input',
        placeholder: 'Enter text here...',
        answer: null,
        error: {},
        next: 2,
        updateInPayload: null,
        claimField: 'backupLocation',
      },
      {
        // 3
        question: 'Which drains are slowly draining?',
        type: 'text-input',
        placeholder: 'Enter text here...',
        answer: null,
        error: {},
        next: 3,
        updateInPayload: null,
        claimField: 'drainsLocation',
      },
      {
        // 4
        question: 'On what floor is the drain/fixture located?',
        type: 'text-input',
        placeholder: 'Enter text here...',
        answer: null,
        error: {},
        next: 4,
        updateInPayload: null,
        claimField: 'issueLocation',
      },
      {
        // 5
        question: 'Is your sewer cleanout inside?',
        type: 'radio-multi',
        inputArray: ['Inside', 'Outside', "Don't know"],
        answer: null,
        error: {},
        next: {
          Inside: 5,
          Outside: 5,
          "Don't know": 5,
        },
        updateInPayload: null,
        claimField: 'sewerCleanout',
      },
      // [HWR-3112 Added new Questions ]
      {
        // 6
        question: 'Are you experiencing a backup?',
        type: 'radio-multi',
        inputArray: ['YES', 'NO'],
        answer: null,
        error: {
          NO: 'Not a covered component',
        },
        next: {
          YES: 6,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        // 7
        question: 'Is the backup occurring when using water or without the use of water?',
        type: 'dropdown',
        inputArray: ['With the use of water', 'Without the use of water'],
        answer: null,
        error: {},
        next: {
          'With the use of water': 7,
          'Without the use of water': 8,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        // 8
        question: 'What is backing up?',
        type: 'text-input',
        placeholder: 'Enter text here...',
        answer: null,
        error: {},
        next: 8,
        updateInPayload: null,
        claimField: null,
      },
      {
        // 9
        question: 'Have you experienced heavy rain or flooding in your area?',
        type: 'radio-multi',
        inputArray: ['Rain', 'Flooding', 'Neither of these'],
        answer: null,
        error: {
          Rain: 'Denied Claim',
          Flooding: 'Denied Claim',
        },
        next: {
          'Neither of these': 9,
        },
        updateInPayload: null,
        claimField: 'rainorFlooding',
      },
      {
        // 10
        question: 'Please Enter Suggested Time and Date for Repair',
        type: 'dropdown',
        inputArray: ['Manually enter Date', 'Morning (8 AM- 12PM)', 'Afternoon (12 PM- 5 PM)', 'Evening ( After 5 PM)'],
        answer: null,
        next: {
          'Manually enter Date': 10,
          'Morning (8 AM- 12PM)': 11,
          'Afternoon (12 PM- 5 PM)': 11,
          'Evening ( After 5 PM)': 11,
        },
        error: {},
        updateInPayload: null,
        claimField: null,
      },
      {
        // 11
        question: 'Enter Date for Repair',
        type: 'date-input',
        placeholder: 'Enter the date in DD/MM/YY format',
        answer: null,
        error: {},
        manualDate: 'Yes',
        next: 11,
        updateInPayload: null,
        claimField: null,
      },
      {
        // 12
        question: 'Additional Notes/Conversation',
        type: 'text-area-input',
        placeholder: 'Enter text here...',
        answer: null,
        error: {},
        next: 12,
        updateInPayload: null,
        claimField: 'additionalNotes',
      },
      {
        // 13
        question: 'Please enter name of the Claimant for the contractor to contact',
        type: 'text-input',
        placeholder: 'Enter an claimant name',
        answer: null,
        next: 13,
        error: {},
        updateInPayload: null,
        claimField: 'contactName',
      },
      {
        // 14
        question: 'Please Enter the best number for the contractor to contact',
        type: 'tel',
        placeholder: 'Enter an contact number',
        answer: null,
        next: null,
        error: {},
        updateInPayload: null,
        claimField: 'contactNo',
      },
    ],
    // [HWR-3280 Updateing claims question for NYC200 location]
    'ac7744e6-4d23-e611-9431-00155ddf6a62': [
      {
        // 0
        question: 'Are you currently backing up?',
        type: 'radio-multi',
        inputArray: ['Yes', 'No'],
        answer: null,
        error: {},
        next: {
          Yes: 1,
          No: 5,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        // 1
        question: 'Where is the backup occurring?',
        type: 'text-input',
        placeholder: 'Enter text here...',
        answer: null,
        error: {},
        next: 2,
        updateInPayload: null,
        claimField: null,
      },
      {
        // 2
        question: 'When did you first notice the backup?',
        type: 'date-input',
        placeholder: 'Enter the date in DD/MM/YY format',
        answer: null,
        error: {},
        next: 3,
        updateInPayload: null,
        claimField: null,
      },
      {
        // 3
        question: 'Is it backing up when water is being used on ALL levels and all fixtures of the home?',
        type: 'radio-multi',
        inputArray: ['Yes', 'No'],
        answer: null,
        error: {
          No: 'DENY',
        },
        next: {
          Yes: 4,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        // 4
        question: 'Has there been any heavy rains in the area?',
        type: 'radio-multi',
        inputArray: ['Yes', 'No'],
        answer: null,
        error: {},
        next: {
          Yes: 5,
          No: 5,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        // 5
        question: 'When did you first notice the issue?',
        type: 'date-input',
        placeholder: 'Enter the date in DD/MM/YY format',
        answer: null,
        error: {},
        next: 6,
        updateInPayload: null,
        claimField: 'firstNoticed',
      },
      {
        // 6
        question: 'Have you experienced heavy rain or flooding in your area?',
        type: 'radio-multi',
        inputArray: ['Rain', 'Flooding', 'Neither of these'],
        answer: null,
        error: {},
        next: {
          Rain: 7,
          Flooding: 7,
          'Neither of these': 7,
        },
        updateInPayload: null,
        claimField: 'rainorFlooding',
      },
      {
        // 7
        question: 'Where is the sewer backing up from?',
        type: 'text-input',
        placeholder: 'Enter text here...',
        answer: null,
        error: {},
        next: 8,
        updateInPayload: null,
        claimField: 'backupLocation',
      },
      {
        // 8
        question: 'Which drains are slowly draining?',
        type: 'text-input',
        placeholder: 'Enter text here...',
        answer: null,
        error: {},
        next: 9,
        updateInPayload: null,
        claimField: 'drainsLocation',
      },
      {
        // 9
        question: 'On what floor is the drain/fixture located?',
        type: 'text-input',
        placeholder: 'Enter text here...',
        answer: null,
        error: {},
        next: 10,
        updateInPayload: null,
        claimField: 'issueLocation',
      },
      {
        // 10
        question: 'Is your sewer cleanout inside?',
        type: 'radio-multi',
        inputArray: ['Inside', 'Outside', "Don't know"],
        answer: null,
        error: {},
        next: {
          Inside: 11,
          Outside: 11,
          "Don't know": 11,
        },
        updateInPayload: null,
        claimField: 'sewerCleanout',
      },
      {
        // 11
        question: 'Please Enter Suggested Time and Date for Repair',
        type: 'dropdown',
        inputArray: ['Manually enter Date', 'Morning (8 AM- 12PM)', 'Afternoon (12 PM- 5 PM)', 'Evening ( After 5 PM)'],
        answer: null,
        next: {
          'Manually enter Date': 12,
          'Morning (8 AM- 12PM)': 13,
          'Afternoon (12 PM- 5 PM)': 13,
          'Evening ( After 5 PM)': 13,
        },
        error: {},
        updateInPayload: null,
        claimField: null,
      },
      {
        // 12
        question: 'Enter Date for Repair',
        type: 'date-input',
        placeholder: 'Enter the date in DD/MM/YY format',
        answer: null,
        error: {},
        manualDate: 'Yes',
        next: 13,
        updateInPayload: null,
        claimField: null,
      },
      {
        // 13
        question: 'Additional Notes/Conversation',
        type: 'text-area-input',
        placeholder: 'Enter text here...',
        answer: null,
        error: {},
        next: 14,
        updateInPayload: null,
        claimField: 'additionalNotes',
      },
      {
        // 14
        question: 'Please enter name of the Claimant for the contractor to contact',
        type: 'text-input',
        placeholder: 'Enter an claimant name ',
        answer: null,
        next: 15,
        error: {},
        updateInPayload: null,
        claimField: 'contactName',
      },
      {
        // 15
        question: 'Please Enter the best number for the contractor to contact',
        type: 'tel',
        placeholder: 'Enter an contact number ',
        answer: null,
        next: null,
        error: {},
        updateInPayload: null,
        claimField: 'contactNo',
      },
    ],
  },
  SEPT: {
    // Septic
    productId: 'd6cb9274-15d7-e411-9425-00155ddf6652',
    daysForChildClaim: 60,
    questions: [
      {
        question: 'When did the issue Occur?',
        type: 'date-input',
        placeholder: 'Enter the date in DD/MM/YY format',
        answer: null,
        error: {},
        next: 1,
        updateInPayload: null,
        claimField: 'firstNoticed',
      },
      {
        question: 'Have you experienced heavy rain or flooding in your area?',
        type: 'radio-multi',
        inputArray: ['Rain', 'Flooding', 'Neither of these'],
        answer: null,
        error: {},
        next: {
          Rain: 2,
          Flooding: 2,
          'Neither of these': 2,
        },
        updateInPayload: null,
        claimField: 'rainorFlooding',
      },
      {
        question: 'Where is the sewer backing up from?',
        type: 'text-input',
        placeholder: 'Enter text here...',
        answer: null,
        error: {},
        next: 3,
        updateInPayload: null,
        claimField: 'backupLocation',
      },
      {
        question: 'Which drains are slowly draining?',
        type: 'text-input',
        placeholder: 'Enter text here...',
        answer: null,
        error: {},
        next: 4,
        updateInPayload: null,
        claimField: 'drainsLocation',
      },
      {
        question: 'On what floor is the drain/fixture located?',
        type: 'text-input',
        placeholder: 'Enter text here...',
        answer: null,
        error: {},
        next: 5,
        updateInPayload: null,
        claimField: 'issueLocation',
      },
      {
        question: 'When was the septic tank last emptied?',
        type: 'date-input',
        placeholder: 'Enter the date in DD/MM/YY format',
        answer: null,
        error: {},
        next: 6,
        updateInPayload: null,
        claimField: 'lastEmptied',
      },
      {
        question: 'Additional Notes/Conversation',
        type: 'text-area-input',
        placeholder: '',
        answer: null,
        error: {},
        next: 7,
        updateInPayload: null,
        claimField: 'additionalNotes',
      },
      {
        question: 'Please enter name of the Claimant for the contractor to contact',
        type: 'text-input',
        placeholder: 'Enter an claimant name ',
        answer: null,
        next: 8,
        error: {},
        updateInPayload: null,
        claimField: 'contactName',
      },
      {
        question: 'Please Enter the best number for the contractor to contact',
        type: 'tel',
        placeholder: 'Enter an contact number ',
        answer: null,
        next: null,
        error: {},
        updateInPayload: null,
        claimField: 'contactNo',
      },
    ],
  },
  WLPP: {
    productId: 'd9cb9274-15d7-e411-9425-00155ddf6652',
    daysForChildClaim: 90,
    CA: 60,
    VA: 60,
    questions: [
      {
        // Question1 -- 0
        question: 'Has the water company come out to verify your service line leak?',
        type: 'radio-multi',
        inputArray: ['Yes', 'No'],
        answer: null,
        error: {
          No: 'Instruct customer to contact their local water company to have a leak verification done.',
        },
        next: {
          Yes: 1,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        // Question2 -- 1
        question: 'Is your Water On or Off?',
        type: 'radio-multi',
        inputArray: ['On', 'Off'],
        answer: null,
        error: {},
        next: {
          On: 5,
          Off: 2,
        },
        updateInPayload: null,
        claimField: 'waterOff',
      },
      {
        // Question2 -- 2 (manupulate payload)
        question: 'Did the Water company or Emergency Personnel (I.E. Firefighters, police or City) shut the water off?',
        type: 'radio-multi',
        inputArray: ['Yes', 'No'],
        answer: null,
        error: {},
        next: {
          Yes: 3,
          No: 5,
        },
        updateInPayload: {
          Yes: {
            waterOff: true,
          },
        },
        claimField: {
          Yes: {
            waterOff: true,
          },
        },
      },
      {
        question: 'Please enter name of the Claimant for the contractor to contact',
        type: 'text-input',
        placeholder: 'Enter an claimant name ',
        answer: null,
        next: 4,
        error: {},
        updateInPayload: null,
        claimField: 'contactName',
      },
      {
        question: 'Please Enter the best number for the contractor to contact',
        type: 'tel',
        placeholder: 'Enter an contact number ',
        answer: null,
        next: null,
        error: {},
        updateInPayload: null,
        claimField: 'contactNo',
      },
      {
        // Question3 -- 3
        question: 'Where is the leak located?',
        type: 'dropdown',
        inputArray: [
          'By the Meter inside the home',
          'By the Meter at the street',
          'In the yard',
          'On the shut off valve inside the home',
          'On Sprinkler system',
          'Water Company Side',
          'Outside Hose Bib/Spigot',
          'OTHER',
        ],
        answer: null,
        error: {
          'On Sprinkler system': 'Claim Denied "Sprinkler/Irrigation Systems"',
          'Water Company Side': 'Claim Denied " Water Company Side"',
          'Outside Hose Bib/Spigot': 'Claim Denied "Hose Bib/Spigot Leak"',
          OTHER: 'Instruct customer to call AWR for additional assistance',
        }, // for multiple option selection Only
        next: {
          'By the Meter inside the home': 6,
          'By the Meter at the street': 15,
          'In the yard': 15,
          'On the shut off valve inside the home': 8,
        },
        updateInPayload: null,
        claimField: 'leakLocation',
      },
      {
        // Question3 -- 4
        question: 'Is the leak before the meter (closest to exterior wall)',
        type: 'radio-multi',
        inputArray: ['YES', 'NO'],
        answer: null,
        error: {},
        next: {
          YES: 11,
          NO: 7,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        // Question3 -- 5
        question: 'Is the leak after the meter?',
        type: 'radio-multi',
        inputArray: ['YES', 'NO'],
        answer: null,
        error: {
          YES: 'Not covered under WLPP, If customer has IHPP proceed with IHPP Questions. If not enrolled in IHPP Claim denied for "Leak/Break on Interior Pipes After Shut-Off Valve"',
        },
        next: {
          NO: 8,
        }, // need to mention error redirection here. For continuation we are moving it forward.
        updateInPayload: null,
        claimField: null,
      },
      {
        // Question3 -- 6
        question: 'Is your meter located inside or outside the home?',
        type: 'radio-multi',
        inputArray: ['Inside', 'Outside'],
        answer: null,
        error: {},
        next: {
          Inside: 11,
          Outside: 9,
        },
        updateInPayload: {
          Outside: {
            jobTypeId: '60D5F45E-236C-E511-941F-00155D006B18',
          },
        },
        claimField: null,
      },
      {
        question: 'Please enter name of the Claimant for the contractor to contact',
        type: 'text-input',
        placeholder: 'Enter an claimant name',
        answer: null,
        next: 10,
        error: {},
        updateInPayload: null,
        claimField: 'contactName',
      },
      {
        question: 'Please Enter the best number for the contractor to contact',
        type: 'tel',
        placeholder: 'Enter an contact number',
        answer: null,
        next: null,
        error: {},
        updateInPayload: null,
        claimField: 'contactNo',
      },
      {
        // Question3 -- 7 change in payload
        question: 'Is the shut off valve before the meter (closest to the exterior wall)',
        type: 'radio-multi',
        inputArray: ['YES', 'NO'],
        answer: null,
        next: {
          YES: 12,
          NO: 14,
        },
        error: {},
        updateInPayload: {
          YES: {
            jobTypeId: '60D5F45E-236C-E511-941F-00155D006B18',
          },
        },
        claimField: null,
      },
      {
        question: 'Please enter name of the Claimant for the contractor to contact',
        type: 'text-input',
        placeholder: 'Enter an claimant name',
        answer: null,
        next: 13,
        error: {},
        updateInPayload: null,
        claimField: 'contactName',
      },
      {
        question: 'Please Enter the best number for the contractor to contact',
        type: 'tel',
        placeholder: 'Enter an contact number ',
        answer: null,
        next: null,
        error: {},
        updateInPayload: null,
        claimField: 'contactNo',
      },
      {
        // Question3 -- 8
        question: 'Is the shut off valve after the meter?',
        type: 'radio-multi',
        inputArray: ['YES', 'NO'],
        answer: null,
        error: {
          YES: 'Not covered under WLPP, If customer has IHPP proceed with IHPP Questions. If not enrolled in IHPP Claim denied for "Leak/Break on Interior Pipes After Shut-Off Valve"',
        },
        next: {
          NO: 15,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        // Question4 -- 9
        question: 'Was the leak caused by normal wear and tear?',
        type: 'radio-multi',
        inputArray: ['YES', 'NO'],
        answer: null,
        error: {
          NO: 'Claim denied for "Third Party Damage"',
        },
        next: {
          YES: 16,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        // Question5 -- 10
        question: 'Is the Leak Visible?',
        type: 'radio-multi',
        inputArray: ['YES', 'NO'],
        answer: null,
        next: {
          YES: 19,
          NO: 17,
        },
        error: {},
        updateInPayload: null,
        claimField: null,
      },
      {
        // Question5 -- 11
        question: 'Did you suspect that there was a leak because of a high water bill?',
        type: 'radio-multi',
        inputArray: ['YES', 'NO'],
        answer: null,
        next: {
          YES: 18,
        },
        error: {
          NO: 'Claim ineligible',
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        // Question5 -- 12
        question: 'Is your meter located inside or outside',
        type: 'radio-multi',
        inputArray: ['Inside', 'Outside'],
        answer: null,
        error: {
          Inside:
            'Not covered under WLPP, If customer has IHPP proceed with IHPP Questions. If not enrolled in IHPP Claim denied for "Leak/Break on Interior Pipes After Shut-Off Valve"',
        },
        next: {
          Outside: 19,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        // Question6 -- 13
        question: 'Please Enter Suggested Time and Date for Repair',
        type: 'dropdown',
        inputArray: ['Manually enter Date', 'Morning (8 AM- 12PM)', 'Afternoon (12 PM- 5 PM)', 'Evening ( After 5 PM)'],
        answer: null,
        next: {
          'Manually enter Date': 20,
          'Morning (8 AM- 12PM)': 21,
          'Afternoon (12 PM- 5 PM)': 21,
          'Evening ( After 5 PM)': 21,
        },
        error: {},
        updateInPayload: null,
        claimField: null,
      },
      {
        // Question7 -- 14
        question: 'Enter Date for Repair',
        type: 'date-input',
        placeholder: 'Enter the date in DD/MM/YY format',
        answer: null,
        error: {},
        manualDate: 'Yes',
        next: 21,
        updateInPayload: null,
        claimField: null,
      },
      {
        question: 'Please enter name of the Claimant for the contractor to contact',
        type: 'text-input',
        placeholder: 'Enter an claimant name',
        answer: null,
        next: 22,
        error: {},
        updateInPayload: null,
        claimField: 'contactName',
      },
      {
        question: 'Please Enter the best number for the contractor to contact',
        type: 'tel',
        placeholder: 'Enter an contact number ',
        answer: null,
        next: null,
        error: {},
        updateInPayload: null,
        claimField: 'contactNo',
      },
    ],
    // [HWR-3280 Updateing claims question for NYC200 location]
    'ac7744e6-4d23-e611-9431-00155ddf6a62': [
      {
        // 0
        question: 'When did you first notice the leak?',
        type: 'date-input',
        placeholder: 'Enter the date in DD/MM/YY format',
        answer: null,
        error: {},
        next: 1,
        updateInPayload: null,
        claimField: 'firstNoticed',
      },
      {
        // 1
        question: 'Is the leak visible?',
        type: 'radio-multi',
        inputArray: ['Yes', 'No'],
        answer: null,
        error: {},
        next: {
          Yes: 2,
          No: 4,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        // 2
        question: 'If so, where?',
        type: 'dropdown',
        inputArray: ['Before the first main shut off valve', 'After first main shut off valve', 'Outside'],
        answer: null,
        error: {
          'After first main shut off valve': 'Denied claim.',
        },
        next: {
          'Before the first main shut off valve': 4,
          Outside: 3,
        },
        updateInPayload: null,
        claimField: 'issueType',
      },
      {
        // 3
        question: 'Are you sure its Outside',
        type: 'radio-multi',
        inputArray: ['Yes', 'No'],
        answer: null,
        error: {
          No: 'Denied claim',
        },
        next: {
          Yes: 26,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        // 4
        question: 'Have you been issued a 3-Day or 10-Day notice? ',
        type: 'radio-multi',
        inputArray: ['Yes', 'No'],
        answer: null,
        error: {},
        next: {
          Yes: 5,
          No: 5,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        // 5
        question: 'Do you currently have water?',
        type: 'radio-multi',
        inputArray: ['Yes', 'No'],
        answer: null,
        error: {},
        next: {
          Yes: 6,
          No: 6,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        // 6
        question: 'Has there ever been a repair or replacement on the water line?',
        type: 'radio-multi',
        inputArray: ['Yes', 'No'],
        answer: null,
        error: {},
        next: {
          Yes: 7,
          No: 7,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        // 7
        question: 'Has the water company come out to verify your service line leak?',
        type: 'radio-multi',
        inputArray: ['Yes', 'No'],
        answer: null,
        error: {
          No: 'Instruct customer to contact their local water company to have a leak verification done.',
        },
        next: {
          Yes: 8,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        // 8
        question: 'Is your Water On or Off?',
        type: 'radio-multi',
        inputArray: ['On', 'Off'],
        answer: null,
        error: {},
        next: {
          On: 12,
          Off: 9,
        },
        updateInPayload: null,
        claimField: 'waterOff',
      },
      {
        // 9
        question: 'Did the Water company or Emergency Personnel (I.E. Firefighters, police or City) shut the water off?',
        type: 'radio-multi',
        inputArray: ['Yes', 'No'],
        answer: null,
        error: {},
        next: {
          Yes: 10,
          No: 12,
        },
        updateInPayload: {
          Yes: {
            waterOff: true,
          },
        },
        claimField: {
          Yes: {
            waterOff: true,
          },
        },
      },
      {
        // 10
        question: 'Please enter name of the Claimant for the contractor to contact',
        type: 'text-input',
        placeholder: 'Enter an claimant name ',
        answer: null,
        next: 11,
        error: {},
        updateInPayload: null,
        claimField: 'contactName',
      },
      {
        // 11
        question: 'Please Enter the best number for the contractor to contact',
        type: 'tel',
        placeholder: 'Enter an contact number ',
        answer: null,
        next: null,
        error: {},
        updateInPayload: null,
        claimField: 'contactNo',
      },
      {
        // 12
        question: 'Where is the leak located?',
        type: 'dropdown',
        inputArray: [
          'By the Meter inside the home',
          'By the Meter at the street',
          'In the yard',
          'On the shut off valve inside the home',
          'On Sprinkler system',
          'Water Company Side',
          'Outside Hose Bib/Spigot',
          'OTHER',
        ],
        answer: null,
        error: {
          'On Sprinkler system': 'Claim Denied "Sprinkler/Irrigation Systems"',
          'Water Company Side': 'Claim Denied " Water Company Side"',
          'Outside Hose Bib/Spigot': 'Claim Denied "Hose Bib/Spigot Leak"',
          OTHER: 'Instruct customer to call AWR for additional assistance',
        }, // for multiple option selection Only
        next: {
          'By the Meter inside the home': 13,
          'By the Meter at the street': 22,
          'In the yard': 22,
          'On the shut off valve inside the home': 14,
        },
        updateInPayload: null,
        claimField: 'leakLocation',
      },
      {
        // 13
        question: 'Is the leak before the meter (closest to exterior wall)',
        type: 'radio-multi',
        inputArray: ['YES', 'NO'],
        answer: null,
        error: {},
        next: {
          YES: 18,
          NO: 14,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        // 14
        question: 'Is the leak after the meter?',
        type: 'radio-multi',
        inputArray: ['YES', 'NO'],
        answer: null,
        error: {
          YES: 'Not covered under WLPP, If customer has IHPP proceed with IHPP Questions. If not enrolled in IHPP Claim denied for "Leak/Break on Interior Pipes After Shut-Off Valve"',
        },
        next: {
          NO: 15,
        }, // need to mention error redirection here. For continuation we are moving it forward.
        updateInPayload: null,
        claimField: null,
      },
      {
        // 15
        question: 'Is your meter located inside or outside the home?',
        type: 'radio-multi',
        inputArray: ['Inside', 'Outside'],
        answer: null,
        error: {},
        next: {
          Inside: 17,
          Outside: 16,
        },
        updateInPayload: {
          Outside: {
            jobTypeId: '60D5F45E-236C-E511-941F-00155D006B18',
          },
        },
        claimField: null,
      },
      {
        // 16
        question: 'Please enter name of the Claimant for the contractor to contact',
        type: 'text-input',
        placeholder: 'Enter an claimant name ',
        answer: null,
        next: 17,
        error: {},
        updateInPayload: null,
        claimField: 'contactName',
      },
      {
        // 17
        question: 'Please Enter the best number for the contractor to contact',
        type: 'tel',
        placeholder: 'Enter an contact number ',
        answer: null,
        next: null,
        error: {},
        updateInPayload: null,
        claimField: 'contactNo',
      },
      {
        // 18
        question: 'Is the shut off valve before the meter (closest to the exterior wall)',
        type: 'radio-multi',
        inputArray: ['YES', 'NO'],
        answer: null,
        next: {
          YES: 19,
          NO: 21,
        },
        error: {},
        updateInPayload: {
          YES: {
            jobTypeId: '60D5F45E-236C-E511-941F-00155D006B18',
          },
        },
        claimField: null,
      },
      {
        // 19
        question: 'Please enter name of the Claimant for the contractor to contact',
        type: 'text-input',
        placeholder: 'Enter an claimant name ',
        answer: null,
        next: 20,
        error: {},
        updateInPayload: null,
        claimField: 'contactName',
      },
      {
        // 20
        question: 'Please Enter the best number for the contractor to contact',
        type: 'tel',
        placeholder: 'Enter an contact number ',
        answer: null,
        next: null,
        error: {},
        updateInPayload: null,
        claimField: 'contactNo',
      },
      {
        // 21
        question: 'Is the shut off valve after the meter?',
        type: 'radio-multi',
        inputArray: ['YES', 'NO'],
        answer: null,
        error: {
          YES: 'Not covered under WLPP, If customer has IHPP proceed with IHPP Questions. If not enrolled in IHPP Claim denied for "Leak/Break on Interior Pipes After Shut-Off Valve"',
        },
        next: {
          NO: 22,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        // 22
        question: 'Was the leak caused by normal wear and tear?',
        type: 'radio-multi',
        inputArray: ['YES', 'NO'],
        answer: null,
        error: {
          NO: 'Claim denied for "Third Party Damage"',
        },
        next: {
          YES: 23,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        // 23
        question: 'Is the Leak Visible?',
        type: 'radio-multi',
        inputArray: ['YES', 'NO'],
        answer: null,
        next: {
          YES: 26,
          NO: 24,
        },
        error: {},
        updateInPayload: null,
        claimField: null,
      },
      {
        // 24
        question: 'Did you suspect that there was a leak because of a high water bill?',
        type: 'radio-multi',
        inputArray: ['YES', 'NO'],
        answer: null,
        next: {
          YES: 25,
        },
        error: {
          NO: 'Claim ineligible',
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        // 25
        question: 'Is your meter located inside or outside',
        type: 'radio-multi',
        inputArray: ['Inside', 'Outside'],
        answer: null,
        error: {
          Inside:
            'Not covered under WLPP, If customer has IHPP proceed with IHPP Questions. If not enrolled in IHPP Claim denied for "Leak/Break on Interior Pipes After Shut-Off Valve"',
        },
        next: {
          Outside: 26,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        // 26
        question: 'Please Enter Suggested Time and Date for Repair',
        type: 'dropdown',
        inputArray: ['Manually enter Date', 'Morning (8 AM- 12PM)', 'Afternoon (12 PM- 5 PM)', 'Evening ( After 5 PM)'],
        answer: null,
        next: {
          'Manually enter Date': 27,
          'Morning (8 AM- 12PM)': 28,
          'Afternoon (12 PM- 5 PM)': 28,
          'Evening ( After 5 PM)': 28,
        },
        error: {},
        updateInPayload: null,
        claimField: null,
      },
      {
        // 27
        question: 'Enter Date for Repair',
        type: 'date-input',
        placeholder: 'Enter the date in DD/MM/YY format',
        answer: null,
        error: {},
        manualDate: 'Yes',
        next: 28,
        updateInPayload: null,
        claimField: null,
      },
      {
        // 28
        question: 'Please enter name of the Claimant for the contractor to contact',
        type: 'text-input',
        placeholder: 'Enter an claimant name ',
        answer: null,
        next: 29,
        error: {},
        updateInPayload: null,
        claimField: 'contactName',
      },
      {
        // 29
        question: 'Please Enter the best number for the contractor to contact',
        type: 'tel',
        placeholder: 'Enter an contact number ',
        answer: null,
        next: null,
        error: {},
        updateInPayload: null,
        claimField: 'contactNo',
      },
    ],
  },
  IHPP: {
    // In house plumbing program.
    productId: 'd3cb9274-15d7-e411-9425-00155ddf6652',
    daysForChildClaim: 30,
    questions: [
      {
        // Question1
        question: 'Select type of issue',
        type: 'radio-multi',
        inputArray: ['Leak', 'Blockage'],
        answer: null,
        error: {},
        next: {
          Leak: 1,
          Blockage: 9,
        },
        updateInPayload: null,
        claimField: 'issueType',
      },
      {
        // Leak
        question: 'When did you first notice the issue?',
        type: 'date-input',
        placeholder: 'Enter the date in DD/MM/YY format',
        answer: null,
        error: {},
        next: 2,
        updateInPayload: null,
        claimField: 'firstNoticed',
      },
      {
        question: 'What is the Size of the Pipe?',
        type: 'radio-multi',
        inputArray: ['1 ¾ or less', '2 or more'],
        answer: null,
        error: {
          '2 or more': 'Claim cannot be established due to ineligible issue.',
        },
        next: {
          '1 ¾ or less': 3,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        question: 'Is the area leaking only when a fixture is used or is it constantly leaking?',
        type: 'dropdown',
        inputArray: ['Constantly', 'When water is draining', 'When the faucet is turned on', 'When the shower or bathtub is used'],
        answer: null,
        error: {
          'When water is draining': 'Claim Denied.',
          'When the faucet is turned on': 'Claim Denied.',
          'When the shower or bathtub is used': 'Claim Denied.',
        },
        next: {
          Constantly: 4,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        question: 'Where is the leak located?',
        type: 'text-input',
        placeholder: 'Enter text here...',
        answer: null,
        error: {},
        next: 5,
        updateInPayload: null,
        claimField: 'leakLocation',
      },
      {
        question: 'Does the leak stop when the shut off valve is turned off?',
        type: 'radio-multi',
        inputArray: ['Yes', 'No', "Don't know"],
        answer: null,
        error: {
          Yes: 'Claim Denied',
        },
        next: {
          No: 6,
          "Don't know": 6,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        question: 'Additional Notes/Conversation',
        type: 'text-area-input',
        placeholder: 'Enter text here...',
        answer: null,
        error: {},
        next: 7,
        updateInPayload: null,
        claimField: 'additionalNotes',
      },
      {
        question: 'Please enter name of the Claimant for the contractor to contact',
        type: 'text-input',
        placeholder: 'Enter an claimant name ',
        answer: null,
        next: 8,
        error: {},
        updateInPayload: null,
        claimField: 'contactName',
      },
      {
        question: 'Please Enter the best number for the contractor to contact',
        type: 'tel',
        placeholder: 'Enter an contact number ',
        answer: null,
        next: null,
        error: {},
        updateInPayload: null,
        claimField: 'contactNo',
      },
      {
        // Blockage
        question: 'When did you first notice the issue?',
        type: 'date-input',
        placeholder: 'Enter the date in DD/MM/YY format',
        answer: null,
        error: {},
        next: 10,
        updateInPayload: null,
        claimField: 'firstNoticed',
      },
      {
        question: 'Where is the sewage backing up from?',
        type: 'text-input',
        placeholder: 'Enter text here...',
        answer: null,
        error: {},
        next: 11,
        updateInPayload: null,
        claimField: 'backupLocation',
      },
      {
        question: 'Which drains are slowly draining?',
        type: 'text-input',
        placeholder: 'Enter text here...',
        answer: null,
        error: {},
        next: 12,
        updateInPayload: null,
        claimField: 'drainsLocation',
      },
      {
        question: 'On what floor is the drain/fixture located?',
        type: 'text-input',
        placeholder: 'Enter text here...',
        answer: null,
        error: {},
        next: 13,
        updateInPayload: null,
        claimField: 'fixture',
      },
      {
        question: 'Additional Notes/Conversation',
        type: 'text-area-input',
        placeholder: 'Enter text here...',
        answer: null,
        error: {},
        next: 14,
        updateInPayload: null,
        claimField: 'additionalNotes',
      },
      {
        question: 'Please enter name of the Claimant for the contractor to contact',
        type: 'text-input',
        placeholder: 'Enter an claimant name ',
        answer: null,
        next: 15,
        error: {},
        updateInPayload: null,
        claimField: 'contactName',
      },
      {
        question: 'Please Enter the best number for the contractor to contact',
        type: 'tel',
        placeholder: 'Enter an contact number ',
        answer: null,
        next: null,
        error: {},
        updateInPayload: null,
        claimField: 'contactNo',
      },
    ],
  },
  EIHP: {
    // In House Plumbing Plus
    productId: 'd2cb9274-15d7-e411-9425-00155ddf6652',
    daysForChildClaim: 30,
    questions: [
      {
        // Question1
        question: 'Select type of issue',
        type: 'dropdown',
        inputArray: ['Leak', 'Toilet', 'Faucet', 'Blockage'],
        answer: null,
        error: {},
        next: {
          Leak: 1,
          Toilet: 8,
          Faucet: 14,
          Blockage: 22,
        },
        updateInPayload: null,
        claimField: 'issueType',
      },
      {
        // Leak
        question: 'When did you first notice the issue?',
        type: 'date-input',
        placeholder: 'Enter the date in DD/MM/YY format',
        answer: null,
        error: {},
        next: 2,
        updateInPayload: null,
        claimField: 'firstNoticed',
      },
      {
        question: 'What is the Size of the Pipe?',
        type: 'radio-multi',
        inputArray: ['Larger', 'Smaller'],
        answer: null,
        error: {},
        next: {
          Larger: 3,
          Smaller: 3,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        question: 'Is the Leak Constant or only when a fixture is being used?',
        type: 'dropdown',
        inputArray: ['Constantly', 'When water is draining', 'When the faucet is turned on', 'When the shower or bathtub is used'],
        answer: null,
        error: {
          'When the shower or bathtub is used': 'Claim Denied.',
        },
        next: {
          Constantly: 4,
          'When water is draining': 4,
          'When the faucet is turned on': 4,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        question: 'Where is the leak located?',
        type: 'text-input',
        placeholder: 'Enter text here...',
        answer: null,
        error: {},
        next: 5,
        updateInPayload: null,
        claimField: 'leakLocation',
      },
      {
        question: 'Additional Notes/Conversation',
        type: 'text-area-input',
        placeholder: 'Enter text here...',
        answer: null,
        error: {},
        next: 6,
        updateInPayload: null,
        claimField: 'additionalNotes',
      },
      {
        question: 'Please enter name of the Claimant for the contractor to contact',
        type: 'text-input',
        placeholder: 'Enter an claimant name ',
        answer: null,
        next: 7,
        error: {},
        updateInPayload: null,
        claimField: 'contactName',
      },
      {
        question: 'Please Enter the best number for the contractor to contact',
        type: 'tel',
        placeholder: 'Enter an contact number ',
        answer: null,
        next: null,
        error: {},
        updateInPayload: null,
        claimField: 'contactNo',
      },
      {
        // Toilet
        question: 'When did you first notice the issue?',
        type: 'date-input',
        placeholder: 'Enter the date in DD/MM/YY format',
        answer: null,
        error: {},
        next: 9,
        updateInPayload: null,
        claimField: 'firstNoticed',
      },
      {
        question: 'What is the issue the toilet is having?',
        type: 'text-input',
        placeholder: 'Enter text here...',
        answer: null,
        error: {},
        next: 10,
        updateInPayload: null,
        claimField: 'issue',
      },
      {
        question: 'Where is the Toilet Located?',
        type: 'text-input',
        placeholder: 'Enter text here...',
        answer: null,
        error: {},
        next: 11,
        updateInPayload: null,
        claimField: 'toiletLocation',
      },
      {
        question: 'Additional Notes/Conversation',
        type: 'text-area-input',
        placeholder: 'Enter text here...',
        answer: null,
        error: {},
        next: 12,
        updateInPayload: null,
        claimField: 'additionalNotes',
      },
      {
        question: 'Please enter name of the Claimant for the contractor to contact',
        type: 'text-input',
        placeholder: 'Enter an claimant name ',
        answer: null,
        next: 13,
        error: {},
        updateInPayload: null,
        claimField: 'contactName',
      },
      {
        question: 'Please Enter the best number for the contractor to contact',
        type: 'tel',
        placeholder: 'Enter an contact number ',
        answer: null,
        next: null,
        error: {},
        updateInPayload: null,
        claimField: 'contactNo',
      },
      {
        // Faucet
        question: 'When did you first notice the issue?',
        type: 'date-input',
        placeholder: 'Enter the date in DD/MM/YY format',
        answer: null,
        error: {},
        next: 15,
        updateInPayload: null,
        claimField: 'firstNoticed',
      },
      {
        question: 'Where is the leak coming from?',
        type: 'text-input',
        placeholder: 'Enter text here...',
        answer: null,
        error: {},
        next: 16,
        updateInPayload: null,
        claimField: null,
      },
      {
        question: 'What Kind of faucet is it?',
        type: 'text-input',
        placeholder: 'Enter text here...',
        answer: null,
        error: {},
        next: 17,
        updateInPayload: null,
        claimField: 'faucetType',
      },
      {
        question: 'Is it a single-handle or Dual Faucet?',
        type: 'radio-multi',
        inputArray: ['Single Handle', 'Dual Handle'],
        answer: null,
        error: {},
        next: {
          'Single Handle': 18,
          'Dual Handle': 18,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        question: 'Where is the Faucet located?',
        type: 'text-input',
        placeholder: 'Enter text here...',
        answer: null,
        error: {},
        next: 19,
        updateInPayload: null,
        claimField: 'leakLocation',
      },
      {
        question: 'Additional Notes/Conversation',
        type: 'text-area-input',
        placeholder: 'Enter text here...',
        answer: null,
        error: {},
        next: 20,
        updateInPayload: null,
        claimField: 'additionalNotes',
      },
      {
        question: 'Please enter name of the Claimant for the contractor to contact',
        type: 'text-input',
        placeholder: 'Enter an claimant name ',
        answer: null,
        next: 21,
        error: {},
        updateInPayload: null,
        claimField: 'contactName',
      },
      {
        question: 'Please Enter the best number for the contractor to contact',
        type: 'tel',
        placeholder: 'Enter an contact number ',
        answer: null,
        next: null,
        error: {},
        updateInPayload: null,
        claimField: 'contactNo',
      },
      {
        // Blockage
        question: 'When did you first notice the issue?',
        type: 'date-input',
        placeholder: 'Enter the date in DD/MM/YY format',
        answer: null,
        error: {},
        next: 23,
        updateInPayload: null,
        claimField: 'firstNoticed',
      },
      {
        question: 'Where is the sewage backing up from?',
        type: 'text-input',
        placeholder: 'Enter text here...',
        answer: null,
        error: {},
        next: 24,
        updateInPayload: null,
        claimField: 'backupLocation',
      },
      {
        question: 'Which drains are slowly draining?',
        type: 'text-input',
        placeholder: 'Enter text here...',
        answer: null,
        error: {},
        next: 25,
        updateInPayload: null,
        claimField: 'drainsLocation',
      },
      {
        question: 'On what floor is the drain/fixture located?',
        type: 'text-input',
        placeholder: 'Enter text here...',
        answer: null,
        error: {},
        next: 26,
        updateInPayload: null,
        claimField: 'fixture',
      },
      {
        question: 'Additional Notes/Conversation',
        type: 'text-area-input',
        placeholder: 'Enter text here...',
        answer: null,
        error: {},
        next: 27,
        updateInPayload: null,
        claimField: 'additionalNotes',
      },
      {
        question: 'Please enter name of the Claimant for the contractor to contact',
        type: 'text-input',
        placeholder: 'Enter an claimant name ',
        answer: null,
        next: 28,
        error: {},
        updateInPayload: null,
        claimField: 'contactName',
      },
      {
        question: 'Please Enter the best number for the contractor to contact',
        type: 'tel',
        placeholder: 'Enter an contact number ',
        answer: null,
        next: null,
        error: {},
        updateInPayload: null,
        claimField: 'contactNo',
      },
    ],
  },
  ELPP: {
    // Interior Electric Program
    productId: 'd4cb9274-15d7-e411-9425-00155ddf6652',
    daysForChildClaim: 30,
    questions: [
      {
        // Question1
        question: 'Select type of issue',
        type: 'dropdown',
        inputArray: ['Outlets', 'Switches', 'Power'],
        answer: null,
        error: {},
        next: {
          Outlets: 1,
          Switches: 9,
          Power: 17,
        },
        updateInPayload: null,
        claimField: 'issueType',
      },
      {
        // Outlets
        question: 'When did the Issue first occur?',
        type: 'date-input',
        placeholder: 'Enter the date in DD/MM/YY format',
        answer: null,
        error: {},
        next: 2,
        updateInPayload: null,
        claimField: 'firstNoticed',
      },
      {
        question: 'Did the issue occur due to a storm?',
        type: 'radio-multi',
        inputArray: ['Yes', 'No'],
        answer: null,
        error: {
          Yes: 'Claim cannot be established due to ineligible issue.',
        },
        next: {
          No: 3,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        question: 'Where is the issue located?',
        type: 'text-input',
        placeholder: 'Enter text here...',
        answer: null,
        error: {},
        next: 4,
        updateInPayload: null,
        claimField: 'outletLocation',
      },
      {
        question: 'Is the issue isolated to just one outlet or multiple outlets?',
        type: 'radio-multi',
        inputArray: ['Single', 'Multiple'],
        answer: null,
        error: {},
        next: {
          Single: 5,
          Multiple: 5,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        question: 'Is this a GFI outlet?',
        type: 'radio-multi',
        inputArray: ['Yes', 'No'],
        answer: null,
        error: {},
        next: {
          Yes: 6,
          No: 6,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        question: 'Additional Notes/Conversation',
        type: 'text-area-input',
        placeholder: 'Enter text here...',
        answer: null,
        error: {},
        next: 7,
        updateInPayload: null,
        claimField: 'additionalNotes',
      },
      {
        question: 'Please enter name of the Claimant for the contractor to contact',
        type: 'text-input',
        placeholder: 'Enter an claimant name ',
        answer: null,
        next: 8,
        error: {},
        updateInPayload: null,
        claimField: 'contactName',
      },
      {
        question: 'Please Enter the best number for the contractor to contact',
        type: 'tel',
        placeholder: 'Enter an contact number ',
        answer: null,
        next: null,
        error: {},
        updateInPayload: null,
        claimField: 'contactNo',
      },
      {
        // Switches
        question: 'When did you first notice the issue?',
        type: 'date-input',
        placeholder: 'Enter the date in DD/MM/YY format',
        answer: null,
        error: {},
        next: 10,
        updateInPayload: null,
        claimField: 'firstNoticed',
      },
      {
        question: 'Did the issue occur due to a storm?',
        type: 'radio-multi',
        inputArray: ['Yes', 'No'],
        answer: null,
        error: {
          Yes: 'Claim cannot be established due to ineligible issue.',
        },
        next: {
          No: 11,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        question: 'Where is the issue located?',
        type: 'text-input',
        placeholder: 'Enter text here...',
        answer: null,
        error: {},
        next: 12,
        updateInPayload: null,
        claimField: 'switchLocation',
      },
      {
        question: 'Is the issue isolated to just one light/switch?',
        type: 'radio-multi',
        inputArray: ['Light', 'Switch'],
        answer: null,
        error: {},
        next: {
          Light: 13,
          Switch: 13,
        },
        updateInPayload: null,
        claimField: 'issue',
      },
      {
        question: 'Have you changed the bulb to the light fixture?',
        type: 'radio-multi',
        inputArray: ['Yes', 'No'],
        answer: null,
        error: {},
        next: {
          Yes: 14,
          No: 14,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        question: 'Additional Notes/Conversation',
        type: 'text-area-input',
        placeholder: 'Enter text here...',
        answer: null,
        error: {},
        next: 15,
        updateInPayload: null,
        claimField: 'additionalNotes',
      },
      {
        question: 'Please enter name of the Claimant for the contractor to contact',
        type: 'text-input',
        placeholder: 'Enter an claimant name ',
        answer: null,
        next: 16,
        error: {},
        updateInPayload: null,
        claimField: 'contactName',
      },
      {
        question: 'Please Enter the best number for the contractor to contact',
        type: 'tel',
        placeholder: 'Enter an contact number ',
        answer: null,
        next: null,
        error: {},
        updateInPayload: null,
        claimField: 'contactNo',
      },
      {
        // Power
        question: 'When did the Issue first occur?',
        type: 'date-input',
        placeholder: 'Enter the date in DD/MM/YY format',
        answer: null,
        error: {},
        next: 18,
        updateInPayload: null,
        claimField: 'firstNoticed',
      },
      {
        question: 'Did the issue occur due to a storm?',
        type: 'radio-multi',
        inputArray: ['Yes', 'No'],
        answer: null,
        error: {
          Yes: 'Claim cannot be established due to ineligible issue.',
        },
        next: {
          No: 19,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        question: 'Where is the issue located?',
        type: 'text-input',
        placeholder: 'Enter text here...',
        answer: null,
        error: {},
        next: 20,
        updateInPayload: null,
        claimField: 'issueLocation',
      },
      {
        question: 'Do your neighbors have power?',
        type: 'radio-multi',
        inputArray: ['Yes', 'No', "Don't know"],
        answer: null,
        error: {
          No: 'Claim Denied.',
        },
        next: {
          Yes: 21,
          "Don't know": 21,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        question: 'Have you tried resetting your circuit breaker/fuse box?',
        type: 'radio-multi',
        inputArray: ['Yes', 'No'],
        answer: null,
        error: {},
        next: {
          Yes: 22,
          No: 22,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        question: 'Do the lights strangely dim when plugging in appliances?',
        type: 'radio-multi',
        inputArray: ['Yes', 'No'],
        answer: null,
        error: {},
        next: {
          Yes: 23,
          No: 23,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        question: 'Please enter name of the Claimant for the contractor to contact',
        type: 'text-input',
        placeholder: 'Enter an claimant name ',
        answer: null,
        next: 24,
        error: {},
        updateInPayload: null,
        claimField: 'contactName',
      },
      {
        question: 'Please Enter the best number for the contractor to contact',
        type: 'tel',
        placeholder: 'Enter an contact number ',
        answer: null,
        next: null,
        error: {},
        updateInPayload: null,
        claimField: 'contactNo',
      },
    ],
  },
  SRG: {
    // Surge Protection program (Power Surge)
    productId: 'd8cb9274-15d7-e411-9425-00155ddf6652',
    daysForChildClaim: 0,
    questions: [
      {
        question: 'When did the power surge occur?',
        type: 'date-input',
        placeholder: 'Enter the date in DD/MM/YY format',
        answer: null,
        error: {},
        next: 1,
        updateInPayload: null,
        claimField: 'firstNoticed',
      },
      {
        question: 'What caused the surge event?',
        type: 'text-input',
        placeholder: 'Enter cause of surge ',
        answer: null,
        error: {},
        next: 2,
        updateInPayload: null,
        claimField: null,
      },
      {
        question: 'Are the lights located on the surge device illuminated?',
        type: 'radio-multi',
        inputArray: ['Yes', 'No'],
        answer: null,
        error: {
          Yes: 'Claim Denied.',
        },
        next: {
          No: 3,
        },
        updateInPayload: null,
        claimField: 'lightsOff',
      },
      {
        question: 'What kind of appliance/equipment was damaged?',
        type: 'checkbox',
        inputArray: [
          {
            title: 'Medical/Life Support',
            checked: false,
          },
          {
            title: 'Home theater systems',
            checked: false,
          },
          {
            title: 'Pumps',
            checked: false,
          },
          {
            title: 'Sprinkler systems',
            checked: false,
          },
          {
            title: 'Electrical fences',
            checked: false,
          },
          {
            title: 'Kitchen appliances',
            checked: false,
          },
          {
            title: 'Basic home electronics',
            checked: false,
          },
          {
            title: 'HVAC system',
            checked: false,
          },
          {
            title: 'Tools',
            checked: false,
          },
          {
            title: 'Garage door opener',
            checked: false,
          },
          {
            title: 'Compressor and air tools',
            checked: false,
          },
          {
            title: 'Other',
            checked: false,
          },
        ],
        labelArray: [
          'Medical/Life Support',
          'Home theater systems (i.e., movie projection equipment & surround sound systems hardwired in to the home)',
          'Pumps (well pump, pool pump)',
          'Sprinkler systems',
          'Electrical fences',
          'Kitchen appliances',
          'Basic home electronics (tv, radio, stereo, DVD player, game console, computer, printer, etc.',
          'HVAC system (central system or single-room unit)',
          'Tools (standard bench power tools, portable power tools and chargers)',
          'Garage door opener',
          'Compressor and air tools',
          'Other',
        ],
        answer: [],
        error: ['Medical/Life Support', 'Home theater systems', 'Pumps', 'Sprinkler systems', 'Electrical fences'],
        next: {
          'Kitchen appliances': 4,
          'Basic home electronics': 4,
          'HVAC system': 4,
          Tools: 4,
          'Garage door opener': 4,
          'Compressor and air tools': 4,
          Other: 4,
        },
        updateInPayload: null,
        claimField: 'damagedAppliances',
      },
      {
        question: 'Is the appliance/equipment located inside your home?',
        type: 'radio-multi',
        inputArray: ['Yes', 'No'],
        answer: null,
        error: {
          No: 'Claim Denied.',
        },
        next: {
          Yes: 5,
        },
        updateInPayload: null,
        claimField: 'issueSystem',
      },
      {
        question: 'How old is the appliance/equipment that was damaged?',
        type: 'dropdown',
        inputArray: ['< 1 year', '1 year - 5 years old', '> 5 years old'],
        answer: null,
        error: {},
        next: {
          '< 1 year': 6,
          '1 year - 5 years old': 6,
          '> 5 years old': 6,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        question: 'Was the appliance/equipment plugged in?',
        type: 'radio-multi',
        inputArray: ['Yes', 'No'],
        answer: null,
        error: {
          No: 'Claim Denied.',
        },
        next: {
          Yes: 7,
        },
        updateInPayload: null,
        claimField: 'unit',
      },
      {
        question: 'Additional Notes/Conversation',
        type: 'text-area-input',
        placeholder: 'Enter text here...',
        answer: null,
        error: {},
        next: 8,
        updateInPayload: null,
        claimField: 'additionalNotes',
      },
      {
        question: 'Please enter name of the Claimant for the contractor to contact',
        type: 'text-input',
        placeholder: 'Enter an claimant name ',
        answer: null,
        next: 9,
        error: {},
        updateInPayload: null,
        claimField: 'contactName',
      },
      {
        question: 'Please Enter the best number for the contractor to contact',
        type: 'tel',
        placeholder: 'Enter an contact number ',
        answer: null,
        next: null,
        error: {},
        updateInPayload: null,
        claimField: 'contactNo',
      },
    ],
  },
  HWHP: {
    // Water Heat repair and replacement program
    productId: '660ec33b-199d-e611-9417-00155d8ff122',
    daysForChildClaim: 30,
    questions: [
      {
        // Question1
        question: 'Select type of issue',
        type: 'dropdown',
        inputArray: ['No hot Water', 'Leak'],
        answer: null,
        error: {},
        next: {
          'No hot Water': 1,
          Leak: 10,
        },
        updateInPayload: null,
        claimField: 'issueType',
      },
      {
        // No hot water
        question: 'When did the Issue first occur?',
        type: 'date-input',
        placeholder: 'Enter the date in DD/MM/YY format',
        answer: null,
        error: {},
        next: 2,
        updateInPayload: null,
        claimField: 'firstNoticed',
      },
      {
        question: 'Do you have more than one water heater in your home?',
        type: 'radio-multi',
        inputArray: ['Yes', 'No'],
        answer: null,
        error: {},
        next: {
          Yes: 3,
          No: 3,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        question: 'Is your water heater Electric or Gas/Propane?',
        type: 'radio-multi',
        inputArray: ['Electric', 'Gas', 'Propane'],
        answer: null,
        error: {},
        next: {
          Electric: 4,
          Gas: 4,
          Propane: 4,
        },
        updateInPayload: null,
        claimField: 'heaterType',
      },
      {
        question: 'Is it isolated to one area/fixture of the home or the entire home?',
        type: 'radio-multi',
        inputArray: ['Fixture', 'Entire Home'],
        answer: null,
        error: {},
        next: {
          Fixture: 5,
          'Entire Home': 5,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        question: 'Where is your water heater located?',
        type: 'text-input',
        placeholder: 'Enter text here...',
        answer: null,
        error: {},
        next: 6,
        updateInPayload: null,
        claimField: 'issueLocation',
      },
      {
        question: 'Additional Notes/Conversation',
        type: 'text-area-input',
        placeholder: 'Enter text here...',
        answer: null,
        error: {},
        next: 7,
        updateInPayload: null,
        claimField: 'additionalNotes',
      },
      {
        question: 'Please enter name of the Claimant for the contractor to contact',
        type: 'text-input',
        placeholder: 'Enter an claimant name ',
        answer: null,
        next: 8,
        error: {},
        updateInPayload: null,
        claimField: 'contactName',
      },
      {
        question: 'Please Enter the best number for the contractor to contact',
        type: 'tel',
        placeholder: 'Enter an contact number ',
        answer: null,
        next: null,
        error: {},
        updateInPayload: null,
        claimField: 'contactNo',
      },
      {
        // Leak
        question: 'When did the Issue first occur?',
        type: 'date-input',
        placeholder: 'Enter the date in DD/MM/YY format',
        answer: null,
        error: {},
        next: 11,
        updateInPayload: null,
        claimField: 'firstNoticed',
      },
      {
        question: 'Do you have more than one water heater in your home?',
        type: 'radio-multi',
        inputArray: ['Yes', 'No'],
        answer: null,
        error: {},
        next: {
          Yes: 12,
          No: 12,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        question: 'Is your water heater Electric/Gas/Propane?',
        type: 'radio-multi',
        inputArray: ['Electric', 'Gas', 'Propane'],
        answer: null,
        error: {},
        next: {
          Electric: 13,
          Gas: 13,
          Propane: 13,
        },
        updateInPayload: null,
        claimField: 'heaterType',
      },
      {
        question: 'Where is your water heater located?',
        type: 'text-input',
        placeholder: 'Enter text here...',
        answer: null,
        error: {},
        next: 14,
        updateInPayload: null,
        claimField: 'issueLocation',
      },
      {
        question: 'Where is the leak?',
        type: 'radio-multi',
        inputArray: ['Top of the tank', 'Bottom of the Tank', 'Supply Line'],
        answer: null,
        error: {},
        next: {
          'Top of the tank': 15,
          'Bottom of the Tank': 15,
          'Supply Line': 15,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        question: 'Additional Notes/Conversation',
        type: 'text-area-input',
        placeholder: 'Enter text here...',
        answer: null,
        error: {},
        next: 16,
        updateInPayload: null,
        claimField: 'additionalNotes',
      },
      {
        question: 'Please enter name of the Claimant for the contractor to contact',
        type: 'text-input',
        placeholder: 'Enter an claimant name ',
        answer: null,
        next: 17,
        error: {},
        updateInPayload: null,
        claimField: 'contactName',
      },
      {
        question: 'Please Enter the best number for the contractor to contact',
        type: 'tel',
        placeholder: 'Enter an contact number ',
        answer: null,
        next: null,
        error: {},
        updateInPayload: null,
        claimField: 'contactNo',
      },
    ],
  },
  WELL: {
    // Well line Protection Program
    productId: 'dacb9274-15d7-e411-9425-00155ddf6652',
    daysForChildClaim: 60,
    questions: [
      {
        question: 'When did the Issue first occur?',
        type: 'date-input',
        placeholder: 'Enter the date in DD/MM/YY format',
        answer: null,
        error: {},
        next: 1,
        updateInPayload: null,
        claimField: 'firstNoticed',
      },
      {
        question: 'Is there Water to the Home?',
        type: 'radio-multi',
        inputArray: ['Low pressure', 'No water'],
        answer: null,
        error: {},
        next: {
          'Low pressure': 2,
          'No water': 2,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        question: 'Is the leak Visible?',
        type: 'radio-multi',
        inputArray: ['Yes', 'No'],
        answer: null,
        error: {},
        next: {
          Yes: 3,
          No: 3,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        question: 'Where is the Leak? (If visible)',
        type: 'text-input',
        placeholder: 'Enter text here...',
        answer: null,
        error: {},
        next: 4,
        updateInPayload: null,
        claimField: 'leakLocation',
      },
      {
        question: 'Is there power to the well?',
        type: 'radio-multi',
        inputArray: ['Yes', 'No'],
        answer: null,
        error: {},
        next: {
          Yes: 5,
          No: 5,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        question: 'Additional Notes/Conversation',
        type: 'text-area-input',
        placeholder: 'Enter text here...',
        answer: null,
        error: {},
        next: 6,
        updateInPayload: null,
        claimField: 'additionalNotes',
      },
      {
        question: 'Please enter name of the Claimant for the contractor to contact',
        type: 'text-input',
        placeholder: 'Enter an claimant name ',
        answer: null,
        next: 7,
        error: {},
        updateInPayload: null,
        claimField: 'contactName',
      },
      {
        question: 'Please Enter the best number for the contractor to contact',
        type: 'tel',
        placeholder: 'Enter an contact number ',
        answer: null,
        next: null,
        error: {},
        updateInPayload: null,
        claimField: 'contactNo',
      },
    ],
  },
  EELP: {
    // Exterior Electric Probing Questions
    productId: '998d1eba-78f8-e611-941d-00155dbb1e24',
    daysForChildClaim: 30,
    questions: [
      {
        // Question1
        question: 'Select type of issue',
        type: 'dropdown',
        inputArray: ['Outlets', 'Switches', 'Power', 'Outside lighting'],
        answer: null,
        error: {},
        next: {
          Outlets: 1,
          Switches: 10,
          Power: 19,
          'Outside lighting': 26,
        },
        updateInPayload: null,
        claimField: 'issueType',
      },
      {
        // Outlet
        question: 'When did the Issue first occur?',
        type: 'date-input',
        placeholder: 'Enter the date in DD/MM/YY format',
        answer: null,
        error: {},
        next: 2,
        updateInPayload: null,
        claimField: 'firstNoticed',
      },
      {
        question: 'Did the issue occur due to a storm?',
        type: 'radio-multi',
        inputArray: ['Yes', 'No'],
        answer: null,
        error: {
          Yes: 'Claim Denied.',
        },
        next: {
          No: 3,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        question: 'Where is the issue located?',
        type: 'text-input',
        placeholder: 'Enter text here...',
        answer: null,
        error: {},
        next: 4,
        updateInPayload: null,
        claimField: 'outletLocation',
      },
      {
        question: 'Is the issue isolated to just one outlet or multiple outlets?',
        type: 'radio-multi',
        inputArray: ['Single', 'Multiple'],
        answer: null,
        error: {},
        next: {
          Single: 5,
          Multiple: 5,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        question: 'Is the outlet attached or detached from the home?',
        type: 'radio-multi',
        inputArray: ['Attached', 'Detached'],
        answer: null,
        error: {},
        next: {
          Attached: 6,
          Detached: 6,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        question: 'Additional Notes/Conversation',
        type: 'text-area-input',
        placeholder: 'Enter text here...',
        answer: null,
        error: {},
        next: 7,
        updateInPayload: null,
        claimField: 'additionalNotes',
      },
      {
        question: 'Please enter name of the Claimant for the contractor to contact',
        type: 'text-input',
        placeholder: 'Enter an claimant name ',
        answer: null,
        next: 8,
        error: {},
        updateInPayload: null,
        claimField: 'contactName',
      },
      {
        question: 'Please Enter the best number for the contractor to contact',
        type: 'tel',
        placeholder: 'Enter an contact number ',
        answer: null,
        next: null,
        error: {},
        updateInPayload: null,
        claimField: 'contactNo',
      },
      {
        // Switch
        question: 'When did the Issue first occur?',
        type: 'date-input',
        placeholder: 'Enter the date in DD/MM/YY format',
        answer: null,
        error: {},
        next: 11,
        updateInPayload: null,
        claimField: 'firstNoticed',
      },
      {
        question: 'Did the issue occur due to a storm?',
        type: 'radio-multi',
        inputArray: ['Yes', 'No'],
        answer: null,
        error: {
          Yes: 'Claim Denied.',
        },
        next: {
          No: 12,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        question: 'Is the Switch attached or detached from the home?',
        type: 'radio-multi',
        inputArray: ['Attached', 'Detached'],
        answer: null,
        error: {},
        next: {
          Attached: 13,
          Detached: 13,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        question: 'Where is the issue located?',
        type: 'text-input',
        placeholder: 'Enter text here...',
        answer: null,
        error: {},
        next: 14,
        updateInPayload: null,
        claimField: 'switchLocation',
      },
      {
        question: 'Is the issue isolated to just one light/switch?',
        type: 'radio-multi',
        inputArray: ['Light', 'Switch'],
        answer: null,
        error: {},
        next: {
          Light: 15,
          Switch: 15,
        },
        updateInPayload: null,
        claimField: 'issue',
      },
      {
        question: 'Have you changed the bulb to the light fixture?',
        type: 'radio-multi',
        inputArray: ['Yes', 'No'],
        answer: null,
        error: {},
        next: {
          Yes: 16,
          No: 16,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        question: 'Additional Notes/Conversation',
        type: 'text-area-input',
        placeholder: 'Enter text here...',
        answer: null,
        error: {},
        next: 17,
        updateInPayload: null,
        claimField: 'additionalNotes',
      },
      {
        question: 'Please enter name of the Claimant for the contractor to contact',
        type: 'text-input',
        placeholder: 'Enter an claimant name ',
        answer: null,
        next: 18,
        error: {},
        updateInPayload: null,
        claimField: 'contactName',
      },
      {
        question: 'Please Enter the best number for the contractor to contact',
        type: 'tel',
        placeholder: 'Enter an contact number ',
        answer: null,
        next: null,
        error: {},
        updateInPayload: null,
        claimField: 'contactNo',
      },
      {
        // Power
        question: 'When did the Issue first occur?',
        type: 'date-input',
        placeholder: 'Enter the date in DD/MM/YY format',
        answer: null,
        error: {},
        next: 20,
        updateInPayload: null,
        claimField: 'firstNoticed',
      },
      {
        question: 'Did the issue occur due to a storm?',
        type: 'radio-multi',
        inputArray: ['Yes', 'No'],
        answer: null,
        error: {
          Yes: 'Claim Denied.',
        },
        next: {
          No: 21,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        question: 'Where is the issue located?',
        type: 'text-input',
        placeholder: 'Enter text here...',
        answer: null,
        error: {},
        next: 22,
        updateInPayload: null,
        claimField: 'issueLocation',
      },
      {
        question: 'Do your neighbors have power?',
        type: 'radio-multi',
        inputArray: ['Yes', 'No', "Don't know"],
        answer: null,
        error: {
          No: 'Claim Denied.',
        },
        next: {
          Yes: 23,
          "Don't know": 23,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        question: 'Have you tried resetting your circuit breaker/fuse box',
        type: 'radio-multi',
        inputArray: ['Yes', 'No'],
        answer: null,
        error: {},
        next: {
          Yes: 24,
          No: 24,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        question: 'Please enter name of the Claimant for the contractor to contact',
        type: 'text-input',
        placeholder: 'Enter an claimant name ',
        answer: null,
        next: 25,
        error: {},
        updateInPayload: null,
        claimField: 'contactName',
      },
      {
        question: 'Please Enter the best number for the contractor to contact',
        type: 'tel',
        placeholder: 'Enter an contact number ',
        answer: null,
        next: null,
        error: {},
        updateInPayload: null,
        claimField: 'contactNo',
      },
      {
        // Outside lighting
        question: 'When did the Issue first occur?',
        type: 'date-input',
        placeholder: 'Enter the date in DD/MM/YY format',
        answer: null,
        error: {},
        next: 27,
        updateInPayload: null,
        claimField: 'firstNoticed',
      },
      {
        question: 'Did the issue occur due to a storm?',
        type: 'radio-multi',
        inputArray: ['Yes', 'No'],
        answer: null,
        error: {
          Yes: 'Claim Denied.',
        },
        next: {
          No: 28,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        question: 'Where is the issue located?',
        type: 'text-input',
        placeholder: 'Enter text here...',
        answer: null,
        error: {},
        next: 29,
        updateInPayload: null,
        claimField: 'issueLocation',
      },
      {
        question: 'Have you changed the bulb or fuse?',
        type: 'radio-multi',
        inputArray: ['Yes', 'No'],
        answer: null,
        error: {},
        next: {
          Yes: 30,
          No: 30,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        question: 'Are the lights hardwired?',
        type: 'radio-multi',
        inputArray: ['Yes', 'No', "Don't know"],
        answer: null,
        error: {
          No: 'Claim Denied.',
        },
        next: {
          Yes: 31,
          "Don't know": 31,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        question: 'Are the lights detached from the home?',
        type: 'radio-multi',
        inputArray: ['Yes', 'No'],
        answer: null,
        error: {},
        next: {
          Yes: 32,
          No: 32,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        question: 'Additional Notes/Conversation',
        type: 'text-area-input',
        placeholder: 'Enter text here...',
        answer: null,
        error: {},
        next: 33,
        updateInPayload: null,
        claimField: 'additionalNotes',
      },
      {
        question: 'Please enter name of the Claimant for the contractor to contact',
        type: 'text-input',
        placeholder: 'Enter an claimant name ',
        answer: null,
        next: 34,
        error: {},
        updateInPayload: null,
        claimField: 'contactName',
      },
      {
        question: 'Please Enter the best number for the contractor to contact',
        type: 'tel',
        placeholder: 'Enter an contact number ',
        answer: null,
        next: null,
        error: {},
        updateInPayload: null,
        claimField: 'contactNo',
      },
    ],
  },
  GLPP: {
    // Gas Line Protection Program
    productId: 'd5cb9274-15d7-e411-9425-00155ddf6652',
    daysForChildClaim: 30,
    questions: [
      {
        question: 'When did the Issue first occur?',
        type: 'date-input',
        placeholder: 'Enter the date in DD/MM/YY format',
        answer: null,
        error: {},
        next: 1,
        updateInPayload: null,
        claimField: 'firstNoticed',
      },
      {
        question: 'Has the Gas company verified a gas leak on your side of the meter?',
        type: 'radio-multi',
        inputArray: ['Yes', 'No'],
        answer: null,
        error: {
          No: 'Please safely exit the premises and call your gas company or 911.',
        },
        next: {
          Yes: 2,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        question: 'Has the Gas Company identified where the leak is located?',
        type: 'radio-multi',
        inputArray: ['Yes', 'No'],
        answer: null,
        error: {},
        next: {
          Yes: 3,
          No: 3,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        question: 'Where do you suspect the leak coming from?',
        type: 'text-input',
        placeholder: 'Enter location where leak is located ',
        answer: null,
        next: 4,
        error: {},
        updateInPayload: null,
        claimField: 'leakLocation',
      },
      {
        question: 'Did the Gas Company shut off the Gas?',
        type: 'radio-multi',
        inputArray: ['Yes', 'No'],
        answer: null,
        error: {},
        next: {
          Yes: 5,
          No: 5,
        },
        updateInPayload: null,
        claimField: 'gasOff',
      },
      {
        question: 'Please enter name of the Claimant for the contractor to contact',
        type: 'text-input',
        placeholder: 'Enter an claimant name ',
        answer: null,
        next: 6,
        error: {},
        updateInPayload: null,
        claimField: 'contactName',
      },
      {
        question: 'Please Enter the best number for the contractor to contact',
        type: 'tel',
        placeholder: 'Enter an contact number ',
        answer: null,
        next: null,
        error: {},
        updateInPayload: null,
        claimField: 'contactNo',
      },
    ],
  },
  CSRP: {
    // Cooling System Protection
    productId: 'd0cb9274-15d7-e411-9425-00155ddf6652',
    daysForChildClaim: 30,
    questions: [
      {
        question: 'When did the Issue first occur?',
        type: 'date-input',
        placeholder: 'Enter the date in DD/MM/YY format',
        answer: null,
        error: {},
        next: 1,
        updateInPayload: null,
        claimField: 'firstNoticed',
      },
      {
        question: 'Do you have more than one system in your home?',
        type: 'radio-multi',
        inputArray: ['One', 'More'],
        answer: null,
        error: {},
        next: {
          One: 2,
          More: 2,
        },
        updateInPayload: null,
        claimField: 'noOfSystems',
      },
      {
        question: 'Enter the temperature of the air blowing through the register?',
        type: 'text-input',
        placeholder: 'Enter temperature value ',
        answer: null,
        error: {},
        next: 3,
        updateInPayload: null,
        claimField: null,
      },
      {
        question: 'Is your thermostat set?',
        type: 'radio-multi',
        inputArray: ['On', 'Off', 'Auto'],
        answer: null,
        error: {},
        next: {
          On: 4,
          Off: 4,
          Auto: 4,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        question: 'Your thermostat is on?',
        type: 'radio-multi',
        inputArray: ['Heat', 'Cool'],
        answer: null,
        error: {},
        next: {
          Heat: 5,
          Cool: 5,
        },
        updateInPayload: null,
        claimField: 'thermostat',
      },
      {
        question: 'Is there air blowing?',
        type: 'radio-multi',
        inputArray: ['Yes', 'No'],
        answer: null,
        error: {},
        next: {
          Yes: 6,
          No: 6,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        question: 'Where is the system located?',
        type: 'text-input',
        placeholder: 'Enter the location ',
        answer: null,
        next: 7,
        error: {},
        updateInPayload: null,
        claimField: 'issueLocation',
      },
      {
        question: 'Additional Notes/Conversation',
        type: 'text-area-input',
        placeholder: 'Enter text here...',
        answer: null,
        error: {},
        next: 8,
        updateInPayload: null,
        claimField: 'additionalNotes',
      },
      {
        question: 'Please enter name of the Claimant for the contractor to contact',
        type: 'text-input',
        placeholder: 'Enter an claimant name ',
        answer: null,
        next: 9,
        error: {},
        updateInPayload: null,
        claimField: 'contactName',
      },
      {
        question: 'Please Enter the best number for the contractor to contact',
        type: 'tel',
        placeholder: 'Enter an contact number ',
        answer: null,
        next: null,
        error: {},
        updateInPayload: null,
        claimField: 'contactNo',
      },
    ],
  },
  HSRP: {
    // Heating System Protection
    productId: 'd1cb9274-15d7-e411-9425-00155ddf6652',
    daysForChildClaim: 30,
    questions: [
      {
        question: 'When did the Issue first occur?',
        type: 'date-input',
        placeholder: 'Enter the date in DD/MM/YY format',
        answer: null,
        error: {},
        next: 1,
        updateInPayload: null,
        claimField: 'firstNoticed',
      },
      {
        question: 'Do you have more than one system in your home?',
        type: 'radio-multi',
        inputArray: ['One', 'More'],
        answer: null,
        error: {},
        next: {
          One: 2,
          More: 2,
        },
        updateInPayload: null,
        claimField: 'noOfSystems',
      },
      {
        question: 'Enter the temperature of the air blowing through the register?',
        type: 'text-input',
        placeholder: 'Enter temperature value ',
        answer: null,
        error: {},
        next: 3,
        updateInPayload: null,
        claimField: null,
      },
      {
        question: 'Is your thermostat set?',
        type: 'radio-multi',
        inputArray: ['On', 'Off', 'Auto'],
        answer: null,
        error: {},
        next: {
          On: 4,
          Off: 4,
          Auto: 4,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        question: 'Is there air blowing?',
        type: 'radio-multi',
        inputArray: ['Yes', 'No'],
        answer: null,
        error: {},
        next: {
          Yes: 5,
          No: 5,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        question: 'Is the system electric or gas or other?',
        type: 'dropdown',
        inputArray: ['Electric', 'Gas', 'Boiler/Hot water', 'Radiant heat', 'Geothermal', 'Heat pump'],
        answer: null,
        error: {
          'Boiler/Hot water': 'Claim cannot be established due to ineligible issue.',
          'Radiant heat': 'Claim cannot be established due to ineligible issue.',
          Geothermal: 'Claim cannot be established due to ineligible issue.',
          'Heat pump': 'Claim cannot be established due to ineligible issue.',
        },
        next: {
          Electric: 6,
          Gas: 6,
        },
        updateInPayload: null,
        claimField: 'unit',
      },
      {
        question: 'Where is the system located?',
        type: 'text-input',
        placeholder: 'Enter the location ',
        answer: null,
        next: 7,
        error: {},
        updateInPayload: null,
        claimField: 'issueLocation',
      },
      {
        question: 'Additional Notes/Conversation',
        type: 'text-area-input',
        placeholder: 'Enter text here...',
        answer: null,
        error: {},
        next: 8,
        updateInPayload: null,
        claimField: 'additionalNotes',
      },
      {
        question: 'Please enter name of the Claimant for the contractor to contact',
        type: 'text-input',
        placeholder: 'Enter an claimant name ',
        answer: null,
        next: 9,
        error: {},
        updateInPayload: null,
        claimField: 'contactName',
      },
      {
        question: 'Please Enter the best number for the contractor to contact',
        type: 'tel',
        placeholder: 'Enter an contact number ',
        answer: null,
        next: null,
        error: {},
        updateInPayload: null,
        claimField: 'contactNo',
      },
    ],
  },
  IHCP: {
    // In house plumbing program.
    productId: 'd8fe151c-02f7-ea11-9474-00155d104f66',
    questions: [
      {
        // Question1
        question: 'Select type of issue',
        type: 'radio-multi',
        inputArray: ['Leak', 'Blockage'],
        answer: null,
        error: {},
        next: {
          Leak: 1,
          Blockage: 9,
        },
        updateInPayload: null,
        claimField: 'issueType',
      },
      {
        // Leak
        question: 'When did you first notice the issue?',
        type: 'date-input',
        placeholder: 'Enter the date in DD/MM/YY format',
        answer: null,
        error: {},
        next: 2,
        updateInPayload: null,
        claimField: 'firstNoticed',
      },
      {
        question: 'What is the Size of the Pipe?',
        type: 'radio-multi',
        inputArray: ['1 ¾ or less', '2 or more'],
        answer: null,
        error: {
          '2 or more': 'Claim cannot be established due to ineligible issue.',
        },
        next: {
          '1 ¾ or less': 3,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        question: 'Is the area leaking only when a fixture is used or is it constantly leaking?',
        type: 'dropdown',
        inputArray: ['Constantly', 'When water is draining', 'When the faucet is turned on', 'When the shower or bathtub is used'],
        answer: null,
        error: {
          'When water is draining': 'Claim Denied.',
          'When the faucet is turned on': 'Claim Denied.',
          'When the shower or bathtub is used': 'Claim Denied.',
        },
        next: {
          Constantly: 4,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        question: 'Where is the leak located?',
        type: 'text-input',
        placeholder: 'Enter text here...',
        answer: null,
        error: {},
        next: 5,
        updateInPayload: null,
        claimField: 'leakLocation',
      },
      {
        question: 'Does the leak stop when the shut off valve is turned off?',
        type: 'radio-multi',
        inputArray: ['Yes', 'No', "Don't know"],
        answer: null,
        error: {
          Yes: 'Claim Denied',
        },
        next: {
          No: 6,
          "Don't know": 6,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        question: 'Additional Notes/Conversation',
        type: 'text-area-input',
        placeholder: 'Enter text here...',
        answer: null,
        error: {},
        next: 7,
        updateInPayload: null,
        claimField: 'additionalNotes',
      },
      {
        question: 'Please enter name of the Claimant for the contractor to contact',
        type: 'text-input',
        placeholder: 'Enter an claimant name ',
        answer: null,
        next: 8,
        error: {},
        updateInPayload: null,
        claimField: 'contactName',
      },
      {
        question: 'Please Enter the best number for the contractor to contact',
        type: 'tel',
        placeholder: 'Enter an contact number ',
        answer: null,
        next: null,
        error: {},
        updateInPayload: null,
        claimField: 'contactNo',
      },
      {
        // Blockage
        question: 'When did you first notice the issue?',
        type: 'date-input',
        placeholder: 'Enter the date in DD/MM/YY format',
        answer: null,
        error: {},
        next: 10,
        updateInPayload: null,
        claimField: 'firstNoticed',
      },
      {
        question: 'Where is the sewage backing up from?',
        type: 'text-input',
        placeholder: 'Enter text here...',
        answer: null,
        error: {},
        next: 11,
        updateInPayload: null,
        claimField: 'backupLocation',
      },
      {
        question: 'Which drains are slowly draining?',
        type: 'text-input',
        placeholder: 'Enter text here...',
        answer: null,
        error: {},
        next: 12,
        updateInPayload: null,
        claimField: 'drainsLocation',
      },
      {
        question: 'On what floor is the drain/fixture located?',
        type: 'text-input',
        placeholder: 'Enter text here...',
        answer: null,
        error: {},
        next: 13,
        updateInPayload: null,
        claimField: 'fixture',
      },
      {
        question: 'Additional Notes/Conversation',
        type: 'text-area-input',
        placeholder: 'Enter text here...',
        answer: null,
        error: {},
        next: 14,
        updateInPayload: null,
        claimField: 'additionalNotes',
      },
      {
        question: 'Please enter name of the Claimant for the contractor to contact',
        type: 'text-input',
        placeholder: 'Enter an claimant name ',
        answer: null,
        next: 15,
        error: {},
        updateInPayload: null,
        claimField: 'contactName',
      },
      {
        question: 'Please Enter the best number for the contractor to contact',
        type: 'tel',
        placeholder: 'Enter an contact number ',
        answer: null,
        next: null,
        error: {},
        updateInPayload: null,
        claimField: 'contactNo',
      },
    ],
  },
  CRSS: {
    // Cooling Repair - Secondary System
    productId: 'cdacd5a6-cfdb-ec11-948c-00155dbb1e5f',
    daysForChildClaim: 30,
    questions: [
      {
        question: 'When did the Issue first occur?',
        type: 'date-input',
        placeholder: 'Enter the date in DD/MM/YY format',
        answer: null,
        error: {},
        next: 1,
        updateInPayload: null,
        claimField: 'firstNoticed',
      },
      {
        question: 'Do you have more than one system in your home?',
        type: 'radio-multi',
        inputArray: ['One', 'More'],
        answer: null,
        error: {},
        next: {
          One: 2,
          More: 2,
        },
        updateInPayload: null,
        claimField: 'noOfSystems',
      },
      {
        question: 'Enter the temperature of the air blowing through the register?',
        type: 'text-input',
        placeholder: 'Enter temperature value ',
        answer: null,
        error: {},
        next: 3,
        updateInPayload: null,
        claimField: null,
      },
      {
        question: 'Is your thermostat set?',
        type: 'radio-multi',
        inputArray: ['On', 'Off', 'Auto'],
        answer: null,
        error: {},
        next: {
          On: 4,
          Off: 4,
          Auto: 4,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        question: 'Your thermostat is on?',
        type: 'radio-multi',
        inputArray: ['Heat', 'Cool'],
        answer: null,
        error: {},
        next: {
          Heat: 5,
          Cool: 5,
        },
        updateInPayload: null,
        claimField: 'thermostat',
      },
      {
        question: 'Is there air blowing?',
        type: 'radio-multi',
        inputArray: ['Yes', 'No'],
        answer: null,
        error: {},
        next: {
          Yes: 6,
          No: 6,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        question: 'Where is the system located?',
        type: 'text-input',
        placeholder: 'Enter the location ',
        answer: null,
        next: 7,
        error: {},
        updateInPayload: null,
        claimField: 'issueLocation',
      },
      {
        question: 'Additional Notes/Conversation',
        type: 'text-area-input',
        placeholder: 'Enter text here...',
        answer: null,
        error: {},
        next: 8,
        updateInPayload: null,
        claimField: 'additionalNotes',
      },
      {
        question: 'Please enter name of the Claimant for the contractor to contact',
        type: 'text-input',
        placeholder: 'Enter an claimant name ',
        answer: null,
        next: 9,
        error: {},
        updateInPayload: null,
        claimField: 'contactName',
      },
      {
        question: 'Please Enter the best number for the contractor to contact',
        type: 'tel',
        placeholder: 'Enter an contact number ',
        answer: null,
        next: null,
        error: {},
        updateInPayload: null,
        claimField: 'contactNo',
      },
    ],
  },
  CRPS: {
    // Cooling Repair - Primary System
    productId: 'b8ac2e48-cfdb-ec11-948c-00155dbb1e5f',
    daysForChildClaim: 30,
    questions: [
      {
        question: 'When did the Issue first occur?',
        type: 'date-input',
        placeholder: 'Enter the date in DD/MM/YY format',
        answer: null,
        error: {},
        next: 1,
        updateInPayload: null,
        claimField: 'firstNoticed',
      },
      {
        question: 'Do you have more than one system in your home?',
        type: 'radio-multi',
        inputArray: ['One', 'More'],
        answer: null,
        error: {},
        next: {
          One: 2,
          More: 2,
        },
        updateInPayload: null,
        claimField: 'noOfSystems',
      },
      {
        question: 'Enter the temperature of the air blowing through the register?',
        type: 'text-input',
        placeholder: 'Enter temperature value ',
        answer: null,
        error: {},
        next: 3,
        updateInPayload: null,
        claimField: null,
      },
      {
        question: 'Is your thermostat set?',
        type: 'radio-multi',
        inputArray: ['On', 'Off', 'Auto'],
        answer: null,
        error: {},
        next: {
          On: 4,
          Off: 4,
          Auto: 4,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        question: 'Your thermostat is on?',
        type: 'radio-multi',
        inputArray: ['Heat', 'Cool'],
        answer: null,
        error: {},
        next: {
          Heat: 5,
          Cool: 5,
        },
        updateInPayload: null,
        claimField: 'thermostat',
      },
      {
        question: 'Is there air blowing?',
        type: 'radio-multi',
        inputArray: ['Yes', 'No'],
        answer: null,
        error: {},
        next: {
          Yes: 6,
          No: 6,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        question: 'Where is the system located?',
        type: 'text-input',
        placeholder: 'Enter the location ',
        answer: null,
        next: 7,
        error: {},
        updateInPayload: null,
        claimField: 'issueLocation',
      },
      {
        question: 'Additional Notes/Conversation',
        type: 'text-area-input',
        placeholder: 'Enter text here...',
        answer: null,
        error: {},
        next: 8,
        updateInPayload: null,
        claimField: 'additionalNotes',
      },
      {
        question: 'Please enter name of the Claimant for the contractor to contact',
        type: 'text-input',
        placeholder: 'Enter an claimant name ',
        answer: null,
        next: 9,
        error: {},
        updateInPayload: null,
        claimField: 'contactName',
      },
      {
        question: 'Please Enter the best number for the contractor to contact',
        type: 'tel',
        placeholder: 'Enter an contact number ',
        answer: null,
        next: null,
        error: {},
        updateInPayload: null,
        claimField: 'contactNo',
      },
    ],
  },
  CPRS: {
    // Preferred Cooling Protection - Primary System
    productId: '803dacc4-cfdb-ec11-948c-00155dbb1e5f',
    daysForChildClaim: 30,
    questions: [
      {
        question: 'When did the Issue first occur?',
        type: 'date-input',
        placeholder: 'Enter the date in DD/MM/YY format',
        answer: null,
        error: {},
        next: 1,
        updateInPayload: null,
        claimField: 'firstNoticed',
      },
      {
        question: 'Do you have more than one system in your home?',
        type: 'radio-multi',
        inputArray: ['One', 'More'],
        answer: null,
        error: {},
        next: {
          One: 2,
          More: 2,
        },
        updateInPayload: null,
        claimField: 'noOfSystems',
      },
      {
        question: 'Enter the temperature of the air blowing through the register?',
        type: 'text-input',
        placeholder: 'Enter temperature value ',
        answer: null,
        error: {},
        next: 3,
        updateInPayload: null,
        claimField: null,
      },
      {
        question: 'Is your thermostat set?',
        type: 'radio-multi',
        inputArray: ['On', 'Off', 'Auto'],
        answer: null,
        error: {},
        next: {
          On: 4,
          Off: 4,
          Auto: 4,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        question: 'Your thermostat is on?',
        type: 'radio-multi',
        inputArray: ['Heat', 'Cool'],
        answer: null,
        error: {},
        next: {
          Heat: 5,
          Cool: 5,
        },
        updateInPayload: null,
        claimField: 'thermostat',
      },
      {
        question: 'Is there air blowing?',
        type: 'radio-multi',
        inputArray: ['Yes', 'No'],
        answer: null,
        error: {},
        next: {
          Yes: 6,
          No: 6,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        question: 'Where is the system located?',
        type: 'text-input',
        placeholder: 'Enter the location ',
        answer: null,
        next: 7,
        error: {},
        updateInPayload: null,
        claimField: 'issueLocation',
      },
      {
        question: 'Additional Notes/Conversation',
        type: 'text-area-input',
        placeholder: 'Enter text here...',
        answer: null,
        error: {},
        next: 8,
        updateInPayload: null,
        claimField: 'additionalNotes',
      },
      {
        question: 'Please enter name of the Claimant for the contractor to contact',
        type: 'text-input',
        placeholder: 'Enter an claimant name ',
        answer: null,
        next: 9,
        error: {},
        updateInPayload: null,
        claimField: 'contactName',
      },
      {
        question: 'Please Enter the best number for the contractor to contact',
        type: 'tel',
        placeholder: 'Enter an contact number ',
        answer: null,
        next: null,
        error: {},
        updateInPayload: null,
        claimField: 'contactNo',
      },
    ],
  },
  CPSS: {
    // Preferred Cooling Protection - Secondary System
    productId: '008362e5-cfdb-ec11-948c-00155dbb1e5f',
    daysForChildClaim: 30,
    questions: [
      {
        question: 'When did the Issue first occur?',
        type: 'date-input',
        placeholder: 'Enter the date in DD/MM/YY format',
        answer: null,
        error: {},
        next: 1,
        updateInPayload: null,
        claimField: 'firstNoticed',
      },
      {
        question: 'Do you have more than one system in your home?',
        type: 'radio-multi',
        inputArray: ['One', 'More'],
        answer: null,
        error: {},
        next: {
          One: 2,
          More: 2,
        },
        updateInPayload: null,
        claimField: 'noOfSystems',
      },
      {
        question: 'Enter the temperature of the air blowing through the register?',
        type: 'text-input',
        placeholder: 'Enter temperature value ',
        answer: null,
        error: {},
        next: 3,
        updateInPayload: null,
        claimField: null,
      },
      {
        question: 'Is your thermostat set?',
        type: 'radio-multi',
        inputArray: ['On', 'Off', 'Auto'],
        answer: null,
        error: {},
        next: {
          On: 4,
          Off: 4,
          Auto: 4,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        question: 'Your thermostat is on?',
        type: 'radio-multi',
        inputArray: ['Heat', 'Cool'],
        answer: null,
        error: {},
        next: {
          Heat: 5,
          Cool: 5,
        },
        updateInPayload: null,
        claimField: 'thermostat',
      },
      {
        question: 'Is there air blowing?',
        type: 'radio-multi',
        inputArray: ['Yes', 'No'],
        answer: null,
        error: {},
        next: {
          Yes: 6,
          No: 6,
        },
        updateInPayload: null,
        claimField: null,
      },
      {
        question: 'Where is the system located?',
        type: 'text-input',
        placeholder: 'Enter the location ',
        answer: null,
        next: 7,
        error: {},
        updateInPayload: null,
        claimField: 'issueLocation',
      },
      {
        question: 'Additional Notes/Conversation',
        type: 'text-area-input',
        placeholder: 'Enter text here...',
        answer: null,
        error: {},
        next: 8,
        updateInPayload: null,
        claimField: 'additionalNotes',
      },
      {
        question: 'Please enter name of the Claimant for the contractor to contact',
        type: 'text-input',
        placeholder: 'Enter an claimant name ',
        answer: null,
        next: 9,
        error: {},
        updateInPayload: null,
        claimField: 'contactName',
      },
      {
        question: 'Please Enter the best number for the contractor to contact',
        type: 'tel',
        placeholder: 'Enter an contact number ',
        answer: null,
        next: null,
        error: {},
        updateInPayload: null,
        claimField: 'contactNo',
      },
    ],
  },
};
