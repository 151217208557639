import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map, catchError, filter } from 'rxjs/operators';
import { HttpService } from '../../core/services/http';

import { environment } from '../../../environments/environment';
import { SessionStorageService } from '../../core/services/session-storage.service';
import { GlobalVariables } from '../../core/services/global-variables.service';
import { SiteContentService } from '../../core/services/siteContents';
import { UtilsService } from '../../core/services/utils.service';

@Injectable()
export class ProductService {
  categoriesStream: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);

  /**
   * Returns the stored vaule stored with given key.
   *
   * @param {string} key
   * @returns {string}
   *
   * @memberof UserService
   */
  constructor(
    private router: Router,
    private sessionStorageService: SessionStorageService,
    private http: HttpService,
    private globalVariables: GlobalVariables,
    private siteContentService: SiteContentService,
    private utilsService: UtilsService,
  ) {
    const state = sessionStorageService.getItem('providerState');
    this.sessionStorageService.setItem('location_code', '');
    const url = window?.location?.pathname?.split('/') ?? '/';
    if (state && !(url.length > 0 && (url.includes('products') || url.includes('product')))) {
      this.utilsService.event.subscribe(data => {
        if (Object.keys(data).length !== 0) {
          this.utilsService.getPartnerDetails$().subscribe(
            res => {
              /** ********** HWR-3642: multiple locations for FEH microsite  ******** */
              const locationCodeId = Array.isArray(res.body.locationCodeId) ? res.body.locationCodeId[0] : '';
              if (![undefined, 'undefined', null, 'null', ''].includes(locationCodeId)) {
                this.sessionStorageService.setItem('location_code', locationCodeId);
              }
              /** ********** HWR-3642: multiple locations for FEH microsite  ******** */
              this.setCategories();
            },
            () => {},
          );
        }
      });
    } else {
      this.setCategories();
    }
  }

  categories$(): Observable<any> {
    return this.categoriesStream.asObservable().pipe(filter(item => !!item));
  }

  getProductListContent() {
    return this.http.get(`${environment.Content_service_API_Endpoint}/product-content`).pipe(map(res => res));
  }

  getProviderContent(location_code: String): Observable<any> {
    return this.http.get(`${environment.Location_service_API_Endpoint}location_codes/${location_code}`).pipe(
      map(res => {
        return res;
      }),
    );
  }

  getLocationData(city: String, state: String): Observable<any> {
    return this.http.get(`${environment.Location_service_API_Endpoint}location-code-exception/?city=${city}&state=${state}`).pipe(map(res => res));
  }

  getLocationDataCRM(locationCode: string): Observable<any> {
    const loc = `${locationCode.substring(0, 2).toUpperCase()}150`;
    return this.http.get(`${environment.Location_service_API_Endpoint}location_codes?location_code=${loc}`).pipe(
      map(res => {
        const newResponse = {
          LocationDetails: [
            {
              Code: res.body[0].locationCode,
              Id: res.body[0].locationCodeId,
              Name: res.body[0].locationCodeName,
            },
          ],
        };
        res.body = newResponse;
        return res;
      }),
    );
  }

  getMarketingCodes(query?: String, locationCodeID?: String, allProducts?: any): Observable<any> {
    let locationQuery;
    const allproducts = allProducts;
    const locationCodeData = locationCodeID || this.sessionStorageService.getItem('location_code'); // HWR - 2696
    let url = `${environment.Product_service_API_Endpoint}marketing-codes/default-codes`;
    if (locationCodeData === 'isOOF') {
      return throwError(() => 'No service available in your vicinity.');
    }
    if (locationCodeData) {
      locationQuery = `location_code=${locationCodeData}`;
    }
    if (query) {
      url = `${url}?${query}`;
      if (locationQuery) {
        url = `${url}&${locationQuery}`;
      }
    } else if (locationQuery) {
      url = `${url}?${locationQuery}`;
    }

    if (allproducts) {
      url = `${url}&allProducts=${allproducts}`;
    }

    return this.http
      .get(url)
      .pipe(map(res => res))
      .pipe(catchError(err => throwError(() => err.error || JSON.stringify(err))));
  }

  getMarketingCodesById(productId: String): Observable<any> {
    return this.http.get(`${environment.Product_service_API_Endpoint}marketing-codes/${productId}`).pipe(map(res => res));
  }

  // Get data form api for UpsellPop
  getUpsellPopupData(programId: String, locationId: String, cart: any, customerNo: String = '', customerId: String = ''): Observable<any> {
    let param = `${environment.Product_service_API_Endpoint}bundle-products/upsell?&programId=${programId}&locationCodeId=${locationId}`;

    if (cart.length) {
      cart.forEach(cartProgramid => {
        param += `&cart=${cartProgramid}`;
      });
    }
    if (customerNo.length) {
      param += `&customerNumber=${customerNo}`;
    }
    if (customerId.length) {
      param += `&customerId=${customerId}`;
    }
    return this.http.get(param).pipe(map(res => res));
  }

  getNonDefaultMarketingCodesById(productId: string[]): Observable<any> {
    const mkc = productId.join(',');
    return this.http
      .get(`${environment.Product_service_API_Endpoint}marketing-codes/non-default-codes?marketing_code=${mkc}&includeDefaultMarketingCodes=true`)
      .pipe(map(res => res));
  }

  setCategories(): void {
    this.getCategories().subscribe(
      data => {
        if (data.status === 200) {
          const resData = data;
          this.categoriesStream.next(resData);
        } else if (data.status !== 200) {
          // error handling
          this.categoriesStream.next([]);
        }
      },
      () => {
        this.categoriesStream.next([]);
      },
    );
  }

  getCategories(categoryLocationCode?: any): Observable<any> {
    let savedAddress;
    let savedZip;
    if (this.sessionStorageService.getItem('selectedAddress')) {
      savedAddress = JSON.parse(this.sessionStorageService.getItem('selectedAddress'));
      savedZip = savedAddress.zipcode;
    } else {
      savedZip = '';
    }
    const locationCode = categoryLocationCode || this.sessionStorageService.getItem('location_code');
    if (savedZip !== '') {
      let queryUrl = `${environment.Product_service_API_Endpoint}category?location_code=`;
      if (locationCode && locationCode !== 'undefined') {
        if (locationCode !== 'isOOF') {
          queryUrl += locationCode;
        }
      }
      return this.http.get(queryUrl).pipe(map(res => res));
    }
    if (locationCode) {
      let queryUrl = `${environment.Product_service_API_Endpoint}category?location_code=`;
      if (locationCode && locationCode !== 'undefined') {
        if (locationCode !== 'isOOF') {
          queryUrl += locationCode;
        }
      }
      return this.http.get(queryUrl).pipe(map(res => res));
    }
    const queryUrl = `${environment.Product_service_API_Endpoint}category`;
    return this.http.get(queryUrl).pipe(map(res => res));
  }

  getRecommendedProducts(
    locationId: String,
    productIds?: any,
    customerId?: any,
    contactType?: any,
    customerNum?: any,
    guestToken?: any,
  ): Observable<any> {
    let url = `${environment.Product_service_API_Endpoint}recommended-products?location_id=${locationId}&isShowOnListing=true`;
    if (productIds.length > 0) {
      url += `&not_in=${productIds}`;
    }
    if (customerId) {
      url += `&customer_id=${customerId}`;
    }
    if (contactType) {
      url += `&contact_type=${contactType}`;
    }
    if (customerNum) {
      url += `&crm_customer_number=${customerNum}`;
    }
    const options = guestToken ? { headers: { authorization: guestToken } } : undefined;
    return this.http.get(url, options).pipe(map(res => res));
  }

  getTermsAndConditions(language: string, state: string, zipcode: string, program: string): Observable<any> {
    return this.http
      .get(`${environment.TERMS_SERVICE_ENDPOINT}/terms-and-conditions?state=${state}&zip_code=${zipcode}&program=${program}&lang=${language}`)
      .pipe(map(res => res));
  }

  getTermsAndConditionsForProduct(
    language: string,
    state: string,
    zipcode: string,
    program: string,
    contactType: any,
    isCombo: any,
    coverageType: string,
    locationCodeId: string,
  ): Observable<any> {
    let url = '';
    if (contactType === 3) {
      url =
        `${environment.TERMS_SERVICE_ENDPOINT}/terms-and-conditions?state=${state}&zip_code=${zipcode}&program=${program}` +
        `&lang=${language}&contact_type=${contactType}&is_combo=${isCombo}&locationCodeId=${locationCodeId}`;
    } else {
      url =
        `${environment.TERMS_SERVICE_ENDPOINT}/terms-and-conditions?state=${state}&zip_code=${zipcode}&program=${program}` +
        `&lang=${language}&is_combo=${isCombo}&locationCodeId=${locationCodeId}`;
    }

    if (coverageType === 'UNLIMITED') {
      url += `&coverage_type=${coverageType}`;
    }

    return this.http.get(url).pipe(map(res => res));
  }

  getServiceGenericContent(serviceId: String) {
    return this.http.get(`${environment.Content_service_API_Endpoint}service-content/${serviceId}`).pipe(map(res => res));
  }

  verifyCustomerService(marketingCodes: Array<any>) {
    return this.http
      .get(`${environment.Product_service_API_Endpoint}marketing-codes/verify?marketingCodes=${marketingCodes.join(',')}`)
      .pipe(map(res => res));
  }

  getUpgradeServices(marketingCode: String, locationId: String) {
    return this.http
      .get(
        `${environment.Product_service_API_Endpoint}bundle-products/
?marketingCodes=${marketingCode}&locationCodeId=${locationId}`,
      )
      .pipe(map(res => res));
  }

  getLocationCode(location_code: String): Observable<any> {
    return this.http.get(`${environment.Location_service_API_Endpoint}location_codes/?location_code=${location_code}`).pipe(
      map(res => {
        return res;
      }),
    );
  }

  getDefaultCodeForRenewal(
    marketingCodeId: String,
    defaultLocationCodeId: String,
    coverageType: String,
    contactType: Number,
    programId: String,
    customerNo: String,
    evo_newmarketingcode: String,
  ): Observable<any> {
    return this.http
      .get(
        `${environment.Product_service_API_Endpoint}marketing-codes/get-default-code-for-renewal?marketing_code_id=${marketingCodeId}&default_location_code_id=${defaultLocationCodeId}&coverage_type=${coverageType}&contact_type=${contactType}&program_id=${programId}&customer_number=${customerNo}&evo_newmarketingcode=${evo_newmarketingcode}`,
      )
      .pipe(map(res => res));
  }

  /* Api's start for HWR-1211 */
  /* Get API for get product reviews */
  getProductReviews(programId: any, page: Number, sortQuery?: any): Observable<any> {
    let url = `${environment.Product_service_API_Endpoint}product-review/get-reviews/${programId}?page=${page}&limit=${environment.review_limit_per_page}`;
    if (sortQuery) {
      url = `${environment.Product_service_API_Endpoint}product-review/get-reviews/${programId}?page=${page}&limit=${environment.review_limit_per_page}&${sortQuery}`;
    }
    return this.http.get(url).pipe(map(res => res));
  }

  /* API for create product reviews */
  createReview(payload: any): Observable<any> {
    const apiUrl = `${`${environment.Product_service_API_Endpoint}product-review/create-review`}`;
    return this.http.post(apiUrl, payload).pipe(map(res => res));
  }

  /* API for HWR-5464 */
  getSuggestedProgramsLogedinuser(locationId: String, cart: any, customerId: any, cartCusNo: String) {
    const queryData = JSON.stringify({
      query: cart,
    });
    return this.http
      .get(
        `${environment.Product_service_API_Endpoint}suggested-programs/list?cartProgramData=${queryData}&locationCodeId=${locationId}&customerId=${customerId}&customerNumber=${cartCusNo}&isShowOnListing=true`,
      )
      .pipe(map(res => res));
  }

  /* Get API for Average product reviews */
  getAvgProductReviews(programId: any): Observable<any> {
    return this.http
      .get(`${environment.Product_service_API_Endpoint}product-review/averageRatingByProgramId?programId=${programId}`)
      .pipe(map(res => res));
  }
  /* Api's end for HWR-1211 */

  /* HWR-2666 Get All Reviews based on program id Start */
  getProductReviewsForSchema(programId: any, allReviews: Boolean): Observable<any> {
    return this.http
      .get(`${environment.Product_service_API_Endpoint}product-review/get-reviews/${programId}?allReviews=${allReviews}`)
      .pipe(map(res => res));
  }
  /* HWR-2666 Get All Reviews based on program id End */

  /* HWR-2925 Get All Reviews count based on program id Start */
  getProductReviewsCount(programId: any): Observable<any> {
    return this.http.get(`${environment.Product_service_API_Endpoint}product-review/get-reviews-countwise/${programId}`).pipe(map(res => res));
  }
  /* HWR-2925 Get All Reviews count based on program id End */

  getCategoryById(serviceId) {
    const queryUrl = `${environment.Product_service_API_Endpoint}category/${serviceId}`;
    return this.http.get(queryUrl).pipe(map(res => res));
  }

  getDefaultCodeForRenewalCheckout(
    marketingCodeId: String,
    defaultLocationCodeId: String,
    coverageType: String,
    contactType: Number,
    programId: String,
    customerNo: String,
    evo_newmarketingcode: String,
    token?,
  ): Observable<any> {
    const options = token ? { headers: { authorization: token } } : undefined;
    return this.http
      .get(
        `${environment.Product_service_API_Endpoint}marketing-codes/get-default-code-for-renewal?marketing_code_id=${marketingCodeId}&default_location_code_id=${defaultLocationCodeId}&coverage_type=${coverageType}&contact_type=${contactType}&program_id=${programId}&customer_number=${customerNo}&evo_newmarketingcode=${evo_newmarketingcode}`,
        options,
      )
      .pipe(map(res => res));
  }

  getSuggestedPrograms(locationId: String, cart: any) {
    const queryData = JSON.stringify({
      query: cart,
    });
    return this.http
      .get(
        `${environment.Product_service_API_Endpoint}suggested-programs/list?cartProgramData=${queryData}&locationCodeId=${locationId}&isShowOnListing=true`,
      )
      .pipe(map(res => res));
  }
}
