import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

export interface Breadcrumb {
  title?: string;
  link?: string;
  active?: boolean;
  query?: any;
}

@Component({
  selector: 'hos-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent {
  @Input() config?: Array<Breadcrumb> = [];

  constructor(private router: Router) {}
}
