import { Component, OnInit, Input } from '@angular/core';
import { NguCarouselConfig } from '@ngu/carousel';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { SimpleModalComponent, SimpleModalService } from 'ngx-simple-modal';
import { WindowScrolling } from '../../services/window-scrolling.service';
import { UtilsService } from '../../services/utils.service';
import { environment } from '../../../../environments/environment';
import { ClaimsService } from '../../services/claims.service';
import { jobTypes } from './jobType';
import { claimsConfig } from './claimsQuestionnaire';
import { claimsSummary } from './claimsSummary';
import { GlobalVariables } from '../../services/global-variables.service';
import { programs } from '../colorConfig/programClass';
import { OrdersService } from '../../../payment-ets/services/orders.service';
import { SessionStorageService } from '../../services/session-storage.service';
import { ErrorModalComponent } from '../error-modal/error-modal.component';

export interface ClaimsQuestionModalModel {
  product: any;
  user: any;
}

@Component({
  selector: 'hos-claims-question-modal',
  templateUrl: './claims-question-modal.component.html',
  styleUrls: ['./claims-question-modal.component.scss'],
})
export class ClaimsQuestionModalComponent extends SimpleModalComponent<ClaimsQuestionModalModel, any> implements ClaimsQuestionModalModel, OnInit {
  // Caraousel
  @Input() carouselEnrollService: NguCarouselConfig;

  public carouselBanner: NguCarouselConfig;

  public carouselEnrolledService: NguCarouselConfig;

  public carouselRecommendedService: NguCarouselConfig;

  public carouselTile: NguCarouselConfig;

  product: any;

  user: any;

  textAreaValue: any;

  showSuccessScreen: boolean = false;

  disabledNext: boolean = true;

  imageBaseUrl: any;

  filteredProducts: any; // For filtering from Bundle Program

  showBundleProductModal = false;

  singleBundleProduct: any; // For Storing the single selected program Info.

  claimsQuestions: any;

  questionIndex = 0;

  claimAnswer: any;

  showLoader = false;

  claimsQuestionsMaster: any;

  previousQuestion: Array<number> = [];

  today: any; // Date for Claims

  isChildClaimSuccess: boolean = false;

  showChildClaimComponent: boolean = false; // For displaying Child Claim Component.

  childQuestNumber: any = 1; // Question Number of Child Component

  childClaimAnswer: any = {}; // Answers for Child Claim Component

  // store claims properties to genearte claimsSummary
  claimSummary: any = {
    customerName: null,
    firstNoticed: null,
    programName: null,
    effectiveDate: null,
    additionalNotes: null,
    customerNo: null,
  };

  // payload to create final claim
  claimsPayload: any = {
    customerId: null,
    productId: null,
    jobTypeId: null,
    stateId: environment.claimStateId,
    customerType: null,
    preferredContactMethod: 'Phone',
    preferredContactPhone: null,
    serviceFeeStatus: 'NA',
    serviceFeeAmount: 0,
    claimSummary: null,
    waterOff: false,
    claimTypeStage: 0, // 0:parent 1:child claim
    locationId: null,
    contactIdName: null,
  };

  childClaimPayload: any;

  intiateClaim: boolean = false;

  claimError: boolean = false;

  claimFailedCriteria: any;

  // fields for previous claims
  previousClaims: any;

  showPreviousClaims: boolean = false;

  selectedClaim: any;

  isBundleView: boolean = false;

  selectedFromBundle: any;

  isLastQuestion: boolean = false;

  isChildQuestion: boolean = false;

  childClaimSummary: any;

  claimResultData: any;

  claimsServiceFeeData: any;

  showServiceFeePopUp: boolean = false;

  serviceFeeAnswer: any = {}; // For saving service fee option answer

  serviceFeeQuestNumber: any = 1; // Question Number of Service Fee Component

  serviceFees: any;

  selectedCard: any;

  daysForChildClaim: any;

  showCliamsDialog: boolean = false;

  // please add/Remove programe code to block/unblock claims filing process
  blockProgramCode = ['IOES', 'CHST', 'WHMPPL', 'APPL', 'EPP', 'CHSTPL', 'HESS', 'HCPL', 'ELPP/EELP', 'HCES', 'WHMPL', 'WHMPR', 'WHM'];

  constructor(
    private simpleModalService: SimpleModalService,
    private windowScrolling: WindowScrolling,
    private httpClient: HttpClient,
    private utilsService: UtilsService,
    private router: Router,
    private claimsService: ClaimsService,
    private datePipe: DatePipe,
    private globalVariables: GlobalVariables,
    private ordersService: OrdersService,
    public sessionStorageService: SessionStorageService,
  ) {
    super();
    // this.windowScrolling.disable();
  }

  ngOnInit() {
    if (!this.carouselEnrollService) {
      this.carouselTile = {
        grid: {
          xs: 1,
          sm: 3,
          md: 4,
          lg: 4,
          all: 0,
        },
        slide: 1,
        speed: 400,
        point: {
          visible: false,
        },
        load: 1,
        loop: true,
        touch: true,
        easing: 'ease',
      };
    } else {
      this.carouselTile = this.carouselEnrollService;
    }
    this.today = this.datePipe.transform(new Date(), 'MM/dd/yyyy');
    this.claimsQuestionsMaster = JSON.parse(JSON.stringify(claimsConfig)); // Clone Copy of Questions
    if (this.product.productDetails.program.products.length) {
      this.showBundleProductModal = true;
    }
    if (this.product.productDetails.program.products.length > 1) {
      this.isBundleView = true;
    }
    this.imageBaseUrl = `${environment.Image_API_Endpoint}${environment.Product_service_API_Endpoint}`;
    this.showLoader = true;
    this.claimsService.getProductById(this.product.productDetails.program.products).subscribe(
      data => {
        if (data) {
          const resultData = data.body;
          const promgrameCode = this.product.productDetails.program.programCode;

          if (this.blockProgramCode.includes(promgrameCode)) {
            this.showLoader = false;
            this.close();
            this.showErrorModal();
            return false;
          }
          this.filteredProducts = resultData.filter(product => this.product.productDetails.program.products.indexOf(product.productId) !== -1);
          /* Bundle products Background color */
          for (let i = 0; i < this.filteredProducts.length; i++) {
            const objData = programs[this.filteredProducts[i].productCode];
            this.filteredProducts[i].colorConfigClass = objData.class;
            this.filteredProducts[i].colorConfigStyle = objData.color;
          }
          // only one product in bundle then start directly with probbing questions
          if (this.filteredProducts.length === 1) {
            this.bundleProgramSelect(this.filteredProducts[0]);
          } else {
            this.showCliamsDialog = true;
            this.showLoader = false;
          }
        }
      },
      error => {
        this.showLoader = false;
        console.log(error);
      },
    );
  }

  saveAnswer(answer: any) {
    this.claimAnswer = answer;
  }

  saveChildClaimAnswer(answer: any) {
    if (this.childQuestNumber === 1) {
      this.childClaimAnswer.firstChildAns = answer;
      this.childClaimAnswer.secChildAns = '-';
    } else if (this.childQuestNumber === 2) {
      this.childClaimAnswer.secChildAns = answer;
    }
    this.childClaimSummary = ` Issue was re-occured on date ${this.childClaimAnswer.firstChildAns}. Additional Comments: ${this.childClaimAnswer.secChildAns}`;
    this.isChildQuestion = true;
  }

  // Bundle Program Select Function
  bundleProgramSelect(product) {
    this.showLoader = true;
    this.singleBundleProduct = product; // Storing the selected bundle info in the variable.
    this.isBundleView = false;
    this.selectedFromBundle = product.productCode;
    if (!this.claimsQuestionsMaster[product.productCode]) {
      this.close();
      this.showErrorModal();
      return false;
    }
    this.showCliamsDialog = true;
    // [HWR-3294] Change child claims days for CA &VA locations.
    const speacialLocations = Object.keys(this.claimsQuestionsMaster[product.productCode]);
    if (speacialLocations.includes(this.product.address.serviceAddress.state)) {
      this.daysForChildClaim = this.claimsQuestionsMaster[product.productCode][this.product.address.serviceAddress.state];
    } else {
      this.daysForChildClaim = this.claimsQuestionsMaster[product.productCode].daysForChildClaim;
    }
    // get previous claims for selected product
    this.claimsService.getClaimsByProductId(this.product.crmCustomerGuid, this.singleBundleProduct.productId).subscribe(
      claims => {
        const { data } = claims.body;
        this.previousClaims = data;
        this.previousClaims = this.previousClaims.filter(
          obj =>
            typeof obj.SummaryofClaim !== 'undefined' &&
            typeof obj.jobId !== 'undefined' &&
            this.dateDifferenceForChildClaim(obj.dateOfClaim) < this.daysForChildClaim,
        );

        this.previousClaims.sort((a, b) => {
          const date1: any = new Date(a.dateOfClaim).getTime();
          const date2: any = new Date(b.dateOfClaim).getTime();
          return date2 - date1;
        });
        this.claimsService
          .getClaimsServiceFee(this.product.crmCustomerGuid, this.singleBundleProduct.productId, this.product.crmEnrollmentGuid)
          .subscribe(
            // eslint-disable-next-line @typescript-eslint/no-shadow
            data => {
              if (data && data.body && data.body.data && data.body.data.Result) {
                this.claimsServiceFeeData = JSON.parse(data.body.data.Result);
                this.serviceFees = this.claimsServiceFeeData.ClaimsServiceFee.ServiceFee;
                this.showLoader = false;
                this.showBundleProductModal = false; // Showing probing questions now
                if (this.previousClaims.length) {
                  this.showPreviousClaims = true;
                }
              } else {
                this.showLoader = false;
                this.showBundleProductModal = false;
                this.intiateClaim = true;
                this.claimError = true;
                this.claimFailedCriteria = 'Something went wrong please try again later...';
              }
            },
            () => {
              this.showLoader = false;
              this.showBundleProductModal = false;
              this.intiateClaim = true;
              this.claimError = true;
              this.claimFailedCriteria = 'Something went wrong please try again later...';
            },
          );
      },
      error => {
        this.showLoader = false;
        console.log(error);
      },
      () => {
        // [HWR-3280 Updating claims question for NYC200 location]
        const key = Object.keys(this.claimsQuestionsMaster[product.productCode]);
        if (key.includes(this.product.locationId)) {
          this.claimsQuestions = JSON.parse(JSON.stringify(this.claimsQuestionsMaster[product.productCode][this.product.locationId]));
        } else {
          this.claimsQuestions = JSON.parse(JSON.stringify(this.claimsQuestionsMaster[product.productCode].questions));
        }
      },
    );
  }

  // Handler to persist selected claim from previous claimslist
  onSelectClaim(claim) {
    this.selectedClaim = claim;
    const customerPropertyType = this.customerTypeSpecification(
      this.product.address.serviceAddress.contactType ? this.product.address.serviceAddress.contactType : this.user.contactType,
    );
    this.childClaimPayload = {
      ...this.claimsPayload,
      parentClaimId: claim.ClaimId,
      customerId: claim.customerId,
      productId: claim.ProductId,
      jobTypeId: claim.jobId,
      customerType: customerPropertyType,
      preferredContactMethod: 'Phone', // Later need to be fixed
      preferredContactPhone: this.user.phoneNumber
        ? this.user.phoneNumber
        : this.claimSummary.contactNo || this.sessionStorageService.getItem('userphoneNumber'),
      enrollmentId: claim.enrollmentId,
      claimSummary: claim.SummaryofClaim,
      customerNo: this.product.address.customerNo || this.product.crmCustomerNumber,
      claimTypeStage: 1,
      // eslint-disable-next-line no-underscore-dangle
      userId: this.user._id,
      locationId: this.product.locationId,
      custId: this.user.customerId,
      contactIdName: `${this.user.firstName} ${this.user.lastName}`,
    };
    this.showChildClaimComponent = true;
  }

  getImage(imageName) {
    return this.utilsService.buildImageUrl(imageName);
  }

  confirmHandler() {
    if (
      this.claimsQuestions[this.questionIndex].type === 'dropdown' ||
      this.claimsQuestions[this.questionIndex].type === 'radio-multi' ||
      this.claimsQuestions[this.questionIndex].type === 'radio-slider' ||
      this.claimsQuestions[this.questionIndex].type === 'checkbox'
    ) {
      // no probing questions

      // Config for checkbox
      let copyOfClaimAnswer: any;
      if (this.claimsQuestions[this.questionIndex].type === 'checkbox') {
        const errorFound = this.claimsQuestions[this.questionIndex].error.some(r => this.claimAnswer.includes(r));
        if (errorFound) {
          this.intiateClaim = true;
          this.claimError = true;
          this.claimFailedCriteria = 'Claim Denied.';
          return;
        }
        copyOfClaimAnswer = JSON.parse(JSON.stringify(this.claimAnswer));
        // eslint-disable-next-line prefer-destructuring
        this.claimAnswer = this.claimAnswer[0];
      }
      if (this.claimAnswer && !this.claimsQuestions[this.questionIndex].next[this.claimAnswer]) {
        // condition for claim cannot be covered

        // Condition to handle checkbox array answers
        if (this.claimsQuestions[this.questionIndex].type === 'checkbox') {
          this.claimAnswer = copyOfClaimAnswer;
        }
        if (this.claimsQuestions[this.questionIndex].error[this.claimAnswer]) {
          this.intiateClaim = true;
          this.claimError = true;
          this.claimFailedCriteria = this.claimsQuestions[this.questionIndex].error[this.claimAnswer];
          return;
        }
        this.intiateClaim = true;
        this.claimSubmit();
      } else {
        if (copyOfClaimAnswer) {
          this.claimAnswer = copyOfClaimAnswer;
          copyOfClaimAnswer = '';
        }
        // move to next probbing question
        // update payload accrording to probbing questions
        if (
          this.claimAnswer &&
          this.claimsQuestions[this.questionIndex].updateInPayload &&
          this.claimsQuestions[this.questionIndex].updateInPayload[this.claimAnswer]
        ) {
          this.claimsPayload = {
            ...this.claimsPayload,
            ...this.claimsQuestions[this.questionIndex].updateInPayload[this.claimAnswer],
          };
        }
        // save claimSummary properties required to genearte claims summary
        if (this.claimAnswer) {
          this.claimsQuestions[this.questionIndex].answer = this.claimAnswer;
        }
        if (this.claimsQuestions[this.questionIndex].claimField) {
          this.claimSummary = {
            ...this.claimSummary,
            [this.claimsQuestions[this.questionIndex].claimField]: this.claimAnswer,
          };
        }
        // persist previous indexes
        this.previousQuestion.push(this.questionIndex);
        // save next questions index
        if (this.claimsQuestions[this.questionIndex].type === 'checkbox') {
          this.questionIndex = this.claimsQuestions[this.questionIndex].next[this.claimAnswer[0]];
        } else {
          this.questionIndex = this.claimsQuestions[this.questionIndex].next[this.claimAnswer];
        }
        // clear the previous answer or persist next answer
        this.claimAnswer = this.claimsQuestions[this.questionIndex].answer ? this.claimsQuestions[this.questionIndex].answer : null;
      }
    } else {
      // handle questions other that input type dropdown/radio
      // eslint-disable-next-line no-lonely-if
      if (!this.claimsQuestions[this.questionIndex].next) {
        // show the reason due to which  claim is failed
        if (this.claimsQuestions[this.questionIndex].error[this.claimAnswer]) {
          this.intiateClaim = true;
          this.claimError = true;
          this.claimFailedCriteria = this.claimsQuestions[this.questionIndex].error[this.claimAnswer];
          return;
        }
        // Service Fee Popup to collect Service Fee
        if (this.claimsServiceFeeData && this.serviceFees > 0) {
          this.showServiceFeePopUp = true;
        } else {
          // save claimSummary properties required to genearte claims summary if last question
          if (this.claimsQuestions[this.questionIndex].claimField) {
            this.claimSummary = {
              ...this.claimSummary,
              [this.claimsQuestions[this.questionIndex].claimField]: this.claimAnswer,
            };
          }
          this.intiateClaim = true;
          this.claimSubmit();
        }
      } else {
        // save answer
        if (this.claimAnswer) {
          this.claimsQuestions[this.questionIndex].answer = this.claimAnswer;
        }
        // save claimSummary properties required to genearte claims summary
        if (this.claimsQuestions[this.questionIndex].claimField) {
          this.claimSummary = {
            ...this.claimSummary,
            [this.claimsQuestions[this.questionIndex].claimField]: this.claimAnswer,
          };
        }
        // persist previous indexes
        this.previousQuestion.push(this.questionIndex);

        // redirect to next question
        this.questionIndex = this.claimsQuestions[this.questionIndex].next;

        // clear the previous answer or persist next answer
        this.claimAnswer = this.claimsQuestions[this.questionIndex].answer ? this.claimsQuestions[this.questionIndex].answer : null;
      }
    }
    // enable next button if last question is text-area-input
    if (this.claimsQuestions[this.questionIndex].next === null && this.claimsQuestions[this.questionIndex].type === 'text-area-input') {
      if (this.claimsServiceFeeData && this.serviceFees > 0) {
        this.showServiceFeePopUp = true;
      } else {
        this.isLastQuestion = true;
      }
    }
  }

  confirmChildHandler() {
    if (this.isChildQuestion) {
      this.childQuestNumber = 2;
    }
  }

  handlePrevious() {
    if (this.previousQuestion.length) {
      this.questionIndex = this.previousQuestion.pop();
      this.claimAnswer = this.claimsQuestions[this.questionIndex].answer ? this.claimsQuestions[this.questionIndex].answer : null;
      this.isLastQuestion = false;
      this.isChildQuestion = false;
      this.showServiceFeePopUp = false;
      this.childQuestNumber = 1;
    } else {
      this.showBundleProductModal = true; // Showing probing questions now
      this.singleBundleProduct = null; // Storing the selected bundle info in the variable.
      this.claimAnswer = null; // clear claimanswer
      this.isBundleView = true;
      this.showServiceFeePopUp = false;
    }
  }

  handleChildPrevious() {
    this.childQuestNumber = 1;
    this.childClaimAnswer = '';
  }

  customerTypeSpecification(type) {
    let customerType = 'CommercialCustomer';
    if (type === 'RESIDENTIAL') {
      customerType = 'ResidentialCustomer';
      return customerType;
    }
    return customerType;
  }

  modalClose() {
    if ((this.showSuccessScreen && this.intiateClaim) || this.isChildClaimSuccess || (this.claimError && this.intiateClaim)) {
      this.globalVariables.claimResults = this.claimResultData;
      this.globalVariables.productData.next(this.product);
      this.router.navigate(['user/claim-success'], {
        queryParams: {
          error: (this.showSuccessScreen && this.intiateClaim) || (this.showSuccessScreen && this.isChildClaimSuccess) ? 'false' : 'true',
        },
      });
    }
    this.windowScrolling.enable();
    this.close();
  }

  claimSubmit() {
    if (this.intiateClaim) {
      this.showLoader = true;
      // customer property
      const customerPropertyType = this.customerTypeSpecification(
        this.product.address.serviceAddress.contactType ? this.product.address.serviceAddress.contactType : this.user.contactType,
      );
      // update claims properties to generate claims summary

      this.claimSummary = {
        ...this.claimSummary,
        customerName: `${this.user.firstName} ${this.user.lastName}`,
        programName: this.product.productDetails.customerProgramName,
        effectiveDate: this.product.programEffectiveDate ? this.product.programEffectiveDate.split('T')[0] : this.product.effectiveFrom.split('T')[0],
      };

      this.textAreaValue = claimsSummary[this.singleBundleProduct.productCode](this.claimSummary);

      // find job id for selected bundle
      const jobType = jobTypes.find(
        // For selecting jobTypeId on basis of product's productId for CRM.
        // job => job.productId === this.singleBundleProduct.productId

        // For selecting jobTypeId on basis of product's programCode (productCode) for Oracle.
        job => job.programCode === this.singleBundleProduct.productCode,
      );

      // payload to create claims
      this.claimsPayload = {
        ...this.claimsPayload,
        enrollmentId: this.product.crmEnrollmentGuid,
        customerId: this.product.crmCustomerGuid,
        productId: this.singleBundleProduct.productId,
        jobTypeId: jobType.jobTypeID,
        customerType: customerPropertyType,
        preferredContactPhone: this.user.phoneNumber ? this.user.phoneNumber : this.claimSummary.contactNo,
        // eslint-disable-next-line no-underscore-dangle
        userId: this.user._id,
        customerNo: this.product.address.customerNo || this.product.crmCustomerNumber,
        locationId: this.product.locationId,
        serviceFeeAmount: this.serviceFees,
        claimSummary:
          (claimsSummary[this.singleBundleProduct.productCode] && claimsSummary[this.singleBundleProduct.productCode](this.claimSummary)) ||
          'Claims Summary',
        custId: this.user.customerId,
        contactIdName: `${this.user.firstName} ${this.user.lastName}`,
      };

      this.claimsService.createClaims(this.claimsPayload).subscribe(
        data => {
          if (data) {
            const resultData = data.body;
            this.showSuccessScreen = true;
            this.claimError = false;
            this.showLoader = false;
            this.claimResultData = resultData;
          }
        },
        () => {
          this.claimError = true;
          this.showLoader = false;
          this.claimFailedCriteria = 'Error While creating claim';
        },
      );
    } else {
      this.claimError = true;
      this.showLoader = false;
      this.claimFailedCriteria = 'Error While creating claim';
    }
  }

  disabledNextBtn() {
    if (this.textAreaValue.trim().length > 0) {
      this.disabledNext = false;
    } else {
      this.disabledNext = true;
    }
  }

  getHeaderClass() {
    if (this.product.productDetails.program.products.length === 1) {
      return `class-${this.product.productDetails.program.programCode}`;
    }
    if (this.selectedFromBundle !== null && this.selectedFromBundle !== undefined && !this.isBundleView) {
      return `class-${this.selectedFromBundle}`;
    }
    return 'class-bundle';
  }

  hidePreviousClaims() {
    this.showPreviousClaims = false;
  }

  statusImageChanges(status) {
    if (status === 'Open') {
      const open = '../../../../img/my-account-claims/OpenClaim.svg';
      return open;
    }
    if (status === 'Pending') {
      return '../../../../img/my-account-claims/ClaimPending.svg';
    }
    if (status === 'Canceled') {
      return '../../../../img/my-account-claims/ClaimCanceled.svg';
    }
    return '../../../../img/my-account-claims/ClaimClosed.svg';
  }

  childClaimSubmit() {
    this.childClaimPayload.claimSummary += this.childClaimSummary;
    this.claimsService.createClaims(this.childClaimPayload).subscribe(
      data => {
        if (data) {
          const resultData = data.body;
          this.showPreviousClaims = false;
          this.showSuccessScreen = true;
          this.isChildClaimSuccess = true;
          this.claimError = false;
          this.childClaimAnswer = 0;
          this.isChildQuestion = false;
          this.showChildClaimComponent = false;
          this.claimResultData = resultData;
          setTimeout(() => {
            this.showLoader = false;
          }, 1000);
        }
      },
      () => {
        this.intiateClaim = true;
        this.claimError = true;
        this.showPreviousClaims = false;
        this.showChildClaimComponent = false;
        this.showSuccessScreen = false;
        this.showLoader = false;
        this.claimFailedCriteria = 'Error While creating claim';
      },
    );
  }

  saveServiceFeeAns(answer: any) {
    // if (this.serviceFeeQuestNumber === 1) {
    //     this.serviceFeeAnswer = answer;
    // } else if (this.serviceFeeQuestNumber === 2) {
    // }
    this.serviceFeeAnswer = answer;
  }

  handleServiceFeePrevious() {
    if (this.serviceFeeQuestNumber === 1) {
      this.showServiceFeePopUp = false;
    } else {
      this.serviceFeeQuestNumber = 1;
    }
    // this.serviceFeeAnswer = '';
  }

  confirmServiceFeeHandler() {
    if (this.serviceFeeAnswer === 'Yes') {
      this.serviceFeeQuestNumber = 2;
    } else {
      this.modalClose();
    }
  }

  dateDifferenceForChildClaim(date) {
    const date1 = new Date(date).getTime();
    const diffInTime = new Date().getTime() - date1;
    const diffInDays = Math.floor(diffInTime / (1000 * 3600 * 24));
    return diffInDays;
  }

  selectCard(event) {
    this.selectedCard = event.data;
  }

  processServiceFees(processedCard: any = {}) {
    if (processedCard.error) {
      this.showLoader = false;
      this.intiateClaim = true;
      this.claimError = true;
      this.claimFailedCriteria = 'Payment failed please try again...';
    } else {
      this.showLoader = true;
      const card = processedCard.data;
      let cardDetails;
      if (card && card.FinancialInstrumentToken) {
        cardDetails = {
          CardType: card.CardType,
          ETSCustomerId: card.FinancialInstrumentToken,
          ExpirationDate: card.ExpirationDate,
          FinancialInstrumentToken: card.ETSCustomerId,
          Last4Digits_CC: card.Last4Digits_CC,
          PaymentType: 'CreditCard',
        };
      } else if (this.selectedCard.CreditCardToken) {
        cardDetails = {
          CardType: this.selectedCard.CardType,
          ETSCustomerId: this.selectedCard.CustomerToken,
          ExpirationDate: this.selectedCard.ExpirationDate,
          FinancialInstrumentToken: this.selectedCard.CreditCardToken,
          Last4Digits_CC: this.selectedCard.AccountNumberMasked,
          PaymentType: 'CreditCard',
        };
      }

      cardDetails.upfrontPaymentToken = card.upfrontPaymentToken;
      cardDetails.fingerprint = card.fingerprint;
      cardDetails.bankAccountType = card.bankAccountType;
      cardDetails.oracleCustId = card.oracleCustId;
      cardDetails.oracleAccountNumber = card.oracleAccountNumber;

      if (this.claimsQuestions[this.questionIndex].claimField) {
        this.claimSummary = {
          ...this.claimSummary,
          [this.claimsQuestions[this.questionIndex].claimField]: this.claimAnswer,
        };
      }
      this.intiateClaim = true;
      // this.ordersService
      //   .genericOneTimePayment(
      //     this.product.address,
      //     this.user.customerId,
      //     this.product.address.customerNo || this.product.crmCustomerNumber,
      //     cardDetails.FinancialInstrumentToken,
      //   )
      //   .subscribe(
      //     oneTimePaymentResponse => {
      //       if (oneTimePaymentResponse && oneTimePaymentResponse.body && oneTimePaymentResponse.body.data) {
      //         this.claimsPayload = {
      //           ...this.claimsPayload,
      //           ...cardDetails,
      //           approvalCode: oneTimePaymentResponse.body.data.approvalCode,
      //         };
      //         this.claimSubmit();
      //       } else {
      //         this.showLoader = false;
      //         this.intiateClaim = true;
      //         this.claimError = true;
      //         this.claimFailedCriteria = 'Payment failed please try again...';
      //       }
      //     },
      //     () => {
      //       this.showLoader = false;
      //       this.intiateClaim = true;
      //       this.claimError = true;
      //       this.claimFailedCriteria = 'Payment failed please try again...';
      //     },
      //   );
      this.claimsPayload = {
        ...this.claimsPayload,
        ...cardDetails,
      };
      this.claimSubmit();
    }
  }

  showErrorModal() {
    this.simpleModalService
      .addModal(
        ErrorModalComponent,
        {
          title: 'Need to Request a Repair Service?',
          message: 'Please call the number below to speak to a customer service representative: <b>1.855.800.5195</b>',
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: true,
        },
      )
      .subscribe(() => {
        this.windowScrolling.enable();
      });
  }
}
