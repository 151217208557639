<section *ngIf="!custDetailsNotFoundComp" class="content-section purchase-history-section">
  <div class="page-wrap">
    <div *ngIf="purchaseDetails?.length" class="purchase-info-container col-lg-10 col-md-11 col-xs-11">
      <div class="address-container">
        <div>
          <p class="margin-vertical-5">
            <strong>
              <span>Account Holder: </span>
              <span>{{ purchaseDetails[0].FullName | titlecase }}</span>
            </strong>
          </p>
        </div>
      </div>
      <div class="email-content-container">
        <p>
          Thank you for protecting your home and household budget with an American Water Resources repair plan(s). It is our privilege to guard you
          and your home from unexpected repair costs.
        </p>
        <p class="margin-vertical-20">
          Our costs for services and materials have increased, and to continue providing you with the highest level of service, including a network of
          experienced, pre-screened, local service technicians, and 24/7 phone service, effective
          {{ purchaseDetails[0]['end_date'] | date : 'MM/dd/yyyy' }}, the {{ purchaseDetails[0]['billing_profile'] }} rate of your service plan(s)
          will be:
        </p>
        <ul class="product-list-style">
          <div
            *ngIf="
              purchaseDetails[0]['syncSource'] === 'rpi';
              then rpiBlock;
              else purchaseDetails[0]['syncSource'] === 'awr' || purchaseDetails[0]['syncSource'] === 'ouc' ? awrBlock : commonBlock
            "
          ></div>

          <ng-template #rpiBlock>
            <li *ngIf="labelKeys">{{ purchaseDetails[0]['pkg1_desc'] }}: ${{ purchaseDetails[0]['total_amount'] | number : '1.2-2' }}</li>
          </ng-template>
          <ng-template #awrBlock>
            <ng-container *ngFor="let item of purchaseDetails[0]['products']">
              <li *ngIf="purchaseDetails[0]['products']">{{ item }}</li>
            </ng-container>
          </ng-template>
          <ng-template #commonBlock>
            <ng-container *ngFor="let item of labelKeys; let i = index">
              <li *ngIf="purchaseDetails[0][item]">{{ labelValues[i] }}: ${{ purchaseDetails[0][item] | number : '1.2-2' }}</li>
            </ng-container>
          </ng-template>
        </ul>
        <p class="margin-vertical-20" *ngIf="purchaseDetails[0]['syncSource'] !== 'rpi'">The price listed excludes applicable sales tax.</p>
        <p class="margin-vertical-20">
          There are no changes to your coverage amounts or terms and conditions. Your Program's details and Terms and Conditions can be viewed by
          logging into your online account or by visiting <a href="https://www.awrusa.com/terms">awrusa.com/terms</a>. Questions? Call
          <a href="tel:{{ purchaseDetails[0]['customerCareNumber'] }}">{{ purchaseDetails[0]['customerCareNumber'] }}</a
          >.
        </p>
        <div>
          <p class="margin-vertical-20">Thank you for being our customer. It's our privilege to serve you.</p>
          <p>Sincerely, <br />Customer Care Team</p>
        </div>
      </div>
      <div>
        <p class="company-about-disclaimer text-justify">
          American Water Resources is a trademark of American Water and used under license, but is not owned by American Water. As such, AWR’s
          optional programs are not provided or guaranteed by American Water or its affiliates, and any changes in AWR’s prices are not determined or
          controlled by American Water or its affiliates.
        </p>
      </div>
    </div>
  </div>
</section>

<hos-page-not-found404 *ngIf="custDetailsNotFoundComp"></hos-page-not-found404>

<div [ngClass]="{ loading: showLoader }"></div>
