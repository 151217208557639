import { Component, HostListener, OnInit, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { find } from 'lodash-es';
import { SimpleModalComponent, SimpleModalService } from 'ngx-simple-modal';
import { SiteContentService } from '../../services/siteContents';
import { appConfig } from '../../appConfig/config';
import { UserService } from '../../../user/services/user.service';
import { AlertService } from '../../services/alert.service';
import { environment } from '../../../../environments/environment';
import { ZipcodeService } from '../../services/zipcode.service';
import { WindowScrolling } from '../../services/window-scrolling.service';
import { SessionStorageService } from '../../services/session-storage.service';

export interface AddressModal {
  address: any;
  userId: any;
}

@Component({
  selector: 'hos-veiw-service-address',
  templateUrl: './veiw-service-address.component.html',
  styleUrls: ['./veiw-service-address.component.scss'],
})
export class VeiwServiceAddressComponent extends SimpleModalComponent<AddressModal, boolean> implements AddressModal, OnInit, OnDestroy {
  address: any;

  userId: any;

  updateResponse: any;

  editAddress: Boolean;

  isClicked: Boolean;

  states: any;

  stateCode: any;

  showLoader: Boolean = false;

  zipcodeValid: Boolean = false;

  customer_service_number: string;

  streetNumberValidation: string = appConfig.streetNumber;

  constructor(
    public simpleModalService: SimpleModalService,
    private siteContentService: SiteContentService,
    private userService: UserService,
    private alertService: AlertService,
    private zipcodeService: ZipcodeService,
    private windowScrolling: WindowScrolling,
    private sessionStorageService: SessionStorageService,
  ) {
    super();
    // this.windowScrolling.disable();
  }

  ngOnInit() {
    this.isClicked = false;
    this.customer_service_number = environment.customer_service_number;
    this.siteContentService.statesdata$().subscribe(states => {
      this.states = states;
      if (!this.states) {
        this.states = this.sessionStorageService.getItem('statesList') ? this.sessionStorageService.getItem('statesList', true) : states;
      }
    });
  }

  @HostListener('document:keyup', ['$event']) handleKeyUp(event) {
    if (event.keyCode === 27) {
      this.close();
      this.windowScrolling.enable();
    }
  }

  onZipcodeUpdated(data: any) {
    if (data) {
      this.address.billingAddress.zipcodeId = data.zipcodeId;
      this.address.billingAddress.locationCodeId = data.locationCodeId;
      this.zipcodeValid = true;
      this.stateCode = data.stateCode;
      if (this.address.billingAddress && this.states) {
        this.address.billingAddress.state = find(this.states, {
          key: data.stateCode,
        }).value;
      }
    } else {
      this.zipcodeValid = false;
    }
  }

  stateChangeHandler(event: any) {
    if (this.stateCode && this.stateCode !== event.key) {
      this.alertService.error(
        `Zipcode does not exist in selected state, Please select ${
          find(this.states, {
            key: this.stateCode,
          }).value
        } to proceed.`,
      );
    }
  }

  saveAddress(form: NgForm) {
    this.isClicked = true;
    if (form.valid && this.address.billingAddress.zipcode.length === 5) {
      this.zipcodeService.getZipcodeId(this.address.billingAddress.zipcode).subscribe(
        res => {
          if (res.body.zipCodeId && res.body.locationCodeId) {
            const response = {
              zipcodeId: res.body.zipCodeId,
              locationCodeId: res.body.locationCodeId,
              stateCode: res.body.serviceState,
            };
            this.onZipcodeUpdated(response);
            if (form.valid && this.zipcodeValid) {
              if (
                find(this.states, {
                  key: this.stateCode,
                }).value === this.address.billingAddress.state
              ) {
                this.showLoader = true;
                this.isClicked = false;
                // eslint-disable-next-line no-underscore-dangle
                delete this.address.billingAddress._id;
                const payload = {
                  billingAddress: this.address.billingAddress,
                };
                this.updateAddress(payload);
              } else {
                this.alertService.error(
                  `Zipcode does not exist in selected state, Please select ${
                    find(this.states, {
                      key: this.stateCode,
                    }).value
                  } to proceed.`,
                );
              }
            }
          }
        },
        () => {
          this.alertService.error('Error, Please Try Again Later');
        },
      );
    }
  }

  updateAddress(payload) {
    // eslint-disable-next-line no-underscore-dangle
    this.userService.updateCustomerBillingAddress(this.userId, this.address._id, payload).subscribe(
      res => {
        this.result = res.body;
        this.showLoader = false;
        if (res.status === 200) {
          this.alertService.success(res.body.responseDesc);
          setTimeout(() => {
            this.close();
          }, 2000);
        } else {
          this.alertService.error('Error while saving address, Please try again later');
        }
      },
      error => {
        this.showLoader = false;
        this.result = error.body;
        this.alertService.error(
          `We're sorry, we are experiencing technical difficulties. Please try again. If you continue to experience issues, please contact Customer Service at <span class="app-txt">${this.customer_service_number}</span> for assistance. Thank you.`,
        );
      },
    );
  }

  blurHandler(input: any) {
    input.isBlur = true;
  }

  closeModal() {
    this.close();
    this.windowScrolling.enable();
  }

  ngOnDestroy() {
    this.windowScrolling.enable();
  }
}
