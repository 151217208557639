<hos-success-alert life="2000" *ngIf="successCall"></hos-success-alert>
<div class="modal-dialog modal-custom-header renew-modal-class modal-content" [ngClass]="{ 'from-checkout-payment': isFromCheckout }">
  <div class="modal-header">
    <button type="button" class="close pull-right set-font" (click)="close()">
      <i class="fa fa-times close-icon" aria-hidden="true"></i>&nbsp;&nbsp;CLOSE
    </button>
    <div class="header-text auto-renew">Renewal Summary</div>
  </div>
  <div class="modal-body">
    <div class="card-body-box">
      <div *ngIf="!isFromCheckout" class="card-body row no-gutters MT-20">
        <div class="col-12">
          <p class="description-cls MB-5">
            Program Name:
            <strong>{{ serviceData.productDetails?.webProgramName || serviceData.productDetails?.customerProgramName }}</strong>
          </p>
        </div>
        <div class="col-12">
          <p class="description-cls">
            New {{ serviceData.billingFrequency }} Price:
            <strong>${{ taxData[0].TaxDetails.EnrollmentPrice }}</strong>
          </p>
        </div>
      </div>
      <div *ngIf="!isFromCheckout" class="card-body row no-gutters">
        <p class="description-cls" [innerHtml]="description"></p>
      </div>
      <div *ngIf="isFromCheckout">
        <div class="payment-body">
          <div class="row select-payment-method font-size-16 padding-left-0">
            <div class="col-6 col-md-6 mobile-full-width MB-15">Name of the Product :</div>
            <div class="col-6 col-md-6 font-weight-500 mobile-full-width MB-15">
              <strong>{{ serviceData?.productDetails?.webProgramName || serviceData?.productDetails?.customerProgramName }}</strong>
            </div>
            <div class="col-6 col-md-6 mobile-full-width MB-15">Select payment option:</div>
            <div class="col-6 col-md-6 font-weight-500 mobile-full-width MB-15">
              <div class="select-payment-mobile">
                <form>
                  <div class="row payment-option txt-right" style="display: flex; flex-direction: column">
                    <div class="col-6 padd0" *ngFor="let freq of billingfrequencyArr">
                      <div class="custom-radio">
                        <input
                          type="radio"
                          class="custom-control-input"
                          id="frequency-{{ freq }}-{{ tempId }}"
                          [value]="freq"
                          name="frequency"
                          [(ngModel)]="billingFrequencyCheckout"
                          (change)="setPaymentFrequency(freq)"
                        />
                        <label class="custom-control-label" for="frequency-{{ freq }}-{{ tempId }}">{{ freq }}</label>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="col-6 col-md-6 mobile-full-width">New {{ billingFrequencyCheckout }} Price:</div>
            <div class="col-6 col-md-6 font-weight-500 mobile-full-width MB-10">
              <strong
                >$ {{ taxData && taxData.length > 0 ? (taxData[0]?.TaxDetails?.Total | number : '1.2-2') : '' }}
                <span>(including sales tax)</span></strong
              >
            </div>
          </div>
          <div class="row select-payment-method">Select a Payment Method</div>
          <div
            class="payment-gateway mobile-padding marginTop-10 ets-payment-gateway-login-cart"
            [ngClass]="{ 'paymentMethodStyle px-5': !isFromCheckout, 'payment-method-style-checkout': isFromCheckout }"
          >
            <!-- <div
              *ngIf="!globalVariables.migratedToOracle"
              id="hp"
              style="display: block"
              data-show-Discover="false"
              data-allow-avs-skip="false"
              data-disable-autocomplete="true"
            ></div> -->
            <div class="payment-body">
              <div class="payment-gateway" style="padding: 15px">
                <div id="stripe-payment-renewal"></div>
              </div>
            </div>
          </div>
          <div class="row select-payment-method" [ngClass]="{ 'select-payment-method-quickcheckout': isFromCheckout }">
            <div class="authorize-text marginBottom-10">
              By clicking the “Submit Payment” button (above) I am providing my electronic signature to authorize the payments described above.
            </div>
            <div>
              <u>Protection Program. </u> If I have one or more Protection Programs in my cart above then by clicking “Enroll Now” above I acknowledge
              and agree (i) to the Terms and Conditions for the Program(s), which can be found at awrusa.com/terms (ii) that the Program(s) I selected
              will auto-renew on an annual basis until cancelled by me in accordance with the Terms and Conditions of the Program(s); (iii) that I
              meet the eligibility requirements in the Terms and Conditions; and (iv) that there is a 30 day waiting period before I can make a claim.
              There is NO 30-Day waiting period if you are renewing your existing Protection Program(s) before the expiration date.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div *ngIf="!isFromCheckout" class="cursor-pointer">
      <button class="btn print-btn text-uppercase" (click)="close()">CANCEL</button>
    </div>
    <div>
      <button *ngIf="!isFromCheckout" (click)="agreeToRenew(serviceData)" class="btn submit-btn app-btn mt-0 text-uppercase">Continue</button>
      <button *ngIf="isFromCheckout" (click)="close()" class="btn submit-btn app-btn mt-0 text-uppercase">Close</button>
    </div>
  </div>
</div>
<div [ngClass]="{ loading: showLoader }"></div>
