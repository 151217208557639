import { Injectable, EventEmitter } from '@angular/core';
import { Subject, BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class GlobalVariables {
  billingFrequency: string = 'Monthly';

  cartAmount = new EventEmitter<any>();

  customerGuid = new Subject<string>();

  claimResults: any;

  waterBillOnOff: boolean;

  selected_Customer_number: any;

  selected_customerGuid: any;

  territoryCustomerServiceNumber: string;

  getAllCustomerAddresses: any;

  categoriesBasedOnZipcode = new EventEmitter<any>();

  userProfileDetails: any;

  routeReferer: any;

  nonLoggedInProductDetails: any;

  nonLoggedInProductListingDetails: any;

  OOFSelected: any = {};

  isPropNotExist: any;

  isAccordionChanged: boolean = false;

  isRouteToMyAccount: boolean = false;

  fixedHeaderProdDetails: boolean = false;

  isAllReviewsClicked: boolean = false;

  myAccClickFromHeader = new EventEmitter<any>();

  myAccClicked: boolean = false;

  userLoggedIn = new EventEmitter<boolean>();

  showProviderPopup = new EventEmitter<any>();

  cartCheckout: any = [];

  showCheckoutPaymentReponsePage: boolean;

  checkoutOrderCuid: any;

  showErrorMessageOnCart: boolean;

  billingFrequencyCheckout: any = 'Monthly';

  fequencyChangeEvent = new EventEmitter<string>();

  triggerOtherRelatedProduct = new EventEmitter<any>();

  cartSwap = new EventEmitter<any>();

  removeProperty = new EventEmitter<any>();

  isSuccessCheckoutWebRegistration: boolean = false;

  locationData: BehaviorSubject<any> = new BehaviorSubject({});

  productData: BehaviorSubject<any> = new BehaviorSubject({});

  customerAccountOrderCuid: any;

  passNewRegisterCustomerAccount: any;

  oneTimeSuccessEvent = new EventEmitter<boolean>();

  isEnrollmentUpdatePage = new EventEmitter<boolean>();

  customerAccountEnrollmentGuid: any;

  showLazyLoadContent: Boolean = false;

  showStripePaymentBox: Boolean = false;

  paymentWidgetStripe: any = 'true';

  loadScriptsFunction = new EventEmitter<boolean>();

  scriptsLoaded: Boolean = false;

  nonLoggedInRenewedOrderIds: any = [];

  loggedInCustNotification = new EventEmitter<boolean>();

  locationData$(): Observable<any> {
    return this.locationData.asObservable();
  }

  productData$(): Observable<any> {
    return this.productData.asObservable();
  }
}
