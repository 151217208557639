import { Injectable } from '@angular/core';
import { PathLocationStrategy, Location } from '@angular/common';

@Injectable()
export class PartnerProviderClass extends PathLocationStrategy {
  paths: string[];

  location: Location;

  prepareExternalUrl(internal: string): string {
    const paths: string[] = internal.split('/').splice(0, 1);
    const myPages = ['service', 'products', 'user', 'cart', 'blog', 'page', 'payment', 'support', 'terms'];
    if (myPages.indexOf(paths[0]) < 0) {
      const basePath: string = (paths && paths[0]) || ''; // Default: my-account
      if (basePath !== '') {
        localStorage.setItem('providerState', basePath);
      } else {
        localStorage.setItem('providerState', '');
      }
      return this.getBaseHref() + basePath + internal;
    }
    localStorage.setItem('providerState', '');
    return this.getBaseHref() + internal;
  }
}
