import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpService } from '../../core/services/http';
import { SessionStorageService } from '../../core/services/session-storage.service';
import { environment } from '../../../environments/environment';
import { GlobalVariables } from '../../core/services/global-variables.service';

@Injectable()
export class LocationService {
  ZipcodeAndLocation: any;

  constructor(
    private router: Router,
    private http: HttpService,
    private sessionStorageService: SessionStorageService,
    private globalVariables: GlobalVariables,
  ) {}

  getLocationCode(zipcode: String) {
    return this.http.get(`${environment.Location_service_API_Endpoint}zip_codes/${zipcode}`).pipe(
      map(res => {
        return res;
      }),
    );
  }

  getLocationsContent(locationCodeId: String): Observable<any> {
    return this.http
      .get(`${environment.Location_service_API_Endpoint}location_codes/${locationCodeId}`)
      .pipe(
        map(res => {
          return res;
        }),
      )
      .pipe(catchError(err => throwError(() => err.error || JSON.stringify(err))));
  }

  getMicroSiteForLocation(locationCode: String): Observable<any> {
    return this.http.get(`${environment.Content_service_API_Endpoint}microsite/${locationCode}`).pipe(map(res => res));
  }

  getZipcodeAndLocation() {
    return this.ZipcodeAndLocation;
  }

  getLocationsDataByLocationCode(locationCode: string) {
    return this.http.get(`${environment.Location_service_API_Endpoint}location_codes?location_code=${locationCode.toUpperCase()}`).pipe(
      map(res => {
        return res;
      }),
    );
  }
}
