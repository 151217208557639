import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, DatePipe } from '@angular/common';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgSelectModule } from '@ng-select/ng-select';
import { NguCarouselModule } from '@ngu/carousel';
import { NgxPaginationModule } from 'ngx-pagination';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CoreModule } from '../core/index';
import { UsersRoutingModule } from './user-routing.module';
import { environment } from '../../environments/environment';

// Components
import { RegisterComponent } from './component/register/register.component';
import { ResetPasswordComponent } from './component/reset-password/reset-password.component';
import { SetPasswordComponent } from './component/set-password/set-password.component';
import { ChangePasswordComponent } from './component/change-password/change-password.component';
import { ActivateAccountComponent } from './component/activate-account/activate-account.component';
import { ProfileComponent } from './component/profile/profile.component';
import { AddPropertyComponent } from './component/add-property/add-property.component';
import { PasswordStrengthMeterComponent } from '../core/component/password-strength-meter/password-strength-meter.component';
import { PaymentHistoryComponent } from './component/payment-history/payment-history.component';
import { MyAccountAddressComponent } from './component/my-account-address/my-account-address.component';
import { ServiceDetailInfoComponent } from './component/service-detail-info/service-detail-info.component';
import { UpdateServiceDetailComponent } from './component/update-service-detail/update-service-detail.component';
import { ThingsYouCanDoComponent } from './component/things-you-can-do/things-you-can-do.component';
import { ClaimsSuccessComponent } from './component/claims-success/claims-success.component';
// tslint:disable-next-line:max-line-length
import { CustomerServiceValidationModalComponent } from './component/customer-service-validation-modal/customer-service-validation-modal.component';
import { CustomerPropertySelectionModalComponent } from './component/customer-property-selection-modal/customer-property-selection-modal.component';
import { AddressValidationModalComponent } from './component/address-validation-modal/address-validation-modal.component';
// import { SuccessAlertComponent } from '../core/component/success-alert/success-alert.component';
import { MyAccountNewHeaderComponent } from './component/my-account-new-header/my-account-new-header.component';
import { RenewNowModalComponent } from './component/renew-now-modal/renew-now-modal.component';
import { AutoRenewModalComponent } from './component/auto-renew-modal/auto-renew-modal.component';
import { LoginPageComponent } from './component/login-page-component/login-page.component';
import { NewForgotPasswordComponent } from './component/login-page-component/forgot-password/forgot-password.component';
import { MyAccountServicesRedesignComponent } from './component/my-account-services-redesign/my-account-services-redesign.component';
import { MyAccountNewHomeRedesignComponent } from './component/my-account-new-home-redesign/my-account-new-home-redesign.component';
import { MyAccountNewClaimsComponent } from './component/my-account-new-claims/my-account-new-claims.component';

// Services
import { UserService } from './services/user.service';
import { UserIdleModule } from './services/user-idle.module';
import { OtherRecommendedNewServicesComponent } from './component/other-recommended-new-services/other-recommended-new-services.component';
import { PipesModule } from '../pipes';
import { ResyncEnrollmentService } from './services/resync-enrollment.service';
import { CheckoutComponent } from './component/checkout/checkout.component';
import { CartDetailsComponent } from './component/cart-details/cart-details.component';
import { QuickCheckoutPaymentBoxComponent } from './component/quick-checkout-payment-box/quick-checkout-payment-box.component';
import { CheckoutEtsPaymentResponseComponent } from './component/quick-checkout-payment-response/quick-checkout-payment-response.component';
import { AuditService } from './services/audit.service';
import { IncreasedPriceCusInfoComponent } from './component/increased-price-cus-info/increased-price-cus-info.component';
import { PageNotFound404Module } from '../page-not-found404';
import { UpgradeServiceModalComponent } from './component/checkout/upgrade-service-modal/upgrade-service-modal.component';
import { ClaimRecommendServicesComponent } from './component/claim-recommended-services/claim-recommended-services.component';
import { CheckoutMyAccountServicesComponent } from './component/checkout-customer-accounts-activity/checkout-customer-accounts-activity.component';
import { QuickEnrollmentUpdateComponent } from './component/quick-enrollment-update/quick-enrollment-update.component';
import { GuestFormComponent } from './component/guest-form/guest-form.component';
import { ManagePaymentComponent } from './component/manage-payment/manage-payment.component';

@NgModule({
  entryComponents: [
    CustomerServiceValidationModalComponent,
    CustomerPropertySelectionModalComponent,
    AddressValidationModalComponent,
    UpgradeServiceModalComponent,
  ],
  declarations: [
    RegisterComponent,
    ChangePasswordComponent,
    ResetPasswordComponent,
    SetPasswordComponent,
    ActivateAccountComponent,
    ProfileComponent,
    AddPropertyComponent,
    PasswordStrengthMeterComponent,
    PaymentHistoryComponent,
    MyAccountAddressComponent,
    CustomerServiceValidationModalComponent,
    CustomerPropertySelectionModalComponent,
    ThingsYouCanDoComponent,
    AddressValidationModalComponent,
    MyAccountNewHeaderComponent,
    OtherRecommendedNewServicesComponent,
    ClaimsSuccessComponent,
    LoginPageComponent,
    NewForgotPasswordComponent,
    MyAccountNewHomeRedesignComponent,
    MyAccountServicesRedesignComponent,
    MyAccountNewClaimsComponent,
    CheckoutComponent,
    CartDetailsComponent,
    QuickCheckoutPaymentBoxComponent,
    CheckoutEtsPaymentResponseComponent,
    IncreasedPriceCusInfoComponent,
    ClaimRecommendServicesComponent,
    CheckoutMyAccountServicesComponent,
    QuickEnrollmentUpdateComponent,
    GuestFormComponent,
    UpgradeServiceModalComponent,
    ManagePaymentComponent,
  ],
  exports: [],
  imports: [
    CommonModule,
    PipesModule,
    RouterModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    UsersRoutingModule,
    CoreModule,
    NgSelectModule,
    NguCarouselModule,
    NgxPaginationModule,
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    UserIdleModule.forRoot({
      idle: environment.website_idle_timeout,
      timeout: 5,
      ping: 840,
    }),
    PageNotFound404Module,
  ],
  providers: [
    UserService,
    ServiceDetailInfoComponent,
    UpdateServiceDetailComponent,
    RenewNowModalComponent,
    AutoRenewModalComponent,
    DatePipe,
    ResyncEnrollmentService,
    AuditService,
  ],
})
export class UserModule {}
