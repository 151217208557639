export const jobTypes = [
  {
    programName: 'Cooling System Repair Program',
    programCode: 'CSRP',
    productId: 'd0cb9274-15d7-e411-9425-00155ddf6652',
    jobTypeID: '6CD5F45E-236C-E511-941F-00155D006B18',
  },
  {
    programName: 'Exterior Electric Line Protection Program',
    programCode: 'EELP',
    productId: '998d1eba-78f8-e611-941d-00155dbb1e24',
    jobTypeID: 'B55436D7-5B20-E811-9449-00155DDF67C1',
  },
  {
    programName: 'Gas Line Protection Program',
    programCode: 'GLPP',
    productId: 'd5cb9274-15d7-e411-9425-00155ddf6652',
    jobTypeID: 'A1722A09-5C73-E511-941F-00155D006B18',
  },
  {
    programName: 'Heating System Repair Program',
    programCode: 'HSRP',
    productId: 'd1cb9274-15d7-e411-9425-00155ddf6652',
    jobTypeID: '6ED5F45E-236C-E511-941F-00155D006B18',
  },
  {
    programName: 'In Home Plumbing Plus Program',
    programCode: 'EIHP',
    productId: 'd2cb9274-15d7-e411-9425-00155ddf6652',
    jobTypeID: '6AD5F45E-236C-E511-941F-00155D006B18',
  },
  {
    programName: 'In Home Plumbing Emergency Program',
    programCode: 'IHPP',
    productId: 'd3cb9274-15d7-e411-9425-00155ddf6652',
    jobTypeID: 'F8D4F45E-236C-E511-941F-00155D006B18',
  },
  {
    programName: 'Interior Electric Line Protection Program',
    programCode: 'ELPP',
    productId: 'd4cb9274-15d7-e411-9425-00155ddf6652',
    jobTypeID: 'ACD5F45E-236C-E511-941F-00155D006B18',
  },
  {
    programName: 'Leak Detection Device Purchase',
    programCode: 'LDDP',
    productId: '523bcc39-65b0-e811-943a-00155d006b95',
    jobTypeID: 'CDE81780-4D1D-E911-945F-00155D006BAA',
  },
  {
    programName: 'Septic Line Protection Program',
    programCode: 'SEPT',
    productId: 'd6cb9274-15d7-e411-9425-00155ddf6652',
    jobTypeID: '4ED5F45E-236C-E511-941F-00155D006B18',
  },
  {
    programName: 'Sewer Line Protection Program',
    programCode: 'SLPP',
    productId: 'd7cb9274-15d7-e411-9425-00155ddf6652',
    jobTypeID: '58D5F45E-236C-E511-941F-00155D006B18',
  },
  {
    programName: 'Surge Protection Program',
    programCode: 'SRG',
    productId: 'd8cb9274-15d7-e411-9425-00155ddf6652',
    jobTypeID: '5C8B0682-B0E4-E511-9428-00155D006B25',
  },
  {
    programName: 'Water Heater Repair and Replacement Program',
    programCode: 'HWHP',
    productId: '660ec33b-199d-e611-9417-00155d8ff122',
    jobTypeID: '0DCAF646-06C6-E711-9445-00155DDF67C1',
  },
  {
    programName: 'Water Line Protection Program',
    programCode: 'WLPP',
    productId: 'd9cb9274-15d7-e411-9425-00155ddf6652',
    jobTypeID: 'A6D5F45E-236C-E511-941F-00155D006B18',
  },
  {
    programName: 'Well Line and Pump Protection Program',
    programCode: 'WELL',
    productId: 'dacb9274-15d7-e411-9425-00155ddf6652',
    jobTypeID: '5ED5F45E-236C-E511-941F-00155D006B18',
  },
  {
    programName: 'In-Home Plumbing Clog Protection Program',
    programCode: 'IHCP',
    productId: 'd8fe151c-02f7-ea11-9474-00155d104f66',
    jobTypeID: '99869453-02F7-EA11-9474-00155D104F66',
  },
  {
    programName: 'Cooling Repair - Secondary System',
    programCode: 'CRSS',
    productId: 'cdacd5a6-cfdb-ec11-948c-00155dbb1e5f',
    jobTypeID: '6CD5F45E-236C-E511-941F-00155D006B18',
  },
  {
    programName: 'Cooling Repair - Primary System',
    programCode: 'CRPS',
    productId: 'b8ac2e48-cfdb-ec11-948c-00155dbb1e5f',
    jobTypeID: '6CD5F45E-236C-E511-941F-00155D006B18',
  },
  {
    programName: 'Preferred Cooling Protection - Primary System',
    programCode: 'CPRS',
    productId: '803dacc4-cfdb-ec11-948c-00155dbb1e5f',
    jobTypeID: '6CD5F45E-236C-E511-941F-00155D006B18',
  },
  {
    programName: 'Preferred Cooling Protection - Secondary System',
    programCode: 'CPSS',
    productId: '008362e5-cfdb-ec11-948c-00155dbb1e5f',
    jobTypeID: '6CD5F45E-236C-E511-941F-00155D006B18',
  },
];
