import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'hos-product-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['faq.component.scss'],
})
export class ProductFaqComponent implements OnChanges {
  @Input() productDetail: any;

  faqs: any = [];

  faqsHeading: String;

  selectedFAQ: number;

  ngOnChanges() {
    if (!!this.productDetail && !!this.productDetail.testimonials) {
      this.faqs = this.productDetail.faqs;
      for (let i = 0; i < this.faqs.length; i++) {
        if (this.faqs[i].answer) {
          this.faqs[i].answer = this.faqs[i].answer.replace(/<p[^>]*>/g, '').replace(/<\/p>/g, '');
        }
      }
      this.faqsHeading = this.productDetail.faqsHeading;
    }
  }

  changeFAQ(index) {
    this.selectedFAQ = index;
  }
}
