<div class="page-wrap gs-wrap row justify-content-center" *ngIf="data.length > 0">
  <div class="col-12 col-lg-10 mt-2 mb-3">
    <div class="card alert-message">
      <div class="card-body d-flex">
        <div>
          <i class="action-required status-icon fa fa-exclamation-triangle fa-lg" aria-hidden="true"></i>
        </div>
        <div class="ml-3">
          Action is required on one or more of your service addresses. Please click the property below to review outstanding items.
        </div>
      </div>
      <div class="row">
        <div class="col-12" *ngFor="let cus of data">
          <div class="address-header" (click)="onAddressClick(cus.customerNumber)">
            {{ cus.address?.streetNumber }} {{ cus.address?.streetName }}, {{ cus.address?.city }}, {{ cus.address?.state }}
            {{ cus.address?.zipcode }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
