import { Directive, ElementRef, AfterViewInit, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[hosAutoFocus]',
})
export class AutofocusDirective implements AfterViewInit {
  @Input() hosAutoFocus: String;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit() {
    this.el.nativeElement.focus({
      preventScroll: true,
    });
  }
}
