<div class="modal-dialog modal-content modal-background PD-0">
  <div class="modal-body">
    <div class="row">
      <div class="col-sm-12 service-header-box">
        <div class="service-header pull-left">
          <div class="pull-left col-9">
            <p class="title mb-0">Payment Details</p>
          </div>
        </div>
        <button type="button" class="close pull-right set-font" (click)="closeOneTimePayment()">
          <i class="fa fa-times" aria-hidden="true"></i>
          <span class="close-text">CLOSE</span>
        </button>
      </div>
    </div>
    <div>
      <div class="payment-body">
        <div class="row select-payment-method font-size-16 padding-left-0" *ngIf="products?.length >= 1">
          <div class="col-6 col-md-6 mobile-full-width">Name of the Product :</div>
          <div class="col-6 col-md-6 font-weight-500 mobile-full-width MB-10">
            {{ products[0]?.productDetails?.webProgramName || products[0]?.productDetails?.customerProgramName }}
          </div>
          <div class="col-6 col-md-6 mobile-full-width">Total outstanding payment due today:</div>
          <div class="col-6 col-md-6 font-weight-500 mobile-full-width MB-10">
            {{ getProductsTaxApiData ? '$ ' + (getProductsTaxApiData?.totalPastDue | number : '1.2-2') : ' ' }}
            <span> {{ getProductsTaxApiData && getProductsTaxApiData?.totalPastDue ? '(including sales tax)' : '' }}</span>
          </div>
        </div>
        <div class="row select-payment-method">Select a Payment Method</div>
        <!-- <div class="cc-method">
                        <div class="card-header-custom row card-list" *ngFor="let card of paymentCards;">
                            <div class="col-7 col-md-7 radio-st mobile-full-width">
                                <div class="custom-radio" *ngIf="card.CreditCardToken !== financialInstrumentToken">
                                    <input type="radio" [disabled]="(currentDate.getFullYear() > card.ExpirationDate.split('/')[1]) || (currentDate.getFullYear() >= card.ExpirationDate.split('/')[1] && currentDate.getMonth()+1 > card.ExpirationDate.split('/')[0])"
                                        class="custom-control-input" id="card-selection-{{card?.CreditCardToken}}"
                                        value="{{card?.CreditCardToken}}" name="cards" (click)="selectCard(card,'CC')">
                                    <label class="custom-control-label payment-label"
                                        for="card-selection-{{card?.CreditCardToken}}">
                                        <img src="/img/payment/credit-card.svg">  <span class="card-exp"> <strong>{{card?.CardType}}</strong>  XXXX <strong>{{card?.AccountNumberMasked}}</strong> </span>
                                    </label>
                                </div>
                                <div class="custom-radio" *ngIf="card.CreditCardToken === financialInstrumentToken">
                                    <input type="radio" disabled
                                        value="{{card?.CreditCardToken}}" name="cards">
                                    <label class="custom-control-label payment-label"
                                        for="card-selection-{{card?.CreditCardToken}}">
                                        <img src="/img/payment/credit-card.svg">  <span class="card-exp"> <strong>{{card?.CardType}}</strong>  XXXX <strong>{{card?.AccountNumberMasked}} </strong> (Already Being Used) </span>
                                    </label>
                                </div>
                            </div>
                            <div class="col-5 col-md-5 desktop-mode-only">
                                <div class="row">
                                    <div class="col-md-4">
                                        <span *ngIf="(currentDate.getFullYear() > card.ExpirationDate.split('/')[1]) || (currentDate.getFullYear() >= card.ExpirationDate.split('/')[1] && currentDate.getMonth()+1 > card.ExpirationDate.split('/')[0])" class="expired">
                                            Expired
                                        </span>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="row expiry-date">
                                            <div class="col-md-6">
                                                <span class="expiry-date-text">Month</span>
                                                <div class="expiry-date-item">
                                                    <span >{{card?.ExpirationDate.split('/')[0]}}</span>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <span class="expiry-date-text">Year</span>
                                                <div class="expiry-date-item">
							                        <span >{{card?.ExpirationDate.split('/')[1]}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 mobile-mode-only-exp ML-30">
                                <span *ngIf="(currentDate.getFullYear() > card.ExpirationDate.split('/')[1]) || (currentDate.getFullYear() >= card.ExpirationDate.split('/')[1] && currentDate.getMonth()+1 > card.ExpirationDate.split('/')[0])" class="expired">
                                    Expired
                                </span>
                            </div>
                            <div class="mobile-full-width mobile-mode-only mobile-cc">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="row expiry-date">
                                            <div class="col-6 mobile-cc-exp">
                                                <span class="expiry-date-text ML-0 MR-10-per">Month</span>
                                                <div class="expiry-date-item">
                                                    <span >{{card?.ExpirationDate.split('/')[0]}}</span>
                                                </div>
                                            </div>
                                            <div class="col-6 mobile-cc-exp">
                                                <span class="expiry-date-text ML-0 MR-10-per">Year</span>
                                                <div class="expiry-date-item">
                                                    <span >{{card?.ExpirationDate.split('/')[1]}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
        <div
          class="payment-gateway px-5 mobile-padding marginTop-10 paymentMethodStyle"
          [style.margin-top]="paymentCards.length > 0 ? '0px' : '29px'"
        >
          <!-- <div id="hp" style="display: block;" data-show-Discover="false" data-allow-avs-skip="false"
                        data-disable-autocomplete="true">
                    </div> -->
          <div id="stripe-payment"></div>
        </div>
        <div class="row select-payment-method">
          <div class="authorize-text marginBottom-10">
            By clicking the “Submit Payment” button (above) I am providing my electronic signature to authorize the payments described above.
          </div>
          <div>
            <u>Protection Program. </u> If I have one or more Protection Programs in my cart above then by clicking “Enroll Now” above I acknowledge
            and agree (i) to the Terms and Conditions for the Program(s), which can be found at awrusa.com/terms (ii) that the Program(s) I selected
            will auto-renew on an annual basis until cancelled by me in accordance with the Terms and Conditions of the Program(s); (iii) that I meet
            the eligibility requirements in the Terms and Conditions; and (iv) that there is a 30 day waiting period before I can make a claim. There
            is NO 30-Day waiting period if you are renewing your existing Protection Program(s) before the expiration date.
          </div>
        </div>
      </div>
    </div>
    <div [ngClass]="{ loading: showLoader || !sessionTokenGenerated }"></div>
  </div>
  <div class="modal-footer">
    <button class="cancel-button cursor-pointer" (click)="closeOneTimePayment()">CANCEL</button>
    <button *ngIf="setCard && setCard !== 'newCard'" (click)="useExistCard()" class="btn submit-btn pull-right update-button">SUBMIT</button>
  </div>
</div>
