import { NgModule } from '@angular/core';

import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { NguCarouselModule } from '@ngu/carousel';

import { CarouselModule } from 'ngx-bootstrap/carousel';
import { HomeModule } from '../home/index';
import { ProductModule } from '../product/product.module';
import { CartModule } from '../cart/cart.module';
import { CoreModule } from '../core/index';
import { ProductDetailModule } from '../product-details/product-details.module';
import { PipesModule } from '../pipes';
import { PageNotFound404Module } from '../page-not-found404/index';
// Route
import { ServicesGenericDetailRoutingModule } from './services-generic-detail-routing';

// Components
import { ServiceLandingComponent } from './component/services-landing/service-landing.component';
import { ServicesGenericDetailsBodyComponent } from './component/body/body.component';

@NgModule({
  declarations: [ServicesGenericDetailsBodyComponent, ServiceLandingComponent],
  exports: [ServicesGenericDetailsBodyComponent, ServiceLandingComponent],
  imports: [
    CommonModule,
    PipesModule,
    RouterModule,
    ServicesGenericDetailRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    NguCarouselModule,
    HomeModule,
    ProductModule,
    CartModule,
    CoreModule,
    ProductDetailModule,
    PageNotFound404Module,
    CarouselModule,
  ],
  providers: [TitleCasePipe],
})
export class ServicesGenericDetailModule {}
