<div class="modal-dialog modal-content modal-background upgrade-service-detail">
  <div class="modal-body">
    <div class="row">
      <div class="col-sm-12 service-header-box">
        <div class="service-header pull-left">
          <div class="pull-left col-9">
            <p class="title mb-0">Upgrade Service</p>
          </div>
        </div>
        <button type="button" class="close pull-right set-font" (click)="close()">
          <i class="fa fa-times" aria-hidden="true"></i>
        </button>
      </div>
    </div>
    <div class="product-list row" *ngIf="upgradeProducts?.length > 0">
      <div class="col-12 cardSection">
        <div *ngFor="let product of upgradeProducts; let i = index" class="upgradeProduct col-lg-4">
          <hos-product-card-upgrade
            [index]="i"
            [productcountid]="i + 1"
            [locData]="locData"
            [product]="product"
            [zipcode]="zipcode"
            [locationId]="locationId"
            [allStates]="allStates"
            [refCheck]="myServicePage"
            [locationCodeData]="locationCodeData"
            [shareIconIndexArray]="shareIconIndexArray"
            [showOldPrice]="setOldPriceFlag"
          ></hos-product-card-upgrade>
        </div>
      </div>
    </div>
    <div class="row no-upgrade-option" *ngIf="upgradeProducts?.length === 0">
      <div class="col-12 product-item">No Upgrade options are available.</div>
    </div>
    <!-- <div class="modal-footer login-footer">

            </div> -->
    <div [ngClass]="{ loading: showLoader }"></div>
  </div>
</div>
