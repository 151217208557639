import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpService } from '../../core/services/http';
import { environment } from '../../../environments/environment';
import { GlobalVariables } from '../../core/services/global-variables.service';

@Injectable()
export class OrdersService {
  public constructor(private http: HttpService, private globalVariables: GlobalVariables) {}

  /**
   * Returns a orders details with products.
   * @param orderId {string}
   * @returns {Observable<any>}
   * @memberof OrdersService
   */
  getOrderByOrderId(orderId: any, customerId: String, guestToken?: any): Observable<any> {
    const options = guestToken ? { headers: { authorization: guestToken } } : undefined;
    const apiUrl = `${environment.Orders_service_API_Endpoint}customer/${customerId}/order/${orderId}`;
    return this.http.get(apiUrl, options).pipe(map(res => res));
  }

  /**
   * Creates a orders details with products.
   * @param order {string}
   * @param orderType {string}
   * @returns {Observable<any>}
   * @memberof OrdersService
   */
  createOrder(order: any, orderType: String, customerId: String, token?): Observable<any> {
    const apiUrl = `${environment.Orders_service_API_Endpoint}customer/${customerId}/enrollment/${orderType}`;
    return this.http
      .post(apiUrl, order, {
        authorization: token,
      })
      .pipe(map(res => res));
  }

  /**
   * Returns a orders details with products.
   * @param customerId {string}
   * @returns {Observable<any>}
   * @memberof OrdersService
   */
  getOrderByCustomerId(customerId: any, page: any): Observable<any> {
    const apiUrl = `${environment.Orders_service_API_Endpoint}customer/${customerId}/enrollment?page=${page}&isActive=true`;
    return this.http.get(apiUrl).pipe(map(res => res));
  }

  /**
   * Returns a orders details with products.
   * @param customerId {string}
   * @returns {Observable<any>}
   * @memberof OrdersService
   */
  getOrderByAddress(customerId: any, addressQuery?: any, token?): Observable<any> {
    const options = token ? { headers: { authorization: token } } : undefined;
    const apiUrl = `${environment.Orders_service_API_Endpoint}customer/${customerId}/enrollment/order/products${addressQuery}`;
    return this.http.get(apiUrl, options).pipe(map(res => res));
  }

  /**
   * Get customer Order history Summary
   *
   * @returns {Observable<any>}
   *
   * @memberof UserService
   */
  getOrderHistorySummary(customerId: string): Observable<any> {
    const apiUrl = `${environment.Orders_service_API_Endpoint}customer/${customerId}/enrollment/order/history`;
    return this.http.get(apiUrl).pipe(map(res => res));
  }

  /**
   * Get customer Order template
   *
   * @returns {Observable<any>}
   *
   * @memberof UserService
   */
  getOrderTemplate(enrollId: string, customerId: String, enrollNo?: string, webEnrollmentId?: string): Observable<any> {
    let apiUrl = `${environment.Orders_service_API_Endpoint}customer/${customerId}/order/${enrollId}/email-template`;
    if (enrollId.includes('Existing-') && enrollNo && webEnrollmentId) {
      apiUrl = `${environment.Orders_service_API_Endpoint}customer/${customerId}/order/${enrollId}/email-template/${enrollNo.trim()}`;
    }
    return this.http.get(apiUrl).pipe(map(res => res));
  }

  /**
   * Send enrollment email
   * @returns {Observable<any>}
   * @memberof OrderService
   */
  sendEnrollmentEmail(orderId: string, stateData: any, customerId: String): Observable<any> {
    let payload = {};
    if (stateData && stateData.partner && stateData.partner.siteName) {
      payload = stateData;
    }
    const apiUrl = `${environment.Orders_service_API_Endpoint}customer/${customerId}/order/${orderId}/send-email`;
    return this.http.post(apiUrl, payload).pipe(map(res => res));
  }

  /**
   * Send ets response Data to order service
   * @returns {Observable<any>}
   * @memberof OrderService
   */
  dataFromEts(etsData: any, customerId: String, guestToken?: any): Observable<any> {
    const syncOrigin: any = '/oracle';
    let obj = {};
    if (guestToken) {
      obj = {
        authorization: guestToken,
      };
    }
    const apiUrl = `${environment.Orders_service_API_Endpoint}customer/${customerId}/enrollment/offbill-payment${syncOrigin}`;
    return this.http.post(apiUrl, etsData, obj).pipe(map(res => res));
  }

  /**
   * Update in Auto Renew
   * @param enrollemenntguid
   * @returns {http response}
   */
  public updateAutoRenew(enrollemenntguid: string) {
    return this.http.put(`${environment.Orders_service_API_Endpoint}orders/updateautorenew/${enrollemenntguid}`, {}).pipe(map(res => res));
  }

  /**
   * retreive order data
   * @returns {Observable<any>}
   * @memberof OrderService
   */
  getOrderBycrmEnrollmentGuid(crmEnrollmentGuid: any, guestToken?: any): Observable<any> {
    const apiUrl = `${environment.Orders_service_API_Endpoint}orders/${crmEnrollmentGuid}`;
    return this.http
      .get(apiUrl, {
        authorization: guestToken,
      })
      .pipe(map(res => res));
  }

  /**
   * Send ets response Data to order service
   * @returns {Observable<any>}
   * @memberof OrderService
   */
  changePaymentPlan(orderDetails: any, customerId: String): Observable<any> {
    const apiUrl = `${environment.Orders_service_API_Endpoint}customer/${customerId}/enrollment/change-paymentplan`;
    return this.http
      .post(apiUrl, orderDetails)
      .pipe(map(res => res))
      .pipe(catchError((e: any) => throwError(() => e)));
  }

  oneTimePayment(payload: any, token?): Observable<any> {
    const apiUrl = `${environment.Orders_service_API_Endpoint}orders/oneTimePayment/${payload.crmEnrollmentGuid}`;
    return this.http
      .post(apiUrl, payload, {
        authorization: token,
      })
      .pipe(map(res => res));
  }

  oracleChangePaymentPlan(orderDetails: any, customerId: String): Observable<any> {
    const apiUrl = `${environment.Orders_service_API_Endpoint}customer/${customerId}/enrollment/oracle/change-paymentplan`;
    return this.http
      .post(apiUrl, orderDetails)
      .pipe(map(res => res))
      .pipe(catchError((e: any) => throwError(() => e)));
  }

  genericOneTimePayment(address, customerId, customerNo, financialInstrumentToken) {
    const options = {
      address,
      customerId,
      customerNo,
      financialInstrumentToken,
    };
    return this.http.post(`${environment.Orders_service_API_Endpoint}orders/one-time-payment`, options).pipe(map(res => res));
  }

  oneTimePaymentOracle(payload: any, token?): Observable<any> {
    const apiUrl = `${environment.Orders_service_API_Endpoint}orders/oracle/oneTimePayment/${payload.crmEnrollmentGuid}`;
    return this.http
      .post(apiUrl, payload, {
        authorization: token,
      })
      .pipe(map(res => res));
  }

  /**
   * get a alert info for customerId.
   * @param customerId {string}
   * @param customerNos {object}
   * @returns {Observable<any>}
   * @memberof OrdersService
   */
  customerAlertInfo(customerNos: any, customerId: String): Observable<any> {
    const apiUrl = `${environment.Orders_service_API_Endpoint}customer/${customerId}/enrollment/order/products/alert`;
    return this.http.post(apiUrl, customerNos).pipe(map(res => res));
  }

  /**
   * get a notification info for orders.
   * @param customerId {string}
   * @returns {Observable<any>}
   * @memberof OrdersService
   */
  customerNotification(customerId: string): Observable<any> {
    const apiUrl = `${environment.Orders_service_API_Endpoint}customer/${customerId}/enrollment/order/products/notification`;
    return this.http.get(apiUrl);
  }

  /**
   * get a alert info for customerId.
   * @param customerId {string}
   * @returns {Observable<any>}
   * @memberof OrdersService
   */
  getProductIcons(customerId: String, token?): Observable<any> {
    const apiUrl = `${environment.Orders_service_API_Endpoint}customer/${customerId}/enrollment/order/productIcons`;
    return this.http
      .get(apiUrl, {
        headers: { authorization: token },
      })
      .pipe(map(res => res));
  }

  /**
   * Cancel Enrollment Request
   * @returns {Observable<any>}
   * @param enrollementguid
   * @memberof OrdersService
   */
  cancelEnrollmentRequest(service: any, payload: any): Observable<any> {
    return this.http
      .put(
        `${environment.Orders_service_API_Endpoint}customer/${service.customerId}/cancel-enrollment/cancel-request/${service.crmEnrollmentGuid}`,
        payload,
      )
      .pipe(map(res => res));
  }

  updateIsVisitedKey(orderId: any): Observable<any> {
    return this.http.put(`${environment.Orders_service_API_Endpoint}orders/updateVisited/${orderId}`, {}).pipe(map(res => res));
  }
}
