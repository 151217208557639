<section
  class="all-reviews content-block content-section shaded-white review-section PB-0"
  id="all-reviews-section"
  [ngClass]="{ 'pad-top': globalVariables.fixedHeaderProdDetails }"
>
  <article class="page-wrap row text-center-back">
    <div>
      <span>
        <a class="back-to-product" (click)="backToProduct()"> Back to Product<i class="fa fa-share fa-lg fa-flip-horizontal arrowDesign"></i> </a>
      </span>
      <div class="clearfix"></div>
    </div>
  </article>
  <article class="page-wrap row">
    <div class="heading">
      <h2 class="section-head-title font-unbounce-title">Reviews</h2>
      <div class="clearfix"></div>
    </div>
  </article>

  <article class="page-wrap">
    <div class="no-review" *ngIf="reviews?.length === 0">
      <div class="row except-desktop-disp-flex">
        <div class="col-sm-6 col-sm-offset-3">
          <div class="no-review-bg">
            <div class="comment-icon">
              <img src="img/review/comment.svg" />
              <p class="no-review-text-1">No Reviews Yet</p>
              <p class="no-review-text-2">Be the first to review this product</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="review-sec except-desktop-disp-flex-main" [ngClass]="{ 'display-flex-cls': reviews?.length === 0 }">
      <div class="row except-desktop-disp-flex" [ngClass]="{ 'display-flex-cls': reviews?.length === 0 }">
        <div class="search-select">
          <select (change)="sortReviews($event.target.value)" [(ngModel)]="selectedSortReview">
            <option [value]="i" *ngFor="let item of sortReviewsBy; let i = index">{{ item.name }}</option>
          </select>
        </div>
        <div class="col-sm-11 review-prog-comment PD-0" id="all-review-comments">
          <div class="col-sm-12 review-card" *ngFor="let review of reviews | paginate : pagingConfig">
            <div class="review-container">
              <div class="min-height-45 display-flex">
                <div class="rating col-md-3" id="rating">
                  <span
                    *ngFor="let i of numbers"
                    class="fa"
                    [ngClass]="{ 'fa-star': i <= review.productRating, 'fa-star-o': i > review.productRating }"
                  ></span>
                </div>
                <div class="review-title col-md-7 col-sm-12 col-xs-12">{{ review.reviewTitle }}</div>
              </div>
              <div
                class="review-comment"
                *ngIf="review.productComment !== null && review.productComment !== undefined && review.productComment !== ''"
              >
                {{ review.productComment }}
              </div>
              <div class="reviewer-dtls">
                <span>by {{ review.customerName }}</span>
                <span class="display-none">{{ review.custAddress }}</span>
                <span>{{ review.createdAt | date : 'longDate' }}</span>
              </div>
            </div>
          </div>
          <div
            class="col-12 col-sm-12 col-md-12 row justify-content-center MT-20"
            *ngIf="reviews?.length !== 0"
            [ngClass]="{ 'bg-border': totalReviews > 5 }"
          >
            <pagination-template
              #p="paginationApi"
              [id]="pagingConfig.id"
              (pageChange)="getProductsReviews($event, 'all-review-comments', sortQuery)"
            >
              <nav class="col-12">
                <ul class="pagination">
                  <li class="page-item" [class.disabled]="p.isFirstPage()">
                    <span *ngIf="p.getCurrent() !== 1">
                      <span class="page-link displayDesktop" (click)="p.previous()"
                        >&lt;&nbsp;
                        <div class="langConnfig">PREVIOUS</div>
                      </span>
                      <span class="page-link displayMobile" (click)="p.previous()">&lt;</span>
                    </span>
                  </li>
                  <li class="page-item" *ngFor="let page of p.pages" [class.active]="p.getCurrent() === page.value">
                    <span *ngIf="p.pages.length > 1">
                      <a class="page-link" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">{{ page.label }}</a>
                      <span class="page-link" *ngIf="p.getCurrent() === page.value">{{ page.label }}</span>
                    </span>
                  </li>
                  <li class="page-item" [class.disabled]="p.isLastPage()">
                    <span *ngIf="p.getCurrent() !== p.pages.length">
                      <a class="page-link displayDesktop" (click)="p.next()">
                        <div class="langConnfig">NEXT</div>
                        &nbsp;&gt;
                      </a>
                      <a class="page-link displayMobile" (click)="p.next()">&gt;</a>
                    </span>
                  </li>
                </ul>
              </nav>
            </pagination-template>
          </div>
        </div>
      </div>
    </div>
  </article>
</section>
<div class="custom-error-alert review-form">
  <section class="content-section review-form-sec" id="review-section">
    <article class="page-wrap mar-t-30 row text-center">
      <div class="col-12">
        <span class="form-head">Write a review</span>
      </div>
    </article>
    <article class="page-wrap">
      <form [formGroup]="allReviewsDtlForm" novalidate>
        <div class="modal-body">
          <div class="user-details" *ngIf="!userDetails">
            <div class="row">
              <div class="col-sm-6 col-xs-6 PL-15 PR-15">
                <div class="form-group required">
                  <label class="review-label control-label">First Name</label>
                  <input
                    class="form-control"
                    maxlength="25"
                    placeholder="Enter First Name"
                    formControlName="firstName"
                    type="text"
                    name="firstName"
                    required
                  />
                  <div class="invalid-field" *ngIf="allReviewsDtlForm.controls['firstName'].errors?.pattern">
                    <span>First Name is invalid.</span>
                  </div>
                  <div
                    class="invalid-field"
                    *ngIf="allReviewsDtlForm.controls['firstName'].touched && allReviewsDtlForm.controls['firstName'].errors?.required"
                  >
                    <span>First name required</span>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-xs-6 PL-15 PR-15">
                <div class="form-group required">
                  <label class="review-label control-label">Last Name</label>
                  <input
                    class="form-control"
                    maxlength="25"
                    placeholder="Enter Last Name"
                    formControlName="lastName"
                    type="text"
                    name="lastName"
                    required
                  />
                  <div class="invalid-field" *ngIf="allReviewsDtlForm.controls['lastName'].errors?.pattern">
                    <span>Last Name is invalid.</span>
                  </div>
                  <div
                    class="invalid-field"
                    *ngIf="allReviewsDtlForm.controls['lastName'].touched && allReviewsDtlForm.controls['lastName'].errors?.required"
                  >
                    <span>Last name required</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-xs-12 PL-15 PR-15">
                <div class="form-group required">
                  <label class="review-label control-label">Email:</label>
                  <input
                    class="form-control"
                    maxlength="50"
                    placeholder="Enter email address"
                    formControlName="email"
                    type="text"
                    name="email"
                    required
                  />
                  <div class="invalid-field" *ngIf="allReviewsDtlForm.controls['email'].errors?.pattern">
                    <span>Please enter a valid email.</span>
                  </div>
                  <div
                    class="invalid-field"
                    *ngIf="allReviewsDtlForm.controls['email'].touched && allReviewsDtlForm.controls['email'].errors?.required"
                  >
                    <span>Email required</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4 col-xs-4 PL-15 PR-15">
              <div class="form-group">
                <label class="review-label">Rating</label>
                <div id="full-stars" class="PD-6" style="padding: 3px">
                  <div class="rating-group">
                    <ng-container *ngFor="let i of numbers">
                      <label (mouseover)="onMouseover(i)" (mouseout)="onMouseout()" class="rating__label" for="rating-{{ i }}"
                        ><i
                          class="rating__icon rating__icon--star fa"
                          [ngClass]="{ 'fa-star': i <= allReviewsDtlForm.value.rating, 'fa-star-o': i > allReviewsDtlForm.value.rating }"
                        ></i
                      ></label>
                      <input
                        class="rating__input"
                        name="rating"
                        id="rating-{{ i }}"
                        value="{{ i }}"
                        type="radio"
                        formControlName="rating"
                        (click)="ratingSelect(i)"
                      />
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-8 col-xs-12 PL-15 PR-15">
              <div class="form-group">
                <label class="review-label">Title</label>
                <input
                  class="form-control"
                  maxlength="100"
                  placeholder="Write title here..."
                  formControlName="title"
                  type="text"
                  (change)="trimSpaceFromBothEnd()"
                />
              </div>
              <small
                id="titleHelp"
                class="form-text text-danger"
                *ngIf="
                  allReviewsDtlForm.controls['title'].invalid &&
                  (allReviewsDtlForm.controls['title'].dirty || allReviewsDtlForm.controls['title'].touched)
                "
              >
                <span *ngIf="allReviewsDtlForm.controls['title'].errors?.required">Please enter Title</span>
                <!-- <span *ngIf="allReviewsDtlForm.controls['title'].errors?.pattern">Title is invalid.</span> -->
              </small>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-xs-12 PL-15 PR-15">
              <div class="form-group">
                <label class="review-label">Review</label>

                <textarea
                  class="form-control textarea-description"
                  maxlength="250"
                  formControlName="description"
                  placeholder="Write review here..."
                  type="text"
                  (change)="trimSpaceFromBothEnd()"
                ></textarea>
                <span class="pull-right char-left"
                  ><em>{{ 250 - allReviewsDtlForm.value.description.length }} characters left</em></span
                >
              </div>
              <small
                id="descriptionHelp"
                class="form-text text-danger"
                *ngIf="
                  allReviewsDtlForm.controls['description'].invalid &&
                  (allReviewsDtlForm.controls['description'].dirty || allReviewsDtlForm.controls['description'].touched)
                "
              >
                <span *ngIf="allReviewsDtlForm.controls['description'].errors?.required">Please enter Review</span>
                <!-- <span *ngIf="allReviewsDtlForm.controls['description'].errors?.pattern">Review is invalid.</span> -->
              </small>
            </div>
          </div>
          <div class="row" *ngIf="!userDetails">
            <div class="col-sm-12 col-xs-12 PL-15 PR-15">
              <p class="mandatory-field-lable">All fields marked with * are required</p>
            </div>
          </div>
          <div class="row displayDeskTop">
            <div class="col-sm-12 col-xs-12 PL-15 PR-15">
              <div class="form-group">
                <div id="captcha" class="captcha"></div>
              </div>
            </div>
          </div>
          <div class="row displayPopUp">
            <div class="col-sm-12 col-xs-12 PL-15 PR-15">
              <div class="form-group">
                <div id="captchaMob" class="captchaMob"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <hos-success-alert life="2000"></hos-success-alert>
        </div>
        <div class="modal-footer mobile-footer">
          <button (click)="modalClose()" class="btn cancel-btn print-btn text-uppercase pull-left mobile-btn">Cancel</button>
          <button
            type="submit"
            [ngClass]="{ 'disable-cls': allReviewsDtlForm.invalid }"
            (click)="createReview()"
            class="btn submit-btn app-btn mt-0 text-uppercase pull-right mobile-btn"
          >
            Post Review
          </button>
          <div class="clearfix"></div>
        </div>
      </form>
    </article>
  </section>
</div>
<div [ngClass]="{ loading: showLoader }"></div>
