import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { PaginationInstance } from 'ngx-pagination';
import { OrdersService } from '../../../payment-ets/services/orders.service';
import { UserService } from '../../services/user.service';
import { Breadcrumb } from '../../../core/component/breadcrumb/breadcrumb.component';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'hos-payment-history',
  templateUrl: './payment-history.component.html',
  styleUrls: ['./payment-history.component.scss'],
})
export class PaymentHistoryComponent implements OnInit {
  userId: any;

  user: any;

  purchaseDetails: any = [];

  showLoader: boolean;

  today: any;

  breadcrumsConfig: Array<Breadcrumb> = [
    {
      title: 'Home',
      link: '/',
      active: false,
    },
    {
      title: 'Purchase History',
      link: '',
      active: true,
    },
  ];

  /* pagination realated */
  public pagingConfig: PaginationInstance = {
    id: 'order-list-paging',
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0,
  };

  constructor(private orderService: OrdersService, private userService: UserService, private datePipe: DatePipe) {}

  ngOnInit() {
    this.user = this.userService.getSignedInUser();
    this.userId = this.user.user._id;
    this.today = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:s', 'UTC');
    this.showLoader = true;
    this.getOrders(1);
  }

  getOrders(page: any) {
    this.showLoader = true;
    this.orderService.getOrderByCustomerId(this.userId, page).subscribe(
      data => {
        if (data.status === 200) {
          const orderData = data.body;
          this.purchaseDetails = orderData.result;
          this.pagingConfig.itemsPerPage = orderData.limit;
          this.pagingConfig.totalItems = orderData.total;
        }
        this.showLoader = false;
      },
      () => {
        this.showLoader = false;
      },
    );
  }

  downloadEnrolledPdf(enrollmentId) {
    this.getTemplateId(enrollmentId, templateId => {
      if (templateId) {
        this.getPdf(templateId);
      }
    });
  }

  getTemplateId(enrollId: any, done: any) {
    this.orderService.getOrderTemplate(enrollId, this.userId).subscribe(
      data => {
        const templateData = data.body;
        done(templateData[0].downloadId);
      },
      () => {
        done(null);
      },
    );
  }

  getPdf(templateId: any) {
    let event;
    const link = document.createElement('a');
    link.href = `${environment.Image_API_Endpoint}${environment.Template_service_API_Endpoint}download/${templateId}`;
    link.target = '_blank';
    link.download = `${templateId}.pdf`;
    if (document.createEvent) {
      event = document.createEvent('MouseEvent');
      event.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
    } else {
      event = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
      });
    }
    link.dispatchEvent(event);
  }

  goToPage(event) {
    this.getOrders(event);
    window.scrollTo(250, 200);
    this.pagingConfig.currentPage = event;
  }
}
