import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService } from './http';
import { environment } from '../../../environments/environment';

@Injectable()
export class ClaimsService {
  constructor(private router: Router, private http: HttpService) {}

  createClaims(claimsData): Observable<any> {
    const apiUrl = `${environment.Claims_service_API_Endpoint}claim?`;
    return this.http.post(apiUrl, claimsData).pipe(map(res => res));
  }

  getClaims(customerId): Observable<any> {
    const url = `${environment.Claims_service_API_Endpoint}claim?customerId=${customerId}`;
    return this.http.get(url).pipe(map(res => res));
  }

  getProductById(productIds): Observable<any> {
    const productIdString = productIds.join(',');
    const apiUrl = `${environment.Product_service_API_Endpoint}products/productId?productIdsString=${productIdString}`;
    return this.http.get(apiUrl).pipe(map(res => res));
  }

  /**
   *  Get customer claims by productID
   */
  getClaimsByProductId(customerId, productId): Observable<any> {
    const claimsByProductapi = `${environment.Claims_service_API_Endpoint}claim?customerId=${customerId}&productId=${productId}`;
    return this.http.get(claimsByProductapi).pipe(map(res => res));
  }

  /**
   *  Get all productID
   */
  getAllProductId(): Observable<any> {
    const claimsByProductapi = `${environment.Product_service_API_Endpoint}products`;
    return this.http.get(claimsByProductapi).pipe(map(res => res));
  }

  /**
   *  Get Claim Name using webClaimID
   */
  getClaimName(webClaimId): Observable<any> {
    const claimsByProductapi = `${environment.Claims_service_API_Endpoint}claim?webclaimid=${webClaimId}`;
    return this.http.get(claimsByProductapi).pipe(map(res => res));
  }

  /**
   *  Get Service Fee for Claims
   */
  getClaimsServiceFee(customerId, productId, enrollmentId): Observable<any> {
    const claimsServiceFeesApi = `${environment.Claims_service_API_Endpoint}claim/service-fees?customerId=${customerId}&productId=${productId}&enrollmentId=${enrollmentId}`;
    return this.http.get(claimsServiceFeesApi).pipe(map(res => res));
  }

  /**
   *  Get Claim using crmEnrollmentGuids
   */
  getTotalClaimsByEnrollmentGuids(crmEnrollmentGuids: string): Observable<any> {
    const payload: any = {
      enrollmentIds: crmEnrollmentGuids,
    };
    const apiUrl = `${environment.Claims_service_API_Endpoint}claim/counts`;
    return this.http.post(apiUrl, payload).pipe(map(res => res));
  }

  /**
   *  Get Claim using crmEnrollmentGuid
   */
  getClaimsByEnrollmentId(enrollmentId: string): Observable<any> {
    const apiUrl = `${environment.Claims_service_API_Endpoint}claim/enrollment/${enrollmentId}`;
    return this.http.get(apiUrl).pipe(map(res => res));
  }
}
