<section class="page-wrap google-review-container">
  <section class="google_review">
    <div class="review_inner">
      <div class="review_header">
        <div class="logo_block">
          <img src="/img/google-reviews/Google_logo.png" class="google_logo" alt="Google Logo" title="Google Logo" />
          <h4>Rating</h4>
        </div>
      </div>
      <div class="review_slider">
        <ngu-carousel [inputs]="googleReviewCarousel" [dataSource]="reviewsData?.reviews" class="google-reviewc-carousel">
          <button NguCarouselPrev class="slide_arrow leftRs">&lt;</button>
          <ngu-tile *nguCarouselDef="let reviews of reviewsData.reviews; let i = index" class="item bannerStyle">
            <div class="review_box">
              <div class="review_content">
                <h2 class="reviewer_name">{{ reviews.reviewerName }}</h2>
                <img
                  src="/img/google-reviews/rating-star/{{ reviews.reviewerRating }}.png"
                  class="review_rating"
                  alt="Rating Star"
                  title="Rating Star"
                />
                <p class="review_text">{{ reviews.reviewerComments }}</p>
              </div>
            </div>
          </ngu-tile>
          <button NguCarouselNext class="slide_arrow rightRs">&gt;</button>
        </ngu-carousel>
      </div>
    </div>
  </section>
</section>
