<hos-my-account-new-header
  [pageName]="true"
  [addPropertyPage]="isAddProperty"
  [cusNo]="selectedCustNo"
  [firstName]="userDetails?.user.firstName"
  [lastName]="userDetails.user.lastName"
  (addressHandler)="addressHandler($event)"
  (addPropertyHomeClick)="pageSwitch()"
></hos-my-account-new-header>
<section class="content-section content-block add-property-section shaded-white">
  <div class="row page-wrap">
    <div class="col-12 col-md-3 section-right">
      <hos-my-account-address [pageType]="'add-property'"></hos-my-account-address>
    </div>
    <div class="col-12 col-md-9 add-new-address-section">
      <form #addressForm="ngForm" novalidate (keypress)="formKeyPressHandler($event, true)">
        <hos-success-alert life="2000"></hos-success-alert>

        <!-- <div class="container prop-border-thick mb-4"
                    [ngClass]="{'active': (isPropertyExist === 'yes' || isPropertyExist === 'no')}">
                    <div class="form-group col-12 col-md-12 no-gutters pt-4">
                        <h3>
                            New Property
                        </h3>
                    </div>
                    <div class="col-12 mb-4 ">
                        <label class="is-this-property-cur col-12">Is this property currently enrolled in protection services?</label>
                        <div class="question-btns col-12 row pt-4">
                            <button (click)="propertyValidate('yes')" class="app-outline-btn-blue col-5"
                                [ngClass]="{'active': isPropertyExist === 'yes'}">
                                Yes
                            </button>
                            <button (click)="propertyValidate('no')" class="app-outline-btn-blue col-5 ml-auto ml-md-4"
                                [ngClass]="{'active': isPropertyExist === 'no'}">
                                No
                            </button>
                        </div>
                    </div>
                </div> -->

        <!-- address section wrapper div starts -->
        <div class="form-group" *ngIf="showPropForm && isPropertyExist !== 'yes'">
          <div class="homeowner-information">
            <div class="form-header d-flex">
              <img src="/img/MyProfile/Profile-Ico-01.svg" class="px-4 mt-3" />
              <span class="d-flex align-items-center">General Information</span>
            </div>
          </div>
          <div class="prop-border-thin p-2">
            <!--Customer Number form-->
            <div class="form-border py-4 px-3 align-form">
              <form #customerForm="ngForm" novalidate (keypress)="formKeyPressHandler($event)">
                <div class="row">
                  <div class="head-spacing col-12 col-md-12">
                    <span class="hr-line heading-text"> Please use your existing customer number in the field below.</span>
                  </div>
                  <div class="form-group col-12 col-md-6">
                    <label for="selectType" class="hr-line"
                      >Customer Number
                      <span class="pull-right tooltip-container cust-no-tooltip">
                        <i
                          class="fa fa-question-circle"
                          aria-hidden="true"
                          placement="top"
                          tooltip="Please register using CUS-XXXXXXXXXXXXXXXX format. Don't know your account number? Register below by using your Address information or call customer service at {{
                            customer_service_number
                          }}"
                        ></i>
                      </span>
                    </label>
                    <input
                      type="text"
                      class="form-control round-form"
                      name="accountNumber"
                      [pattern]="customer_number_regex"
                      ngModel
                      #accountNumber="ngModel"
                      maxlength="100"
                      [ngClass]="{ 'is-invalid': (accountNumber.dirty || invalidForm) && !accountNumber.valid }"
                      required
                      placeholder="CUS-XXXXXXXXXXXXXXXX"
                      (keyup)="onCustomerNumberChange()"
                    />
                    <small class="form-text text-danger" *ngIf="(accountNumber.dirty || invalidForm) && !accountNumber.valid"
                      >Please enter a properly formatted Customer Number - CUS-XXXXXXXXXXXXX</small
                    >
                    <small class="form-text text-danger" *ngIf="customerExistInHOS"
                      >This customer number you have entered is already registered.</small
                    >
                  </div>
                  <div class="form-group col-12 col-md-6 text-right p-0 mt-4">
                    <button
                      type="submit"
                      class="btn primary-btn block full-width m-b mr-3"
                      (click)="invalidForm = true; accountNumberKeyPressHandler(customerForm)"
                    >
                      Next >
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div class="col-12 col-md-12">
              <!-- <div class="form-divider">
                                <hr/>
                                <div class="circle"> <span>OR</span></div>
                            </div> -->
              <div class="hr-content">
                <span class="heading-text">If you do not know your customer number, please enter your information in the fields below.</span>
              </div>
            </div>

            <div class="property-type row col-12">
              <label class="col-12 mb-4 pt-3">Property Type</label>
              <div class="col-6 col-md-4 col-lg-3">
                <div class="custom-radio res-com-al">
                  <input
                    [disabled]="!isPropertyExist"
                    type="radio"
                    class="custom-control-input"
                    id="property-residential"
                    value="RESIDENTIAL"
                    name="contactType"
                    (click)="setContactType('RESIDENTIAL')"
                    checked
                  />
                  <label class="custom-control-label" for="property-residential">Residential</label>
                </div>
              </div>
              <div class="col-6 col-md-4 col-lg-3">
                <div class="custom-radio res-com-al">
                  <input
                    [disabled]="!isPropertyExist"
                    type="radio"
                    class="custom-control-input"
                    id="property-commercial"
                    value="COMMERCIAL"
                    name="contactType"
                    (click)="setContactType('COMMERCIAL')"
                  />
                  <label class="custom-control-label" for="property-commercial">Commercial</label>
                </div>
              </div>
            </div>
            <div class="col-12">
              <hr class="hr-line" />
            </div>

            <div class="commercial-info row col-12 pr-0" *ngIf="address.contactType === 'COMMERCIAL'">
              <div class="form-group col-12 col-xl-6 pr-0">
                <label for="selectType">Type of Business</label>
                <ng-select
                  [disabled]="!isPropertyExist"
                  placeholder="- Select a Type of Business -"
                  [(ngModel)]="business.businessType"
                  [ngClass]="{ 'is-invalid': isClicked && !businessType.valid }"
                  name="businessType"
                  #businessType="ngModel"
                  required
                >
                  <ng-option value="Food Service">Food Service</ng-option>
                  <ng-option value="Retail">Retail</ng-option>
                  <ng-option value="Automotive">Automotive</ng-option>
                  <ng-option value="Health Services">Health Services</ng-option>
                  <ng-option value="Professional Services">Professional Services</ng-option>
                  <ng-option value="Worship Center">Worship Center</ng-option>
                  <ng-option value="Rental Home/ Apartment">Rental Home/ Apartment</ng-option>
                  <ng-option value="VFW/ Union Hall/ Private Club">VFW/ Union Hall/ Private Club</ng-option>
                  <ng-option value="Manuftrg/ Distr/ Indust">Manuftrg/ Distr/ Indust</ng-option>
                  <ng-option value="Other">Other</ng-option>
                </ng-select>
                <small id="emailHelp" class="form-text text-danger" *ngIf="isClicked && !businessType.valid">Type of business is required.</small>
              </div>
              <div class="form-group col-12 col-xl-6 pr-0">
                <label for="selectType">Business Name</label>
                <input
                  [disabled]="!isPropertyExist"
                  type="text"
                  class="form-control round-form"
                  [ngClass]="{ 'is-invalid': isClicked && !businessName.valid }"
                  name="businessName"
                  required
                  [(ngModel)]="business.businessName"
                  #businessName="ngModel"
                />
                <small id="emailHelp" class="form-text text-danger" *ngIf="isClicked && !businessName.valid">Business name is required.</small>
              </div>
              <div class="form-group col-12 col-xl-6 pr-0">
                <label for="selectType">Contact Title</label>
                <ng-select
                  [disabled]="!isPropertyExist"
                  placeholder="- Select a Title -"
                  [(ngModel)]="business.contactTitle"
                  [ngClass]="{ 'is-invalid': isClicked && !contactTitle.valid }"
                  name="contactTitle"
                  #contactTitle="ngModel"
                  required
                >
                  <ng-option value="Tenant">Tenant</ng-option>
                  <ng-option value="Property Owner">Property Owner</ng-option>
                  <ng-option value="Management Company">Management Company</ng-option>
                </ng-select>
                <small id="emailHelp" class="form-text text-danger" *ngIf="isClicked && !contactTitle.valid">Contact title is required.</small>
              </div>
              <div class="form-group col-12 col-xl-6 pr-0">
                <label for="selectType">Business Phone</label>
                <input
                  [disabled]="!isPropertyExist"
                  type="text"
                  class="form-control round-form"
                  [ngClass]="{ 'is-invalid': isClicked && !businessPhone.valid }"
                  name="businessPhone"
                  required
                  [(ngModel)]="business.businessPhone"
                  #businessPhone="ngModel"
                  (blur)="blurHandler(businessPhone)"
                  (keypress)="numberOnly($event)"
                  maxlength="10"
                  minlength="10"
                />
                <small id="emailHelp" class="form-text text-danger" *ngIf="isClicked && !businessPhoneErrorMsg && !businessPhone.valid"
                  >Business phone is required.</small
                >
                <small id="emailHelp" class="form-text text-danger" *ngIf="businessPhone.isBlur || isClicked">{{ businessPhoneErrorMsg }}</small>
              </div>
            </div>

            <div class="col-12">
              <hr class="hr-line" *ngIf="address.contactType === 'COMMERCIAL'" />
            </div>
            <div class="commercial-info row col-12 pr-0">
              <div class="form-group col-12 col-md-6 pr-0">
                <label for="selectType">Address</label>
                <input
                  [disabled]="!isPropertyExist"
                  autocomplete="none"
                  type="text"
                  class="form-control round-form"
                  [ngClass]="{ 'is-invalid': isClicked && !streetNumber.valid }"
                  name="streetNumber"
                  required
                  [(ngModel)]="address.streetNumber"
                  (keyup)="getSuggestion($event)"
                  #streetNumber="ngModel"
                  pattern="{{ streetNumberValidation }}"
                />
                <small id="emailHelp" class="form-text text-danger" *ngIf="isClicked && address.streetNumber === ''">Address is required.</small>
                <small id="emailHelp" class="form-text text-danger" *ngIf="isClicked && !streetNumber.valid && address.streetNumber !== ''"
                  ><span *ngIf="streetNumber.hasError('pattern')">Address is invalid.</span
                  ><span *ngIf="!streetNumber.hasError('pattern')">Address is required.</span></small
                >
                <div class="showDropDownWrapper">
                  <div *ngIf="showDropDown" class="search-drop-down">
                    <div
                      (click)="selectValuePropertyExist(addr.street_line, addr.city)"
                      class="search-results"
                      *ngFor="let addr of suggestedAddress.suggestions"
                    >
                      <a [innerHTML]="addr.street_line + ', ' + addr.city"> {{ addr.street_line }}, {{ addr.city }} </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group col-12 col-md-6 pr-0">
                <label for="selectType">Apt., Unit, PO Box, etc.</label>
                <input
                  [disabled]="!isPropertyExist"
                  autocomplete="none"
                  type="text"
                  class="form-control round-form"
                  [ngClass]="{ 'is-invalid': isClicked && !streetName.valid }"
                  name="streetName"
                  [(ngModel)]="address.streetName"
                  #streetName="ngModel"
                />
                <small id="emailHelp" class="form-text text-danger" *ngIf="isClicked && !streetName.valid"
                  >Apt., Unit, PO Box, etc. is required.</small
                >
              </div>

              <div class="form-group col-12 col-md-6 pr-0">
                <label for="selectType">City</label>
                <input
                  type="text"
                  [disabled]="!isPropertyExist"
                  class="form-control round-form"
                  [ngClass]="{ 'is-invalid': isClicked && !city.valid }"
                  name="city"
                  required
                  [(ngModel)]="address.city"
                  #city="ngModel"
                />
                <small id="emailHelp" class="form-text text-danger" *ngIf="isClicked && !address.city">City address is required.</small>
              </div>
              <div class="form-group col-12 col-md-6 pr-0">
                <label for="selectType">Zipcode</label>
                <hos-zipcode
                  [disabled]="!isPropertyExist"
                  [isClicked]="isClicked"
                  [address]="address"
                  (notify)="checkForProviderZipUpdate($event)"
                ></hos-zipcode>
              </div>
              <div class="form-group col-12 col-md-6 pr-0">
                <label for="selectType">State</label>
                <ng-select
                  [disabled]="true"
                  [items]="states"
                  bindLabel="value"
                  bindValue="value"
                  [(ngModel)]="address.state"
                  (change)="stateChangeHandler($event)"
                  [ngClass]="{ 'is-disabled-state': true }"
                  name="state"
                  #state="ngModel"
                  class="skiptranslate state-arrow"
                  required
                >
                </ng-select>
              </div>
              <div class="form-group all-fields col-12 col-md-12">All fields are required</div>
              <div class="col-12 pr-0">
                <hr class="hr-line" />
              </div>
              <div class="form-group col-12 col-md-12 save-cust">
                <button
                  type="submit"
                  [disabled]="!isPropertyExist"
                  [ngClass]="{ 'is-disabled': !isPropertyExist }"
                  (click)="addNewServiceAddress(addressForm)"
                  class="btn primary-btn app-btn float-right"
                >
                  Save Property
                </button>
                <a class="float-right mt-4 cancel-address" [ngClass]="{ 'is-disabled-link': !isPropertyExist }" routerLink="/user/myaccount"
                  >CANCEL</a
                >
              </div>
            </div>
          </div>

          <div class="form-group col-12 col-md-12 pt-3">
            <p class="notes-properties">
              Notes:
              <br />
              Properties can not be edited. Please double check your information before save this new property. <br />
              If you need to edit or delete a property, please
              <a class="cancel-address" [routerLink]="contactLink">CONTACT US</a>
            </p>
          </div>
        </div>
        <!-- address section wrapper div ends -->
        <!--- register component html start --->
        <div class="form-group col-12 col-md-12 no-gutters homeowner-information" *ngIf="showPropForm && isPropertyExist === 'yes'">
          <div class="form-header row m-0">
            <img src="/img/MyProfile/Profile-Ico-01.svg" class="mt-auto px-4" />
            <span class="my-auto">General Information</span>
          </div>
          <div class="form-border py-4 align-form">
            <form #customerForm="ngForm" novalidate (keypress)="formKeyPressHandler($event)">
              <div class="row">
                <div class="form-group col-12 col-md-6">
                  <label for="selectType" class="hr-line"
                    >Customer Number
                    <span class="pull-right tooltip-container">
                      <i
                        class="fa fa-question-circle"
                        aria-hidden="true"
                        placement="top"
                        tooltip="Don’t know your account number, please call customer service {{ customer_service_number }}"
                      ></i>
                    </span>
                  </label>
                  <input
                    type="text"
                    class="form-control round-form"
                    name="accountNumber"
                    [pattern]="customer_number_regex"
                    ngModel
                    #accountNumber="ngModel"
                    maxlength="100"
                    [ngClass]="{ 'is-invalid': (accountNumber.dirty || invalidForm) && !accountNumber.valid }"
                    required
                    (keyup)="onCustomerNumberChange()"
                  />
                  <small class="form-text text-danger" *ngIf="(accountNumber.dirty || invalidForm) && !accountNumber.valid"
                    >Please enter a properly formatted Customer Number - CUS-XXXXXXXXXXXXX</small
                  >
                  <small class="form-text text-danger" *ngIf="customerExistInHOS">This customer number you have entered is already registered.</small>
                </div>
                <div class="form-group col-12 col-md-6 text-right p-0 mt-auto">
                  <button
                    type="submit"
                    class="btn primary-btn block full-width m-b mr-3"
                    (click)="invalidForm = true; accountNumberKeyPressHandler(customerForm)"
                  >
                    Next >
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div class="col-12 col-md-12">
            <hr class="hr-line" />
          </div>
          <form #customerAddressForm="ngForm" novalidate (keypress)="formKeyPressHandler($event)">
            <div class="customer-section mb-2 py-4 align-form">
              <h5 class="mb-5">
                <span class="pr-0 head-text-existing">Forgot your customer number? Complete the information below to continue registration</span>
              </h5>
              <div class="row">
                <div class="form-group col-12 col-md-6">
                  <label for="selectType">Address</label>
                  <input
                    type="text"
                    autocomplete="off"
                    class="form-control round-form"
                    [ngClass]="{ 'is-invalid': (streetNumberExisting.dirty || isClickedForm) && !streetNumberExisting.valid }"
                    pattern="{{ streetNumberValidation }}"
                    (keyup)="getSuggestion($event)"
                    name="streetNumberExisting"
                    required
                    [(ngModel)]="addressLookup.streetNumber"
                    #streetNumberExisting="ngModel"
                    maxlength="100"
                  />
                  <small
                    id="emailHelp"
                    class="form-text text-danger"
                    *ngIf="(streetNumberExisting.dirty || isClickedForm) && !streetNumberExisting.valid"
                    ><span *ngIf="streetNumberExisting.errors?.required">Address is required.</span
                    ><span *ngIf="streetNumberExisting.errors?.pattern">Address is invalid.</span></small
                  >
                  <div class="showDropDownWrapper">
                    <div *ngIf="showDropDown" class="search-drop-down">
                      <div
                        (click)="selectValue(addr.street_line, addr.city, 2)"
                        class="search-results"
                        *ngFor="let addr of suggestedAddress.suggestions"
                      >
                        <a [innerHTML]="addr.street_line + ', ' + addr.city"> {{ addr.street_line }}, {{ addr.city }} </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group col-12 col-md-6">
                  <label for="selectType">Apt., Unit, PO Box, etc.</label>
                  <input
                    type="text"
                    autocomplete="off"
                    class="form-control round-form"
                    [ngClass]="{ 'is-invalid': (streetNameExisting.dirty || isClickedForm) && !streetNameExisting.valid }"
                    name="streetNameExisting"
                    [(ngModel)]="addressLookup.streetName"
                    #streetNameExisting="ngModel"
                    maxlength="100"
                  />
                  <small id="emailHelp" class="form-text text-danger" *ngIf="(streetNameExisting.dirty || isClickedForm) && !streetNameExisting.valid"
                    >Apt., Unit, PO Box, etc. is required.</small
                  >
                </div>
                <div class="form-group col-12 col-md-6">
                  <label for="selectType">City</label>
                  <input
                    type="text"
                    class="form-control round-form"
                    [ngClass]="{ 'is-invalid': (cityExisting.dirty || isClickedForm) && !cityExisting.valid }"
                    name="cityExisting"
                    required
                    [(ngModel)]="addressLookup.city"
                    #cityExisting="ngModel"
                    maxlength="80"
                  />
                  <small id="emailHelp" class="form-text text-danger" *ngIf="(cityExisting.dirty || isClickedForm) && !cityExisting.valid"
                    >City address is required.</small
                  >
                </div>
                <div class="form-group col-12 col-md-6">
                  <label for="selectType">ZIP Code</label>
                  <hos-zipcode
                    [disabled]="isZipcodeReadOnly"
                    [isClicked]="isClickedForm"
                    [address]="addressLookup"
                    (notify)="onZipcodeUpdatedLookup($event)"
                  ></hos-zipcode>
                </div>
                <hr />
                <div class="form-group col-12 col-md-6">
                  <label for="selectType">Phone Number</label>
                  <input
                    type="text"
                    class="form-control round-form"
                    (blur)="blurHandler(phoneNumberExisting)"
                    (keypress)="keyPressHandler(phoneNumberExisting); numberOnly($event)"
                    maxlength="10"
                    minlength="10"
                    [ngClass]="{ 'is-invalid': (phoneNumberExisting.dirty || isClickedForm) && !phoneNumberExisting.valid }"
                    [pattern]="phone_number_regex"
                    name="phoneNumberExisting"
                    required
                    [(ngModel)]="addressLookup.phoneNumber"
                    #phoneNumberExisting="ngModel"
                  />
                  <small
                    id="emailHelp"
                    class="form-text text-danger"
                    *ngIf="isClickedForm && !phoneNumberExistingErrorMsg && !phoneNumberExisting.valid"
                    >Please enter valid phone number.</small
                  >
                  <small id="emailHelp" class="form-text text-danger" *ngIf="phoneNumberExisting.isBlur || isClickedForm">{{
                    phoneNumberExistingErrorMsg
                  }}</small>
                </div>
                <div id="service-address" class="form-group col-12 col-md-12">
                  <div class="custom-checkbox">
                    <input
                      type="checkbox"
                      id="authorize-check-exist"
                      name="authorizedTosenDMessages"
                      [(ngModel)]="addressLookup.isAuthorizedToSendMessage"
                      #authorizedTosenDMessages="ngModel"
                    />
                    <label for="authorize-check-exist"
                      >Yes, I authorize <span class="skiptranslate">American Water Resources</span> to send me text messages. Standard text-messaging
                      rates may apply.</label
                    >
                  </div>
                </div>
              </div>
              <label class="form-message set-align">All fields are required</label>
              <hr class="my-1" />
              <div class="form-group col-12 col-md-12 text-right p-0">
                <button
                  type="submit"
                  [disabled]="!zipcodeSet"
                  class="btn primary-btn block full-width m-b"
                  (click)="isClickedForm = true; accountNumberKeyPressHandler(customerAddressForm)"
                >
                  Next >
                </button>
              </div>
            </div>
          </form>
        </div>
        <!--- register component html end --->
      </form>
    </div>
  </div>
</section>

<div [ngClass]="{ loading: !!showLoader }"></div>
