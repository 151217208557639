import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpService } from '../../core/services/http';
import { environment } from '../../../environments/environment';

@Injectable()
export class AuditService {
  constructor(private http: HttpService) {}

  saveToAudit(auditData: any) {
    return this.http.post(`${environment.AUDIT_SERVICE_API_ENDPOINT}/hos-audit`, auditData).pipe(map(res => res));
  }
}
