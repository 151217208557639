import { Component, OnInit, Inject, Optional, PLATFORM_ID } from '@angular/core';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { TitleCasePipe, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { DotCMSService } from '../../../core/services/dotcms.service';
import { environment } from '../../../../environments/environment';
import { Breadcrumb } from '../../../core/component/breadcrumb/breadcrumb.component';
import { SessionStorageService } from '../../../core/services/session-storage.service';
import { LocationService } from '../../../home/services/location.service';
import { ProductService } from '../../../product/services/product.service';
import { SeoService } from '../../../core/services/seo.service';
import { SeoConfig } from '../../../core/model/seo-config.model';
import { DotCMSCategory } from '../../../core/model/dotcms-constants.model';
import { JsonLDService } from '../../../core/services/json-ld.service';
import { ZipcodeService } from '../../../core/services/zipcode.service';

@Component({
  selector: 'hos-static-page',
  templateUrl: './service-landing.component.html',
  styleUrls: ['./service-landing.component.scss'],
})
export class ServiceLandingComponent implements OnInit {
  showSubLink: Boolean = false;

  menuTitle: String;

  baseImage = '';

  categories: any;

  selectedPage: any = {};

  selectedAddress: any;

  urlPageParam = '';

  baseContentUrl = `${environment.API_Endpoint}${environment.Content_service_API_Endpoint}`;

  category = '';

  seoConfig: SeoConfig;

  breadcrumsConfig: Array<Breadcrumb> = [
    {
      title: 'Home',
      link: '/',
      active: false,
    },
    {
      title: 'Services',
      link: '',
      active: false,
    },
  ];

  showLoader: Boolean = false;

  logoURL: any;

  isbrowser = true;

  isserver = false;

  seoURLOrigin: any = '';

  seoURLHref: any = '';

  seoURLHostname: any = '';

  zipLocationCodeData: any = {};

  providerSelectionDetails: any = {};

  constructor(
    private productService: ProductService,
    private locationService: LocationService,
    private seoService: SeoService,
    private sessionStorageService: SessionStorageService,
    private router: Router,
    private dotCMSService: DotCMSService,
    private titleCasePipe: TitleCasePipe,
    private jsonLDService: JsonLDService,
    private zipcodeService: ZipcodeService,
    private activatedRoute: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Optional() @Inject('serverUrl') protected origin: string,
    @Optional() @Inject('pathname') protected pathname: string,
    @Optional() @Inject('hostData') protected hostData: string,
  ) {
    this.isbrowser = isPlatformBrowser(platformId);
    this.isserver = isPlatformServer(platformId);
    this.router.events.subscribe(val => {
      if (this.isbrowser) {
        this.seoURLOrigin = window.location.origin;
        this.seoURLHref = window.location.href;
        this.seoURLHostname = window.location.hostname;
      }
      if (this.isserver) {
        this.seoURLOrigin = this.origin;
        this.seoURLHref = this.origin + this.pathname;
        this.seoURLHostname = this.hostData;
      }
      if (val instanceof NavigationStart) {
        this.jsonLDService.removeStructuredData();
      }
    });
    this.logoURL = this.sessionStorageService.getItem('logoURL');
    const zipcodeParam = this.activatedRoute.snapshot.paramMap.get('zipcode');
    if (zipcodeParam) {
      this.zipLocationCodeData.zipcode = zipcodeParam;
      this.zipLocationCodeData.locationCodeId = this.sessionStorageService.getItem('location_code')
        ? this.sessionStorageService.getItem('location_code')
        : '';
      this.providerSelectionDetails = this.sessionStorageService.getItem('providerSelectionDetails')
        ? JSON.parse(this.sessionStorageService.getItem('providerSelectionDetails'))
        : {};
    } else {
      this.zipLocationCodeData = {};
    }
  }

  ngOnInit() {
    this.showLoader = true;
    if (Object.keys(this.zipLocationCodeData).length > 0 && this.zipLocationCodeData.zipcode !== '') {
      this.zipcodeService.getZipcodeId(this.zipLocationCodeData.zipcode).subscribe(
        res => {
          const zipcodeResponse = res.body;
          this.showLoader = false;
          const { locationCodeId } = zipcodeResponse;
          if (locationCodeId !== undefined) {
            this.showLoader = true;
            if (this.providerSelectionDetails.providerValue === undefined) {
              this.zipLocationCodeData.locationCodeId = locationCodeId;
            } else {
              this.zipLocationCodeData.locationCodeId = this.providerSelectionDetails.locationCodeId
                ? this.providerSelectionDetails.locationCodeId
                : this.sessionStorageService.getItem('location_code');
            }
            this.locationService.getLocationsContent(this.zipLocationCodeData.locationCodeId).subscribe(
              data => {
                const locationResponse = data.body;
                const { locationCode } = locationResponse;
                this.zipLocationCodeData.locationCode = locationCode.toLowerCase();
                this.locationService.getLocationsDataByLocationCode(locationCode).subscribe(
                  resData => {
                    const locationContentData = resData.body[0];
                    this.locationService.getMicroSiteForLocation(locationCode).subscribe(
                      dataMicroSite => {
                        this.showLoader = false;
                        const micrositeResponse = dataMicroSite.body;
                        const micrositeName = micrositeResponse.site.siteName;
                        this.zipLocationCodeData.state = micrositeName || '';
                        if (zipcodeResponse.serviceState === locationContentData.serviceState) {
                          this.getCategories(this.zipLocationCodeData);
                        } else {
                          this.getCategories();
                        }
                      },
                      () => {
                        this.showLoader = false;
                        this.getCategories();
                      },
                    );
                  },
                  () => {
                    this.showLoader = false;
                    this.getCategories();
                  },
                );
              },
              () => {
                this.showLoader = false;
                this.getCategories();
              },
            );
          }
        },
        () => {
          this.showLoader = false;
          this.getCategories();
        },
      );
    } else {
      this.showLoader = false;
      this.getCategories();
    }
  }

  goToCategory(redirctUrl) {
    let url;
    if (redirctUrl.includes('service')) {
      url = redirctUrl.toLowerCase();
    } else {
      url = redirctUrl;
    }
    url = `${window.location.origin}/${decodeURIComponent(url)}`;
    window.location.href = url;
  }

  setPageMetaData() {
    this.menuTitle = this.selectedPage.subTitle;
    this.seoConfig = {
      title: this.selectedPage.title,
      description: this.selectedPage.summary,
      keywords: `service line warranties, water line protection, sewer line protection,
      in home plumbing emergencies, electric line protection, power surge protection, heating system repair, cooling system repair`,
      image: '',
      slug: '',
      sitename: '',
      type: '',
      summary: '',
      url: '',
    };
  }

  addJsonLdScript() {
    const data = {
      '@context': 'http://schema.org',
      '@type': 'NewsArticle',
      mainEntityOfPage: {
        '@type': 'WebPage',
        '@id': this.seoURLHref,
      },
      isPartOf: {
        '@type': 'CreativeWork',
        name: 'American Water Resources',
        url: this.seoURLOrigin || 'https://www.awrusa.com/',
      },
      inLanguage: 'en',
      headline: this.selectedPage.title,
      description: this.selectedPage.summary,
      // "keywords":"",
      articleSection: this.selectedPage.contentType || 'Article',
      url: this.seoURLHref,
      image: this.baseImage,
      datePublished: '',
      dateModified: this.selectedPage.updatedDate ? this.selectedPage.updatedDate.toISOString() : '',
      articleBody: this.selectedPage.content ? this.selectedPage.content.replace(/<[^>]+>/g, '') : '',
      author: {
        '@type': 'Person',
        name: this.seoURLHostname || 'www.awrusa.com',
        image: this.logoURL,
      },
      publisher: {
        '@type': 'Organization',
        name: 'American Water Resources',
        url: this.seoURLOrigin || 'https://www.awrusa.com/',
        logo: {
          '@type': 'ImageObject',
          url: this.logoURL,
          width: 600,
          height: 60,
        },
      },
      associatedMedia: {
        '@type': 'imageObject',
        url: this.baseImage,
        caption: this.selectedPage.subTitle || this.selectedPage.title,
        description: this.selectedPage.subTitle || this.selectedPage.title,
        height: '900',
        width: '1200',
      },
    };
    this.jsonLDService.insertSchema(data);
  }

  getCategoryContentFromDotCMS(zipLocationCodeData?: any) {
    this.showLoader = true;
    this.dotCMSService.getContentByCategory(DotCMSCategory.SERVICE).subscribe(
      response => {
        this.selectedPage = response.data[0];
        let links;
        for (let i = 0; i < (this.categories ? this.categories.length : 0); i++) {
          if (this.categories[i].customerProgramName) {
            if (this.categories[i].customerProgramName.startsWith('Unlimited')) {
              const programName = this.categories[i].customerProgramName
                ? this.categories[i].customerProgramName.split('Unlimited ')[1]
                : this.categories[i].webProgramName.split('Unlimited ')[1];

              links = `${links}<li style="margin-bottom: 0px;"><a class="cursor" style="padding-left: 30px;" href="${`${
                zipLocationCodeData.state ? `${zipLocationCodeData.state}/` : ''
              }product/${zipLocationCodeData.zipcode}/${zipLocationCodeData.locationCode.toLowerCase()}/residential/${this.categories[
                i
              ].customerProgramName
                .trim()
                .split(' ')
                .join('-')
                .toLowerCase()}`}">${programName}</li></a>`;

              this.categories[i].hrefLink = `${`${zipLocationCodeData.state ? `${zipLocationCodeData.state}/` : ''}product/${
                zipLocationCodeData.zipcode
              }/${zipLocationCodeData.locationCode.toLowerCase()}/residential/${this.categories[i].customerProgramName
                .trim()
                .split(' ')
                .join('-')
                .toLowerCase()}`}`;
              this.categories[i].customerProgramName = programName;
            } else {
              const programName = this.categories[i].webProgramName
                ? this.categories[i].webProgramName.trim().split(' ').join('-').toLowerCase()
                : this.categories[i].customerProgramName.trim().split(' ').join('-').toLowerCase();

              links = `${links}<li style="margin-bottom: 0px;"><a class="cursor" style="padding-left: 30px;" href="${`${
                zipLocationCodeData.state ? `${zipLocationCodeData.state}/` : ''
              }product/${zipLocationCodeData.zipcode}/${zipLocationCodeData.locationCode.toLowerCase()}/residential/${programName}`}">${
                this.categories[i].webProgramName ? this.categories[i].webProgramName : this.categories[i].customerProgramName
              }</li></a>`;

              this.categories[i].hrefLink = `${`${zipLocationCodeData.state ? `${zipLocationCodeData.state}/` : ''}product/${
                zipLocationCodeData.zipcode
              }/${zipLocationCodeData.locationCode.toLowerCase()}/residential/${programName}`}`;
            }
          } else {
            links = `${links}<li style="margin-bottom: 0px;"><a class="cursor" style="padding-left: 30px;" href="${`${
              this.sessionStorageService.getItem('providerState')
                ? `${this.sessionStorageService.getItem('providerState')}/`
                : zipLocationCodeData && zipLocationCodeData.state
                ? `${zipLocationCodeData.state}/`
                : ''
            }service/${encodeURIComponent(this.categories[i].categoryName.split(' ').join('-').toLowerCase())}`}">${
              this.categories[i].categoryName
            }</li></a>`;

            this.categories[i].hrefLink = `${`${
              this.sessionStorageService.getItem('providerState')
                ? `${this.sessionStorageService.getItem('providerState')}/`
                : zipLocationCodeData && zipLocationCodeData.state
                ? `${zipLocationCodeData.state}/`
                : ''
            }service/${encodeURIComponent(this.categories[i].categoryName.split(' ').join('-').toLowerCase())}`}`;
          }
        }
        const categories = this.categories ? this.categories.length.toString() : this.categories;
        this.selectedPage.content = this.selectedPage.content.replace('$count$', categories);
        this.selectedPage.content = this.selectedPage.content.replace('$services$', links);
        this.baseImage = this.selectedPage.bannerImageURL
          ? this.dotCMSService.dotCMSImageById(this.selectedPage.bannerImageURL)
          : 'img/heating-banner.jpg';
        this.setPageMetaData();
        this.seoService.generateTitleTags(this.seoConfig);
        if (this.zipLocationCodeData?.zipcode) {
          this.seoService.metaSetNoIndexNoFollow();
        } else {
          this.seoService.setCanonicalTags();
        }
        this.addJsonLdScript();
        this.selectedPage.content = this.selectedPage.content.replace('undefined', '');
        this.showLoader = false;
      },
      () => {
        this.showLoader = false;
      },
    );
  }

  getCategories(zipLocationCodeData?: any) {
    this.showLoader = true;
    const locationCodeId = this.sessionStorageService.getItem('location_code') ? this.sessionStorageService.getItem('location_code') : '';
    if (((zipLocationCodeData && zipLocationCodeData.locationCodeId) || locationCodeId) !== '') {
      this.productService.getCategories(zipLocationCodeData ? zipLocationCodeData.locationCodeId : locationCodeId).subscribe(
        categories => {
          const categoriesData = categories.body;
          if (zipLocationCodeData) {
            const allProducts = true;
            this.productService
              .getMarketingCodes('', zipLocationCodeData ? zipLocationCodeData.locationCodeId : locationCodeId, allProducts)
              .subscribe(
                data => {
                  if (data.status === 200) {
                    const marketingCodeData = data.body;
                    const filteredMarketingCodeData = marketingCodeData.result.filter(p => p.contactType === 1);
                    if (filteredMarketingCodeData && filteredMarketingCodeData.length > 0) {
                      const filteredCategoriesData = filteredMarketingCodeData.filter(category => {
                        const resultCategoryData = categoriesData.filter(p => category.program.programId === p.reviewProgramId);

                        if (resultCategoryData.length > 0) {
                          return resultCategoryData;
                        }
                        return false;
                      });
                      this.categories = filteredCategoriesData;
                    } else {
                      this.categories = categoriesData;
                    }
                  } else {
                    this.categories = categoriesData;
                  }
                  this.showLoader = false;
                  this.getCategoryContentFromDotCMS(zipLocationCodeData);
                },
                error => {
                  console.log(error);
                  this.showLoader = false;
                  this.categories = categoriesData;
                  this.getCategoryContentFromDotCMS(zipLocationCodeData);
                },
              );
          } else {
            this.showLoader = false;
            this.categories = categoriesData;
            this.getCategoryContentFromDotCMS();
          }
        },
        error => {
          this.showLoader = false;
          console.log(error);
        },
      );
    } else {
      // this.productService.setCategories();
      this.productService.categories$().subscribe(
        categories => {
          this.showLoader = false;
          this.categories = categories.body;
          this.getCategoryContentFromDotCMS();
        },
        error => {
          this.showLoader = false;
          console.log(error);
        },
      );
    }
  }
}
