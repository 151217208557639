import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'hos-password-strength-meter',
  templateUrl: './password-strength-meter.component.html',
  styleUrls: ['./password-strength-meter.component.scss'],
})
export class PasswordStrengthMeterComponent implements OnChanges {
  @Input() password: string;

  @Input() confirmPassword: string;

  @Output() passwordMeterResult = new EventEmitter();

  criteria: Array<any> = [];

  constructor() {
    this.createCriteria('initial');
    this.passwordMeterResult.emit('initial');
  }

  /**
   * On changes of input values
   */
  ngOnChanges() {
    if (this.password || this.confirmPassword) {
      this.createCriteria('invalid');
      this.checkPasswordStrength(this.password, this.confirmPassword);
    } else {
      this.createCriteria('initial');
      this.passwordMeterResult.emit('initial');
    }
  }

  /**
   * Check password strength
   * @param {password}
   * @returns {object}
   */
  checkPasswordStrength(password, confirmPassword) {
    // Regular Expressions.
    const regex = [];
    regex.push('[A-Z]'); // Uppercase Alphabet.
    regex.push('[a-z]'); // Lowercase Alphabet.
    regex.push('[0-9]'); // Digit.
    regex.push('[!@#$%^&*()~`\\-_\\+]'); // Special Character.

    // Validate for each Regular Expression.
    for (let i = 0; i < regex.length; i++) {
      if (new RegExp(regex[i]).test(password)) {
        this.criteria.find(x => x.id === i).class = 'valid';
      }
    }

    // Validate for length of Password.
    if (password.length >= 8) {
      this.criteria.find(x => x.id === 4).class = 'valid';
    }

    // Validate for Confirm Password.
    if (password === confirmPassword) {
      this.criteria.find(x => x.id === 5).class = 'valid';
    }

    const regexWhiteSpace = /\s+/;
    if (regexWhiteSpace.test(password) || regexWhiteSpace.test(confirmPassword)) {
      this.criteria[6].class = 'invalid';
    } else {
      this.criteria[6].class = 'valid';
    }

    // Emit Result
    let result = 'true';
    this.criteria.forEach(element => {
      if (element.class === 'invalid') {
        result = 'false';
      }
    });
    this.passwordMeterResult.emit(result);
  }

  createCriteria(classname) {
    this.criteria = [
      {
        id: 0,
        class: classname,
        content: 'at least 1 uppercase letter',
      },
      {
        id: 1,
        class: classname,
        content: 'at least 1 lowercase letter',
      },
      {
        id: 2,
        class: classname,
        content: 'at least 1 numeric character',
      },
      {
        id: 3,
        class: classname,
        content: 'at least 1 special character(!@#$%^&*()~`_-+)',
      },
      {
        id: 4,
        class: classname,
        content: 'at least 8 characters',
      },
      {
        id: 5,
        class: classname,
        content: 'Password must match',
      },
      {
        id: 6,
        class: classname,
        content: 'Password should not contain any spaces',
      },
    ];
  }
}
