<section *ngIf="!loading" class="content-section shaded-grey terms-section" id="terms-section">
  <div class="banner-box">
    <div class="banner">
      <div class="content-box page-wrap">
        <div class="col-12 col-sm-12 col-md-12 col-lg-8">
          <h1>Sorry page not found</h1>
        </div>
      </div>
    </div>
  </div>

  <article class="content-block page-wrap terms-wrap">
    <div class="detail mb-4">
      Sorry, the page you are looking for cannot be found and might have been removed, had its name changed, or is temporarily unavailable. It is
      recommended that you start again from the homepage. Feel free to contact us if the problem persists or if you definitely cannot find what you’re
      looking for.
    </div>
  </article>
</section>
<input type="hidden" value="404-not-found" />
<div *ngIf="loading" class="loading-screen">
  <div [ngClass]="{ loading: loading }"></div>
</div>
