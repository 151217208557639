import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ProductDetailsBodyComponent } from './component/body/body.component';

export const productDetailRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: ':productName/:productId',
        component: ProductDetailsBodyComponent,
      },
    ],
  },
  {
    path: '',
    children: [
      {
        path: ':zipcode/:locationcode/:type/:programName',
        component: ProductDetailsBodyComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(productDetailRoutes)],
  exports: [RouterModule],
})
export class ProductDetailRoutingModule {}
