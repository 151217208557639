<section
  class="testimonial content-block content-section"
  [ngStyle]="{ 'background-image': bgImgTestimonials ? 'url(' + bgImgTestimonials + ')' : '' }"
>
  <div class="testimonial-page-wrap" *ngIf="testimonials?.length > 0">
    <ngu-carousel #myCarousel class="banner" [inputs]="testimonialCarousel" [dataSource]="testimonials">
      <ngu-tile *nguCarouselDef="let testimonial of testimonials; let i = index" class="item PD-0">
        <div class="col-12 col-sm-12 col-lg-12 testimonial-wrap">
          <div class="row">
            <div class="testimonial-content">
              <div class="banner-image" [style.background-image]="'url(' + testimonial?.image + ')'"></div>
              <div class="description">
                <p class="description-text">{{ testimonial?.description }}</p>
                <div class="from-txt">
                  <div class="from-txt-name">{{ testimonial?.name }}</div>
                  <div class="from-txt-place">{{ testimonial?.place }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ngu-tile>
      <button NguCarouselPrev class="leftRs">&lt;</button>
      <button NguCarouselNext class="rightRs">&gt;</button>
      <ul class="ngucarouselPoint" NguCarouselPoint>
        <li
          *ngFor="let j of myCarousel?.pointNumbers; let i = index"
          [class.active]="j === myCarousel.activePoint"
          (click)="myCarousel.moveTo(j)"
        ></li>
      </ul>
    </ngu-carousel>
  </div>
</section>
