<div class="top-navbar" [ngClass]="{ selected: isScrolled }">
  <div class="top-bar-text text-center col-12 fs-restrict-dead-clicks">
    <span class="head-text">Contact Us 24/7</span>
    <a
      href="tel:{{ showPriceInfoContactNo ? priceInfoContactNumber : siteContent?.contactNumber }}"
      angulartics2On="click"
      angularticsLabel="phone_header"
      angularticsValue="0"
      angularticsAction="click"
      angularticsCategory="call"
      ><span class="number-text">{{ showPriceInfoContactNo ? priceInfoContactNumber : siteContent?.contactNumber }}</span></a
    >
  </div>
</div>
