<div class="modal-dialog custom-error-alert modal-content modal-background">
  <div class="modal-header modal-custom-header error-modal-custom-header info-type">
    <div class="info-background mx-auto"></div>
    <!-- <h4 class="modal-title">
                <div class="{{alertIcon}} pt-1"></div>
                <div class="pl-2">{{title}}</div>
            </h4> -->
  </div>
  <div class="modal-body">
    <h4 class="mb-3">{{ title }}</h4>
    <div class="mb-3 pr-1 messageText">
      {{ message }}
    </div>
    <div class="row pt-3">
      <div class="col-12 mb-3 scroll" *ngFor="let service of data">
        <div class="custom-radio">
          <input
            type="radio"
            class="custom-control-input"
            id="{{ service.customerNo }}"
            value="{{ service.customerNo }}"
            name="serviceValidation"
            (click)="selectCode(service.customerNo)"
          />
          <label class="custom-control-label" for="{{ service.customerNo }}">
            <strong>{{ service.serviceAddress ? service.showAddress : 'Address not available at the moment' }}</strong>
          </label>
        </div>
      </div>
      <div class="col-12" *ngIf="error">
        <label class="text-danger">Please select one to proceed.</label>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <div>
      <button (click)="confirmHandler()" class="submit-btn block full-width app-btn mt-0">{{ confirmText || 'Ok' }}</button>
    </div>
  </div>
</div>
