import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ZipcodeService } from '../../services/zipcode.service';

@Component({
  selector: 'hos-zipcode',
  templateUrl: './zipcode.component.html',
  styleUrls: ['./zipcode.component.scss'],
})
export class ZipcodeComponent implements OnInit {
  @Input() address: any;

  @Input() disabled: Boolean = false;

  zipcode: string;

  @Input() isValid: boolean;

  @Input() isClicked?: boolean;

  @Output() notify: EventEmitter<any> = new EventEmitter<any>();

  notFound: Boolean = false;

  @Input() serviceUnavailable: Boolean = false;

  @Input() isFromCheckout: Boolean = false;

  constructor(private zipcodeService: ZipcodeService) {}

  ngOnInit() {
    if (this.address.zipcode) {
      this.zipcode = this.address.zipcode;
      this.locationCodeHandler(); /* HWR-1445 fixed state load issue after zipcode change */
    }
  }

  /* ngOnChanges() {
    if (this.address['zipcode']) {
      this.zipcode = this.address['zipcode'];
      this.locationCodeHandler();
    }
  } */

  onZipcodeChange(searchValue: String, input?: any) {
    this.address.zipcode = this.zipcode;
    this.notFound = false;
    if (input.valid) {
      this.locationCodeHandler();
    } else {
      this.serviceUnavailable = false;
      this.notify.emit(false);
    }
  }

  locationCodeHandler() {
    this.zipcodeService.getZipcodeId(this.zipcode).subscribe(
      res => {
        if (res.body.zipCodeId && res.body.locationCodeId) {
          const response = {
            zipcodeId: res.body.zipCodeId,
            locationCodeId: res.body.locationCodeId,
            stateCode: res.body.serviceState,
          };
          this.notify.emit(response);
        }
      },
      () => {
        this.notFound = true;
      },
    );
  }

  blurHandler(input: any) {
    input.isBlur = true;
  }

  keyPressHandler(input: any) {
    input.isBlur = false;
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  zipcodeChangedFromSuggestion(zipcode) {
    this.zipcode = zipcode;
    this.locationCodeHandler();
  }
}
