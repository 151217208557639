import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpService } from '../../core/services/http';
import { environment } from '../../../environments/environment';

@Injectable()
export class TermsServiceService {
  csvUrl = `${environment.TERMS_SERVICE_ENDPOINT}/terms-and-conditions`;

  constructor(private http: HttpService) {}

  getStatesData() {
    return this.http.get(`${environment.Content_service_API_Endpoint}/states`).pipe(map(res => res));
  }

  getProducts(page: Number, language, state, zip) {
    const pageNumber = page === 1 ? '' : `&page=${page}`;
    let url = `${this.csvUrl}?lang=${language}${pageNumber}`;
    if (state) {
      url += `&state=${state}`;
    }
    if (zip) {
      url += `&zip_code=${zip}`;
    }
    return this.http
      .get(url)
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }
}
