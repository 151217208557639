<section class="payment-response-box content-section shaded-white">
  <div class="head-title-text" *ngIf="isSuccess && isAnySuccess">
    <div class="alert alert-success final-success-alert text-center head-padding" role="alert">
      <span class="text-msg">
        <img src="/img/payment/Message-Ico-Success.svg" alt="Success Icon" />
        <p class="paddT20">We are processing your Enrollment and Payment</p>
      </span>
    </div>
  </div>
  <div class="head-title-text" *ngIf="errorProducts.length > 0 && errorProducts.length === products.length">
    <div class="alert alert-danger final-error-alert text-center head-padding" role="alert">
      <span class="text-msg">
        <img src="/img/payment/Message-Ico-Error.svg" alt="Error Icon" />
        <p class="paddT20">We have encountered a problem with your enrollment</p>
      </span>
    </div>
  </div>
  <article class="page-wrap row justify-content-center">
    <!-- Success -->
    <div class="col-12 col-md-8 paddTMob20" *ngIf="isSuccess">
      <div class="card reg-account-div">
        <div
          class="card-body width-mobile-100"
          [ngStyle]="{ 'col-6': passNewRegisterCustomerResponse && !hideRegisterBlock }"
          [ngStyle]="{ 'col-12': !passNewRegisterCustomerResponse }"
        >
          <div class="customer-name">
            <strong class="title"> Welcome {{ userName }}</strong>
            <br />
            <span>{{ generalInformation?.address?.customerNo }}</span>
          </div>
          <div>
            <span>Service Address</span>
            <br />
            <strong class="skiptranslate">
              {{ generalInformation?.address?.serviceAddress?.streetNumber }}
              {{ generalInformation?.address?.serviceAddress?.streetName }}, {{ generalInformation?.address?.serviceAddress?.city }},
              {{ generalInformation?.address?.serviceAddress?.state }},
              {{ generalInformation?.address?.serviceAddress?.zipcode }}
            </strong>
          </div>
        </div>
        <div *ngIf="passNewRegisterCustomerResponse && !hideRegisterBlock" class="card-body col-6 reg-account-cls width-mobile-100">
          <p class="title title-subs font-16">Don't have an account?</p>
          <button
            class="btn btn-sm btn-register reg-account-btn-cls skiptranslate"
            title="Register"
            type="button"
            (click)="scrollToSection('web-register-div-id')"
          >
            Click Here To Register
          </button>
        </div>
      </div>

      <div class="card mb-4" *ngIf="isAddress">
        <div class="sub-card col-12 row py-3 minH110">
          <div class="col-12 col-md-12 mb-1 mb-md-0 text-center padd0">
            <div class="minH">
              <span class="medium-light-text">Order placed on</span>
            </div>
            <div class="mt-md-2 mt-1">
              <h6>
                <strong>{{ generalInformation?.orderPlacedOn | date : 'MM/dd/yyyy' : 'UTC' }}</strong>
              </h6>
            </div>
          </div>
          <!--    <div class="col-4 col-md-4 mb-1 mb-md-0 text-center padd0">
                        <div class="minH">
                            <span class="medium-light-text">Program(s) Effective Date</span>
                        </div>
                        <div class="mt-md-2 mt-1">
                            <h6>
                                <strong>{{generalInformation?.effectiveDate | date:'MM/dd/yyyy'}}</strong>
                            </h6>
                        </div>
                    </div>
                    <div class="col-4 col-md-5 text-center padd0">
                        <div class="minH">
                            <span class="medium-light-text">Program(s) Term</span>
                        </div>
                        <div class="mt-md-2 mt-1">
                            <h6 class="txt-left">
                                <strong>{{generalInformation?.effectiveDate | date:'MM/dd/yyyy'}} - {{generalInformation?.renewalDate
                                    | date:'MM/dd/yyyy'}}</strong>
                            </h6>
                        </div>
                    </div> -->
        </div>
      </div>

      <div class="card">
        <!-- On bill section -->
        <div class="marB20" *ngIf="productsByBillingType.onbill.products.length > 0">
          <div class="mb-2">
            <span class="font-20 mb-2">Product(s) included on your monthly water bill</span>
            <span class="pull-right-imp pr-4 displayDesktop">Cost</span>
          </div>
          <div class="col-12 sub-card border-bottom no-border-radius-top no-border-radius-bottom mt-30">
            <div class="row product-list" *ngFor="let product of productsByBillingType.onbill.products">
              <div class="col-2 col-sm-2 col-md-2 col-lg-1 text-center">
                <img
                  class="product-image"
                  [alt]="product.productDetails?.webProgramName || product?.productDetails?.customerProgramName"
                  [src]="product?.productDetails?.programIconUrl ? getImage(productServiceBaseUrl + product.productDetails.programIconUrl) : ''"
                />
              </div>
              <div class="col-6 col-sm-2 col-md-6 col-lg-7 product-details mt-1">
                <h6 class="product-name">
                  <strong>{{ product.productDetails?.webProgramName || product?.productDetails?.customerProgramName }}</strong
                  ><br />
                  <div *ngIf="product.crmStatus === '3'; then CRMPending; else product.crmStatus === '999' && Pending"></div>
                  <ng-template #CRMPending
                    >Status: <span class="statusColorOrenge">{{ product.crmStatus === '3' ? 'Pending' : '' }}</span></ng-template
                  >
                  <ng-template #Pending
                    >Status: <span class="statusColorOrenge">{{ product.crmStatus === '999' ? 'Pending' : '' }}</span></ng-template
                  >
                  <div *ngIf="product.crmStatus === '0'; then Failure; else product.crmStatus === '2' && Complete"></div>
                  <ng-template #Failure
                    >Status: <span class="statusColorRed">{{ product.crmStatus === '0' ? 'Failure' : '' }}</span></ng-template
                  >
                  <ng-template #Complete
                    >Status: <span class="statusColorGreen">{{ product.crmStatus === '2' ? 'Complete' : '' }}</span></ng-template
                  >
                </h6>
              </div>
              <div class="to-right col-4 col-sm-4 col-md-4 col-lg-4 text-right product-price">
                <h6 class="cost-txt">
                  <span>${{ product.enrollmentPrice?.TaxDetails?.EnrollmentPrice?.toFixed(2) }}</span>
                </h6>
              </div>
              <hr />
            </div>
            <div class="row product-list">
              <div class="col-4 col-sm-4 col-md-5 col-lg-7 ml-auto">Taxes</div>
              <div class="to-right col-4 col-md-3 text-right product-price">
                <h6 class="cost-txt">
                  <span>${{ productsByBillingType?.onbill?.tax?.toFixed(2) }}</span>
                </h6>
              </div>
            </div>
          </div>
          <div class="card-body row no-gutters total-paid height-100">
            <div class="col-8 col-md-9 col-xl-8 total">
              <div class="product-name">
                <span>Represents recurring monthly charges. <br class="displayMobile" />All amounts are in US dollars.</span>
              </div>
            </div>
            <div class="col-4 col-md-3 col-xl-4 row paddT5">
              <div class="col-12 col-md-12 col-xl-5 text-right font-16 pr-0">TOTAL</div>
              <div class="col-12 col-md-12 col-xl-7 text-right pr-0">
                <h4 class="amtText">${{ productsByBillingType?.onbill?.total?.toFixed(2) }}</h4>
              </div>
            </div>
          </div>
        </div>

        <!-- Paid now section -->
        <div class="marB20" *ngIf="productsByBillingType?.paidbill?.products?.length > 0">
          <div class="mb-2 prodHeader">
            <span class="font-20 mb-2">Product(s) paid now</span>
            <br class="displayMobile" />
            <div *ngIf="!fromNonLoggedInRenewal" class="frequencyFullText">
              <span class="frequencyText">Payment Frequency: </span>{{ generalInformation?.billingFrequency }}
            </div>
          </div>
          <div class="col-12 sub-card no-border-radius-top mt-30">
            <div class="row product-list" *ngFor="let product of productsByBillingType?.paidbill?.products">
              <div class="col-2 col-sm-2 col-md-2 col-lg-1 text-center">
                <img
                  class="product-image"
                  [alt]="product.productDetails?.webProgramName || product?.productDetails?.customerProgramName"
                  [src]="product?.productDetails?.programIconUrl ? getImage(productServiceBaseUrl + product.productDetails.programIconUrl) : ''"
                />
              </div>
              <div class="col-6 col-sm-2 col-md-6 col-lg-7 product-details mt-1">
                <h6 *ngIf="!this.enrollmentGuid && !!this.orderId" class="product-name">
                  <strong>{{ product.productDetails?.webProgramName || product?.productDetails?.customerProgramName }}</strong
                  ><br />
                  <div *ngIf="product.crmStatus === '3'; then CRMPending; else product.crmStatus === '999' && Pending"></div>
                  <ng-template #CRMPending
                    >Status: <span class="statusColorOrenge">{{ product.crmStatus === '3' ? 'Pending' : '' }}</span></ng-template
                  >
                  <ng-template #Pending
                    >Status: <span class="statusColorOrenge">{{ product.crmStatus === '999' ? 'Pending' : '' }}</span></ng-template
                  >
                  <div *ngIf="product.crmStatus === '0'; then Failure; else product.crmStatus === '2' && Complete"></div>
                  <ng-template #Failure
                    >Status: <span class="statusColorRed">{{ product.crmStatus === '0' ? 'Failure' : '' }}</span></ng-template
                  >
                  <ng-template #Complete
                    >Status: <span class="statusColorGreen">{{ product.crmStatus === '2' ? 'Complete' : '' }}</span></ng-template
                  >
                </h6>
                <h6 *ngIf="!!this.enrollmentGuid && !this.orderId" class="product-name">
                  <strong>{{ product.productDetails?.webProgramName || product?.productDetails?.customerProgramName }}</strong
                  ><br />
                  Status: <span class="statusColorOrenge">Pending</span>
                </h6>
              </div>
              <div class="to-right col-4 col-sm-4 col-md-4 col-lg-4 text-right product-price">
                <h6 class="cost-txt">
                  <span>${{ product.enrollmentPrice?.TaxDetails?.EnrollmentPrice?.toFixed(2) }}</span>
                </h6>
                <div *ngIf="fromNonLoggedInRenewal" class="frequencyFullText pt-0">
                  <span class="frequencyText">Payment Frequency: </span>{{ product?.billingFrequency }}
                </div>
              </div>
            </div>
            <div class="row product-list">
              <div class="col-4 col-sm-4 col-md-5 col-lg-7 ml-auto">Taxes</div>
              <div class="to-right col-4 col-md-3 text-right product-price">
                <h6 class="cost-txt">
                  <span>${{ productsByBillingType?.paidbill?.tax?.toFixed(2) }}</span>
                </h6>
              </div>
            </div>
          </div>
          <div class="card-body row no-gutters total-paid height-100">
            <div class="col-8 col-md-9 col-xl-8 total">
              <div class="product-name">
                <span
                  >Represents recurring {{ generalInformation?.billingFrequency }} charges. <br class="displayMobile" />All amounts are in US
                  dollars.</span
                >
              </div>
            </div>
            <div class="col-4 col-md-3 col-xl-4 row paddT5">
              <div class="col-12 col-md-12 col-xl-5 text-right font-16 pr-0">TOTAL</div>
              <div class="col-12 col-md-12 col-xl-7 text-right pr-0">
                <h4 class="amtText">${{ productsByBillingType?.paidbill?.total?.toFixed(2) }}</h4>
              </div>
            </div>
          </div>
        </div>
        <!-- Error processing products -->
        <!-- <div class="card-body col-12 no-border-radius-top error-box" *ngIf="errorProducts.length > 0">
                    <div class="row col-12 mb-2">
                        Error encountered during enrollment
                    </div>
                    <div class="row product-list" *ngFor="let product of errorProducts">
                        <div class="col-2 col-md-1 text-center">
                            <img class="product-image" [src]="product?.productDetails?.programIconUrl ? productServiceBaseUrl  + product.productDetails.programIconUrl : ''">
                        </div>
                        <div class="col-6 col-md-7 product-details mt-1">
                            <h6 class="product-name">
                                <strong>{{product.productDetails?.webProgramName}}</strong>
                            </h6>
                            // <h6 class="product-desc">
                              //  <span [innerHTML]="product.productDetails?.programDescription"></span>
                          // </h6>
                        </div>
                        <div class="to-right col-4 col-md-3 text-right medium-light-text product-price">
                            <h6 class="cost-txt">
                                <span>${{product.enrollmentPrice.TaxDetails.EnrollmentPrice}}</span>
                            </h6>
                        </div>
                    </div>
                </div> -->
      </div>

      <p *ngIf="isAddress" class="text-center displayDesktop message-text">
        Once your enrollment is fully processed you will receive a confirmation email for this transaction containing all information about your new
        enrollment. If you do not receive the email to your inbox, please be sure to check your spam folder as well.
      </p>
      <p *ngIf="isAddress" class="thank-you-text text-center marB20">Thank you for your order!</p>
      <hr class="col-12 p-0 displayMobile marB40" />
    </div>

    <div *ngIf="passNewRegisterCustomerResponse && isSuccess && !hideRegisterBlock" class="col-md-8 MB-20 web-register-div" id="web-register-div-id">
      <main class="main hide-background">
        <div class="wrapper web-register-div-wrapper-cls">
          <div class="card web-register-div-card-cls">
            <div>
              <div class="title">
                <h1 class="title title-large web-register-div-sign-in-title-cls">
                  Looks like you don't have an account with us, fill in the below details and get registered with us!
                </h1>
              </div>
              <form class="form web-register-div-form-cls" #registerForm="ngForm" novalidate (keypress)="formKeyPressHandler($event, true)">
                <div class="col-12">
                  <div class="col-6 PD-0 width-tab-mobile-100">
                    <div class="form-group web-register-div-form-group-email-cls">
                      <label for="selectType">Email</label>
                      <input
                        type="email"
                        class="form-control round-form"
                        disabled
                        [pattern]="email_regex"
                        required
                        name="email"
                        [ngModel]="emailId"
                        #email="ngModel"
                      />
                      <!-- <small id="emailHelp" class="form-text text-danger" *ngIf="isExistingUser && emailId.valid && existingUser?.accountnumber">The email address you have entered is already registered</small> -->
                      <!-- <small id="emailHelp" class="form-text text-danger" *ngIf="email.isBlur  && !email.valid">Please enter valid email address.</small> -->
                      <!-- <small id="emailHelp" class="form-text text-danger" *ngIf="(isClicked && !email.valid && !email.touched)">Please enter valid email address.</small> -->
                    </div>
                  </div>
                  <div class="col-12 PD-0 d-flex width-tab-mobile-flex-col">
                    <div class="col-6 PD-0 width-tab-mobile-100">
                      <div class="form-group web-register-div-form-group-pwd-cls" id="credential-section">
                        <label for="selectType">Password</label>
                        <input
                          [type]="isView ? 'text' : 'password'"
                          class="form-control round-form"
                          (blur)="blurHandler(password)"
                          [ngClass]="{ 'is-invalid': (isClicked || password.isBlur) && !password.valid }"
                          [pattern]="password_regex"
                          name="password"
                          required
                          ngModel
                          #password="ngModel"
                        />
                        <span (click)="isView = !isView" class="fa {{ isView ? 'fa-eye' : 'fa-eye-slash' }} pwd-toggle"></span>
                        <small id="pwdHelp" class="form-text text-danger" *ngIf="isClicked && !password.valid">Please enter valid Password.</small>
                      </div>
                      <div class="form-group web-register-div-form-group-pwd-cls">
                        <label for="selectType">Confirm Password</label>
                        <input
                          [type]="isConfirmView ? 'text' : 'password'"
                          class="form-control round-form"
                          (blur)="blurHandler(confirmPassword)"
                          [ngClass]="{
                            'is-invalid':
                              ((isClicked || confirmPassword.isBlur) && !confirmPassword.valid) || password.value !== confirmPassword.value
                          }"
                          [pattern]="password_regex"
                          name="confirmPassword"
                          required
                          ngModel
                          #confirmPassword="ngModel"
                        />
                        <span (click)="isConfirmView = !isConfirmView" class="fa {{ isConfirmView ? 'fa-eye' : 'fa-eye-slash' }} pwd-toggle"></span>
                        <small id="confirmPwdHelp" class="form-text text-danger" *ngIf="isClicked && !confirmPassword.valid"
                          >Please enter valid Confirm Password.</small
                        >
                      </div>
                    </div>
                    <div class="col-6 PD-0 width-tab-mobile-100">
                      <div class="col-12 password-strength-cls">
                        <hos-password-strength-meter
                          [password]="password.value"
                          [confirmPassword]="confirmPassword.value"
                        ></hos-password-strength-meter>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div class="col-12 PD-0 margin-15-0">
                    <div class="form-group web-register-div-form-group-button-cls">
                      <button
                        class="input-submit notranslate web-register-div-input-submit-cls"
                        (click)="registerCustomer(registerForm); isClicked = true"
                        title="LOG IN"
                        type="submit"
                      >
                        Register
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
    </div>

    <!-- Other services on successful order -->
    <section class="col-12" *ngIf="isSuccess">
      <article>
        <div class="col-12 row m-0">
          <hr class="col-3 p-0 displayDesktop" />
          <h2 class="section-head-title col-12 col-sm-12 col-md-6 px-0">Other Services you may be interested in</h2>
          <hr class="col-3 p-0 displayDesktop" />
        </div>
      </article>
      <article class="row paddB70 productCarousel">
        <ngu-carousel [inputs]="carouselTile" [dataSource]="otherProducts" class="category-tile col-12">
          <ngu-tile *nguCarouselDef="let product of otherProducts; let i = index" class="item">
            <hos-product-card-qc-response-page
              [index]="i"
              [productcountid]="i + 1"
              [locData]="locData"
              [products]="otherProducts"
              [product]="product"
              [zipcode]="zipcode"
              [locationId]="locationId"
              [showOldPrice]="setOldPriceFlag"
            ></hos-product-card-qc-response-page>
          </ngu-tile>
          <span
            NguCarouselPrev
            class="cat-slide-icon left-cat-icon fa fa-chevron-left"
            [ngClass]="{ 'hide-slider': otherProducts?.length <= 4 }"
          ></span>
          <span
            NguCarouselNext
            class="cat-slide-icon right-cat-icon fa fa-chevron-right"
            [ngClass]="{ 'hide-slider': otherProducts?.length <= 4 }"
          ></span>
        </ngu-carousel>

        <div class="col-12 col-sm-12 col-md-12 mb-5 text-center" *ngIf="otherProducts?.length === 0">No other services available.</div>
      </article>
    </section>

    <!-- Error -->
    <div class="col-12 mt-3 error-body mb-3 mt-5" *ngIf="errorProducts.length > 0 && errorProducts.length === products.length">
      <div class="col-12 title-text">
        <h4>Hello {{ userName }}</h4>
      </div>
      <div class="col-12">
        <div class="error-margin">
          Please call customer service to ensure your enrollment has completed and you are protected.
          <br />
          <br />
          <h4>{{ customer_service_number }}</h4>
          <!-- <button routerLink="/payment" class="btn btn-primary app-btn place-order-btn to-right">Place Order Again</button> -->
        </div>
        <!-- <div class="row mb-5">
                        <p class="col-8"> The rejection could be due to card or bank account issue.</p>
                        <button routerLink="/payment" class="btn btn-primary app-btn place-order-btn to-right">Use different card</button>
                    </div> -->
        <!-- <div>If you continue having problems placing your order please
                        <a routerLink="/page/Contact_Us/contact">Contact Us</a>
                        <br> or call CustomerService:
                    </div> -->
      </div>
      <!-- <h2 class="text-center end-text">
                <i>We Keep Life flowing!</i>
            </h2> -->
    </div>
    <!-- </div> -->
  </article>
  <div id="checkout_order_success" class="btn gaTrackBtn"></div>
  <div id="checkout_successpage_register_success" class="btn gaTrackBtn"></div>
  <div id="annual_billing_frequency" class="btn gaTrackBtn"></div>
  <div id="monthly_billing_frequency" class="btn gaTrackBtn"></div>
  <div id="successful_enrollment_renewal" class="btn gaTrackBtn"></div>
  <div id="order_includes_do_not_autoRenew" class="btn gaTrackBtn"></div>
</section>
<div [ngClass]="{ loading: showLoader }"></div>
