import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class ConnectionService {
  private connectionMonitor: Observable<boolean>;

  constructor(@Inject(PLATFORM_ID) private platform: Object) {
    if (isPlatformBrowser(this.platform)) {
      this.connectionMonitor = new Observable(observer => {
        window.addEventListener('offline', () => {
          observer.next(false);
        });
        window.addEventListener('online', () => {
          observer.next(true);
        });
      });
    }
  }

  monitor(): Observable<boolean> {
    return this.connectionMonitor;
  }
}
