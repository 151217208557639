<h2 class="headerText">Please answer the following questions regarding your claim.</h2>

<div *ngIf="question.type === 'dropdown'" class="questionView">
  <p class="questionView__textView">
    <span class="questionView__indexStyle">{{ previousQuestion.length + 1 }}</span>
    <span class="questionView__questionTextStyle">{{ question.question }}</span>
  </p>
  <div class="form-group dropdown-box">
    <select class="form-control" id="dropdown" (change)="optionValueChange($event.target['value'])" [(ngModel)]="question.answer">
      <option selected disabled>Choose an option</option>
      <option [value]="answer" *ngFor="let answer of question?.inputArray">{{ answer }}</option>
    </select>
  </div>
</div>

<div *ngIf="question.type === 'text-input'" class="questionView">
  <!-- <p>Free Text</p> -->
  <p class="questionView__textView">
    <span class="questionView__indexStyle">{{ previousQuestion.length + 1 }}</span>
    <span class="questionView__questionTextStyle">{{ question.question }}</span>
  </p>
  <input
    maxlength="200"
    type="text"
    class="form-control"
    placeholder="{{ question.placeholder }}"
    [(ngModel)]="question.answer"
    id="text-input"
    (keyup)="optionValueChange($event.target.value)"
  />
</div>

<div *ngIf="question.type === 'tel'" class="questionView">
  <!-- <p>Tel Input</p> -->
  <p class="questionView__textView">
    <span class="questionView__indexStyle">{{ previousQuestion.length + 1 }}</span>
    <span class="questionView__questionTextStyle">{{ question.question }}</span>
  </p>
  <input
    maxlength="10"
    type="tel"
    onpaste="return false;"
    class="form-control"
    placeholder="{{ question.placeholder }}"
    [(ngModel)]="question.answer"
    id="tel"
    (keypress)="numberOnly($event)"
    (keyup)="optionValueChange($event.target.value)"
  />
</div>

<div *ngIf="question.type === 'radio-slider'" class="questionView">
  <!-- <p>Radio</p> -->
  <p class="questionView__textView">
    <span class="questionView__indexStyle">{{ previousQuestion.length + 1 }}</span>
    <span class="questionView__questionTextStyle">{{ question.question }}</span>
  </p>
  <div class="radioWrapper radio-slider-cls">
    <!-- <div class="form-check form-check-inline" *ngFor="let option of question?.inputArray;let i = index">
            <input name="radio-input_{{i}}"  class="form-check-input" type="radio" id="radio-input_{{i}}" [value]= "option" [(ngModel)]="question.answer" (ngModelChange)="optionValueChange($event)">
            <label class="form-check-label" for="radio-input_{{i}}">{{option}}</label>
        </div> -->
    <label class="switch" [style.width.px]="switchWidth" for="checkbox">
      <div #YesContent class="yes" [innerHTML]="question.inputArray[0]"></div>
      <div #NoContent class="no" [innerHTML]="question.inputArray[1]"></div>
      <input type="checkbox" id="checkbox" [(ngModel)]="radioVal" (ngModelChange)="radioButtonChange($event)" />
      <div class="slider round" [attr.data-before]="radioVal === false ? this.question.inputArray[0] : this.question.inputArray[1]" id="theDiv"></div>
    </label>
  </div>
</div>

<div *ngIf="question.type === 'text-area-input'" class="questionView">
  <!-- <p>Text Area Input</p> -->
  <p class="questionView__textView">
    <span class="questionView__indexStyle">{{ previousQuestion.length + 1 }}</span>
    <span class="questionView__questionTextStyle">{{ question.question }}</span>
  </p>
  <div class="questionView__textAreaView">
    <textarea
      maxlength="300"
      placeholder="{{ question.placeholder }}"
      class="form-control"
      id="text-area-input"
      rows="5"
      [(ngModel)]="question.answer"
      (keyup)="optionValueChange($event.target.value)"
    ></textarea>
  </div>
</div>

<div *ngIf="question.type === 'checkbox'" class="questionView">
  <!-- <p>Checkbox</p> -->
  <p class="questionView__textView">
    <span class="questionView__indexStyle">{{ previousQuestion.length + 1 }}</span>
    <span class="questionView__questionTextStyle">{{ question.question }}</span>
  </p>
  <div class="w-100 customCheckboxView">
    <ng-container *ngFor="let answer of question?.inputArray; let id = index">
      <label for="cb{{ id }}" class="customCheckboxView__container" tooltip="{{ question?.labelArray[id] }}">
        <input
          type="checkbox"
          name="cb"
          id="cb{{ id }}"
          [value]="answer.checked"
          [(ngModel)]="answer.checked"
          (change)="checkboxValueChange($event, answer)"
        />{{ answer.title }}
        <span class="customCheckboxView__checkmark"></span>
      </label>
    </ng-container>
  </div>
</div>

<!-- Multi radio button -->
<div *ngIf="question.type === 'radio-multi'" class="questionView">
  <p class="questionView__textView">
    <span class="questionView__indexStyle">{{ previousQuestion.length + 1 }}</span>
    <span class="questionView__questionTextStyle">{{ question.question }}</span>
  </p>
  <div class="d-flex justify-content-center radioWrapper">
    <div class="div-group col-md-12">
      <div
        class="radio-box-width col-md-3"
        *ngFor="let option of question?.inputArray; let i = index"
        style.backgroundColor="{{ radioMultiClrConfig?.colorConfigStyle[i] }}"
      >
        <label for="rb{{ i }}"
          ><input
            type="radio"
            name="rb"
            id="rb{{ i }}"
            [value]="option"
            [(ngModel)]="question.answer"
            (click)="changeBackgroundClr(i)"
            (ngModelChange)="optionValueChange($event)"
          />
          <span class="checkmark-radio"></span>{{ option }}</label
        >
      </div>
    </div>
  </div>
</div>

<div *ngIf="question.type === 'date-input'" class="questionView">
  <p class="questionView__textView">
    <span class="questionView__indexStyle">{{ previousQuestion.length + 1 }}</span>
    <span class="questionView__questionTextStyle">{{ question.question }}</span>
  </p>
  <input
    type="date"
    class="form-control"
    onkeydown="return false"
    placeholder="{{ question.placeholder }}"
    [(ngModel)]="question.answer"
    id="date-input"
    (change)="optionValueChange($event.target.value)"
    min="{{ question.manualDate === 'Yes' ? (today | date : 'yyyy-MM-dd' : 'UTC') : (minDate | date : 'yyyy-MM-dd' : 'UTC') }}"
    max="{{ question.manualDate === 'Yes' ? null : (today | date : 'yyyy-MM-dd' : 'UTC') }}"
  />
</div>
