import { NgModule, APP_INITIALIZER } from '@angular/core';
import { CommonModule, APP_BASE_HREF } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NguCarouselModule } from '@ngu/carousel';
import { AlertModule } from 'ngx-bootstrap/alert';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

// Components
import { Angulartics2Module } from 'angulartics2';
import { HttpHandler } from '@angular/common/http';
import { BreadcrumbComponent } from './component/breadcrumb/breadcrumb.component';
import { ZipcodeComponent } from './component/zipcode/zipcode.component';
import { SuccessAlertComponent } from './component/success-alert/success-alert.component';
import { ErrorModalComponent } from './component/error-modal/error-modal.component';
import { ConfirmModalComponent } from './component/confirm-modal/confirm-modal.component';
import { AlertConfirmModalComponent } from './component/alert-confirm-modal/alert-confirm-modal.component';
import { ProductCardSwitcherComponent } from '../product/component/products/product-card/product-card-switcher.component';
import { ProductCardDetailsPageComponent } from '../product/component/products/product-card/product-card-details-page/product-card-details-page.component';
import { ProductCardListingPageComponent } from '../product/component/products/product-card/product-card-listing-page/product-card-listing-page.component';
import { ProductCardOrderPageComponent } from '../product/component/products/product-card/product-card-order-page/product-card-order-page.component';
import { ProductCardQCResponsePageComponent } from '../product/component/products/product-card/product-card-qc-response-page/product-card-qc-response-page.component';
import { ProductCardRecommendedSectionComponent } from '../product/component/products/product-card/product-card-recommended-section/product-card-recommended-section.component';
import { ProductCardUpgradeComponent } from '../product/component/products/product-card/product-card-upgrade/product-card-upgrade.component';
import { VeiwServiceAddressComponent } from './component/veiw-service-address/veiw-service-address.component';
import { SelectLanguageComponent } from './component/select-language/select-language.component';
import { SurgeModalComponent } from './component/surge-modal/surge-modal.component';
import { CCPAUpdatesModalComponent } from './component/ccpa-updates/ccpa-updates.component';
import { ClaimsQuestionModalComponent } from './component/claims-question-modal/claims-question-modal.component';
import { ClaimsQuestionComponent } from './component/claims-question-modal/claims-question-component/claims-question.component';
import { ClaimsChildComponent } from './component/claims-question-modal/claims-child-component/claims-child.component';
import { ClaimsPaymentComponent } from './component/claims-question-modal/claims-payment-component/claims-payment.component';
import { OneTimePaymentOracleComponent } from './component/one-time-payment-oracle/one-time-payment-oracle.component';
import { CancelEnrollmentComponent } from './component/cancel-enrollment-modal/cancel-enrollment-modal.component';
import { ProductCardClaimPageComponent } from '../product/component/products/product-card/product-card-claim-page/product-card-claim-page.component';

// Services
import { SessionStorageService } from './services/session-storage.service';
import { AuthService } from './services/auth.service';
import { HttpService } from './services/http';
import { DotCMSService } from './services/dotcms.service';
import { GooglePlacesService } from './services/google-places.service';
import { UtilityProviderService } from './services/utility-provider';
import { AlertService } from './services/alert.service';
import { ZipcodeService } from './services/zipcode.service';
import { SeoService } from './services/seo.service';
import { StorageService, LocalStorageService } from './services/local-storage.service';
import { SiteContentService } from './services/siteContents';
import { CrmProxyService } from './services/crm-proxy.service';
import { ConnectionService } from './services/connection.service';
import { TemplateService } from './services/template.service';
import { WindowScrolling } from './services/window-scrolling.service';

import { CanActivateViaAuthGuard } from './guards/auth.guards';
import { CanActivateViaLoggedinGuard } from './guards/logged.guards';
import { CanDeactivateViaAuthGuard } from './guards/deactivate.guards';
import { CCPAUpdatesService } from './services/ccpa-updates.service';
import { ClaimsService } from './services/claims.service';

import { AutofocusDirective } from './directive/autofocus.directive';
import { PartnerProviderClass } from './services/partner-provider';
import { getBaseLocation } from './services/common-functions.util';
import { LinkService } from './services/link-service';
import { AppSecrets } from '../app.secrets';
import { JsonLDService } from './services/json-ld.service';

import { LazyImgDirective } from './directive/lazyimage.directive';
import { ScrollSpyDirective } from './directive/scroll-spy.directive';
import { PipesModule } from '../pipes';
import { PlatFormId } from './services/plateform-id.service';
import { GtagPayloadGeneratorService } from './services/gtag-payload-generator';
import { RevealDirective } from './directive/reveal.directive';
import { LazyLoadDirective } from './directive/lazy-load-content.directive';

export function appSecretLoad(config: AppSecrets) {
  return () => config.load();
}

export function httpInterceptor(handler: HttpHandler, appSecrets: AppSecrets, platformId: PlatFormId, sessionStorageService: SessionStorageService) {
  return new HttpService(handler, appSecrets, platformId, sessionStorageService);
}

@NgModule({
  declarations: [
    AutofocusDirective,
    BreadcrumbComponent,
    ZipcodeComponent,
    ErrorModalComponent,
    ConfirmModalComponent,
    AlertConfirmModalComponent,
    SuccessAlertComponent,
    ProductCardDetailsPageComponent,
    ProductCardListingPageComponent,
    ProductCardOrderPageComponent,
    ProductCardQCResponsePageComponent,
    ProductCardRecommendedSectionComponent,
    ProductCardUpgradeComponent,
    SelectLanguageComponent,
    SurgeModalComponent,
    CCPAUpdatesModalComponent,
    ClaimsQuestionModalComponent,
    ClaimsQuestionComponent,
    ClaimsChildComponent,
    LazyImgDirective,
    LazyLoadDirective,
    ClaimsPaymentComponent,
    OneTimePaymentOracleComponent,
    ScrollSpyDirective,
    CancelEnrollmentComponent,
    ProductCardClaimPageComponent,
    RevealDirective,
    // VeiwServiceAddressComponent
  ],
  exports: [
    AutofocusDirective,
    BreadcrumbComponent,
    ZipcodeComponent,
    ErrorModalComponent,
    ConfirmModalComponent,
    AlertConfirmModalComponent,
    SuccessAlertComponent,
    Angulartics2Module,
    ProductCardDetailsPageComponent,
    ProductCardListingPageComponent,
    ProductCardOrderPageComponent,
    ProductCardQCResponsePageComponent,
    ProductCardRecommendedSectionComponent,
    ProductCardUpgradeComponent,
    SelectLanguageComponent,
    SurgeModalComponent,
    CCPAUpdatesModalComponent,
    ClaimsQuestionModalComponent,
    ClaimsQuestionComponent,
    ClaimsChildComponent,
    LazyImgDirective,
    LazyLoadDirective,
    ClaimsPaymentComponent,
    ScrollSpyDirective,
    CancelEnrollmentComponent,
    ProductCardClaimPageComponent,
    RevealDirective,
  ],
  imports: [
    AlertModule.forRoot(),
    NguCarouselModule,
    TooltipModule.forRoot(),
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    Angulartics2Module.forRoot(),
    PipesModule,
  ],
  providers: [
    SessionStorageService,
    AuthService,
    DotCMSService,
    GooglePlacesService,
    UtilityProviderService,
    GtagPayloadGeneratorService,
    AlertService,
    ZipcodeService,
    SeoService,
    CCPAUpdatesService,
    SiteContentService,
    CrmProxyService,
    TemplateService,
    WindowScrolling,
    LinkService,
    ConnectionService,
    ClaimsService,
    {
      provide: StorageService,
      useClass: LocalStorageService,
    },
    {
      provide: HttpService,
      useFactory: httpInterceptor,
      deps: [HttpHandler, AppSecrets, PlatFormId, SessionStorageService],
    },
    CanActivateViaAuthGuard,
    CanActivateViaLoggedinGuard,
    CanDeactivateViaAuthGuard,
    VeiwServiceAddressComponent,
    PartnerProviderClass,
    {
      provide: APP_BASE_HREF,
      useFactory: getBaseLocation,
    },
    AppSecrets,
    {
      provide: APP_INITIALIZER,
      useFactory: appSecretLoad,
      deps: [AppSecrets],
      multi: true,
    },
    JsonLDService,
    PlatFormId,
    ProductCardSwitcherComponent,
  ],
})
export class CoreModule {}
