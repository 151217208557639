import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ApplicationRef, PLATFORM_ID, APP_ID, Inject } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

import { isPlatformBrowser } from '@angular/common';

import { UrlSerializer } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SimpleModalModule } from 'ngx-simple-modal';
import { AppRoutingModule } from './app-routes';

import { CoreModule } from './core/index';
import { LayoutModule } from './layout/index';
import { HomeModule } from './home/index';
import { ServicesGenericDetailModule } from './services-generic-details/services-generic-detail.module';
import { AppComponent } from './app.component';
import { SiteLoginComponent } from './site-login.component';
import { GlobalVariables } from './core/services/global-variables.service';
import { PageNotFound404Module } from './page-not-found404/index';

import { ProviderModalComponent } from './product/component/provider-modal/provider-modal.component';
import { ServiceDetailInfoComponent } from './user/component/service-detail-info/service-detail-info.component';
import { UpsellServiceDetailComponent } from './product/component/upsell-service-detail/upsell-service-detail.component';
import { UpdateServiceDetailComponent } from './user/component/update-service-detail/update-service-detail.component';
import { VeiwServiceAddressComponent } from './core/component/veiw-service-address/veiw-service-address.component';
import { AutoRenewModalComponent } from './user/component/auto-renew-modal/auto-renew-modal.component';
import { LowerCaseUrlSerializer } from './core/services/urlSerializer.service';
import { RenewNowModalComponent } from './user/component/renew-now-modal/renew-now-modal.component';
// Animation
import { GtagModule } from './gtag/gtag.module';
import { CartQuickCheckoutService } from './cart/services/cart-quick-checkout.service';
import { CartService } from './cart/services/cart.service';
import { OrdersService } from './payment-ets/services/orders.service';
import { ProductService } from './product/services/product.service';
import { UserService } from './user/services/user.service';
import { PaymentEtsService } from './payment-ets/services/payment-ets.service';
import { UtilsService } from './core/services/utils.service';

export function getLocalStorage() {
  return window ? window.localStorage : null;
}

@NgModule({
  declarations: [
    AppComponent,
    SiteLoginComponent,
    ProviderModalComponent,
    ServiceDetailInfoComponent,
    UpsellServiceDetailComponent,
    UpdateServiceDetailComponent,
    VeiwServiceAddressComponent,
    // PageNotFound404Component,
    // ManagePaymentComponent,
    RenewNowModalComponent,
    AutoRenewModalComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({
      appId: 'hos-serverside',
    }),
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule,
    HomeModule,
    CoreModule,
    LayoutModule,
    ServicesGenericDetailModule,
    PageNotFound404Module,
    GtagModule.init({}),
    BrowserAnimationsModule,
    SimpleModalModule,
  ],
  providers: [
    {
      provide: UrlSerializer,
      useClass: LowerCaseUrlSerializer,
    },
    {
      provide: 'LOCALSTORAGE',
      useFactory: getLocalStorage,
    },
    GlobalVariables,
    UserService,
    CartQuickCheckoutService,
    CartService,
    OrdersService,
    ProductService,
    PaymentEtsService,
    UtilsService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(applicationRef: ApplicationRef, @Inject(PLATFORM_ID) private platformId: Object, @Inject(APP_ID) private appId: string) {
    const platform = isPlatformBrowser(platformId) ? 'on the server' : 'in the browser';
    // eslint-disable-next-line no-console
    console.log(`Running ${platform} with appId=${appId}`);
    Object.defineProperty(applicationRef, '_rootComponents', {
      get: () => applicationRef.components,
    });
  }
}
