import { isPlatformBrowser } from '@angular/common';
import { Directive, ElementRef, HostListener, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { GlobalVariables } from '../services/global-variables.service';

@Directive({
  selector: '[hosLazyLoad]',
})
export class LazyLoadDirective implements OnInit, OnDestroy {
  private observer: IntersectionObserver;

  private scrolled: boolean = false;

  constructor(private elementRef: ElementRef, private globalVariables: GlobalVariables, @Inject(PLATFORM_ID) private platformId: any) {}

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this.loadContent();
            this.observer.unobserve(entry.target);
          }
        });
      });

      this.addScrollListener();
    }
  }

  private addScrollListener() {
    window.addEventListener('scroll', this.scrollHandler.bind(this));
  }

  private scrollHandler() {
    if (!this.scrolled) {
      this.observer.observe(this.elementRef.nativeElement);
      this.scrolled = true;
    }
  }

  private loadContent() {
    this.globalVariables.showLazyLoadContent = true;
  }

  @HostListener('window:beforeunload')
  ngOnDestroy() {
    if (isPlatformBrowser(this.platformId)) {
      window.removeEventListener('scroll', this.scrollHandler);
    }
  }
}
