<div class="title">
  <div class="col-12 PL-0">
    <span>
      <a class="linktext back-to-text notranslate" (click)="disableForgotPassword()">
        &nbsp;
        <i class="fa fa-chevron-left arrowDesign"></i>&nbsp; BACK TO LOGIN</a
      >
    </span>
    <span *ngIf="!isRegisterSucess && registerLink"><a class="linktext back-to-text notranslate right" (click)="goToRegister()">REGISTER</a></span>
  </div>
  <!-- <a (click)="disableForgotPassword()" class="linktext back-to-text">
        &nbsp;
         <i class="fa fa-chevron-left arrowDesign"></i>&nbsp; Back to Login</a> -->
  <h1 class="title title-large" [ngClass]="{ 'm-0': isRegisterSucess }">Forgot your password?</h1>
</div>
<!-- <div *ngIf="!isValidCaptcha" class="page-no-align">
    1/2
</div>
<div *ngIf="isValidCaptcha" class="page-no-align">
    2/2
</div> -->
<div class="headerTextMob" [ngClass]="{ 'success-reg-headerTextMob': isRegisterSucess }">
  <!-- <h4 class="forgotTitle">{{title || 'Confirm'}}</h4> -->
  <small *ngIf="!isValidCaptcha" class="form-text font-cls">Security Check, Please check the box</small>
  <small *ngIf="isValidCaptcha" class="form-text font-cls">Please enter your email address used to create your account</small>
</div>
<div *ngIf="!isValidCaptcha" class="col-12">
  <form class="m-t" role="form" #captchaFormMob="ngForm" (ngSubmit)="onCaptchaSubmit(captchaFormMob)" novalidate>
    <div class="row displayDeskTop" [ngClass]="{ 'success-reg-displayDeskTop': isRegisterSucess }">
      <div class="PL-15 PR-15 captchaDiv" [ngClass]="isRegisterSucess ? 'col-9' : 'col-12'">
        <div class="form-group">
          <div id="captcha" class="captcha"></div>
        </div>
      </div>
      <div class="marNext marT15 nextBtn notranslate" [ngClass]="{ 'col-3 success-reg-marNext success-reg-nextBtn mt-0': isRegisterSucess }">
        <button type="submit" class="btn form-submit-deskTop block full-width m-b">NEXT</button>
      </div>
    </div>
    <div class="row displayPopUp" [ngClass]="{ 'success-reg-displayPopUp': isRegisterSucess }">
      <div class="col-sm-12 col-xs-12 PL-15 PR-15 captchaDiv">
        <div class="form-group">
          <div id="captchaMob" class="captchaMob"></div>
        </div>
      </div>
      <div class="marNext marT15 nextBtn notranslate" [ngClass]="{ 'success-reg-marNext success-reg-nextBtn mt-0': isRegisterSucess }">
        <button type="submit" class="btn form-submit-deskTop block full-width m-b">NEXT</button>
      </div>
    </div>
    <!-- <div class="row">
            <div class="col-12 captchaDiv">
                <div class="form-group">
                    <div id="captchaMob" class="captchaMob"></div>
                </div>
            </div>
            <div class="marNext marT15 nextBtn">
                <button type="submit" class="btn form-submit-deskTop block full-width m-b">NEXT</button>
            </div>
        </div> -->
  </form>
</div>
<div *ngIf="isValidCaptcha" class="col-12">
  <form class="m-t" *ngIf="isValidCaptcha" role="form" #forgotPasswordForm="ngForm" (ngSubmit)="onSubmit(forgotPasswordForm)" novalidate>
    <div class="row marT15">
      <div class="col-12 pl-0">
        <div class="form-group">
          <input
            type="text"
            class="round-form form-control"
            [pattern]="email_regex"
            name="email"
            placeholder="Your email"
            required
            (focus)="onFocusChange()"
            ngModel
            #email="ngModel"
          />
          <div *ngIf="errorMessage" class="form-text text-danger justify-text">
            {{ errorMessage }}
          </div>
          <div class="pt-3 pl-1 justify-text" *ngIf="successMessage">
            {{ successMessage }}
          </div>
          <small id="emailHelp" class="form-text text-danger" [hidden]="!email.pattern || email.pristine">This is not a valid email.</small>
        </div>
      </div>
      <div class="nextBtn marNext notranslate" [ngClass]="{ 'mb-3 success-reg-marNext success-reg-nextBtn': isRegisterSucess }">
        <button
          type="submit"
          *ngIf="showSubmit"
          class="btn form-submit-mob block full-width m-b"
          [ngClass]="{ 'success-reg-form-submit-mob': isRegisterSucess }"
        >
          SUBMIT
        </button>
      </div>
    </div>
  </form>
</div>
