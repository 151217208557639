<!-- Child Claim questions -->

<div>
  <h2 class="headerText" *ngIf="!isChildQuestionTwo">Please answer the following questions regarding your claim.</h2>

  <div class="questionView" *ngIf="isChildQuestionOne">
    <p class="questionView__textView">
      <span class="questionView__indexStyle">1</span>
      <span class="questionView__questionTextStyle">When this issue was re-occurred</span>
    </p>
    <input
      type="date"
      class="form-control"
      onkeydown="return false"
      placeholder="Enter the date in DD/MM/YY format"
      id="date-input"
      [(ngModel)]="childClaimData"
      (change)="optionValueChange()"
      min="{{ minDate | date : 'yyyy-MM-dd' : 'UTC' }}"
      max="{{ today | date : 'yyyy-MM-dd' : 'UTC' }}"
    />
  </div>

  <div class="questionView" *ngIf="isChildQuestionTwo">
    <!-- <p>Text Area Input</p> -->
    <div class="d-flex justify-content-center align-items-center">
      <div class="questionsView__numStyle">
        <div class="checkmark-right">&nbsp;</div>
      </div>
      <h3 class="questionsView__questStyle">Previous Claim Details.</h3>
    </div>
    <div class="claimsView">
      <p class="dateTextStyle">
        Previous Claim Date: <span class="spanStyle">{{ selectedClaim?.dateOfClaim.split('T')[0] }}</span>
      </p>
      <p class="claimsDetailsStyle">Claim Details</p>
      <div>
        <p class="claimsDetailsTextStyle">{{ textAreaValue || selectedClaim?.SummaryofClaim }}</p>
      </div>
    </div>
    <p class="questionView__textView">
      <span class="questionView__indexStyle">2</span>
      <span class="questionView__questionTextStyle">Additional Notes/Conversation</span>
    </p>
    <div class="questionView__textAreaView">
      <textarea
        maxlength="300"
        placeholder="Enter some Text here...."
        class="form-control"
        id="text-area-input"
        rows="5"
        [(ngModel)]="childClaimData"
        (keyup)="optionValueChange()"
      ></textarea>
    </div>
  </div>
</div>
