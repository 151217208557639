<!-- Claims Service Fee Box -->

<div>
  <h2 class="headerText" *ngIf="!isServiceFeeQuestTwo">Please select the option.</h2>

  <div class="questionView" *ngIf="isServiceFeeQuestOne">
    <p class="questionView__textView">
      <span class="questionView__indexStyle"><i class="fa fa-dollar" aria-hidden="true"></i></span>
      <span class="questionView__questionTextStyle">Your following service fee cost ${{ serviceFees }}. Want to proceed further?</span>
    </p>
    <div class="d-flex justify-content-center radioWrapper">
      <div class="div-group col-md-12">
        <div
          class="radio-box-width col-md-3"
          *ngFor="let option of input.inputArray; let i = index"
          style.backgroundColor="{{ radioMultiClrConfig?.colorConfigStyle[i] }}"
        >
          <label for="rb{{ i }}"
            ><input
              type="radio"
              name="rb"
              id="rb{{ i }}"
              [value]="option"
              (click)="changeBackgroundClr(i)"
              [(ngModel)]="input.answer"
              (ngModelChange)="optionValueChange($event)"
            />
            <span class="checkmark-radio"></span>{{ option }}</label
          >
        </div>
      </div>
    </div>
  </div>

  <div class="questionView" [hidden]="!isServiceFeeQuestTwo">
    <p class="questionView__textView">
      <span class="questionView__indexStyle"><i class="fa fa-credit-card" aria-hidden="true"></i></span>
      <span class="questionView__questionTextStyle">Select a Payment Method</span>
    </p>
    <div [ngClass]="{ loading: showLoader || !sessionTokenGenerated }"></div>
    <div class="payment-body" [hidden]="showLoader">
      <div class="payment-gateway">
        <!-- <div class="block_div" id="hp" data-show-Discover="false" data-allow-avs-skip="false" data-disable-autocomplete="true"></div> -->
        <div id="stripe-claim"></div>
      </div>
    </div>
  </div>
</div>
