/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { HttpService } from './http';
import { SessionStorageService } from './session-storage.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class CrmProxyService {
  constructor(private router: Router, private http: HttpService, private sessionStorageService: SessionStorageService) {}

  getProductBillTypes(location_code_id: any, program_ids: any, marketing_code_ids?: any) {
    const params = `location_code_id=${location_code_id}&program_ids=${program_ids}${
      marketing_code_ids ? `&marketing_code_ids=${marketing_code_ids}` : ''
    }`;
    return this.http.get(`${environment.Crm_Proxy_Service_API_Endpoint}products-billing-type?${params}`).pipe(map(res => res));
  }

  getProductsTax(customer_id: any, marketing_code_ids: any, program_ids: any, location_code: any, payment_method: any, frequency: any) {
    const params = `customer_id=${customer_id}&payment_method=${payment_method}&frequency=${frequency}
&location_code=${location_code}&marketing_code_ids=${marketing_code_ids}&program_ids=${program_ids}`;
    return this.http
      .get(`${environment.Crm_Proxy_Service_API_Endpoint}products-tax`, {
        params: {
          customer_id,
          payment_method,
          frequency,
          location_code,
          marketing_code_ids,
          program_ids,
        },
      })
      .pipe(map(res => res));
  }

  getProductsTaxCheckout(
    token,
    customer_id: any,
    marketing_code_ids: any,
    program_ids: any,
    location_code: any,
    payment_method: any,
    frequency: any,
  ) {
    const options = token ? { headers: { authorization: token } } : {};
    const params = `customer_id=${customer_id}&payment_method=${payment_method}&frequency=${frequency}
&location_code=${location_code}&marketing_code_ids=${marketing_code_ids}&program_ids=${program_ids}`;
    return this.http
      .get(`${environment.Crm_Proxy_Service_API_Endpoint}products-tax`, {
        ...options,
        params: {
          customer_id,
          payment_method,
          frequency,
          location_code,
          marketing_code_ids,
          program_ids,
        },
      })
      .pipe(map(res => res));
  }

  getLeakProductTax(
    customer_id: any,
    marketing_code_ids: any,
    program_ids: any,
    location_code: any,
    frequency: any,
    payment_term: any,
    total_down_payment: any,
  ) {
    const params = `customer_id=${customer_id}&payment_term=${payment_term}&total_down_payment=${total_down_payment}&frequency=${frequency}
&location_code=${location_code}&marketing_code_ids=${marketing_code_ids}&program_ids=${program_ids}`;
    return this.http.get(`${environment.Crm_Proxy_Service_API_Endpoint}products-tax-leak?${params}`).pipe(map(res => res));
  }

  getSmartAddress(street_number: string, street: string, zip_code: any, city: string, state: string) {
    // https://hos.accionbreeze.io/crm-proxy-service/address-validation/street-address?street_number=17street=clark%20ct&zip_code=07920&city=Basking%20Ridge&state=nj
    const street2 = street ? `&street=${encodeURIComponent(street)}` : '';
    const params = `street_number=${encodeURIComponent(street_number)}${street2}&zip_code=${zip_code}&city=${encodeURIComponent(
      city.trim(),
    )}&state=${state}`;
    return this.http.get(`${environment.Crm_Proxy_Service_API_Endpoint}address-validation/street-address?${params}`).pipe(map(res => res));
  }

  getAddressSuggestion(address: string) {
    const params = `prefix=${encodeURIComponent(address)}`;
    return this.http.get(`${environment.Crm_Proxy_Service_API_Endpoint}address-validation/address-suggest?${params}`).pipe(map(res => res));
  }

  getPastDue(enrollmentId: String, token?: any) {
    const options = token ? { headers: { authorization: token } } : {};
    return this.http.get(`${environment.Crm_Proxy_Service_API_Endpoint}get-past-due/${enrollmentId}`, options).pipe(map(res => res));
  }

  getGoogleRating() {
    return this.http.get(`${environment.Crm_Proxy_Service_API_Endpoint}googleapi/reviews`).pipe(
      map(res => {
        return res;
      }),
    );
  }
}
