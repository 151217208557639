import { Component, OnInit, Input } from '@angular/core';
import { NguCarouselConfig } from '@ngu/carousel';
import { Router } from '@angular/router';
import { SimpleModalService } from 'ngx-simple-modal';
import { ProductService } from '../../../product/services/product.service';
import { environment } from '../../../../environments/environment';
import { SessionStorageService } from '../../../core/services/session-storage.service';
import { LocationService } from '../../services/location.service';
import { ErrorModalComponent } from '../../../core/component/error-modal/error-modal.component';
import { SiteContentService } from '../../../core/services/siteContents';
import { UtilsService } from '../../../core/services/utils.service';
import { GlobalVariables } from '../../../core/services/global-variables.service';

@Component({
  selector: 'hos-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
})
export class CategoryComponent implements OnInit {
  @Input() pageName?: string;

  categories: Array<any> = [];

  selectedAddress: any;

  productServiceUrl: String;

  public categoriesItem: Array<any>;

  public carouselTile: NguCarouselConfig;

  locationCodeData: any;

  tempZip: any;

  customer_service_number: String;

  constructor(
    private productService: ProductService,
    private simpleModalService: SimpleModalService,
    private sessionStorageService: SessionStorageService,
    private locationService: LocationService,
    private siteContentService: SiteContentService,
    private utilsService: UtilsService,
    private router: Router,
    private globalVariables: GlobalVariables,
  ) {
    this.globalVariables.categoriesBasedOnZipcode.subscribe(data => {
      this.filterCategories(data);
    });
  }

  ngOnInit() {
    this.customer_service_number = environment.customer_service_number;
    this.productServiceUrl = environment.Image_API_Endpoint + environment.Product_service_API_Endpoint;
    this.carouselTile = {
      grid: {
        xs: 2,
        sm: 4,
        md: 5,
        lg: 6,
        all: 0,
      },
      slide: 3,
      speed: 400,
      point: {
        visible: false,
      },
      load: 2,
      touch: true,
      easing: 'ease',
    };

    this.productService.categories$().subscribe(categories => {
      this.categories = categories.body;
    });
  }

  goToCategory(category) {
    const savedAddress = this.sessionStorageService.getItem('selectedAddress');
    this.selectedAddress = savedAddress ? JSON.parse(savedAddress) : '';
    this.tempZip = this.sessionStorageService.getItem('tempZip');
    /* HWR-2796 */
    if (this.selectedAddress && this.selectedAddress.zipcode !== '' && !!this.selectedAddress.zipcode) {
      this.tempZip = this.selectedAddress.zipcode;
      this.catRedirect(category);
    } else if (this.tempZip !== '' && this.tempZip) {
      this.tempZip = this.sessionStorageService.getItem('tempZip');
      this.catRedirect(category);
    } else {
      this.router.navigate([`/service/${category.categoryName.trim().split(' ').join('-').toLowerCase()}`]);
    }
  }

  catRedirect(category: any) {
    let payload: any;
    this.locationService.getLocationCode(this.tempZip).subscribe(
      result => {
        const locationData = result.body;
        if (result.status === 200) {
          this.sessionStorageService.setItem('location_code', locationData.locationCodeId);
        } else {
          this.sessionStorageService.setItem('location_code', 'isOOF');
        }
        payload = {
          zipcode: this.tempZip,
          location_code: locationData.locationCodeId,
        };
        this.locationService.ZipcodeAndLocation = payload;
        const allProducts = true;
        this.productService.getMarketingCodes('', '', allProducts).subscribe(
          data => {
            if (data.status === 200) {
              const resData = data.body;
              let resultData = resData.result.filter(ele => ele.program.programId === category.reviewProgramId && ele.coverageType === 'REGULAR');
              if (resultData.length === 0) {
                resultData = resData.result.filter(ele => data.program.programId === category.reviewProgramId && ele.coverageType === 'UNLIMITED');
              }
              const WebProgName: any = resultData[0].webProgramName ? resultData[0].webProgramName : resultData[0].programName;
              let categoryParams: any;
              const typeParams = 'residential';
              if (category) {
                categoryParams = WebProgName.toString().split(' ').join('-').split(',').join('');
              }
              this.locationService.getLocationsContent(this.sessionStorageService.getItem('location_code')).subscribe(
                dataReceived => {
                  this.locationCodeData = dataReceived.body;
                  this.locationService.getMicroSiteForLocation(this.locationCodeData.locationCode).subscribe(
                    dataMicroSite => {
                      if (dataMicroSite.body.level === 'default') {
                        if (this.sessionStorageService.getItem('providerState')) {
                          this.sessionStorageService.setItem('isMicroSite', '0');
                          this.sessionStorageService.deleteItem('baseUrlState');
                          this.sessionStorageService.deleteItem('providerState');
                          let redirctUrl = `/product/${this.tempZip}/${this.locationCodeData.locationCode.toLowerCase()}`;
                          redirctUrl += typeParams ? `/${typeParams}` : '';
                          redirctUrl += categoryParams ? `/${categoryParams.toLowerCase()}` : '';
                          window.location.href = redirctUrl;
                        } else {
                          //   this.router.navigate(['/products/', this.tempZip, (this.locationCodeData.locationCode).toLowerCase()]);
                          let redirctUrl = `/product/${this.tempZip}/${this.locationCodeData.locationCode.toLowerCase()}`;
                          redirctUrl += typeParams ? `/${typeParams}` : '';
                          redirctUrl += categoryParams ? `/${categoryParams.toLowerCase()}` : '';
                          this.router.navigate([redirctUrl]);
                        }
                      } else {
                        this.sessionStorageService.setItem('isMicroSite', '1');
                        if (dataMicroSite.body.site.siteName) {
                          dataMicroSite.body.site.siteName = dataMicroSite.body.site.siteName.toLowerCase();
                        } else {
                          dataMicroSite.body.site.siteName = '';
                        }

                        let providerState = '';
                        providerState = this.sessionStorageService.getItem('providerState')
                          ? this.sessionStorageService.getItem('providerState').toLowerCase()
                          : '';
                        if (providerState === dataMicroSite.body.site.siteName) {
                          //   this.router.navigate(['/products/', this.tempZip, (this.locationCodeData.locationCode).toLowerCase()]);
                          let redirctUrl = `/product/${this.tempZip}/${this.locationCodeData.locationCode.toLowerCase()}`;
                          redirctUrl += typeParams ? `/${typeParams}` : '';
                          redirctUrl += categoryParams ? `/${categoryParams.toLowerCase()}` : '';
                          this.router.navigate([redirctUrl]);
                        } else {
                          if (dataMicroSite.body.site.siteName) {
                            dataMicroSite.body.site.siteName = dataMicroSite.body.site.siteName.toLowerCase();
                          } else {
                            dataMicroSite.body.site.siteName = '';
                          }
                          this.sessionStorageService.setItem('providerState', dataMicroSite.body.site.siteName);
                          this.sessionStorageService.setItem('baseUrlState', dataMicroSite.body.site.siteName);
                          let redirctUrl = `/${dataMicroSite.body.site.siteName}/product/${
                            this.tempZip
                          }/${this.locationCodeData.locationCode.toLowerCase()}`;
                          redirctUrl += typeParams ? `/${typeParams}` : '';
                          redirctUrl += categoryParams ? `/${categoryParams.toLowerCase()}` : '';
                          window.location.href = redirctUrl;
                        }
                      }
                    },
                    () => {},
                  );
                },
                () => {},
              );
            } else if (data.status !== 200) {
              this.showErrorModal('Error', 'No service available in your vicinity.');
            }
          },
          () => {
            this.simpleModalService.addModal(
              ErrorModalComponent,
              {
                title: 'We are sorry. We do not currently offer services in your location.',
                message: 'Please check back soon for product and service availability in your area.',
                showButton: 'close',
                alertType: 'info-type',
              },
              {
                wrapperClass: 'modal-main-class in',
                closeOnClickOutside: true,
              },
            );
          },
        );
      },
      () => {
        const strError: string = 'Please enter valid zip code to proceed.';
        this.showErrorModal('Alert', strError);
        this.sessionStorageService.setItem('location_code', 'isOOF');
      },
    );
  }

  showErrorModal(title: string, message: string) {
    this.simpleModalService
      .addModal(
        ErrorModalComponent,
        {
          title,
          message,
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: true,
        },
      )
      .subscribe(() => {});
  }

  getImage(imageName) {
    return this.utilsService.buildImageUrl(imageName);
  }

  async filterCategories(data) {
    let payload: any;
    if (data) {
      this.locationService.getLocationCode(data).subscribe(
        result => {
          const locationData = result.body;
          if (result.status === 200) {
            this.sessionStorageService.setItem('location_code', locationData.locationCodeId);
          } else {
            this.sessionStorageService.setItem('location_code', 'isOOF');
          }
          payload = {
            zipcode: data,
            location_code: locationData.locationCodeId,
          };
          this.locationService.ZipcodeAndLocation = payload;
          this.selectedAddress = {
            rawData: '',
            zipcode: data,
          };
          this.sessionStorageService.setItem('selectedAddress', JSON.stringify(this.selectedAddress));
          this.getCategoriesApiCall();
        },
        () => {
          this.sessionStorageService.setItem('location_code', 'isOOF');
        },
      );
    } else {
      const url = this.sessionStorageService.getItem('providerState');
      if (url) {
        const resp = await this.getPartnerDetailsApi();
        const getPartnerDetails = resp.body;
        /** ********** HWR-3642: multiple locations for FEH microsite  ******** */
        const locationCodeId = Array.isArray(getPartnerDetails.locationCodeId) ? getPartnerDetails.locationCodeId[0] : '';
        if (![undefined, 'undefined', null, 'null', ''].includes(locationCodeId)) {
          this.sessionStorageService.setItem('location_code', locationCodeId);
        }
        /** ********** HWR-3642: multiple locations for FEH microsite  ******** */
      }
      this.getCategoriesApiCall();
    }
  }

  getCategoriesApiCall() {
    this.productService.setCategories();
    this.productService.categories$().subscribe(
      categories => {
        this.categories = categories.body;
      },
      () => {},
    );
  }

  public getPartnerDetailsApi(): Promise<any> {
    return this.utilsService.getPartnerDetails$().toPromise();
  }
}
