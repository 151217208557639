/* eslint-disable max-classes-per-file */
import { Injectable } from '@angular/core';

export abstract class StorageService {
  public abstract get(): Storage;
}

// tslint:disable-next-line:max-classes-per-file
@Injectable()
export class LocalStorageService extends StorageService {
  public get(): Storage {
    try {
      return localStorage;
    } catch (e) {
      return e;
    }
  }
}
