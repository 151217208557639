import { CartItem } from './cart-item.model';

export class ShoppingCart {
  public items: CartItem[] = new Array<CartItem>();

  public grossTotal = 0;

  public customerId;

  public serviceAddress;

  public _id: string;

  public mkcType: string;

  public updateFrom(src: ShoppingCart) {
    this.items = src.items;
    this.grossTotal = src.grossTotal;
    this._id = src._id;
    this.serviceAddress = src.serviceAddress;
    this.customerId = src.customerId;
    this.mkcType = src.mkcType;
  }
}
