<section class="address-section custom-scroll">
  <div class="card" *ngFor="let address of serviceAddresses; let i = index" [ngClass]="{ selected: i === selectedAddress && pageType === 'home' }">
    <div class="card-body" (click)="pageType === 'home' && i !== selectedAddress ? changeAddress(i, address) : ''">
      <div class="row address-body mt-2">
        <span class="icon-size col-3 col-sm-3 col-md-4 col-lg-3">
          <img
            *ngIf="address?.serviceAddress?.contactType === 'RESIDENTIAL' || address?.serviceAddress?.contactType !== 'COMMERCIAL'"
            src="img/my-account/icons/Residential-Ico.svg"
          />
          <img *ngIf="address?.serviceAddress?.contactType === 'COMMERCIAL'" src="img/my-account/icons/Commercial-Ico.svg" />
        </span>
        <div class="address-detail col-9 col-sm-9 col-md-8 col-lg-9">
          <p class="address-content skiptranslate mb-0" id="serviceAddress">
            {{ address.serviceAddress.streetNumber }} {{ address.serviceAddress.streetName }}, <br />
            {{ address.serviceAddress.city }}, {{ address.serviceAddress.state }}, {{ address.serviceAddress.zipcode }}
          </p>
          <a (click)="seeAddressDetail(serviceAddresses[selectedAddress])" class="non-href-links see-detail-text"><u>SEE DETAILS></u></a>
        </div>
      </div>
    </div>
  </div>
</section>
<section *ngIf="pageType === 'home'" class="mobile-address-box">
  <div class="itmes-mob" id="items">
    <div class="btn-group custom-dropdown" dropdown placement="top">
      <button dropdownToggle type="button" class="btn drop-btn dropdown-toggle">
        <div class="address-detail-mob skiptranslate" *ngIf="selectedAddress > -1 && !!serviceAddresses[selectedAddress]">
          {{ serviceAddresses[selectedAddress]?.serviceAddress.streetNumber }} {{ serviceAddresses[selectedAddress]?.serviceAddress.streetName }},
          <br />
          {{ serviceAddresses[selectedAddress]?.serviceAddress.city }}, {{ serviceAddresses[selectedAddress]?.serviceAddress.state }},
          {{ serviceAddresses[selectedAddress]?.serviceAddress.zipcode }}
          <br />
        </div>
      </button>

      <ul *dropdownMenu class="dropdown-menu skiptranslate" role="menu">
        <li
          class="addr-txt"
          role="menuitem"
          *ngFor="let address of serviceAddresses; let i = index"
          (click)="pageType === 'home' ? changeAddress(i, address) : ''"
        >
          <a class="dropdown-item" [ngClass]="{ active: i === selectedAddress }">
            {{ address.serviceAddress.streetNumber }} {{ address.serviceAddress.streetName }}, <br />
            {{ address.serviceAddress.city }}, {{ address.serviceAddress.state }}, {{ address.serviceAddress.zipcode }}</a
          >
        </li>
      </ul>
    </div>
    <div class="see-addr-parent col-12 mt-1">
      <a (click)="seeAddressDetail(serviceAddresses[selectedAddress])" class="see-details-mob">See Details ></a>
    </div>
  </div>
  <div class="selected-address-view skiptranslate" *ngIf="selectedAddress > -1 && !!serviceAddresses?.selectedAddress">
    {{ serviceAddresses[selectedAddress]?.serviceAddress.streetNumber }} {{ serviceAddresses[selectedAddress]?.serviceAddress.streetName }}, <br />
    {{ serviceAddresses[selectedAddress]?.serviceAddress.city }}, {{ serviceAddresses[selectedAddress]?.serviceAddress.state }},
    {{ serviceAddresses[selectedAddress]?.serviceAddress.zipcode }}
    <br />
    <div class="text-center mt-1">
      <a (click)="seeAddressDetail(serviceAddresses[selectedAddress])" class="see-detail-text non-href-links">See Details</a>
    </div>
  </div>
</section>
