import { NgModule } from '@angular/core';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

// Components
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './component/footer/footer.component';
import { NavigationBarComponent } from './component/navigation-bar/navigation-bar.component';
import { TopBarComponent } from './component/top-bar/top-bar.component';
import { RightNavigationMenuComponent } from './component/right-navigation-menu/right-navigation-menu.component';

// Modules
import { CoreModule } from '../core/index';
import { CustomerNotificationComponent } from './component/customer-notification/customer-notification.component';

@NgModule({
  declarations: [FooterComponent, NavigationBarComponent, TopBarComponent, RightNavigationMenuComponent, CustomerNotificationComponent],
  exports: [FooterComponent, NavigationBarComponent, TopBarComponent, RightNavigationMenuComponent],
  imports: [RouterModule, CommonModule, CoreModule, BsDropdownModule.forRoot()],
})
export class LayoutModule {}
