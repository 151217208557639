<div class="modal-dialog provider-modal modal-content modal-background">
  <div class="modal-body">
    <div class="row p-l">
      <div class="col-12 col-md-4 col-lg-4 parent-provider">
        <img [src]="providerLogo" class="provider-logo-img" [alt]="providerName" />
      </div>
      <div class="col-12 col-md-8 col-lg-8 set-left-border">
        <p class="provider-by set-text-align">
          <span class="non-href-links navigate-link skiptranslate">{{ providerName }}</span> in partnership with
          <span class="skiptranslate">American Water Resources</span>, provides products to our customers in:
        </p>

        <div class="change-zipcode set-text-align skiptranslate" *ngIf="selectedAddress?.rawData?.formatted_address">
          {{ selectedAddress?.rawData.formatted_address }}.
          <!-- <a class="non-href-links" >Change ZIP Code </a> -->
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer login-footer">
    <p class="isyourprovider">
      <span *ngIf="locationCode && locationCode !== 'PA200'">Is {{ providerName }} your service provider?</span>
      <span *ngIf="locationCode && locationCode === 'PA200'">Are you a property owner in the City of Philadelphia?</span>
    </p>
    <div class="row justify-content-center">
      <div class="col-8 col-md-12">
        <button class="btn primary-btn set-width mt-1 btn-margin-right" (click)="confirm()">YES</button>
        <button class="btn primary-btn set-width mt-1 btn-margin-left" (click)="cancel()">NO</button>
      </div>
    </div>
  </div>
</div>
