import { PLATFORM_ID, Inject, Injectable } from '@angular/core';
import { Observable, Observer, forkJoin, of, throwError } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { nanoid } from 'nanoid';
import { catchError, map } from 'rxjs/operators';
import { StorageService } from '../../core/services/local-storage.service';
import { CartItem } from '../model/cart-item.model';
import { ShoppingCart } from '../model/cart.model';
import { environment } from '../../../environments/environment';
import { HttpService } from '../../core/services/http';
import { UserService } from '../../user/services/user.service';
import { SessionStorageService } from '../../core/services/session-storage.service';

import { GtagService } from '../../gtag/gtag.service';
import { GtagPayloadGeneratorService } from '../../core/services/gtag-payload-generator';

const CART_KEY = 'cartCheckout';
@Injectable()
export class CartQuickCheckoutService {
  private storage: Storage;

  private subscriptionObservable: Observable<ShoppingCart>;

  private subscribers: Array<Observer<ShoppingCart>> = new Array<Observer<ShoppingCart>>();

  private cuid: any; // cuid for first time create cart before call

  public constructor(
    private storageService: StorageService,
    private http: HttpService,
    private userService: UserService,
    private sessionStorageService: SessionStorageService,
    private gtagService: GtagService,
    private gtagPayloadGeneratorService: GtagPayloadGeneratorService,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.storage = this.storageService.get();
    }
    this.cuid = nanoid();
    const userDetails = this.userService.getSignedInUser();
    if (!userDetails) {
      this.subscriptionObservable = new Observable<ShoppingCart>((observer: Observer<ShoppingCart>) => {
        this.subscribers.push(observer);
        observer.next(this.retrieveCheckout());
        return () => {
          this.subscribers = this.subscribers.filter(obs => obs !== observer);
        };
      });
    }
  }

  /**
   * Get cart subscription observable.
   * @returns {Observable<Response>}
   */
  public get(): Observable<ShoppingCart> {
    return this.subscriptionObservable;
  }

  /**
   * Add item to cart.
   * @param product
   */
  public addItem(product: any, done?: any): void {
    const cart = this.retrieveCheckout();
    let item = cart.items.find(p => p.productId === product._id);
    this.checkAndInitLocalCart(cart, cartId => {
      const newCart = this.retrieveCheckout();
      newCart.mkcType = product.mkcType;
      item = new CartItem();
      item.type = 'service';
      item.productId = product._id;
      item.status = product.status;
      item.quantity = 1;
      item.locationId = product.locationId;
      item.zipcode = product.zipcode;
      item.customerNo = product.customerNo;
      item.isUpgrade = product.isUpgrade;
      item.enrollmentGuid = product.enrollmentGuid;
      item.contactType = product.contactType; // HWR-2857
      item.details = product;
      if (typeof product.leakFrequencyVal !== 'undefined') {
        item.leakFrequencyVal = product.leakFrequencyVal;
      }
      if (typeof product.description !== 'undefined') {
        item.description = product.description;
      }
      if (typeof product.isRenew !== 'undefined') {
        item.isRenew = product.isRenew;
        item.parentEnrollmentDate = product.parentEnrollmentDate;
        item.parentEnrollmentId = product.parentEnrollmentId;
      }
      this.addItemToCartBeforeCall(cartId, item).subscribe(
        data => {
          if (data.status === 200 || data.status === 201) {
            const response = data.body;
            item._id = response.items[response.items.length - 1]._id;
            item.details = response.items[response.items.length - 1].details;
            if (data.status === 200) {
              newCart.items.push(item);
              this.ecommerceEventsForGA(response.items[response.items.length - 1], 'addToCart');
            } else {
              newCart.items = response.items;
              this.ecommerceEventsForGA(response.items[response.items.length - 1], 'addToCart');
            }
            this.calculateCart(newCart);
            this.save(newCart);
            this.dispatch(newCart);
            done(null, []);
          } else if (data.status === 409) {
            done(true, data.body);
          }
        },
        err => {
          done(true, err);
        },
      );
    });
  }

  /**
   * Remove item from cart.
   * @param product
   */
  public removeItem(product: any, done: any): void {
    const cart = this.retrieveCheckout();
    const selectedData = cart.items.find(o => o.productId === product.productId);
    const index = cart.items.findIndex(o => o.productId === product.productId);
    if (index !== -1) {
      cart.items.splice(index, 1);
    }
    this.calculateCart(cart);
    this.save(cart);
    this.dispatch(cart);
    done(null, true);
    const guestToken: any = this.sessionStorageService.getItem('guestToken');
    if (guestToken) {
      this.deleteFromCart(cart._id, selectedData._id).subscribe(
        data => {
          if (data.status === 200) {
            this.ecommerceEventsForGA(product, 'removeItemFromCart');
            done(null, true);
          }
        },
        () => {
          done(true, null);
        },
      );
    } else {
      this.ecommerceEventsForGA(product, 'removeItemFromCart');
    }
  }

  /**
   * Empty cart.
   */
  public empty(): void {
    const newCart = new ShoppingCart();
    this.save(newCart);
    this.dispatch(newCart);
  }

  /**
   * Update cart items.
   */
  public updateCartItems(cart: any): void {
    this.calculateCart(cart);
    this.save(cart);
    this.dispatch(cart);
  }

  /**
   * Create cart API call.
   * @param cart
   * @returns {http response}
   */
  public createCartBeforeCall() {
    const date = new Date();
    const data = {
      body: {
        createdAt: date.toISOString(),
        items: [],
        updatedAt: date.toISOString(),
        _id: this.cuid,
      },
      status: 200,
    };
    // todo
    return of(data);
  }

  /**
   * Create cart API call.
   * @param cart
   * @returns {http response}
   */
  public createCart(cart: any, token?: string) {
    return this.http
      .post(`${environment.Cart_service_API_Endpoint}cart`, JSON.stringify(cart), {
        authorization: token,
      })
      .pipe(map(res => res));
  }

  /**
   * Get cart API call.
   * @param cartId
   * @returns {http response}
   */
  public getCart(cartId: string) {
    return this.http.get(`${environment.Cart_service_API_Endpoint}cart/${cartId}`).pipe(map(res => res));
  }

  /**
   * Get customer's saved cart API call.
   * @param customerId
   * @returns {http response}
   */
  public getCustomerCart(customerId?: string, token?: string) {
    const options = token ? { headers: { authorization: token } } : undefined;
    return this.http.get(`${environment.Cart_service_API_Endpoint}cart/customer/${customerId}`, options).pipe(map(res => res));
  }

  /**
   * Add item to cart API call.
   * @param cartId
   * @param cartItem
   * @returns {http response}
   */
  public addItemToCartBeforeCall(cartId: string, cartItem: CartItem): Observable<any> {
    const payload = {
      items: [],
    };
    const cartObj = {
      type: cartItem.type,
      productId: cartItem.productId,
      status: cartItem.status,
      quantity: cartItem.quantity,
      locationId: cartItem.locationId,
      zipcode: cartItem.zipcode,
      customerNo: cartItem.customerNo,
      isUpgrade: cartItem.isUpgrade,
      enrollmentGuid: cartItem.enrollmentGuid,
      description: cartItem.description,
      isRenew: cartItem.isRenew,
      parentEnrollmentDate: cartItem.parentEnrollmentDate,
      parentEnrollmentId: cartItem.parentEnrollmentId,
      contactType: cartItem.contactType,
      _id: cartId,
      details: cartItem.details,
      leakFrequencyVal: undefined,
    };
    if (typeof cartItem.leakFrequencyVal !== 'undefined') {
      cartObj.leakFrequencyVal = cartItem.leakFrequencyVal;
    }
    payload.items.push(cartObj);
    const cartData: any = JSON.parse(this.sessionStorageService.getItem('cartCheckout'));
    let cartItemProductIds: any;
    if (cartData && cartData.items && cartData.items.length > 0) {
      cartItemProductIds = cartData.items.map(item => item.productId);
      // To restrict if bundle is already added on cart or trying to add bundle
      if (cartItem && cartItem.details) {
        let data: any = {
          body: payload,
          status: 201,
        };

        const hasDuplicateData = this.detectDuplicateItems(payload.items[0], cartData.items);
        if (hasDuplicateData) {
          if (hasDuplicateData.isAlreadyBundle) {
            data.isAlreadyBundle = hasDuplicateData.isAlreadyBundle;
          }
          data.status = 409;
          return throwError(() => data);
        }

        const hasPlumbingOnCart = this.checkPlumbingOnCart(payload.items[0], cartData.items);
        if (hasPlumbingOnCart) {
          data = {
            body: payload,
            status: 409,
          };
          return throwError(() => data);
        }

        payload.items = this.convertCartForBundles(payload.items[0], cartData.items);

        return of(data);
      }
      if (cartItemProductIds.includes(payload.items[0].productId)) {
        const data = {
          body: payload,
          status: 409,
        };
        return throwError(() => data);
      }
    }
    const data = {
      body: payload,
      status: 200,
    };
    return of(data);
  }

  /**
   * Add item to cart API call.
   * @param cartId
   * @param cartItem
   * @returns {http response}
   */
  public addItemToCart(cartId: string, cartItem: CartItem, token?) {
    const payload = {
      items: [],
    };
    const cartObj = {
      type: cartItem.type,
      productId: cartItem.productId,
      status: cartItem.status,
      quantity: cartItem.quantity,
      locationId: cartItem.locationId,
      zipcode: cartItem.zipcode,
      customerNo: cartItem.customerNo,
      isUpgrade: cartItem.isUpgrade,
      enrollmentGuid: cartItem.enrollmentGuid,
      description: cartItem.description,
      isRenew: cartItem.isRenew,
      parentEnrollmentDate: cartItem.parentEnrollmentDate,
      parentEnrollmentId: cartItem.parentEnrollmentId,
      contactType: cartItem.contactType, // HWR-2857
      leakFrequencyVal: undefined,
    };
    if (typeof cartItem.leakFrequencyVal !== 'undefined') {
      cartObj.leakFrequencyVal = cartItem.leakFrequencyVal;
    }
    payload.items.push(cartObj);
    return this.http
      .put(`${environment.Cart_service_API_Endpoint}cart/${cartId}/item`, payload, {
        authorization: token,
      })
      .pipe(
        map(res => {
          if (res.status === 200) {
            this.save(res.body);
            this.dispatch(res.body);
          } else if (res.status === 201) {
            const previousCart = JSON.parse(this.storage.getItem(CART_KEY));
            const newCart = {
              ...previousCart,
              items: res.body.data.cartData,
            };
            this.save(newCart);
            this.dispatch(newCart);
            return res;
          }
          return res;
        }),
        catchError(error => of(error)),
      );
  }

  /**
   * Add multiple item to cart API call.
   * @param cartId
   * @param cartItems
   * @returns {http response}
   */
  public addMultiItemToCart(cartId: string, cartItems: Array<CartItem>, itemStatus?: String, done?: any) {
    const apiArray = cartItems.map(id => this.addItemToCartBeforeCall(cartId, id));

    forkJoin(apiArray).subscribe({
      next: results => {
        const resultsSucess = [];
        const resultsBundledCustService = [];
        const resultsBundled = [];
        const duplicateMatch = [];
        for (let i = 0; i < results.length; i++) {
          if (results[i].status === 200) {
            resultsSucess.push(results[i]);
          } else if (results[i].status === 400) {
            resultsBundledCustService.push(results[i]);
          } else if (results[i].status === 201) {
            resultsBundled.push(results[i]);
          } else if (results[i].status === 409) {
            duplicateMatch.push(results[i]);
          }
        }
        done(resultsSucess, resultsBundledCustService, resultsBundled, duplicateMatch);
      },
    });
  }

  public addMultiItemToCartWithToken(cartId: string, cartItems: Array<CartItem>, token: string, done?: any) {
    const apiArray = cartItems.map(id => this.addItemToCart(cartId, id, token));

    forkJoin(apiArray).subscribe({
      next: results => {
        const resultsSucess = [];
        const resultsBundledCustService = [];
        const resultsBundled = [];
        const duplicateMatch = [];
        for (let i = 0; i < results.length; i++) {
          if (results[i].status === 200) {
            resultsSucess.push(results[i]);
          } else if (results[i].status === 400) {
            resultsBundledCustService.push(results[i]);
          } else if (results[i].status === 201) {
            resultsBundled.push(results[i]);
          } else if (results[i].status === 409) {
            duplicateMatch.push(results[i]);
          }
        }
        done(resultsSucess, resultsBundledCustService, resultsBundled, duplicateMatch);
      },
    });
  }

  /**
   * Update in cart item status.
   * @param cartId
   * @param status
   * @returns {http response}
   */
  public updateItemStatus(cartId: String, status: String) {
    return this.http
      .put(`${environment.Cart_service_API_Endpoint}cart/update-status/${status}/id/${cartId}`, JSON.stringify({}))
      .pipe(map(res => res));
  }

  /**
   * Remove from cart API call.
   * @param cartId
   * @param cartItemId
   * @returns {http response}
   */
  public deleteFromCart(cartId: string, cartItemId: string) {
    return this.http.delete(`${environment.Cart_service_API_Endpoint}cart/${cartId}/item/${cartItemId}`).pipe(map(res => res));
  }

  /**
   * Update customer id to cart object.
   * @param customerId
   */
  public updateCustomerToCart(customerId: string) {
    const cart = this.retrieveCheckout();
    cart.customerId = customerId;
    const payload = {
      customerId,
    };
    if (cart._id) {
      this.http.put(`${environment.Cart_service_API_Endpoint}cart/${cart._id}/item`, JSON.stringify(payload)).subscribe(res => {
        if (res.status === 200) {
          this.save(cart);
          this.dispatch(cart);
        }
      });
    }
  }

  /**
   * Sync local and saved cart data & update customer Id to cart object.
   * @param customerId
   */
  public getCustomerCartToLocal(customerId?: string, guestToken?: any, done?: any) {
    this.getCustomerCart(customerId, guestToken).subscribe(
      data => {
        if (data.status === 200) {
          const cartData = data.body;
          const localCart = this.retrieveCheckout();
          let cart = new ShoppingCart();
          cart = cartData;
          if (!!cart && localCart._id !== cart._id) {
            if (localCart._id) {
              const notSavedData = [];
              localCart.items.forEach(localItem => {
                const dataExist = cart.items.find(o => o.productId === localItem.productId);
                if (!dataExist) {
                  if (cart.items.length === 0) {
                    notSavedData.push(localItem);
                  } else if (cart.items[0].zipcode === localItem.zipcode) {
                    notSavedData.push(localItem);
                  }
                }
              });
              if (notSavedData.length > 0) {
                this.addMultiItemToCart(cart._id, notSavedData, '', (resultsSucess, resultsBundledCustService, resultsBundled, duplicateMatch) => {
                  if (resultsBundled.length > 0) {
                    const response = resultsBundled[0].json();
                    const oldCart = this.retrieveCheckout();
                    oldCart.items = response.data.cartData;
                    this.updateCartItems(oldCart);
                    done(null, response, 201);
                  } else {
                    if (resultsSucess.length > 0) {
                      for (let i = 0; i < resultsSucess.length; i++) {
                        if (resultsSucess[i].status === 200) {
                          cart.items = resultsSucess[i].json().items;
                          this.updateCartItems(cart);
                        }
                      }
                    }
                    if (resultsBundledCustService.length > 0) {
                      this.updateCartItems(cart);
                      done(400);
                    } else if (duplicateMatch.length > 0) {
                      this.updateCartItems(cart);
                      done(409);
                    } else {
                      done(200);
                    }
                  }
                });
              } else {
                this.updateCartItems(cart);
                if (done) {
                  done(200);
                }
              }
            } else {
              this.updateCartItems(cart);
              if (done) {
                done(200);
              }
            }
          } else {
            // this.updateCustomerToCart(customerId);
            this.save(cartData);
            this.dispatch(cartData);
            if (done) {
              done(200);
            }
          }
        }
      },
      () => {
        if (done) {
          done(200);
        }
      },
    );
  }

  /**
   * Update cart based on location id.
   * @param cart
   * @param done
   */
  public updateCartOnCustomerNo(customerNo: String, done?: any) {
    const cart = this.retrieveCheckout();
    const deleteData: any = [];
    cart.items.forEach(data => {
      if (customerNo !== data.customerNo) {
        deleteData.push(data);
      }
    });
    if (deleteData.length > 0) {
      const apiArray = deleteData.map(product => this.deleteFromCart(cart._id, product._id));

      forkJoin(apiArray).subscribe({
        next: (results: any[]) => {
          for (let i = 0; i < results.length; i++) {
            if (results[i].status !== 200) {
              done(`Error deleting ${deleteData[i].details.webProgramName}Id`, null);
              return;
            }
          }
          const deletedNames: any = [];
          deleteData.forEach(data => {
            const index = cart.items.findIndex(o => o._id === data._id);
            if (index !== -1) {
              cart.items.splice(index, 1);
            }
            deletedNames.push(data.details.webProgramName);
          });
          this.calculateCart(cart);
          this.save(cart);
          this.dispatch(cart);
          done(null, deletedNames);
        },
      });
    } else {
      done(null, []);
    }
  }

  /**
   * Update cart items status.
   * @param statusType
   * @param done
   */
  public updateProductCartStatus(statusType: String, done: any) {
    const cartData: any = this.retrieveCheckout();
    this.updateItemStatus(cartData._id, statusType).subscribe(
      response => {
        if (response.status === 200) {
          if (statusType === 'ASYNC_IN_PROGRESS') {
            cartData.items = [];
          } else if (statusType === 'PAYMENT_DECLINED') {
            cartData.items.forEach(product => {
              const p = product;
              p.status = 'PAYMENT_DECLINED';
            });
          }
          this.calculateCart(cartData);
          this.save(cartData);
          this.dispatch(cartData);
          done(null, cartData);
        } else {
          done(true, null);
        }
      },
      () => {
        done(true, null);
      },
    );
  }

  /**
   * Calculate cart items total amount.
   * @param cart
   */
  public calculateCart(cart: ShoppingCart): void {
    const c = cart;
    c.grossTotal = cart.items.map(item => item.details.monthlyPrice).reduce((previous, current) => Number(previous) + Number(current), 0);
    c.grossTotal = Number(cart.grossTotal.toFixed(2));
  }

  /**
   * Retrieve Checkout local saved cart data.
   * @returns {cart}
   */
  private retrieveCheckout(): ShoppingCart {
    const cart = new ShoppingCart();
    if (isPlatformBrowser(this.platformId)) {
      const storedCart = this.storage.getItem(CART_KEY);
      if (storedCart) {
        cart.updateFrom(JSON.parse(storedCart));
      }
    }
    return cart;
  }

  /**
   * Save cart item to local storage.
   * @param cart
   */
  public save(cart: ShoppingCart): void {
    if (isPlatformBrowser(this.platformId)) {
      this.storage.setItem(CART_KEY, JSON.stringify(cart));
    }
  }

  /**
   * Dispatch subscribers.
   * @param cart
   */
  public dispatch(cart: ShoppingCart): void {
    this.subscribers.forEach(sub => {
      try {
        sub.next(cart);
      } catch (e) {
        // we want all subscribers to get the update even if one errors.
      }
    });
  }

  /**
   * Check and initialize cart object on adding initial item.
   * @param cart
   * @param done
   */
  private checkAndInitLocalCart(cart: any, done: any) {
    if (!cart._id) {
      this.createCartBeforeCall().subscribe(
        data => {
          if (data.status === 200) {
            const resData = data.body;
            const newCart = new ShoppingCart();
            newCart._id = resData._id;
            // if (!!resData.customerId) {
            //     newCart.customerId = resData.customerId;
            // }
            if (isPlatformBrowser(this.platformId)) {
              this.storage.setItem(CART_KEY, JSON.stringify(newCart));
            }
            newCart.updateFrom(newCart);
            done(newCart._id);
          }
        },
        () => {
          done(null);
        },
      );
    } else {
      done(cart._id);
    }
  }

  /**
   * Update Leak Cart Payment Option API call.
   * @param cartId
   * @returns {http response}
   */
  public updateCartValue(cartId: string, value: string, customerId: string) {
    const payload = {
      data: {
        leakFrequencyVal: value,
        customerId,
      },
    };
    return this.http.put(`${environment.Cart_service_API_Endpoint}cart/${cartId}/item/update`, payload).pipe(
      map(res => {
        if (res.status === 200) {
          this.save(res.body);
          // this.dispatch(res);
        }
        return res;
      }),
    );
  }

  public deleteCustomerCart(customerId: string) {
    return this.http.delete(`${environment.Cart_service_API_Endpoint}cart/customer?customerId=${customerId}`);
  }

  public convertCartForBundles(payload: any, cart: any) {
    const setOfIds = new Set(payload.details.program.products);
    const finalCartItems = cart.filter(cartItem => !cartItem.details.program.products.every(productId => setOfIds.has(productId)));

    finalCartItems.push(payload);

    return finalCartItems;
  }

  public detectDuplicateItems(payload?: any, cart?: any) {
    const flatCartItems = cart.map(item => item.details.program.products).flat();
    const setOfIds = new Set(flatCartItems);
    const isDuplicate = payload.details.program.products.every(productId => setOfIds.has(productId));

    if (isDuplicate && flatCartItems.length > 1 && payload.details.program.products.length < flatCartItems.length) {
      return {
        isAlreadyBundle: true,
        data: payload,
      };
    }

    return isDuplicate;
  }

  public checkPlumbingOnCart(payload?: any, cart?: any) {
    const flatCartItems = cart.map(item => item.details.program.programId).flat();
    const found = environment.plumbingProgramIds.some(
      data => flatCartItems.includes(data) && environment.plumbingProgramIds.includes(payload.details.program.programId),
    );
    if (found) {
      return true;
    }
    return false;
  }

  public ecommerceEventsForGA(product: any, type: string) {
    let actionItems;

    if (product.details) {
      const productPrice = parseFloat(product.details.monthlyPrice);
      actionItems = {
        affiliation: 'AW Checkout',
        value: productPrice.toFixed(2).toString(),
        currency: 'USD',
      };
      actionItems.items = [...this.gtagPayloadGeneratorService.generatingPayloadsForGA(product, productPrice)];
    } else {
      const productPrice = parseFloat(product.monthlyPrice);
      actionItems = {
        affiliation: 'AW Checkout',
        value: productPrice.toFixed(2).toString(),
        currency: 'USD',
      };
      actionItems.items = [...this.gtagPayloadGeneratorService.generatingPayloadsForGA(product, productPrice)];
    }

    if (type === 'addToCart') {
      this.gtagService.addToCart(actionItems);
    }

    if (type === 'addUpgradeToCart') {
      this.gtagService.addUpgradeToCart(actionItems);
    }

    if (type === 'removeItemFromCart') {
      this.gtagService.removeFromCart(actionItems);
    }
  }
}
