<div class="pr-0 mr-0 mt-1" id="{{ selectedAddIndex }}-claims-list" *ngIf="isDesktopView && claims?.length > 0">
  <div *ngFor="let claim of claims; let i = index" id="single-div-claim" table-responsive class="container claims-container-desktop pr-0 mr-0">
    <table class="table table-hover claims-table position-relative text-center mb-1" [ngClass]="{ 'multi-claims': claims.length > 1 }">
      <thead>
        <tr class="claims-table-header claims-table-header-text">
          <th><img class="fileClaimsIcon" src="../../../../img/my-account-claims/ClaimIcon.svg" alt="claims-icon" /></th>
          <th>Claim Status</th>
          <th>Claim Number</th>
          <th>
            <img src="../../../../img/my-account-claims/calendar-plus-solid.svg" alt="Claims-submit" class="th-claims-icon mb-1 mr-1" />Claim
            Submitted
          </th>
          <th>
            <img src="../../../../img/my-account-claims/calendar-minus-solid.svg" alt="Claims-close" class="th-claims-icon mb-1 mr-1" />Claim Closed
          </th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr class="claims-table-body-text bg-white">
          <td></td>
          <td class="claims-status">
            <i
              class="fa fa-lg"
              [ngClass]="{
                'fa-check-circle fa-claims-open': claim?.HOSstatus === 'Open',
                'fa-lock fa-sm icon-lock-circle': claim?.HOSstatus === 'Closed'
              }"
              aria-hidden="true"
              style="margin-right: 3px"
            ></i
            >&nbsp;{{ claim?.HOSstatus }}
          </td>
          <td>{{ claim?.ClaimName ? '#' + claim?.ClaimName : '-' }}</td>
          <td>{{ claim?.dateOfClaim ? (claim?.dateOfClaim.split('T')[0] | date : 'MM/dd/yy') : '-' }}</td>
          <td>{{ claim?.ClaimClosedDate ? (claim?.ClaimClosedDate.split('T')[0] | date : 'MM/dd/yy') : '-' }}</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="container pr-0 claims-container position-relative" id="{{ selectedAddIndex }}-claims-list" *ngIf="!isDesktopView && claims?.length > 0">
  <div *ngFor="let claim of claims; let i = index" class="container claims-div position-relative mb-1" table-responsive>
    <div class="row">
      <div class="col col-header-text">Claim Status</div>
      <div class="col col-text claims-status">
        <i
          class="fa fa-lg"
          [ngClass]="{
            'fa-check-circle fa-claims-open': claim?.HOSstatus === 'Open',
            'fa-lock fa-sm icon-lock-circle': claim?.HOSstatus === 'Closed'
          }"
          aria-hidden="true"
        ></i
        >&nbsp;{{ claim?.HOSstatus }}
      </div>
    </div>
    <div class="row">
      <div class="col col-header-text">Claim Number</div>
      <div class="col col-text">
        {{ claim?.ClaimName ? '#' + claim?.ClaimName : '-' }}
      </div>
    </div>
    <div class="row">
      <div class="col col-header-text">
        <img src="../../../../img/my-account-claims/calendar-plus-solid.svg" alt="Claims-submit" class="claims-header-icon mb-1" />C Submitted
      </div>
      <div class="col col-text">
        {{ claim?.dateOfClaim ? (claim?.dateOfClaim | date : 'MM/dd/yy') : '-' }}
      </div>
    </div>
    <div class="row">
      <div class="col col-header-text">
        <img src="../../../../img/my-account-claims/calendar-minus-solid.svg" alt="Claims-close" class="claims-header-icon mb-1" />C Closed
      </div>
      <div class="col col-text">
        {{ claim?.ClaimClosedDate ? (claim?.ClaimClosedDate | date : 'MM/dd/yy') : '-' }}
      </div>
    </div>
  </div>
</div>
