import { Injectable, PLATFORM_ID, Inject, Optional } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { LinkService } from './link-service';
import { SeoConfig } from '../model/seo-config.model';
import { UtilsService } from './utils.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class SeoService {
  isbrowser: boolean = true;

  isserver: boolean = false;

  seoURL = '';

  constructor(
    private titleService: Title,
    private metaService: Meta,
    private linkService: LinkService,
    private utilsService: UtilsService,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Optional() @Inject('serverUrl') protected origin: string,
    @Optional() @Inject('pathname') protected pathname: string,
  ) {
    this.isbrowser = isPlatformBrowser(platformId);
    this.isserver = isPlatformServer(platformId);
    if (this.isbrowser) {
      this.seoURL = window.location.origin + window.location.pathname;
    }
    if (this.isserver) {
      this.seoURL = this.origin + this.pathname;
    }
  }

  public setTitle(newTitle: string) {
    console.log(newTitle);
    this.titleService.setTitle(newTitle);
  }

  public setCanonicalTags(programName?: string) {
    if (programName) {
      if (this.isbrowser) {
        this.seoURL = `${window.location.origin}/service/${programName}`;
      }
      if (this.isserver) {
        this.seoURL = `${this.origin}/service/${programName}`;
      }
    } else {
      if (this.isbrowser) {
        this.seoURL = window.location.origin + window.location.pathname;
      }
      if (this.isserver) {
        this.seoURL = this.origin + this.pathname;
      }
    }

    this.linkService.addTag(
      {
        rel: 'canonical',
        href: this.seoURL,
      },
      true,
    );
    this.metaService.removeTag('name=robots');
  }

  public generateTitleTags(config: SeoConfig) {
    const logoURL = `${environment.Image_API_Endpoint}${environment.Content_service_API_Endpoint}/img/logo/AW_Logo_02.png`;
    if (config.image === '') {
      config.image = this.utilsService.buildImageUrl(logoURL);
    }
    /* Set main title */
    this.setTitle(config.title);

    /* General meta tags */
    this.metaService.updateTag({
      name: 'description',
      content: config.description,
    });
    this.metaService.updateTag({
      name: 'keywords',
      content: config.keywords,
    });

    /* Twitter meta tags */
    this.metaService.updateTag({
      name: 'twitter:card',
      content: 'summary',
    });
    this.metaService.updateTag({
      name: 'twitter:title',
      content: config.title,
    });
    this.metaService.updateTag({
      name: 'twitter:description',
      content: config.description,
    });
    this.metaService.updateTag({
      name: 'twitter:image',
      content: config.image,
    });
    this.metaService.updateTag({
      name: 'twitter:image:width',
      content: '400',
    });
    this.metaService.updateTag({
      name: 'twitter:image:height',
      content: '300',
    });
    this.metaService.updateTag({
      name: 'twitter:url',
      content: this.seoURL,
    });
    this.metaService.updateTag({
      name: 'twitter:site',
      content: '@amwater',
    });

    /* OpenGraph meta tags */
    this.metaService.updateTag({
      property: 'og:title',
      content: config.title,
    });
    this.metaService.updateTag({
      property: 'og:type',
      content: 'website',
    });
    this.metaService.updateTag({
      property: 'og:image:type',
      content: 'image/png',
    });
    this.metaService.updateTag({
      property: 'og:image',
      content: config.image,
    });
    this.metaService.updateTag({
      property: 'og:image:width',
      content: '400',
    });
    this.metaService.updateTag({
      property: 'og:image:height',
      content: '300',
    });
    this.metaService.updateTag({
      property: 'og:description',
      content: config.description,
    });
    this.metaService.updateTag({
      property: 'og:url',
      content: this.seoURL,
    });
    // this.metaService.updateTag({ property: 'og:site_name', content: config.sitename });
  }

  public metaSetNoIndexNoFollow() {
    this.linkService.removeTag('rel=canonical');
    this.metaService.addTag({ name: 'robots', content: 'noindex, nofollow' });
  }
}
