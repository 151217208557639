import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  HostListener,
  ElementRef,
  EventEmitter,
  PLATFORM_ID,
  Inject,
  Renderer2,
  ViewChild,
  Optional,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SimpleModalService } from 'ngx-simple-modal';
import { Location, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { NguCarouselConfig } from '@ngu/carousel';
import { trigger, style, animate, transition } from '@angular/animations';
import { SessionStorageService } from '../../../core/services/session-storage.service';
import { UserService } from '../../../user/services/user.service';
import { CartService } from '../../../cart/services/cart.service';
import { ProductService } from '../../../product/services/product.service';
import { environment } from '../../../../environments/environment';
import { ErrorModalComponent } from '../../../core/component/error-modal/error-modal.component';
import { ProviderModalComponent } from '../../../product/component/provider-modal/provider-modal.component';
import { SeoService } from '../../../core/services/seo.service';
import { SeoConfig } from '../../../core/model/seo-config.model';
import { BundleModalComponent } from '../../../cart/component/bundle-modal/bundle-modal.component';
import { LocationService } from '../../../home/services/location.service';
import { GooglePlacesService } from '../../../core/services/google-places.service';
import { SiteContentService } from '../../../core/services/siteContents';
import { UtilsService } from '../../../core/services/utils.service';
import { AlertConfirmModalComponent } from '../../../core/component/alert-confirm-modal/alert-confirm-modal.component';
import { CustomerPropertySelectionModalComponent } from '../../../user/component/customer-property-selection-modal/customer-property-selection-modal.component';
import { SurgeModalComponent } from '../../../core/component/surge-modal/surge-modal.component';
import { UpsellServiceDetailComponent } from '../../../product/component/upsell-service-detail/upsell-service-detail.component';
import { WindowScrolling } from '../../../core/services/window-scrolling.service';
import { GlobalVariables } from '../../../core/services/global-variables.service';
import { CartQuickCheckoutService } from '../../../cart/services/cart-quick-checkout.service';
import { ProductCardSwitcherComponent } from '../../../product/component/products/product-card/product-card-switcher.component';
import { UpgradeServiceModalComponent } from '../../../user/component/checkout/upgrade-service-modal/upgrade-service-modal.component';

@Component({
  selector: 'hos-product-detail-header',
  templateUrl: './detail-header.component.html',
  styleUrls: ['./detail-header.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({
          height: 70,
          opacity: 0,
        }),
        animate(
          '1s ease-in-out',
          style({
            height: 70,
            opacity: 1,
          }),
        ),
      ]),
      transition(':leave', [
        style({
          height: 70,
          opacity: 1,
        }),
        animate(
          '1s ease-in-out',
          style({
            height: 70,
            opacity: 0,
          }),
        ),
      ]),
    ]),
  ],
})
export class ProductDetailHeaderComponent implements OnInit, OnChanges {
  @Input() productDetail: any;

  @Input() productType: string;

  @Input() locationCodeId: any;

  @Input() serviceState: any;

  @Input() allProducts: any;

  @Output() locationCodeHandler: EventEmitter<any> = new EventEmitter<any>();

  @Output() zipcodeChanged: EventEmitter<any> = new EventEmitter<any>();

  @Output() showPartner: EventEmitter<any> = new EventEmitter<any>();

  @Output() reviewSchemaData: EventEmitter<any> = new EventEmitter<any>();

  @Output() divHeightWithScrollEmitter: EventEmitter<any> = new EventEmitter<any>();

  fixedHeader = false;

  isProviser: Boolean = true;

  selectedAddress: any = {};

  location_code: String;

  showLoader: Boolean = false;

  termsLink: any;

  isLocationCodeMatching: Boolean = false;

  locationData: any;

  locationCodeData: any;

  state: any;

  baseUrl: String;

  baseUrlLoc: String;

  partnerName: String;

  locData: any;

  mapCategoriesById: any = {};

  seoConfig: SeoConfig;

  benefitsHeading: String;

  coveredRepairsHeading: String;

  customer_service_number: String;

  userDetails: any;

  productData: any;

  specialProduct: Array<String> = [];

  leakFrequencyVal: any;

  numbers: number[];

  avgrating: any;

  math = Math;

  totalReviews: any;

  selectedServiceAddressesUnderZipcode: any;

  siteContent: any;

  allStates: any;

  programName: any;

  locationCode: any;

  zipCode: any;

  category: any;

  showProductChecking: Boolean = false;

  disableButton: Boolean = false;

  isbrowser: Boolean = true;

  type: any;

  shareIcon: Boolean = false;

  siteNameParam: any;

  isserver = false;

  colorbanner = '';

  @ViewChild('toggleButton') toggleButton?: ElementRef;

  @ViewChild('menu') menu?: ElementRef;

  description: any = '';

  public carouselBanner: NguCarouselConfig;

  productBannerMedia: any;

  productTabletBannerMedia: any;

  productMobileBannerMedia: any;

  bannerMedia: any;

  tabletBannerMedia: any;

  mobileBannerMedia: any;

  productBannerVideoUrl: any = '';

  productBannerVideoUrlHeading: String;

  scrollyHeightFixedHeader: any = 200;

  @ViewChild('generalSection') generalSection: ElementRef;

  @ViewChild('genMobImg') genMobImg: ElementRef;

  divHeightWithScroll: any;

  micrositeNames: any;

  zipcodeLookupTexts: any;

  public innerWidth: any;

  categoriesData: any;

  categoryName: any;

  constructor(
    private el: ElementRef,
    private sessionStorageService: SessionStorageService,
    private userService: UserService,
    private cartService: CartService,
    private router: Router,
    private location: Location,
    private route: ActivatedRoute,
    private productService: ProductService,
    private simpleModalService: SimpleModalService,
    private seoService: SeoService,
    private googlePlacesService: GooglePlacesService,
    private siteContentService: SiteContentService,
    private locationService: LocationService,
    private utilsService: UtilsService,
    private windowScrolling: WindowScrolling,
    public globalVariables: GlobalVariables,
    private renderer: Renderer2,
    private _sanitizer: DomSanitizer,
    private cartQuickCheckoutService: CartQuickCheckoutService,
    private productCardSwitcherComponent: ProductCardSwitcherComponent,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Optional() @Inject('serverUrl') protected origin: string,
    @Optional() @Inject('pathname') protected pathname: string,
  ) {
    this.micrositeNames = this.sessionStorageService.getItem('micrositeName')
      ? JSON.parse(this.sessionStorageService.getItem('micrositeName'))
      : environment.micrositeNames;
    this.isbrowser = isPlatformBrowser(platformId);
    this.isserver = isPlatformServer(platformId);
    this.specialProduct = environment.special_product;
    this.numbers = Array(5)
      .fill(1)
      .map((x, i) => i + 1);
    this.renderer.listen('window', 'click', (e: Event) => {
      if (this.menu && e.target !== this.toggleButton.nativeElement && e.target !== this.menu.nativeElement) {
        this.shareIcon = false;
      }
    });
    this.siteContentService.statesdata$().subscribe(states => {
      this.allStates = states;
      if (this.isbrowser) {
        if (!this.allStates) {
          this.allStates = this.sessionStorageService.getItem('statesList') ? this.sessionStorageService.getItem('statesList', true) : states;
        }
      }
    });
  }

  ngOnInit() {
    this.innerWidth = window?.innerWidth;
    // this.allProducts = this.allProducts; To Check
    this.userDetails = this.userService.getSignedInUser();
    this.description = '';
    this.carouselBanner = {
      grid: {
        xs: 3,
        sm: 3,
        md: 4,
        lg: 5,
        all: 0,
      },
      slide: 1,
      speed: 400,
      point: {
        visible: false,
      },
      load: 2,
      touch: true,
      loop: true,
      easing: 'ease',
    };
    this.baseUrlLoc = `${environment.Image_API_Endpoint}${environment.Location_service_API_Endpoint}`;
    this.baseUrl = environment.Image_API_Endpoint + environment.Product_service_API_Endpoint;
    this.customer_service_number = environment.customer_service_number;
    const savedAddress = this.sessionStorageService.getItem('selectedAddress');
    /** HWR - 2696 */
    this.zipCode = this.route.snapshot.params.zipcode;
    this.locationCode = this.route.snapshot.params.locationcode;
    this.programName = this.route.snapshot.params.programName;
    this.category = this.route.snapshot.params.type ? this.route.snapshot.params.type : 'residential';
    /** HWR - 2696 */
    // this.selectedAddress = JSON.parse(savedAddress);
    this.location_code = this.sessionStorageService.getItem('location_code');
    const providerSelectionDetails = this.sessionStorageService.getItem('providerSelectionDetails')
      ? JSON.parse(this.sessionStorageService.getItem('providerSelectionDetails'))
      : '';

    /**
     * Q1 Fix [KP]: Popup added for details pages
     */
    const locationCodeId = this.sessionStorageService.getItem('location_code');
    if (
      locationCodeId &&
      this.productType !== 'service' &&
      (!providerSelectionDetails || (providerSelectionDetails && this.zipCode !== providerSelectionDetails.zipCode))
    ) {
      this.providerPopupDetails();
    }

    if (this.zipCode) {
      this.selectedAddress.zipcode = this.zipCode;
      this.selectedAddress.formatted_address = this.zipCode;
      this.sessionStorageService.setItem('selectedAddress', JSON.stringify(this.selectedAddress));
    } else if (savedAddress) {
      if (this.isbrowser) {
        this.selectedAddress = JSON.parse(savedAddress);
      }
      const zip = this.selectedAddress.zipcode;
      if (zip) {
        /** HWR - 2696 Start - Changed URL path */
        if (this.zipCode && this.locationCode && this.programName) {
          this.router.navigate([
            `/product/${this.zipCode}/${this.locationCode.toLowerCase()}/${this.category.toLowerCase()}/${this.programName
              .toString()
              .split(' ')
              .join('-')
              .split(',')
              .join('')
              .toLowerCase()}`,
          ]);
          /** HWR - 2696 End - Changed URL path */
        } else if (this.productType !== 'service') {
          if (this.locationCodeData && this.locationCodeData.locationCode) {
            this.router.navigate([`/products/${this.zipCode || zip}/${this.locationCodeData.locationCode.toLowerCase()}/residential`]);
          } else {
            this.productService.getProviderContent(this.sessionStorageService.getItem('location_code')).subscribe(data => {
              const locationCodeData = data.body;
              this.router.navigate([`/products/${this.zipCode || zip}/${locationCodeData.locationCode.toLowerCase()}/residential`]);
            });
          }
        }
      }
    }
    this.siteContentService.statesdata$().subscribe(response => {
      this.allStates = response;
    });
    this.utilsService.getPartnerDetails$().subscribe(response => {
      const result = response.body;
      this.zipcodeLookupTexts = result?.site?.zipcodeLookupText;
    });
    this.setState();
    if (this.zipCode) {
      this.googlePlacesService.getCityAndStateData(this.zipCode).subscribe(
        result => {
          if (result.body.results && result.body.results.length > 0) {
            this.selectedAddress.rawData = result.body.results[0];
            this.setState();
          }
        },
        () => {
          this.simpleModalService.addModal(
            ErrorModalComponent,
            {
              title: 'Error',
              message: 'Oops, Something went wrong, Please try again.',
              showButton: 'close',
            },
            {
              wrapperClass: 'modal-main-class in',
              closeOnClickOutside: true,
            },
          );
        },
      );
    }
    this.getPartnerDetails();
    this.productService.categories$().subscribe(categories => {
      this.categoriesData = categories.body;
      if (this.categoriesData && this.categoriesData.length > 0) {
        this.mapCategories(categories.body);
      }
    });
  }

  setState() {
    if (!!this.selectedAddress && !!this.selectedAddress.rawData && this.selectedAddress.rawData.address_components) {
      this.selectedAddress.rawData.address_components.forEach(data => {
        if (data.types[0] === 'administrative_area_level_1') {
          this.state = data.short_name;
        }
      });
    }
  }

  async ngOnChanges() {
    // this.allProducts = this.allProducts; To Check
    if (this.productDetail) {
      this.productBannerMedia = [];
      this.productTabletBannerMedia = [];
      this.productMobileBannerMedia = [];
      if (this.productType === 'product') {
        this.description = this._sanitizer.bypassSecurityTrustHtml(
          this.productDetail.programDescription ? this.productDetail.programDescription : '',
        );
      } else if (this.productType === 'service') {
        this.description = this._sanitizer.bypassSecurityTrustHtml(this.productDetail.description ? this.productDetail.description : '');
      }
      const locaId = this.locationCodeId;
      if (locaId) {
        this.locationData = this.productDetail.defaultLocationCodeId ? this.productDetail.defaultLocationCodeId : '';
        if (this.locationData && this.locationData === locaId) {
          this.isLocationCodeMatching = true;
        } else {
          this.isLocationCodeMatching = false;
        }
      }

      if (typeof this.productDetail.bannerImages.descriptionImage === 'object') {
        if (
          this.productDetail &&
          typeof this.productDetail.bannerImages.descriptionImage !== 'undefined' &&
          this.productDetail.bannerImages.descriptionImage.length > 0
        ) {
          // the array is defined and has at least one element
          if (
            this.productDetail.bannerImages.descriptionImage.length === 1 &&
            this.productDetail.bannerImages.descriptionImage[0].startsWith('programDetailsBgImage')
          ) {
            this.getStaticBannerImages('Desktop');
          } else {
            this.getDynamicBannerImages('Desktop');
          }
        }
      } else {
        this.getStaticBannerImages('Desktop');
      }

      if (typeof this.productDetail.bannerImages.descriptionTabletImages === 'object') {
        if (
          this.productDetail &&
          typeof this.productDetail.bannerImages.descriptionTabletImages !== 'undefined' &&
          this.productDetail.bannerImages.descriptionTabletImages.length > 0
        ) {
          // the array is defined and has at least one element
          if (
            this.productDetail.bannerImages.descriptionTabletImages.length === 1 &&
            this.productDetail.bannerImages.descriptionTabletImages[0].startsWith('programDetailsBgImage')
          ) {
            this.getStaticBannerImages('Tablet');
          } else {
            this.getDynamicBannerImages('Tablet');
          }
        }
      } else {
        this.getStaticBannerImages('Tablet');
      }

      if (typeof this.productDetail.bannerImages.descriptionMobileImages === 'object') {
        if (
          this.productDetail &&
          typeof this.productDetail.bannerImages.descriptionMobileImages !== 'undefined' &&
          this.productDetail.bannerImages.descriptionMobileImages.length > 0
        ) {
          // the array is defined and has at least one element
          if (
            this.productDetail.bannerImages.descriptionMobileImages.length === 1 &&
            this.productDetail.bannerImages.descriptionMobileImages[0].startsWith('programDetailsBgImage')
          ) {
            this.getStaticBannerImages('Mobile');
          } else {
            this.getDynamicBannerImages('Mobile');
          }
        }
      } else {
        this.getStaticBannerImages('Mobile');
      }

      if (this.productDetail.video) {
        this.productBannerVideoUrlHeading = this.productDetail.videoHeading;

        /** The below condition is to check whether the default video object value is equal to empty string */
        if (this.productDetail.video[0] === '') {
          this.productBannerVideoUrl = '';
        } else {
          /* Condition to handle multiple banner image strict no duplicate */
          this.productDetail.video.forEach(element => {
            if (this.productBannerMedia.indexOf(element) === -1) {
              this.productBannerMedia.push({
                type: 'video',
                value: this._sanitizer.bypassSecurityTrustHtml(element.videoUrl.replace(/width="\d+"/g, '').replace(/height="\d+"/g, '')),
                thumbnail: this.getImage(this.baseUrl + element.videoThumbnail),
              });
            }
          });
        }
      }

      /* Add to cart logic on click of enroll now before login to add cart after login */
      if (this.globalVariables.nonLoggedInProductDetails && Object.keys(this.globalVariables.nonLoggedInProductDetails).length > 0) {
        const urlOperation: any = window.location.pathname.split('/');
        if (
          urlOperation.length > 0 &&
          urlOperation[0] !== '' &&
          !this.micrositeNames.includes(urlOperation[0]) &&
          this.globalVariables.isPropNotExist
        ) {
          if (['product'].includes(urlOperation[1])) {
            this.globalVariables.isPropNotExist = false;
            const getMicrositeDetails = await this.getMicrositeContentApi(urlOperation[3]);
            if (getMicrositeDetails && getMicrositeDetails.body && getMicrositeDetails.body.site.siteName) {
              urlOperation.unshift(getMicrositeDetails.body.site.siteName);
              const redirectUrlOp: any = urlOperation.join('/');
              this.location.go(redirectUrlOp);
            }
          }
        }
        /* Start - To Check if cust# is null return to myaccount route */
        let isEmptyCustNo: boolean = false;
        const userDetails = JSON.parse(this.sessionStorageService.getItem('loginUserDetails'));
        this.showLoader = true;
        if (userDetails) {
          await this.getAllCustomerAddressesApi(userDetails);
        }
        if (userDetails && this.globalVariables.getAllCustomerAddresses) {
          const lastPropData = JSON.parse(
            JSON.stringify(this.globalVariables.getAllCustomerAddresses[this.globalVariables.getAllCustomerAddresses.length - 1]),
          );
          if (
            lastPropData &&
            lastPropData.serviceAddress.zipcode === this.route.snapshot.params.zipcode &&
            ['null', 'undefined', undefined, null, ''].includes(lastPropData.customerNo)
          ) {
            for (let i = 0; i <= 2; i++) {
              const lastIndexData = JSON.parse(
                JSON.stringify(this.globalVariables.getAllCustomerAddresses[this.globalVariables.getAllCustomerAddresses.length - 1]),
              );
              if (lastIndexData && ['null', 'undefined', undefined, null, ''].includes(lastIndexData.customerNo)) {
                if (i === 2) {
                  isEmptyCustNo = true;
                } else {
                  await new Promise(resolve => {
                    setTimeout(resolve, 2000);
                  });
                  await this.getAllCustomerAddressesApi(userDetails);
                }
              }
            }
          }
        }
        this.showLoader = false;
        if (isEmptyCustNo) {
          this.router.navigate(['/user/myaccount']);
          return;
        }
        /* End - To Check if cust# is null return to myaccount route */
        if (this.productDetail.defaultLocationCodeId === this.globalVariables.nonLoggedInProductDetails.defaultLocationCodeId) {
          this.addProductToCart(this.globalVariables.nonLoggedInProductDetails);
        } else {
          this.globalVariables.nonLoggedInProductDetails = this.productDetail;
          this.addProductToCart(this.globalVariables.nonLoggedInProductDetails);
        }
      }
      this.benefitsHeading = this.productDetail.benefitsHeading;
      this.coveredRepairsHeading = this.productDetail.coveredRepairsHeading;
      this.colorbanner = this.productDetail.colorConfigStyle ? this.productDetail.colorConfigStyle : '#06B3E4';
      const categoryObject = this.categoriesData?.find(obj => obj.reviewProgramId === this.productDetail?.program?.programId);
      this.categoryName = categoryObject?.categoryName?.replace(/ /g, '-').toLowerCase();
      this.getAvgProductsReviews();
      if (this.allStates && this.allStates.length > 0) {
        this.setPageMetaData();
        this.seoService.generateTitleTags(this.seoConfig);
        if (this.zipCode) {
          this.seoService.metaSetNoIndexNoFollow();
        } else {
          this.seoService.setCanonicalTags(this.categoryName);
        }
      }
      this.zipCode = this.route.snapshot.params.zipcode;
      this.selectedAddress.zipcode = this.zipCode;
      this.selectedAddress.formatted_address = this.zipCode;
      this.sessionStorageService.setItem('selectedAddress', JSON.stringify(this.selectedAddress));
    }
  }

  openModalPopup() {
    this.simpleModalService
      .addModal(
        ProviderModalComponent,
        {},
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: false,
        },
      )
      .subscribe(isConfirmed => {
        if (isConfirmed) {
          this.getPartnerDetails();
          this.isProviser = true;
          this.showPartner.emit(isConfirmed);
        } else {
          this.locData = [];
          this.isProviser = false;
          this.showPartner.emit(false);
        }
      });
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.setFixedHeader();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  scrollToTop() {
    $('html, body').animate(
      {
        scrollTop: 0,
      },
      1000,
    );
    this.globalVariables.fixedHeaderProdDetails = false;
  }

  setFixedHeader() {
    this.scrollyHeightFixedHeader = this.setScrollHeight();
    // mobile device check
    // const windowWidth = window.innerWidth;
    // const break_small: any = 768;
    // if (windowWidth < break_small && !this.fixedHeader) {
    //     const element = this.genMobImg ? this.genMobImg.nativeElement : '';
    //     if (element) {
    //         const viewportOffset = element.getBoundingClientRect();
    //         const height = viewportOffset.height;
    //         this.scrollyHeightFixedHeader = this.scrollyHeightFixedHeader + height;
    //     }
    // }
    if (window.scrollY > this.scrollyHeightFixedHeader) {
      this.fixedHeader = true;
      this.globalVariables.fixedHeaderProdDetails = true;
    } else {
      this.fixedHeader = false;
      this.globalVariables.fixedHeaderProdDetails = false;
    }
  }

  mapCategories(categories: any) {
    categories.forEach(category => {
      this.mapCategoriesById[category._id] = category;
    });
  }

  getProductDetail(query?: String) {
    this.showLoader = true;
    this.productService.getMarketingCodes(query).subscribe(
      data => {
        if (data.status === 200) {
          this.router.navigate([`/products/${this.zipCode}/${this.locationCodeData.locationCode.toLowerCase()}/residential`]);
        } else if (data.status !== 200) {
          this.showLoader = false;
          this.simpleModalService.addModal(
            ErrorModalComponent,
            {
              title: 'Error',
              message: 'Data is not available at this moment, Please try again later.',
              showButton: 'close',
            },
            {
              wrapperClass: 'modal-main-class in',
              closeOnClickOutside: true,
            },
          );
        }
      },
      () => {
        this.showLoader = false;
        this.simpleModalService.addModal(
          ErrorModalComponent,
          {
            title: "We're sorry, we are experiencing technical difficulties.",
            message: `Please try again. If you continue to experience issues, please contact Customer Service at <span class="app-txt">
${this.customer_service_number}</span> for assistance. Thank you.`,
            showButton: 'close',
            alertType: 'tech-type',
          },
          {
            wrapperClass: 'modal-main-class in',
            closeOnClickOutside: true,
          },
        );
      },
    );
  }

  close() {
    if (this.isbrowser) {
      this.selectedAddress = JSON.parse(this.sessionStorageService.getItem('selectedAddress'));
      this.location_code = this.sessionStorageService.getItem('location_code');
    }
  }

  addressHandler(event) {
    this.showProductChecking = true;
    /**
     * Q1 Fix [KP]: Condition for clear popup
     */
    const providerSelectionDetailsAddress = this.sessionStorageService.getItem('providerSelectionDetails');
    if (providerSelectionDetailsAddress && providerSelectionDetailsAddress.zipCode !== this.zipCode) {
      this.sessionStorageService.setItem('providerSelectionDetails', '{}');
    }

    this.disableButton = false;
    if (this.isbrowser) {
      this.selectedAddress = JSON.parse(this.sessionStorageService.getItem('selectedAddress'));
    }
    this.location_code = this.sessionStorageService.getItem('location_code');
    if (event) {
      this.locationCodeHandler.emit(true);
      this.zipcodeChanged.emit(event);
    } else {
      this.showProductChecking = false;
      this.disableButton = true;
      this.locationCodeHandler.emit(false);
      this.zipcodeChanged.emit(false);
    }

    const serviceLocationCode = this.productDetail.defaultLocationCodeId;

    if (serviceLocationCode && serviceLocationCode === event.location_code) {
      this.isLocationCodeMatching = true;
    } else {
      this.isLocationCodeMatching = false;
    }
    const contactType = environment.types.filter(item => item.type === this.category);
    this.type = contactType[0].id;
    let query;
    if (this.type) {
      query = `contact_type=${this.type}&allProducts=true`;
    } else {
      query = 'allProducts=true';
    }
    this.productService.getMarketingCodes(query).subscribe(
      data => {
        if (data.status === 200) {
          let filterData = [];
          const resData = data.body;
          const programId = this.productType === 'product' ? this.productDetail.program.programId : this.productDetail.programId;
          const categoryType = this.productType === 'service' ? 'residential' : this.category === 'residential' ? 1 : 3;
          const coverageType = this.productType === 'service' ? 'REGULAR' : this.productDetail.coverageType;
          if (this.productType === 'product') {
            filterData = resData.result.filter(
              ele1 => ele1.program.programId === programId && categoryType === ele1.contactType && coverageType === ele1.coverageType,
            );
          } else {
            filterData = resData.result.filter(
              ele2 => ele2.program.programId === programId && categoryType === 'residential' && coverageType === 'REGULAR',
            );
          }
          if (filterData.length > 0) {
            this.programName = filterData[0].webProgramName.split(' ').join('-').toLowerCase();
            this.locationService
              .getLocationsContent(event.location_code || this.sessionStorageService.getItem('location_code'))
              .subscribe(dataReceived => {
                this.locationCodeData = dataReceived.body;
                this.serviceState = dataReceived.serviceState;
                this.locationService.getMicroSiteForLocation(this.locationCodeData.locationCode).subscribe(resDataMicroSite => {
                  const dataMicroSite = resDataMicroSite.body;
                  if (dataMicroSite.level === 'default' && this.locationCodeData.websitePartnerName === '') {
                    if (this.sessionStorageService.getItem('providerState')) {
                      this.sessionStorageService.setItem('isMicroSite', '0');
                      this.sessionStorageService.deleteItem('baseUrlState');
                      this.sessionStorageService.deleteItem('providerState');
                      if (this.productType === 'product') {
                        let queryString = '/product';
                        /** HWR - 2696 */
                        queryString += event.zipcode ? `/${event.zipcode}` : '';
                        queryString += this.locationCodeData.locationCode ? `/${this.locationCodeData.locationCode.toLowerCase()}` : '';
                        queryString += this.category ? `/${this.category.toLowerCase()}` : '';
                        queryString += this.programName
                          ? `/${this.programName.toString().split(' ').join('-').split(',').join('').toLowerCase()}`
                          : '';
                        window.location.href = queryString;
                        /** HWR - 2696 */
                      } else {
                        /** Fix for service change passsing hardcoded value */
                        const queryServiceUrl = `/product/${
                          event.zipcode
                        }/${this.locationCodeData.locationCode.toLowerCase()}/residential/${this.programName
                          .toString()
                          .split(' ')
                          .join('-')
                          .split(',')
                          .join('')
                          .toLowerCase()}`;
                        window.location.href = queryServiceUrl;
                      }
                    } else if (this.productType === 'product') {
                      this.router.navigate([
                        `/product/${
                          event.zipcode
                        }/${this.locationCodeData.locationCode.toLowerCase()}/${this.category.toLowerCase()}/${this.programName
                          .toString()
                          .split(' ')
                          .join('-')
                          .split(',')
                          .join('')
                          .toLowerCase()}`,
                      ]);
                    } else {
                      /** Fix for service change passsing hardcoded value */
                      this.router.navigate([
                        `/product/${event.zipcode}/${this.locationCodeData.locationCode.toLowerCase()}/residential/${this.programName
                          .toString()
                          .split(' ')
                          .join('-')
                          .split(',')
                          .join('')
                          .toLowerCase()}`,
                      ]);
                    }
                  } else {
                    this.locationCode = this.locationCodeData.locationCode.toLowerCase();
                    this.locationCodeId = this.locationCodeData.locationCodeId;
                    this.zipCode = event.zipcode;
                    const providerSelectionDetails = this.sessionStorageService.getItem('providerSelectionDetails')
                      ? JSON.parse(this.sessionStorageService.getItem('providerSelectionDetails'))
                      : '';
                    if (
                      (this.locationCodeId &&
                        this.productType !== 'service' &&
                        (!providerSelectionDetails || (providerSelectionDetails && this.zipCode !== providerSelectionDetails.zipCode))) ||
                      !providerSelectionDetails
                    ) {
                      this.providerPopupDetails(dataMicroSite, event);
                    } else {
                      this.micrositesRedirectOnDetailsPage(dataMicroSite, event);
                    }
                  }
                });
              });
          } else {
            this.showProductChecking = false;
            this.disableButton = true;
            this.locationService.getLocationsContent(event.location_code).subscribe(response => {
              const responseData = response.body;
              this.simpleModalService
                .addModal(
                  AlertConfirmModalComponent,
                  {
                    title: '',
                    message:
                      ' This service is not available for the selected address, Click on OK if you want to see other products from the same zip code',
                    alertIcon: 'fa fa-info-circle red',
                    confirmText: 'OK',
                  },
                  {
                    wrapperClass: 'modal-main-class in',
                    closeOnClickOutside: true,
                  },
                )
                .subscribe(isConfirmed => {
                  if (isConfirmed) {
                    this.locationService.getMicroSiteForLocation(responseData.locationCode).subscribe(resDataMicroSite => {
                      const dataMicroSite = resDataMicroSite.body;
                      if (dataMicroSite.level === 'default') {
                        window.location.href = `/products/${event.zipcode}/${responseData.locationCode.toLowerCase()}/residential/`;
                      } else {
                        const micrositeurl = dataMicroSite.site.siteName ? `/${dataMicroSite.site.siteName}` : '';
                        window.location.href = `${micrositeurl}/products/${event.zipcode}/${responseData.locationCode.toLowerCase()}/residential/`;
                      }
                    });
                  }
                });
            });
          }
        } else if (data.status !== 200) {
          this.showErrorModal('Error', 'No service available in selected zipcode.');
        }
      },
      () => {
        this.showProductChecking = false;
        this.disableButton = true;
      },
    );
  }

  changeAddress() {
    this.selectedAddress = {};
  }

  scrollToSection(sectionId: string) {
    if (this.isbrowser) {
      //   document.querySelector('#' + sectionId).scrollIntoView({ behavior: 'smooth',block: "start",inline:"start" });
      if (this.globalVariables.fixedHeaderProdDetails) {
        $('html, body').animate(
          {
            scrollTop: $(`#${sectionId}`).offset().top - 200,
          },
          1000,
        );
        document.body.scrollTop -= 200;
      } else {
        $('html, body').animate(
          {
            scrollTop: $(`#${sectionId}`).offset().top - 150,
          },
          1000,
        );
      }
    }
  }

  public addProductToCart(product: any): void {
    this.productData = product;
    if (!this.userDetails && product.contactType !== 3) {
      this.addCheckoutItemToCart(product);
      return;
    }
    if (this.userDetails) {
      this.getCustomerAddress();
    } else {
      this.sessionStorageService.setItem('directPayment', '1');
      this.openLoginWindow(product);
    }
  }

  public addProductToCartProcessing(serviceAddresses: any, description?: string) {
    const { productData } = this;
    productData.isBundle = this.productData.program.products.length > 1;
    productData.status = 'PENDING_PAYMENT';
    productData.provider = 'AWR';
    /** HWR - 2696 */
    productData.locationId = this.locationCodeId;
    productData.zipcode = this.zipCode;
    /** HWR - 2696 */
    productData.isUpgrade = false;
    this.globalVariables.waterBillOnOff = serviceAddresses[0].waterBillOnOff;
    if (typeof description !== 'undefined') {
      productData.description = description;
    }
    if (productData.program.programId.includes(this.specialProduct)) {
      productData.leakFrequencyVal = this.leakFrequencyVal;
    }
    // option to select property
    if (serviceAddresses.length > 1) {
      this.simpleModalService
        .addModal(
          CustomerPropertySelectionModalComponent,
          {
            title: 'Protection Services Enrollment',
            message: 'Please select a property you would like to apply coverage',
            alertIcon: 'fa fa-info-circle red',
            confirmText: 'Ok',
            data: serviceAddresses,
          },
          {
            wrapperClass: 'modal-main-class in',
            closeOnClickOutside: true,
          },
        )
        .subscribe(isConfirmed => {
          this.windowScrolling.enable();
          if (isConfirmed) {
            productData.customerNo = isConfirmed;
            this.globalVariables.waterBillOnOff = serviceAddresses.filter(item => item.customerNo === isConfirmed)[0].waterBillOnOff;
            this.addItemToCartApiCall(productData, serviceAddresses);
          }
        });
    } else if (serviceAddresses.length === 1) {
      productData.customerNo = serviceAddresses[0].customerNo;
      this.addItemToCartApiCall(productData, serviceAddresses);
    }
  }

  addItemToCartApiCall(productData, serviceAddresses?) {
    const locData = productData.defaultLocationCodeId || this.locationCodeId;
    const selAddCustDetails = serviceAddresses.filter(item => item.customerNo === productData.customerNo);
    if (locData && selAddCustDetails && selAddCustDetails[0].serviceAddress.locationCodeId !== locData) {
      const gaTrackId = 'do_not_offer';
      this.simpleModalService.addModal(
        ErrorModalComponent,
        {
          title: 'We are sorry. We do not currently offer this service in your location.',
          message: '',
          showButton: 'close',
          alertType: 'info-type',
          confirmBtnId: gaTrackId || 'close',
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: true,
        },
      );
      this.showLoader = false;
      return;
    }
    this.showLoader = true;
    /** HWR-2386: disallow Residential customer to enroll in Commercial programs and vice versa *** */
    const productContactType = environment.types.filter(item => item.id === this.productData.contactType);
    const serviceAddressContactType = this.selectedServiceAddressesUnderZipcode
      .filter(data => data.customerNo === productData.customerNo)
      .map(item => item.serviceAddress.contactType);
    if (serviceAddressContactType[0] !== productContactType[0].type.toUpperCase() && serviceAddressContactType[0] === 'RESIDENTIAL') {
      this.showErrorModal(
        '',
        `We're sorry. We have your account identified as a Residential property and cannot apply Commercial protection plans to this account. For more information on the difference between residential and commercial protection plans please call <b>${this.globalVariables.territoryCustomerServiceNumber}</b>.`,
      );
      this.showLoader = false;
      return;
    }
    if (serviceAddressContactType[0] !== productContactType[0].type.toUpperCase() && serviceAddressContactType[0] === 'COMMERCIAL') {
      this.showErrorModal(
        '',
        `We're sorry. We have your account identified as a Commercial property and cannot apply Residential protection plans to this account. For more information on the difference between residential and commercial protection plans please call <b>${this.globalVariables.territoryCustomerServiceNumber}</b>.`,
      );
      this.showLoader = false;
      return;
    }
    /** HWR-2386 *** */

    const isonbillreturn = this.checkIfOnBill();
    if (isonbillreturn) {
      /** HWR-2500: stop user to add product in card */
      return false;
    }
    this.cartService.addItem(productData, '', (err, data, status) => {
      this.globalVariables.nonLoggedInProductDetails = {};
      if (err) {
        if (data.status === 409) {
          if (data.error.data && data.error.data.bundleData && data.error.responseDesc === 'Bundle is possible') {
            if (data.error.data.bundleData.length > 0) {
              const { newCartItemPayload } = data;
              const payload = [];
              const enrollments = data.error.data.existingEnrollments;
              const newCartItem = data.error.data.newProgramInCart;
              const { bundleData } = data.error.data;
              if (newCartItemPayload.productId === this.productData._id) {
                /** To get the product details in the payload */
                newCartItemPayload.details = this.productData;
              }
              payload.push(newCartItemPayload); /** To get the cart payload */
              this.productCardSwitcherComponent.showUpgradePopupModal(
                UpgradeServiceModalComponent,
                enrollments,
                newCartItem,
                payload,
                bundleData,
                this,
                result => {
                  if (result) {
                    const updatedPayload = result.payload[0];
                    this.productCardSwitcherComponent.addUpdatedUpgradeItemToCart(updatedPayload, this);
                  }
                },
              );
            } else {
              this.showErrorModal(
                "We're Sorry",
                `You are currently protected with a similar product.
                If you wish to upgrade or change your protection coverage, please contact Customer Service at ${this.customer_service_number}.`,
                'Ok',
              );
            }
          } else if (data.error.errPopupMessage) {
            this.showErrorModal(data.error.errPopupTitle, data.error.errPopupMessage, 'Ok', data.error.gaEventLabel);
          } else {
            this.showErrorModal(
              "We're Sorry",
              `You are currently protected with a similar product.
                If you wish to upgrade or change your protection coverage, please contact Customer Service at ${this.customer_service_number}.`,
              'Ok',
            );
          }
        } else if (data.status === 400) {
          this.showErrorModal(
            'Upgrade to Bundle',
            `If you are looking to bundle your existing services,
        please visit your My Services page and view the list of opportunities from your current services.`,
            'Ok',
          );
        } else {
          this.showErrorModal('Error', 'Error while adding item to cart');
        }
        this.cartService.getCustomerCartToLocal(this.userDetails.user._id, '', () => {
          const cartData = this.cartService.get();
          cartData.subscribe(cart => {
            this.globalVariables.cartAmount.emit(cart);
          });
        });
      } else if (status === 201) {
        this.openBundle(data);
      } else if (!!data && data.length > 0) {
        this.sessionStorageService.setItemToloclaStorage('deletedCartData', JSON.stringify(data));
      } else {
        this.simpleModalService
          .addModal(
            UpsellServiceDetailComponent,
            {
              service: productData,
            },
            {
              wrapperClass: 'modal-main-class in',
              closeOnClickOutside: true,
            },
          )
          .subscribe((isConfirmed: any) => {
            this.windowScrolling.enable();
            if (isConfirmed) {
              this.showLoader = true;
              this.cartService.removeItem(isConfirmed.removeProduct, error => {
                if (error) {
                  this.showLoader = false;
                  this.showErrorModal('Error', 'Error removing item from cart');
                } else {
                  delete isConfirmed.removeProduct;
                  const upsellproductData = isConfirmed;
                  upsellproductData.isBundle = upsellproductData.program.products.length > 1;
                  upsellproductData.status = 'PENDING_PAYMENT';
                  upsellproductData.provider = 'AWR';
                  upsellproductData.locationId = this.locationCodeId;
                  upsellproductData.zipcode = this.zipCode;
                  this.cartService.addItem(upsellproductData, '', (errorData, dataRes) => {
                    this.showLoader = false;
                    if (errorData) {
                      if (dataRes.status === 409) {
                        this.showErrorModal(
                          'Upgrade to Bundle',
                          `If you are looking to bundle your existing services,
                      please visit your My Services page and view the list of opportunities from your current services.`,
                          'Ok',
                        );
                      } else if (dataRes.status === 400) {
                        this.showErrorModal(
                          'Upgrade to Bundle',
                          `If you are looking to bundle your existing services,
                      please visit your My Services page and view the list of opportunities from your current services.`,
                          'Ok',
                        );
                      } else {
                        this.showErrorModal('Error', 'Error while adding item to cart');
                      }
                    } else {
                      this.router.navigate(['/payment'], {
                        queryParams: {
                          section: 'cart',
                        },
                      });
                    }
                  });
                }
              });
            }
          });
        // this.router.navigate(['/payment']);
      }
      this.showLoader = false;
    });
  }

  getCustomerAddress() {
    this.showLoader = true;
    this.userDetails = this.userService.getSignedInUser();
    this.userService.getAllCustomerAddresses(this.userDetails.user._id).subscribe(resdata => {
      const data = resdata.body;
      this.showLoader = false;
      const serviceAddresses = data;
      let selectedZipcode = '';
      if (this.isbrowser) {
        const selectedAddress = JSON.parse(this.sessionStorageService.getItem('selectedAddress'));
        selectedZipcode = selectedAddress.zipcode ? selectedAddress.zipcode : this.zipCode;
      }
      const serviceAddressesUnderZipcode = serviceAddresses.filter(
        y => y.serviceAddress.zipcode === selectedZipcode && y.customerNo !== '' && y.customerNo !== undefined,
      );
      serviceAddressesUnderZipcode.forEach(element => {
        element.showAddress = `${element.serviceAddress.streetNumber} ${element.serviceAddress.streetName}, ${element.serviceAddress.city}
, ${element.serviceAddress.state}, ${element.serviceAddress.zipcode}`;
      });
      this.selectedServiceAddressesUnderZipcode = serviceAddressesUnderZipcode;
      if (serviceAddressesUnderZipcode.length > 0) {
        if (this.productData.program.programId === environment.surge_program_id) {
          this.simpleModalService
            .addModal(
              SurgeModalComponent,
              {
                confirmText: 'Enroll Now',
              },
              {
                wrapperClass: 'modal-main-class in',
                closeOnClickOutside: false,
              },
            )
            .subscribe(isConfirmed => {
              if (typeof isConfirmed === 'boolean') {
                this.addProductToCartProcessing(serviceAddressesUnderZipcode);
              } else {
                this.addProductToCartProcessing(serviceAddressesUnderZipcode, isConfirmed);
              }
            });
        } else if (this.productData.program.programCode === 'LDDP') {
          this.leakFrequencyVal = 'Upfront';
        } else {
          this.addProductToCartProcessing(serviceAddressesUnderZipcode);
        }
      } else {
        this.simpleModalService
          .addModal(
            AlertConfirmModalComponent,
            {
              title: `The service you selected for zip code
          ${selectedZipcode} does not match with your existing property zip code(s).`,
              message: 'Would you like to add a new property?',
              alertIcon: 'fa fa-exclamation-circle red',
              cancelText: 'No',
              confirmText: 'Yes',
            },
            {
              wrapperClass: 'modal-main-class in',
              closeOnClickOutside: false,
            },
          )
          .subscribe(isConfirmed => {
            if (isConfirmed) {
              this.globalVariables.isPropNotExist = true;
              this.router.navigate(['/user/add-property'], {
                queryParams: {
                  zipcode: selectedZipcode,
                },
              });
            }
          });
      }
    });
  }

  openLoginWindow(product?: any) {
    /* if (this.isLoginOpen) {
    this.simpleModalService.removeAll();
      this.isLoginOpen = false;
    }
    this.simpleModalService.addModal(LoginModalComponent, {
      title: 'Already have an account?',
      message: ''
    }, {
        wrapperClass: 'modal-main-class in',
        closeOnClickOutside: true
      })
      .subscribe((isConfirmed) => {
        // We get dialog result
        if (isConfirmed && isConfirmed.confirm) {
          this.getCustomerAddress();
        } else if (isConfirmed && isConfirmed.confirm === false) {
          this.loadForgotPassword();
        } else if (isConfirmed && isConfirmed.confirm === undefined) {
        }
      }); */

    const hashValue: any = ![undefined, 'undefined', null, 'null', ''].includes(window.location.hash) ? window.location.hash : '';
    let urlOperation: any = window.location.pathname.split('/');
    urlOperation = urlOperation.filter(item => !this.micrositeNames.includes(item) && item !== '');
    const prevUrl = urlOperation.join('/');
    /* Storing product details */
    if (product && Object.keys(product).length > 0) {
      this.globalVariables.nonLoggedInProductDetails = product;
    }
    this.router.navigate(['/user/login'], {
      queryParams: {
        referer: encodeURIComponent(prevUrl + hashValue),
      },
    });
  }

  goToTerms(): void {
    if (this.productDetail) {
      let isCombo = 0;
      if (this.productDetail.program.products.length > 1) {
        isCombo = 1;
      }
      // to get selected language
      let lang = $('.goog-te-menu-value span:first').text();
      if (lang === 'Spanish') {
        lang = 'spanish';
      } else {
        lang = 'english';
      }
      const program = this.productDetail.program.programCode;
      this.locationService.getLocationCode(this.selectedAddress.zipcode).subscribe(result => {
        const locationData = result.body;
        if (result.status === 200) {
          const zipcodeCheck = locationData.locationCodeId === this.productDetail.defaultLocationCodeId ? this.selectedAddress.zipcode : '0';
          this.state = this.state
            ? this.state
            : this.serviceState
            ? this.allStates.filter(stateObj => stateObj.key === this.serviceState).map(obj => obj.value)[0]
            : '';
          this.productService
            .getTermsAndConditionsForProduct(
              lang,
              this.state,
              zipcodeCheck,
              program,
              this.productDetail.contactType,
              isCombo,
              this.productDetail.coverageType,
              this.productDetail.defaultLocationCodeId,
            )
            .subscribe(
              response => {
                this.termsLink = `${environment.Image_API_Endpoint + environment.TERMS_SERVICE_ENDPOINT}/${
                  response.body.result && response.body.result.length ? response.body.result[0].pdfUrl : ''
                }`;
                window.open(this.termsLink, '_blank');
              },
              () => {},
            );
        }
      });
    }
  }

  showErrorModal(title: string, message: string, confirmationMsg?: string, gaTrackBtnId?: string) {
    this.simpleModalService
      .addModal(
        ErrorModalComponent,
        {
          title,
          message,
          confirmationMsg,
          confirmBtnId: gaTrackBtnId || 'close',
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: true,
        },
      )
      .subscribe(() => {});
  }

  getPartnerDetails() {
    this.partnerName = this.sessionStorageService.getItem('websitePartnerName');
    if (this.partnerName) {
      this.productService.getProviderContent(this.locationCodeId || this.sessionStorageService.getItem('location_code')).subscribe(
        locationData => {
          this.locData = locationData.body;
        },
        () => {
          this.locData = [];
        },
      );
    } else {
      this.locData = [];
    }
  }

  setPageMetaData() {
    const imageUrl = this.productDetail.programBannerIconUrl
      ? this.baseUrl + this.productDetail.programBannerIconUrl
      : this.productDetail.bannerImages.descriptionMobileImages && this.productDetail.bannerImages.descriptionMobileImages.length > 0
      ? this.productDetail.bannerImages.descriptionMobileImages[0].startsWith('programDetailsBgImage/Mobile')
        ? `../../../../img/${this.productDetail.bannerImages.descriptionMobileImages[0]}`
        : this.baseUrl + this.productDetail.bannerImages.descriptionMobileImages[0]
      : `../../../../img/${this.productDetail.bannerImages.descriptionMobileImages[0]}`;
    const seoConfigImageUrl = this.utilsService.buildImageUrl(imageUrl);
    let titles = '';
    if (this.programName !== undefined) {
      titles = `${this.programName.split('-').join(' ')} | `;
    } else {
      titles = '';
    }
    /** *** HWR-1528-1529 Set SEO title and description metatag - Start **** */
    let desc = `American Water Resources offers affordable home protection programs
    that help protect against unexpected repair costs for service line emergencies.`;
    if (this.productType === 'product') {
      const seoState = this.serviceState ? this.allStates.filter(stateObj => stateObj.key === this.serviceState).map(obj => obj.value) : '';
      const seoZipcode = this.zipCode ? this.zipCode : '';
      titles = this.productDetail.seoTitle
        ? `${this.productDetail.seoTitle} | ${seoState} | ${seoZipcode} | `
        : this.productDetail.webProgramName
        ? `${this.productDetail.webProgramName} | ${seoState} | ${seoZipcode} | `
        : '';
      desc = this.productDetail.seoProgramDescription
        ? `${this.productDetail.seoProgramDescription} - ${seoState}, ${seoZipcode}`
        : this.productDetail.programDescription
        ? `${this.productDetail.programDescription.replace(/<[^>]+>/g, '')} - ${seoState}, ${seoZipcode}`
        : '';
    } else if (this.productType === 'service') {
      titles = this.productDetail.seoCategoryTitle
        ? `${this.productDetail.seoCategoryTitle} | `
        : this.productDetail.serviceName
        ? `${this.productDetail.serviceName} | `
        : '';
      desc = this.productDetail.seoCategoryDescription ? this.productDetail.seoCategoryDescription : desc;
    }
    /** *** HWR-1528-1529 Set SEO title and description metatag - End **** */
    this.seoConfig = {
      title: `${titles}Service Line Warranties | American Water Resources`,
      description: desc,
      keywords: `service line warranties, water line protection, sewer line protection,
      in home plumbing emergencies, electric line protection, power surge protection, heating system repair, cooling system repair`,
      image: seoConfigImageUrl,
      slug: '',
      sitename: '',
      type: '',
      summary: '',
      url: '',
    };
  }

  openBundle(data: any) {
    this.simpleModalService
      .addModal(
        BundleModalComponent,
        {
          data,
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: true,
        },
      )
      .subscribe(isConfirmed => {
        if (isConfirmed) {
          this.router.navigate(['/payment'], {
            queryParams: {
              section: 'cart',
            },
          });
        }
      });
  }

  getImage(imageName) {
    return this.utilsService.buildImageUrl(imageName);
  }

  /* Average product review API Call - AP - 1-june */
  getAvgProductsReviews() {
    // this.showProductChecking = true;
    /* To show default program averagre rating on service page - Start **** */
    if (this.productDetail.coverageType && this.productDetail.coverageType === 'UNLIMITED') {
      this.productDetail.programId = this.productDetail.reviewProgramId;
    }
    if (!this.productDetail.programId) {
      // eslint-disable-next-line no-prototype-builtins
      if (this.mapCategoriesById.hasOwnProperty(this.productDetail.serviceId)) {
        const selectedCategory = this.mapCategoriesById[this.productDetail.serviceId];
        this.productDetail.programId = selectedCategory.reviewProgramId;
      }
    }
    /*  To show default program averagre rating on service page - End **** */
    this.productService.getAvgProductReviews(this.productDetail.programId).subscribe(
      data => {
        const result = data.body;
        this.avgrating = this.math.round(result.averageRatingByProgram);
        this.totalReviews = result.totalReviews;
        const reviewData: any = {
          avgrating: this.avgrating,
          totalReviews: this.totalReviews,
        };
        this.reviewSchemaData.emit(reviewData);
        this.showLoader = false;
        //   this.showProductChecking = false;
      },
      () => {
        this.avgrating = '';
        this.totalReviews = '';
        const reviewData: any = {
          avgrating: this.avgrating,
          totalReviews: this.totalReviews,
        };
        this.reviewSchemaData.emit(reviewData);
        this.showLoader = false;
        // this.showProductChecking = false;
      },
    );
  }

  checkIfOnBill() {
    /** HWR-2500: New method | This method return boolean value after checking water bill is on/off */
    const that = this;
    this.showLoader = false;
    let contactNumber = environment.onbillCallNumbers.default;
    if (this.globalVariables.waterBillOnOff === false) {
      const stateVal: any = that.allStates.filter(stateData => stateData.key === that.serviceState);
      if (stateVal && environment.onbillCallNumbers[stateVal[0].value]) {
        contactNumber = environment.onbillCallNumbers[stateVal[0].value];
      }
      this.simpleModalService
        .addModal(
          AlertConfirmModalComponent,
          {
            title: '',
            message: `There has been an issue with adding your program to your water bill. Please call <b>${contactNumber}</b> to verify your water bill status.`,
            alertIcon: 'fa fa-info-circle red',
            confirmText: 'OK',
          },
          {
            wrapperClass: 'modal-main-class in',
            closeOnClickOutside: true,
          },
        )
        .subscribe(() => {
          this.showLoader = false;
        });
      return true;
    }
    return false;
  }

  goToPaymentOptions() {}

  showSocialIcons() {
    this.shareIcon = !this.shareIcon;
  }

  learnMoreNativeLink(product: any, link: any) {
    let url;
    let originUrl;
    if (this.productType === 'service') {
      if (this.isbrowser) {
        originUrl = window.location.href;
      }
      if (this.isserver) {
        originUrl = this.origin + this.pathname;
      }
      url = originUrl;
    } else {
      const zipcodeParams = this.route.snapshot.params.zipcode || this.zipCode;
      const typeParams = this.productDetail.contactType === 1 ? 'residential' : 'commercial';
      const locationcodeParams = this.route.snapshot.params.locationcode || this.locationCodeData.locationCode.toLowerCase();
      this.siteNameParam = this.sessionStorageService.getItem('providerState');
      if (this.isbrowser) {
        originUrl = window.location.origin;
      }
      if (this.isserver) {
        originUrl = this.origin;
      }
      if (this.siteNameParam) {
        url = `${originUrl}/${this.siteNameParam}/product/${zipcodeParams}/${locationcodeParams}/${typeParams}/${product.webProgramName
          .toString()
          .split(' ')
          .join('-')
          .split(',')
          .join('')
          .toLowerCase()}`;
      } else {
        url = `${originUrl}/product/${zipcodeParams}/${locationcodeParams}/${typeParams}/${product.webProgramName
          .toString()
          .split(' ')
          .join('-')
          .split(',')
          .join('')
          .toLowerCase()}`;
      }
    }
    const args = {
      url,
      title: 'Protect your family’s home with this affordable protection plan!',
      description: '',
      image: '',
      via: '',
      tags: '',
    };
    const width = 820;
    const height = 500;
    const left = Math.floor((screen.width - width) / 2);
    const top = Math.floor((screen.height - height) / 4);
    let params = `width=${width}, height=${height}`;
    params += `, top=${top}, left=${left}`;
    params += ', directories=no';
    params += ', location=no';
    params += ', menubar=no';
    params += ', resizable=no';
    params += ', scrollbars=no';
    params += ', status=no';
    params += ', toolbar=no';
    switch (link) {
      case 'fb':
        this.popupfacebook(args, params);
        break;
      case 'twitter':
        this.popuptweet(args, params);
        break;
      case 'linkedin':
        this.popuplinkedin(args, params);
        break;
      case 'pinterest':
        this.popupPinterest(args, params);
        break;
      case 'mail':
        this.popupMail(args);
        break;
      default:
    }
  }

  /* Facebook Share Popup */
  popupfacebook(args, screenParams) {
    /* Passing encodeURIComponent as fix of truncate url */
    let shareUrl = 'https://www.facebook.com/sharer/sharer.php';
    shareUrl += `?u=${args.url}`;
    if (args.title) {
      shareUrl += `&title=${args.title}`;
    }
    if (args.description) {
      shareUrl += `&description=${args.description}`;
    }
    if (args.image) {
      shareUrl += `&picture=${args.image}`;
    }
    const newwindow = window.open(shareUrl, 'name', screenParams);
    if (window.focus) {
      newwindow.focus();
    }
  }

  /* Twitter Share Popup */
  popuptweet(args, screenParams) {
    /* Passing encodeURIComponent as fix of truncate url */
    let shareUrl = 'https://twitter.com/intent/tweet';
    shareUrl += `?url=${encodeURIComponent(args.url)}`;
    if (args.title) {
      shareUrl += `&text=${args.title}`;
    }
    if (args.via) {
      shareUrl += `&via=${args.via}`;
    }
    if (args.tags) {
      shareUrl += `&hashtags=${args.tags}`;
    }
    const newwindow = window.open(shareUrl, 'name', screenParams);
    if (window.focus) {
      newwindow.focus();
    }
  }

  /* Linked In Share Popup */
  popuplinkedin(args, screenParams) {
    /* Passing encodeURIComponent as fix of truncate url */

    let shareUrl = 'http://www.linkedin.com/shareArticle';
    shareUrl += `?url=${args.url}`;
    if (args.title) {
      shareUrl += `&title=${args.title}`;
    }
    if (args.description) {
      shareUrl += `&summary=${args.description}`;
    }
    const newwindow = window.open(shareUrl, 'name', screenParams);
    if (window.focus) {
      newwindow.focus();
    }
  }

  /* Pinterest Save Popup */
  popupPinterest(args, screenParams) {
    /* Need to add a visible image for it to show in pnterest (Append the url in media query parameter) */
    /* Passing encodeURIComponent as fix of truncate url */
    let pinterestUrl = `https://pinterest.com/pin/create/button/?url=${args.url}`;
    // if text is not provided, pin button won't work.
    if (args.description) {
      pinterestUrl += `&description=${args.description}`;
    } else if (document) {
      const descElm = document.querySelector('meta[property="og:description"]');
      if (descElm) {
        pinterestUrl += `&description=${descElm.getAttribute('content')}`;
      }
    }
    if (args.image) {
      pinterestUrl += `&media=${args.image}`;
    } else if (document) {
      const imageElm = document.querySelector('meta[property="og:image"]');
      if (imageElm) {
        pinterestUrl += `&media=${imageElm.getAttribute('content')}`;
      }
    }
    const newwindow = window.open(pinterestUrl, 'name', screenParams);
    if (window.focus) {
      newwindow.focus();
    }
  }

  /* Mail to Popup */
  popupMail(args) {
    /* Passing encodeURIComponent as fix of truncate url */
    const mailUrl = `mailto:?subject=Check out this product&body=Protect your family%27s home with this affordable protection plan%21  %3A  ${encodeURIComponent(
      args.url,
    )}`;
    const newwindow = window.open(mailUrl);
    if (window.focus) {
      newwindow.focus();
    }
  }

  /* HWR-2985[KP] & Q1 Fix: This method use to display provider popup */
  getLocationCodeCrm(locationCode: any) {
    this.productService.getLocationDataCRM(locationCode).subscribe(
      respLocation => {
        const location = respLocation.body;
        this.sessionStorageService.setItem('location_code', location.LocationDetails[0].Id);
        const locationData = {
          locationCode: location.LocationDetails[0].Code,
          locationCodeId: location.LocationDetails[0].Id,
        };
        this.locationCode = location.LocationDetails[0].Id;
        this.getMicrositeContent(locationData);
      },
      () => {
        this.showLoader = false;
      },
    );
  }

  getMicrositeContent(location) {
    this.locationService.getMicroSiteForLocation(location.locationCode).subscribe(
      respDataMicroSite => {
        const dataMicroSite = respDataMicroSite.body;
        this.sessionStorageService.setItem('location_code', location.locationCodeId);
        const providerSelectionDetails = this.sessionStorageService.getItem('providerSelectionDetails')
          ? JSON.parse(this.sessionStorageService.getItem('providerSelectionDetails'))
          : '';
        providerSelectionDetails.locationCodeId = location.locationCodeId;
        providerSelectionDetails.providerValue = 'no';
        this.sessionStorageService.setItem('providerSelectionDetails', JSON.stringify(providerSelectionDetails));
        if (dataMicroSite.site.siteName) {
          window.location.href = `${dataMicroSite.site.siteName}/product/${this.zipCode}/${location.locationCode.toLowerCase()}/${this.category}/${
            this.programName
          }`;
        } else {
          window.location.href = `/product/${this.zipCode}/${location.locationCode.toLowerCase()}/${this.category}/${this.programName}`;
        }
      },
      () => {
        this.showLoader = false;
      },
    );
  }

  async providerPopupDetails(dataMicroSite = null, event = null) {
    const providercontent = await this.getProviderContentApi();
    const providercontentBody = providercontent.body;
    let checkMultiZipcode: any;
    if (this.userDetails) {
      if (this.globalVariables.getAllCustomerAddresses) {
        checkMultiZipcode = this.globalVariables.getAllCustomerAddresses.filter(item => item.serviceAddress.zipcode === this.zipCode);
      }
    }
    const providerSelectionDetails = this.sessionStorageService.getItem('providerSelectionDetails')
      ? JSON.parse(this.sessionStorageService.getItem('providerSelectionDetails'))
      : '';
    if (
      providercontentBody &&
      providercontentBody.websitePartnerName !== '' &&
      (!(providerSelectionDetails && providerSelectionDetails.zipCode === this.zipCode && providerSelectionDetails.providerSelected) ||
        (checkMultiZipcode && checkMultiZipcode.length > 1))
    ) {
      this.simpleModalService
        .addModal(
          ProviderModalComponent,
          {
            zipcode: this.zipCode,
          },
          {
            wrapperClass: 'modal-main-class in',
            closeOnClickOutside: false,
          },
        )
        .subscribe(isConfirmed => {
          if (!isConfirmed) {
            this.isProviser = false;
            this.showPartner.emit(false);
            if (this.locationCodeData && this.locationCodeData.serviceState) {
              this.serviceState = this.locationCodeData.serviceState;
            }
            const savedAddress = this.sessionStorageService.getItem('selectedAddress');
            let selectedCity = '';
            if (savedAddress) {
              const address = JSON.parse(savedAddress);
              if (address.rawData && address.rawData.address_components.length) {
                selectedCity = address.rawData.address_components[1].short_name;
              }
            }
            this.checkLocationCodeException(selectedCity, this.serviceState);
          }
          if (isConfirmed) {
            this.micrositesRedirectOnDetailsPage(dataMicroSite, event);
          }
        });
    } else {
      this.micrositesRedirectOnDetailsPage(dataMicroSite, event);
    }
  }

  public getProviderContentApi(): Promise<any> {
    return this.productService.getProviderContent(this.locationCodeId).toPromise();
  }

  checkLocationCodeException(selectedCity, serviceState) {
    this.productService.getLocationData(selectedCity, serviceState).subscribe(resData => {
      const data = resData.body;
      if (data.length > 0) {
        this.sessionStorageService.setItem('location_code', data[0].locationCodeId);
        const locationData = {
          locationCode: data[0].locationCode,
          locationCodeId: data[0].locationCodeId,
        };
        this.locationCodeId = data[0].locationCodeId;
        this.getMicrositeContent(locationData);
      } else if (selectedCity === '') {
        this.getLocationCodeCrm(this.locationCode);
      } else {
        this.checkLocationCodeException('', this.serviceState);
      }
    });
  }

  public getMicrositeContentApi(data): Promise<any> {
    return this.locationService.getMicroSiteForLocation(data).toPromise();
  }

  public getAllCustomerAddressesApi(userDetails): Promise<any> {
    return this.userService.getAllCustomerAddresses(userDetails.user._id).toPromise();
  }

  adjustPricingBoxColor(color, amount) {
    if (this.productType === 'product' && this.productDetail.program.programId.includes(this.specialProduct)) {
      amount = -amount;
    }
    return `#${color
      .replace(/^#/, '')
      // eslint-disable-next-line @typescript-eslint/no-shadow
      .replace(/../g, color => `0${Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)}`.substr(-2))}`;
  }

  setBannerMedia(media, deviceType?) {
    if (deviceType === 'Desktop') {
      this.bannerMedia = media;
    } else if (deviceType === 'Tablet') {
      this.tabletBannerMedia = media;
    } else if (deviceType === 'Mobile') {
      this.mobileBannerMedia = media;
    }
  }

  setScrollHeight() {
    const breaksmall: any = 768;
    const windowWidth: any = window.innerWidth;
    if (windowWidth < breaksmall) {
      const setHeight: any = 100;
      // if (this.productBannerMedia && this.productBannerMedia.length > 1) {
      //     setHeight = 0;
      // }
      return this.getScrollHeightAndReturn(setHeight, windowWidth);
    }
    if (windowWidth >= breaksmall) {
      return this.getScrollHeightAndReturn(100);
    }
  }

  getScrollHeightAndReturn(lessWidth?: any, windowWidth?: any) {
    const element = this.generalSection ? this.generalSection.nativeElement : '';
    if (element && element.getBoundingClientRect) {
      const viewportOffset = element.getBoundingClientRect();
      const { bottom } = viewportOffset;
      this.divHeightWithScroll = bottom + window.scrollY;
      this.divHeightWithScrollEmitter.emit(this.divHeightWithScroll);
      return bottom + window.scrollY - lessWidth;
    }
    if (this.divHeightWithScroll) {
      this.divHeightWithScrollEmitter.emit(windowWidth < 768 ? this.divHeightWithScroll - 100 : this.divHeightWithScroll);
    }
    return this.divHeightWithScroll ? this.divHeightWithScroll - (windowWidth < 768 ? 100 : 100) : 300;
  }

  goToInstallRequirements() {}

  getStaticBannerImages(deviceType?: any) {
    if (deviceType === 'Desktop') {
      /* Desktop display banner media */
      this.bannerMedia = {
        type: 'image',
        value: `../../../../img/${this.productDetail.bannerImages.descriptionImage[0]}`,
      };

      /* Condition to handle multiple banner image strict no duplicate */
      this.productDetail.bannerImages.descriptionImage.forEach(element => {
        if (this.productBannerMedia.indexOf(element) === -1) {
          this.productBannerMedia.push({
            type: 'image',
            value: `../../../../img/${element}`,
          });
        }
      });
    } else if (deviceType === 'Tablet') {
      /* Tablet display banner media */
      this.tabletBannerMedia = {
        type: 'image',
        value: `../../../../img/${this.productDetail.bannerImages.descriptionTabletImages[0]}`,
      };

      /* Condition to handle multiple banner image strict no duplicate */
      this.productDetail.bannerImages.descriptionTabletImages.forEach(element => {
        if (this.productTabletBannerMedia.indexOf(element) === -1) {
          this.productTabletBannerMedia.push({
            type: 'image',
            value: `../../../../img/${element}`,
          });
        }
      });
    } else if (deviceType === 'Mobile') {
      /* Mobile display banner media */
      this.mobileBannerMedia = {
        type: 'image',
        value: `../../../../img/${this.productDetail.bannerImages.descriptionMobileImages[0]}`,
      };

      /* Condition to handle multiple banner image strict no duplicate */
      this.productDetail.bannerImages.descriptionMobileImages.forEach(element => {
        if (this.productMobileBannerMedia.indexOf(element) === -1) {
          this.productMobileBannerMedia.push({
            type: 'image',
            value: `../../../../img/${element}`,
          });
        }
      });
    }
  }

  getDynamicBannerImages(deviceType?: any) {
    if (deviceType === 'Desktop') {
      /* Desktop display banner media */
      this.bannerMedia = {
        type: 'image',
        value: this.getImage(this.baseUrl + this.productDetail.bannerImages.descriptionImage[0]),
      };

      /* Condition to handle multiple banner image strict no duplicate */
      this.productDetail.bannerImages.descriptionImage.forEach(element => {
        if (this.productBannerMedia.indexOf(element) === -1) {
          this.productBannerMedia.push({
            type: 'image',
            value: this.getImage(this.baseUrl + element),
          });
        }
      });
    } else if (deviceType === 'Tablet') {
      /* Tablet display banner media */
      this.tabletBannerMedia = {
        type: 'image',
        value: this.getImage(this.baseUrl + this.productDetail.bannerImages.descriptionTabletImages[0]),
      };

      /* Condition to handle multiple banner image strict no duplicate */
      this.productDetail.bannerImages.descriptionTabletImages.forEach(element => {
        if (this.productTabletBannerMedia.indexOf(element) === -1) {
          this.productTabletBannerMedia.push({
            type: 'image',
            value: this.getImage(this.baseUrl + element),
          });
        }
      });
    } else if (deviceType === 'Mobile') {
      /* Mobile display banner media */
      this.mobileBannerMedia = {
        type: 'image',
        value: this.getImage(this.baseUrl + this.productDetail.bannerImages.descriptionMobileImages[0]),
      };

      /* Condition to handle multiple banner image strict no duplicate */
      this.productDetail.bannerImages.descriptionMobileImages.forEach(element => {
        if (this.productMobileBannerMedia.indexOf(element) === -1) {
          this.productMobileBannerMedia.push({
            type: 'image',
            value: this.getImage(this.baseUrl + element),
          });
        }
      });
    }
  }

  addCheckoutItemToCart(productData: any) {
    let { programId } = productData.program;
    if (productData.coverageType === 'UNLIMITED') {
      programId = `unlimited-${productData.program.programId}`;
    }
    const locationId = this.locationCodeId || productData.defaultLocationCodeId;
    const checkoutCartData: any = this.sessionStorageService.getItem('cartCheckout')
      ? JSON.parse(this.sessionStorageService.getItem('cartCheckout'))
      : [];
    let cartValue = [];
    if (checkoutCartData.items) {
      cartValue = checkoutCartData.items.map(item => {
        let { programIdData } = item.details.program;
        if (item.details.coverageType === 'UNLIMITED') {
          programIdData = `unlimited-${item.details.program.programId}`;
        }
        return programIdData;
      });
    }

    this.productService.getUpsellPopupData(programId, locationId, cartValue).subscribe(
      data => {
        const popup = data.body.result;
        if (data.status === 200) {
          this.addToCart(productData, popup);
        }
      },
      error => {
        console.log(error);
      },
    );
  }

  showCheckoutPage() {
    this.router.navigate(['/user/checkout']);
  }

  addToCart(productData, popup) {
    productData.isBundle = productData.program.products.length > 1;
    productData.status = 'PENDING_PAYMENT';
    productData.provider = 'AWR';
    productData.locationId = this.locationCodeId || productData.defaultLocationCodeId;
    productData.zipcode = this.zipCode;
    const checkoutCartData: any = this.sessionStorageService.getItem('cartCheckout')
      ? JSON.parse(this.sessionStorageService.getItem('cartCheckout'))
      : [];
    if (
      this.zipCode &&
      checkoutCartData &&
      checkoutCartData.items &&
      checkoutCartData.items.length > 0 &&
      this.zipCode !== checkoutCartData.items[0].zipcode
    ) {
      checkoutCartData.items = [];
      this.sessionStorageService.setItem('cartCheckout', JSON.stringify(checkoutCartData));
      this.globalVariables.cartCheckout = JSON.parse(this.sessionStorageService.getItem('cartCheckout'));
    }
    this.cartQuickCheckoutService.addItem(productData, (err, data, status) => {
      this.sessionStorageService.setItem('isRetainEnrollClicked', 'false');
      if (err) {
        if (data.status === 409 && !data.isAlreadyBundle) {
          const gaTrackBtnId = 'existing_program_in_cart';
          this.showErrorModal("We're Sorry", 'This program has already been added to your cart.', 'Ok', gaTrackBtnId);
        } else if (data.status === 409 && data.isAlreadyBundle) {
          const gaTrackBtnId = 'existing_bundle_in_cart';
          this.showErrorModal("We're Sorry", 'This program is included in a bundle that has already been added to your cart.', 'Ok', gaTrackBtnId);
        } else if (data.status === 400) {
          this.showErrorModal(
            'Upgrade to Bundle',
            `If you are looking to bundle your existing services,
          please visit your My Services page and view the list of opportunities from your current services.`,
            'Ok',
          );
        } else {
          this.showErrorModal('Error', 'Error while adding item to cart');
        }
      } else if (status === 201) {
        this.openBundle(data);
      } else if (!!data && data.length > 0) {
        this.sessionStorageService.setItemToloclaStorage('deletedCartDataCheckout', JSON.stringify(data));
      } else {
        if (popup.length <= 0) {
          this.showCheckoutPage();
        }
        if (popup.length > 0) {
          this.simpleModalService
            .addModal(
              UpsellServiceDetailComponent,
              {
                service: popup,
                locationCodeData: this.locationCodeData,
                isFromCheckout: true,
              },
              {
                wrapperClass: 'modal-main-class in',
                closeOnClickOutside: true,
              },
            )
            .subscribe((isConfirmed: any) => {
              this.windowScrolling.enable();
              if (isConfirmed) {
                productData.productId = productData._id;
                delete isConfirmed.removeProduct;
                this.cartQuickCheckoutService.removeItem(productData, () => {
                  popup = [];
                  this.addToCart(isConfirmed, popup);
                });
              }
            });
          popup = [];
        }
      }
      this.showLoader = false;
    });
  }

  micrositesRedirectOnDetailsPage(dataMicroSite, event) {
    if (dataMicroSite && event) {
      this.sessionStorageService.setItem('isMicroSite', '1');
      if (dataMicroSite.site.siteName) {
        dataMicroSite.site.siteName = dataMicroSite.site.siteName.toLowerCase();
      } else {
        dataMicroSite.site.siteName = '';
      }

      let providerState = '';
      providerState = this.sessionStorageService.getItem('providerState') ? this.sessionStorageService.getItem('providerState').toLowerCase() : '';
      if (providerState === dataMicroSite.site.siteName) {
        if (this.productType === 'product') {
          this.router.navigate([
            `/product/${event.zipcode}/${this.locationCodeData.locationCode.toLowerCase()}/${this.category.toLowerCase()}/${this.programName
              .toString()
              .split(' ')
              .join('-')
              .split(',')
              .join('')
              .toLowerCase()}`,
          ]);
        } else {
          /** Fix for service change passsing hardcoded value */
          this.router.navigate([
            `/product/${event.zipcode}/${this.locationCodeData.locationCode.toLowerCase()}/residential/${this.programName
              .toString()
              .split(' ')
              .join('-')
              .split(',')
              .join('')
              .toLowerCase()}`,
          ]);
        }
      } else {
        if (dataMicroSite.site.siteName) {
          dataMicroSite.site.siteName = dataMicroSite.site.siteName.toLowerCase();
        } else {
          dataMicroSite.site.siteName = '';
        }
        this.sessionStorageService.setItem('providerState', dataMicroSite.site.siteName);
        this.sessionStorageService.setItem('baseUrlState', dataMicroSite.site.siteName);

        if (this.productType === 'product') {
          let queryString = dataMicroSite.site.siteName ? `/${dataMicroSite.site.siteName}/product` : '/product';
          /** HWR - 2696 */
          queryString += event.zipcode ? `/${event.zipcode}` : '';
          queryString += this.locationCodeData.locationCode ? `/${this.locationCodeData.locationCode.toLowerCase()}` : '';
          queryString += this.category ? `/${this.category.toLowerCase()}` : '';
          queryString += this.programName ? `/${this.programName.toString().split(' ').join('-').split(',').join('').toLowerCase()}` : '';
          window.location.href = queryString;
          /** HWR - 2696 */
        } else {
          /** Fix for service change passsing hardcoded value */
          const queryServiceUrl = dataMicroSite.site.siteName
            ? `/${dataMicroSite.site.siteName}/product/${
                event.zipcode
              }/${this.locationCodeData.locationCode.toLowerCase()}/residential/${this.programName
                .toString()
                .split(' ')
                .join('-')
                .split(',')
                .join('')
                .toLowerCase()}`
            : `/product/${event.zipcode}/${this.locationCodeData.locationCode.toLowerCase()}/residential/${this.programName
                .toString()
                .split(' ')
                .join('-')
                .split(',')
                .join('')
                .toLowerCase()}`;
          window.location.href = queryServiceUrl;
        }
      }
    }
  }
}
