<div class="curve-mask">
  <section class="content-block content-section partner">
    <div class="partner-cls">
      <article class="page-wrap intro-txt row">
        <h3 class="section-heading col-12 col-sm-12 col-md-12">{{ partnersData.title }}</h3>
        <span class="col-12 col-sm-12 partner-description">
          {{ partnersData.description }}
        </span>
      </article>
      <div class="page-wrap intro-txt row">
        <ul class="col-12 col-sm-12 partner-list row">
          <ng-container *ngIf="isbrowser">
            <li class="col-12 col-xs-6 col-sm-6 col-md-4 col-lg-3 partner-logo PD-0" *ngFor="let partner of partnersData.partners" appReveal>
              <div class="bgPartner">
                <span class="logo">
                  <img *ngIf="partner?.icon" [src]="getImage(partnerServiceBaseUrl + partner.icon)" [alt]="partner.partnerName" />
                </span>
                <p class="partnerName">{{ partner.partnerName }}</p>
              </div>
            </li>
          </ng-container>
        </ul>
      </div>
      <div *ngIf="partnersData?.partnerPhone" class="page-wrap alignBtm row">
        <h4 class="col-12 col-sm-12 partnerTextM">
          <p>{{ partnersData?.partnerText }}</p>
          <p>
            <a class="partner-tel" href="tel:{{ partnersData?.partnerPhone }}"><i class="fa-regular fa-phone"></i> {{ partnersData.partnerPhone }}</a>
          </p>
        </h4>
      </div>
    </div>
  </section>
</div>
