import { Component, OnInit, OnDestroy, ViewChild, HostListener, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NgForm } from '@angular/forms';
import { first, forkJoin, Observable } from 'rxjs';
import { nanoid } from 'nanoid';
import { SimpleModalService } from 'ngx-simple-modal';
import { find, map } from 'lodash-es';
import { appConfig } from '../../../core/appConfig/config';
import { UserService } from '../../services/user.service';
import { SessionStorageService } from '../../../core/services/session-storage.service';
import { environment } from '../../../../environments/environment';
import { ErrorModalComponent } from '../../../core/component/error-modal/error-modal.component';
import { User } from './user';
import { SiteContentService } from '../../../core/services/siteContents';
import { SeoConfig } from '../../../core/model/seo-config.model';
import { SeoService } from '../../../core/services/seo.service';
import { ProductService } from '../../../product/services/product.service';
import { ZipcodeService } from '../../../core/services/zipcode.service';
import { GooglePlacesService } from '../../../core/services/google-places.service';
import { CrmProxyService } from '../../../core/services/crm-proxy.service';
import { CCPAUpdatesService } from '../../../core/services/ccpa-updates.service';
import { GlobalVariables } from '../../../core/services/global-variables.service';
import { UtilsService } from '../../../core/services/utils.service';
import { LocationService } from '../../../home/services/location.service';
import { ShoppingCart } from './model/cart.model';
import { CartQuickCheckoutService } from '../../../cart/services/cart-quick-checkout.service';
import { CartDetailsComponent } from '../cart-details/cart-details.component';
import { AlertConfirmModalComponent } from '../../../core/component/alert-confirm-modal/alert-confirm-modal.component';
import { ProviderModalComponent } from '../../../product/component/provider-modal/provider-modal.component';
import { AddressValidationModalComponent } from '../address-validation-modal/address-validation-modal.component';
import { OrdersService } from '../../../payment-ets/services/orders.service';
import { BundleModalComponent } from '../../../cart/component/bundle-modal/bundle-modal.component';
import { ZipcodeComponent } from '../../../core/component/zipcode/zipcode.component';
import { GtagService } from '../../../gtag/gtag.service';
import { GtagPayloadGeneratorService } from '../../../core/services/gtag-payload-generator';
import { UpgradeServiceModalComponent } from './upgrade-service-modal/upgrade-service-modal.component';

// declare let hp: any;
declare let FullStory;

@Component({
  selector: 'hos-checkout-cart',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
})
export class CheckoutComponent implements OnInit, OnDestroy {
  selectedAddress: any = {
    rawData: '',
    zipcode: '',
  };

  temporaryUserId: string;

  resultRegiData: any;

  locationCode: any;

  zipcode: any;

  selectedCity: any;

  validAddress = true;

  strAddress: string = '';

  zipcodeValid: boolean = false;

  registerError: string = '';

  registerSuccess: string = '';

  userEmail: string = '';

  isClicked: boolean;

  invalidForm: boolean = false;

  user: User = new User();

  existingUser: User = new User();

  email_regex: string;

  phone_number_regex: string;

  customer_service_number: string;

  referer: string;

  isExistingUser: boolean = false;

  states: any;

  showLoader: boolean = false;

  zipcodeSet: boolean = false;

  streetNumberValidation: string = appConfig.streetNumber;

  addressLookup: any = {
    zipcode: '',
  };

  stateCode: any;

  showProvider: boolean = false;

  locationCodeData: any;

  partnerName: any;

  confirmEmailId: string;

  existingCustomerData: any;

  backPress: boolean = false;

  seoConfig: SeoConfig = {
    title: 'Enroll Now in Service Line Warranties | American Water Resources',
    description: `American Water Resources offers affordable home protection programs
      that help protect against unexpected repair costs for service line emergencies.`,
    keywords: `service line warranties, water line protection, sewer line protection,
      in home plumbing emergencies, electric line protection, power surge protection, heating system repair, cooling system repair`,
    image: '',
    slug: '',
    sitename: '',
    type: '',
    summary: '',
    url: '',
  };

  showDropDown = false;

  suggestedAddress: any;

  homePhoneErrorMsg: string;

  phoneNumberExistingErrorMsg: string;

  isDisposableEmail: boolean = false;

  homeOwner = {
    isHomeOwner: null,
    allowRental: null,
    offbillOnly: null,
  };

  providerAvailable: boolean = false;

  providerSelected: boolean;

  existingUserLocationCodeId: any;

  oldUserData: any = {
    email: '',
    zipcode: '',
  };

  abandonedLocationCode: any;

  isExistingCustReg: boolean = false;

  @ViewChild('registerForm') registerForm: NgForm;

  public cartProducts: Array<any>;

  cart: Observable<ShoppingCart>;

  cartSubscription: any;

  public itemCount: number;

  showPaymentPage: boolean = false;

  isAuthorizedToViewForm: boolean = false;

  toggleMobileCartVal: boolean = false;

  cartData: any;

  EmailExistedData: any;

  address: any;

  customerDetails: any;

  guestToken: any = '';

  addressPresentIn: any;

  zipcodeFromCartCheckout: any;

  @ViewChild(CartDetailsComponent) cartDetailsComponent: CartDetailsComponent;

  customerGuid: any;

  productsByBillingType: any;

  sapLocations: any;

  customerServiceNumber: string;

  partnerObj: any = {};

  cuid: string; // cuid for if only onbill products

  providerState: any;

  siteName: any;

  guestToken_id: any;

  name_regex: string;

  locationDataFetched: boolean = false;

  userName: any;

  passNewRegisterCustomerResponse: any;

  blockUnicodeRegex: string = environment.blockUnicodeRegex;

  lastNameMismatchFound: string = '';

  isBrowser: boolean = true;

  @ViewChild(ZipcodeComponent) zipcodeComponent: ZipcodeComponent;

  oldZipcode: any = '';

  isDefaultMkc = true;

  isZipcodeEditable = false;

  @HostListener('document:click')
  clickout() {
    this.showDropDown = false;
  }

  constructor(
    private utilsService: UtilsService,
    private router: Router,
    private userService: UserService,
    public activatedRoute: ActivatedRoute,
    private sessionStorageService: SessionStorageService,
    private simpleModalService: SimpleModalService,
    seoService: SeoService,
    private siteContentService: SiteContentService,
    private productService: ProductService,
    private zipcodeService: ZipcodeService,
    private googlePlacesService: GooglePlacesService,
    private crmProxyService: CrmProxyService,
    private ccpaUpdatesService: CCPAUpdatesService,
    private locationService: LocationService,
    public globalVariables: GlobalVariables,
    private cartQuickCheckoutService: CartQuickCheckoutService,
    private ordersService: OrdersService,
    private gtagService: GtagService,
    private gtagPayloadGeneratorService: GtagPayloadGeneratorService,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
    seoService.generateTitleTags(this.seoConfig);
    seoService.setCanonicalTags();
    this.referer = this.activatedRoute.snapshot.queryParams.referer;
    this.customer_service_number = environment.customer_service_number;
    this.customerServiceNumber = environment.customer_service_number;
    this.userService.setLoggin('');
    this.email_regex = environment.email_regex;
    this.sapLocations = environment.SAPLocations;
    this.globalVariables.triggerOtherRelatedProduct.subscribe(data => {
      if (data) {
        this.setOtherProductsToCartLocal();
      }
    });
    this.name_regex = environment.name_regex;
  }

  ngOnInit() {
    this.isBrowser = isPlatformBrowser(this.platformId);
    if (this.isBrowser) {
      this.activatedRoute.queryParams.pipe(first()).subscribe(async query => {
        if (query.type === 'nondefault' && query.mkc) {
          this.isDefaultMkc = false;
          this.isZipcodeEditable = true;
          this.addToCartUsingQueryParam(query);
        } else if (query.mkc && query.zipcode && query.location) {
          this.addToCartUsingQueryParam(query);
        } else {
          this.initialize();
        }
      });
    }
  }

  async initialize() {
    this.isBrowser = isPlatformBrowser(this.platformId);
    this.router.navigate(['/user/checkout'], {
      queryParams: {
        section: 'cart',
      },
    });
    this.initializeProductStructure();
    this.globalVariables.cartCheckout = JSON.parse(this.sessionStorageService.getItem('cartCheckout'));
    if (!this.globalVariables.cartCheckout) {
      this.cartQuickCheckoutService.createCartBeforeCall().subscribe(() => {});
    }
    this.customerGuid = '';
    const cartDataFromLocal = JSON.parse(this.sessionStorageService.getItem('cartCheckout'));
    this.isDefaultMkc = !(cartDataFromLocal && cartDataFromLocal.mkcType === 'nondefault');
    this.isZipcodeEditable = !this.isDefaultMkc;
    this.zipcodeFromCartCheckout =
      cartDataFromLocal && cartDataFromLocal.items.length > 0
        ? {
            zipcode: cartDataFromLocal.items[0].zipcode,
          }
        : '';
    const userData = JSON.parse(this.sessionStorageService.getItem('user_data'));
    const savedZipcode = this.zipcodeFromCartCheckout.zipcode;
    if (userData && userData.address[0].serviceAddress.zipcode === savedZipcode) {
      this.user.firstName = userData.firstName;
      this.user.lastName = userData.lastName;
      this.user.email = userData.email;
      this.confirmEmailId = userData.email;
      this.user.phoneNumber = userData.phoneNumber;
      this.user.address[0].serviceAddress.streetNumber = userData.address[0].serviceAddress.streetNumber;
      this.user.address[0].serviceAddress.streetName = userData.address[0].serviceAddress.streetName;
      this.user.address[0].serviceAddress.city = userData.address[0].serviceAddress.city;
    }
    this.sessionStorageService.deleteItem('providerSelected');
    this.sessionStorageService.deleteItem('isProvider');
    this.sessionStorageService.deleteItem('websitePartnerName');
    this.siteContentService.statesdata$().subscribe(states => {
      this.states = states;
      if (!this.states) {
        this.states = this.sessionStorageService.getItem('statesList') ? JSON.parse(this.sessionStorageService.getItem('statesList')) : states;
      }
    });
    this.showCCPAUpdates();
    this.siteName = this.sessionStorageService.getItem('providerState');
    this.providerState = this.sessionStorageService.getItem('websitePartnerName');
    this.partnerObj = {
      siteName: this.siteName,
      providerName: this.providerState,
    };
    this.cuid = nanoid();
    this.cart = this.cartQuickCheckoutService.get();
    if (this.cart) {
      this.cart.subscribe(cart => {
        this.cartData = cart;
        this.cartProducts = cart.items;
        this.globalVariables.cartAmount.emit(cart);
      });
    }

    if (this.isDefaultMkc) {
      this.setLocationData()
        .then(() => {
          this.setProductsByBillingType()
            .then(() => {
              this.calculateBybillingType(this.productsByBillingType);
            })
            .catch(() => {
              const trackAPIGATracking = 'trackProductTaxBillingAPIFail';
              this.globalVariables.showErrorMessageOnCart = true;
              this.showLoader = false;
              this.showErrorAlert(trackAPIGATracking);
            });
        })
        .catch(() => {
          const GAErrorId = 'checkout_error_fetching_LocationContent';
          const title = 'We are currently facing technical difficulties.';
          const message = '<br><p style="margin-top:-20px;margin-bottom:0;">Please try again after sometime. Sorry for any inconvenience.';
          this.showErrorModal(title, message, ErrorModalComponent, GAErrorId);
        });
    } else {
      this.setNonDefaultMarketingCodeAsOffbill();
      this.calculateBybillingType(this.productsByBillingType);
      this.locationDataFetched = true;
    }
  }

  getImage(imageName) {
    return this.utilsService.buildImageUrl(imageName);
  }

  onZipcodeUpdated(data: any) {
    if (data) {
      this.locationCode = data.locationCodeId;
      this.user.address[0].serviceAddress.zipcodeId = data.zipcodeId;
      this.user.address[0].serviceAddress.locationCodeId = data.locationCodeId;
      if (this.isDefaultMkc) {
        this.user.address[0].serviceAddress.zipcode = this.globalVariables.cartCheckout.items[0].zipcode
          ? this.globalVariables.cartCheckout.items[0].zipcode
          : '';
      } else {
        this.user.address[0].serviceAddress.zipcode = this.zipcodeComponent.zipcode;
      }
      this.zipcodeValid = true;
      this.stateCode = data.stateCode;
      let oofLocationCode = '';
      if (this.user && this.user.address && this.user.address.length > 0 && this.user.address[0].serviceAddress) {
        this.states = ['null', 'undefined', undefined, null, ''].includes(this.states)
          ? this.sessionStorageService.getItem('statesList')
            ? JSON.parse(this.sessionStorageService.getItem('statesList'))
            : this.states
          : this.states;
        this.user.address[0].serviceAddress.state = find(this.states, {
          key: data.stateCode,
        }).value;
        oofLocationCode = `${this.user.address[0].serviceAddress.state}150`;
      }
      if (this.sessionStorageService.getItem('providerSelected') !== '1') {
        this.showLoader = true;
        this.googlePlacesService.getCityAndStateData(this.user.address[0].serviceAddress.zipcode).subscribe(
          result => {
            result = result.body;
            this.autoCompleteSearchCallback(result.results[0]);
            this.productService.getProviderContent(data.locationCodeId).subscribe(dataForLocation => {
              dataForLocation = dataForLocation.body;
              this.checkLocationChangedForReferer(dataForLocation.locationCode);
              if (oofLocationCode !== dataForLocation.locationCode) {
                this.productService.getLocationCode(oofLocationCode).subscribe(dataForOofLocationCode => {
                  dataForOofLocationCode = dataForOofLocationCode.body;
                  this.user.address[0].serviceAddress.locationCodeId = dataForOofLocationCode[0].locationCodeId;
                  this.checkLocationChangedForReferer(dataForLocation.locationCode);
                  this.updateDataForLocation(dataForLocation);
                });
              } else {
                this.updateDataForLocation(dataForLocation);
              }
            });
          },
          () => {
            // error in google service
            this.showLoader = false;
          },
        );
      }
    } else {
      this.zipcodeValid = false;
      this.sessionStorageService.deleteItem('providerSelected');
      this.sessionStorageService.deleteItem('isProvider');
      this.sessionStorageService.deleteItem('websitePartnerName');
    }
  }

  setLocationForLocalCart(locationCodeId) {
    if (!this.isDefaultMkc) {
      const search = this.globalVariables.cartCheckout.items[0].details.locationCodes.find(p => p.locationCodeId === locationCodeId);
      if (!search) {
        const GAErrorId = 'do_not_offer';
        this.showLoader = false;
        this.showCartErrorModal('We are sorry. We do not currently offer this service in your location.', '', '', GAErrorId, false);
        this.zipcodeComponent.zipcode = undefined;
      } else {
        this.locationCode = locationCodeId;
        this.sessionStorageService.setItem('location_code', this.locationCode);
        const checkoutCartData = JSON.parse(this.sessionStorageService.getItem('cartCheckout'));
        checkoutCartData.items.forEach(p => {
          p.locationId = this.locationCode;
          p.zipcode = this.zipcodeComponent.zipcode;
        });
        this.globalVariables.cartCheckout = checkoutCartData;
        this.cartProducts = checkoutCartData.items;
        this.cartData = checkoutCartData;
        this.sessionStorageService.setItem('cartCheckout', JSON.stringify(checkoutCartData));
        this.globalVariables.cartAmount.emit(checkoutCartData);
        this.initializeProductStructure();
        this.setProductsByBillingType().then(() => {
          this.calculateBybillingType(this.productsByBillingType);
          this.globalVariables.cartSwap.emit(checkoutCartData);
        });
      }
    }
  }

  autoCompleteSearchCallback(data: any) {
    if (data) {
      if (data) {
        for (let i = 0; i < data.address_components.length; i++) {
          for (let j = 0; j < data.address_components[i].types.length; j++) {
            if (data.address_components[i].types[j] === 'postal_code') {
              this.selectedAddress.rawData = data;
              this.selectedAddress.zipcode = this.user.address[0].serviceAddress.zipcode;
              this.validAddress = false;
              this.sessionStorageService.setItem('selectedAddress', JSON.stringify(this.selectedAddress));
              this.user.address[0].serviceAddress.city = this.selectedAddress?.rawData?.formatted_address?.split(',')[0];
            }
          }
        }
      }
    } else {
      this.sessionStorageService.deleteItem('location_code');
      this.sessionStorageService.deleteItem('selectedAddress');
    }
  }

  openModalPopup(selectedZipcode: string) {
    this.providerAvailable = true;
    this.simpleModalService
      .addModal(
        ProviderModalComponent,
        {
          zipcode: selectedZipcode,
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: false,
        },
      )
      .subscribe(isConfirmed => {
        this.sessionStorageService.setItem('providerSelected', '1');
        this.sessionStorageService.setItem('isProvider', '1');
        if (isConfirmed) {
          this.showProvider = true;
          this.providerSelected = true;
          this.productService.setCategories();
          this.globalVariables.OOFSelected.isOOFLocationRegister = 'false';
          this.setLocationForLocalCart(this.locationCode);
        } else if (this.backPress) {
          this.backPress = false;
        } else {
          this.partnerName = '';
          this.sessionStorageService.setItem('websitePartnerName', '');
          this.providerSelected = false;
          this.globalVariables.OOFSelected.isOOFLocationRegister = 'true';
          this.notMyProvider();
        }
        if (this.user.address[0].serviceAddress.state.toLowerCase() === 'ca') {
          this.ccpaUpdatesService.getCCPAUpdates();
        }
      });
  }

  notMyProvider() {
    const savedAddress = this.sessionStorageService.getItem('selectedAddress');
    this.selectedCity = '';
    if (savedAddress) {
      const address = JSON.parse(savedAddress);
      if (address.rawData && address.rawData.address_components.length) {
        this.selectedCity = address.rawData.address_components[1].short_name;
      }
    }
    this.productService.getLocationData(this.selectedCity, this.locationCodeData.serviceState).subscribe(
      data => {
        data = data.body;
        if (data.length > 0) {
          this.productService.getProviderContent(data[0].locationCodeId).subscribe(() => {
            this.user.address[0].serviceAddress.locationCodeId = data[0].locationCodeId;
            this.checkLocationChangedForReferer(data[0].locationCode);
            this.sessionStorageService.setItem('location_code', data[0].locationCodeId);
            this.productService.setCategories();
            this.locationCode = data[0].locationCodeId;
            this.abandonedLocationCode = data[0].locationCode;
            this.setLocationForLocalCart(data[0].locationCodeId);
            // this.abandonedRegistrationInsert();
          });
        } else {
          this.getLocationDataState();
        }
      },
      () => {
        this.showLoader = false;
      },
    );
  }

  getLocationDataState() {
    this.selectedCity = '';
    this.productService.getLocationData(this.selectedCity, this.locationCodeData.serviceState).subscribe(
      dataReceived => {
        dataReceived = dataReceived.body;
        if (dataReceived.length > 0) {
          this.productService.getProviderContent(dataReceived[0].locationCodeId).subscribe(() => {
            this.user.address[0].serviceAddress.locationCodeId = dataReceived[0].locationCodeId;
            this.checkLocationChangedForReferer(dataReceived[0].locationCode);
            this.sessionStorageService.setItem('location_code', dataReceived[0].locationCodeId);
            this.setLocationForLocalCart(dataReceived[0].locationCodeId);
            this.productService.setCategories();
            this.locationCode = dataReceived[0].locationCodeId;
            this.abandonedLocationCode = dataReceived[0].locationCode;
            // this.abandonedRegistrationInsert();
          });
        } else {
          this.getLocationCodeCrm(this.locationCodeData.locationCode);
        }
      },
      () => {
        this.showLoader = false;
      },
    );
  }

  getLocationCodeCrm(locationCode: any) {
    this.productService.getLocationDataCRM(locationCode).subscribe(
      location => {
        location = location.body;
        this.productService.getProviderContent(location.LocationDetails[0].Id).subscribe(() => {
          this.user.address[0].serviceAddress.locationCodeId = location.LocationDetails[0].Id;
          this.checkLocationChangedForReferer(location.LocationDetails[0].Code);
          this.sessionStorageService.setItem('location_code', location.LocationDetails[0].Id);
          this.globalVariables.OOFSelected.isOOFLocationRegister = 'true';
          this.locationCode = location.LocationDetails[0].Id;
          this.productService.setCategories();
          this.abandonedLocationCode = location.LocationDetails[0].Code;
          this.setLocationForLocalCart(location.LocationDetails[0].Id);
          // this.abandonedRegistrationInsert();
        });
      },
      () => {
        this.showLoader = false;
      },
    );
  }

  stateChangeHandler(event: any) {
    if (this.stateCode && this.stateCode !== event.key) {
      this.showAlertModal({
        title: 'Error',
        // tslint:disable-next-line:max-line-length
        message: `Zipcode does not exist in selected state, Please select ${
          find(this.states, {
            key: this.stateCode,
          }).value
        } to proceed.`,
        alertIcon: 'fa fa-exclamation-circle red',
        cancelText: 'Cancel',
        confirmText: 'Ok',
      });
    }
  }

  onZipcodeUpdatedLookup(data: any) {
    if (data) {
      this.addressLookup.zipcodeId = data.zipcodeId;
      this.addressLookup.locationCodeId = data.locationCodeId;
      this.addressLookup.state = data.stateCode;
      this.states = ['null', 'undefined', undefined, null, ''].includes(this.states)
        ? this.sessionStorageService.getItem('statesList')
          ? JSON.parse(this.sessionStorageService.getItem('statesList'))
          : this.states
        : this.states;
      const stateName = find(this.states, {
        key: this.addressLookup.state,
      });
      this.addressLookup.stateName = stateName.value;
      this.zipcodeSet = true;
    } else {
      this.zipcodeSet = false;
      this.addressLookup.stateName = '';
    }
  }

  goToStep(form: any) {
    this.userName = `${form.value.firstName} ${form.value.lastName}`;
    this.isClicked = true;
    if (form.invalid) {
      if (form.controls.firstName && (form.controls.firstName.valid === false || form.controls.lastName.valid === false)) {
        $('html, body').animate(
          {
            scrollTop: $('#name').offset().top - 60,
          },
          1000,
        );
      } else if (
        form.controls.email &&
        (form.controls.email.valid === false ||
          form.controls.phoneNumber.valid === false ||
          (form.controls.businessPhone && form.controls.businessPhone.valid === false) ||
          (form.controls.phoneNumberExisting && form.controls.phoneNumberExisting.valid === false) ||
          this.isExistingUser ||
          this.isDisposableEmail)
      ) {
        $('html, body').animate(
          {
            scrollTop: $('#email').offset().top - 50,
          },
          1000,
        );
      } else if (
        form.controls.city &&
        (form.controls.city.valid === false ||
          form.controls.state.valid === false ||
          form.controls.streetNumber.valid === false ||
          form.controls.streetName.valid === false)
      ) {
        $('html, body').animate(
          {
            scrollTop: $('#service-address').offset().top,
          },
          1000,
        );
      }
      return;
    }
    this.states = ['null', 'undefined', undefined, null, ''].includes(this.states)
      ? this.sessionStorageService.getItem('statesList')
        ? JSON.parse(this.sessionStorageService.getItem('statesList'))
        : this.states
      : this.states;
    if (
      form.controls.state.value ===
      find(this.states, {
        key: this.stateCode,
      }).value
    ) {
      this.showLoader = true;
      if (form.valid && this.zipcodeValid && !this.isDisposableEmail) {
        const searchAddress = this.user.address[0].serviceAddress;
        this.getSmartAddress(
          searchAddress.streetNumber,
          searchAddress.streetName || '',
          searchAddress.state,
          searchAddress.city,
          searchAddress.zipcode,
          upDateAddress => {
            if (upDateAddress) {
              if (this.globalVariables.OOFSelected && this.globalVariables.OOFSelected.isOOFLocationRegister === 'true') {
                /* Add code if need to set other field for ooflocation */
              } else {
                this.zipcodeService.getZipcodeId(upDateAddress.zipcode).subscribe(
                  res => {
                    // this.onZipcodeUpdated(res);
                    res = res.body;
                    this.user.address[0].serviceAddress.zipcodeId = res.zipCodeId;
                    this.user.address[0].serviceAddress.locationCodeId = res.locationCodeId;
                  },
                  () => {},
                );
              }
              if (this.user.address[0].serviceAddress.zipcode !== upDateAddress.zipcode) {
                this.oldZipcode = JSON.parse(JSON.stringify(this.user.address[0].serviceAddress.zipcode));
              }
              this.user.address[0].serviceAddress.streetNumber = upDateAddress.streetNumber;
              this.user.address[0].serviceAddress.streetName = upDateAddress.streetName || '';
              this.user.address[0].serviceAddress.city = upDateAddress.city;
              this.user.address[0].serviceAddress.state = upDateAddress.state;
              this.user.address[0].serviceAddress.zipcode = upDateAddress.zipcode;
              this.zipcodeFromCartCheckout.zipcode = this.user.address[0].serviceAddress.zipcode;
            }
            this.checkExistingUser(form, response => {
              if (!!response && response.length === 0) {
                this.isClicked = false;
                if (!this.isExistingUser) {
                  this.onSubmitRegister(form, 'NonMatches');
                } else {
                  this.onSubmitRegister(form, 'EmailMatched');
                }
              } else if (response.length > 0) {
                this.isClicked = false;
                const currentCustomer = this.checkCustomerExists(response, form.controls.lastName.value, form.controls.email.value);
                if (currentCustomer) {
                  this.existingCustomerData = currentCustomer;
                  const existingCustomerDataCopy = {
                    ...this.existingCustomerData,
                  };
                  this.setExistingCustomerData(existingCustomerDataCopy);
                  if (!this.isExistingUser) {
                    this.onSubmitRegister(form, 'AddressMatched');
                  } else {
                    this.onSubmitRegister(form, 'AllMatched');
                  }
                } else {
                  if (this.addressPresentIn === 'customercrmsDB') {
                    this.lastNameMismatchFound = response[0].Customer_Number;
                  } else if (this.addressPresentIn === 'customersDB') {
                    this.lastNameMismatchFound = response[0].address.customerNo;
                  }
                  if (this.isExistingUser) {
                    this.onSubmitRegister(form, 'EmailMatched');
                  } else {
                    this.onSubmitRegister(form, 'NonMatches');
                  }
                }
              }
            });
          },
        );
      } else {
        this.states = ['null', 'undefined', undefined, null, ''].includes(this.states)
          ? this.sessionStorageService.getItem('statesList')
            ? JSON.parse(this.sessionStorageService.getItem('statesList'))
            : this.states
          : this.states;
        this.showAlertModal({
          title: 'Error',
          message: `Zipcode does not exist in selected state, Please select ${
            find(this.states, {
              key: this.stateCode,
            }).value
          } to proceed.`,
          alertIcon: 'fa fa-exclamation-circle red',
          cancelText: 'Cancel',
          confirmText: 'Ok',
        });
      }
    }
  }

  async onSubmitRegister(form: NgForm, regType) {
    this.ecommerceEventsForGA(this.productsByBillingType, 'beginCheckout');
    switch (regType) {
      case 'NonMatches': {
        this.userEmail = form.value.email;
        this.user.email = form.value.email;
        this.user.firstName = form.value.firstName.trim() || '';
        this.user.lastName = form.value.lastName.trim();
        this.user.phoneNumber = form.value.phoneNumber;
        this.user.phoneNumber2 = '';
        // HWR-5136 Checkbox Removal and Updated Disclaimer for Checkout sending defalt true
        this.user.isAuthorizedToSendMessage = 1;
        this.user.cellPhone1SmsOptInDate = new Date().toJSON().slice(0, 10);
        this.confirmEmailId = form.value.confirmEmail;
        this.user.address[0].serviceAddress.city = form.value.city;
        this.user.address[0].serviceAddress.streetNumber = form.value.streetNumber;
        this.user.address[0].serviceAddress.streetName = form.value.streetName || '';
        this.user.address[0].firstName = form.value.firstName.trim() || '';
        this.user.address[0].lastName = form.value.lastName.trim();
        $('html, body').animate(
          {
            scrollTop: 0,
          },
          1000,
        );
        this.sanitize(this.user);
        // To do : Change address model later
        // this.user.address[0].serviceAddress = this.user.address[0].serviceAddress;
        this.user.address[0].billingAddress = this.user.address[0].serviceAddress;

        if (this.isDefaultMkc) {
          const isCartUpdated = await this.updateCartBasedOnLocation();
          if (isCartUpdated) {
            return;
          }
        }
        // call customer register service here
        if (this.referer) {
          this.user.referer = this.referer;
        }
        const locationCodeId = this.cartProducts[0].locationId
          ? this.cartProducts[0].locationId
          : this.sessionStorageService.getItem('location_code');
        this.user.address[0].serviceAddress.locationCodeId = locationCodeId;
        this.user.address[0].billingAddress.locationCodeId = locationCodeId;
        const payload: any = this.user;
        payload.isFromCheckout = true;
        if (!payload.address[0].serviceAddress.streetName) {
          payload.address[0].serviceAddress.streetName = '';
        }
        if (this.referer) {
          payload.retainUrl = this.referer;
        }
        payload.utmParams = this.sessionStorageService.getItem('utmParams') || '';
        payload.address[0].providerAvailable = this.providerAvailable;
        payload.address[0].ownOrRentedValidated = 'true';
        payload.address[0].noWaterBillConversion = 'false';
        if (this.providerAvailable) {
          payload.address[0].providerSelected = this.providerSelected;
        }
        if (this.lastNameMismatchFound.length) {
          payload.address[0].lastNameMismatchFound = this.lastNameMismatchFound;
        }
        this.registerCustomer(payload);
        break;
      }
      case 'AddressMatched': {
        if (this.addressPresentIn === 'customercrmsDB') {
          this.showLoader = true;
          if (this.existingUser.address[0].customerNo) {
            // this.existingUser.email = this.existingUser.email ? this.existingUser.email : form.value.loginId;
            this.existingUser.email = this.isExistingCustReg ? form.value.loginId : form.value.email;
            this.userEmail = this.existingUser.email;
            //   this.existingUser.password = form.value.password;
            this.sanitize(this.existingUser);
            this.sanitize(this.user);
            if (this.referer) {
              this.existingUser.referer = this.referer;
            }
            this.existingUser.cellPhone1SmsOptInDate = new Date().toJSON().slice(0, 10);
          }
          const payload: any = this.existingUser.address[0] && this.existingUser.address[0].customerNo ? this.existingUser : this.user;
          delete payload.accountnumber;
          payload.isFromCheckout = true;
          // HWR-5136 Checkbox Removal and Updated Disclaimer for Checkout sending defalt true
          payload.isAuthorizedToSendMessage = 1;
          // if (payload.password === form.value.confirmPassword) {
          if (!payload.address[0].serviceAddress.streetName) {
            payload.address[0].serviceAddress.streetName = '';
          }
          if (this.referer) {
            payload.retainUrl = this.referer;
          }
          payload.utmParams = this.sessionStorageService.getItem('utmParams') || '';
          payload.address[0].providerAvailable = this.providerAvailable;
          if (this.providerAvailable) {
            payload.address[0].providerSelected = this.providerSelected;
          }
          await new Promise(resolve => {
            setTimeout(resolve, 2000);
          });
          payload.address[0].serviceAddress.locationCodeId = this.existingCustomerData.LocationCodeId;
          payload.address[0].billingAddress.locationCodeId = this.existingCustomerData.LocationCodeId;
          const existingLocId: any = this.existingUser.address[0].serviceAddress.locationCodeId;
          if (!['null', 'undefined', undefined, null, ''].includes(existingLocId) && existingLocId !== this.cartData.items[0].locationId) {
            if (this.cartData.items && this.cartData.items.length) {
              this.updateCartItemsAsPerAddress(this.existingUser.address[0], this.cartProducts)
                .then((cartItems: any) => {
                  this.showErrorModal(
                    'Important Information',
                    'We have updated the products in your cart based on your location',
                    ErrorModalComponent,
                    'program_change_location',
                  );
                  this.cartProducts = cartItems;
                  if (this.existingUserLocationCodeId) {
                    this.locationService.getLocationsContent(this.existingUserLocationCodeId).subscribe(
                      dataReceivedRes => {
                        const dataReceived = dataReceivedRes.body;
                        if (dataReceived && dataReceived.locationCode.includes('150')) {
                          payload.address[0].providerAvailable = true;
                          payload.address[0].providerSelected = true;
                        }
                      },
                      () => {
                        const GAErrorId = 'checkout_error_fetching_LocationContent';
                        const title = 'We are currently facing technical difficulties.';
                        const message =
                          '<br><p style="margin-top:-20px;margin-bottom:0;">Please try again after sometime. Sorry for any inconvenience.';
                        this.showLoader = false;
                        this.showErrorModal(title, message, ErrorModalComponent, GAErrorId);
                      },
                    );
                  } else {
                    this.registerCustomer(payload);
                  }
                })
                .catch(() => {
                  const GAErrorId = 'do_not_offer';
                  this.showLoader = false;
                  this.showCartErrorModal('We are sorry. We do not currently offer this service in your location.', '', '', GAErrorId);
                  this.cartQuickCheckoutService.empty();
                });
            } else if (this.existingUserLocationCodeId) {
              this.locationService.getLocationsContent(this.existingUserLocationCodeId).subscribe(
                dataReceivedRes => {
                  const dataReceived = dataReceivedRes.body;
                  if (dataReceived && dataReceived.locationCode.includes('150')) {
                    payload.address[0].providerAvailable = true;
                    payload.address[0].providerSelected = true;
                  }
                  this.registerCustomer(payload);
                },
                () => {
                  const GAErrorId = 'checkout_error_fetching_LocationContent';
                  const title = 'We are currently facing technical difficulties.';
                  const message = '<br><p style="margin-top:-20px;margin-bottom:0;">Please try again after sometime. Sorry for any inconvenience.';
                  this.showLoader = false;
                  this.showErrorModal(title, message, ErrorModalComponent, GAErrorId);
                },
              );
            } else {
              this.registerCustomer(payload);
            }
          } else if (this.existingUserLocationCodeId) {
            this.locationService.getLocationsContent(this.existingUserLocationCodeId).subscribe(
              dataReceivedRes => {
                const dataReceived = dataReceivedRes.body;
                if (dataReceived && dataReceived.locationCode.includes('150')) {
                  payload.address[0].providerAvailable = true;
                  payload.address[0].providerSelected = true;
                }
                this.registerCustomer(payload);
              },
              () => {
                const GAErrorId = 'checkout_error_fetching_LocationContent';
                const title = 'We are currently facing technical difficulties.';
                const message = '<br><p style="margin-top:-20px;margin-bottom:0;">Please try again after sometime. Sorry for any inconvenience.';
                this.showLoader = false;
                this.showErrorModal(title, message, ErrorModalComponent, GAErrorId);
              },
            );
          } else {
            this.registerCustomer(payload);
          }
        } else if (this.addressPresentIn === 'customersDB') {
          this.customerGuid = this.existingUser.address[0].customerGuid;
          this.customerDetails = {
            data: {
              customerGuid: this.existingUser.address[0].customerGuid,
              customerNo: this.existingUser.address[0].customerNo,
              customerId: this.existingUser.customerId,
            },
          };
          const existingLocId: any = this.existingUser.address[0].serviceAddress.locationCodeId;
          if (!['null', 'undefined', undefined, null, ''].includes(existingLocId) && existingLocId !== this.cartData.items[0].locationId) {
            if (this.cartData.items && this.cartData.items.length) {
              this.updateCartItemsAsPerAddress(this.existingUser.address[0], this.cartProducts)
                .then((cartItems: any) => {
                  this.showErrorModal(
                    'Important Information',
                    'We have updated the products in your cart based on your location',
                    ErrorModalComponent,
                    'program_change_location',
                  );
                  this.cartProducts = cartItems;
                })
                .catch(() => {
                  const GAErrorId = 'checkout_error_updateCartItemsPerAddress';
                  this.showLoader = false;
                  this.showCartErrorModal('We are sorry. We do not currently offer this service in your location.', '', '', GAErrorId);
                  this.cartQuickCheckoutService.empty();
                });
            }
          } else {
            this.passNewRegisterCustomerResponse = JSON.parse(JSON.stringify(this.existingUser.address[0]));
            this.passNewRegisterCustomerResponse.resSource = 'customersDB';
            this.passNewRegisterCustomerResponse.email = this.user.email;
            if (this.existingUser.address && 'isFromCheckout' in this.existingUser.address[0]) {
              this.passNewRegisterCustomerResponse.isFromCheckout = this.existingUser.address[0].isFromCheckout;
            }
            this.fetchToken(this.existingUser.email);
          }
        }
        break;
      }
      case 'EmailMatched': {
        this.user.address[0].billingAddress = this.user.address[0].serviceAddress;
        const locationCodeId = this.cartProducts[0].locationId
          ? this.cartProducts[0].locationId
          : this.sessionStorageService.getItem('location_code');
        this.user.address[0].serviceAddress.locationCodeId = locationCodeId;
        this.user.address[0].billingAddress.locationCodeId = locationCodeId;
        const payload: any = {
          serviceAddress: this.user.address[0].serviceAddress,
          billingAddress: this.user.address[0].serviceAddress,
          isBillingAddressSameAsServiceAddress: true,
          ownOrRentedValidated: 'true',
          noWaterBillConversion: 'false',
          // [HWR-2723 Feilds Added For New-Property Registration]
          firstName: this.user.firstName || '',
          lastName: this.user.lastName,
        };
        payload.isFromCheckout = true;
        payload.providerAvailable = this.providerAvailable;
        if (this.providerAvailable) {
          payload.providerSelected = this.providerSelected;
        }
        if (this.lastNameMismatchFound.length) {
          payload.lastNameMismatchFound = this.lastNameMismatchFound;
        }
        if (this.isDefaultMkc) {
          const isCartUpdated = await this.updateCartBasedOnLocation();
          if (isCartUpdated) {
            return;
          }
        }
        const customerid: any = this.EmailExistedData ? this.EmailExistedData.user._id : '';
        this.userService.addCustomerAddress(customerid, payload).subscribe(
          res => {
            if (res.status === 200) {
              this.address = res.body.data.address;
              this.customerGuid = res.body.data.customerGuid;
              this.customerDetails = res.body;
              this.passNewRegisterCustomerResponse = JSON.parse(JSON.stringify(payload));
              this.passNewRegisterCustomerResponse.resSource = 'address';
              this.passNewRegisterCustomerResponse.email = this.user.email;
              // let element: HTMLElement = document.getElementById('checkout_successful_register') as HTMLElement;
              // element.click();
              $('html, body').animate(
                {
                  scrollTop: 0,
                },
                1000,
              );
              this.fetchToken(this.user.email);
            }
            this.showLoader = false;
          },
          () => {
            const GAErrorId = 'checkout_error_addCustomerAddress';
            this.showLoader = false;
            $('html, body').animate(
              {
                scrollTop: 0,
              },
              'slow',
            );
            if (this.oldZipcode && this.oldZipcode !== this.user.address[0].serviceAddress.zipcode) {
              this.globalVariables.cartCheckout.items[0].zipcode = this.user.address[0].serviceAddress.zipcode;
              this.zipcodeComponent.zipcodeChangedFromSuggestion(this.user.address[0].serviceAddress.zipcode);
            }
            const errorTitle = "We're sorry, there was a problem attempting to create your account.";
            const strError: string = 'Please call the number at the top of page to speak to a Customer Service Representative.';
            this.showErrorModal(errorTitle, strError, ErrorModalComponent, GAErrorId);
          },
        );
        break;
      }
      case 'AllMatched': {
        if (this.addressPresentIn === 'customercrmsDB') {
          const customerid: any = this.EmailExistedData ? this.EmailExistedData.user._id : '';
          if (this.EmailExistedData && this.EmailExistedData.user.lastName.trim() === this.user.lastName.trim()) {
            if (this.existingUserLocationCodeId) {
              this.locationService.getLocationsContent(this.existingUserLocationCodeId).subscribe(
                async dataReceivedRes => {
                  const dataReceived = dataReceivedRes.body;
                  const payloadAllMatched: any = {
                    serviceAddress: this.existingUser.address[0].serviceAddress,
                    billingAddress: this.existingUser.address[0].serviceAddress,
                    isBillingAddressSameAsServiceAddress: true,
                    ownOrRentedValidated: this.existingUser.address[0].ownOrRentedValidated,
                    noWaterBillConversion: this.existingUser.address[0].noWaterBillConversion,
                    waterBillOnOff: this.existingUser.address[0].waterBillOnOff,
                    firstName: this.user.firstName || '',
                    lastName: this.user.lastName,
                  };
                  payloadAllMatched.isFromCheckout = true;
                  payloadAllMatched.providerAvailable = this.providerAvailable;
                  payloadAllMatched.customerNo = this.existingUser.address[0].customerNo;
                  payloadAllMatched.customerGuid = this.existingUser.address[0].customerGuid;
                  if (this.providerAvailable) {
                    payloadAllMatched.providerSelected = this.providerSelected;
                  }
                  if (dataReceived && dataReceived.locationCode.includes('150')) {
                    payloadAllMatched.providerAvailable = true;
                    payloadAllMatched.providerSelected = true;
                  }
                  await new Promise(resolve => {
                    setTimeout(resolve, 2000);
                  });
                  // payloadAllMatched.address[0].serviceAddress.locationCodeId = this.existingCustomerData.LocationCodeId;
                  // payloadAllMatched.address[0].billingAddress.locationCodeId = this.existingCustomerData.LocationCodeId;
                  const existingLocId: any = this.existingUser.address[0].serviceAddress.locationCodeId;
                  if (!['null', 'undefined', undefined, null, ''].includes(existingLocId) && existingLocId !== this.cartData.items[0].locationId) {
                    if (this.cartData.items && this.cartData.items.length) {
                      this.updateCartItemsAsPerAddress(this.existingUser.address[0], this.cartProducts)
                        .then((cartItems: any) => {
                          this.showErrorModal(
                            'Important Information',
                            'We have updated the products in your cart based on your location',
                            ErrorModalComponent,
                            'program_change_location',
                          );
                          this.cartProducts = cartItems;
                          this.userService.addCustomerAddress(customerid, payloadAllMatched).subscribe(
                            res => {
                              if (res.status === 200) {
                                this.customerGuid = res.body.data.customerGuid;
                                this.customerDetails = res.body;
                                this.passNewRegisterCustomerResponse = JSON.parse(JSON.stringify(payloadAllMatched));
                                this.passNewRegisterCustomerResponse.resSource = 'address';
                                this.passNewRegisterCustomerResponse.email = this.user.email;
                                // let element: HTMLElement = document.getElementById('checkout_successful_register') as HTMLElement;
                                // element.click();
                                return;
                              }
                              this.showLoader = false;
                            },
                            () => {
                              const GAErrorId = 'checkout_error_addCustomerAddress';
                              const errorTitle = "We're sorry, there was a problem attempting to create your account.";
                              const strError: string = 'Please call the number at the top of page to speak to a Customer Service Representative.';
                              this.showLoader = false;
                              if (this.oldZipcode && this.oldZipcode !== this.existingUser.address[0].serviceAddress.zipcode) {
                                this.globalVariables.cartCheckout.items[0].zipcode = this.existingUser.address[0].serviceAddress.zipcode;
                                this.zipcodeComponent.zipcodeChangedFromSuggestion(this.existingUser.address[0].serviceAddress.zipcode);
                              }
                              $('html, body').animate(
                                {
                                  scrollTop: 0,
                                },
                                'slow',
                              );
                              this.showErrorModal(errorTitle, strError, ErrorModalComponent, GAErrorId);
                            },
                          );
                        })
                        .catch(() => {
                          const GAErrorId = 'checkout_error_updateCartItemsPerAddress';
                          this.showLoader = false;
                          this.showCartErrorModal('We are sorry. We do not currently offer this service in your location.', '', '', GAErrorId);
                          this.cartQuickCheckoutService.empty();
                        });
                    }
                  } else {
                    this.userService.addCustomerAddress(customerid, payloadAllMatched).subscribe(
                      res => {
                        if (res.status === 200) {
                          this.customerGuid = res.body.data.customerGuid;
                          this.customerDetails = res.body;
                          this.passNewRegisterCustomerResponse = JSON.parse(JSON.stringify(payloadAllMatched));
                          this.passNewRegisterCustomerResponse.resSource = 'address';
                          this.passNewRegisterCustomerResponse.email = this.user.email;
                          // let element: HTMLElement = document.getElementById('checkout_successful_register') as HTMLElement;
                          // element.click();
                          this.fetchToken(this.user.email);
                        }
                        this.showLoader = false;
                      },
                      () => {
                        const GAErrorId = 'checkout_error_addCustomerAddress';
                        const errorTitle = "We're sorry, there was a problem attempting to create your account.";
                        const strError: string = 'Please call the number at the top of page to speak to a Customer Service Representative.';
                        this.showLoader = false;
                        if (this.oldZipcode && this.oldZipcode !== this.existingUser.address[0].serviceAddress.zipcode) {
                          this.globalVariables.cartCheckout.items[0].zipcode = this.existingUser.address[0].serviceAddress.zipcode;
                          this.zipcodeComponent.zipcodeChangedFromSuggestion(this.existingUser.address[0].serviceAddress.zipcode);
                        }
                        $('html, body').animate(
                          {
                            scrollTop: 0,
                          },
                          'slow',
                        );
                        this.showErrorModal(errorTitle, strError, ErrorModalComponent, GAErrorId);
                      },
                    );
                  }
                },
                () => {
                  const GAErrorId = 'checkout_error_fetching_LocationContent';
                  const title = 'We are currently facing technical difficulties.';
                  const message = '<br><p style="margin-top:-20px;margin-bottom:0;">Please try again after sometime. Sorry for any inconvenience.';
                  this.showLoader = false;
                  this.showErrorModal(title, message, ErrorModalComponent, GAErrorId);
                },
              );
            } else {
              const payloadAllMatched: any = {
                serviceAddress: this.existingUser.address[0].serviceAddress,
                billingAddress: this.existingUser.address[0].serviceAddress,
                isBillingAddressSameAsServiceAddress: true,
                ownOrRentedValidated: this.existingUser.address[0].ownOrRentedValidated,
                noWaterBillConversion: this.existingUser.address[0].noWaterBillConversion,
                waterBillOnOff: this.existingUser.address[0].waterBillOnOff,
                firstName: this.existingUser.firstName || '',
                lastName: this.existingUser.lastName,
              };
              payloadAllMatched.isFromCheckout = true;
              payloadAllMatched.providerAvailable = this.providerAvailable;
              payloadAllMatched.customerNo = this.existingUser.address[0].customerNo;
              payloadAllMatched.customerGuid = this.existingUser.address[0].customerGuid;
              if (this.providerAvailable) {
                payloadAllMatched.providerSelected = this.providerSelected;
              }
              await new Promise(resolve => {
                setTimeout(resolve, 2000);
              });
              const existingLocId: any = this.existingUser.address[0].serviceAddress.locationCodeId;
              if (!['null', 'undefined', undefined, null, ''].includes(existingLocId) && existingLocId !== this.cartData.items[0].locationId) {
                if (this.cartData.items && this.cartData.items.length) {
                  this.updateCartItemsAsPerAddress(this.existingUser.address[0], this.cartProducts)
                    .then((cartItems: any) => {
                      this.showErrorModal(
                        'Important Information',
                        'We have updated the products in your cart based on your location',
                        ErrorModalComponent,
                        'program_change_location',
                      );
                      this.cartProducts = cartItems;
                    })
                    .catch(() => {
                      const GAErrorId = 'checkout_error_updateCartItemsPerAddress';
                      this.showLoader = false;
                      this.showCartErrorModal('We are sorry. We do not currently offer this service in your location.', '', '', GAErrorId);
                      this.cartQuickCheckoutService.empty();
                    });
                }
              } else {
                this.userService.addCustomerAddress(customerid, payloadAllMatched).subscribe(
                  res => {
                    if (res.status === 200) {
                      this.customerGuid = res.body.data.customerGuid;
                      this.customerDetails = res.body;
                      this.passNewRegisterCustomerResponse = JSON.parse(JSON.stringify(payloadAllMatched));
                      this.passNewRegisterCustomerResponse.resSource = 'address';
                      this.passNewRegisterCustomerResponse.email = this.user.email;
                      // let element: HTMLElement = document.getElementById('checkout_successful_register') as HTMLElement;
                      // element.click();
                      this.fetchToken(this.user.email);
                    }
                    this.showLoader = false;
                  },
                  () => {
                    const GAErrorId = 'checkout_error_addCustomerAddress';
                    const errorTitle = "We're sorry, there was a problem attempting to create your account.";
                    const strError: string = 'Please call the number at the top of page to speak to a Customer Service Representative.';
                    this.showLoader = false;
                    if (this.oldZipcode && this.oldZipcode !== this.existingUser.address[0].serviceAddress.zipcode) {
                      this.globalVariables.cartCheckout.items[0].zipcode = this.existingUser.address[0].serviceAddress.zipcode;
                      this.zipcodeComponent.zipcodeChangedFromSuggestion(this.existingUser.address[0].serviceAddress.zipcode);
                    }
                    $('html, body').animate(
                      {
                        scrollTop: 0,
                      },
                      'slow',
                    );
                    this.showErrorModal(errorTitle, strError, ErrorModalComponent, GAErrorId);
                  },
                );
              }
            }
          } else {
            // show error modal
          }
        } else if (this.addressPresentIn === 'customersDB') {
          this.customerGuid = this.existingUser.address[0].customerGuid;
          this.customerDetails = {
            data: {
              customerGuid: this.existingUser.address[0].customerGuid,
              customerNo: this.existingUser.address[0].customerNo,
              customerId: this.existingUser.customerId,
            },
          };
          const existingLocId: any = this.existingUser.address[0].serviceAddress.locationCodeId;
          if (!['null', 'undefined', undefined, null, ''].includes(existingLocId) && existingLocId !== this.cartData.items[0].locationId) {
            if (this.cartData.items && this.cartData.items.length) {
              this.updateCartItemsAsPerAddress(this.existingUser.address[0], this.cartProducts)
                .then((cartItems: any) => {
                  this.showErrorModal(
                    'Important Information',
                    'We have updated the products in your cart based on your location',
                    ErrorModalComponent,
                    'program_change_location',
                  );
                  this.cartProducts = cartItems;
                })
                .catch(() => {
                  const GAErrorId = 'checkout_error_updateCartItemsPerAddress';
                  this.showLoader = false;
                  this.showCartErrorModal('We are sorry. We do not currently offer this service in your location.', '', '', GAErrorId);
                  this.cartQuickCheckoutService.empty();
                });
            }
          } else {
            this.passNewRegisterCustomerResponse = JSON.parse(JSON.stringify(this.existingUser.address[0]));
            this.passNewRegisterCustomerResponse.resSource = 'customersDB';
            this.passNewRegisterCustomerResponse.email = this.user.email;
            if (this.existingUser.address && 'isFromCheckout' in this.existingUser.address[0]) {
              this.passNewRegisterCustomerResponse.isFromCheckout = this.existingUser.address[0].isFromCheckout;
            }
            this.fetchToken(this.user.email);
          }
        }
        break;
      }
    }
  }

  registerCustomer(payload) {
    this.showLoader = true;
    this.userService.registerCustomer(payload).subscribe(
      data => {
        this.globalVariables.OOFSelected = {};
        this.showLoader = false;
        if (data.status === 200) {
          this.customerDetails = data.body;
          this.passNewRegisterCustomerResponse = JSON.parse(JSON.stringify(payload));
          this.passNewRegisterCustomerResponse.resSource = 'register';
          this.customerGuid = data.body.data.customerGuid;
          const element: HTMLElement = document.getElementById('checkout_successful_register') as HTMLElement;
          element.click();
          $('html, body').animate(
            {
              scrollTop: 0,
            },
            1000,
          );
          this.fetchToken(payload.email);
        }
      },
      error => {
        const GAErrorId = 'checkout_error_registerCustomer';
        this.isClicked = false;
        this.showLoader = false;
        this.registerError = error.error.message;
        let errorTitle = "We're sorry, there was a problem attempting to create your account.";
        let strError: string = 'Please call the number at the top of page to speak to a Customer Service Representative.';
        if (error.status === 409) {
          strError = 'User already exists';
          errorTitle = 'Error';
        }
        if (this.oldZipcode && this.oldZipcode !== payload.address[0].serviceAddress.zipcode) {
          this.globalVariables.cartCheckout.items[0].zipcode = payload.address[0].serviceAddress.zipcode;
          this.zipcodeComponent.zipcodeChangedFromSuggestion(payload.address[0].serviceAddress.zipcode);
        }
        this.showErrorModal(errorTitle, strError, ErrorModalComponent, GAErrorId);
      },
    );
  }

  formKeyPressHandler(event: any) {
    const keyCode = event.keyCode || event.which;
    if (keyCode === 13) {
      event.preventDefault();
      return false;
    }
  }

  sanitize(obj: Object) {
    Object.keys(obj).forEach(prop => {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        if (typeof obj[prop] === 'object') {
          this.sanitize(obj[prop]);
        } else if (!obj[prop] && obj[prop] !== 0) {
          delete obj[prop];
        }
      }
    });
  }

  showErrorModal(title: string, message: string, modalComponent: any, GAErrorId?: string, isZipChanged?: boolean) {
    this.simpleModalService
      .addModal(
        modalComponent,
        {
          title,
          message,
          confirmBtnId: GAErrorId || 'close',
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: false,
        },
      )
      .subscribe(isConfirmed => {
        if (isConfirmed === undefined) {
          /** Save Checkout User Form Data */
          this.user.address[0].serviceAddress.zipcode =
            this.zipcodeFromCartCheckout !== ''
              ? this.zipcodeFromCartCheckout.zipcode
              : JSON.parse(this.sessionStorageService.getItem('selectedAddress')).zipcode;
          this.sessionStorageService.setItem('user_data', JSON.stringify(this.user));
          if (isZipChanged) {
            const { locationCodeId } = this.user.address[0].serviceAddress;
            this.sessionStorageService.setItem('location_code', locationCodeId);
            this.zipcodeComponent.zipcodeChangedFromSuggestion(this.user.address[0].serviceAddress.zipcode);
          }
        }
      });
  }

  emailBlurHandler(input: any) {
    this.isDisposableEmail = false;
    input.isBlur = true;
    this.isExistingUser = false;
    if (input.valid) {
      if (environment.disposable_email.includes(input.value.split('@')[1].toLowerCase())) {
        input.isBlur = false;
        this.isDisposableEmail = true;
        this.registerForm.controls.email.setErrors({
          incorrect: true,
        });
      } else {
        this.userService.verifyUser(input.value).subscribe(
          data => {
            if (data.status === 200) {
              this.isExistingUser = true;
              this.EmailExistedData = data.body;
              input.isBlur = false;
            }
          },
          () => {
            this.isExistingUser = false;
          },
        );
      }
    }
    if (typeof FullStory === 'function') {
      FullStory.identify(`Checkout ${input.value}`, {
        displayName: `Checkout ${input.value}`,
        name: `${this.user.firstName} ${this.user.lastName}`,
        email: input.value,
      });
    }
  }

  phoneBlurHandler(input: any) {
    input.isBlur = true;
    const pattern = /^\d{10}$/;
    if (input.value && !pattern.test(input.value)) {
      if (input.name === 'phoneNumber') {
        this.homePhoneErrorMsg = 'Please enter 10 digit number';
      }
      return false;
    }
    if (input.name === 'phoneNumber') {
      this.homePhoneErrorMsg = '';
    }

    return true;
  }

  blurHandler(input: any) {
    input.isBlur = true;
    this.phoneNumberExistingErrorMsg = '';
    const pattern = /^\d{10}$/;
    if (input.value && !pattern.test(input.value)) {
      this.phoneNumberExistingErrorMsg = 'Please enter 10 digit number';
      return false;
    }
    return true;
  }

  keyPressHandler(input: any, type?: any) {
    input.isBlur = false;
    if (type === 'phoneNumber') {
      this.user.phoneNumber = input.control.value;
    }
  }

  emailKeyPressHandler(input: any) {
    input.isBlur = false;
    this.isExistingUser = false;
    this.user.email = input.control.value;
  }

  scrollToSection(sectionId: string) {
    document.querySelector(`#${sectionId}`).scrollIntoView({
      behavior: 'smooth',
    });
  }

  setExistingCustomerData(existingCustomer: any) {
    if (this.addressPresentIn === 'customercrmsDB') {
      this.existingUser = this.formatSyncData(existingCustomer);
    } else {
      this.existingUser = existingCustomer;
      this.existingUser.address = [JSON.parse(JSON.stringify(existingCustomer.address))];
    }

    const zipcodes = [];
    if (this.existingUser.address[0].serviceAddress.zipcode === this.existingUser.address[0].billingAddress.zipcode) {
      zipcodes.push(this.zipcodeService.getZipcodeId(this.existingUser.address[0].serviceAddress.zipcode));
    } else {
      zipcodes.push(this.zipcodeService.getZipcodeId(this.existingUser.address[0].serviceAddress.zipcode));
      zipcodes.push(this.zipcodeService.getZipcodeId(this.existingUser.address[0].billingAddress.zipcode));
    }
    this.getZipcodeData(zipcodes, zipcodedata => {
      // zipcodedata = zipcodedata['body'];
      if (!!this.existingUser.address[0].waterBillOnOff && !!this.existingUser.address[0].noWaterBillConversion) {
        const oofLocationCode = `${this.existingUser.address[0].serviceAddress.state}150`;
        this.productService.getProviderContent(zipcodedata[0].locationCodeId).subscribe(dataForLocation => {
          dataForLocation = dataForLocation.body;
          if (oofLocationCode !== dataForLocation.locationCode) {
            this.productService.getLocationCode(oofLocationCode).subscribe(dataForOofLocationCode => {
              dataForOofLocationCode = dataForOofLocationCode.body;
              this.locationCodeData = dataForOofLocationCode[0];
              zipcodedata[0].locationCodeId = dataForOofLocationCode[0].locationCodeId;
            });
          } else {
            this.productService.getProviderContent(zipcodedata[0].locationCodeId).subscribe(dataForLoc => {
              this.locationCodeData = dataForLoc.body;
            });
          }
        });
      } else {
        this.productService.getProviderContent(zipcodedata[0].locationCodeId).subscribe(dataForLocation => {
          this.locationCodeData = dataForLocation.body;
        });
      }

      this.existingUser.address[0].serviceAddress.zipcodeId = zipcodedata[0].zipCodeId;
      this.existingUser.address[0].serviceAddress.locationCodeId = zipcodedata[0].locationCodeId;
      if (zipcodedata.length === 1) {
        this.existingUser.address[0].billingAddress.zipcodeId = zipcodedata[0].zipCodeId;
        this.existingUser.address[0].billingAddress.locationCodeId = zipcodedata[0].locationCodeId;
      } else if (zipcodedata.length === 2) {
        this.existingUser.address[0].billingAddress.zipcodeId = zipcodedata[1].zipCodeId;
        this.existingUser.address[0].billingAddress.locationCodeId = zipcodedata[1].locationCodeId;
      }
      $('html, body').animate(
        {
          scrollTop: 0,
        },
        1000,
      );
    });
    /* HWR-2485 End */
  }

  getZipcodeData(zipcodes, done: any) {
    forkJoin(zipcodes).subscribe(
      zipcodesData => {
        const zipcodeData: any = [];
        (zipcodesData as any).forEach(ele => {
          zipcodeData.push(ele.body);
        });
        done(zipcodeData);
      },
      () => {
        const errorTitle = "We're sorry, there was a problem attempting to create your account.";
        const strError = `Please contact Customer Service at <span class="app-txt">
        ${this.customer_service_number}</span>`;
        this.showLoader = false;
        this.showErrorModal(errorTitle, strError, ErrorModalComponent);
      },
    );
  }

  setContactType(type) {
    this.user.address[0].serviceAddress.contactType = type;
    this.isClicked = false;
  }

  checkExistingUser(form, done) {
    this.showLoader = true;
    this.lastNameMismatchFound = '';
    const stateValue = this.user.address[0].serviceAddress.state;
    this.states = ['null', 'undefined', undefined, null, ''].includes(this.states)
      ? this.sessionStorageService.getItem('statesList')
        ? JSON.parse(this.sessionStorageService.getItem('statesList'))
        : this.states
      : this.states;
    const state = find(this.states, o => {
      if (o.value === stateValue) {
        return o;
      }
    });
    const params = `street=${encodeURIComponent(this.user.address[0].serviceAddress.streetName.trim() || '')}
&street_number=${encodeURIComponent(this.user.address[0].serviceAddress.streetNumber.trim())}
&city=${encodeURIComponent(this.user.address[0].serviceAddress.city.trim())}
&state=${encodeURIComponent(state.value)}&zip_code=${encodeURIComponent(this.user.address[0].serviceAddress.zipcode.trim())}`;

    this.userService.searchCustomerByAddressFromCustomers(params).subscribe(
      res => {
        this.addressPresentIn = 'customersDB';
        done(res.body.responseDesc);
        this.showLoader = false;
      },
      err => {
        let errorName = '';
        const apiError = err;
        errorName = apiError.error.error.name;
        if (errorName === 'AddressNotFoundError' || errorName === 'InvalidAddressError') {
          const params1 = `street=${encodeURIComponent(this.user.address[0].serviceAddress.streetName.trim() || '')}
            &street_number=${encodeURIComponent(this.user.address[0].serviceAddress.streetNumber.trim())}
            &city=${encodeURIComponent(this.user.address[0].serviceAddress.city.trim())}
            &state=${encodeURIComponent(state.value)}&zip_code=${encodeURIComponent(this.user.address[0].serviceAddress.zipcode.trim())}
            &first_name=${encodeURIComponent(this.user.firstName.trim())}&last_name=${encodeURIComponent(this.user.lastName.trim())}
            &phone=${encodeURIComponent(this.user.phoneNumber.trim())}`;
          this.userService.searchCustomerByAddress(params1).subscribe(
            res => {
              this.addressPresentIn = 'customercrmsDB';
              done(res.body);
              this.showLoader = false;
            },
            () => {
              const GAErrorId = 'checkout_error_checkExistingUser';
              let errorMassgae = '';
              let errorN = '';
              const apiErr = err;
              errorN = apiErr.error.error.name;
              if (!['null', 'undefined', undefined, null, ''].includes(errorN) && errorN === 'MultipleCustomersFoundError') {
                errorMassgae = `We're sorry, but we are having difficulty creating your account as our records indicate there are multiple
                       accounts in your name. Please contact Customer Service at ${this.customer_service_number} to complete your registration.`;
                this.showAlertModal({
                  title: "We're sorry, we are having difficulty verifying your account information.",
                  message: errorMassgae,
                  alertIcon: 'fa fa-exclamation-circle red',
                  cancelText: ' ',
                  confirmText: 'Ok',
                  confirmBtnId: GAErrorId || 'close',
                });
              } else {
                done([]);
              }
            },
          );
        } else {
          done([]);
        }
      },
    );
  }

  showAlertModal(data?: any, done?) {
    this.showLoader = false;
    this.simpleModalService
      .addModal(
        AlertConfirmModalComponent,
        {
          title: data.title,
          message: data.message,
          alertIcon: data.alertIcon,
          navigate: data.navigate,
          cancelText: data.cancelText,
          confirmText: data.confirmText,
          confirmBtnId: data.confirmBtnId ? data.confirmBtnId : 'close',
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: false,
        },
      )
      .subscribe(isConfirmed => {
        if (isConfirmed) {
          if (done) {
            done(true);
          }
        } else if (isConfirmed === false) {
          if (done) {
            done(false);
          }
        } else if (isConfirmed === undefined) {
          /** Save Checkout User Form Data */
          this.user.address[0].serviceAddress.zipcode =
            this.zipcodeFromCartCheckout !== ''
              ? this.zipcodeFromCartCheckout.zipcode
              : JSON.parse(this.sessionStorageService.getItem('selectedAddress')).zipcode;
          this.sessionStorageService.setItem('user_data', JSON.stringify(this.user));
          if (done) {
            done(false);
          }
        }
        if (data.navigate) {
          this.router.navigate(['']);
        }
        if (isConfirmed === 'confirmed' && data.confirmText === 'YES, CANCEL REGISTRATION') {
          this.router.navigate(['']);
        }
      });
  }

  showErrorRegistering(GAErrorId?: any) {
    this.showAlertModal({
      title: "We're sorry, there was a problem attempting to create your account.",
      message: 'Please call the number at the top of page to speak to a Customer Service Representative.',
      alertIcon: 'fa fa-exclamation-circle red',
      cancelText: ' ',
      confirmText: 'Ok',
      confirmBtnId: GAErrorId || 'close',
    });
  }

  getSmartAddress(strNo, strtName, state, city, zipcode, done: any) {
    this.crmProxyService.getSmartAddress(strNo, strtName, zipcode, city, state).subscribe(
      res => {
        const data = res.body;
        if (data.length && data[0].components) {
          const addressLine1 = data[0]?.delivery_line_1;
          const addressLine2 = data[0]?.delivery_line_2 ? `${data[0].delivery_line_2}, ` : '';
          const smartAddress: string = `${addressLine1},${addressLine2}${data[0]?.components?.city_name},`;
          const userAddress: string = `${strNo.trim()},${strtName ? `${strtName.trim()}, ` : ''}${city.trim()},`;
          if (
            !(
              smartAddress === userAddress &&
              zipcode === data[0].components.zipcode &&
              city.trim() === data[0].components.city_name &&
              state === data[0].components.state_abbreviation
            )
          ) {
            this.simpleModalService
              .addModal(
                AddressValidationModalComponent,
                {
                  inputAddress: {
                    streetNumber: strNo.trim(),
                    streetName: strtName.trim(),
                    city: city.trim(),
                    state: state.trim(),
                    zipcode: zipcode.trim(),
                  },
                  suggestedAddress: data[0],
                },
                {
                  wrapperClass: 'modal-main-class in',
                  closeOnClickOutside: false,
                },
              )
              .subscribe(isConfirmed => {
                done(isConfirmed);
              });
          } else {
            done();
          }
        } else {
          const GAErrorId = 'ga_track_customer_address_validation_fail';
          const alertData = {
            title: '',
            message: `Please check your address, our system indicates that this is not a valid service address.<br></br>
          If you are continuing to experience issues, please call  1-855-800-5195 to speak to a customer service representative.`,
            alertIcon: 'fa fa-info-circle red',
            cancelText: ' ',
            confirmText: 'Continue',
            confirmBtnId: GAErrorId || 'close',
          };
          this.showAlertModal(alertData);
        }
      },
      () => {
        done(undefined);
      },
    );
  }

  getSuggestion(event: any) {
    this.crmProxyService.getAddressSuggestion(event.target.value).subscribe(res => {
      this.suggestedAddress = res.body;
      this.showDropDown = true;
    });
  }

  closeDropDown() {
    this.showDropDown = !this.showDropDown;
  }

  openDropDown() {
    this.showDropDown = false;
  }

  selectValue(address1, city, form) {
    if (form === 1) {
      this.user.address[0].serviceAddress.streetNumber = address1;
      this.user.address[0].serviceAddress.city = city;
    } else {
      this.addressLookup.streetNumber = address1;
      this.addressLookup.city = city;
    }
    // this.stateForm.patchValue({"search": value});
    this.showDropDown = false;
  }

  updateDataForLocation(dataForLocation) {
    this.showLoader = false;
    this.locationCodeData = dataForLocation;
    this.abandonedLocationCode = this.locationCodeData.locationCode;
    this.sessionStorageService.setItem('websitePartnerName', dataForLocation.websitePartnerName);
    this.partnerName = this.sessionStorageService.getItem('websitePartnerName');
    this.providerAvailable = false;
    /* HWR-1445 Don't show provider prompt if entered zipcode matches with zipcode stored from provider check and providerSelected is true */
    const providerSelectionDetails = JSON.parse(this.sessionStorageService.getItem('providerSelectionDetails')) || {};
    if (dataForLocation.websitePartnerName !== '' && !providerSelectionDetails.providerSelected) {
      this.openModalPopup(this.user.address[0].serviceAddress.zipcode);
      return;
    }
    if (dataForLocation.websitePartnerName === '') {
      this.user.address[0].serviceAddress.locationCodeId = this.locationCodeData.locationCodeId;
      this.sessionStorageService.setItem('location_code', this.locationCodeData.locationCodeId);
      this.productService.setCategories();
      this.locationCode = this.locationCodeData.locationCodeId;
      this.sessionStorageService.setItem('providerSelectionDetails', '{}');
      this.setLocationForLocalCart(this.locationCodeData.locationCodeId);
      return;
    }
    if (providerSelectionDetails && providerSelectionDetails.zipCode !== this.user.address[0].serviceAddress.zipcode) {
      this.sessionStorageService.setItem('providerSelectionDetails', '{}');
    }

    if (providerSelectionDetails && providerSelectionDetails.providerSelected && providerSelectionDetails.providerValue === 'no') {
      this.partnerName = '';
      this.sessionStorageService.setItem('websitePartnerName', '');
      this.providerSelected = false;
      this.globalVariables.OOFSelected.isOOFLocationRegister = 'true';
      this.notMyProvider();
    } else {
      this.user.address[0].serviceAddress.locationCodeId = this.locationCodeData.locationCodeId;
      this.sessionStorageService.setItem('location_code', this.locationCodeData.locationCodeId);
      this.productService.setCategories();
      this.locationCode = this.locationCodeData.locationCodeId;
      this.setLocationForLocalCart(this.locationCodeData.locationCodeId);
    }
  }

  showCCPAUpdates() {
    const providerState = this.sessionStorageService.getItem('providerState');
    if (environment.ccpa_updates_provider.includes(providerState)) {
      this.ccpaUpdatesService.getCCPAUpdates();
    }
  }

  /* Restrict Space Character AP March 9 2020 */
  AvoidSpace(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode === 32) {
      return false;
    }
    return true;
  }

  abandonedRegistrationInsert($event = null) {
    /**
     * HWR-2142[VJ]:Insert and Update AbandonedRegistration Collection
     * HWR-3016[KP][02-Feb-2021]:Added steps number and fix related locationCode error, existing user fix and code optimzation.
     * HWR-3024[KP][23-Feb-2021]:Added streetName,streetNumber.
     */
    let userDetail;
    if (this.existingUser.accountnumber) {
      userDetail = this.existingUser;
    } else {
      userDetail = this.user;
    }
    if ($event) {
      if ($event.target.name.toLowerCase() === 'email' && $event.target.value === this.oldUserData.email) {
        return;
      }
      if ($event.target.name.toLowerCase() === 'zipcode' && $event.target.value === this.oldUserData.zipcode) {
        return;
      }
    }

    if (userDetail.email && !this.isExistingUser && this.locationCodeData) {
      if (!this.abandonedLocationCode) {
        this.abandonedLocationCode = this.locationCodeData.locationCode;
      }
      const abandonedRegistration = {
        firstName: userDetail.firstName,
        lastName: userDetail.lastName,
        middleName: userDetail.middleName || '',
        email: userDetail.email.toLowerCase() || '',
        phoneNumber: userDetail.phoneNumber,
        city: userDetail.address[0].serviceAddress.city,
        zipcode: userDetail.address[0].serviceAddress.zipcode,
        state: userDetail.address[0].serviceAddress.state,
        locationCodeId: userDetail.address[0].serviceAddress.locationCodeId,
        locationCode: this.abandonedLocationCode,
        contactType: userDetail.address[0].serviceAddress.contactType || 'RESIDENTIAL',
        // "step": this.getCurrentStep(),
        streetName: userDetail.address[0].serviceAddress.streetName,
        streetNumber: userDetail.address[0].serviceAddress.streetNumber,
        // "mobilePhoneNumber": userDetail.mobilePhoneNumber
      };
      this.oldUserData.email = abandonedRegistration.email;
      this.oldUserData.zipcode = abandonedRegistration.zipcode;
      if (!this.temporaryUserId) {
        /*  this.userService.abandonedRegistrationInsert(abandonedRegistration)
            .subscribe((data) => {
                this.resultRegiData = data['body'];
                this.temporaryUserId = this.resultRegiData._id;
        }); */
      } else {
        /* this.userService.abandonedRegistrationFindAndUpdate(this.temporaryUserId, abandonedRegistration)
            .subscribe((data) => {
                this.resultRegiData = data['body'];
        }); */
      }
    }
    if (this.isExistingUser) {
      // this.deleteAbandonedRegCustomer();
    }
  }

  deleteAbandonedRegCustomer() {
    if (this.temporaryUserId) {
      this.userService.abandonedregistrationcustomerdelete(this.temporaryUserId).subscribe(() => {
        this.temporaryUserId = '';
      });
    }
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  checkLocationChangedForReferer(data) {
    if (this.referer) {
      /* Logic to change referer if location code is changed */
      let urlOperation: any = this.referer.split('/');
      const micrositeNames = this.sessionStorageService.getItem('micrositeName')
        ? JSON.parse(this.sessionStorageService.getItem('micrositeName'))
        : environment.micrositeNames;
      // urlOperation = urlOperation.filter(item => !environment.micrositeNames.includes(item) && item !== "");
      urlOperation = urlOperation.filter(item => !micrositeNames.includes(item) && item !== '');
      if (urlOperation.length > 0 && ['products', 'product'].includes(urlOperation[0])) {
        let changed: boolean = false;
        if (data && data.toLowerCase() !== urlOperation[2]) {
          changed = true;
          urlOperation[2] = data.toLowerCase();
          this.referer = urlOperation.join('/');
        }
        let userDetail;
        if (this.existingUser.accountnumber) {
          userDetail = this.existingUser;
        } else {
          userDetail = this.user;
        }
        if (userDetail.address[0].serviceAddress && userDetail.address[0].serviceAddress.zipcode !== urlOperation[1]) {
          changed = true;
          urlOperation[1] = userDetail.address[0].serviceAddress.zipcode;
          this.referer = urlOperation.join('/');
        }
        if (changed) {
          const redirectUrlOp: any = this.referer;
          const urlTree = this.router.createUrlTree([], {
            queryParams: {
              referer: decodeURIComponent(redirectUrlOp),
            },
            queryParamsHandling: 'merge',
            preserveFragment: true,
          });
          this.router.navigateByUrl(urlTree);
        }
      }
    }
  }

  checkboxFunction() {
    this.isAuthorizedToViewForm = !this.isAuthorizedToViewForm;
    if (!this.isAuthorizedToViewForm) {
      this.isClicked = false;
    }
  }

  toggleMobileCart() {
    this.toggleMobileCartVal = !this.toggleMobileCartVal;
  }

  formatSyncData(existingCustomer) {
    const existingUser: any = {};
    // hot fix optincellphone date
    existingUser.cellPhone1SmsOptInDate = new Date().toJSON().slice(0, 10);
    existingUser.accountnumber = existingCustomer.Customer_Number;
    existingUser.oracleAccountNumber = existingUser.oracleAccountNumber || '';

    if (existingCustomer.telephone) {
      existingUser.phoneNumber = existingCustomer.telephone.phoneNumber1;
      existingUser.phoneNumber2 = existingCustomer.telephone.phoneNumber2 || '';
    }

    if (existingCustomer.Email) {
      existingUser.email = existingCustomer.Email;
    } else if (existingCustomer.Email2) {
      existingUser.email = existingCustomer.Email2;
    } else if (existingCustomer.Email3) {
      existingUser.email = existingCustomer.Email3;
    }

    existingUser.firstName = existingCustomer.First_Name || '';
    existingUser.lastName = existingCustomer.Last_Name || '';
    existingUser.middleName = existingCustomer.Middle_Name || '';

    const contactType = existingCustomer.Contact_Type ? existingCustomer.Contact_Type : 1;
    let setContactType: any = '';
    if (contactType === 3 || contactType === 7) {
      setContactType = 'COMMERCIAL';
    } else {
      setContactType = 'RESIDENTIAL';
    }
    this.states = ['null', 'undefined', undefined, null, ''].includes(this.states)
      ? this.sessionStorageService.getItem('statesList')
        ? JSON.parse(this.sessionStorageService.getItem('statesList'))
        : this.states
      : this.states;
    const serviceStateVal = find(this.states, {
      key: existingCustomer.Service_address.Service_State.toString(),
    });
    const billingStateVal = find(this.states, {
      key: existingCustomer.Billing_Address.Billing_State.toString(),
    });
    this.existingUserLocationCodeId = existingCustomer.LocationCodeId;
    existingUser.address = [
      {
        customerNo: existingCustomer.Customer_Number,
        customerGuid: existingCustomer.Customer,
        waterBillOnOff: existingCustomer.WaterBillOnOff || true,

        ownOrRentedValidated: existingCustomer.OwnOrRentValidated ? existingCustomer.OwnOrRentValidated.toString() : 'false',
        noWaterBillConversion: existingCustomer.NoWaterBillConversion ? existingCustomer.NoWaterBillConversion.toString() : 'false',
        // [HWR-2723 Feilds add for insert FirstName/LastName for existing customer registration]
        firstName: existingCustomer.First_Name || '',
        lastName: existingCustomer.Last_Name,

        serviceAddress: {
          streetName: existingCustomer.Service_address.Service_street2 || '',
          streetNumber: existingCustomer.Service_address.Service_Street_Number.toString(),
          city: existingCustomer.Service_address.Service_City,
          state: serviceStateVal.value,
          zipcode: existingCustomer.Service_address.Service_zipcode,
          contactType: setContactType,
        },

        billingAddress: {
          streetName: existingCustomer.Billing_Address.Billing_street2 || '',
          streetNumber: existingCustomer.Billing_Address.Billing_Street_Number.toString(),
          city: existingCustomer.Billing_Address.Billing_City || '',
          state: (billingStateVal ? billingStateVal.value : '') || '',
          zipcode: existingCustomer.Billing_Address.Billing_zipcode || '',
          contactType: setContactType || '',
        },
      },
    ];
    return existingUser;
  }

  onBillFrequencyChange(frequency: string) {
    this.globalVariables.billingFrequencyCheckout = frequency;
    if (this.customerGuid) {
      this.setPaymentFrequency();
    } else {
      this.calculateBybillingType(this.productsByBillingType);
    }
  }

  fetchToken(email: any) {
    this.showLoader = true;
    this.userService.getGuestToken(email).subscribe(
      async res => {
        if (res.status === 200) {
          this.guestToken = res.body.authHeader;
          this.sessionStorageService.setItem('guestToken', this.guestToken);
          this.guestToken_id = res.body.user._id;
          this.addToCart(res.body.user._id);
        }
      },
      () => {
        this.showLoader = false;
      },
    );
  }

  deleteCart(customerId: string) {
    this.cartQuickCheckoutService.deleteCustomerCart(customerId).subscribe(() => {
      this.addToCart(customerId);
    });
  }

  addToCart(customerId: string) {
    this.showLoader = true;
    this.cartQuickCheckoutService.getCustomerCart(customerId, this.guestToken).subscribe(
      data => {
        if (data.status === 200) {
          this.deleteCart(customerId);
        }
      },
      error => {
        if (error.status === 404) {
          const cartObj = {
            customerId,
          };
          this.createCart(cartObj);
        }
      },
    );
  }

  createCart(cartObj, updatedPayload?: any) {
    this.showLoader = true;
    this.cartQuickCheckoutService.createCart(cartObj, this.guestToken).subscribe(data => {
      if (data.status === 200) {
        const resData = data.body;
        let payload;
        if (updatedPayload) {
          payload = updatedPayload;
        } else {
          payload = this.cartProducts.map(p => ({
            ...p,
            isUpgrade: false,
            customerNo:
              this.existingUser && this.addressPresentIn && !this.lastNameMismatchFound.length
                ? this.existingUser.address[0].customerNo
                : this.customerDetails.data.customerNo,
          }));
        }
        this.cartQuickCheckoutService.addMultiItemToCartWithToken(
          resData._id,
          payload,
          this.guestToken,
          (resultsSucess, resultsBundledCustService, resultsBundled, duplicateMatch) => {
            if (duplicateMatch.length > 0) {
              this.sessionStorageService.deleteItem('cartCheckout');
              this.showPaymentPage = false;
              if (duplicateMatch[0].error.data) {
                const enrollments = duplicateMatch[0].error.data.existingEnrollments;
                const newCartItem = duplicateMatch[0].error.data.newProgramInCart;
                const bundledData = duplicateMatch[0].error.data.bundleData;

                if (enrollments.length === 0 && bundledData.length === 0) {
                  this.showCartErrorModal(
                    "We're Sorry",
                    `You are currently protected with a similar product.
                    If you wish to upgrade or change your protection coverage, please contact Customer Service at ${this.customer_service_number}.`,
                    'Ok',
                  );
                } else {
                  this.showUpgradePopupModal(UpgradeServiceModalComponent, enrollments, newCartItem, payload, bundledData, result => {
                    if (result) {
                      const updatedCartObj = {
                        customerId: this.guestToken_id,
                      };
                      const updatedPayloadVal = result.payload;
                      this.createCart(updatedCartObj, updatedPayloadVal);
                    } else {
                      /** Save Checkout User Form Data and Resetting the cart */
                      this.user.address[0].serviceAddress.zipcode =
                        this.zipcodeFromCartCheckout !== ''
                          ? this.zipcodeFromCartCheckout.zipcode
                          : JSON.parse(this.sessionStorageService.getItem('selectedAddress')).zipcode;
                      this.sessionStorageService.setItem('user_data', JSON.stringify(this.user));
                      const checkoutCartData = this.globalVariables.cartCheckout;
                      this.sessionStorageService.setItem('cartCheckout', JSON.stringify(checkoutCartData));
                    }
                  });
                }
              } else {
                this.showCartErrorModal(
                  "We're Sorry",
                  `You are currently protected with a similar product.
                  If you wish to upgrade or change your protection coverage, please contact Customer Service at ${this.customer_service_number}.`,
                  'Ok',
                );
              }
            } else if (resultsBundledCustService.length > 0) {
              this.sessionStorageService.deleteItem('cartCheckout');
              this.showPaymentPage = false;
              this.showCartErrorModal(
                'Upgrade to Bundle',
                `If you are looking to bundle your existing services,
                please visit your My Services page and view the list of opportunities from your current services.`,
                'Ok',
              );
            } else if (resultsBundled.length > 0) {
              resultsBundled.forEach(bundle => {
                this.openBundle(bundle.body);
              });
              this.customerGuid = undefined;
              const checkoutCartData = JSON.parse(this.sessionStorageService.getItem('cartCheckout'));
              this.globalVariables.cartCheckout = checkoutCartData;
              this.cartProducts = checkoutCartData.items;
              this.cartData = checkoutCartData;
              this.globalVariables.cartAmount.emit(checkoutCartData);
              this.initializeProductStructure();
              this.setProductsByBillingType().then(() => {
                this.calculateBybillingType(this.productsByBillingType);
                this.globalVariables.cartSwap.emit(checkoutCartData);
              });
              this.showLoader = false;
            } else {
              this.showLoader = false;
              if (this.productsByBillingType.offbill.products.length > 0 && this.productsByBillingType.onbill.products.length >= 0) {
                this.showPaymentPage = true;
                const element: HTMLElement = document.getElementById('ets-payment-gateway-checkout') as HTMLElement;
                setTimeout(() => element.click(), 2500);
              }
              this.setProductPriceDetails(this.guestToken_id, this.guestToken);
            }
          },
        );
      }
    });
  }

  setProductPriceDetails(data: any, token) {
    this.showLoader = true;
    this.updateBillingAndTaxInfo(data, token, () => {
      this.showLoader = false;
    });
  }

  /**
   * Get billing type and tax information.
   * @param done
   */
  updateBillingAndTaxInfo(dataId: any, token: any, done?: any) {
    const billingTypeCopy = JSON.parse(JSON.stringify(this.productsByBillingType)); // new variable to store object before reintating productsByBillingType
    if (!!dataId && !!token && this.cartProducts.length > 0) {
      //   $('#hp').empty();
      /* Get product on and off bill type & Tax amount */
      const locationCodeId = this.cartProducts[0].locationId;
      const cartProductDuplicate: any = JSON.parse(JSON.stringify(this.cartProducts));
      const programIds = map(cartProductDuplicate, 'details.program.programId').join(',');
      const marketingcodeids = map(cartProductDuplicate, 'details.marketingCodeId').join(',');
      const customerid = this.customerGuid;
      const paymentmethod = 'Check';
      const frequency = this.globalVariables.billingFrequencyCheckout === 'Monthly' ? 'Monthly' : 'Annually';
      let locationcode = '';
      this.locationService.getLocationsContent(this.cartProducts[0].locationId).subscribe(
        data => {
          locationcode = data.body.locationCode;
          if (programIds) {
            const getProductBillingType = this.crmProxyService.getProductBillTypes(locationCodeId, programIds, marketingcodeids);
            const getProducttaxAmount = this.crmProxyService.getProductsTaxCheckout(
              token,
              customerid,
              marketingcodeids,
              programIds,
              locationcode,
              paymentmethod,
              frequency,
            );
            const productDetails = [getProductBillingType, getProducttaxAmount];

            forkJoin(productDetails).subscribe(
              async productsData => {
                this.showLoader = true;
                this.initializeProductStructure();
                const billingData = productsData[0].body.data;
                const taxData = productsData[1].body.data;
                cartProductDuplicate.forEach(product => {
                  if (product.details.programId.includes('unlimited-')) {
                    product.details.programId = product.details.programId.replace('unlimited-', '');
                  }
                  const matchedBillingData = find(billingData, {
                    programId: product.details.programId,
                  });
                  const matchedtaxData = find(taxData, {
                    marketingCodeId: product.details.marketingCodeId,
                  });
                  if (matchedBillingData.operations.OnBill || (matchedBillingData.operations.OnBill && matchedBillingData.operations.OffBill)) {
                    if (this.globalVariables.billingFrequencyCheckout === 'Annual') {
                      matchedtaxData.TaxDetails = {
                        EnrollmentPrice: matchedtaxData.TaxDetails.EnrollmentPrice / 12,
                        SubTotal: matchedtaxData.TaxDetails.SubTotal / 12,
                        Tax: matchedtaxData.TaxDetails.Tax / 12,
                        Total: matchedtaxData.TaxDetails.Total / 12,
                      };
                    }
                    product.taxData = matchedtaxData;
                    const productData = billingTypeCopy.offbill.products.filter(item => {
                      if (item.details.marketingCodeId === product.details.marketingCodeId) return item;
                    });
                    if (productData[0] && productData[0].isAutoRenewStatus) {
                      product.isAutoRenew = productData[0].isAutoRenew;
                      product.isAutoRenewStatus = productData[0].isAutoRenewStatus;
                    }
                    this.productsByBillingType.onbill.products.push(product);
                  } else if (matchedBillingData.operations.OffBill) {
                    const productData = billingTypeCopy.offbill.products.filter(item => {
                      if (item.details.marketingCodeId === product.details.marketingCodeId) return item;
                    });
                    product.taxData = matchedtaxData;
                    if (productData[0] && productData[0].isAutoRenewStatus) {
                      product.isAutoRenew = productData[0].isAutoRenew;
                      product.isAutoRenewStatus = productData[0].isAutoRenewStatus;
                    }
                    this.productsByBillingType.offbill.products.push(product);
                  }
                });

                this.calculateBybillingType(this.productsByBillingType);
                if (this.productsByBillingType.offbill.products.length === 0 && this.productsByBillingType.onbill.products.length > 0) {
                  // onbill api call
                  await new Promise(resolve => {
                    setTimeout(resolve, 1000);
                  });
                  this.enrollProducts();
                } else if (
                  this.isAuthorizedToViewForm &&
                  this.productsByBillingType.offbill.products.length > 0 &&
                  this.productsByBillingType.onbill.products.length >= 0
                ) {
                  this.showPaymentPage = true;
                }
                this.showLoader = false;
                done(false, true);
              },
              () => {
                const trackAPIGATracking = 'trackProductTaxBillingAPIFail';
                this.showErrorAlertAndReload(trackAPIGATracking);
                this.globalVariables.showErrorMessageOnCart = true;
                done(true, null);
              },
            );
          }
        },
        () => {
          this.showLoader = false;
          this.showErrorAlertAndReload();
          done(true, null);
        },
      );
    } else {
      if (this.cartProducts.length > 0) {
        this.showErrorAlert();
      }
      this.initializeProductStructure();
      done(true, null);
    }
  }

  showErrorAlertAndReload(trackAPIGATracking?: any) {
    this.simpleModalService
      .addModal(
        AlertConfirmModalComponent,
        {
          title: 'We have encountered a problem with your enrollment',
          message:
            '<p style="margin-top:-20px;margin-bottom:0;">We are currently facing technical difficulties.Please try again after sometime. Sorry for any inconvenience. <br> <br> Please call the number at the top of page to speak to a Customer Service Representative.</p>',
          alertIcon: 'fa fa-exclamation-circle red',
          cancelText: ' ',
          confirmText: 'Close Message',
          confirmBtnId: trackAPIGATracking || 'close',
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: false,
        },
      )
      .subscribe(isConfirmed => {
        if (isConfirmed) {
          window.location.reload();
        }
      });
  }

  showErrorAlert(trackAPIGATracking?: any) {
    this.simpleModalService
      .addModal(
        AlertConfirmModalComponent,
        {
          title: 'We have encountered a problem with your enrollment',
          message: `<p style="margin-top:-20px;margin-bottom:0;">Please call customer service to ensure your enrollment has completed and you are protected. <b><a href="tel:${this.customerServiceNumber}">${this.customerServiceNumber}</a></b></p>`,
          alertIcon: 'fa fa-exclamation-circle red',
          cancelText: ' ',
          confirmText: 'Close Message',
          confirmBtnId: trackAPIGATracking || 'close',
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: false,
        },
      )
      .subscribe(isConfirmed => {
        if (isConfirmed) {
          // this.router.navigate(['cart']);
        } else if (isConfirmed === undefined) {
          /** Save Checkout User Form Data */
          this.user.address[0].serviceAddress.zipcode =
            this.zipcodeFromCartCheckout !== ''
              ? this.zipcodeFromCartCheckout.zipcode
              : JSON.parse(this.sessionStorageService.getItem('selectedAddress')).zipcode;
          this.sessionStorageService.setItem('user_data', JSON.stringify(this.user));
        }
      });
  }

  /**
   * initialize onbill off bill product structure.
   */
  initializeProductStructure() {
    this.productsByBillingType = {
      onbill: {
        total: 0,
        subtotal: 0,
        tax: 0,
        products: [],
      },
      offbill: {
        total: 0,
        subtotal: 0,
        tax: 0,
        products: [],
      },
      grossTotal: 0,
    };
  }

  /**
   * Calculate total, subtoal & tax amount.
   * @param products
   */
  calculateBybillingType(products: any) {
    if (this.customerGuid) {
      products.onbill.subtotal = products.onbill.products.reduce((previous, current) => previous + current.taxData.TaxDetails.EnrollmentPrice, 0);
      products.onbill.tax = products.onbill.products.reduce((previous, current) => previous + current.taxData.TaxDetails.Tax, 0);
      products.offbill.subtotal = products.offbill.products.reduce((previous, current) => previous + current.taxData.TaxDetails.EnrollmentPrice, 0);
      products.offbill.tax = products.offbill.products.reduce((previous, current) => previous + current.taxData.TaxDetails.Tax, 0);
      products.onbill.total = (products.onbill.subtotal + products.onbill.tax).toFixed(2);
      products.offbill.total = (products.offbill.subtotal + products.offbill.tax).toFixed(2);
      products.grossTotal = Number(products.onbill.total) + Number(products.offbill.total);
    } else {
      products.onbill.subtotal = products.onbill.products.reduce((previous, current) => {
        const productAmount = current.details.monthlyPrice;
        return previous + parseFloat(productAmount);
      }, 0);
      products.onbill.tax = products.onbill.products.reduce(() => 0, 0);
      products.offbill.subtotal = products.offbill.products.reduce((previous, current) => {
        const productAmount =
          this.globalVariables.billingFrequencyCheckout === 'Monthly'
            ? current.details.monthlyPrice
            : this.globalVariables.billingFrequencyCheckout === 'Annual'
            ? current.details.annualPrice
            : current.details.annualPrice;
        return previous + parseFloat(productAmount);
      }, 0);
      products.offbill.tax = products.offbill.products.reduce(() => 0, 0);
      products.onbill.total = (products.onbill.subtotal + products.onbill.tax).toFixed(2);
      products.offbill.total = (products.offbill.subtotal + products.offbill.tax).toFixed(2);
      products.grossTotal = Number(products.onbill.total) + Number(products.offbill.total);
    }
    this.ecommerceEventsForGA(this.productsByBillingType, 'viewCartItems');
  }

  public getProductBillTypesApi(locationCodeId, programIds, marketing_code_ids): Promise<any> {
    return this.crmProxyService.getProductBillTypes(locationCodeId, programIds, marketing_code_ids).toPromise();
  }

  public getAllCustomerAddressesApi(customerId, guestToken): Promise<any> {
    return this.crmProxyService.getProductBillTypes(customerId, guestToken).toPromise();
  }

  /**
   * Enroll on and offbill products.
   * @param res
   */
  async enrollProducts() {
    const onbillOrderRequests: Array<any> = [];
    this.showLoader = true;

    // make call to enrollment service and redirect to payment/success
    this.productsByBillingType.onbill.products.forEach(item => {
      const orderItem: any = {
        cartId: this.cartData._id,
        cartItemId: item._id,
        customerId: this.customerDetails.data.customerId,
        address:
          this.existingUser && this.addressPresentIn && !this.lastNameMismatchFound.length ? this.existingUser.address[0] : this.user.address[0],
        productId: item.productId,
        billingFrequency: 'Monthly', // default all onbill orders are monthly
        crmCustomerGuid: this.customerDetails.data.customerGuid,
        crmCustomerNumber: this.customerDetails.data.customerNo,
        enrollmentPrice: item.taxData,
        locationId: item.locationId,
        partner: this.partnerObj,
        description: item.description,
        enrollmentGuid: item.enrollmentGuid ? item.enrollmentGuid : '',
        isUpgrade: !!item.isUpgrade,
      };

      onbillOrderRequests.push(orderItem);
    });

    if (this.productsByBillingType.offbill.products.length === 0 && this.productsByBillingType.onbill.products.length > 0) {
      const allOnBillProductPayload = [];
      onbillOrderRequests.forEach(product => {
        const tepmPayload = {
          order: product,
          billingType: 'onbill',
          orderId: this.cuid,
          isFromCheckout: true,
        };
        allOnBillProductPayload.push(tepmPayload);
      });
      this.enrollmentRequest(allOnBillProductPayload, err => {
        if (!err) {
          // this.sendEnrollmentEmail(this.cuid);
          this.updateSuccessCartStatus(errCart => {
            this.showLoader = false;
            if (!errCart) {
              const queryParam = {
                queryParams: {
                  orderId: this.cuid,
                },
              };
              this.globalVariables.billingFrequencyCheckout = 'Monthly';
              this.router.navigate(['user/checkout'], queryParam);
              this.globalVariables.checkoutOrderCuid = this.cuid;
              this.globalVariables.showCheckoutPaymentReponsePage = true;
              /** Resetting the Local User Cart Data */
              this.sessionStorageService.deleteItem('user_data');
            } else {
              this.showErrorModal('Error', 'Error while updating cart status.', ErrorModalComponent);
            }
          });
        } else {
          this.showLoader = false;
          this.showErrorAlert();
        }
      });
    } else {
      this.showLoader = false;
    }
  }

  /**
   * Multiple enrollment request.
   * @param products
   * @param done
   */
  enrollmentRequest(products: any, done: any) {
    const apiArray = products.map(product =>
      this.ordersService.createOrder(product, product.billingType, this.customerDetails.data.customerId, this.guestToken),
    );

    forkJoin(apiArray).subscribe({
      next: (results: any[]) => {
        results.forEach(result => {
          if (result.status !== 200) {
            const element: HTMLElement = document.getElementById('checkout_order_error') as HTMLElement;
            element.click();
            this.sessionStorageService.deleteItem('user_data');
            done(true, result);
          }
        });
        this.sessionStorageService.deleteItem('user_data');
        done(null, results);
      },
      error: err => {
        const element: HTMLElement = document.getElementById('checkout_order_error') as HTMLElement;
        element.click();
        this.sessionStorageService.deleteItem('user_data');
        done(true, err);
      },
    });
  }

  /**
   * Update cart status to complete.
   * @param done
   */
  updateSuccessCartStatus(done: any) {
    this.cartQuickCheckoutService.updateProductCartStatus('ASYNC_IN_PROGRESS', errCart => {
      if (!errCart) {
        done(null, true);
      } else {
        done(true, false);
      }
    });
  }

  showCartErrorModal(title: string, message: string, confirmationMsg?: string, GAErrorId?: string, hasReditect = true) {
    this.simpleModalService
      .addModal(
        ErrorModalComponent,
        {
          title,
          message,
          confirmationMsg,
          confirmBtnId: GAErrorId || 'close',
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: true,
        },
      )
      .subscribe(isConfirmed => {
        if (isConfirmed === undefined) {
          /** Save Checkout User Form Data */
          this.user.address[0].serviceAddress.zipcode =
            this.zipcodeFromCartCheckout !== ''
              ? this.zipcodeFromCartCheckout.zipcode
              : JSON.parse(this.sessionStorageService.getItem('selectedAddress')).zipcode;
          this.sessionStorageService.setItem('user_data', JSON.stringify(this.user));
          // nagivate to the home page
          if (hasReditect) {
            this.router.navigate(['/']);
          }
        }
      });
  }

  /**
   * Set payment frequency.
   * @param frequency
   */
  setPaymentFrequency() {
    if (this.guestToken_id && this.guestToken) {
      this.showLoader = true;
      this.updateBillingAndTaxInfo(this.guestToken_id, this.guestToken, () => {
        this.showLoader = false;
      });
    }
  }

  async setOtherProductsToCartLocal() {
    this.globalVariables.cartCheckout = JSON.parse(this.sessionStorageService.getItem('cartCheckout'));
    if (!this.globalVariables.cartCheckout) {
      this.cartQuickCheckoutService.createCartBeforeCall().subscribe(() => {});
    }
    if (this.globalVariables.cartCheckout && this.globalVariables.cartCheckout.items && this.globalVariables.cartCheckout.items.length > 0) {
      const locationCodeId = this.globalVariables.cartCheckout.items[0].locationId;
      const cartProductDuplicate: any = JSON.parse(JSON.stringify(this.globalVariables.cartCheckout.items));
      const programIds = map(cartProductDuplicate, 'details.program.programId').join(',');
      const marketingcodeids = map(cartProductDuplicate, 'details.marketingCodeId').join(',');
      this.showLoader = true;
      try {
        const getProductBillTypesAPI = await this.getProductBillTypesApi(locationCodeId, programIds, marketingcodeids);
        const getProductBillTypesAPIRes = getProductBillTypesAPI.body;
        if (getProductBillTypesAPI.status === 200) {
          this.showLoader = false;
          this.initializeProductStructure();
          const billingData = getProductBillTypesAPIRes.data;
          this.globalVariables.cartCheckout.items.forEach(element => {
            if (element.details.programId.includes('unlimited-')) {
              element.details.programId = element.details.programId.replace('unlimited-', '');
            }
            const matchedBillingData = find(billingData, {
              programId: element.details.programId,
            });
            if (matchedBillingData.operations.OnBill || (matchedBillingData.operations.OnBill && matchedBillingData.operations.OffBill)) {
              this.productsByBillingType.onbill.products.push(element);
            } else if (matchedBillingData.operations.OffBill) {
              this.productsByBillingType.offbill.products.push(element);
            }
          });
        } else {
          const trackAPIGATracking = 'trackProductTaxBillingAPIFail';
          this.globalVariables.showErrorMessageOnCart = true;
          this.showLoader = false;
          this.showErrorAlert(trackAPIGATracking);
        }
      } catch (err) {
        const trackAPIGATracking = 'trackProductTaxBillingAPIFail';
        this.globalVariables.showErrorMessageOnCart = true;
        this.showLoader = false;
        this.showErrorAlert(trackAPIGATracking);
      }
    }
    this.calculateBybillingType(this.productsByBillingType);
    const cartDataFromLocal = JSON.parse(this.sessionStorageService.getItem('cartCheckout'));
    this.zipcodeFromCartCheckout =
      cartDataFromLocal && cartDataFromLocal.items.length > 0
        ? {
            zipcode: cartDataFromLocal.items[0].zipcode,
          }
        : '';
    this.cart = this.cartQuickCheckoutService.get();
    this.cart.subscribe(cart => {
      this.cartData = cart;
      this.cartProducts = cart.items;
      this.globalVariables.cartAmount.emit(cart);
    });
  }

  updateCartItemsAsPerAddress(address, cartData) {
    return new Promise((resolve, reject) => {
      this.showLoader = true;
      const locationcode = address.serviceAddress.locationCodeId;
      const contactType = address.serviceAddress && address.serviceAddress.contactType && address.serviceAddress.contactType === 'COMMERCIAL' ? 3 : 1;
      const replaceItemsInCart = [];
      const query = `contact_type=${contactType}&allProducts=true`;
      this.getDefaultMarketingCode(query, locationcode)
        .then((defaulMarketingCodesData: any) => {
          cartData.forEach(item => {
            const resData = defaulMarketingCodesData.find(data => item.details.programId === data.programId);
            if (resData) {
              resData.isBundle = resData.program.products.length > 1;
              resData.status = 'PENDING_PAYMENT';
              resData.provider = 'AWR';
              resData.locationId = resData.defaultLocationCodeId;
              resData.zipcode = address.serviceAddress.zipcode;
            } else {
              reject(item);
            }
            replaceItemsInCart.push(this.replaceItemInCart(item, resData));
          });
          Promise.all(replaceItemsInCart)
            .then(() => {
              this.globalVariables.cartCheckout = JSON.parse(this.sessionStorageService.getItem('cartCheckout'));
              this.initializeProductStructure();
              this.setProductsByBillingType()
                .then(() => {
                  resolve(this.globalVariables.cartCheckout.items);
                })
                .catch(err => {
                  reject(err);
                });
            })
            .catch(err => {
              reject(err);
            });
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  getDefaultMarketingCode(query, location_code) {
    return new Promise((resolve, reject) => {
      this.productService.getMarketingCodes(query, location_code).subscribe(
        data => {
          if (data.status === 200 && data.body && data.body.result && data.body.result) {
            resolve(data.body.result);
          } else {
            reject(data);
          }
        },
        err => {
          reject(err);
        },
      );
    });
  }

  replaceItemInCart(source, replacement) {
    return new Promise(resolve => {
      this.cartQuickCheckoutService.removeItem(source, () => {
        this.cartQuickCheckoutService.addItem(replacement, () => {
          resolve(replacement);
        });
      });
    });
  }

  async setProductsByBillingType() {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      if (this.globalVariables.cartCheckout && this.globalVariables.cartCheckout.items && this.globalVariables.cartCheckout.items.length > 0) {
        const locationCodeId = this.globalVariables.cartCheckout.items[0].locationId || this.locationCode;
        const cartProductDuplicate: any = JSON.parse(JSON.stringify(this.globalVariables.cartCheckout.items));
        const programIds = map(cartProductDuplicate, 'details.program.programId').join(',');
        const marketingcodeids = map(cartProductDuplicate, 'details.marketingCodeId').join(',');
        this.showLoader = true;
        try {
          const getProductBillTypesAPI = await this.getProductBillTypesApi(locationCodeId, programIds, marketingcodeids);
          const getProductBillTypesAPIRes = getProductBillTypesAPI.body;
          if (getProductBillTypesAPI.status === 200) {
            this.showLoader = false;
            const billingData = getProductBillTypesAPIRes.data;
            this.globalVariables.cartCheckout.items.forEach(element => {
              if (element.details.programId.includes('unlimited-')) {
                element.details.programId = element.details.programId.replace('unlimited-', '');
              }
              const matchedBillingData = find(billingData, {
                programId: element.details.programId,
              });
              if (matchedBillingData.operations.OnBill || (matchedBillingData.operations.OnBill && matchedBillingData.operations.OffBill)) {
                this.productsByBillingType.onbill.products.push(element);
              } else if (matchedBillingData.operations.OffBill) {
                this.productsByBillingType.offbill.products.push(element);
              }
              resolve(this.productsByBillingType);
            });
          } else {
            reject();
          }
        } catch (err) {
          reject(err);
        }
      }
    });
  }

  openBundle(data: any) {
    this.simpleModalService
      .addModal(
        BundleModalComponent,
        {
          data,
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: true,
        },
      )
      .subscribe(() => {});
  }

  ngOnDestroy() {
    if (this.isBrowser) {
      if (!this.globalVariables.showCheckoutPaymentReponsePage) {
        /** Save Checkout User Form Data */
        this.user.address[0].serviceAddress.zipcode =
          this.zipcodeFromCartCheckout !== ''
            ? this.zipcodeFromCartCheckout.zipcode
            : JSON.parse(this.sessionStorageService.getItem('selectedAddress')).zipcode;
        this.sessionStorageService.setItem('user_data', JSON.stringify(this.user));
      }
    }
    this.globalVariables.showCheckoutPaymentReponsePage = false;
    this.globalVariables.checkoutOrderCuid = undefined;
    this.globalVariables.isSuccessCheckoutWebRegistration = false;
  }

  setLocationData() {
    const locationCodeId =
      this.cartProducts && this.cartProducts.length && this.cartProducts[0].locationId
        ? this.cartProducts[0].locationId
        : this.sessionStorageService.getItem('location_code')
        ? this.sessionStorageService.getItem('location_code')
        : '';
    return new Promise((resolve, reject) => {
      this.locationService.getLocationsContent(locationCodeId).subscribe(
        data => {
          this.locationDataFetched = true;
          resolve(data);
        },
        err => {
          this.locationDataFetched = true;
          reject(err);
        },
      );
    });
  }

  checkCustomerExists(customerList, lastName, email) {
    let customerFound = null;
    let emailMatched = false;
    lastName = lastName.toLowerCase().trim();
    email = email.toLowerCase().trim();
    for (let i = 0; i < customerList.length; i++) {
      const currentCustomer = customerList[i];
      if (
        currentCustomer.Last_Name &&
        currentCustomer.Last_Name.toLowerCase().trim() === lastName &&
        currentCustomer.email &&
        currentCustomer.email.toLowerCase().trim() === email
      ) {
        customerFound = currentCustomer;
        break;
      } else if (currentCustomer.email && currentCustomer.email.toLowerCase().trim() === email) {
        customerFound = currentCustomer;
        emailMatched = true;
      } else if (
        this.addressPresentIn === 'customersDB' &&
        currentCustomer.lastName &&
        currentCustomer.lastName.toLowerCase().trim() === lastName &&
        !emailMatched
      ) {
        customerFound = currentCustomer;
      } else if (
        this.addressPresentIn === 'customercrmsDB' &&
        currentCustomer.Last_Name &&
        currentCustomer.Last_Name.toLowerCase().trim() === lastName &&
        !emailMatched
      ) {
        customerFound = currentCustomer;
      }
    }
    return customerFound;
  }

  async updateCartBasedOnLocation() {
    const { locationCodeId } = this.user.address[0].serviceAddress;
    if (!['null', 'undefined', undefined, null, ''].includes(locationCodeId) && locationCodeId !== this.cartData.items[0].locationId) {
      if (this.cartData.items && this.cartData.items.length) {
        try {
          const updateCartItemsResponse: any = await this.updateCartItemsAsPerAddress(this.user.address[0], this.cartProducts);
          if (updateCartItemsResponse) {
            this.cartProducts = updateCartItemsResponse;
            this.zipcodeFromCartCheckout.zipcode = this.cartProducts[0].zipcode;
            this.calculateBybillingType(this.productsByBillingType);
            this.globalVariables.cartSwap.emit(this.cartProducts);
            this.showErrorModal(
              'Important Information',
              'We have updated the products in your cart based on your location',
              ErrorModalComponent,
              'program_change_location',
              true,
            );
          } else {
            const GAErrorId = 'do_not_offer';
            this.showLoader = false;
            this.showCartErrorModal('We are sorry. We do not currently offer this service in your location.', '', '', GAErrorId);
            this.cartQuickCheckoutService.empty();
          }
        } catch (err) {
          const GAErrorId = 'do_not_offer';
          this.showLoader = false;
          this.showCartErrorModal('We are sorry. We do not currently offer this service in your location.', '', '', GAErrorId);
          this.cartQuickCheckoutService.empty();
        }
        return true;
      }
    }
  }

  ecommerceEventsForGA(products?: any, type?: string) {
    let items = [];

    if (products.offbill.products.length > 0) {
      items = [...this.gtagPayloadGeneratorService.generatingPayloadsForGA(products.offbill.products)];
    }

    if (products.onbill.products.length > 0) {
      items = [...items, ...this.gtagPayloadGeneratorService.generatingPayloadsForGA(products.onbill.products, null, true)];
    }

    const grossTotal = Number(parseFloat(products.offbill.total)) + Number(parseFloat(products.onbill.total));

    const actionItems = {
      affiliation: 'AW',
      currency: 'USD',
      value: grossTotal.toString(),
      billingfrequency: this.globalVariables.billingFrequencyCheckout,
      items,
    };

    if (type === 'viewCartItems') {
      this.gtagService.viewCartItems(actionItems);
    }

    if (type === 'beginCheckout') {
      this.gtagService.beginCheckout(actionItems);
    }
  }

  showUpgradePopupModal(modalComponent: any, enrolledProducts: any, cartItems: any, cartPayload: any, bundledData?: any, done?: any) {
    this.simpleModalService
      .addModal(
        modalComponent,
        {
          enrolledProducts,
          cartItems,
          cartPayload,
          bundledData,
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: false,
        },
      )
      .subscribe(isConfirmed => {
        if (isConfirmed) {
          this.showLoader = false;
          if (done) {
            done(isConfirmed);
          }
        } else if (isConfirmed === false) {
          this.showLoader = false;
          if (done) {
            done(false);
          }
        } else if (isConfirmed === undefined) {
          this.showLoader = false;
          if (done) {
            done(false);
          } else {
            done(undefined);
          }
        }
      });
  }

  async addToCartUsingQueryParam(query: Params) {
    const mkc = typeof query.mkc === 'string' ? [query.mkc] : query.mkc;
    this.sessionStorageService.deleteItem('cartCheckout');
    this.showLoader = true;
    if (query.type === 'nondefault') {
      this.fetchNonDefaultMarketingCodes(mkc);
      return;
    }
    const location = await this.fetchLocationData(query.location, query.zipcode);
    this.fetchMarketingCodes(location, mkc, query.zipcode);
  }

  async fetchLocationData(locationCode: string, zipcode = '') {
    try {
      const location = await this.locationService.getLocationsDataByLocationCode(locationCode).toPromise();
      if (location.status === 200 && location.body[0]) {
        if (location.body[0].websitePartnerName !== '' && this.isDefaultMkc) {
          this.sessionStorageService.setItem('providerSelected', '1');
          this.sessionStorageService.setItem('isProvider', '1');
          this.showProvider = true;
          this.providerSelected = true;
          this.globalVariables.OOFSelected.isOOFLocationRegister = 'false';
          const providerSelectionDetails = {
            providerSelected: true,
            zipCode: zipcode,
          };
          this.sessionStorageService.setItem('providerSelectionDetails', JSON.stringify(providerSelectionDetails));
        }
        this.locationCode = location.body[0].locationCodeId;
        this.sessionStorageService.setItem('location_code', this.locationCode);
      }
      return location;
    } catch (error) {
      this.showLoader = false;
      this.showErrorAlert();
    }
  }

  fetchNonDefaultMarketingCodes(mkc: string[]) {
    this.productService.getNonDefaultMarketingCodesById(mkc).subscribe(
      async data => {
        if (data.status === 200) {
          const cartItems = data.body.result.filter(m => mkc.includes(m.marketingCode));
          // for (const item of cartItems) {
          //   this.addToLocalCart(item, '');
          // }
          const cartMarketingCodes = [];
          cartItems.forEach(item => {
            if (!cartMarketingCodes.includes(item.marketingCode)) {
              this.addToLocalCart(item, '');
              cartMarketingCodes.push(item.marketingCode);
            }
          });
          this.showLoader = false;
          this.initialize();
        }
      },
      () => {
        this.showLoader = false;
        this.showErrorAlert();
      },
    );
  }

  fetchMarketingCodes(location: any, mkc: string[], zipcode: string) {
    this.locationCode = location.body[0].locationCodeId;
    this.sessionStorageService.setItem('location_code', this.locationCode);
    const query = `contact_type=${1}&allProducts=true`;
    this.productService.getMarketingCodes(query, this.locationCode).subscribe(
      data => {
        if (data.status === 200) {
          const cartItems = data.body.result.filter(m => mkc.includes(m.marketingCode));
          cartItems.forEach(item => {
            this.addToLocalCart(item, zipcode);
          });
          this.showLoader = false;
          this.initialize();
        }
      },
      () => {
        this.showLoader = false;
        this.showErrorAlert();
      },
    );
  }

  addToLocalCart(productData, zipcode) {
    productData.isBundle = productData.program.products.length > 1;
    productData.status = 'PENDING_PAYMENT';
    productData.provider = 'AWR';
    productData.locationId = this.locationCode || productData.defaultLocationCodeId;
    productData.zipcode = zipcode;
    productData.mkcType = this.isDefaultMkc ? undefined : 'nondefault';
    let checkoutCartData = this.sessionStorageService.getItem('cartCheckout');

    if (checkoutCartData) {
      checkoutCartData = JSON.parse(checkoutCartData);
    } else {
      checkoutCartData = {
        grossTotal: 0,
        mkcType: this.isDefaultMkc ? undefined : 'nondefault',
        items: [],
        _id: '',
      };
    }

    this.sessionStorageService.setItem('cartCheckout', JSON.stringify(checkoutCartData));
    this.globalVariables.cartCheckout = checkoutCartData;

    this.cartQuickCheckoutService.addItem(productData, (err, data, status) => {
      this.addToCartHandler(err, data, status);
      this.showLoader = false;
    });
  }

  addToCartHandler(err: any, data: any, status: any) {
    if (err) {
      if (data.status === 409 && !data.isAlreadyBundle) {
        const gaTrackBtnId = 'existing_program_in_cart';
        this.showLocalCartErrorModal("We're Sorry", 'This program has already been added to your cart.', 'Ok', gaTrackBtnId);
      } else if (data.status === 409 && data.isAlreadyBundle) {
        const gaTrackBtnId = 'existing_bundle_in_cart';
        this.showLocalCartErrorModal(
          "We're Sorry",
          'This program is included in a bundle that has already been added to your cart.',
          'Ok',
          gaTrackBtnId,
        );
      } else if (data.status === 400) {
        this.showLocalCartErrorModal(
          'Upgrade to Bundle',
          `If you are looking to bundle your existing services,
          please visit your My Services page and view the list of opportunities from your current services.`,
          'Ok',
        );
      } else {
        this.showLocalCartErrorModal('Error', 'Error while adding item to cart');
      }
    } else if (status === 201) {
      this.openBundle(data);
    } else if (!!data && data.length > 0) {
      this.sessionStorageService.setItem('deletedCartDataCheckout', JSON.stringify(data));
    }
  }

  showLocalCartErrorModal(title: string, message: string, confirmationMsg?: string, gaTrackBtnId?: string) {
    this.simpleModalService
      .addModal(
        ErrorModalComponent,
        {
          title,
          message,
          confirmationMsg,
          confirmBtnId: gaTrackBtnId || 'close',
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: true,
        },
      )
      .subscribe(() => {});
  }

  setNonDefaultMarketingCodeAsOffbill() {
    this.globalVariables.cartCheckout.items.forEach(element => {
      if (element.details.programId.includes('unlimited-')) {
        element.details.programId = element.details.programId.replace('unlimited-', '');
      }
      this.productsByBillingType.offbill.products.push(element);
    });
  }
}
