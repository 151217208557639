<div class="modal-dialog custom-error-alert modal-content modal-background" *ngIf="showCliamsDialog">
  <!-- <div class="modal-content modal-background"> -->
  <!-- Modal Header -->
  <div [ngClass]="getHeaderClass()" class="modal-header modal-custom-header error-modal-custom-header">
    <div class="modalHeaderView">
      <h2 class="modalHeaderText">File a Claim</h2>
      <button type="button" class="close pull-right set-font" (click)="modalClose()">
        <i class="fa fa-times close-icon" aria-hidden="true"></i>&nbsp;&nbsp;CLOSE
      </button>
    </div>
    <div class="modalHeaderViewDetails">
      <div class="propertyDetails">
        <div class="propertyDetails__imageView">
          <img src="../../../../img/my-account/icons/MyAccount.svg" alt="My-Property" />
        </div>
        <span class="propertyDetails__textStyle">
          {{ product.address.serviceAddress.streetNumber }} {{ product.address.serviceAddress.streetName }},
          {{ product.address.serviceAddress.city }}, {{ product.address.serviceAddress.state }},
          {{ product.address.serviceAddress.zipcode }}
        </span>
      </div>
      <div class="propertyDetails">
        <img class="propertyDetails__imgStyle" [src]="getImage(imageBaseUrl + product.productDetails?.programIconUrl)" alt="My-Property" />
        <span class="propertyDetails__textStyle">
          {{ singleBundleProduct ? singleBundleProduct.productName : product.productDetails.customerProgramName }}
        </span>
      </div>
    </div>
  </div>
  <!-- Modal Body  -->
  <div class="modal-body">
    <!-- CLaims Bundle Product Question starts here -->
    <div class="questionsView" *ngIf="showBundleProductModal && filteredProducts?.length > 1">
      <h2 class="modalBodyQuestionText">Which Program does your claim concern?</h2>
      <div class="row text-center MT-20">
        <div
          class="bundle-box"
          (click)="bundleProgramSelect(filterProduct)"
          *ngFor="let filterProduct of filteredProducts; let i = index"
          style.backgroundColor="{{ filterProduct.colorConfigStyle }}"
        >
          <div class="col-md-3 bundle-img">
            <img class="img-size" [src]="getImage(imageBaseUrl + filterProduct.icon)" alt="Bundle-Product" />
          </div>
          <div class="col-md-9 bundle-prog-title">
            <span>{{ filterProduct.productName }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- CLaims Bundle Product Question ends here -->
    <!-- Continue with previous claims  -->
    <div class="questionsView" *ngIf="showPreviousClaims && previousClaims.length && !isChildClaimSuccess && !showChildClaimComponent">
      <div class="d-flex">
        <div class="questionsView__numStyle">1</div>
        <div class="childClaimQuestionStyle">
          <h2 class="modalBodyQuestionText childClaimQuestionStyle__questionTextStyle">Is this claim related to a previous claim?</h2>
          <p class="childClaimQuestionStyle__paragraphTextStyle">Please select an option that best describes your claim type.</p>
        </div>
      </div>
      <div class="row MT-20 childClaimCarouselViewStyle">
        <div class="col-9 col-md-9">
          <div class="enrolled-services">
            <p class="pastClaimsTextStyle">Past Claims</p>
            <div class="col-12 col-md-12 recommended-property-block">
              <section class="services-slider col-12 service-outer" *ngIf="previousClaims?.length">
                <div class="slider slider-height" *ngIf="previousClaims?.length">
                  <ngu-carousel class="row services p-1 m-2" [inputs]="carouselTile" [dataSource]="previousClaims">
                    <ngu-tile
                      *nguCarouselDef="let claim of previousClaims; let i = index"
                      class="col-12 col-md-4 col-lg-3 col-xl-3 service-tile item"
                    >
                      <div (click)="onSelectClaim(claim)" class="pastClaimsStyle">
                        <div class="pastClaimsStyle__circleStyle">
                          <img class="pastClaimsStyle__circleStyle--img" [src]="statusImageChanges(claim.HOSstatus)" alt="icon" />
                        </div>
                        <div class="pastClaimsStyle__statusStyle">
                          <p class="pastClaimsStyle__statusStyle--pStyle textStyle">
                            {{ claim?.HOSstatus || '-' }}
                          </p>
                          <p class="pastClaimsStyle__statusStyle--pStyle">
                            {{ claim?.dateOfClaim?.split('T')[0] || '-' }}
                          </p>
                          <p class="pastClaimsStyle__statusStyle--pStyle textStyle">
                            {{ claim?.ClaimName || '-' }}
                          </p>
                        </div>
                      </div>
                    </ngu-tile>
                    <span NguCarouselPrev class="cat-slide-icon left-cat-icon fa ser-before"></span>
                    <span NguCarouselNext class="cat-slide-icon right-cat-icon fa ser-after"></span>
                  </ngu-carousel>
                </div>
              </section>
            </div>
          </div>
        </div>
        <div class="col-3 col-md-3 notRelatedButtonStyle">
          <img class="notRelatedButtonStyle__imgStyle" src="../../../../img/my-account-claims/NotRelatedIcon.svg" alt="icon" />
          <button class="notRelatedButtonStyle__btnStyle" (click)="hidePreviousClaims()">Not related</button>
        </div>
      </div>
    </div>

    <!-- Show Child Claim Question Component -->

    <hos-claims-child-component
      [productDetails]="product"
      [childQuestNumber]="childQuestNumber"
      *ngIf="showChildClaimComponent"
      [selectedClaim]="selectedClaim"
      (childClaimAnswer)="saveChildClaimAnswer($event)"
    ></hos-claims-child-component>

    <!-- End Of Child Claim Question Component -->

    <!--  Continue with previous claims model ends here -->
    <!-- claims success screen starts here -->
    <div *ngIf="!showBundleProductModal && !claimError && showSuccessScreen" class="questionsView__divStyle">
      <div class="d-flex justify-content-center align-items-center">
        <div class="questionsView__numStyle">
          <div class="checkmark-right">&nbsp;</div>
        </div>
        <h3 class="questionsView__questStyle">Claim Filed</h3>
      </div>
      <div class="claimsView">
        <div>
          <p class="dateTextStyle">
            Date Filed <span class="spanStyle">{{ today }}</span>
          </p>
          <p class="claimsDetailsStyle">Claim Details</p>
          <div>
            <p class="claimsDetailsTextStyle" *ngIf="!isChildClaimSuccess">{{ textAreaValue }}</p>
            <p class="claimsDetailsTextStyle" *ngIf="isChildClaimSuccess">{{ childClaimPayload?.claimSummary }}</p>
          </div>
        </div>
      </div>
      <div>
        <img src="../../../../img/my-account-claims/ClaimFiledIcon.svg" alt="Claim-Process-Icon" />
      </div>
    </div>
    <!-- handle claims error starts here -->
    <div *ngIf="!showBundleProductModal && claimError && intiateClaim" class="questionsView">
      <div class="d-flex justify-content-center align-items-center">
        <div class="questionsView__numStyle">
          <div class="close">&nbsp;</div>
        </div>
        <h3 class="questionsView__questStyle">Claim Failed</h3>
      </div>
      <div class="claimsView">
        <div class="text-center">
          <p class="claimsFailedTextStyle">{{ this.claimFailedCriteria }}</p>
          <img src="../../../../img/my-account-claims/ClaimFiledHomeIcon.svg" alt="Claim-Process-Icon" />
        </div>
      </div>
    </div>
    <!-- Claims Question Component starts here -->
    <hos-claims-question-helper-component
      *ngIf="!showPreviousClaims && !showBundleProductModal"
      [style.display]="
        !showBundleProductModal &&
        !showSuccessScreen &&
        !intiateClaim &&
        claimsQuestions &&
        !showPreviousClaims &&
        !claimError &&
        !showServiceFeePopUp
          ? 'block'
          : 'none'
      "
      [previousQuestion]="previousQuestion"
      [productInfo]="singleBundleProduct"
      [effectiveFromDt]="
        product.programEffectiveDate ? product.programEffectiveDate : product.effectiveFrom ? product.effectiveFrom : product.renewalDate
      "
      [question]="claimsQuestions[questionIndex]"
      (claimAnswer)="saveAnswer($event)"
    >
    </hos-claims-question-helper-component>

    <!-- For Claims Service Fee -->
    <hos-claims-payment-component
      *ngIf="showServiceFeePopUp && !showSuccessScreen && !intiateClaim && claimsQuestions && !showPreviousClaims && !claimError && serviceFees > 0"
      [selectedAddress]="product.address"
      [userInfo]="user"
      [serviceFeeQuestNumber]="serviceFeeQuestNumber"
      (serviceFeeOptionAns)="saveServiceFeeAns($event)"
      [claimsServiceFeeData]="claimsServiceFeeData"
      [product]="product"
      (paymentData)="processServiceFees($event)"
      (cardSelected)="selectCard($event)"
    ></hos-claims-payment-component>
  </div>
  <!-- Modal Footer  -->
  <div class="modal-footer">
    <div class="footer__viewStyle" *ngIf="!isChildQuestion && !showServiceFeePopUp">
      <div
        class="cursor-pointer mobile-btn-margin"
        *ngIf="
          (!showSuccessScreen && !intiateClaim && !singleBundleProduct) ||
          (!showSuccessScreen && !intiateClaim && filteredProducts?.length === 1 && previousQuestion?.length === 0) ||
          showPreviousClaims
        "
      >
        <a class="btnCancel" (click)="modalClose()">CANCEL</a>
      </div>
      <div
        class="footer__nextBtnView"
        *ngIf="
          !showSuccessScreen &&
          !intiateClaim &&
          !showPreviousClaims &&
          singleBundleProduct &&
          !(filteredProducts?.length === 1 && questionIndex === 0) &&
          !showServiceFeePopUp
        "
      >
        <a class="footer__nextBtn" (click)="handlePrevious()">PREV</a>
      </div>
      <div class="footer__icon" *ngIf="!showSuccessScreen && !intiateClaim">
        <img src="../../../../img/my-account-claims/ClaimProcessIcon.svg" alt="Claim-Process-Icon" />
      </div>
      <div class="footer__nextBtnView" *ngIf="!showSuccessScreen && !intiateClaim && singleBundleProduct">
        <a (click)="confirmHandler()" [ngClass]="[!claimAnswer && !isLastQuestion ? 'footer__nextDisabled' : 'footer__nextBtn']">NEXT</a>
      </div>
      <div class="footer__claimsCloseBtn" *ngIf="showSuccessScreen || (claimError && intiateClaim)">
        <a [attr.id]="showSuccessScreen ? 'successfulClaim' : 'closeModal'" class="footer__nextBtn" (click)="modalClose()">Close</a>
      </div>
    </div>

    <!-- For Child Claim Footer -->

    <div class="footer__viewStyle" *ngIf="isChildQuestion && !showServiceFeePopUp">
      <div class="cursor-pointer mobile-btn-margin" *ngIf="childQuestNumber === 1">
        <a class="btnCancel" (click)="modalClose()">CANCEL</a>
      </div>
      <div class="footer__nextBtnView" *ngIf="childQuestNumber === 2 && !claimError">
        <a class="footer__nextBtn" (click)="handleChildPrevious()">PREV</a>
      </div>
      <div class="footer__icon" *ngIf="(childQuestNumber === 1 || childQuestNumber === 2) && !claimError && !showSuccessScreen">
        <img src="../../../../img/my-account-claims/ClaimProcessIcon.svg" alt="Claim-Process-Icon" />
      </div>
      <div class="footer__nextBtnView" *ngIf="childQuestNumber === 1">
        <a id="childClaimId" (click)="confirmChildHandler()" [ngClass]="[!childClaimAnswer ? 'footer__nextDisabled' : 'footer__nextBtn']">NEXT</a>
      </div>
      <div class="footer__nextBtnView" *ngIf="childQuestNumber === 2 && !claimError">
        <a id="childClaimId" (click)="childClaimSubmit()" [ngClass]="[!childClaimAnswer ? 'footer__nextDisabled' : 'footer__nextBtn']"
          >File Child Claim</a
        >
      </div>
      <div class="footer__claimsCloseBtn" *ngIf="showSuccessScreen || (claimError && intiateClaim)">
        <a class="footer__nextBtn" [attr.id]="showSuccessScreen ? 'successfulClaim' : 'closeModal'" (click)="modalClose()">Close</a>
      </div>
    </div>

    <!-- For Claims Service Footer -->

    <div class="footer__viewStyle" *ngIf="showServiceFeePopUp">
      <!-- <div class="cursor-pointer mobile-btn-margin" *ngIf="serviceFeeQuestNumber === 1">
                    <a class="btnCancel" (click)="modalClose()">CANCEL</a>
                </div> -->
      <div class="footer__nextBtnView" *ngIf="!claimError && !showSuccessScreen">
        <a class="footer__nextBtn" (click)="handleServiceFeePrevious()">PREV</a>
      </div>
      <div class="footer__icon" *ngIf="(serviceFeeQuestNumber === 1 || serviceFeeQuestNumber === 2) && !claimError && !showSuccessScreen">
        <img src="../../../../img/my-account-claims/ClaimProcessIcon.svg" alt="Claim-Process-Icon" />
      </div>
      <div class="footer__nextBtnView" *ngIf="serviceFeeQuestNumber === 1 && !claimError && !showSuccessScreen">
        <a id="childClaimId" (click)="confirmServiceFeeHandler()" [ngClass]="[!serviceFeeAnswer.length ? 'footer__nextDisabled' : 'footer__nextBtn']"
          >NEXT</a
        >
      </div>
      <div class="footer__nextBtnView" *ngIf="serviceFeeQuestNumber === 2 && !claimError && !showSuccessScreen">
        <a id="childClaimId" (click)="processServiceFees()" [ngClass]="[!selectedCard?.CreditCardToken ? 'footer__nextDisabled' : 'footer__nextBtn']"
          >Proceed to File Claim</a
        >
      </div>
      <div class="footer__claimsCloseBtn" *ngIf="showSuccessScreen || (claimError && intiateClaim)">
        <a class="footer__nextBtn" [attr.id]="showSuccessScreen ? 'successfulClaim' : 'closeModal'" (click)="modalClose()">Close</a>
      </div>
    </div>
  </div>
  <!-- </div> -->
</div>

<div [ngClass]="{ loading: showLoader }"></div>
