import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

@Injectable()
export class PlatFormId {
  isBrowser: boolean = true;

  isServer: boolean = false;

  private platFormObj: Object = {};

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.isServer = isPlatformServer(platformId);
  }

  public getPlatForm() {
    this.platFormObj = {
      isBrowser: this.isBrowser,
      isServer: this.isServer,
    };
    return this.platFormObj;
  }
}
