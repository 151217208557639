<!--- FAQ -->
<section class="content-block content-section shaded-white faq-section PB-0" id="faq-section">
  <article class="page-wrap row">
    <div class="col-12">
      <h2 class="section-head-title font-unbounce-title">{{ faqsHeading ? faqsHeading : 'Frequently Asked Questions' }}</h2>
    </div>
  </article>

  <article class="page-wrap row">
    <div id="faq-accordion" role="tablist" aria-multiselectable="true" class="col-12 accordion fs-restrict-dead-clicks set-b-m">
      <div class="card" *ngFor="let faq of faqs; let i = index" (click)="changeFAQ(i)" [ngClass]="{ 'faq-selected': i === selectedFAQ }">
        <div class="card-header" role="tab" id="heading_{{ i }}">
          <h4 class="mb-0">
            <a
              data-toggle="collapse"
              data-parent="#faq-accordion"
              href="#faqcollapse_{{ i }}"
              [attr.aria-expanded]="i === 0"
              [ngClass]="{ collapsed: i !== 0 }"
              aria-controls="collapseOne"
            >
              <span class="faq-text"> Q. {{ faq.question }} </span>
            </a>
          </h4>
        </div>

        <div id="faqcollapse_{{ i }}" class="collapse" role="tabpanel" data-parent="#faq-accordion" [ngClass]="{ show: i === 0 }">
          <div class="card-block">
            <p innerHtml="A. {{ faq.answer }}"></p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-12 text-center set-b-m" *ngIf="faqs.length === 0">No FAQ's available at this moment.</div>
  </article>
</section>
