import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import file from '../secret.json';

if (environment.production) {
  enableProdMode();
}

async function bootstrap() {
  const res = await fetch(`${environment.API_Endpoint}${environment.Content_service_API_Endpoint}micrositenames?APIKey=${file.APIKey}`);
  const data = await res.json();
  environment.micrositeNames = data;
  if (res.status === 200) {
    localStorage.setItem('micrositeName', JSON.stringify(data));
  } else {
    localStorage.setItem('micrositeName', JSON.stringify(environment.micrositeNames));
  }
  platformBrowserDynamic().bootstrapModule(AppModule);
}

if (document.readyState === 'complete') {
  bootstrap();
} else {
  document.addEventListener('DOMContentLoaded', bootstrap);
}
