/* eslint-disable no-underscore-dangle */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SimpleModalComponent, SimpleModalService } from 'ngx-simple-modal';
import { GlobalVariables } from '../../../core/services/global-variables.service';
import { SessionStorageService } from '../../../core/services/session-storage.service';
import { ProductService } from '../../../product/services/product.service';
import { LocationService } from '../../services/location.service';
import { WindowScrolling } from '../../../core/services/window-scrolling.service';

export interface GetStartedModal {
  productData: any;
  mkcCategories?: any;
  isCategory?: any;
}
@Component({
  selector: 'hos-get-started-modal',
  templateUrl: './get-started-modal.component.html',
  styleUrls: ['./get-started-modal.component.scss'],
})
export class GetStartedModalComponent extends SimpleModalComponent<GetStartedModal, any> implements OnInit, OnDestroy {
  locationCodeData: any = '';

  productData: any;

  errorText: String = '';

  mkcCategories: any;

  isCategory: any;

  constructor(
    simpleModalService: SimpleModalService,
    private router: Router,
    private productService: ProductService,
    private locationService: LocationService,
    private sessionStorageService: SessionStorageService,
    private globalVariables: GlobalVariables,
    private windowScrolling: WindowScrolling,
  ) {
    super();
    // this.windowScrolling.disable();
  }

  ngOnInit() {
    this.errorText = '';
  }

  addressHandler(event) {
    this.errorText = '';
    const serviceCategoryData: any = this.mkcCategories.filter(ele => {
      if (this.isCategory) {
        return this.productData.serviceId === ele._id;
      }
      return this.productData.programId === ele.reviewProgramId;
    });
    if (event) {
      const locationCode = event.location_code ? event.location_code : '';
      this.productService.getCategoryById(serviceCategoryData[0]._id ? serviceCategoryData[0]._id : '').subscribe(dataRes => {
        if (dataRes.status === 200) {
          const category = dataRes.body;
          this.productService.getMarketingCodes('', locationCode).subscribe(
            data => {
              if (data.status === 200) {
                this.close();
                this.windowScrolling.enable();
                const resData = data.body;
                let resultData = resData.result.filter(ele => ele.program.programId === category.reviewProgramId && ele.coverageType === 'REGULAR');
                if (resultData.length === 0) {
                  resultData = resData.result.filter(ele => ele.program.programId === category.reviewProgramId && ele.coverageType === 'UNLIMITED');
                }
                if (resultData.length !== 0) {
                  this.redirect(event, resultData[0]);
                } else {
                  this.redirect(event, []);
                }
              } else if (data.status !== 200) {
                this.errorText = 'No service available in your vicinity.';
              }
            },
            () => {
              this.errorText = 'Please check back soon for product and service availability in your area.';
            },
          );
        }
      });
    } else {
      this.sessionStorageService.setItem('selectedAddress', JSON.stringify(''));
    }
  }

  redirect(event, resultData) {
    let WebProgName = '';
    this.locationService.getLocationsContent(event.location_code || this.sessionStorageService.getItem('location_code')).subscribe(
      dataReceived => {
        this.close();
        // this.windowScrolling.disable();
        this.locationCodeData = dataReceived.body;
        this.locationService.getMicroSiteForLocation(this.locationCodeData.locationCode).subscribe(
          dataMicroSite => {
            WebProgName = resultData.webProgramName ? resultData.webProgramName : resultData.programName;
            let categoryParams: any;
            if (WebProgName) {
              categoryParams = WebProgName.toString().split(' ').join('-').split(',').join('').toLowerCase();
            } else {
              this.router.navigate([`/products/${event.zipcode}/${this.locationCodeData.locationCode.toLowerCase()}/residential`]);
            }
            if (dataMicroSite.body.level === 'default') {
              if (this.sessionStorageService.getItem('providerState')) {
                this.sessionStorageService.setItem('isMicroSite', '0');
                this.sessionStorageService.deleteItem('baseUrlState');
                this.sessionStorageService.deleteItem('providerState');
                if (categoryParams) {
                  window.location.href = `/product/${
                    event.zipcode
                  }/${this.locationCodeData.locationCode.toLowerCase()}/residential/${categoryParams}`;
                } else {
                  window.location.href = `/products/${event.zipcode}/${this.locationCodeData.locationCode.toLowerCase()}/residential`;
                }
              } else if (categoryParams) {
                this.router.navigate([`/product/${event.zipcode}/${this.locationCodeData.locationCode.toLowerCase()}/residential/${categoryParams}`]);
              } else {
                this.router.navigate([`/products/${event.zipcode}/${this.locationCodeData.locationCode.toLowerCase()}/residential`]);
              }
            } else {
              this.sessionStorageService.setItem('isMicroSite', '1');
              if (dataMicroSite.body.site.siteName) {
                dataMicroSite.body.site.siteName = dataMicroSite.body.site.siteName.toLowerCase();
              } else {
                dataMicroSite.body.site.siteName = '';
              }

              let providerState = '';
              providerState = this.sessionStorageService.getItem('providerState')
                ? this.sessionStorageService.getItem('providerState').toLowerCase()
                : '';
              if (providerState === dataMicroSite.body.site.siteName) {
                if (categoryParams) {
                  this.router.navigate([
                    `/product/${event.zipcode}/${this.locationCodeData.locationCode.toLowerCase()}/residential/${categoryParams}`,
                  ]);
                } else {
                  this.router.navigate([`/products/${event.zipcode}/${this.locationCodeData.locationCode.toLowerCase()}/residential`]);
                }
              } else {
                if (dataMicroSite.body.site.siteName) {
                  dataMicroSite.body.site.siteName = dataMicroSite.body.site.siteName.toLowerCase();
                } else {
                  dataMicroSite.body.site.siteName = '';
                }
                this.sessionStorageService.setItem('providerState', dataMicroSite.body.site.siteName);
                this.sessionStorageService.setItem('baseUrlState', dataMicroSite.body.site.siteName);

                if (categoryParams) {
                  window.location.href = `/${dataMicroSite.body.site.siteName}/product/${
                    event.zipcode
                  }/${this.locationCodeData.locationCode.toLowerCase()}/residential/${categoryParams}`;
                } else {
                  window.location.href = `/${dataMicroSite.body.site.siteName}/products/${
                    event.zipcode
                  }/${this.locationCodeData.locationCode.toLowerCase()}/residential`;
                }
              }
            }
          },
          () => {},
        );
      },
      () => {},
    );
  }

  ngOnDestroy() {
    this.close();
    this.windowScrolling.enable();
  }
}
