<hos-success-alert life="2000" *ngIf="successCall"></hos-success-alert>
<div class="modal-dialog modal-custom-header modal-content">
  <div class="modal-header">
    <button type="button" class="close pull-right set-font" (click)="close()">
      <i class="fa fa-times close-icon" aria-hidden="true"></i>&nbsp;&nbsp;CLOSE
    </button>
    <div class="header-text auto-renew">Auto Renew</div>
  </div>
  <div class="modal-body">
    <div class="card-body-box">
      <div class="card-body row no-gutters">
        <div class="col-2">
          <img
            class="cart-image"
            [src]="service?.productDetails?.programIconUrl ? getImage(productServiceBaseUrl + service?.productDetails?.programIconUrl) : ''"
          />
        </div>
        <div class="col-10">
          <div class="row desktop-only">
            <div class="col-md-12 product-details">
              <div class="product-currently-en">Program Currently Enrolled</div>
              <h6 class="product-name">
                <strong>{{ service?.productDetails?.webProgramName }}</strong>
              </h6>
              <div class="product-desc">Monthly Billing Frequency Selected</div>
            </div>
          </div>
          <div class="mobile-only">
            <div class="col-md-12 product-details">
              <div class="product-currently-en">Program Currently Enrolled</div>
              <p class="product-name">
                <strong>{{ service?.productDetails?.webProgramName }}</strong>
              </p>
              <div class="product-desc">Monthly Billing Frequency Selected</div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body row no-gutters">
        <p class="description-cls">
          <br />AWR’s <strong>Auto Renew</strong> option is the most convenient way to ensure that your Program continues without interruption. At the
          end of your 12-month term, your {{ service?.productDetails?.webProgramName }} will automatically renew for another 12-month term in
          accordance with your current monthly or annual (as applicable) recurring payment method. You can change your payment method and/or
          auto-renewing status at any time by calling us at {{ siteContent?.contactNumber }} . <br /><br />By clicking “I AGREE TO AUTO RENEW” below,
          I authorize AWR (and my financial institution - for Direct Debit) to debit or charge on a monthly or annual (as applicable) recurring basis
          the Program fee in accordance with the Program’s Terms and Conditions and <a (click)="goToTermsOfAuth()">Terms of Authorization</a>. This
          authorization will remain in effect and my Program will continue to auto renew for additional annual terms until I notify AWR of a change or
          cancellation.
        </p>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="cursor-pointer">
      <button class="btn print-btn text-uppercase" (click)="close()">CANCEL</button>
    </div>
    <div>
      <button (click)="agreeToAutoRenew()" class="btn submit-btn app-btn mt-0 text-uppercase">I AGREE TO AUTO RENEW</button>
    </div>
  </div>
</div>
